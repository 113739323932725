import { type Address } from '@strise/types'

export const extractAddress = (address: Omit<Address, '__typename' | 'countryV2'>): string | null => {
  if (!address.addressLine && !address.zipCode && !address.city) return null

  const formatted = [[address.addressLine], [address.zipCode, address.city]]
    .map((subAddress) => subAddress.filter(Boolean).join(' '))
    .filter(Boolean)
    .join(', ')
  return formatted
}

export const hasAddressValues = (address: Omit<Address, '__typename' | 'countryV2'>): boolean => {
  return !!extractAddress(address)
}
