import { CompanyStatusDot } from './CompanyStatusDot'
import { companyStatusTitles } from './companyStatusUtils'
import { type I18n } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { type CompanyStatus } from '@strise/types'
import { IconPlus, Typography } from '@strise/ui-components'

export const CompanyStatusSelectButton = ({ i18n, value }: { i18n: I18n; value?: CompanyStatus | null }) => {
  const title = value ? companyStatusTitles[value] : null

  const label = title ?? defineMessage({ message: 'Add to portfolio' })

  return (
    <div className='flex w-full items-center'>
      {!!value && <CompanyStatusDot status={value} className='mr-2' />}
      {!value && <IconPlus size='md' className='mr-2 shrink-0' />}
      <Typography component='span' variant='aLabel' className='truncate'>
        {i18n._(label)}
      </Typography>
    </div>
  )
}
