import { Trans } from '@lingui/macro'
import { usePersistentState } from '@strise/react-utils'
import { Divider, Tabs, TabsContent, TabsList, TabsTrigger } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { RenderContextType } from '~/contexts/RenderContext/RenderContext'
import { RenderContextProvider } from '~/contexts/RenderContext/RenderContextProvider'
import { ReviewCompanyContent } from '~/features/Review/ReviewCompanyContent'
import { ReviewPrivatePersonsContent } from '~/features/Review/ReviewPrivatePersonsContent'
import { TestIDs } from '~/utils/testIDs'

export const ReviewView = (): ReactNode => {
  const [activeTab, setActiveTab] = usePersistentState<string>('review-active-tab', 'companies')
  const features = useCurrentUserFeatures()
  const isPrivatePersonEnabled = features.EXPERIMENTAL_PRIVATE_PERSON_FEATURES

  return (
    <RenderContextProvider type={RenderContextType.Review}>
      <div data-id={TestIDs.Review.root}>
        <Tabs defaultValue={activeTab} className='flex flex-col' onValueChange={setActiveTab}>
          <TabsList className={isPrivatePersonEnabled ? 'flex justify-center pt-4 pb-2' : 'hidden'}>
            <TabsTrigger value='companies'>
              <Trans>Companies</Trans>
            </TabsTrigger>
            {isPrivatePersonEnabled && (
              <TabsTrigger value='privatePersons'>
                <Trans>Private Persons</Trans>
              </TabsTrigger>
            )}
          </TabsList>
          <Divider />
          <TabsContent value='companies'>
            <ReviewCompanyContent />
          </TabsContent>
          {isPrivatePersonEnabled && (
            <TabsContent value='privatePersons'>
              <ReviewPrivatePersonsContent />
            </TabsContent>
          )}
        </Tabs>
      </div>
    </RenderContextProvider>
  )
}
