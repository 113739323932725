import { Trans } from '@lingui/macro'
import { type TeamReviewSettingsV2Fragment } from '@strise/app-shared/src/graphqlTypes'
import { type SetStateFn } from '@strise/react-utils'
import { type AuditRisk, ReviewSettingKind } from '@strise/types'
import { Divider, Skeleton, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useTeamId } from '~/contexts/TeamContext/TeamContext'
import { AuditRiskSelect } from '~/features/Risk/AuditRiskSelect'
import { CustomRiskLevelSelect } from '~/features/Risk/CustomRiskLevelSelect'
import { useRiskLevelsQuery } from '~/graphqlOperations'

interface ReviewRiskAssessmentSectionProps {
  auditRisk: AuditRisk
  customRiskLevel: string | null
  disabled: boolean
  setAuditRisk: SetStateFn<AuditRisk>
  setCustomRiskLevel: SetStateFn<string | null>
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
}

export const ReviewRiskAssessmentSection = ({
  auditRisk,
  customRiskLevel,
  disabled,
  setAuditRisk,
  setCustomRiskLevel,
  teamReviewSettings
}: ReviewRiskAssessmentSectionProps): ReactNode => {
  const features = useCurrentUserFeatures()

  const team = useTeamId()
  const { data, loading } = useRiskLevelsQuery({
    variables: { team },
    skip: !features.CUSTOM_RISK_LEVELS
  })

  if (
    !teamReviewSettings.settings.find((setting) => setting.kind === ReviewSettingKind.EnableRiskAssessmentValue)
      ?.enabled
  )
    return null

  return (
    <>
      <Typography className='mb-2 mt-4' variant='subtitle1'>
        {features.AUTOMATED_RISK_ENGINE ? <Trans>Final risk assessment</Trans> : <Trans>Risk assessment</Trans>}
      </Typography>
      <div className='py-4'>
        {features.CUSTOM_RISK_LEVELS && loading && <Skeleton className='h-10 w-96' />}
        {features.CUSTOM_RISK_LEVELS && data && data.riskLevels.length !== 0 ? (
          <CustomRiskLevelSelect
            selectedRisk={customRiskLevel}
            onChange={(newRisk) => setCustomRiskLevel(newRisk)}
            disabled={disabled}
            riskLevels={data.riskLevels}
          />
        ) : (
          <AuditRiskSelect
            selectedRisk={auditRisk}
            onChange={({ target: { value } }) => setAuditRisk(value as AuditRisk)}
            disabled={disabled}
          />
        )}
      </div>
      <Divider />
    </>
  )
}
