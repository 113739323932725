import { Dropdown, type DropdownProps } from './Dropdown'
import { IconButton, IconOverflowHorizontal, type IconPropsWithRef, cn } from '@strise/ui-components'
import { type ReactNode, forwardRef } from 'react'

type OverflowMenuType = Omit<DropdownProps, 'buttonProps'> & {
  buttonProps: { iconProps?: IconPropsWithRef } & DropdownProps['buttonProps']
}

const ToggleButton = forwardRef<HTMLButtonElement, OverflowMenuType['buttonProps']>(
  ({ className, iconProps, ...props }, ref): ReactNode => (
    <IconButton className={cn('shrink-0 rounded', className)} ref={ref} variant='ghost' palette='tertiary' {...props}>
      <IconOverflowHorizontal {...iconProps} />
    </IconButton>
  )
)

export const OverflowMenu = ({ buttonProps, children, className, ...props }: OverflowMenuType) => (
  <Dropdown className={className} menu ToggleComponent={<ToggleButton {...buttonProps} />} {...props}>
    {children}
  </Dropdown>
)
