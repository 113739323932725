import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconConnect = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <g fill='currentColor' fillRule='evenodd' clipPath='url(#IconConnect_svg__a)' clipRule='evenodd'>
        <path d='M23.207.793a1 1 0 0 1 0 1.414l-1.965 1.965.657.657a4 4 0 0 1 0 5.656l-4.243 4.243-8.485-8.485L13.414 2a4 4 0 0 1 5.656 0l.758.757L21.792.793a1 1 0 0 1 1.415 0Zm-2.722 5.45a2 2 0 0 1 0 2.828L17.656 11.9 12 6.243l2.829-2.829a2 2 0 0 1 2.828 0l2.829 2.829ZM11.328 14.257l-1.586-1.585 1.965-1.965-1.415-1.414-1.964 1.964-2.086-2.086L2 13.414a4 4 0 0 0 0 5.657l.757.757-1.965 1.965a1 1 0 1 0 1.415 1.414l1.964-1.965.657.657a4 4 0 0 0 5.657 0l4.242-4.242-1.985-1.986 1.965-1.964-1.415-1.414-1.964 1.964Zm-7.914.571L6.242 12l5.657 5.657-2.828 2.828a2 2 0 0 1-2.829 0l-2.828-2.828a2 2 0 0 1 0-2.829Z' />
      </g>
      <defs>
        <clipPath id='IconConnect_svg__a'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
    </svg>
  )
)
IconConnect.displayName = 'IconConnect'
