import { t } from '@lingui/macro'
import { IdentityVerificationStatus } from '@strise/types'
import { Button } from '@strise/ui-components'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { IdvCheckResetButton } from '~/features/IDVCheck/IdvCheckResetButton'

interface IdvActionsProps {
  loadingStatus: IdentityVerificationStatus | null
  onUpdateStatus: (status: IdentityVerificationStatus) => Promise<void>
  setOpen: Dispatch<SetStateAction<boolean>>
  status: IdentityVerificationStatus
}

export const IdvActions = ({ loadingStatus, onUpdateStatus, setOpen, status }: IdvActionsProps): ReactNode => {
  const handleApprove = async () => {
    await onUpdateStatus(IdentityVerificationStatus.Approved)
    setOpen(false)
  }

  const handleDecline = async () => {
    await onUpdateStatus(IdentityVerificationStatus.Declined)
    setOpen(false)
  }

  return (
    <div className='flex items-center gap-2 mt-4 justify-between w-full'>
      <IdvCheckResetButton onUpdateStatus={onUpdateStatus} loadingStatus={loadingStatus} setOpen={setOpen} />
      <div className='flex gap-2'>
        {status !== IdentityVerificationStatus.Declined && (
          <Button
            data-track='IDV Check / Decline'
            variant='outlined'
            className='border-semantic-danger-shade-75 text-semantic-danger-shade-75 hover:bg-semantic-danger-shade-5'
            onClick={handleDecline}
            disabled={!!loadingStatus}
            loading={loadingStatus === IdentityVerificationStatus.Declined}
          >
            {t`Decline`}
          </Button>
        )}
        {status !== IdentityVerificationStatus.Approved && (
          <Button
            data-track='IDV Check / Approve'
            className='rounded-sm bg-semantic-success-shade-100 text-white hover:bg-semantic-success-shade-50'
            onClick={handleApprove}
            disabled={!!loadingStatus}
            loading={loadingStatus === IdentityVerificationStatus.Approved}
          >
            {t`Approve`}
          </Button>
        )}
      </div>
    </div>
  )
}
