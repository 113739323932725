import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconArrowUpSmall = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m12 7.586 4.707 4.707-1.414 1.414L13 11.414V16.5h-2v-5.086l-2.293 2.293-1.414-1.414L12 7.586Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconArrowUpSmall.displayName = 'IconArrowUpSmall'
