import { type ApolloCache } from '@apollo/client'
import { type DeleteModifier } from '@apollo/client/cache/core/types/common'
import { t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import { PrivatePersonReviewStatusKind } from '@strise/types'
import { useUpdatePrivatePersonReviewStatusMutation } from '~/graphqlOperations'

const resetPrivatePersonsByReviewStatusCache = (cache: ApolloCache<object>): void => {
  cache.modify({
    fields: {
      privatePersonsByReviewStatus(_, { DELETE }): DeleteModifier {
        return DELETE
      }
    }
  })
}

export const resetPrivatePersonSearchCache = (cache: ApolloCache<object>): void => {
  cache.modify({
    fields: {
      privatePersonSearch(_, { DELETE }): DeleteModifier {
        return DELETE
      }
    }
  })
}

export const useUpdatePrivatePersonReviewStatus = () => {
  const [updateReviewStatus, { loading: updateReviewStatusLoading }] = useUpdatePrivatePersonReviewStatusMutation({
    update: resetPrivatePersonsByReviewStatusCache
  })

  const handleUpdateReviewStatus = async (id: string, status: PrivatePersonReviewStatusKind) => {
    try {
      await updateReviewStatus({ variables: { id, status } })
      if (status === PrivatePersonReviewStatusKind.InReview) {
        toast.success(t`Private person added to review`)
      } else {
        toast.success(t`Private person removed from review`)
      }
    } catch {
      toast.error(t`Failed to update private person review status`)
    }
  }

  return { updateReviewStatus: handleUpdateReviewStatus, updateReviewStatusLoading }
}
