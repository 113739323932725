import { Typography, type TypographyProps } from '../Typography'
import type { HTMLProps, ReactNode } from 'react'
import { forwardRef } from 'react'

export type LabelProps = TypographyProps & HTMLProps<HTMLLabelElement>
export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({ className, ...props }, ref): ReactNode => (
    <Typography ref={ref} className={className} component='label' variant='body2' {...props} />
  )
)
Label.displayName = 'Label'
