'use client'

import { cn } from '../../utils/className'
import { Button, type ButtonProps } from '../Button'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import {
  Children,
  type ComponentPropsWithoutRef,
  type ReactNode,
  cloneElement,
  forwardRef,
  isValidElement
} from 'react'

export interface TabsProps extends ComponentPropsWithoutRef<typeof TabsPrimitive.Root>, Pick<ButtonProps, 'palette'> {}

const Tabs = forwardRef<React.ElementRef<typeof TabsPrimitive.Root>, TabsProps>(
  ({ children, className, palette, ...props }, ref): ReactNode => (
    <TabsPrimitive.Root ref={ref} className={cn('data-[orientation=vertical]:inline-flex', className)} {...props}>
      {/* eslint-disable-next-line @typescript-eslint/promise-function-async,@eslint-react/no-children-map */}
      {Children.map(children, (child) => {
        if (!isValidElement(child)) return child
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@eslint-react/no-clone-element
        return cloneElement(child, {
          palette,
          ...child.props
        })
      })}
    </TabsPrimitive.Root>
  )
)
Tabs.displayName = TabsPrimitive.Tabs.displayName

interface TabsListProps extends ComponentPropsWithoutRef<typeof TabsPrimitive.List>, Pick<ButtonProps, 'palette'> {}

const TabsList = forwardRef<React.ElementRef<typeof TabsPrimitive.List>, TabsListProps>(
  ({ children, className, palette, ...props }, ref): ReactNode => (
    <TabsPrimitive.List
      ref={ref}
      className={cn('inline-flex data-[orientation=vertical]:flex-col', className)}
      {...props}
    >
      {/* eslint-disable-next-line @typescript-eslint/promise-function-async,@eslint-react/no-children-map */}
      {Children.map(children, (child) => {
        if (!isValidElement(child)) return child
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@eslint-react/no-clone-element
        return cloneElement(child, {
          palette,
          ...child.props
        })
      })}
    </TabsPrimitive.List>
  )
)

TabsList.displayName = TabsPrimitive.List.displayName

interface TabsTriggerProps extends ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>, Omit<ButtonProps, 'value'> {}

const TabsTrigger = forwardRef<React.ElementRef<typeof TabsPrimitive.Trigger>, TabsTriggerProps>(
  ({ children, className, palette = 'secondary', ...props }, ref): ReactNode => (
    <TabsPrimitive.Trigger
      ref={ref}
      asChild
      className={cn(
        'text-text-primary',
        'data-[orientation=horizontal]:data-[state=active]:border-b-4 data-[orientation=vertical]:data-[state=active]:border-r-4',
        'data-[orientation=horizontal]:data-[state=active]:pt-1 data-[orientation=vertical]:data-[state=active]:pr-[calc(1.25rem-4px)]',
        'data-[state=active]:font-bold',
        className
      )}
      {...props}
    >
      <Button palette={palette}>{children}</Button>
    </TabsPrimitive.Trigger>
  )
)

TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

type TabsContentProps = ComponentPropsWithoutRef<typeof TabsPrimitive.Content>

const TabsContent = forwardRef<React.ElementRef<typeof TabsPrimitive.Content>, TabsContentProps>(
  ({ className, ...props }, ref) => <TabsPrimitive.Content ref={ref} className={className} {...props} />
)

TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
