import { Plural, Trans } from '@lingui/macro'
import { Chip, type ChipProps, IconSubsidiary, Tooltip, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { type OwnerChartNodeEntityFragment, type ShareholderFragment } from '~/graphqlTypes'

export const OwnershipSubsidiariesChip = ({
  className,
  entity,
  ...props
}: { entity: ShareholderFragment | OwnerChartNodeEntityFragment } & ChipProps): ReactNode => {
  if (!('numOwnerships' in entity)) return null
  if (!entity.numOwnerships) return null

  const tooltipContent = (
    <div>
      <Plural value={entity.numOwnerships} one='# subsidiary' other='# subsidiaries' />{' '}
      <Trans>with {'>'}50% ownership</Trans>
    </div>
  )

  return (
    <Tooltip content={tooltipContent}>
      <span>
        <Chip
          className={cn('my-0 ml-1 mr-0 px-2', className)}
          startIcon={<IconSubsidiary size='md' />}
          label={entity.numOwnerships}
          {...props}
        />
      </span>
    </Tooltip>
  )
}
