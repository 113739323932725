import { cn } from '../../utils/className'
import { createContext, useContext } from '@strise/react-utils'
import {
  type HTMLAttributes,
  type ReactNode,
  type TdHTMLAttributes,
  type ThHTMLAttributes,
  forwardRef,
  useMemo
} from 'react'

type TableVariant = 'default' | 'backplate'

interface TableContextProps {
  variant: TableVariant
}

const TableContext = createContext<TableContextProps>()
TableContext.displayName = 'TableContext'

export interface TableProps extends HTMLAttributes<HTMLTableElement> {
  className?: string
  variant?: TableVariant
  wrapperClassName?: string
}

const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ className, variant = 'default', wrapperClassName, ...props }, ref): ReactNode => {
    const contextValue = useMemo(
      () => ({
        variant
      }),
      [variant]
    )

    return (
      <TableContext.Provider value={contextValue}>
        <div className={cn('relative w-full overflow-auto', wrapperClassName)}>
          <table
            ref={ref}
            className={cn('w-full caption-bottom text-sm', className)}
            data-variant={variant}
            {...props}
          />
        </div>
      </TableContext.Provider>
    )
  }
)
Table.displayName = 'Table'

const TableHeader = forwardRef<HTMLTableSectionElement, TableSectionProps>(
  ({ className, ...props }, ref): ReactNode => (
    <thead ref={ref} className={cn('[&_tr]:border-b', className)} {...props} />
  )
)
TableHeader.displayName = 'TableHeader'

const TableBody = forwardRef<HTMLTableSectionElement, TableSectionProps>(
  ({ className, ...props }, ref): ReactNode => (
    <tbody ref={ref} className={cn('[&_tr:last-child]:border-0', className)} {...props} />
  )
)
TableBody.displayName = 'TableBody'

const TableFooter = forwardRef<HTMLTableSectionElement, TableSectionProps>(
  ({ className, ...props }, ref): ReactNode => (
    <tfoot ref={ref} className={cn('border-t font-medium [&>tr]:last:border-b-0', className)} {...props} />
  )
)
TableFooter.displayName = 'TableFooter'

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ className, ...props }, ref): ReactNode => (
    <tr ref={ref} className={cn('border-b border-gray-20 transition-colors hover:bg-gray-5', className)} {...props} />
  )
)
TableRow.displayName = 'TableRow'

export interface TableHeadProps extends ThHTMLAttributes<HTMLTableCellElement> {
  className?: string
}

const TableHead = forwardRef<HTMLTableCellElement, TableHeadProps>(({ className, ...props }, ref): ReactNode => {
  const { variant } = useContext(TableContext)

  return (
    <th
      ref={ref}
      className={cn(
        'p-4 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0',
        variant === 'backplate' && 'bg-gray-90 text-md text-white',
        variant === 'default' && 'text-text-primary',
        className
      )}
      {...props}
    />
  )
})
TableHead.displayName = 'TableHead'

const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ className, ...props }, ref): ReactNode => (
    <td ref={ref} className={cn('p-4 align-top [&:has([role=checkbox])]:pr-0', className)} {...props} />
  )
)
TableCell.displayName = 'TableCell'

const TableCaption = forwardRef<HTMLTableCaptionElement, TableCaptionProps>(
  ({ className, ...props }, ref): ReactNode => (
    <caption ref={ref} className={cn('mt-4 text-sm text-gray-30', className)} {...props} />
  )
)
TableCaption.displayName = 'TableCaption'

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption }

export interface TableSectionProps extends HTMLAttributes<HTMLTableSectionElement> {
  className?: string
}

export interface TableCellProps extends TdHTMLAttributes<HTMLTableCellElement> {
  className?: string
}

export interface TableCaptionProps extends HTMLAttributes<HTMLTableCaptionElement> {
  className?: string
}

export interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  className?: string
}
