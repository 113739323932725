import { Trans } from '@lingui/macro'
import { isRateLimitError } from '@strise/app-shared'
import { useContext } from '@strise/react-utils'
import { Button, IconFilter, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import {
  CurrentUserSettingsContext,
  useCurrentUserEnabledContentLanguages,
  useCurrentUserFeatures
} from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { GrowSuggestedCompanies } from '~/features/Grow/GrowSuggestedCompanies'
import { userGrowSettingsToInput } from '~/features/Settings/settingsUtils'
import { useSimilarCompaniesQuery } from '~/graphqlOperations'
import { ContentViews, SidepanelTab } from '~/utils/urls'
import { useActiveContentView } from '~/utils/viewsHooks'

export const SidepanelGrow = forwardRef<HTMLDivElement>(({ ...props }, ref): ReactNode => {
  const currentPath = useActiveContentView()
  const pathname = currentPath ? location.pathname.replace(currentPath, ContentViews.Grow) : `/${ContentViews.Grow}`

  const features = useCurrentUserFeatures()
  const enabledContentLanguages = useCurrentUserEnabledContentLanguages()
  const { id: companyId } = useContext(SidepanelContext)
  const { settings } = useContext(CurrentUserSettingsContext)

  const settingsInput = userGrowSettingsToInput(settings)

  const { data, error, loading } = useSimilarCompaniesQuery({
    variables: {
      // @ts-expect-error
      eventsCacheKey: 'sidepanel-opportunity-events',
      company: companyId,
      size: 15,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      settings: settingsInput.opportunities!,
      eventLanguages: enabledContentLanguages
    },
    skip: !features.OPPORTUNITIES || !settingsInput.opportunities,
    fetchPolicy: 'cache-and-network'
  })
  const companies = (data?.company.similarCompanies.edges ?? []).map(({ node }) => node)

  if (!features.OPPORTUNITIES) return null

  return (
    <>
      <div ref={ref} data-tab={SidepanelTab.Grow} />
      <div className='mr-4 flex items-center justify-between p-4' {...props}>
        <Typography variant='h3'>
          <Trans>Grow</Trans>
        </Typography>
        <div className='ml-auto flex items-center text-text-primary'>
          <Button
            className='-mr-4'
            data-track='Sidepanel / Opportunities / Narrow opportunities'
            data-id='Sidepanel / Opportunities / Narrow opportunities'
            asChild
            variant='ghost'
            palette='primary'
            // TODO - fix all icons so that fill is used on the parent `svg` tag and not the `path` tag
            startIcon={<IconFilter className='mr-2 [&>*]:fill-primary-main' />}
          >
            <NavLink
              to={{
                pathname
              }}
            >
              <Trans>Filter in Grow</Trans>
            </NavLink>
          </Button>
        </div>
      </div>
      <GrowSuggestedCompanies
        companies={companies}
        exclude={[companyId]}
        loading={loading}
        rateLimited={isRateLimitError(error)}
        trackContext='sidepanel'
      />
    </>
  )
})
