import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconExport = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m12 .586 6.707 6.707-1.414 1.414L13 4.414V16h-2V4.414L6.707 8.707 5.293 7.293 12 .586ZM3 21v-9H1v11h22V12h-2v9H3Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconExport.displayName = 'IconExport'
