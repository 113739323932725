import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconEmployees = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M11 6c0 .388-.044.765-.128 1.128a5.016 5.016 0 0 1 2.256 0A5 5 0 1 1 21 10c1.214.912 1.999 2.364 1.999 4v3h-6.528A5.978 5.978 0 0 1 18 21v2H6v-2c0-1.537.578-2.939 1.528-4H1v-3c0-1.636.785-3.088 2-4a5 5 0 1 1 8-4ZM6 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm5 11.83a3.001 3.001 0 1 1 2.002-5.66A3.001 3.001 0 0 1 11 14.83ZM6 11c.375 0 .734.069 1.065.194A4.978 4.978 0 0 0 8 15H3v-1a3 3 0 0 1 3-3Zm11 1a4.978 4.978 0 0 1-1 3h5v-1a3 3 0 0 0-4.065-2.806c.043.263.065.532.065.806Zm1-9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-6 14a4 4 0 0 0-4 4h8a4 4 0 0 0-4-4Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconEmployees.displayName = 'IconEmployees'
