import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconUnderline = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 16 16'
      {...props}
    >
      <g fill='#454545' fillRule='evenodd' clipPath='url(#IconUnderline_svg__a)' clipRule='evenodd'>
        <path d='M.68 5.444V.681h1.362v4.763a6.125 6.125 0 1 0 12.25 0V.681h1.36v4.763a7.486 7.486 0 0 1-14.971 0ZM.68 14.292h14.973v1.36H.68v-1.36Z' />
      </g>
      <defs>
        <clipPath id='IconUnderline_svg__a'>
          <path fill='#fff' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  )
)
IconUnderline.displayName = 'IconUnderline'
