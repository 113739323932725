import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconSparkline = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M23 4.442V23H1v-7.445l6.103-5.493 4.964 5.957 3.212-3.212L23 4.442Zm-2 5.116-4.279 4.635-4.788 4.788-5.036-6.043L3 16.445V21h18V9.558Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M14.586 5H13V3h5v5h-2V6.414l-4.293 4.293-1.414-1.414L14.586 5Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconSparkline.displayName = 'IconSparkline'
