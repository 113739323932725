import { t } from '@lingui/macro'
import { type DivProps, type DivPropsWithChildren } from '@strise/react-utils'
import { Divider, type DividerProps, Typography, cn } from '@strise/ui-components'
import { isString } from 'lodash-es'
import type { ReactNode } from 'react'

export type DetailProps = {
  contentContainerProps?: DivProps
  dividerProps?: DividerProps
  hideDivider?: boolean
  label?: ReactNode
  labelContainerProps?: DivProps
  showEmpty?: boolean
} & DivPropsWithChildren

export const Detail = ({
  children,
  className,
  contentContainerProps,
  dividerProps,
  hideDivider,
  label,
  labelContainerProps,
  showEmpty,
  ...props
}: DetailProps): ReactNode => {
  if (!children && !showEmpty) return null

  return (
    <>
      <div
        className={cn(
          'flex min-h-[48px] w-full legacy-xs:flex-col legacy-xs:items-stretch legacy-md:flex-row legacy-md:items-start',
          className
        )}
        {...props}
      >
        <div
          {...labelContainerProps}
          className={cn(
            'mr-3 mt-2 flex shrink-0 flex-col justify-center whitespace-normal text-text-secondary legacy-xs:mb-0 legacy-xs:min-h-0 legacy-xs:w-full legacy-md:mb-2 legacy-md:min-h-flags-chip legacy-md:w-1/3',
            labelContainerProps?.className
          )}
        >
          {isString(label) ? <Typography variant='aLabel'>{label}</Typography> : label}
        </div>
        <div
          {...contentContainerProps}
          className={cn(
            'flex min-h-flags-chip shrink-0 flex-col justify-center self-center whitespace-normal legacy-xs:w-full legacy-md:w-2/3',
            children ? 'text-inherit' : 'text-text-secondary',
            contentContainerProps?.className
          )}
        >
          {children && !isString(children) ? (
            children
          ) : (
            <Typography variant='body1' className={cn(children ? 'text-inherit' : 'text-text-secondary')}>
              {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
              {children ?? t`No data`}
            </Typography>
          )}
        </div>
      </div>
      {!hideDivider && <Divider {...dividerProps} />}
    </>
  )
}
