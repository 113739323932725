import { t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { IdentityVerificationStatus } from '@strise/types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@strise/ui-components'
import type { ReactNode } from 'react'
import { IDVUrl } from '~/features/IDVCheck/IDVUrl'
import { IdvCheckResetButton } from '~/features/IDVCheck/IdvCheckResetButton'

interface IDVCheckModalSendRequestProps {
  children: ReactNode
  name: string
  onUpdateStatus: (status: IdentityVerificationStatus) => Promise<void>
  onUrlCopy: () => void
  open: boolean
  setOpen: SetStateFn<boolean>
  statusLoading: IdentityVerificationStatus | null
  url: string | null
}

export const IDVCheckModalSendRequest = ({
  children,
  name,
  onUpdateStatus,
  onUrlCopy,
  open,
  setOpen,
  statusLoading,
  url
}: IDVCheckModalSendRequestProps): ReactNode => {
  const handleSubmitRequest = async (): Promise<void> => {
    await onUpdateStatus(IdentityVerificationStatus.PendingDocument)
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t`Send ID verification request`}</DialogTitle>
          <DialogDescription>
            <IDVUrl url={url} onUrlCopy={onUrlCopy} />
            {t`Share the following link with ${name} to request their ID verification.`}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className='sm:justify-between'>
          <IdvCheckResetButton loadingStatus={statusLoading} onUpdateStatus={onUpdateStatus} setOpen={setOpen} />
          <Button
            data-track='IDV Check / Mark as requested'
            onClick={handleSubmitRequest}
            loading={statusLoading === IdentityVerificationStatus.PendingDocument}
            disabled={!!statusLoading}
            className='rounded-sm border-[#403dff] bg-[#403dff] text-white hover:border-[#3937af] hover:bg-[#3937af]'
          >
            {t`Mark as requested`}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
