import { t } from '@lingui/macro'
import { toast, useIsSupervisor } from '@strise/app-shared'
import { copyTextToClipboard, getBrowserGlobals, usePersistentState } from '@strise/react-utils'
import { omniEntityUrl } from '@strise/ts-utils'
import type { MouseEvent } from 'react'
import { useCallback, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'
import { QUERY_PARAMS, STORAGE_KEYS } from '~/constants'
import { breakpoints } from '~/theme'

export const useIsMobile = (): boolean => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.md}px)`)
  return isMobile
}

export const useLocalStorageVersion = (version: string): void => {
  const [currentVersion, setCurrentVersion] = usePersistentState<string>(STORAGE_KEYS.localStorageVersion, version)

  useEffect(() => {
    if (!currentVersion) {
      setCurrentVersion(version)
    } else if (currentVersion !== version) {
      getBrowserGlobals()?.window.localStorage.clear()
      getBrowserGlobals()?.window.location.reload()
    }
  }, [])
}

export const useIntersectionRef = (
  options: IntersectionObserverInit,
  cb: IntersectionObserverCallback
): ((node: Element | null) => void) => {
  const observer = useRef<IntersectionObserver | null>(null)

  return useCallback(
    (node: Element | null) => {
      if (!getBrowserGlobals()?.window.IntersectionObserver) return

      if (!node) {
        if (observer.current) {
          observer.current.disconnect()
        }
        return
      }

      const browserGlobals = getBrowserGlobals()

      if (browserGlobals) {
        observer.current = new browserGlobals.window.IntersectionObserver(cb, options)
        observer.current.observe(node)
      }
    },
    [cb, options]
  )
}

interface UseCopyStringOpts {
  successMsg?: string
  trimSpaces?: boolean
}

export const copyString = (
  str: string,
  { successMsg = t`Copied to clipboard`, trimSpaces = false }: UseCopyStringOpts = {}
): ((event?: MouseEvent) => void) => {
  return (event?: MouseEvent) => {
    if (event) event.preventDefault()

    const trimmed = trimSpaces ? str.replaceAll(/\s/g, '') : str

    copyTextToClipboard(trimmed)

    if (successMsg) {
      toast.success(successMsg)
    }
  }
}

export const useAltClickToOmni = (id: string): ((e: MouseEvent) => void) => {
  const isSupervisor = useIsSupervisor()
  return useCallback(
    (e: MouseEvent) => {
      if (isSupervisor && e.altKey) {
        e.preventDefault()
        getBrowserGlobals()?.window.open(omniEntityUrl(id), '_blank')
      }
    },
    [id]
  )
}

export const useConfirmationBeforeUnload = (): void => {
  useEffect(() => {
    const handler = (event: BeforeUnloadEvent): string => {
      event.preventDefault()
      return (event.returnValue = 'Are you sure you want to exit?')
    }

    const globalWindow = getBrowserGlobals()?.window

    if (!globalWindow) return

    globalWindow.addEventListener('beforeunload', handler)
    return () => globalWindow.removeEventListener('beforeunload', handler)
  }, [])
}

export const useTriggerConflictModal = (): ((entityId: string, teamId?: string) => void) => {
  const [searchParams, setSearchParams] = useSearchParams()
  return (entityId: string, teamId?: string) => {
    const newParams = new URLSearchParams(searchParams)
    if (teamId) {
      newParams.set('team', teamId)
    }
    newParams.set(QUERY_PARAMS.conflictId, entityId)
    setSearchParams(newParams)
    // We need to do a full reload when changing teams
    if (teamId) {
      getBrowserGlobals()?.window.location.reload()
    }
  }
}
