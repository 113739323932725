import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconVerified = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 25 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M14.897 2.727 12.413 0 9.928 2.727l-3.515-1.12-.789 3.605L2.02 6l1.12 3.515L.413 12l2.727 2.485L2.02 18l3.604.788.789 3.604 3.515-1.12L12.413 24l2.484-2.727 3.516 1.12.788-3.605L22.805 18l-1.12-3.515L24.414 12l-2.727-2.485L22.805 6l-3.604-.788-.788-3.604-3.516 1.12Zm-3.147 14.33 6.181-8.99-1.648-1.134-4.819 7.01-2.65-2.65L7.4 12.707l4.35 4.35Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconVerified.displayName = 'IconVerified'
