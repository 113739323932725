import { t } from '@lingui/macro'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, cn } from '@strise/ui-components'
import { type ComponentProps, type ReactNode, useState } from 'react'
import { EntityIcon } from '~/components/EntityIcon'
import ExpandButton from '~/features/Ownerships/ControlCard/ExpandButton'
import OwnershipLevel from '~/features/Ownerships/ControlCard/OwnershipLevel'
import { type CompanyControlEntityFragment } from '~/graphqlTypes'

const StyledTableHead = ({ children }: ComponentProps<typeof TableHead>): ReactNode => (
  <TableHead className='text-text-secondary flex flex-col px-4 py-1'>{children}</TableHead>
)

const StyledTableCell = ({ children }: ComponentProps<typeof TableCell>): ReactNode => (
  <TableCell className='w-2/3 px-4 py-1'>{children}</TableCell>
)

const StyledTableRow = ({ children }: ComponentProps<typeof TableRow>): ReactNode => (
  <TableRow className='hover:bg-transparent border-none'>{children}</TableRow>
)

const OwnerControlAdditionalInformation = ({
  additionalInformation,
  owner
}: {
  additionalInformation: CompanyControlEntityFragment['additionalInformation']
  owner: CompanyControlEntityFragment
}): ReactNode => {
  const [isExpanded, setIsExpanded] = useState(false)

  if (additionalInformation.length === 0) {
    return null
  }

  const shareClassesRow = (): ReactNode => {
    if (!additionalInformation.some((info) => info.__typename === 'ShareClasses')) {
      return null
    }

    return (
      <StyledTableRow>
        <StyledTableHead>
          <Typography variant='aLabel'>{t`Share classes`}</Typography>
        </StyledTableHead>
        <StyledTableCell>
          <div className='flex flex-col gap-1'>
            {additionalInformation
              .filter((info) => info.__typename === 'ShareClasses')
              .map((info) =>
                info.shareClasses.map((shareClass) => (
                  <Typography key={shareClass.shareClass}>
                    {shareClass.shareClass}: {shareClass.share}
                    {shareClass.votingRights !== null &&
                      ` - ${shareClass.votingRights ? t`Voting rights` : t`No voting rights`}`}
                  </Typography>
                ))
              )}
          </div>
        </StyledTableCell>
      </StyledTableRow>
    )
  }

  const registryInformationRow = (): ReactNode => {
    if (!additionalInformation.some((info) => info.__typename === 'UboRegister')) {
      return null
    }

    return (
      <StyledTableRow>
        <StyledTableHead>
          <Typography variant='aLabel'>{t`Registry information`}</Typography>
        </StyledTableHead>
        <StyledTableCell>
          <div className='flex flex-col gap-1'>
            {additionalInformation
              .filter((info) => info.__typename === 'UboRegister')
              .map((info) => info.natureOfControl.map((control) => <Typography key={control}>{control}</Typography>))}
          </div>
        </StyledTableCell>
      </StyledTableRow>
    )
  }

  const controlPathRow = (): ReactNode => {
    if (!additionalInformation.some((info) => info.__typename === 'ControlPath')) {
      return null
    }

    return (
      <StyledTableRow>
        <StyledTableHead>
          <Typography variant='aLabel'>{t`Control chain`}</Typography>
        </StyledTableHead>
        <StyledTableCell>
          {additionalInformation
            .filter((info) => info.__typename === 'ControlPath')
            .map((info) => (
              <div key={owner.entity.id} className='flex flex-col gap-1'>
                <div className='flex items-center gap-2'>
                  <EntityIcon entity={owner.entity} size='sm' />
                  <Typography>{owner.entity.name}</Typography>
                  <OwnershipLevel level={info.path.length} />
                </div>
                {info.path.map((control, index) => (
                  <Typography key={index}>
                    {'↳ '} {control.name} <OwnershipLevel level={info.path.length - index - 1} />
                  </Typography>
                ))}
              </div>
            ))}
        </StyledTableCell>
      </StyledTableRow>
    )
  }

  const votingPowerRow = (): ReactNode => {
    if (!additionalInformation.some((info) => info.__typename === 'VotingPower')) {
      return null
    }

    return (
      <StyledTableRow>
        <StyledTableHead>
          <Typography variant='aLabel'>{t`Voting power`}</Typography>
          <Typography variant='aLabelSmall'>{t`Computed by Strise`}</Typography>
        </StyledTableHead>
        <StyledTableCell>
          {additionalInformation
            .filter((info) => info.__typename === 'VotingPower')
            .map((info) => (
              <Typography key={info.votingPower}>{info.votingPower}</Typography>
            ))}
        </StyledTableCell>
      </StyledTableRow>
    )
  }

  return (
    <div className={cn('p-2 rounded-md', { 'bg-blue-5': isExpanded })}>
      <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} dataTrack='Owner Details / View More' />
      {isExpanded && (
        <Table>
          <TableBody>
            {shareClassesRow()}
            {votingPowerRow()}
            {registryInformationRow()}
            {controlPathRow()}
          </TableBody>
        </Table>
      )}
    </div>
  )
}

export default OwnerControlAdditionalInformation
