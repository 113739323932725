import * as Types from './graphqlTypes';

import { gql } from '@apollo/client/index.js';
import * as Apollo from '@apollo/client/index.js';
const defaultOptions = {} as const;
export const SimpleUserFragmentDoc = gql`
    fragment SimpleUser on SimpleUser {
  id
  name
  avatar
  email
}
    `;
export const CompanyUserConnectionEdgeFragmentDoc = gql`
    fragment CompanyUserConnectionEdge on CompanyUserConnectionEdge {
  node {
    id
    ...SimpleUser
  }
}
    ${SimpleUserFragmentDoc}`;
export const CompanyUserConnectionFragmentDoc = gql`
    fragment CompanyUserConnection on CompanyUserConnection {
  edges {
    ...CompanyUserConnectionEdge
  }
}
    ${CompanyUserConnectionEdgeFragmentDoc}`;
export const AssigneeCompanyEdgeFragmentDoc = gql`
    fragment AssigneeCompanyEdge on PortfolioCompanyConnectionEdge {
  node {
    id
    assignee(team: $team) {
      ...CompanyUserConnection
    }
  }
}
    ${CompanyUserConnectionFragmentDoc}`;
export const AssigneeResponseFragmentDoc = gql`
    fragment AssigneeResponse on MutationQuery {
  team(id: $team) {
    id
    portfolio(id: $portfolio) {
      id
      companies(filter: {companies: $companies}) {
        edges {
          ...AssigneeCompanyEdge
        }
      }
    }
  }
}
    ${AssigneeCompanyEdgeFragmentDoc}`;
export const CompanyNotificationSettingsFragmentDoc = gql`
    fragment CompanyNotificationSettings on Company {
  id
  notificationSetting(team: $team) {
    user {
      id
      ...SimpleUser
    }
    status
    created
  }
}
    ${SimpleUserFragmentDoc}`;
export const EntityAccessFragmentDoc = gql`
    fragment EntityAccess on EntityAccess {
  hasAccess
  description
}
    `;
export const CountryFragmentDoc = gql`
    fragment Country on Country {
  id
  englishName
  isoAlpha2Code
  kind
}
    `;
export const LifeStatusFragmentDoc = gql`
    fragment LifeStatus on CompanyLifeStatus {
  isActive
  date
  lifeStatus
}
    `;
export const KeyEntityMetaFragmentDoc = gql`
    fragment KeyEntityMeta on KeyEntityMeta {
  label
  content
  iconId
  copyValue
  tooltip
}
    `;
export const CompanyBaseFragmentDoc = gql`
    fragment CompanyBase on Company {
  id
  name
  primaryCountry {
    ...Country
  }
  lifeStatus {
    ...LifeStatus
  }
  keyMetaV2 {
    ...KeyEntityMeta
  }
  access {
    ...EntityAccess
  }
  isGlobalCompany
  isSlim
}
    ${CountryFragmentDoc}
${LifeStatusFragmentDoc}
${KeyEntityMetaFragmentDoc}
${EntityAccessFragmentDoc}`;
export const GlobalCompanyBaseFragmentDoc = gql`
    fragment GlobalCompanyBase on GlobalCompany {
  id
  name
  registerCountry
  primaryCountry {
    ...Country
  }
  lifeStatus {
    ...LifeStatus
  }
  keyMetaV2 {
    ...KeyEntityMeta
  }
  access {
    ...EntityAccess
  }
}
    ${CountryFragmentDoc}
${LifeStatusFragmentDoc}
${KeyEntityMetaFragmentDoc}
${EntityAccessFragmentDoc}`;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
  addressLine
  city
  zipCode
  country
  countryV2 {
    ...Country
  }
}
    ${CountryFragmentDoc}`;
export const PersonBaseFragmentDoc = gql`
    fragment PersonBase on Person {
  id
  name
  gender
  countries {
    ...Country
  }
  primaryCountry {
    ...Country
  }
  keyMetaV2 {
    ...KeyEntityMeta
  }
  access {
    ...EntityAccess
  }
  birthDateV2
  address {
    ...Address
  }
  isGlobalPerson
}
    ${CountryFragmentDoc}
${KeyEntityMetaFragmentDoc}
${EntityAccessFragmentDoc}
${AddressFragmentDoc}`;
export const GlobalPersonBaseFragmentDoc = gql`
    fragment GlobalPersonBase on GlobalPerson {
  id
  name
  pep
  primaryCountry {
    ...Country
  }
  keyMetaV2 {
    ...KeyEntityMeta
  }
  access {
    ...EntityAccess
  }
  birthDate
  address {
    ...Address
  }
}
    ${CountryFragmentDoc}
${KeyEntityMetaFragmentDoc}
${EntityAccessFragmentDoc}
${AddressFragmentDoc}`;
export const PrivatePersonBaseFragmentDoc = gql`
    fragment PrivatePersonBase on PrivatePerson {
  id
  name
  keyMetaV2 {
    ...KeyEntityMeta
  }
  access {
    ...EntityAccess
  }
  gender
  birthDate
  birthYear
  addresses: address {
    ...Address
  }
  location
  citizenship {
    ...Country
  }
}
    ${KeyEntityMetaFragmentDoc}
${EntityAccessFragmentDoc}
${AddressFragmentDoc}
${CountryFragmentDoc}`;
export const BaseEntityLikeFragmentDoc = gql`
    fragment BaseEntityLike on EntityLike {
  id
  name
  access {
    ...EntityAccess
  }
  ... on Company {
    ...CompanyBase
  }
  ... on GlobalCompany {
    ...GlobalCompanyBase
  }
  ... on Person {
    ...PersonBase
  }
  ... on GlobalPerson {
    ...GlobalPersonBase
  }
  ... on PrivatePerson {
    ...PrivatePersonBase
  }
}
    ${EntityAccessFragmentDoc}
${CompanyBaseFragmentDoc}
${GlobalCompanyBaseFragmentDoc}
${PersonBaseFragmentDoc}
${GlobalPersonBaseFragmentDoc}
${PrivatePersonBaseFragmentDoc}`;
export const LegalFormFragmentDoc = gql`
    fragment LegalForm on LegalForm {
  id
  name
  code
  countryV2
  kind
  hasShares
  hasBoard
  hasOwner
  hasBeneficialOwners
  hasPartners
  hasNorwegianSoleTraderCreditData
  hasCollateral
  hasShareClasses
  hasCreditReport
}
    `;
export const CustomMetaFragmentDoc = gql`
    fragment CustomMeta on CustomMeta {
  createdBy {
    __typename
    id
    name
  }
  createdAt
  lastModifiedAt
  comment
  hidden
  merged
  lastModifiedBy {
    __typename
    id
    name
  }
}
    `;
export const TimespanFragmentDoc = gql`
    fragment Timespan on Timespan {
  from
  to
}
    `;
export const RoleMetaFragmentDoc = gql`
    fragment RoleMeta on RoleMeta {
  roleTitle
  roleTitleDescription
  roleTitleId
  isActive
  customMeta {
    ...CustomMeta
  }
  period {
    ...Timespan
  }
  prettyTimespan
  prettyDuration
}
    ${CustomMetaFragmentDoc}
${TimespanFragmentDoc}`;
export const RoleConflictPartFragmentDoc = gql`
    fragment RoleConflictPart on RoleConflictPart {
  entity {
    id
    ...BaseEntityLike
  }
  roleMeta {
    ...RoleMeta
  }
}
    ${BaseEntityLikeFragmentDoc}
${RoleMetaFragmentDoc}`;
export const OwnershipConflictPartFragmentDoc = gql`
    fragment OwnershipConflictPart on OwnershipConflictPart {
  entity {
    id
    ...BaseEntityLike
  }
}
    ${BaseEntityLikeFragmentDoc}`;
export const ConflictFragmentDoc = gql`
    fragment Conflict on ConflictLike {
  id
  entity {
    id
    ...BaseEntityLike
    ... on Company {
      legalForm {
        ...LegalForm
      }
      showOwnerChart
    }
  }
  reason
  resolved
  resolvedSelection
  resolvedBy {
    ...SimpleUser
  }
  ... on RoleConflictV2 {
    customPart {
      ...RoleConflictPart
    }
    conflictingPart {
      ...RoleConflictPart
    }
  }
  ... on OwnershipConflict {
    customPart {
      ...OwnershipConflictPart
    }
    conflictingPart {
      ...OwnershipConflictPart
    }
  }
}
    ${BaseEntityLikeFragmentDoc}
${LegalFormFragmentDoc}
${SimpleUserFragmentDoc}
${RoleConflictPartFragmentDoc}
${OwnershipConflictPartFragmentDoc}`;
export const ConnectedAppFragmentDoc = gql`
    fragment ConnectedApp on ConnectedApp {
  id
  kind
}
    `;
export const ShareClassesFragmentDoc = gql`
    fragment ShareClasses on ShareClasses {
  shareClasses {
    shareClass
    share
    votingRights
  }
}
    `;
export const ControlMetaFragmentDoc = gql`
    fragment ControlMeta on ControlMeta {
  ... on UboRegister {
    natureOfControl
  }
  ... on ControlPath {
    path {
      ...BaseEntityLike
    }
  }
  ... on ShareClasses {
    ...ShareClasses
  }
  ... on VotingPower {
    votingPower
  }
}
    ${BaseEntityLikeFragmentDoc}
${ShareClassesFragmentDoc}`;
export const CompanyControlEntityFragmentDoc = gql`
    fragment CompanyControlEntity on CompanyControlEntity {
  entity {
    ...BaseEntityLike
  }
  ownershipMax
  reasons
  keyTags {
    ... on DefaultTag {
      label
      value
    }
    ... on FamilyMembersTag {
      label
      value
      relations {
        person {
          ...BaseEntityLike
        }
        translatedRelationships
      }
    }
  }
  additionalInformation {
    ...ControlMeta
  }
  customMeta {
    ...CustomMeta
  }
}
    ${BaseEntityLikeFragmentDoc}
${ControlMetaFragmentDoc}
${CustomMetaFragmentDoc}`;
export const CompanyControlOwnersFragmentDoc = gql`
    fragment CompanyControlOwners on CompanyControlOwners {
  entities {
    ...CompanyControlEntity
  }
  totalCount
  totalOwnership
}
    ${CompanyControlEntityFragmentDoc}`;
export const ControlDetailsFragmentDoc = gql`
    fragment ControlDetails on CompanyControl {
  beneficialOwners {
    ...CompanyControlOwners
  }
  owners {
    ...CompanyControlOwners
  }
  shareClasses {
    ...ShareClasses
  }
}
    ${CompanyControlOwnersFragmentDoc}
${ShareClassesFragmentDoc}`;
export const CounterPartyFragmentDoc = gql`
    fragment CounterParty on CounterParty {
  id
  counterPartyEntity {
    ...BaseEntityLike
  }
  direction
  comment
  createdBy {
    ...SimpleUser
  }
  lastModified
}
    ${BaseEntityLikeFragmentDoc}
${SimpleUserFragmentDoc}`;
export const TeamCompanyStatusFragmentDoc = gql`
    fragment TeamCompanyStatus on TeamCompanyStatus {
  status
  previousStatus
  modifiedAt
  modifiedBy {
    id
    name
  }
  modifiedByKind
}
    `;
export const CompanyTagFragmentDoc = gql`
    fragment CompanyTag on Sticker {
  id
  name
}
    `;
export const CompanyTagConnectionEdgeFragmentDoc = gql`
    fragment CompanyTagConnectionEdge on CompanyStickerConnectionEdge {
  node {
    id
    ...CompanyTag
  }
}
    ${CompanyTagFragmentDoc}`;
export const CompanyTagConnectionFragmentDoc = gql`
    fragment CompanyTagConnection on CompanyStickerConnection {
  key
  edges {
    ...CompanyTagConnectionEdge
  }
}
    ${CompanyTagConnectionEdgeFragmentDoc}`;
export const CompanyTagsFragmentDoc = gql`
    fragment CompanyTags on Company {
  id
  tags: stickers(team: $team) {
    ...CompanyTagConnection
  }
}
    ${CompanyTagConnectionFragmentDoc}`;
export const CompanyMetaFragmentDoc = gql`
    fragment CompanyMeta on Company {
  id
  ...CompanyBase
  statusV2(team: $team, portfolio: $portfolio) {
    ...TeamCompanyStatus
  }
  ...CompanyTags
  assignee(team: $team) {
    ...CompanyUserConnection
  }
  legalForm {
    ...LegalForm
  }
  showOwnerChart
}
    ${CompanyBaseFragmentDoc}
${TeamCompanyStatusFragmentDoc}
${CompanyTagsFragmentDoc}
${CompanyUserConnectionFragmentDoc}
${LegalFormFragmentDoc}`;
export const SidepanelCounterpartiesFragmentDoc = gql`
    fragment SidepanelCounterparties on Company {
  id
  counterParties(team: $team) {
    ...CounterParty
  }
  ...CompanyMeta
}
    ${CounterPartyFragmentDoc}
${CompanyMetaFragmentDoc}`;
export const AnnotatedFragmentDoc = gql`
    fragment Annotated on Annotation {
  id
  annotated {
    id
  }
}
    `;
export const AnnotationFragmentDoc = gql`
    fragment Annotation on Annotation {
  id
  length
  phrase
  extractors
  annotator
  offset {
    ... on TitleOffset {
      offset
    }
    ... on BodyOffset {
      offset
    }
  }
  ...Annotated
}
    ${AnnotatedFragmentDoc}`;
export const BaseEventFragmentDoc = gql`
    fragment BaseEvent on Event {
  id
  title
  published
  publisher
  behindPaywall
  images {
    url
  }
  companyEventKinds
  flagSeverity
}
    `;
export const BaseEventWithSummaryFragmentDoc = gql`
    fragment BaseEventWithSummary on Event {
  ...BaseEvent
  summary {
    text
  }
}
    ${BaseEventFragmentDoc}`;
export const EventFragmentDoc = gql`
    fragment Event on Event {
  ...BaseEventWithSummary
  liked(portfolio: $portfolio, team: $team, company: $entity)
}
    ${BaseEventWithSummaryFragmentDoc}`;
export const TopicFragmentDoc = gql`
    fragment Topic on Topic {
  id
  name
}
    `;
export const TopicConnectionFragmentDoc = gql`
    fragment TopicConnection on TopicConnection {
  edges {
    node {
      id
      ...Topic
    }
  }
}
    ${TopicFragmentDoc}`;
export const EventEdgeFragmentDoc = gql`
    fragment EventEdge on EntityLikeEventConnectionEdge {
  node {
    id
    ...Event
  }
  cursor
  summary {
    text
  }
  topics {
    ...TopicConnection
  }
  includedCompaniesMentions {
    id
    ...BaseEntityLike
  }
  relevant
}
    ${EventFragmentDoc}
${TopicConnectionFragmentDoc}
${BaseEntityLikeFragmentDoc}`;
export const FinancialsRowFragmentDoc = gql`
    fragment FinancialsRow on FinancialsRow {
  values
  afterUnderline
  fieldInfo {
    id
  }
}
    `;
export const FinancialsSectionFragmentDoc = gql`
    fragment FinancialsSection on FinancialsSection {
  key
  name
  rows {
    ...FinancialsRow
  }
}
    ${FinancialsRowFragmentDoc}`;
export const FinancialsMetaFragmentDoc = gql`
    fragment FinancialsMeta on FinancialStatementMeta {
  year
  periodTitle
  currency
  notes
  auditorComment
  auditorClarification
}
    `;
export const FinancialsFragmentDoc = gql`
    fragment Financials on Financials {
  sections {
    ...FinancialsSection
  }
  meta {
    ...FinancialsMeta
  }
  operatingRevenues
  compare {
    ...FinancialsSection
  }
  highlightsEnabled
}
    ${FinancialsSectionFragmentDoc}
${FinancialsMetaFragmentDoc}`;
export const FinancialStatementsFragmentDoc = gql`
    fragment FinancialStatements on Company {
  id
  financials(size: 5, consolidated: false) {
    ...Financials
  }
  consolidatedFinancials: financials(size: 5, consolidated: true) {
    ...Financials
  }
}
    ${FinancialsFragmentDoc}`;
export const CompareCompanyFinancialsFragmentDoc = gql`
    fragment CompareCompanyFinancials on Company {
  id
  corporatePurpose
  ...CompanyMeta
  ...FinancialStatements
}
    ${CompanyMetaFragmentDoc}
${FinancialStatementsFragmentDoc}`;
export const AuditTrailEntryFragmentDoc = gql`
    fragment AuditTrailEntry on AuditTrailEntry {
  date
  action
  target
  metadata {
    name
    value
  }
  user {
    ...SimpleUser
  }
  comment
}
    ${SimpleUserFragmentDoc}`;
export const IdvDocumentInfoFragmentDoc = gql`
    fragment IdvDocumentInfo on IdentityVerificationDocumentInformation {
  name
  reference
  contentType
  size
}
    `;
export const IdvRequestFragmentDoc = gql`
    fragment IdvRequest on IdentityVerificationRequest {
  id
  status
  url
  documentNotifiedAt
  documentCollectedAt
  linkExpiresAt
}
    `;
export const IdvRoleFragmentDoc = gql`
    fragment IdvRole on IdentityVerificationRole {
  person {
    ...PersonBase
  }
  idvRequest {
    ...IdvRequest
  }
}
    ${PersonBaseFragmentDoc}
${IdvRequestFragmentDoc}`;
export const IndustryFragmentDoc = gql`
    fragment Industry on Industry {
  id
  name
  description
  country
}
    `;
export const IndustryWithChildrenFragmentDoc = gql`
    fragment IndustryWithChildren on Industry {
  id
  ...Industry
  children: childrenV2(countries: $countries) {
    edges {
      node {
        id
        ...Industry
      }
    }
  }
}
    ${IndustryFragmentDoc}`;
export const CountryOrCollectionFragmentDoc = gql`
    fragment CountryOrCollection on Location {
  id
  name
  kind
  documentationUrl
}
    `;
export const RegionFragmentDoc = gql`
    fragment Region on Location {
  id
  name
}
    `;
export const DefaultLocationFragmentDoc = gql`
    fragment DefaultLocation on Location {
  id
  name
  regions {
    edges {
      node {
        id
        ...Region
      }
    }
  }
}
    ${RegionFragmentDoc}`;
export const BaseNotificationFragmentDoc = gql`
    fragment BaseNotification on Notification {
  id
  status
}
    `;
export const BaseNotificationConnectionFragmentDoc = gql`
    fragment BaseNotificationConnection on NotificationConnection {
  unreadCount
  edges {
    node {
      id
      ...BaseNotification
    }
  }
}
    ${BaseNotificationFragmentDoc}`;
export const NotificationFragmentDoc = gql`
    fragment Notification on Notification {
  id
  title
  status
  kind
  body
  message
  created
  category
  image
  url
  entity {
    ...BaseEntityLike
  }
  flag {
    id
  }
  team {
    id
    name
  }
  otherUser {
    id
    email
  }
}
    ${BaseEntityLikeFragmentDoc}`;
export const NotificationConnectionFragmentDoc = gql`
    fragment NotificationConnection on NotificationConnection {
  unreadCount
  edges {
    node {
      id
      ...Notification
    }
  }
}
    ${NotificationFragmentDoc}`;
export const GrowSuggestedCompanyFragmentDoc = gql`
    fragment GrowSuggestedCompany on Company {
  id
  ...CompanyMeta
  ...FinancialStatements
  corporatePurpose
  events(
    settings: {events: {languages: $eventLanguages, time: {since: MONTH}, sources: {eventTypes: [SALES_TRIGGERS]}, sort: RECENCY}, first: 2}
    portfolio: $portfolio
    team: $team
  ) {
    edges {
      node {
        id
        ...BaseEventWithSummary
      }
      summary {
        text
      }
    }
  }
}
    ${CompanyMetaFragmentDoc}
${FinancialStatementsFragmentDoc}
${BaseEventWithSummaryFragmentDoc}`;
export const SimpleCompanyBaseFragmentDoc = gql`
    fragment SimpleCompanyBase on SimpleCompany {
  id
  name
  lifeStatus {
    ...LifeStatus
  }
  access {
    ...EntityAccess
  }
}
    ${LifeStatusFragmentDoc}
${EntityAccessFragmentDoc}`;
export const SimpleCompanyMetaFragmentDoc = gql`
    fragment SimpleCompanyMeta on SimpleCompany {
  id
  ...SimpleCompanyBase
  organizationNumber
  registerCountry
  primaryCountry {
    id
    ...Country
  }
}
    ${SimpleCompanyBaseFragmentDoc}
${CountryFragmentDoc}`;
export const GlobalCompanyMetaFragmentDoc = gql`
    fragment GlobalCompanyMeta on GlobalCompany {
  id
  ...GlobalCompanyBase
}
    ${GlobalCompanyBaseFragmentDoc}`;
export const PersonMetaFragmentDoc = gql`
    fragment PersonMeta on Person {
  id
  ...PersonBase
}
    ${PersonBaseFragmentDoc}`;
export const GlobalPersonMetaFragmentDoc = gql`
    fragment GlobalPersonMeta on GlobalPerson {
  id
  ...GlobalPersonBase
}
    ${GlobalPersonBaseFragmentDoc}`;
export const PrivatePersonMetaFragmentDoc = gql`
    fragment PrivatePersonMeta on PrivatePerson {
  id
  ...PrivatePersonBase
}
    ${PrivatePersonBaseFragmentDoc}`;
export const EntityLikeMetaFragmentDoc = gql`
    fragment EntityLikeMeta on EntityLike {
  id
  ...BaseEntityLike
  ... on Company {
    ...CompanyMeta
  }
  ... on SimpleCompany {
    ...SimpleCompanyMeta
  }
  ... on GlobalCompany {
    ...GlobalCompanyMeta
  }
  ... on Person {
    ...PersonMeta
  }
  ... on GlobalPerson {
    ...GlobalPersonMeta
  }
  ... on PrivatePerson {
    ...PrivatePersonMeta
  }
}
    ${BaseEntityLikeFragmentDoc}
${CompanyMetaFragmentDoc}
${SimpleCompanyMetaFragmentDoc}
${GlobalCompanyMetaFragmentDoc}
${PersonMetaFragmentDoc}
${GlobalPersonMetaFragmentDoc}
${PrivatePersonMetaFragmentDoc}`;
export const AdverseFlagFragmentDoc = gql`
    fragment AdverseFlag on AdverseFlag {
  id
  date
  reason
  severity
  entity {
    ...BaseEntityLike
  }
  event {
    id
  }
}
    ${BaseEntityLikeFragmentDoc}`;
export const EntityFlagsFragmentDoc = gql`
    fragment EntityFlags on EntityLike {
  id
  flags {
    edges {
      node {
        id
        ...AdverseFlag
      }
    }
  }
}
    ${AdverseFlagFragmentDoc}`;
export const OwnerChartNodeEntityFragmentDoc = gql`
    fragment OwnerChartNodeEntity on EntityLike {
  id
  ...EntityLikeMeta
  ...EntityFlags @include(if: $withFlags)
  ... on Company {
    numOwnerships(from: 50)
  }
  ... on Person {
    numOwnerships(from: 50)
  }
  ... on GlobalCompany {
    numOwnerships(from: 50)
  }
  ... on GlobalPerson {
    numOwnerships(from: 50)
  }
}
    ${EntityLikeMetaFragmentDoc}
${EntityFlagsFragmentDoc}`;
export const BeneficialOwnerMetaFragmentDoc = gql`
    fragment BeneficialOwnerMeta on BeneficialOwnerMeta {
  reason
  familyRelationships {
    person {
      ...BaseEntityLike
    }
    translatedRelationships
  }
  meta {
    name
    value
  }
}
    ${BaseEntityLikeFragmentDoc}`;
export const OwnerChartNodeFragmentDoc = gql`
    fragment OwnerChartNode on OwnershipChartNode {
  id
  name
  indirectShare
  indirectShareValue
  isLeaf
  shareClasses {
    shareClass
    share
  }
  entity {
    id
    ...OwnerChartNodeEntity
  }
  beneficialOwnerMeta {
    ...BeneficialOwnerMeta
  }
  isMerged
}
    ${OwnerChartNodeEntityFragmentDoc}
${BeneficialOwnerMetaFragmentDoc}`;
export const OwnerChartEdgeFragmentDoc = gql`
    fragment OwnerChartEdge on OwnershipChartEdge {
  child
  parent
  share
  customMeta {
    ...CustomMeta
  }
}
    ${CustomMetaFragmentDoc}`;
export const OwnerChartFragmentDoc = gql`
    fragment OwnerChart on OwnershipChart {
  nodes {
    id
    ...OwnerChartNode
  }
  edges {
    ...OwnerChartEdge
  }
  hasCustomOwners
  lastModifiedAt
}
    ${OwnerChartNodeFragmentDoc}
${OwnerChartEdgeFragmentDoc}`;
export const TableLabelFragmentDoc = gql`
    fragment TableLabel on TableLabelLike {
  isActive
  ... on TableLabelString {
    content
  }
  ... on TableLabelEntity {
    entity {
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
    description
  }
  ... on TableLabelRole {
    name
    roleTitleId
  }
  ... on TableLabelEntityRole {
    entity {
      ...BaseEntityLike
    }
  }
}
    ${BaseEntityLikeFragmentDoc}
${EntityFlagsFragmentDoc}`;
export const TableRowValueFragmentDoc = gql`
    fragment TableRowValue on TableRowValueLike {
  link
  tooltip
  copyValue
  iconId
  ... on TableRowValueString {
    content
  }
  ... on TableRowValueLabeledString {
    label
    content
  }
  ... on TableRowValueRelatedEntity {
    entity {
      id
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
  }
  ... on TableRowValueOwnership {
    maybeEntity: entity {
      id
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
    entityName
    ownership: ownershipV2
    uboMeta {
      ...BeneficialOwnerMeta
    }
    shareClasses {
      shareClass
      share
    }
    modifiedAt
    customMeta {
      ...CustomMeta
    }
  }
  ... on TableRowValueRoleWithEntity {
    detailedRoles
    roleMetas {
      ...RoleMeta
    }
    entity {
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
  }
  ... on TableRowValueRole {
    roleMeta {
      ...RoleMeta
    }
  }
  ... on TableRowValueRoleForPerson {
    roleMeta {
      ...RoleMeta
    }
    roleEntity {
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
  }
}
    ${BaseEntityLikeFragmentDoc}
${EntityFlagsFragmentDoc}
${BeneficialOwnerMetaFragmentDoc}
${CustomMetaFragmentDoc}
${RoleMetaFragmentDoc}`;
export const TableRowSectionFragmentDoc = gql`
    fragment TableRowSection on TableRowSection {
  label {
    ...TableLabel
  }
  paginationThreshold
  values {
    ...TableRowValue
  }
}
    ${TableLabelFragmentDoc}
${TableRowValueFragmentDoc}`;
export const TableRowFragmentDoc = gql`
    fragment TableRow on TableRow {
  key
  label {
    ...TableLabel
  }
  kind
  paginationThreshold
  sections {
    ...TableRowSection
  }
}
    ${TableLabelFragmentDoc}
${TableRowSectionFragmentDoc}`;
export const DataSourceFragmentDoc = gql`
    fragment DataSource on DataSource {
  name
  link
  lastUpdatedAt
}
    `;
export const TableFragmentDoc = gql`
    fragment Table on Table {
  title
  description
  rows {
    ...TableRow
  }
  dataSources {
    ...DataSource
  }
  hasCustomData
  lastModifiedAt
}
    ${TableRowFragmentDoc}
${DataSourceFragmentDoc}`;
export const OwnersFragmentDoc = gql`
    fragment Owners on EntityLike {
  id
  ... on Company {
    ownerChart(
      team: $team
      threshold: 5
      ignoreCustomOwnerships: $ignoreCustomOwnerships
    ) {
      ...OwnerChart
    }
    ownerChart25: ownerChart(
      team: $team
      threshold: 25
      ignoreCustomOwnerships: $ignoreCustomOwnerships
    ) {
      ...OwnerChart
    }
    owners5: owners(
      team: $team
      threshold: 5
      ignoreCustomOwnerships: $ignoreCustomOwnerships
    ) {
      ...Table
    }
    owners25: owners(
      team: $team
      threshold: 25
      ignoreCustomOwnerships: $ignoreCustomOwnerships
    ) {
      ...Table
    }
  }
  ... on GlobalCompany {
    ownerChart(
      team: $team
      threshold: 5
      ignoreCustomOwnerships: $ignoreCustomOwnerships
    ) {
      ...OwnerChart
    }
    ownerChart25: ownerChart(
      team: $team
      threshold: 25
      ignoreCustomOwnerships: $ignoreCustomOwnerships
    ) {
      ...OwnerChart
    }
    owners5: owners(
      team: $team
      threshold: 5
      ignoreCustomOwnerships: $ignoreCustomOwnerships
    ) {
      ...Table
    }
    owners25: owners(
      team: $team
      threshold: 25
      ignoreCustomOwnerships: $ignoreCustomOwnerships
    ) {
      ...Table
    }
  }
}
    ${OwnerChartFragmentDoc}
${TableFragmentDoc}`;
export const ShareholderFragmentDoc = gql`
    fragment Shareholder on Shareholder {
  ... on Company {
    id
    ...CompanyMeta
    numOwnerships(from: 0)
  }
  ... on GlobalCompany {
    id
    ...GlobalCompanyMeta
    numOwnerships(from: 0)
  }
}
    ${CompanyMetaFragmentDoc}
${GlobalCompanyMetaFragmentDoc}`;
export const ShareholderConnectionEdgeFragmentDoc = gql`
    fragment ShareholderConnectionEdge on ShareholderConnectionEdge {
  sharePercentage
  node {
    ...Shareholder
  }
  isUncertain
}
    ${ShareholderFragmentDoc}`;
export const ShareholderConnectionFragmentDoc = gql`
    fragment ShareholderConnection on ShareholderConnection {
  edges {
    ...ShareholderConnectionEdge
  }
}
    ${ShareholderConnectionEdgeFragmentDoc}`;
export const PepRoleFragmentDoc = gql`
    fragment PepRole on PepRole {
  active
  roleCategory
  country {
    ...Country
  }
  localizedRoleDescription
  from
  to
}
    ${CountryFragmentDoc}`;
export const BasePepInfoFragmentDoc = gql`
    fragment BasePepInfo on PepInfo {
  id
  name
  pep
  rca
  address {
    ...Address
  }
  roles {
    ...PepRole
  }
}
    ${AddressFragmentDoc}
${PepRoleFragmentDoc}`;
export const MatchInfoFragmentDoc = gql`
    fragment MatchInfo on MatchInfo {
  name
  description
  kind
  kindName
}
    `;
export const MatchInfoAnalysisFragmentDoc = gql`
    fragment MatchInfoAnalysis on MatchInfoAnalysis {
  matchInfos {
    ...MatchInfo
  }
  algorithm
  threshold
  result
  suggestedTruePositive
}
    ${MatchInfoFragmentDoc}`;
export const EntityDispositionFragmentDoc = gql`
    fragment EntityDisposition on EntityDisposition {
  id
  status
  createdBy {
    ...SimpleUser
  }
  created
  comment
}
    ${SimpleUserFragmentDoc}`;
export const PepInfoFragmentDoc = gql`
    fragment PepInfo on PepInfo {
  ...BasePepInfo
  birthDate
  birthDateV2
  gender
  matchInfoAnalysis {
    ...MatchInfoAnalysis
  }
  disposition {
    ...EntityDisposition
  }
  countries {
    ...Country
  }
  relations {
    from
    relationDescription
    relatedPep {
      ...BasePepInfo
    }
  }
  source
  customMeta {
    ...CustomMeta
  }
}
    ${BasePepInfoFragmentDoc}
${MatchInfoAnalysisFragmentDoc}
${EntityDispositionFragmentDoc}
${CountryFragmentDoc}
${CustomMetaFragmentDoc}`;
export const PersonPepInfoFragmentDoc = gql`
    fragment PersonPepInfo on Person {
  id
  pepInfo(team: $team) @include(if: $includePepV1) {
    ...PepInfo
  }
  pepInfos: pepInfoV2(team: $team) @include(if: $includePepV2) {
    ...PepInfo
  }
}
    ${PepInfoFragmentDoc}`;
export const PrivatePersonPepInfoFragmentDoc = gql`
    fragment PrivatePersonPepInfo on PrivatePerson {
  id
  pepInfo(team: $team) {
    ...PepInfo
  }
}
    ${PepInfoFragmentDoc}`;
export const SimpleUserConnectionEdgeFragmentDoc = gql`
    fragment SimpleUserConnectionEdge on SimpleUserConnectionEdge {
  node {
    id
    ...SimpleUser
  }
}
    ${SimpleUserFragmentDoc}`;
export const SimpleUserConnectionFragmentDoc = gql`
    fragment SimpleUserConnection on SimpleUserConnection {
  edges {
    ...SimpleUserConnectionEdge
  }
}
    ${SimpleUserConnectionEdgeFragmentDoc}`;
export const RevenueFragmentDoc = gql`
    fragment Revenue on SimpleFinancials {
  currency
  operatingRevenue
}
    `;
export const PortfolioCompanyFragmentDoc = gql`
    fragment PortfolioCompany on SimpleCompany {
  id
  ...SimpleCompanyMeta
  tags: stickers @include(if: $tags) {
    ...CompanyTagConnection
  }
  assignee @include(if: $assignees) {
    ...SimpleUserConnection
  }
  flags {
    edges {
      node {
        id
        ...AdverseFlag
      }
    }
  }
  financials(corporate: false) @include(if: $revenue) {
    ...Revenue
  }
  groupFinancials: financials(corporate: true) @include(if: $revenue) {
    ...Revenue
  }
  employees: numberOfEmployees @include(if: $employees)
  statusModified @include(if: $statusModified)
  statusV2 {
    ...TeamCompanyStatus
  }
  headquartersLocation @include(if: $hq) {
    id
    name
  }
}
    ${SimpleCompanyMetaFragmentDoc}
${CompanyTagConnectionFragmentDoc}
${SimpleUserConnectionFragmentDoc}
${AdverseFlagFragmentDoc}
${RevenueFragmentDoc}
${TeamCompanyStatusFragmentDoc}`;
export const CompletedMetricFragmentDoc = gql`
    fragment CompletedMetric on PortfolioHealthReviewMetric {
  teamCount
  userCount
  userPercentageChange
}
    `;
export const ReviewMetricsFragmentDoc = gql`
    fragment ReviewMetrics on PortfolioHealth {
  reviewMetrics {
    completedMetric {
      ...CompletedMetric
    }
    highPriorityMetric {
      ...CompletedMetric
    }
    pendingMetric {
      ...CompletedMetric
    }
    upcomingMetric {
      ...CompletedMetric
    }
  }
}
    ${CompletedMetricFragmentDoc}`;
export const SegmentsFragmentDoc = gql`
    fragment Segments on PortfolioHealthRiskSegment {
  name
  color
  count
  percentage
}
    `;
export const RiskDistributionFragmentDoc = gql`
    fragment RiskDistribution on PortfolioHealth {
  riskDistribution {
    segments {
      ...Segments
    }
    totalCount
  }
}
    ${SegmentsFragmentDoc}`;
export const RiskFactorsFragmentDoc = gql`
    fragment RiskFactors on PortfolioHealth {
  riskFactors {
    pepCount
    sanctionCount
    rcaCount
    pepInHighRiskCountryCount
    highRiskCountryCount
    highRiskIndustryCount
  }
}
    `;
export const ReminderFragmentDoc = gql`
    fragment Reminder on Reminder {
  id
  time
  completedAt
  overdue
  status
  created
  user {
    ...SimpleUser
  }
}
    ${SimpleUserFragmentDoc}`;
export const CompanyRemindersFragmentDoc = gql`
    fragment CompanyReminders on Company {
  id
  reminders(team: $team, status: $statusFilter) {
    edges {
      node {
        id
        ...Reminder
      }
    }
  }
}
    ${ReminderFragmentDoc}`;
export const ReviewFragmentDoc = gql`
    fragment Review on Review {
  id
  status
  risk
  customRiskLevel {
    id
    label
    color
  }
  comment
  user {
    ...SimpleUser
  }
  companyStatus
  created
  fileName
}
    ${SimpleUserFragmentDoc}`;
export const BaseReviewCompanyFragmentDoc = gql`
    fragment BaseReviewCompany on Company {
  ...CompanyMeta
  reviews(team: $team) {
    edges {
      node {
        id
        ...Review
      }
    }
  }
}
    ${CompanyMetaFragmentDoc}
${ReviewFragmentDoc}`;
export const ReviewAddressFragmentDoc = gql`
    fragment ReviewAddress on ReviewAddress {
  addressLine
  zipCode
  city
  country
}
    `;
export const ReviewCountryFragmentDoc = gql`
    fragment ReviewCountry on ReviewCountry {
  id
  name
  isoAlpha2Code
  englishName
}
    `;
export const ReviewPepRoleFragmentDoc = gql`
    fragment ReviewPepRole on ReviewPepRole {
  from
  to
  title
  category
  country {
    ...ReviewCountry
  }
  isActive
}
    ${ReviewCountryFragmentDoc}`;
export const ReviewPepBaseFragmentDoc = gql`
    fragment ReviewPepBase on ReviewPep {
  id
  entityName
  isPep
  isRca
  address {
    ...ReviewAddress
  }
  roles {
    ...ReviewPepRole
  }
}
    ${ReviewAddressFragmentDoc}
${ReviewPepRoleFragmentDoc}`;
export const ReviewEntityFragmentDoc = gql`
    fragment ReviewEntity on ReviewEntity {
  id
  name
  access {
    hasAccess
    description
  }
  url
  relation
  primaryCountry {
    ...ReviewCountry
  }
  countries {
    ...ReviewCountry
  }
  birthDate
  address {
    ...ReviewAddress
  }
  gender
  isGlobalPerson
  isGlobalCompany
  isSlim
}
    ${ReviewCountryFragmentDoc}
${ReviewAddressFragmentDoc}`;
export const ReviewUserFragmentDoc = gql`
    fragment ReviewUser on ReviewUser {
  id
  name
  email
  avatar
}
    `;
export const ReviewCustomMetaFragmentDoc = gql`
    fragment ReviewCustomMeta on ReviewCustomMeta {
  createdBy {
    ...ReviewUser
  }
  createdAt
  lastModifiedBy {
    ...ReviewUser
  }
  lastModifiedAt
  hidden
  merged
  comment
}
    ${ReviewUserFragmentDoc}`;
export const ReviewPepFragmentDoc = gql`
    fragment ReviewPep on ReviewPep {
  ...ReviewPepBase
  birthDate
  birthDateV2
  gender
  countries {
    ...ReviewCountry
  }
  entity {
    ...ReviewEntity
  }
  relatedPersons {
    pep {
      ...ReviewPepBase
    }
    from
    relationType
  }
  disposition {
    id
    status
    created
    createdBy {
      ...ReviewUser
    }
    comment
  }
  matchInfoAnalysis {
    matchInfos {
      name
      description
      kind
      kindName
    }
    algorithm
    threshold
    result
    suggestedTruePositive
  }
  reason
  source
  customMeta {
    ...ReviewCustomMeta
  }
}
    ${ReviewPepBaseFragmentDoc}
${ReviewCountryFragmentDoc}
${ReviewEntityFragmentDoc}
${ReviewUserFragmentDoc}
${ReviewCustomMetaFragmentDoc}`;
export const ReviewValueMetaFragmentDoc = gql`
    fragment ReviewValueMeta on ReviewValueMeta {
  status
  reason
}
    `;
export const ReviewPepValueFragmentDoc = gql`
    fragment ReviewPepValue on ReviewPepValue {
  value {
    ...ReviewPep
  }
  values {
    ...ReviewPep
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewPepFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewSanctionFragmentDoc = gql`
    fragment ReviewSanction on ReviewSanction {
  entity {
    ...ReviewEntity
  }
  entityName
  isSanctioned
  reason
  sanctionedBy
  lastUpdated
  sanctionedSince
  sourceLinks
  sourceJson
}
    ${ReviewEntityFragmentDoc}`;
export const ReviewSanctionValueFragmentDoc = gql`
    fragment ReviewSanctionValue on ReviewSanctionValue {
  reviewMeta {
    ...ReviewValueMeta
  }
  value {
    ...ReviewSanction
  }
}
    ${ReviewValueMetaFragmentDoc}
${ReviewSanctionFragmentDoc}`;
export const ReviewEventFragmentDoc = gql`
    fragment ReviewEvent on ReviewEvent {
  id
  title
  publishedDate
  publisher
  url
  amsTopics
  externalUrl
}
    `;
export const ReviewEventValueFragmentDoc = gql`
    fragment ReviewEventValue on ReviewEventValue {
  reviewMeta {
    ...ReviewValueMeta
  }
  value {
    ...ReviewEvent
  }
}
    ${ReviewValueMetaFragmentDoc}
${ReviewEventFragmentDoc}`;
export const ReviewPrivatePersonDataFragmentDoc = gql`
    fragment ReviewPrivatePersonData on ReviewPrivatePersonData {
  id
  url
  name
  peps {
    ...ReviewPepValue
  }
  sanctions {
    ...ReviewSanctionValue
  }
  ams {
    ...ReviewEventValue
  }
}
    ${ReviewPepValueFragmentDoc}
${ReviewSanctionValueFragmentDoc}
${ReviewEventValueFragmentDoc}`;
export const PrivatePersonReviewStatusFragmentDoc = gql`
    fragment PrivatePersonReviewStatus on PrivatePersonReviewStatus {
  status
  createdBy {
    ...SimpleUser
  }
  created
}
    ${SimpleUserFragmentDoc}`;
export const PrivatePersonReviewFragmentDoc = gql`
    fragment PrivatePersonReview on PrivatePersonReview {
  id
  risk
  customRiskLevel {
    id
    label
    color
  }
  comment
  user {
    ...SimpleUser
  }
  created
  fileName
}
    ${SimpleUserFragmentDoc}`;
export const ReviewPrivatePersonFragmentDoc = gql`
    fragment ReviewPrivatePerson on PrivatePerson {
  ...PrivatePersonBase
  reviewStatus {
    ...PrivatePersonReviewStatus
  }
  reviews(team: $team) {
    ...PrivatePersonReview
  }
}
    ${PrivatePersonBaseFragmentDoc}
${PrivatePersonReviewStatusFragmentDoc}
${PrivatePersonReviewFragmentDoc}`;
export const ReviewStringValueFragmentDoc = gql`
    fragment ReviewStringValue on ReviewStringValue {
  value
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewValueMetaFragmentDoc}`;
export const ReviewBranchFragmentDoc = gql`
    fragment ReviewBranch on ReviewBranch {
  name
  address
  numberOfEmployees
  industry
  organizationNumber
}
    `;
export const ReviewBranchValueFragmentDoc = gql`
    fragment ReviewBranchValue on ReviewBranchValue {
  value {
    ...ReviewBranch
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewBranchFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewRoleMetaFragmentDoc = gql`
    fragment ReviewRoleMeta on ReviewRoleMeta {
  roleTitleId
  roleTitle
  isActive
  timespan
  duration
  roleTitleDescription
  from
  to
  customMeta {
    ...ReviewCustomMeta
  }
}
    ${ReviewCustomMetaFragmentDoc}`;
export const ReviewDetailedRoleFragmentDoc = gql`
    fragment ReviewDetailedRole on ReviewDetailedRole {
  roleMetas {
    ...ReviewRoleMeta
  }
  entity {
    ...ReviewEntity
  }
  otherRoles {
    company {
      ...ReviewEntity
    }
    roles {
      ...ReviewRoleMeta
    }
  }
  otherIndustries
}
    ${ReviewRoleMetaFragmentDoc}
${ReviewEntityFragmentDoc}`;
export const ReviewDetailedRoleValueFragmentDoc = gql`
    fragment ReviewDetailedRoleValue on ReviewDetailedRoleValue {
  value {
    ...ReviewDetailedRole
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewDetailedRoleFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewRoleFragmentDoc = gql`
    fragment ReviewRole on ReviewRole {
  roleMetas {
    ...ReviewRoleMeta
  }
  entity {
    ...ReviewEntity
  }
}
    ${ReviewRoleMetaFragmentDoc}
${ReviewEntityFragmentDoc}`;
export const ReviewRoleValueFragmentDoc = gql`
    fragment ReviewRoleValue on ReviewRoleValue {
  value {
    ...ReviewRole
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewRoleFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewRoleSectionFragmentDoc = gql`
    fragment ReviewRoleSection on ReviewRoleSection {
  roleTitle
  roles {
    ...ReviewRoleValue
  }
}
    ${ReviewRoleValueFragmentDoc}`;
export const ReviewOwnerFragmentDoc = gql`
    fragment ReviewOwner on ReviewOwner {
  entity {
    ...ReviewEntity
  }
  entityName
  ownershipPercentage
  shareClasses
  beneficialOwnershipMeta {
    reason
    familyRelationships {
      entity {
        ...ReviewEntity
      }
      translatedRelationships
    }
    meta {
      name
      value
    }
  }
  customMeta {
    ...ReviewCustomMeta
  }
}
    ${ReviewEntityFragmentDoc}
${ReviewCustomMetaFragmentDoc}`;
export const ReviewOwnerValueFragmentDoc = gql`
    fragment ReviewOwnerValue on ReviewOwnerValue {
  value {
    ...ReviewOwner
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewOwnerFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewIdvRequestFragmentDoc = gql`
    fragment ReviewIdvRequest on ReviewIdvRequest {
  id
  status
  url
  documentNotifiedAt
  documentCollectedAt
  linkExpiresAt
}
    `;
export const ReviewIdvRoleFragmentDoc = gql`
    fragment ReviewIdvRole on ReviewIdvRole {
  person {
    ...ReviewEntity
  }
  idvRequest {
    ...ReviewIdvRequest
  }
}
    ${ReviewEntityFragmentDoc}
${ReviewIdvRequestFragmentDoc}`;
export const ReviewIdvRoleValueFragmentDoc = gql`
    fragment ReviewIdvRoleValue on ReviewIdvRoleValue {
  value {
    ...ReviewIdvRole
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewIdvRoleFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewSubsidiaryFragmentDoc = gql`
    fragment ReviewSubsidiary on ReviewSubsidiary {
  entity {
    ...ReviewEntity
  }
  ownershipPercentage
}
    ${ReviewEntityFragmentDoc}`;
export const ReviewSubsidiaryValueFragmentDoc = gql`
    fragment ReviewSubsidiaryValue on ReviewSubsidiaryValue {
  value {
    ...ReviewSubsidiary
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewSubsidiaryFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewFinancialRowFragmentDoc = gql`
    fragment ReviewFinancialRow on ReviewFinancialRow {
  title
  values
  afterUnderline
}
    `;
export const ReviewFinancialSectionFragmentDoc = gql`
    fragment ReviewFinancialSection on ReviewFinancialSection {
  title
  rows {
    ...ReviewFinancialRow
  }
}
    ${ReviewFinancialRowFragmentDoc}`;
export const ReviewFinancialsFragmentDoc = gql`
    fragment ReviewFinancials on ReviewFinancials {
  periods
  currencies
  sections {
    ...ReviewFinancialSection
  }
}
    ${ReviewFinancialSectionFragmentDoc}`;
export const ReviewFinancialsValueFragmentDoc = gql`
    fragment ReviewFinancialsValue on ReviewFinancialsValue {
  value {
    ...ReviewFinancials
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewFinancialsFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewAuditorCommentFragmentDoc = gql`
    fragment ReviewAuditorComment on ReviewAuditorComment {
  year
  comment
}
    `;
export const ReviewAuditorCommentValueFragmentDoc = gql`
    fragment ReviewAuditorCommentValue on ReviewAuditorCommentValue {
  value {
    ...ReviewAuditorComment
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewAuditorCommentFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewCreditScoreFragmentDoc = gql`
    fragment ReviewCreditScore on ReviewCreditScore {
  id
  date
  ratingValue
  internationalRating
  creditLimit
  contractLimit
  currencyCode
  dbt
  industryDbt
  averageInvoiceValue
  paymentsOnFile
  paymentsPaid
  paymentsStillOwing
  unsettledRemarksCount
  unsettledRemarksSum
  partiallySettledRemarksCount
  partiallySettledRemarksSum
  collateralCount
  collateralSum
  incomes {
    value {
      incomeYears
      municipalityCodes
      grossIncomes
      taxClasses
      sumTaxes
      netIncomes
      netWealths
    }
    reviewMeta {
      ...ReviewValueMeta
    }
  }
  isMonitored
  hasPaymentRemarks
}
    ${ReviewValueMetaFragmentDoc}`;
export const ReviewCreditScoreValueFragmentDoc = gql`
    fragment ReviewCreditScoreValue on ReviewCreditScoreValue {
  value {
    ...ReviewCreditScore
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewCreditScoreFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewPaymentRemarkValueFragmentDoc = gql`
    fragment ReviewPaymentRemarkValue on ReviewPaymentRemarkValue {
  value {
    id
    date
    amount
    creditor {
      ...ReviewEntity
    }
    creditorName
    kind
    partiallySettled
    referenceNumber
    source
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewEntityFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewCcjFragmentDoc = gql`
    fragment ReviewCCJ on ReviewCCJ {
  caseNumber
  amount
  date
  paidDate
  status
  court
  incomingRecordDetails
  isExactMatch
}
    `;
export const ReviewCcjValueFragmentDoc = gql`
    fragment ReviewCCJValue on ReviewCCJValue {
  value {
    ...ReviewCCJ
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewCcjFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewRegisterFragmentDoc = gql`
    fragment ReviewRegister on ReviewRegister {
  name
  isRegistered
  status
  externalId
  date
  event {
    ...ReviewEvent
  }
}
    ${ReviewEventFragmentDoc}`;
export const ReviewRegisterValueFragmentDoc = gql`
    fragment ReviewRegisterValue on ReviewRegisterValue {
  value {
    ...ReviewRegister
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewRegisterFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewFlagFragmentDoc = gql`
    fragment ReviewFlag on ReviewFlag {
  id
  date
  severity
  reason
  entity {
    ...ReviewEntity
  }
  event {
    ...ReviewEvent
  }
}
    ${ReviewEntityFragmentDoc}
${ReviewEventFragmentDoc}`;
export const ReviewFlaggedEventValueFragmentDoc = gql`
    fragment ReviewFlaggedEventValue on ReviewFlaggedEventValue {
  value {
    ...ReviewFlag
  }
  reviewMeta {
    ...ReviewValueMeta
  }
}
    ${ReviewFlagFragmentDoc}
${ReviewValueMetaFragmentDoc}`;
export const ReviewAmsSectionFragmentDoc = gql`
    fragment ReviewAmsSection on ReviewAmsSection {
  entity {
    ...ReviewEntity
  }
  entityName
  events {
    ...ReviewEventValue
  }
}
    ${ReviewEntityFragmentDoc}
${ReviewEventValueFragmentDoc}`;
export const ReviewCustomCheckboxFragmentDoc = gql`
    fragment ReviewCustomCheckbox on ReviewCustomCheckboxV2 {
  name
  description
}
    `;
export const ReviewAlertFragmentDoc = gql`
    fragment ReviewAlert on ReviewAlert {
  kind
  meta {
    ...ReviewValueMeta
  }
}
    ${ReviewValueMetaFragmentDoc}`;
export const ReviewCompanyDataFragmentDoc = gql`
    fragment ReviewCompanyData on ReviewCompanyData {
  id
  includeSections
  excludeSections
  name
  url
  organizationNumber
  countries {
    ...ReviewStringValue
  }
  legalForm {
    ...ReviewStringValue
  }
  inceptionDate {
    ...ReviewStringValue
  }
  address {
    ...ReviewStringValue
  }
  shareCapital {
    ...ReviewStringValue
  }
  corporatePurpose {
    ...ReviewStringValue
  }
  numberOfEmployees {
    ...ReviewStringValue
  }
  industries {
    ...ReviewStringValue
  }
  previousNames {
    ...ReviewStringValue
  }
  shareClasses {
    ...ReviewStringValue
  }
  branches {
    ...ReviewBranchValue
  }
  peps {
    ...ReviewPepValue
  }
  sanctions {
    ...ReviewSanctionValue
  }
  innehavers {
    ...ReviewDetailedRoleValue
  }
  partners {
    ...ReviewDetailedRoleValue
  }
  ceos {
    ...ReviewRoleValue
  }
  chairpersons {
    ...ReviewRoleValue
  }
  otherBoardMembers {
    ...ReviewRoleSection
  }
  personsWithSignificantControl {
    ...ReviewRoleValue
  }
  otherRoles {
    ...ReviewRoleSection
  }
  beneficialOwners {
    ...ReviewOwnerValue
  }
  alternativeBeneficialOwners {
    ...ReviewOwnerValue
  }
  historicalRoles {
    ...ReviewRoleSection
  }
  otherOwners {
    ...ReviewOwnerValue
  }
  idvRoles {
    ...ReviewIdvRoleValue
  }
  subsidiaries {
    ...ReviewSubsidiaryValue
  }
  consolidatedFinancials {
    ...ReviewFinancialsValue
  }
  consolidatedAuditorComments {
    ...ReviewAuditorCommentValue
  }
  financials {
    ...ReviewFinancialsValue
  }
  auditorComments {
    ...ReviewAuditorCommentValue
  }
  creditScore {
    ...ReviewCreditScoreValue
  }
  paymentRemarks {
    ...ReviewPaymentRemarkValue
  }
  ccjs {
    ...ReviewCCJValue
  }
  registers {
    ...ReviewRegisterValue
  }
  companyFlaggedEvents {
    ...ReviewFlaggedEventValue
  }
  companyAms {
    ...ReviewEventValue
  }
  personsAms {
    ...ReviewAmsSection
  }
  boardGenderDiversity {
    ...ReviewStringValue
  }
  subjectToPublicProcurement {
    ...ReviewStringValue
  }
  customCheckboxes {
    ...ReviewCustomCheckbox
  }
  alerts {
    ...ReviewAlert
  }
  isGlobal
}
    ${ReviewStringValueFragmentDoc}
${ReviewBranchValueFragmentDoc}
${ReviewPepValueFragmentDoc}
${ReviewSanctionValueFragmentDoc}
${ReviewDetailedRoleValueFragmentDoc}
${ReviewRoleValueFragmentDoc}
${ReviewRoleSectionFragmentDoc}
${ReviewOwnerValueFragmentDoc}
${ReviewIdvRoleValueFragmentDoc}
${ReviewSubsidiaryValueFragmentDoc}
${ReviewFinancialsValueFragmentDoc}
${ReviewAuditorCommentValueFragmentDoc}
${ReviewCreditScoreValueFragmentDoc}
${ReviewPaymentRemarkValueFragmentDoc}
${ReviewCcjValueFragmentDoc}
${ReviewRegisterValueFragmentDoc}
${ReviewFlaggedEventValueFragmentDoc}
${ReviewEventValueFragmentDoc}
${ReviewAmsSectionFragmentDoc}
${ReviewCustomCheckboxFragmentDoc}
${ReviewAlertFragmentDoc}`;
export const RiskLevelFragmentDoc = gql`
    fragment RiskLevel on CustomRiskLevel {
  id
  label
  color
  sortingIndex
  archived
  createdBy {
    id
    name
  }
  created
  modifiedBy {
    id
    name
  }
  lastModified
}
    `;
export const EntityBusinessRoleMetaFragmentDoc = gql`
    fragment EntityBusinessRoleMeta on EntityLike {
  id
  ... on Company {
    id
    roleTableV2(
      team: $team
      filterActive: $filterActive
      ignoreCustomRoles: $ignoreCustomRoles
    ) {
      ...Table
    }
  }
  ... on Person {
    id
    roleTableV2(
      team: $team
      filterActive: $filterActive
      ignoreCustomRoles: $ignoreCustomRoles
    ) {
      ...Table
    }
  }
  ... on GlobalCompany {
    id
    roleTableV2(filterActive: $filterActive) {
      ...Table
    }
  }
  ... on GlobalPerson {
    id
    roleTableV2(filterActive: $filterActive) {
      ...Table
    }
  }
}
    ${TableFragmentDoc}`;
export const PaymentRemarkFragmentDoc = gql`
    fragment PaymentRemark on PaymentRemarkV2 {
  id
  date
  amount
  kind
  creditor {
    id
    ...BaseEntityLike
  }
  creditorName
  partiallySettled
  referenceNumber
  source
}
    ${BaseEntityLikeFragmentDoc}`;
export const CreditReportIncomeFragmentDoc = gql`
    fragment CreditReportIncome on CreditReportIncomeV2 {
  incomeYears
  netIncomes
  grossIncomes
  netWealths
  sumTaxes
  taxClasses
  municipalityCodes
}
    `;
export const CreditReportFragmentDoc = gql`
    fragment CreditReport on CreditReportV2 {
  id
  date
  ratingValue
  internationalRating
  creditLimit
  contractLimit
  dbt
  industryDbt
  averageInvoiceValue
  paymentsOnFile
  paymentsStillOwing
  paymentsPaid
  currencyCode
  hasPaymentRemarks
  unsettledRemarksCount
  unsettledRemarksSum
  partiallySettledRemarksCount
  partiallySettledRemarksSum
  collateralCount
  collateralSum
  isMonitored
  collateral {
    ...PaymentRemark
  }
  paymentRemarks {
    ...PaymentRemark
  }
  incomes {
    ...CreditReportIncome
  }
  totalDebtBalance
}
    ${PaymentRemarkFragmentDoc}
${CreditReportIncomeFragmentDoc}`;
export const CreditReportCompanyFragmentDoc = gql`
    fragment CreditReportCompany on Company {
  id
  creditReportV2(team: $team) {
    id
    ...CreditReport
  }
  ...CompanyMeta
}
    ${CreditReportFragmentDoc}
${CompanyMetaFragmentDoc}`;
export const CountyCourtJudgementFragmentDoc = gql`
    fragment CountyCourtJudgement on CountyCourtJudgementV2 {
  amount
  caseNumber
  court
  currencyCode
  date
  incomingRecordDetails
  isExactMatch
  paidDate
  status
}
    `;
export const CountyCourtJudgementsReportFragmentDoc = gql`
    fragment CountyCourtJudgementsReport on CountyCourtJudgementsReportV2 {
  date
  judgements {
    ...CountyCourtJudgement
  }
}
    ${CountyCourtJudgementFragmentDoc}`;
export const CountyCourtJudgementsReportCompanyFragmentDoc = gql`
    fragment CountyCourtJudgementsReportCompany on Company {
  id
  ccjReportV2(team: $team) {
    ...CountyCourtJudgementsReport
  }
  ...CompanyMeta
}
    ${CountyCourtJudgementsReportFragmentDoc}
${CompanyMetaFragmentDoc}`;
export const RealEstateFragmentDoc = gql`
    fragment RealEstate on RealEstate {
  info
  warnings {
    title
    description
    date
  }
  documents {
    id
    title
    tooltip
    date
    amount
    currency
    amountTooltip
    creditors {
      ...BaseEntityLike
    }
    otherCreditorNames
  }
  date
}
    ${BaseEntityLikeFragmentDoc}`;
export const RegisterFragmentDoc = gql`
    fragment Register on Register {
  name
  date
  statusDescription
  isRegistered
  registerId
  event {
    id
  }
}
    `;
export const EntityDataSourcesFragmentDoc = gql`
    fragment EntityDataSources on EntityDataSources {
  baseInfo {
    ...DataSource
  }
  financials {
    ...DataSource
  }
  collateral {
    ...DataSource
  }
  realEstate {
    ...DataSource
  }
  credit {
    ...DataSource
  }
  shareholders {
    ...DataSource
  }
  roles {
    ...DataSource
  }
  sanctions {
    ...DataSource
  }
  peps {
    ...DataSource
  }
}
    ${DataSourceFragmentDoc}`;
export const CollateralFragmentDoc = gql`
    fragment Collateral on Collateral {
  creditor {
    id
    ...BaseEntityLike
  }
  creditorName
  amount
  currency
  date
  description
}
    ${BaseEntityLikeFragmentDoc}`;
export const CompanyBranchFragmentDoc = gql`
    fragment CompanyBranch on CompanyBranch {
  name
  businessNumber
  url
  address {
    ...Address
  }
  numberOfEmployees
  industry {
    id
    ...Industry
  }
  activeFrom
  activeTo
  isActive
}
    ${AddressFragmentDoc}
${IndustryFragmentDoc}`;
export const LocationFragmentDoc = gql`
    fragment Location on Location {
  id
  name
  description
  documentationUrl
  countries: countriesV2 {
    id
    ...Country
  }
}
    ${CountryFragmentDoc}`;
export const SidepanelCompanyFragmentDoc = gql`
    fragment SidepanelCompany on Company {
  id
  ...CompanyMeta
  companyInformation {
    ...Table
  }
  coordinates
  corporatePurpose
  ...FinancialStatements
  ...CreditReportCompany @include(if: $includeCreditReport)
  ...CountyCourtJudgementsReportCompany @include(if: $includeCreditReport)
  realEstates @include(if: $includeRealEstates) {
    ...RealEstate
  }
  notificationSetting(team: $team) {
    status
  }
  registers {
    edges {
      node {
        ...Register
      }
    }
  }
  dataSources {
    ...EntityDataSources
  }
  collaterals {
    edges {
      node {
        ...Collateral
      }
    }
  }
  branches {
    edges {
      node {
        ...CompanyBranch
      }
    }
  }
  organizationNumberV2 {
    value
  }
  primaryCountry {
    ...Country
  }
  headquartersLocation {
    ...Location
  }
}
    ${CompanyMetaFragmentDoc}
${TableFragmentDoc}
${FinancialStatementsFragmentDoc}
${CreditReportCompanyFragmentDoc}
${CountyCourtJudgementsReportCompanyFragmentDoc}
${RealEstateFragmentDoc}
${RegisterFragmentDoc}
${EntityDataSourcesFragmentDoc}
${CollateralFragmentDoc}
${CompanyBranchFragmentDoc}
${CountryFragmentDoc}
${LocationFragmentDoc}`;
export const SidepanelPersonFragmentDoc = gql`
    fragment SidepanelPerson on Person {
  id
  ...PersonMeta
  personInformation {
    ...Table
  }
  dataSources {
    ...EntityDataSources
  }
}
    ${PersonMetaFragmentDoc}
${TableFragmentDoc}
${EntityDataSourcesFragmentDoc}`;
export const SidepanelPrivatePersonFragmentDoc = gql`
    fragment SidepanelPrivatePerson on PrivatePerson {
  id
  ...PrivatePersonMeta
  personInformation {
    ...Table
  }
  isMonitored(team: $team)
  matchingBusinessPersons {
    edges {
      node {
        ...PersonBase
      }
    }
  }
  dataSources {
    ...EntityDataSources
  }
}
    ${PrivatePersonMetaFragmentDoc}
${TableFragmentDoc}
${PersonBaseFragmentDoc}
${EntityDataSourcesFragmentDoc}`;
export const SidepanelGlobalPersonFragmentDoc = gql`
    fragment SidepanelGlobalPerson on GlobalPerson {
  id
  ...GlobalPersonMeta
  personInformation {
    ...Table
  }
  dataSources {
    ...EntityDataSources
  }
}
    ${GlobalPersonMetaFragmentDoc}
${TableFragmentDoc}
${EntityDataSourcesFragmentDoc}`;
export const SidepanelGlobalCompanyFragmentDoc = gql`
    fragment SidepanelGlobalCompany on GlobalCompany {
  id
  ...GlobalCompanyMeta
  companyInformation {
    ...Table
  }
  description
  notificationSetting(team: $team) {
    status
  }
  dataSources {
    ...EntityDataSources
  }
}
    ${GlobalCompanyMetaFragmentDoc}
${TableFragmentDoc}
${EntityDataSourcesFragmentDoc}`;
export const SidepanelEntityFragmentDoc = gql`
    fragment SidepanelEntity on EntityLike {
  id
  ...EntityLikeMeta
  ... on Company {
    ...SidepanelCompany
  }
  ... on Person {
    ...SidepanelPerson
  }
  ... on PrivatePerson {
    ...SidepanelPrivatePerson
  }
  ... on GlobalPerson {
    ...SidepanelGlobalPerson
  }
  ... on GlobalCompany {
    ...SidepanelGlobalCompany
  }
}
    ${EntityLikeMetaFragmentDoc}
${SidepanelCompanyFragmentDoc}
${SidepanelPersonFragmentDoc}
${SidepanelPrivatePersonFragmentDoc}
${SidepanelGlobalPersonFragmentDoc}
${SidepanelGlobalCompanyFragmentDoc}`;
export const OrganizationTagsFragmentDoc = gql`
    fragment OrganizationTags on Team {
  id
  tags: stickers {
    edges {
      node {
        id
        ...CompanyTag
      }
    }
  }
}
    ${CompanyTagFragmentDoc}`;
export const ActivityStatFragmentDoc = gql`
    fragment ActivityStat on ActivityStat {
  totalCount
  counts
  percentageChange
}
    `;
export const ActivityStatsFragmentDoc = gql`
    fragment ActivityStats on ActivityStats {
  search {
    ...ActivityStat
  }
  prospect {
    ...ActivityStat
  }
  review {
    ...ActivityStat
  }
  completedTeamActivity {
    ...ActivityStat
  }
  createdTeamActivity {
    ...ActivityStat
  }
}
    ${ActivityStatFragmentDoc}`;
export const TeamActivityFragmentDoc = gql`
    fragment TeamActivity on TeamActivity {
  id
  note
  kind
  timestamp
  created
  assignee {
    ...SimpleUser
  }
  createdBy {
    ...SimpleUser
  }
  reminder {
    ...Reminder
  }
  entity {
    ...BaseEntityLike
  }
}
    ${SimpleUserFragmentDoc}
${ReminderFragmentDoc}
${BaseEntityLikeFragmentDoc}`;
export const CompanyTeamActivitiesFragmentDoc = gql`
    fragment CompanyTeamActivities on Company {
  id
  teamActivities(
    team: $team
    page: $page
    reminderStatus: $reminderStatus
    filter: $filter
  ) {
    edges {
      node {
        id
        ...TeamActivity
      }
    }
    totalCount
  }
}
    ${TeamActivityFragmentDoc}`;
export const ReviewStatementFilterKindFragmentDoc = gql`
    fragment ReviewStatementFilterKind on ReviewStatementFilterKind {
  id
  name
  countries
}
    `;
export const ReviewTriggerStatementInfoFragmentDoc = gql`
    fragment ReviewTriggerStatementInfo on ReviewTriggerStatementInfo {
  name
  kind
  operators
  defaultOperator
  valueKind
  optionalFilters(countries: $countries) {
    id
    ...ReviewStatementFilterKind
  }
  riskLevelIndicator
}
    ${ReviewStatementFilterKindFragmentDoc}`;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on SimpleUser {
  id
  name
  email
  avatar
}
    `;
export const RoleTitleFragmentDoc = gql`
    fragment RoleTitle on RoleTitle {
  id
  name
  country {
    ...Country
  }
}
    ${CountryFragmentDoc}`;
export const CompanyFinancialsMetaFragmentDoc = gql`
    fragment CompanyFinancialsMeta on Company {
  id
  metaFinancials: financials(size: 1, consolidated: false) {
    meta {
      year
      currency
    }
    operatingRevenues
  }
  metaConsolidatedFinancials: financials(size: 1, consolidated: true) {
    meta {
      year
      currency
    }
    operatingRevenues
  }
}
    `;
export const FinancialFieldInfoFragmentDoc = gql`
    fragment FinancialFieldInfo on FinancialFieldInfo {
  id
  name
  kind
  renderKind
  countryV2 {
    ...Country
  }
  isExpense
}
    ${CountryFragmentDoc}`;
export const AmsFragmentDoc = gql`
    fragment Ams on EntityLike {
  id
  ... on PrivatePerson {
    ams(team: $teamId) {
      ...BaseEventWithSummary
    }
  }
}
    ${BaseEventWithSummaryFragmentDoc}`;
export const ApplicationSettingsFragmentDoc = gql`
    fragment ApplicationSettings on ApplicationSettings {
  displayLanguage
  sidePanelFinancialHighlightsSelector
  opportunityFinancialHighlightsSelector
  opportunityFinancialHighlightCount
  timeZone
  useCaseKinds
  positionKind
}
    `;
export const BaseRateLimitFragmentDoc = gql`
    fragment BaseRateLimit on RateLimit {
  id
  used
  quota
  resetAt
}
    `;
export const SanctionInfoFragmentDoc = gql`
    fragment SanctionInfo on SanctionInfoV2 {
  lastUpdated
  sanctionedSince
  sourceLinks
  sourceJson
  sanctionedBy
  isSanctioned
}
    `;
export const CompanyNetworkEntityPersonFragmentDoc = gql`
    fragment CompanyNetworkEntityPerson on CompanyNetworkEntityPerson {
  person {
    ...BaseEntityLike
  }
  pep @include(if: $includePepV1) {
    ...PepInfo
  }
  peps @include(if: $includePepV2) {
    ...PepInfo
  }
  sanctions {
    ...SanctionInfo
  }
  associatedCompanies {
    ...BaseEntityLike
  }
}
    ${BaseEntityLikeFragmentDoc}
${PepInfoFragmentDoc}
${SanctionInfoFragmentDoc}`;
export const CompanyNetworkEntityCompanyFragmentDoc = gql`
    fragment CompanyNetworkEntityCompany on CompanyNetworkEntityCompany {
  company {
    ...BaseEntityLike
  }
  sanctions {
    ...SanctionInfo
  }
}
    ${BaseEntityLikeFragmentDoc}
${SanctionInfoFragmentDoc}`;
export const CompanyNetworkFragmentDoc = gql`
    fragment CompanyNetwork on CompanyNetwork {
  company {
    ...BaseEntityLike
    sanctionInfo(team: $team) {
      ...SanctionInfo
    }
  }
  allPersons {
    ...CompanyNetworkEntityPerson
  }
  otherCompanies {
    ...CompanyNetworkEntityCompany
  }
}
    ${BaseEntityLikeFragmentDoc}
${SanctionInfoFragmentDoc}
${CompanyNetworkEntityPersonFragmentDoc}
${CompanyNetworkEntityCompanyFragmentDoc}`;
export const TeamFragmentDoc = gql`
    fragment Team on Team {
  id
  name
  customer {
    id
  }
  portfolios {
    edges {
      node {
        id
      }
    }
  }
}
    `;
export const TeamEdgeFragmentDoc = gql`
    fragment TeamEdge on UserTeamConnectionEdge {
  node {
    id
    ...Team
  }
  role {
    id
    name
  }
  status
}
    ${TeamFragmentDoc}`;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
  id
  name
  email
  avatar
  teams {
    edges {
      ...TeamEdge
    }
  }
}
    ${TeamEdgeFragmentDoc}`;
export const EntitySanctionInfoFragmentDoc = gql`
    fragment EntitySanctionInfo on EntityLike {
  id
  name
  ... on Company {
    sanctionInfo(team: $teamId) {
      ...SanctionInfo
    }
  }
  ... on GlobalCompany {
    sanctionInfo(team: $teamId) {
      ...SanctionInfo
    }
  }
  ... on Person {
    sanctionInfo(team: $teamId) {
      ...SanctionInfo
    }
  }
  ... on GlobalPerson {
    sanctionInfo(team: $teamId) {
      ...SanctionInfo
    }
  }
  ... on PrivatePerson {
    sanctionInfo(team: $teamId) {
      ...SanctionInfo
    }
  }
}
    ${SanctionInfoFragmentDoc}`;
export const EventSettingsFragmentDoc = gql`
    fragment EventSettings on EventFilter {
  time {
    since
  }
  topics
  sources {
    eventTypes
  }
}
    `;
export const ListViewCompanySettingsFragmentDoc = gql`
    fragment ListViewCompanySettings on CompanyFilter {
  statuses
  employees {
    from
    to
  }
  locations
  revenue {
    from
    to
  }
  assignees
  stickers
  severity
  countries
}
    `;
export const OpportunitySettingsFragmentDoc = gql`
    fragment OpportunitySettings on OpportunitySettings {
  employees {
    from
    to
  }
  industries
  locations {
    locations
    polygon {
      latitude
      longitude
    }
  }
  assignees
  stickers
  requireNoAccountant
  accountants
  financials {
    valueFilters {
      id
      from
      to
    }
  }
  collateralCreditors
  realEstateCollateralCreditors
  inception {
    moreThan
    lessThan
  }
  legalForms
  flagKinds
}
    `;
export const ReviewCompanySettingsFragmentDoc = gql`
    fragment ReviewCompanySettings on CompanyFilter {
  statuses
  assignees
  stickers
}
    `;
export const ReviewSettingsFragmentDoc = gql`
    fragment ReviewSettings on ReviewSettings {
  companies {
    ...ReviewCompanySettings
  }
}
    ${ReviewCompanySettingsFragmentDoc}`;
export const RiskSummaryFragmentDoc = gql`
    fragment RiskSummary on RiskSummary {
  risks {
    label
    reason
    level
    kind
    searched
    found
  }
}
    `;
export const FlagKindInfoFragmentDoc = gql`
    fragment FlagKindInfo on FlagKindInfo {
  id
  name
  countries
}
    `;
export const UserGrowSettingsFragmentDoc = gql`
    fragment UserGrowSettings on UserGrowSettings {
  assignees {
    ...SimpleUser
  }
  tags {
    ...CompanyTag
  }
  locations {
    ...Region
  }
  coordinates {
    latitude
    longitude
  }
  industries {
    ...Industry
  }
  employees {
    from
    to
  }
  inception {
    moreThan
    lessThan
  }
  accountants {
    ...BaseEntityLike
  }
  requireNoAccountant
  collateralCreditors {
    ...BaseEntityLike
  }
  realEstateCollateralCreditors {
    ...BaseEntityLike
  }
  legalForms {
    ...LegalForm
  }
  flagKinds {
    ...FlagKindInfo
  }
  financialFieldsHighlighted
  financialHighlightCount
  financials {
    id
    from
    to
  }
}
    ${SimpleUserFragmentDoc}
${CompanyTagFragmentDoc}
${RegionFragmentDoc}
${IndustryFragmentDoc}
${BaseEntityLikeFragmentDoc}
${LegalFormFragmentDoc}
${FlagKindInfoFragmentDoc}`;
export const UserSidepanelSettingsFragmentDoc = gql`
    fragment UserSidepanelSettings on UserSidepanelSettings {
  topics {
    ...Topic
  }
  sources
  financialFieldsHighlighted
}
    ${TopicFragmentDoc}`;
export const UserReviewSettingsFragmentDoc = gql`
    fragment UserReviewSettings on UserReviewSettings {
  statuses
  assignees {
    ...SimpleUser
  }
  tags {
    ...CompanyTag
  }
}
    ${SimpleUserFragmentDoc}
${CompanyTagFragmentDoc}`;
export const UserPortfolioSettingsFragmentDoc = gql`
    fragment UserPortfolioSettings on UserPortfolioSettings {
  countries
  flagSeverities
  statuses
  assignees {
    ...SimpleUser
  }
  tags {
    ...CompanyTag
  }
  employees {
    from
    to
  }
  revenue {
    from
    to
  }
  locations {
    ...Region
  }
}
    ${SimpleUserFragmentDoc}
${CompanyTagFragmentDoc}
${RegionFragmentDoc}`;
export const UserSettingsFragmentDoc = gql`
    fragment UserSettings on UserSettings {
  generalTermsAccepted
  privacyTermsAccepted
  displayLanguage
  timeZone
  useCaseKinds
  positionKind
  grow {
    ...UserGrowSettings
  }
  sidepanel {
    ...UserSidepanelSettings
  }
  review {
    ...UserReviewSettings
  }
  portfolio {
    ...UserPortfolioSettings
  }
}
    ${UserGrowSettingsFragmentDoc}
${UserSidepanelSettingsFragmentDoc}
${UserReviewSettingsFragmentDoc}
${UserPortfolioSettingsFragmentDoc}`;
export const FeatureCategoryFragmentDoc = gql`
    fragment FeatureCategory on FeatureCategory {
  name
  kind
}
    `;
export const FeatureFragmentDoc = gql`
    fragment Feature on Feature {
  name
  kind
  category {
    ...FeatureCategory
  }
  isAdminOnly
  isBeta
  isEnabled
  isEditable
  requiresIntegration
  requiredIntegration @include(if: $includeIntegration)
  description
}
    ${FeatureCategoryFragmentDoc}`;
export const FeaturesFragmentDoc = gql`
    fragment Features on Features {
  features(
    includeFeatureCategories: $includeFeatureCategories
    excludeFeatureCategories: $excludeFeatureCategories
  ) {
    ...Feature
  }
  enabledContentLanguages
}
    ${FeatureFragmentDoc}`;
export const SettingsFragmentDoc = gql`
    fragment Settings on User {
  id
  userSettings(team: $team) {
    ...UserSettings
  }
  features: featuresV2(team: $team) {
    ...Features
  }
}
    ${UserSettingsFragmentDoc}
${FeaturesFragmentDoc}`;
export const TableLabelBaseFragmentDoc = gql`
    fragment TableLabelBase on TableLabelLike {
  isActive
  ... on TableLabelString {
    content
  }
}
    `;
export const TableRowValueBaseFragmentDoc = gql`
    fragment TableRowValueBase on TableRowValueLike {
  link
  tooltip
  copyValue
  iconId
  ... on TableRowValueString {
    content
  }
  ... on TableRowValueLabeledString {
    label
    content
  }
}
    `;
export const TableRowSectionBaseFragmentDoc = gql`
    fragment TableRowSectionBase on TableRowSection {
  label {
    ...TableLabelBase
  }
  paginationThreshold
  values {
    ...TableRowValueBase
  }
}
    ${TableLabelBaseFragmentDoc}
${TableRowValueBaseFragmentDoc}`;
export const TableRowBaseFragmentDoc = gql`
    fragment TableRowBase on TableRow {
  key
  label {
    ...TableLabelBase
  }
  kind
  paginationThreshold
  sections {
    ...TableRowSectionBase
  }
}
    ${TableLabelBaseFragmentDoc}
${TableRowSectionBaseFragmentDoc}`;
export const TableBaseFragmentDoc = gql`
    fragment TableBase on Table {
  title
  description
  rows {
    ...TableRowBase
  }
  dataSources {
    ...DataSource
  }
}
    ${TableRowBaseFragmentDoc}
${DataSourceFragmentDoc}`;
export const TeamSimpleUserConnectionFragmentDoc = gql`
    fragment TeamSimpleUserConnection on TeamSimpleUserConnectionEdge {
  node {
    id
    ...SimpleUser
  }
  role {
    id
    name
  }
  status
}
    ${SimpleUserFragmentDoc}`;
export const TeamSimpleUsersFragmentDoc = gql`
    fragment TeamSimpleUsers on Team {
  id
  users: simpleUsers(q: "") {
    edges {
      ...TeamSimpleUserConnection
    }
  }
}
    ${TeamSimpleUserConnectionFragmentDoc}`;
export const TermsFragmentDoc = gql`
    fragment Terms on Settings {
  terms {
    generalTerms
    privacyTerms
  }
}
    `;
export const TeamReviewSettingsV2FragmentDoc = gql`
    fragment TeamReviewSettingsV2 on TeamSettings {
  reviewSettingsV2 {
    settings {
      ... on BooleanReviewSetting {
        enabled
        kind
      }
      ... on StringReviewSetting {
        stringRiskValues: riskValues
        enabled
        kind
      }
      ... on RiskReviewSetting {
        reviewRiskValues: riskValues
        enabled
        kind
      }
      ... on FlagReviewSetting {
        flagRiskValues: riskValues
        enabled
        kind
      }
      ... on EntityReviewSetting {
        entityRiskValues: riskValues
        enabled
        kind
      }
      ... on EntityWithCountryReviewSetting {
        entityWithCountryRiskValues: riskValues {
          entity
          country
        }
        enabled
        kind
      }
    }
    customCheckboxes {
      name
      enabled
      description
    }
    enabledPdfSections
  }
}
    `;
export const TeamReviewTriggerSettingsFragmentDoc = gql`
    fragment TeamReviewTriggerSettings on TeamSettings {
  reviewTriggerSettings {
    triggers {
      statements {
        kind
        operator
        value
        statementKindFilter
      }
    }
  }
}
    `;
export const TeamSanctionsSettingsFragmentDoc = gql`
    fragment TeamSanctionsSettings on TeamSettings {
  sanctionSettings {
    similarityScoreThreshold
    filterOnInception
    filterOnNationality
  }
}
    `;
export const RiskClassSettingsFragmentDoc = gql`
    fragment RiskClassSettings on TeamSettings {
  riskClassSettings {
    high {
      triggers {
        statements {
          kind
          operator
          value
          statementKindFilter
        }
      }
      action
    }
    medium {
      triggers {
        statements {
          kind
          operator
          value
          statementKindFilter
        }
      }
      action
    }
    low {
      triggers {
        statements {
          kind
          operator
          value
          statementKindFilter
        }
      }
      action
    }
  }
}
    `;
export const TeamSettingsFragmentDoc = gql`
    fragment TeamSettings on Team {
  id
  name
  teamSettings {
    teamEmails {
      email
    }
    onlySendDocumentsToTeamEmails
    ...TeamReviewSettingsV2
    ...TeamReviewTriggerSettings
    ...TeamSanctionsSettings
    flagFilterSettings {
      period
    }
    ...RiskClassSettings
  }
}
    ${TeamReviewSettingsV2FragmentDoc}
${TeamReviewTriggerSettingsFragmentDoc}
${TeamSanctionsSettingsFragmentDoc}
${RiskClassSettingsFragmentDoc}`;
export const AssignUsersToCompaniesDocument = gql`
    mutation AssignUsersToCompanies($team: ID!, $portfolio: ID!, $companies: [ID!]!, $users: [ID!]!) {
  assignUsersToCompanies(team: $team, companies: $companies, users: $users) {
    ...AssigneeResponse
  }
}
    ${AssigneeResponseFragmentDoc}`;
export type AssignUsersToCompaniesMutationFn = Apollo.MutationFunction<Types.AssignUsersToCompaniesMutation, Types.AssignUsersToCompaniesMutationVariables>;

/**
 * __useAssignUsersToCompaniesMutation__
 *
 * To run a mutation, you first call `useAssignUsersToCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUsersToCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUsersToCompaniesMutation, { data, loading, error }] = useAssignUsersToCompaniesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      companies: // value for 'companies'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useAssignUsersToCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<Types.AssignUsersToCompaniesMutation, Types.AssignUsersToCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssignUsersToCompaniesMutation, Types.AssignUsersToCompaniesMutationVariables>(AssignUsersToCompaniesDocument, options);
      }
export type AssignUsersToCompaniesMutationHookResult = ReturnType<typeof useAssignUsersToCompaniesMutation>;
export type AssignUsersToCompaniesMutationResult = Apollo.MutationResult<Types.AssignUsersToCompaniesMutation>;
export type AssignUsersToCompaniesMutationOptions = Apollo.BaseMutationOptions<Types.AssignUsersToCompaniesMutation, Types.AssignUsersToCompaniesMutationVariables>;
export const ReassignUsersToCompaniesDocument = gql`
    mutation ReassignUsersToCompanies($team: ID!, $portfolio: ID!, $companies: [ID!]!, $users: [ID!]!) {
  reassignUsersToCompanies(team: $team, companies: $companies, users: $users) {
    ...AssigneeResponse
  }
}
    ${AssigneeResponseFragmentDoc}`;
export type ReassignUsersToCompaniesMutationFn = Apollo.MutationFunction<Types.ReassignUsersToCompaniesMutation, Types.ReassignUsersToCompaniesMutationVariables>;

/**
 * __useReassignUsersToCompaniesMutation__
 *
 * To run a mutation, you first call `useReassignUsersToCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignUsersToCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignUsersToCompaniesMutation, { data, loading, error }] = useReassignUsersToCompaniesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      companies: // value for 'companies'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useReassignUsersToCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReassignUsersToCompaniesMutation, Types.ReassignUsersToCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReassignUsersToCompaniesMutation, Types.ReassignUsersToCompaniesMutationVariables>(ReassignUsersToCompaniesDocument, options);
      }
export type ReassignUsersToCompaniesMutationHookResult = ReturnType<typeof useReassignUsersToCompaniesMutation>;
export type ReassignUsersToCompaniesMutationResult = Apollo.MutationResult<Types.ReassignUsersToCompaniesMutation>;
export type ReassignUsersToCompaniesMutationOptions = Apollo.BaseMutationOptions<Types.ReassignUsersToCompaniesMutation, Types.ReassignUsersToCompaniesMutationVariables>;
export const UnassignUsersFromCompaniesDocument = gql`
    mutation UnassignUsersFromCompanies($team: ID!, $portfolio: ID!, $companies: [ID!]!, $users: [ID!]!) {
  unassignUsersFromCompanies(team: $team, companies: $companies, users: $users) {
    ...AssigneeResponse
  }
}
    ${AssigneeResponseFragmentDoc}`;
export type UnassignUsersFromCompaniesMutationFn = Apollo.MutationFunction<Types.UnassignUsersFromCompaniesMutation, Types.UnassignUsersFromCompaniesMutationVariables>;

/**
 * __useUnassignUsersFromCompaniesMutation__
 *
 * To run a mutation, you first call `useUnassignUsersFromCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignUsersFromCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignUsersFromCompaniesMutation, { data, loading, error }] = useUnassignUsersFromCompaniesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      companies: // value for 'companies'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useUnassignUsersFromCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnassignUsersFromCompaniesMutation, Types.UnassignUsersFromCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnassignUsersFromCompaniesMutation, Types.UnassignUsersFromCompaniesMutationVariables>(UnassignUsersFromCompaniesDocument, options);
      }
export type UnassignUsersFromCompaniesMutationHookResult = ReturnType<typeof useUnassignUsersFromCompaniesMutation>;
export type UnassignUsersFromCompaniesMutationResult = Apollo.MutationResult<Types.UnassignUsersFromCompaniesMutation>;
export type UnassignUsersFromCompaniesMutationOptions = Apollo.BaseMutationOptions<Types.UnassignUsersFromCompaniesMutation, Types.UnassignUsersFromCompaniesMutationVariables>;
export const AssigneeDocument = gql`
    query Assignee($id: ID!) {
  assignee: simpleUser(id: $id) {
    id
    ...SimpleUser
  }
}
    ${SimpleUserFragmentDoc}`;

/**
 * __useAssigneeQuery__
 *
 * To run a query within a React component, call `useAssigneeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssigneeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssigneeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssigneeQuery(baseOptions: Apollo.QueryHookOptions<Types.AssigneeQuery, Types.AssigneeQueryVariables> & ({ variables: Types.AssigneeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssigneeQuery, Types.AssigneeQueryVariables>(AssigneeDocument, options);
      }
export function useAssigneeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssigneeQuery, Types.AssigneeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssigneeQuery, Types.AssigneeQueryVariables>(AssigneeDocument, options);
        }
export function useAssigneeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.AssigneeQuery, Types.AssigneeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AssigneeQuery, Types.AssigneeQueryVariables>(AssigneeDocument, options);
        }
export type AssigneeQueryHookResult = ReturnType<typeof useAssigneeQuery>;
export type AssigneeLazyQueryHookResult = ReturnType<typeof useAssigneeLazyQuery>;
export type AssigneeSuspenseQueryHookResult = ReturnType<typeof useAssigneeSuspenseQuery>;
export type AssigneeQueryResult = Apollo.QueryResult<Types.AssigneeQuery, Types.AssigneeQueryVariables>;
export const CompanyAssigneesDocument = gql`
    query CompanyAssignees($id: ID!, $team: ID!) {
  company(id: $id) {
    id
    assignee(team: $team) {
      ...CompanyUserConnection
    }
  }
}
    ${CompanyUserConnectionFragmentDoc}`;

/**
 * __useCompanyAssigneesQuery__
 *
 * To run a query within a React component, call `useCompanyAssigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAssigneesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAssigneesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useCompanyAssigneesQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyAssigneesQuery, Types.CompanyAssigneesQueryVariables> & ({ variables: Types.CompanyAssigneesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyAssigneesQuery, Types.CompanyAssigneesQueryVariables>(CompanyAssigneesDocument, options);
      }
export function useCompanyAssigneesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyAssigneesQuery, Types.CompanyAssigneesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyAssigneesQuery, Types.CompanyAssigneesQueryVariables>(CompanyAssigneesDocument, options);
        }
export function useCompanyAssigneesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CompanyAssigneesQuery, Types.CompanyAssigneesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyAssigneesQuery, Types.CompanyAssigneesQueryVariables>(CompanyAssigneesDocument, options);
        }
export type CompanyAssigneesQueryHookResult = ReturnType<typeof useCompanyAssigneesQuery>;
export type CompanyAssigneesLazyQueryHookResult = ReturnType<typeof useCompanyAssigneesLazyQuery>;
export type CompanyAssigneesSuspenseQueryHookResult = ReturnType<typeof useCompanyAssigneesSuspenseQuery>;
export type CompanyAssigneesQueryResult = Apollo.QueryResult<Types.CompanyAssigneesQuery, Types.CompanyAssigneesQueryVariables>;
export const CompanyByOrganizationNumberDocument = gql`
    query CompanyByOrganizationNumber($organizationNumber: OrganizationNumber!, $country: ContentLanguage!) {
  companyByOrganizationNumber(
    organizationNumber: $organizationNumber
    country: $country
  ) {
    id
  }
}
    `;

/**
 * __useCompanyByOrganizationNumberQuery__
 *
 * To run a query within a React component, call `useCompanyByOrganizationNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyByOrganizationNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyByOrganizationNumberQuery({
 *   variables: {
 *      organizationNumber: // value for 'organizationNumber'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useCompanyByOrganizationNumberQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyByOrganizationNumberQuery, Types.CompanyByOrganizationNumberQueryVariables> & ({ variables: Types.CompanyByOrganizationNumberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyByOrganizationNumberQuery, Types.CompanyByOrganizationNumberQueryVariables>(CompanyByOrganizationNumberDocument, options);
      }
export function useCompanyByOrganizationNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyByOrganizationNumberQuery, Types.CompanyByOrganizationNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyByOrganizationNumberQuery, Types.CompanyByOrganizationNumberQueryVariables>(CompanyByOrganizationNumberDocument, options);
        }
export function useCompanyByOrganizationNumberSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CompanyByOrganizationNumberQuery, Types.CompanyByOrganizationNumberQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyByOrganizationNumberQuery, Types.CompanyByOrganizationNumberQueryVariables>(CompanyByOrganizationNumberDocument, options);
        }
export type CompanyByOrganizationNumberQueryHookResult = ReturnType<typeof useCompanyByOrganizationNumberQuery>;
export type CompanyByOrganizationNumberLazyQueryHookResult = ReturnType<typeof useCompanyByOrganizationNumberLazyQuery>;
export type CompanyByOrganizationNumberSuspenseQueryHookResult = ReturnType<typeof useCompanyByOrganizationNumberSuspenseQuery>;
export type CompanyByOrganizationNumberQueryResult = Apollo.QueryResult<Types.CompanyByOrganizationNumberQuery, Types.CompanyByOrganizationNumberQueryVariables>;
export const CreditReportCompanyDocument = gql`
    query CreditReportCompany($entity: ID!, $team: ID!, $portfolio: ID!) {
  company(id: $entity) {
    id
    ...CreditReportCompany
  }
}
    ${CreditReportCompanyFragmentDoc}`;

/**
 * __useCreditReportCompanyQuery__
 *
 * To run a query within a React component, call `useCreditReportCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditReportCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditReportCompanyQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *   },
 * });
 */
export function useCreditReportCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.CreditReportCompanyQuery, Types.CreditReportCompanyQueryVariables> & ({ variables: Types.CreditReportCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditReportCompanyQuery, Types.CreditReportCompanyQueryVariables>(CreditReportCompanyDocument, options);
      }
export function useCreditReportCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditReportCompanyQuery, Types.CreditReportCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditReportCompanyQuery, Types.CreditReportCompanyQueryVariables>(CreditReportCompanyDocument, options);
        }
export function useCreditReportCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CreditReportCompanyQuery, Types.CreditReportCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditReportCompanyQuery, Types.CreditReportCompanyQueryVariables>(CreditReportCompanyDocument, options);
        }
export type CreditReportCompanyQueryHookResult = ReturnType<typeof useCreditReportCompanyQuery>;
export type CreditReportCompanyLazyQueryHookResult = ReturnType<typeof useCreditReportCompanyLazyQuery>;
export type CreditReportCompanySuspenseQueryHookResult = ReturnType<typeof useCreditReportCompanySuspenseQuery>;
export type CreditReportCompanyQueryResult = Apollo.QueryResult<Types.CreditReportCompanyQuery, Types.CreditReportCompanyQueryVariables>;
export const NotificationCompaniesDocument = gql`
    query NotificationCompanies($portfolio: ID!, $team: ID!, $filter: CompanyFilterInput!, $size: Int, $offset: Int) {
  team(id: $team) {
    id
    portfolio(id: $portfolio) {
      id
      companies(filter: $filter, size: $size, offset: $offset) {
        edges {
          node {
            id
            ...CompanyMeta
            ...CompanyNotificationSettings
          }
        }
      }
    }
  }
}
    ${CompanyMetaFragmentDoc}
${CompanyNotificationSettingsFragmentDoc}`;

/**
 * __useNotificationCompaniesQuery__
 *
 * To run a query within a React component, call `useNotificationCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCompaniesQuery({
 *   variables: {
 *      portfolio: // value for 'portfolio'
 *      team: // value for 'team'
 *      filter: // value for 'filter'
 *      size: // value for 'size'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useNotificationCompaniesQuery(baseOptions: Apollo.QueryHookOptions<Types.NotificationCompaniesQuery, Types.NotificationCompaniesQueryVariables> & ({ variables: Types.NotificationCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotificationCompaniesQuery, Types.NotificationCompaniesQueryVariables>(NotificationCompaniesDocument, options);
      }
export function useNotificationCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotificationCompaniesQuery, Types.NotificationCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotificationCompaniesQuery, Types.NotificationCompaniesQueryVariables>(NotificationCompaniesDocument, options);
        }
export function useNotificationCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.NotificationCompaniesQuery, Types.NotificationCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.NotificationCompaniesQuery, Types.NotificationCompaniesQueryVariables>(NotificationCompaniesDocument, options);
        }
export type NotificationCompaniesQueryHookResult = ReturnType<typeof useNotificationCompaniesQuery>;
export type NotificationCompaniesLazyQueryHookResult = ReturnType<typeof useNotificationCompaniesLazyQuery>;
export type NotificationCompaniesSuspenseQueryHookResult = ReturnType<typeof useNotificationCompaniesSuspenseQuery>;
export type NotificationCompaniesQueryResult = Apollo.QueryResult<Types.NotificationCompaniesQuery, Types.NotificationCompaniesQueryVariables>;
export const RegistryCheckMetaDocument = gql`
    query RegistryCheckMeta($entity: ID!) {
  company(id: $entity) {
    id
    registryCheck {
      fieldName
      isExactMatch
      comment
    }
    registryDocuments {
      id
      name
      type
      source
    }
  }
}
    `;

/**
 * __useRegistryCheckMetaQuery__
 *
 * To run a query within a React component, call `useRegistryCheckMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistryCheckMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistryCheckMetaQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *   },
 * });
 */
export function useRegistryCheckMetaQuery(baseOptions: Apollo.QueryHookOptions<Types.RegistryCheckMetaQuery, Types.RegistryCheckMetaQueryVariables> & ({ variables: Types.RegistryCheckMetaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RegistryCheckMetaQuery, Types.RegistryCheckMetaQueryVariables>(RegistryCheckMetaDocument, options);
      }
export function useRegistryCheckMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RegistryCheckMetaQuery, Types.RegistryCheckMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RegistryCheckMetaQuery, Types.RegistryCheckMetaQueryVariables>(RegistryCheckMetaDocument, options);
        }
export function useRegistryCheckMetaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.RegistryCheckMetaQuery, Types.RegistryCheckMetaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RegistryCheckMetaQuery, Types.RegistryCheckMetaQueryVariables>(RegistryCheckMetaDocument, options);
        }
export type RegistryCheckMetaQueryHookResult = ReturnType<typeof useRegistryCheckMetaQuery>;
export type RegistryCheckMetaLazyQueryHookResult = ReturnType<typeof useRegistryCheckMetaLazyQuery>;
export type RegistryCheckMetaSuspenseQueryHookResult = ReturnType<typeof useRegistryCheckMetaSuspenseQuery>;
export type RegistryCheckMetaQueryResult = Apollo.QueryResult<Types.RegistryCheckMetaQuery, Types.RegistryCheckMetaQueryVariables>;
export const ResolveRoleConflictDocument = gql`
    mutation ResolveRoleConflict($entity: ID!, $team: ID!, $input: ResolveRoleConflictInput!, $resolved: Boolean!) {
  resolveRoleConflict(team: $team, input: $input) {
    company(id: $entity) {
      id
      conflicts(team: $team, resolved: $resolved) {
        ...Conflict
      }
    }
  }
}
    ${ConflictFragmentDoc}`;
export type ResolveRoleConflictMutationFn = Apollo.MutationFunction<Types.ResolveRoleConflictMutation, Types.ResolveRoleConflictMutationVariables>;

/**
 * __useResolveRoleConflictMutation__
 *
 * To run a mutation, you first call `useResolveRoleConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveRoleConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveRoleConflictMutation, { data, loading, error }] = useResolveRoleConflictMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *      team: // value for 'team'
 *      input: // value for 'input'
 *      resolved: // value for 'resolved'
 *   },
 * });
 */
export function useResolveRoleConflictMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResolveRoleConflictMutation, Types.ResolveRoleConflictMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResolveRoleConflictMutation, Types.ResolveRoleConflictMutationVariables>(ResolveRoleConflictDocument, options);
      }
export type ResolveRoleConflictMutationHookResult = ReturnType<typeof useResolveRoleConflictMutation>;
export type ResolveRoleConflictMutationResult = Apollo.MutationResult<Types.ResolveRoleConflictMutation>;
export type ResolveRoleConflictMutationOptions = Apollo.BaseMutationOptions<Types.ResolveRoleConflictMutation, Types.ResolveRoleConflictMutationVariables>;
export const ResolveOwnershipConflictsDocument = gql`
    mutation ResolveOwnershipConflicts($entity: ID!, $team: ID!, $input: ResolveConflictsInput!, $resolved: Boolean!) {
  resolveOwnershipConflicts(team: $team, input: $input) {
    company(id: $entity) {
      id
      conflicts(team: $team, resolved: $resolved) {
        ...Conflict
      }
    }
  }
}
    ${ConflictFragmentDoc}`;
export type ResolveOwnershipConflictsMutationFn = Apollo.MutationFunction<Types.ResolveOwnershipConflictsMutation, Types.ResolveOwnershipConflictsMutationVariables>;

/**
 * __useResolveOwnershipConflictsMutation__
 *
 * To run a mutation, you first call `useResolveOwnershipConflictsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveOwnershipConflictsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveOwnershipConflictsMutation, { data, loading, error }] = useResolveOwnershipConflictsMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *      team: // value for 'team'
 *      input: // value for 'input'
 *      resolved: // value for 'resolved'
 *   },
 * });
 */
export function useResolveOwnershipConflictsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResolveOwnershipConflictsMutation, Types.ResolveOwnershipConflictsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResolveOwnershipConflictsMutation, Types.ResolveOwnershipConflictsMutationVariables>(ResolveOwnershipConflictsDocument, options);
      }
export type ResolveOwnershipConflictsMutationHookResult = ReturnType<typeof useResolveOwnershipConflictsMutation>;
export type ResolveOwnershipConflictsMutationResult = Apollo.MutationResult<Types.ResolveOwnershipConflictsMutation>;
export type ResolveOwnershipConflictsMutationOptions = Apollo.BaseMutationOptions<Types.ResolveOwnershipConflictsMutation, Types.ResolveOwnershipConflictsMutationVariables>;
export const ConflictsDocument = gql`
    query Conflicts($entity: ID!, $team: ID!, $resolved: Boolean!) {
  company(id: $entity) {
    id
    conflicts(team: $team, resolved: $resolved) {
      ...Conflict
    }
  }
}
    ${ConflictFragmentDoc}`;

/**
 * __useConflictsQuery__
 *
 * To run a query within a React component, call `useConflictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConflictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConflictsQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *      team: // value for 'team'
 *      resolved: // value for 'resolved'
 *   },
 * });
 */
export function useConflictsQuery(baseOptions: Apollo.QueryHookOptions<Types.ConflictsQuery, Types.ConflictsQueryVariables> & ({ variables: Types.ConflictsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConflictsQuery, Types.ConflictsQueryVariables>(ConflictsDocument, options);
      }
export function useConflictsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConflictsQuery, Types.ConflictsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConflictsQuery, Types.ConflictsQueryVariables>(ConflictsDocument, options);
        }
export function useConflictsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ConflictsQuery, Types.ConflictsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ConflictsQuery, Types.ConflictsQueryVariables>(ConflictsDocument, options);
        }
export type ConflictsQueryHookResult = ReturnType<typeof useConflictsQuery>;
export type ConflictsLazyQueryHookResult = ReturnType<typeof useConflictsLazyQuery>;
export type ConflictsSuspenseQueryHookResult = ReturnType<typeof useConflictsSuspenseQuery>;
export type ConflictsQueryResult = Apollo.QueryResult<Types.ConflictsQuery, Types.ConflictsQueryVariables>;
export const CreateOAuthConnectedAppDocument = gql`
    mutation CreateOAuthConnectedApp($team: ID!, $kind: ConnectedAppKind!, $code: String!) {
  createOAuthConnectedApp(team: $team, kind: $kind, code: $code) {
    connectedApp(team: $team, kind: $kind) {
      id
      ...ConnectedApp
      tagFieldName
      organizationNumberFieldName
      code
    }
  }
}
    ${ConnectedAppFragmentDoc}`;
export type CreateOAuthConnectedAppMutationFn = Apollo.MutationFunction<Types.CreateOAuthConnectedAppMutation, Types.CreateOAuthConnectedAppMutationVariables>;

/**
 * __useCreateOAuthConnectedAppMutation__
 *
 * To run a mutation, you first call `useCreateOAuthConnectedAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOAuthConnectedAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOAuthConnectedAppMutation, { data, loading, error }] = useCreateOAuthConnectedAppMutation({
 *   variables: {
 *      team: // value for 'team'
 *      kind: // value for 'kind'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCreateOAuthConnectedAppMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateOAuthConnectedAppMutation, Types.CreateOAuthConnectedAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateOAuthConnectedAppMutation, Types.CreateOAuthConnectedAppMutationVariables>(CreateOAuthConnectedAppDocument, options);
      }
export type CreateOAuthConnectedAppMutationHookResult = ReturnType<typeof useCreateOAuthConnectedAppMutation>;
export type CreateOAuthConnectedAppMutationResult = Apollo.MutationResult<Types.CreateOAuthConnectedAppMutation>;
export type CreateOAuthConnectedAppMutationOptions = Apollo.BaseMutationOptions<Types.CreateOAuthConnectedAppMutation, Types.CreateOAuthConnectedAppMutationVariables>;
export const DeleteConnectedAppDocument = gql`
    mutation DeleteConnectedApp($team: ID!, $kind: ConnectedAppKind!) {
  deleteConnectedApp(team: $team, kind: $kind) {
    connectedApps(team: $team) {
      edges {
        node {
          id
          ...ConnectedApp
        }
      }
    }
  }
}
    ${ConnectedAppFragmentDoc}`;
export type DeleteConnectedAppMutationFn = Apollo.MutationFunction<Types.DeleteConnectedAppMutation, Types.DeleteConnectedAppMutationVariables>;

/**
 * __useDeleteConnectedAppMutation__
 *
 * To run a mutation, you first call `useDeleteConnectedAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConnectedAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConnectedAppMutation, { data, loading, error }] = useDeleteConnectedAppMutation({
 *   variables: {
 *      team: // value for 'team'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useDeleteConnectedAppMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteConnectedAppMutation, Types.DeleteConnectedAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteConnectedAppMutation, Types.DeleteConnectedAppMutationVariables>(DeleteConnectedAppDocument, options);
      }
export type DeleteConnectedAppMutationHookResult = ReturnType<typeof useDeleteConnectedAppMutation>;
export type DeleteConnectedAppMutationResult = Apollo.MutationResult<Types.DeleteConnectedAppMutation>;
export type DeleteConnectedAppMutationOptions = Apollo.BaseMutationOptions<Types.DeleteConnectedAppMutation, Types.DeleteConnectedAppMutationVariables>;
export const ConnectedAppsDocument = gql`
    query ConnectedApps($team: ID!) {
  connectedApps(team: $team) {
    edges {
      node {
        id
        ...ConnectedApp
      }
    }
  }
}
    ${ConnectedAppFragmentDoc}`;

/**
 * __useConnectedAppsQuery__
 *
 * To run a query within a React component, call `useConnectedAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedAppsQuery({
 *   variables: {
 *      team: // value for 'team'
 *   },
 * });
 */
export function useConnectedAppsQuery(baseOptions: Apollo.QueryHookOptions<Types.ConnectedAppsQuery, Types.ConnectedAppsQueryVariables> & ({ variables: Types.ConnectedAppsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConnectedAppsQuery, Types.ConnectedAppsQueryVariables>(ConnectedAppsDocument, options);
      }
export function useConnectedAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConnectedAppsQuery, Types.ConnectedAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConnectedAppsQuery, Types.ConnectedAppsQueryVariables>(ConnectedAppsDocument, options);
        }
export function useConnectedAppsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ConnectedAppsQuery, Types.ConnectedAppsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ConnectedAppsQuery, Types.ConnectedAppsQueryVariables>(ConnectedAppsDocument, options);
        }
export type ConnectedAppsQueryHookResult = ReturnType<typeof useConnectedAppsQuery>;
export type ConnectedAppsLazyQueryHookResult = ReturnType<typeof useConnectedAppsLazyQuery>;
export type ConnectedAppsSuspenseQueryHookResult = ReturnType<typeof useConnectedAppsSuspenseQuery>;
export type ConnectedAppsQueryResult = Apollo.QueryResult<Types.ConnectedAppsQuery, Types.ConnectedAppsQueryVariables>;
export const CompanyControlDetailsDocument = gql`
    query CompanyControlDetails($company: ID!, $team: ID!, $beneficialOwnerThreshold: Float!) {
  company(id: $company) {
    id
    controlDetails(team: $team, beneficialOwnerThreshold: $beneficialOwnerThreshold) {
      ...ControlDetails
    }
    dataSources {
      shareholders {
        ...DataSource
      }
    }
  }
}
    ${ControlDetailsFragmentDoc}
${DataSourceFragmentDoc}`;

/**
 * __useCompanyControlDetailsQuery__
 *
 * To run a query within a React component, call `useCompanyControlDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyControlDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyControlDetailsQuery({
 *   variables: {
 *      company: // value for 'company'
 *      team: // value for 'team'
 *      beneficialOwnerThreshold: // value for 'beneficialOwnerThreshold'
 *   },
 * });
 */
export function useCompanyControlDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyControlDetailsQuery, Types.CompanyControlDetailsQueryVariables> & ({ variables: Types.CompanyControlDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyControlDetailsQuery, Types.CompanyControlDetailsQueryVariables>(CompanyControlDetailsDocument, options);
      }
export function useCompanyControlDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyControlDetailsQuery, Types.CompanyControlDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyControlDetailsQuery, Types.CompanyControlDetailsQueryVariables>(CompanyControlDetailsDocument, options);
        }
export function useCompanyControlDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CompanyControlDetailsQuery, Types.CompanyControlDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyControlDetailsQuery, Types.CompanyControlDetailsQueryVariables>(CompanyControlDetailsDocument, options);
        }
export type CompanyControlDetailsQueryHookResult = ReturnType<typeof useCompanyControlDetailsQuery>;
export type CompanyControlDetailsLazyQueryHookResult = ReturnType<typeof useCompanyControlDetailsLazyQuery>;
export type CompanyControlDetailsSuspenseQueryHookResult = ReturnType<typeof useCompanyControlDetailsSuspenseQuery>;
export type CompanyControlDetailsQueryResult = Apollo.QueryResult<Types.CompanyControlDetailsQuery, Types.CompanyControlDetailsQueryVariables>;
export const CreateCounterPartyDocument = gql`
    mutation CreateCounterParty($entity: ID!, $team: ID!, $input: CounterPartyInput!) {
  createCounterParty(team: $team, input: $input) {
    company(id: $entity) {
      id
      counterParties(team: $team) {
        ...CounterParty
      }
    }
  }
}
    ${CounterPartyFragmentDoc}`;
export type CreateCounterPartyMutationFn = Apollo.MutationFunction<Types.CreateCounterPartyMutation, Types.CreateCounterPartyMutationVariables>;

/**
 * __useCreateCounterPartyMutation__
 *
 * To run a mutation, you first call `useCreateCounterPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCounterPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCounterPartyMutation, { data, loading, error }] = useCreateCounterPartyMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *      team: // value for 'team'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCounterPartyMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCounterPartyMutation, Types.CreateCounterPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCounterPartyMutation, Types.CreateCounterPartyMutationVariables>(CreateCounterPartyDocument, options);
      }
export type CreateCounterPartyMutationHookResult = ReturnType<typeof useCreateCounterPartyMutation>;
export type CreateCounterPartyMutationResult = Apollo.MutationResult<Types.CreateCounterPartyMutation>;
export type CreateCounterPartyMutationOptions = Apollo.BaseMutationOptions<Types.CreateCounterPartyMutation, Types.CreateCounterPartyMutationVariables>;
export const DeleteCounterPartyDocument = gql`
    mutation DeleteCounterParty($team: ID!, $counterPartyId: ID!, $company: ID!, $portfolio: ID!) {
  deleteCounterParty(team: $team, counterPartyId: $counterPartyId) {
    company(id: $company) {
      id
      ...SidepanelCounterparties
    }
  }
}
    ${SidepanelCounterpartiesFragmentDoc}`;
export type DeleteCounterPartyMutationFn = Apollo.MutationFunction<Types.DeleteCounterPartyMutation, Types.DeleteCounterPartyMutationVariables>;

/**
 * __useDeleteCounterPartyMutation__
 *
 * To run a mutation, you first call `useDeleteCounterPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCounterPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCounterPartyMutation, { data, loading, error }] = useDeleteCounterPartyMutation({
 *   variables: {
 *      team: // value for 'team'
 *      counterPartyId: // value for 'counterPartyId'
 *      company: // value for 'company'
 *      portfolio: // value for 'portfolio'
 *   },
 * });
 */
export function useDeleteCounterPartyMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCounterPartyMutation, Types.DeleteCounterPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCounterPartyMutation, Types.DeleteCounterPartyMutationVariables>(DeleteCounterPartyDocument, options);
      }
export type DeleteCounterPartyMutationHookResult = ReturnType<typeof useDeleteCounterPartyMutation>;
export type DeleteCounterPartyMutationResult = Apollo.MutationResult<Types.DeleteCounterPartyMutation>;
export type DeleteCounterPartyMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCounterPartyMutation, Types.DeleteCounterPartyMutationVariables>;
export const UpdateCounterPartyDocument = gql`
    mutation UpdateCounterParty($team: ID!, $counterPartyId: ID!, $comment: String!, $company: ID!, $portfolio: ID!) {
  updateCounterParty(
    team: $team
    counterPartyId: $counterPartyId
    comment: $comment
  ) {
    company(id: $company) {
      id
      ...SidepanelCounterparties
    }
  }
}
    ${SidepanelCounterpartiesFragmentDoc}`;
export type UpdateCounterPartyMutationFn = Apollo.MutationFunction<Types.UpdateCounterPartyMutation, Types.UpdateCounterPartyMutationVariables>;

/**
 * __useUpdateCounterPartyMutation__
 *
 * To run a mutation, you first call `useUpdateCounterPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCounterPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCounterPartyMutation, { data, loading, error }] = useUpdateCounterPartyMutation({
 *   variables: {
 *      team: // value for 'team'
 *      counterPartyId: // value for 'counterPartyId'
 *      comment: // value for 'comment'
 *      company: // value for 'company'
 *      portfolio: // value for 'portfolio'
 *   },
 * });
 */
export function useUpdateCounterPartyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCounterPartyMutation, Types.UpdateCounterPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCounterPartyMutation, Types.UpdateCounterPartyMutationVariables>(UpdateCounterPartyDocument, options);
      }
export type UpdateCounterPartyMutationHookResult = ReturnType<typeof useUpdateCounterPartyMutation>;
export type UpdateCounterPartyMutationResult = Apollo.MutationResult<Types.UpdateCounterPartyMutation>;
export type UpdateCounterPartyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCounterPartyMutation, Types.UpdateCounterPartyMutationVariables>;
export const CounterpartiesDocument = gql`
    query Counterparties($team: ID!, $company: ID!, $portfolio: ID!) {
  company(id: $company) {
    id
    ...SidepanelCounterparties
  }
}
    ${SidepanelCounterpartiesFragmentDoc}`;

/**
 * __useCounterpartiesQuery__
 *
 * To run a query within a React component, call `useCounterpartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterpartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterpartiesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      company: // value for 'company'
 *      portfolio: // value for 'portfolio'
 *   },
 * });
 */
export function useCounterpartiesQuery(baseOptions: Apollo.QueryHookOptions<Types.CounterpartiesQuery, Types.CounterpartiesQueryVariables> & ({ variables: Types.CounterpartiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CounterpartiesQuery, Types.CounterpartiesQueryVariables>(CounterpartiesDocument, options);
      }
export function useCounterpartiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CounterpartiesQuery, Types.CounterpartiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CounterpartiesQuery, Types.CounterpartiesQueryVariables>(CounterpartiesDocument, options);
        }
export function useCounterpartiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CounterpartiesQuery, Types.CounterpartiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CounterpartiesQuery, Types.CounterpartiesQueryVariables>(CounterpartiesDocument, options);
        }
export type CounterpartiesQueryHookResult = ReturnType<typeof useCounterpartiesQuery>;
export type CounterpartiesLazyQueryHookResult = ReturnType<typeof useCounterpartiesLazyQuery>;
export type CounterpartiesSuspenseQueryHookResult = ReturnType<typeof useCounterpartiesSuspenseQuery>;
export type CounterpartiesQueryResult = Apollo.QueryResult<Types.CounterpartiesQuery, Types.CounterpartiesQueryVariables>;
export const OrderCcjReportDocument = gql`
    mutation OrderCCJReport($company: ID!, $team: ID!, $portfolio: ID!, $customer: ID!) {
  orderCreditReport(company: $company, team: $team) {
    company(id: $company) {
      id
      ...CountyCourtJudgementsReportCompany
    }
    latestRateLimit(customer: $customer, kind: CREDIT_SCORE) {
      id
      ...BaseRateLimit
    }
  }
}
    ${CountyCourtJudgementsReportCompanyFragmentDoc}
${BaseRateLimitFragmentDoc}`;
export type OrderCcjReportMutationFn = Apollo.MutationFunction<Types.OrderCcjReportMutation, Types.OrderCcjReportMutationVariables>;

/**
 * __useOrderCcjReportMutation__
 *
 * To run a mutation, you first call `useOrderCcjReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCcjReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCcjReportMutation, { data, loading, error }] = useOrderCcjReportMutation({
 *   variables: {
 *      company: // value for 'company'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useOrderCcjReportMutation(baseOptions?: Apollo.MutationHookOptions<Types.OrderCcjReportMutation, Types.OrderCcjReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.OrderCcjReportMutation, Types.OrderCcjReportMutationVariables>(OrderCcjReportDocument, options);
      }
export type OrderCcjReportMutationHookResult = ReturnType<typeof useOrderCcjReportMutation>;
export type OrderCcjReportMutationResult = Apollo.MutationResult<Types.OrderCcjReportMutation>;
export type OrderCcjReportMutationOptions = Apollo.BaseMutationOptions<Types.OrderCcjReportMutation, Types.OrderCcjReportMutationVariables>;
export const OrderCreditReportDocument = gql`
    mutation OrderCreditReport($company: ID!, $team: ID!, $portfolio: ID!, $customer: ID!) {
  orderCreditReport(company: $company, team: $team) {
    company(id: $company) {
      id
      ...CreditReportCompany
    }
    latestRateLimit(customer: $customer, kind: CREDIT_SCORE) {
      id
      ...BaseRateLimit
    }
  }
}
    ${CreditReportCompanyFragmentDoc}
${BaseRateLimitFragmentDoc}`;
export type OrderCreditReportMutationFn = Apollo.MutationFunction<Types.OrderCreditReportMutation, Types.OrderCreditReportMutationVariables>;

/**
 * __useOrderCreditReportMutation__
 *
 * To run a mutation, you first call `useOrderCreditReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCreditReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCreditReportMutation, { data, loading, error }] = useOrderCreditReportMutation({
 *   variables: {
 *      company: // value for 'company'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useOrderCreditReportMutation(baseOptions?: Apollo.MutationHookOptions<Types.OrderCreditReportMutation, Types.OrderCreditReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.OrderCreditReportMutation, Types.OrderCreditReportMutationVariables>(OrderCreditReportDocument, options);
      }
export type OrderCreditReportMutationHookResult = ReturnType<typeof useOrderCreditReportMutation>;
export type OrderCreditReportMutationResult = Apollo.MutationResult<Types.OrderCreditReportMutation>;
export type OrderCreditReportMutationOptions = Apollo.BaseMutationOptions<Types.OrderCreditReportMutation, Types.OrderCreditReportMutationVariables>;
export const UpdateCreditMonitoringDocument = gql`
    mutation UpdateCreditMonitoring($company: ID!, $team: ID!, $enabled: Boolean!, $portfolio: ID!) {
  updateCreditMonitoring(company: $company, team: $team, enabled: $enabled) {
    company(id: $company) {
      id
      ...CreditReportCompany
    }
  }
}
    ${CreditReportCompanyFragmentDoc}`;
export type UpdateCreditMonitoringMutationFn = Apollo.MutationFunction<Types.UpdateCreditMonitoringMutation, Types.UpdateCreditMonitoringMutationVariables>;

/**
 * __useUpdateCreditMonitoringMutation__
 *
 * To run a mutation, you first call `useUpdateCreditMonitoringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditMonitoringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditMonitoringMutation, { data, loading, error }] = useUpdateCreditMonitoringMutation({
 *   variables: {
 *      company: // value for 'company'
 *      team: // value for 'team'
 *      enabled: // value for 'enabled'
 *      portfolio: // value for 'portfolio'
 *   },
 * });
 */
export function useUpdateCreditMonitoringMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCreditMonitoringMutation, Types.UpdateCreditMonitoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCreditMonitoringMutation, Types.UpdateCreditMonitoringMutationVariables>(UpdateCreditMonitoringDocument, options);
      }
export type UpdateCreditMonitoringMutationHookResult = ReturnType<typeof useUpdateCreditMonitoringMutation>;
export type UpdateCreditMonitoringMutationResult = Apollo.MutationResult<Types.UpdateCreditMonitoringMutation>;
export type UpdateCreditMonitoringMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCreditMonitoringMutation, Types.UpdateCreditMonitoringMutationVariables>;
export const ExportPortfolioToCrmDocument = gql`
    mutation ExportPortfolioToCrm($team: ID!, $kind: ConnectedAppKind!) {
  exportPortfolioToCrm(team: $team, kind: $kind) {
    strise
  }
}
    `;
export type ExportPortfolioToCrmMutationFn = Apollo.MutationFunction<Types.ExportPortfolioToCrmMutation, Types.ExportPortfolioToCrmMutationVariables>;

/**
 * __useExportPortfolioToCrmMutation__
 *
 * To run a mutation, you first call `useExportPortfolioToCrmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportPortfolioToCrmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportPortfolioToCrmMutation, { data, loading, error }] = useExportPortfolioToCrmMutation({
 *   variables: {
 *      team: // value for 'team'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useExportPortfolioToCrmMutation(baseOptions?: Apollo.MutationHookOptions<Types.ExportPortfolioToCrmMutation, Types.ExportPortfolioToCrmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ExportPortfolioToCrmMutation, Types.ExportPortfolioToCrmMutationVariables>(ExportPortfolioToCrmDocument, options);
      }
export type ExportPortfolioToCrmMutationHookResult = ReturnType<typeof useExportPortfolioToCrmMutation>;
export type ExportPortfolioToCrmMutationResult = Apollo.MutationResult<Types.ExportPortfolioToCrmMutation>;
export type ExportPortfolioToCrmMutationOptions = Apollo.BaseMutationOptions<Types.ExportPortfolioToCrmMutation, Types.ExportPortfolioToCrmMutationVariables>;
export const ImportPortfolioFromCrmDocument = gql`
    mutation ImportPortfolioFromCrm($team: ID!, $kind: ConnectedAppKind!) {
  importPortfolioFromCrm(team: $team, kind: $kind) {
    strise
  }
}
    `;
export type ImportPortfolioFromCrmMutationFn = Apollo.MutationFunction<Types.ImportPortfolioFromCrmMutation, Types.ImportPortfolioFromCrmMutationVariables>;

/**
 * __useImportPortfolioFromCrmMutation__
 *
 * To run a mutation, you first call `useImportPortfolioFromCrmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPortfolioFromCrmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPortfolioFromCrmMutation, { data, loading, error }] = useImportPortfolioFromCrmMutation({
 *   variables: {
 *      team: // value for 'team'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useImportPortfolioFromCrmMutation(baseOptions?: Apollo.MutationHookOptions<Types.ImportPortfolioFromCrmMutation, Types.ImportPortfolioFromCrmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ImportPortfolioFromCrmMutation, Types.ImportPortfolioFromCrmMutationVariables>(ImportPortfolioFromCrmDocument, options);
      }
export type ImportPortfolioFromCrmMutationHookResult = ReturnType<typeof useImportPortfolioFromCrmMutation>;
export type ImportPortfolioFromCrmMutationResult = Apollo.MutationResult<Types.ImportPortfolioFromCrmMutation>;
export type ImportPortfolioFromCrmMutationOptions = Apollo.BaseMutationOptions<Types.ImportPortfolioFromCrmMutation, Types.ImportPortfolioFromCrmMutationVariables>;
export const UpdateCrmOrganizationNumberFieldDocument = gql`
    mutation UpdateCrmOrganizationNumberField($team: ID!, $kind: ConnectedAppKind!, $fieldName: String!) {
  updateCrmOrganizationNumberField(
    team: $team
    kind: $kind
    fieldName: $fieldName
  ) {
    connectedApp(team: $team, kind: $kind) {
      id
      organizationNumberFieldName
    }
  }
}
    `;
export type UpdateCrmOrganizationNumberFieldMutationFn = Apollo.MutationFunction<Types.UpdateCrmOrganizationNumberFieldMutation, Types.UpdateCrmOrganizationNumberFieldMutationVariables>;

/**
 * __useUpdateCrmOrganizationNumberFieldMutation__
 *
 * To run a mutation, you first call `useUpdateCrmOrganizationNumberFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrmOrganizationNumberFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrmOrganizationNumberFieldMutation, { data, loading, error }] = useUpdateCrmOrganizationNumberFieldMutation({
 *   variables: {
 *      team: // value for 'team'
 *      kind: // value for 'kind'
 *      fieldName: // value for 'fieldName'
 *   },
 * });
 */
export function useUpdateCrmOrganizationNumberFieldMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCrmOrganizationNumberFieldMutation, Types.UpdateCrmOrganizationNumberFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCrmOrganizationNumberFieldMutation, Types.UpdateCrmOrganizationNumberFieldMutationVariables>(UpdateCrmOrganizationNumberFieldDocument, options);
      }
export type UpdateCrmOrganizationNumberFieldMutationHookResult = ReturnType<typeof useUpdateCrmOrganizationNumberFieldMutation>;
export type UpdateCrmOrganizationNumberFieldMutationResult = Apollo.MutationResult<Types.UpdateCrmOrganizationNumberFieldMutation>;
export type UpdateCrmOrganizationNumberFieldMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCrmOrganizationNumberFieldMutation, Types.UpdateCrmOrganizationNumberFieldMutationVariables>;
export const UpdateCrmTagFieldDocument = gql`
    mutation UpdateCrmTagField($team: ID!, $kind: ConnectedAppKind!, $fieldName: String!) {
  updateCrmTagField(team: $team, kind: $kind, fieldName: $fieldName) {
    connectedApp(team: $team, kind: $kind) {
      id
      tagFieldName
    }
  }
}
    `;
export type UpdateCrmTagFieldMutationFn = Apollo.MutationFunction<Types.UpdateCrmTagFieldMutation, Types.UpdateCrmTagFieldMutationVariables>;

/**
 * __useUpdateCrmTagFieldMutation__
 *
 * To run a mutation, you first call `useUpdateCrmTagFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrmTagFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrmTagFieldMutation, { data, loading, error }] = useUpdateCrmTagFieldMutation({
 *   variables: {
 *      team: // value for 'team'
 *      kind: // value for 'kind'
 *      fieldName: // value for 'fieldName'
 *   },
 * });
 */
export function useUpdateCrmTagFieldMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCrmTagFieldMutation, Types.UpdateCrmTagFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCrmTagFieldMutation, Types.UpdateCrmTagFieldMutationVariables>(UpdateCrmTagFieldDocument, options);
      }
export type UpdateCrmTagFieldMutationHookResult = ReturnType<typeof useUpdateCrmTagFieldMutation>;
export type UpdateCrmTagFieldMutationResult = Apollo.MutationResult<Types.UpdateCrmTagFieldMutation>;
export type UpdateCrmTagFieldMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCrmTagFieldMutation, Types.UpdateCrmTagFieldMutationVariables>;
export const CrmCompanySchemaDocument = gql`
    query CrmCompanySchema($team: ID!, $kind: ConnectedAppKind!) {
  crmCompanySchema(team: $team, kind: $kind) {
    fields {
      id
      name
    }
  }
}
    `;

/**
 * __useCrmCompanySchemaQuery__
 *
 * To run a query within a React component, call `useCrmCompanySchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanySchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmCompanySchemaQuery({
 *   variables: {
 *      team: // value for 'team'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useCrmCompanySchemaQuery(baseOptions: Apollo.QueryHookOptions<Types.CrmCompanySchemaQuery, Types.CrmCompanySchemaQueryVariables> & ({ variables: Types.CrmCompanySchemaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CrmCompanySchemaQuery, Types.CrmCompanySchemaQueryVariables>(CrmCompanySchemaDocument, options);
      }
export function useCrmCompanySchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CrmCompanySchemaQuery, Types.CrmCompanySchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CrmCompanySchemaQuery, Types.CrmCompanySchemaQueryVariables>(CrmCompanySchemaDocument, options);
        }
export function useCrmCompanySchemaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CrmCompanySchemaQuery, Types.CrmCompanySchemaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CrmCompanySchemaQuery, Types.CrmCompanySchemaQueryVariables>(CrmCompanySchemaDocument, options);
        }
export type CrmCompanySchemaQueryHookResult = ReturnType<typeof useCrmCompanySchemaQuery>;
export type CrmCompanySchemaLazyQueryHookResult = ReturnType<typeof useCrmCompanySchemaLazyQuery>;
export type CrmCompanySchemaSuspenseQueryHookResult = ReturnType<typeof useCrmCompanySchemaSuspenseQuery>;
export type CrmCompanySchemaQueryResult = Apollo.QueryResult<Types.CrmCompanySchemaQuery, Types.CrmCompanySchemaQueryVariables>;
export const CrmSyncInfoDocument = gql`
    query CrmSyncInfo($team: ID!, $kind: ConnectedAppKind!) {
  crmSyncInfo(team: $team, kind: $kind) {
    portfolioCount
    crmPortfolioCount
    addableToStriseCount
    addableToCrmCount
    crmMissingOrganizationNumberCount
    notFoundCount
    alreadyInStriseCount
    alreadyInCrmCount
  }
}
    `;

/**
 * __useCrmSyncInfoQuery__
 *
 * To run a query within a React component, call `useCrmSyncInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmSyncInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmSyncInfoQuery({
 *   variables: {
 *      team: // value for 'team'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useCrmSyncInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.CrmSyncInfoQuery, Types.CrmSyncInfoQueryVariables> & ({ variables: Types.CrmSyncInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CrmSyncInfoQuery, Types.CrmSyncInfoQueryVariables>(CrmSyncInfoDocument, options);
      }
export function useCrmSyncInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CrmSyncInfoQuery, Types.CrmSyncInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CrmSyncInfoQuery, Types.CrmSyncInfoQueryVariables>(CrmSyncInfoDocument, options);
        }
export function useCrmSyncInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CrmSyncInfoQuery, Types.CrmSyncInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CrmSyncInfoQuery, Types.CrmSyncInfoQueryVariables>(CrmSyncInfoDocument, options);
        }
export type CrmSyncInfoQueryHookResult = ReturnType<typeof useCrmSyncInfoQuery>;
export type CrmSyncInfoLazyQueryHookResult = ReturnType<typeof useCrmSyncInfoLazyQuery>;
export type CrmSyncInfoSuspenseQueryHookResult = ReturnType<typeof useCrmSyncInfoSuspenseQuery>;
export type CrmSyncInfoQueryResult = Apollo.QueryResult<Types.CrmSyncInfoQuery, Types.CrmSyncInfoQueryVariables>;
export const GenerateOwnershipConflictDocument = gql`
    mutation GenerateOwnershipConflict($team: ID!, $entity: ID!, $customOwnershipInput: UpdateEntityOwnerInput!, $conflictingOwnershipInput: UpdateEntityOwnerInput!, $withFlags: Boolean = false, $ignoreCustomRoles: Boolean = false, $filterActive: Boolean = false) {
  generateOwnershipConflict(
    team: $team
    entity: $entity
    customOwnershipInput: $customOwnershipInput
    conflictingOwnershipInput: $conflictingOwnershipInput
  ) {
    entity(id: $entity) {
      id
      ...EntityBusinessRoleMeta
    }
  }
}
    ${EntityBusinessRoleMetaFragmentDoc}`;
export type GenerateOwnershipConflictMutationFn = Apollo.MutationFunction<Types.GenerateOwnershipConflictMutation, Types.GenerateOwnershipConflictMutationVariables>;

/**
 * __useGenerateOwnershipConflictMutation__
 *
 * To run a mutation, you first call `useGenerateOwnershipConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOwnershipConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOwnershipConflictMutation, { data, loading, error }] = useGenerateOwnershipConflictMutation({
 *   variables: {
 *      team: // value for 'team'
 *      entity: // value for 'entity'
 *      customOwnershipInput: // value for 'customOwnershipInput'
 *      conflictingOwnershipInput: // value for 'conflictingOwnershipInput'
 *      withFlags: // value for 'withFlags'
 *      ignoreCustomRoles: // value for 'ignoreCustomRoles'
 *      filterActive: // value for 'filterActive'
 *   },
 * });
 */
export function useGenerateOwnershipConflictMutation(baseOptions?: Apollo.MutationHookOptions<Types.GenerateOwnershipConflictMutation, Types.GenerateOwnershipConflictMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GenerateOwnershipConflictMutation, Types.GenerateOwnershipConflictMutationVariables>(GenerateOwnershipConflictDocument, options);
      }
export type GenerateOwnershipConflictMutationHookResult = ReturnType<typeof useGenerateOwnershipConflictMutation>;
export type GenerateOwnershipConflictMutationResult = Apollo.MutationResult<Types.GenerateOwnershipConflictMutation>;
export type GenerateOwnershipConflictMutationOptions = Apollo.BaseMutationOptions<Types.GenerateOwnershipConflictMutation, Types.GenerateOwnershipConflictMutationVariables>;
export const GenerateRoleConflictDocument = gql`
    mutation GenerateRoleConflict($team: ID!, $entity: ID!, $customRoleInput: UpdateEntityBusinessRoleInput!, $conflictingRoleInput: UpdateEntityBusinessRoleInput!, $withFlags: Boolean = false, $ignoreCustomRoles: Boolean = false, $filterActive: Boolean = false) {
  generateRoleConflict(
    team: $team
    entity: $entity
    customRoleInput: $customRoleInput
    conflictingRoleInput: $conflictingRoleInput
  ) {
    entity(id: $entity) {
      id
      ...EntityBusinessRoleMeta
    }
  }
}
    ${EntityBusinessRoleMetaFragmentDoc}`;
export type GenerateRoleConflictMutationFn = Apollo.MutationFunction<Types.GenerateRoleConflictMutation, Types.GenerateRoleConflictMutationVariables>;

/**
 * __useGenerateRoleConflictMutation__
 *
 * To run a mutation, you first call `useGenerateRoleConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRoleConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRoleConflictMutation, { data, loading, error }] = useGenerateRoleConflictMutation({
 *   variables: {
 *      team: // value for 'team'
 *      entity: // value for 'entity'
 *      customRoleInput: // value for 'customRoleInput'
 *      conflictingRoleInput: // value for 'conflictingRoleInput'
 *      withFlags: // value for 'withFlags'
 *      ignoreCustomRoles: // value for 'ignoreCustomRoles'
 *      filterActive: // value for 'filterActive'
 *   },
 * });
 */
export function useGenerateRoleConflictMutation(baseOptions?: Apollo.MutationHookOptions<Types.GenerateRoleConflictMutation, Types.GenerateRoleConflictMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GenerateRoleConflictMutation, Types.GenerateRoleConflictMutationVariables>(GenerateRoleConflictDocument, options);
      }
export type GenerateRoleConflictMutationHookResult = ReturnType<typeof useGenerateRoleConflictMutation>;
export type GenerateRoleConflictMutationResult = Apollo.MutationResult<Types.GenerateRoleConflictMutation>;
export type GenerateRoleConflictMutationOptions = Apollo.BaseMutationOptions<Types.GenerateRoleConflictMutation, Types.GenerateRoleConflictMutationVariables>;
export const EntityMetaDocument = gql`
    query EntityMeta($team: ID!, $portfolio: ID!, $id: ID!) {
  entity(id: $id) {
    id
    ...EntityLikeMeta
  }
}
    ${EntityLikeMetaFragmentDoc}`;

/**
 * __useEntityMetaQuery__
 *
 * To run a query within a React component, call `useEntityMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityMetaQuery({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntityMetaQuery(baseOptions: Apollo.QueryHookOptions<Types.EntityMetaQuery, Types.EntityMetaQueryVariables> & ({ variables: Types.EntityMetaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EntityMetaQuery, Types.EntityMetaQueryVariables>(EntityMetaDocument, options);
      }
export function useEntityMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EntityMetaQuery, Types.EntityMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EntityMetaQuery, Types.EntityMetaQueryVariables>(EntityMetaDocument, options);
        }
export function useEntityMetaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.EntityMetaQuery, Types.EntityMetaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EntityMetaQuery, Types.EntityMetaQueryVariables>(EntityMetaDocument, options);
        }
export type EntityMetaQueryHookResult = ReturnType<typeof useEntityMetaQuery>;
export type EntityMetaLazyQueryHookResult = ReturnType<typeof useEntityMetaLazyQuery>;
export type EntityMetaSuspenseQueryHookResult = ReturnType<typeof useEntityMetaSuspenseQuery>;
export type EntityMetaQueryResult = Apollo.QueryResult<Types.EntityMetaQuery, Types.EntityMetaQueryVariables>;
export const SearchDocument = gql`
    query Search($q: String!, $team: ID!, $portfolio: ID!, $input: ApplicationSearchInput!, $showExtraMeta: Boolean!, $withDataSources: Boolean!) {
  personsOrCompanies(team: $team, q: $q, input: $input) {
    edges {
      node {
        ...EntityLikeMeta
        ... on Company {
          keyMetaExtra @include(if: $showExtraMeta) {
            ...KeyEntityMeta
          }
          dataSources @include(if: $withDataSources) {
            ...EntityDataSources
          }
        }
      }
    }
  }
}
    ${EntityLikeMetaFragmentDoc}
${KeyEntityMetaFragmentDoc}
${EntityDataSourcesFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      q: // value for 'q'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      input: // value for 'input'
 *      showExtraMeta: // value for 'showExtraMeta'
 *      withDataSources: // value for 'withDataSources'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchQuery, Types.SearchQueryVariables> & ({ variables: Types.SearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchQuery, Types.SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchQuery, Types.SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchQuery, Types.SearchQueryVariables>(SearchDocument, options);
        }
export function useSearchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.SearchQuery, Types.SearchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchQuery, Types.SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchSuspenseQueryHookResult = ReturnType<typeof useSearchSuspenseQuery>;
export type SearchQueryResult = Apollo.QueryResult<Types.SearchQuery, Types.SearchQueryVariables>;
export const SuggestedMergeEntitiesDocument = gql`
    query SuggestedMergeEntities($q: String!, $team: ID!, $portfolio: ID!, $input: ApplicationSearchInput!, $showExtraMeta: Boolean!) {
  suggestedMergeEntities(team: $team, q: $q, input: $input) {
    edges {
      node {
        ...EntityLikeMeta
        ... on Company {
          keyMetaExtra @include(if: $showExtraMeta) {
            ...KeyEntityMeta
          }
          dataSources {
            ...EntityDataSources
          }
        }
      }
    }
  }
}
    ${EntityLikeMetaFragmentDoc}
${KeyEntityMetaFragmentDoc}
${EntityDataSourcesFragmentDoc}`;

/**
 * __useSuggestedMergeEntitiesQuery__
 *
 * To run a query within a React component, call `useSuggestedMergeEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedMergeEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedMergeEntitiesQuery({
 *   variables: {
 *      q: // value for 'q'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      input: // value for 'input'
 *      showExtraMeta: // value for 'showExtraMeta'
 *   },
 * });
 */
export function useSuggestedMergeEntitiesQuery(baseOptions: Apollo.QueryHookOptions<Types.SuggestedMergeEntitiesQuery, Types.SuggestedMergeEntitiesQueryVariables> & ({ variables: Types.SuggestedMergeEntitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SuggestedMergeEntitiesQuery, Types.SuggestedMergeEntitiesQueryVariables>(SuggestedMergeEntitiesDocument, options);
      }
export function useSuggestedMergeEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SuggestedMergeEntitiesQuery, Types.SuggestedMergeEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SuggestedMergeEntitiesQuery, Types.SuggestedMergeEntitiesQueryVariables>(SuggestedMergeEntitiesDocument, options);
        }
export function useSuggestedMergeEntitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.SuggestedMergeEntitiesQuery, Types.SuggestedMergeEntitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SuggestedMergeEntitiesQuery, Types.SuggestedMergeEntitiesQueryVariables>(SuggestedMergeEntitiesDocument, options);
        }
export type SuggestedMergeEntitiesQueryHookResult = ReturnType<typeof useSuggestedMergeEntitiesQuery>;
export type SuggestedMergeEntitiesLazyQueryHookResult = ReturnType<typeof useSuggestedMergeEntitiesLazyQuery>;
export type SuggestedMergeEntitiesSuspenseQueryHookResult = ReturnType<typeof useSuggestedMergeEntitiesSuspenseQuery>;
export type SuggestedMergeEntitiesQueryResult = Apollo.QueryResult<Types.SuggestedMergeEntitiesQuery, Types.SuggestedMergeEntitiesQueryVariables>;
export const UpdateEventLikeStatusDocument = gql`
    mutation UpdateEventLikeStatus($portfolio: ID!, $team: ID!, $company: ID!, $event: ID!, $status: LikeStatus) {
  updateEventLikeStatus(
    portfolio: $portfolio
    team: $team
    company: $company
    event: $event
    status: $status
  ) {
    id
    liked(portfolio: $portfolio, team: $team, company: $company)
  }
}
    `;
export type UpdateEventLikeStatusMutationFn = Apollo.MutationFunction<Types.UpdateEventLikeStatusMutation, Types.UpdateEventLikeStatusMutationVariables>;

/**
 * __useUpdateEventLikeStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEventLikeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventLikeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventLikeStatusMutation, { data, loading, error }] = useUpdateEventLikeStatusMutation({
 *   variables: {
 *      portfolio: // value for 'portfolio'
 *      team: // value for 'team'
 *      company: // value for 'company'
 *      event: // value for 'event'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateEventLikeStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEventLikeStatusMutation, Types.UpdateEventLikeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEventLikeStatusMutation, Types.UpdateEventLikeStatusMutationVariables>(UpdateEventLikeStatusDocument, options);
      }
export type UpdateEventLikeStatusMutationHookResult = ReturnType<typeof useUpdateEventLikeStatusMutation>;
export type UpdateEventLikeStatusMutationResult = Apollo.MutationResult<Types.UpdateEventLikeStatusMutation>;
export type UpdateEventLikeStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEventLikeStatusMutation, Types.UpdateEventLikeStatusMutationVariables>;
export const UpdateEventReadStatusDocument = gql`
    mutation UpdateEventReadStatus($events: [ID!]!, $read: Boolean!) {
  updateEventReadStatus(events: $events, read: $read) {
    id
    read
  }
}
    `;
export type UpdateEventReadStatusMutationFn = Apollo.MutationFunction<Types.UpdateEventReadStatusMutation, Types.UpdateEventReadStatusMutationVariables>;

/**
 * __useUpdateEventReadStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEventReadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventReadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventReadStatusMutation, { data, loading, error }] = useUpdateEventReadStatusMutation({
 *   variables: {
 *      events: // value for 'events'
 *      read: // value for 'read'
 *   },
 * });
 */
export function useUpdateEventReadStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEventReadStatusMutation, Types.UpdateEventReadStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEventReadStatusMutation, Types.UpdateEventReadStatusMutationVariables>(UpdateEventReadStatusDocument, options);
      }
export type UpdateEventReadStatusMutationHookResult = ReturnType<typeof useUpdateEventReadStatusMutation>;
export type UpdateEventReadStatusMutationResult = Apollo.MutationResult<Types.UpdateEventReadStatusMutation>;
export type UpdateEventReadStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEventReadStatusMutation, Types.UpdateEventReadStatusMutationVariables>;
export const EntityEventHeaderDocument = gql`
    query EntityEventHeader($id: ID!) {
  entity(id: $id) {
    id
    ...BaseEntityLike
  }
}
    ${BaseEntityLikeFragmentDoc}`;

/**
 * __useEntityEventHeaderQuery__
 *
 * To run a query within a React component, call `useEntityEventHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityEventHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityEventHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntityEventHeaderQuery(baseOptions: Apollo.QueryHookOptions<Types.EntityEventHeaderQuery, Types.EntityEventHeaderQueryVariables> & ({ variables: Types.EntityEventHeaderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EntityEventHeaderQuery, Types.EntityEventHeaderQueryVariables>(EntityEventHeaderDocument, options);
      }
export function useEntityEventHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EntityEventHeaderQuery, Types.EntityEventHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EntityEventHeaderQuery, Types.EntityEventHeaderQueryVariables>(EntityEventHeaderDocument, options);
        }
export function useEntityEventHeaderSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.EntityEventHeaderQuery, Types.EntityEventHeaderQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EntityEventHeaderQuery, Types.EntityEventHeaderQueryVariables>(EntityEventHeaderDocument, options);
        }
export type EntityEventHeaderQueryHookResult = ReturnType<typeof useEntityEventHeaderQuery>;
export type EntityEventHeaderLazyQueryHookResult = ReturnType<typeof useEntityEventHeaderLazyQuery>;
export type EntityEventHeaderSuspenseQueryHookResult = ReturnType<typeof useEntityEventHeaderSuspenseQuery>;
export type EntityEventHeaderQueryResult = Apollo.QueryResult<Types.EntityEventHeaderQuery, Types.EntityEventHeaderQueryVariables>;
export const EventAiSummaryDocument = gql`
    query EventAiSummary($id: ID!, $summaryContext: [ID!]!, $selection: SummarySelection, $language: DisplayLanguage) {
  event(id: $id) {
    id
    summary(context: $summaryContext) {
      text(selection: $selection, language: $language)
    }
  }
}
    `;

/**
 * __useEventAiSummaryQuery__
 *
 * To run a query within a React component, call `useEventAiSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventAiSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventAiSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      summaryContext: // value for 'summaryContext'
 *      selection: // value for 'selection'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useEventAiSummaryQuery(baseOptions: Apollo.QueryHookOptions<Types.EventAiSummaryQuery, Types.EventAiSummaryQueryVariables> & ({ variables: Types.EventAiSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EventAiSummaryQuery, Types.EventAiSummaryQueryVariables>(EventAiSummaryDocument, options);
      }
export function useEventAiSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EventAiSummaryQuery, Types.EventAiSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EventAiSummaryQuery, Types.EventAiSummaryQueryVariables>(EventAiSummaryDocument, options);
        }
export function useEventAiSummarySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.EventAiSummaryQuery, Types.EventAiSummaryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EventAiSummaryQuery, Types.EventAiSummaryQueryVariables>(EventAiSummaryDocument, options);
        }
export type EventAiSummaryQueryHookResult = ReturnType<typeof useEventAiSummaryQuery>;
export type EventAiSummaryLazyQueryHookResult = ReturnType<typeof useEventAiSummaryLazyQuery>;
export type EventAiSummarySuspenseQueryHookResult = ReturnType<typeof useEventAiSummarySuspenseQuery>;
export type EventAiSummaryQueryResult = Apollo.QueryResult<Types.EventAiSummaryQuery, Types.EventAiSummaryQueryVariables>;
export const EventMetadataDocument = gql`
    query EventMetadata($id: ID!, $entityId: ID!, $summaryContext: [ID!]!, $clusterScore: Float = 0.5, $first: Int!, $team: ID!, $portfolio: ID!) {
  event(id: $id) {
    id
    url
    summary(context: $summaryContext) {
      text
    }
    liked(portfolio: $portfolio, team: $team, company: $entityId)
    publisher
    isPublishedByStrise
    relevant(company: $entityId)
    similar(first: $first, clusterScore: $clusterScore) {
      edges {
        node {
          id
          publisher
          url
          isPublishedByStrise
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
}
    `;

/**
 * __useEventMetadataQuery__
 *
 * To run a query within a React component, call `useEventMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventMetadataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      entityId: // value for 'entityId'
 *      summaryContext: // value for 'summaryContext'
 *      clusterScore: // value for 'clusterScore'
 *      first: // value for 'first'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *   },
 * });
 */
export function useEventMetadataQuery(baseOptions: Apollo.QueryHookOptions<Types.EventMetadataQuery, Types.EventMetadataQueryVariables> & ({ variables: Types.EventMetadataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EventMetadataQuery, Types.EventMetadataQueryVariables>(EventMetadataDocument, options);
      }
export function useEventMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EventMetadataQuery, Types.EventMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EventMetadataQuery, Types.EventMetadataQueryVariables>(EventMetadataDocument, options);
        }
export function useEventMetadataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.EventMetadataQuery, Types.EventMetadataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EventMetadataQuery, Types.EventMetadataQueryVariables>(EventMetadataDocument, options);
        }
export type EventMetadataQueryHookResult = ReturnType<typeof useEventMetadataQuery>;
export type EventMetadataLazyQueryHookResult = ReturnType<typeof useEventMetadataLazyQuery>;
export type EventMetadataSuspenseQueryHookResult = ReturnType<typeof useEventMetadataSuspenseQuery>;
export type EventMetadataQueryResult = Apollo.QueryResult<Types.EventMetadataQuery, Types.EventMetadataQueryVariables>;
export const EventWithAnnotationsDocument = gql`
    query EventWithAnnotations($id: ID!, $entityFilter: [ID!], $portfolioFilter: [ID!]) {
  event(id: $id) {
    id
    title
    summary {
      text
    }
    official
    body
    read
    authors
    published
    publisher
    url
    shouldRedirect
    behindPaywall
    images {
      url
    }
    annotations(entityFilter: $entityFilter, portfolioFilter: $portfolioFilter) {
      edges {
        node {
          id
          ...Annotation
        }
      }
    }
  }
}
    ${AnnotationFragmentDoc}`;

/**
 * __useEventWithAnnotationsQuery__
 *
 * To run a query within a React component, call `useEventWithAnnotationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventWithAnnotationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventWithAnnotationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      entityFilter: // value for 'entityFilter'
 *      portfolioFilter: // value for 'portfolioFilter'
 *   },
 * });
 */
export function useEventWithAnnotationsQuery(baseOptions: Apollo.QueryHookOptions<Types.EventWithAnnotationsQuery, Types.EventWithAnnotationsQueryVariables> & ({ variables: Types.EventWithAnnotationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EventWithAnnotationsQuery, Types.EventWithAnnotationsQueryVariables>(EventWithAnnotationsDocument, options);
      }
export function useEventWithAnnotationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EventWithAnnotationsQuery, Types.EventWithAnnotationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EventWithAnnotationsQuery, Types.EventWithAnnotationsQueryVariables>(EventWithAnnotationsDocument, options);
        }
export function useEventWithAnnotationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.EventWithAnnotationsQuery, Types.EventWithAnnotationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EventWithAnnotationsQuery, Types.EventWithAnnotationsQueryVariables>(EventWithAnnotationsDocument, options);
        }
export type EventWithAnnotationsQueryHookResult = ReturnType<typeof useEventWithAnnotationsQuery>;
export type EventWithAnnotationsLazyQueryHookResult = ReturnType<typeof useEventWithAnnotationsLazyQuery>;
export type EventWithAnnotationsSuspenseQueryHookResult = ReturnType<typeof useEventWithAnnotationsSuspenseQuery>;
export type EventWithAnnotationsQueryResult = Apollo.QueryResult<Types.EventWithAnnotationsQuery, Types.EventWithAnnotationsQueryVariables>;
export const EventsFeedDocument = gql`
    query EventsFeed($entity: ID!, $portfolio: ID!, $team: ID!, $settings: EventSettingsInput!) {
  entity(id: $entity) {
    id
    name
    eventsFeed: events(settings: $settings, portfolio: $portfolio, team: $team) {
      edges {
        ...EventEdge
      }
      pageInfo {
        hasNextPage
      }
      hasMoreRelevant @client
      hasMoreIrrelevant @client
    }
  }
}
    ${EventEdgeFragmentDoc}`;

/**
 * __useEventsFeedQuery__
 *
 * To run a query within a React component, call `useEventsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsFeedQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *      portfolio: // value for 'portfolio'
 *      team: // value for 'team'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useEventsFeedQuery(baseOptions: Apollo.QueryHookOptions<Types.EventsFeedQuery, Types.EventsFeedQueryVariables> & ({ variables: Types.EventsFeedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EventsFeedQuery, Types.EventsFeedQueryVariables>(EventsFeedDocument, options);
      }
export function useEventsFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EventsFeedQuery, Types.EventsFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EventsFeedQuery, Types.EventsFeedQueryVariables>(EventsFeedDocument, options);
        }
export function useEventsFeedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.EventsFeedQuery, Types.EventsFeedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EventsFeedQuery, Types.EventsFeedQueryVariables>(EventsFeedDocument, options);
        }
export type EventsFeedQueryHookResult = ReturnType<typeof useEventsFeedQuery>;
export type EventsFeedLazyQueryHookResult = ReturnType<typeof useEventsFeedLazyQuery>;
export type EventsFeedSuspenseQueryHookResult = ReturnType<typeof useEventsFeedSuspenseQuery>;
export type EventsFeedQueryResult = Apollo.QueryResult<Types.EventsFeedQuery, Types.EventsFeedQueryVariables>;
export const CompareCompaniesFinancialsDocument = gql`
    query CompareCompaniesFinancials($team: ID!, $portfolio: ID!, $selectedCompany: ID!, $ids: [ID!]!, $year: Int!) {
  compareCompanies(ids: $ids, year: $year, consolidated: false) {
    financials {
      ...FinancialsSection
    }
  }
  selectedCompany: company(id: $selectedCompany) {
    id
    ...CompareCompanyFinancials
  }
}
    ${FinancialsSectionFragmentDoc}
${CompareCompanyFinancialsFragmentDoc}`;

/**
 * __useCompareCompaniesFinancialsQuery__
 *
 * To run a query within a React component, call `useCompareCompaniesFinancialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompareCompaniesFinancialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareCompaniesFinancialsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      selectedCompany: // value for 'selectedCompany'
 *      ids: // value for 'ids'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useCompareCompaniesFinancialsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompareCompaniesFinancialsQuery, Types.CompareCompaniesFinancialsQueryVariables> & ({ variables: Types.CompareCompaniesFinancialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompareCompaniesFinancialsQuery, Types.CompareCompaniesFinancialsQueryVariables>(CompareCompaniesFinancialsDocument, options);
      }
export function useCompareCompaniesFinancialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompareCompaniesFinancialsQuery, Types.CompareCompaniesFinancialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompareCompaniesFinancialsQuery, Types.CompareCompaniesFinancialsQueryVariables>(CompareCompaniesFinancialsDocument, options);
        }
export function useCompareCompaniesFinancialsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CompareCompaniesFinancialsQuery, Types.CompareCompaniesFinancialsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompareCompaniesFinancialsQuery, Types.CompareCompaniesFinancialsQueryVariables>(CompareCompaniesFinancialsDocument, options);
        }
export type CompareCompaniesFinancialsQueryHookResult = ReturnType<typeof useCompareCompaniesFinancialsQuery>;
export type CompareCompaniesFinancialsLazyQueryHookResult = ReturnType<typeof useCompareCompaniesFinancialsLazyQuery>;
export type CompareCompaniesFinancialsSuspenseQueryHookResult = ReturnType<typeof useCompareCompaniesFinancialsSuspenseQuery>;
export type CompareCompaniesFinancialsQueryResult = Apollo.QueryResult<Types.CompareCompaniesFinancialsQuery, Types.CompareCompaniesFinancialsQueryVariables>;
export const SimilarCompareCompaniesDocument = gql`
    query SimilarCompareCompanies($company: ID!, $team: ID!, $portfolio: ID!, $size: Int = 6) {
  company(id: $company) {
    id
    name
    similarCompanies(size: $size, team: $team, portfolio: $portfolio, settings: {}) {
      edges {
        node {
          ...CompanyMeta
        }
      }
    }
  }
}
    ${CompanyMetaFragmentDoc}`;

/**
 * __useSimilarCompareCompaniesQuery__
 *
 * To run a query within a React component, call `useSimilarCompareCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarCompareCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarCompareCompaniesQuery({
 *   variables: {
 *      company: // value for 'company'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useSimilarCompareCompaniesQuery(baseOptions: Apollo.QueryHookOptions<Types.SimilarCompareCompaniesQuery, Types.SimilarCompareCompaniesQueryVariables> & ({ variables: Types.SimilarCompareCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SimilarCompareCompaniesQuery, Types.SimilarCompareCompaniesQueryVariables>(SimilarCompareCompaniesDocument, options);
      }
export function useSimilarCompareCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SimilarCompareCompaniesQuery, Types.SimilarCompareCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SimilarCompareCompaniesQuery, Types.SimilarCompareCompaniesQueryVariables>(SimilarCompareCompaniesDocument, options);
        }
export function useSimilarCompareCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.SimilarCompareCompaniesQuery, Types.SimilarCompareCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SimilarCompareCompaniesQuery, Types.SimilarCompareCompaniesQueryVariables>(SimilarCompareCompaniesDocument, options);
        }
export type SimilarCompareCompaniesQueryHookResult = ReturnType<typeof useSimilarCompareCompaniesQuery>;
export type SimilarCompareCompaniesLazyQueryHookResult = ReturnType<typeof useSimilarCompareCompaniesLazyQuery>;
export type SimilarCompareCompaniesSuspenseQueryHookResult = ReturnType<typeof useSimilarCompareCompaniesSuspenseQuery>;
export type SimilarCompareCompaniesQueryResult = Apollo.QueryResult<Types.SimilarCompareCompaniesQuery, Types.SimilarCompareCompaniesQueryVariables>;
export const PositiveFlagKindsDocument = gql`
    query PositiveFlagKinds($countries: [ContentLanguage!]) {
  positiveFlagKinds(countries: $countries) {
    ...FlagKindInfo
  }
}
    ${FlagKindInfoFragmentDoc}`;

/**
 * __usePositiveFlagKindsQuery__
 *
 * To run a query within a React component, call `usePositiveFlagKindsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositiveFlagKindsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositiveFlagKindsQuery({
 *   variables: {
 *      countries: // value for 'countries'
 *   },
 * });
 */
export function usePositiveFlagKindsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PositiveFlagKindsQuery, Types.PositiveFlagKindsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PositiveFlagKindsQuery, Types.PositiveFlagKindsQueryVariables>(PositiveFlagKindsDocument, options);
      }
export function usePositiveFlagKindsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PositiveFlagKindsQuery, Types.PositiveFlagKindsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PositiveFlagKindsQuery, Types.PositiveFlagKindsQueryVariables>(PositiveFlagKindsDocument, options);
        }
export function usePositiveFlagKindsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.PositiveFlagKindsQuery, Types.PositiveFlagKindsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PositiveFlagKindsQuery, Types.PositiveFlagKindsQueryVariables>(PositiveFlagKindsDocument, options);
        }
export type PositiveFlagKindsQueryHookResult = ReturnType<typeof usePositiveFlagKindsQuery>;
export type PositiveFlagKindsLazyQueryHookResult = ReturnType<typeof usePositiveFlagKindsLazyQuery>;
export type PositiveFlagKindsSuspenseQueryHookResult = ReturnType<typeof usePositiveFlagKindsSuspenseQuery>;
export type PositiveFlagKindsQueryResult = Apollo.QueryResult<Types.PositiveFlagKindsQuery, Types.PositiveFlagKindsQueryVariables>;
export const GlobalEntitySimilarEntitiesDocument = gql`
    query GlobalEntitySimilarEntities($team: ID!, $id: ID!, $limit: Int) {
  entity(id: $id) {
    id
    ...BaseEntityLike
    ... on GlobalCompany {
      similarEntities(team: $team, limit: $limit) {
        edges {
          node {
            ...BaseEntityLike
          }
        }
      }
    }
    ... on GlobalPerson {
      similarEntities(team: $team, limit: $limit) {
        edges {
          node {
            ...BaseEntityLike
          }
        }
      }
    }
    ... on Person {
      similarEntities(team: $team, limit: $limit) {
        edges {
          node {
            ...BaseEntityLike
          }
        }
      }
    }
    ... on Company {
      similarEntities(team: $team, limit: $limit) {
        edges {
          node {
            ...BaseEntityLike
          }
        }
      }
    }
  }
}
    ${BaseEntityLikeFragmentDoc}`;

/**
 * __useGlobalEntitySimilarEntitiesQuery__
 *
 * To run a query within a React component, call `useGlobalEntitySimilarEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalEntitySimilarEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalEntitySimilarEntitiesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGlobalEntitySimilarEntitiesQuery(baseOptions: Apollo.QueryHookOptions<Types.GlobalEntitySimilarEntitiesQuery, Types.GlobalEntitySimilarEntitiesQueryVariables> & ({ variables: Types.GlobalEntitySimilarEntitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GlobalEntitySimilarEntitiesQuery, Types.GlobalEntitySimilarEntitiesQueryVariables>(GlobalEntitySimilarEntitiesDocument, options);
      }
export function useGlobalEntitySimilarEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GlobalEntitySimilarEntitiesQuery, Types.GlobalEntitySimilarEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GlobalEntitySimilarEntitiesQuery, Types.GlobalEntitySimilarEntitiesQueryVariables>(GlobalEntitySimilarEntitiesDocument, options);
        }
export function useGlobalEntitySimilarEntitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GlobalEntitySimilarEntitiesQuery, Types.GlobalEntitySimilarEntitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GlobalEntitySimilarEntitiesQuery, Types.GlobalEntitySimilarEntitiesQueryVariables>(GlobalEntitySimilarEntitiesDocument, options);
        }
export type GlobalEntitySimilarEntitiesQueryHookResult = ReturnType<typeof useGlobalEntitySimilarEntitiesQuery>;
export type GlobalEntitySimilarEntitiesLazyQueryHookResult = ReturnType<typeof useGlobalEntitySimilarEntitiesLazyQuery>;
export type GlobalEntitySimilarEntitiesSuspenseQueryHookResult = ReturnType<typeof useGlobalEntitySimilarEntitiesSuspenseQuery>;
export type GlobalEntitySimilarEntitiesQueryResult = Apollo.QueryResult<Types.GlobalEntitySimilarEntitiesQuery, Types.GlobalEntitySimilarEntitiesQueryVariables>;
export const RequestIdvVerificationDocument = gql`
    mutation RequestIdvVerification($person: ID!, $company: ID!, $team: ID!) {
  requestIdentityVerification(person: $person, company: $company, team: $team) {
    ...IdvRequest
  }
}
    ${IdvRequestFragmentDoc}`;
export type RequestIdvVerificationMutationFn = Apollo.MutationFunction<Types.RequestIdvVerificationMutation, Types.RequestIdvVerificationMutationVariables>;

/**
 * __useRequestIdvVerificationMutation__
 *
 * To run a mutation, you first call `useRequestIdvVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestIdvVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestIdvVerificationMutation, { data, loading, error }] = useRequestIdvVerificationMutation({
 *   variables: {
 *      person: // value for 'person'
 *      company: // value for 'company'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useRequestIdvVerificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestIdvVerificationMutation, Types.RequestIdvVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestIdvVerificationMutation, Types.RequestIdvVerificationMutationVariables>(RequestIdvVerificationDocument, options);
      }
export type RequestIdvVerificationMutationHookResult = ReturnType<typeof useRequestIdvVerificationMutation>;
export type RequestIdvVerificationMutationResult = Apollo.MutationResult<Types.RequestIdvVerificationMutation>;
export type RequestIdvVerificationMutationOptions = Apollo.BaseMutationOptions<Types.RequestIdvVerificationMutation, Types.RequestIdvVerificationMutationVariables>;
export const UpdateIdvStatusDocument = gql`
    mutation UpdateIdvStatus($person: ID!, $company: ID!, $team: ID!, $status: IdentityVerificationStatus!) {
  updateIdentityVerificationStatus(
    person: $person
    company: $company
    team: $team
    status: $status
  ) {
    ...IdvRequest
  }
}
    ${IdvRequestFragmentDoc}`;
export type UpdateIdvStatusMutationFn = Apollo.MutationFunction<Types.UpdateIdvStatusMutation, Types.UpdateIdvStatusMutationVariables>;

/**
 * __useUpdateIdvStatusMutation__
 *
 * To run a mutation, you first call `useUpdateIdvStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIdvStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIdvStatusMutation, { data, loading, error }] = useUpdateIdvStatusMutation({
 *   variables: {
 *      person: // value for 'person'
 *      company: // value for 'company'
 *      team: // value for 'team'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateIdvStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateIdvStatusMutation, Types.UpdateIdvStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateIdvStatusMutation, Types.UpdateIdvStatusMutationVariables>(UpdateIdvStatusDocument, options);
      }
export type UpdateIdvStatusMutationHookResult = ReturnType<typeof useUpdateIdvStatusMutation>;
export type UpdateIdvStatusMutationResult = Apollo.MutationResult<Types.UpdateIdvStatusMutation>;
export type UpdateIdvStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateIdvStatusMutation, Types.UpdateIdvStatusMutationVariables>;
export const IdvDocumentInfosDocument = gql`
    query IdvDocumentInfos($id: ID!, $team: ID!) {
  idvDocumentsInformation(id: $id, team: $team) {
    ...IdvDocumentInfo
  }
}
    ${IdvDocumentInfoFragmentDoc}`;

/**
 * __useIdvDocumentInfosQuery__
 *
 * To run a query within a React component, call `useIdvDocumentInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdvDocumentInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdvDocumentInfosQuery({
 *   variables: {
 *      id: // value for 'id'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useIdvDocumentInfosQuery(baseOptions: Apollo.QueryHookOptions<Types.IdvDocumentInfosQuery, Types.IdvDocumentInfosQueryVariables> & ({ variables: Types.IdvDocumentInfosQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IdvDocumentInfosQuery, Types.IdvDocumentInfosQueryVariables>(IdvDocumentInfosDocument, options);
      }
export function useIdvDocumentInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IdvDocumentInfosQuery, Types.IdvDocumentInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IdvDocumentInfosQuery, Types.IdvDocumentInfosQueryVariables>(IdvDocumentInfosDocument, options);
        }
export function useIdvDocumentInfosSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.IdvDocumentInfosQuery, Types.IdvDocumentInfosQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IdvDocumentInfosQuery, Types.IdvDocumentInfosQueryVariables>(IdvDocumentInfosDocument, options);
        }
export type IdvDocumentInfosQueryHookResult = ReturnType<typeof useIdvDocumentInfosQuery>;
export type IdvDocumentInfosLazyQueryHookResult = ReturnType<typeof useIdvDocumentInfosLazyQuery>;
export type IdvDocumentInfosSuspenseQueryHookResult = ReturnType<typeof useIdvDocumentInfosSuspenseQuery>;
export type IdvDocumentInfosQueryResult = Apollo.QueryResult<Types.IdvDocumentInfosQuery, Types.IdvDocumentInfosQueryVariables>;
export const IdvDocumentsDocument = gql`
    query IdvDocuments($id: ID!, $team: ID!, $documentReferences: [String!]!) {
  idvDocuments(id: $id, team: $team, documentReferences: $documentReferences)
}
    `;

/**
 * __useIdvDocumentsQuery__
 *
 * To run a query within a React component, call `useIdvDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdvDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdvDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      team: // value for 'team'
 *      documentReferences: // value for 'documentReferences'
 *   },
 * });
 */
export function useIdvDocumentsQuery(baseOptions: Apollo.QueryHookOptions<Types.IdvDocumentsQuery, Types.IdvDocumentsQueryVariables> & ({ variables: Types.IdvDocumentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IdvDocumentsQuery, Types.IdvDocumentsQueryVariables>(IdvDocumentsDocument, options);
      }
export function useIdvDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IdvDocumentsQuery, Types.IdvDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IdvDocumentsQuery, Types.IdvDocumentsQueryVariables>(IdvDocumentsDocument, options);
        }
export function useIdvDocumentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.IdvDocumentsQuery, Types.IdvDocumentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IdvDocumentsQuery, Types.IdvDocumentsQueryVariables>(IdvDocumentsDocument, options);
        }
export type IdvDocumentsQueryHookResult = ReturnType<typeof useIdvDocumentsQuery>;
export type IdvDocumentsLazyQueryHookResult = ReturnType<typeof useIdvDocumentsLazyQuery>;
export type IdvDocumentsSuspenseQueryHookResult = ReturnType<typeof useIdvDocumentsSuspenseQuery>;
export type IdvDocumentsQueryResult = Apollo.QueryResult<Types.IdvDocumentsQuery, Types.IdvDocumentsQueryVariables>;
export const IdvRolesDocument = gql`
    query IdvRoles($entity: ID!, $team: ID!) {
  company(id: $entity) {
    id
    idvRoles(team: $team) {
      ...IdvRole
    }
  }
}
    ${IdvRoleFragmentDoc}`;

/**
 * __useIdvRolesQuery__
 *
 * To run a query within a React component, call `useIdvRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdvRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdvRolesQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useIdvRolesQuery(baseOptions: Apollo.QueryHookOptions<Types.IdvRolesQuery, Types.IdvRolesQueryVariables> & ({ variables: Types.IdvRolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IdvRolesQuery, Types.IdvRolesQueryVariables>(IdvRolesDocument, options);
      }
export function useIdvRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IdvRolesQuery, Types.IdvRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IdvRolesQuery, Types.IdvRolesQueryVariables>(IdvRolesDocument, options);
        }
export function useIdvRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.IdvRolesQuery, Types.IdvRolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IdvRolesQuery, Types.IdvRolesQueryVariables>(IdvRolesDocument, options);
        }
export type IdvRolesQueryHookResult = ReturnType<typeof useIdvRolesQuery>;
export type IdvRolesLazyQueryHookResult = ReturnType<typeof useIdvRolesLazyQuery>;
export type IdvRolesSuspenseQueryHookResult = ReturnType<typeof useIdvRolesSuspenseQuery>;
export type IdvRolesQueryResult = Apollo.QueryResult<Types.IdvRolesQuery, Types.IdvRolesQueryVariables>;
export const AllIndustriesDocument = gql`
    query AllIndustries($countries: [ContentLanguage!]!) {
  industries {
    edges {
      node {
        ...IndustryWithChildren
      }
    }
  }
}
    ${IndustryWithChildrenFragmentDoc}`;

/**
 * __useAllIndustriesQuery__
 *
 * To run a query within a React component, call `useAllIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIndustriesQuery({
 *   variables: {
 *      countries: // value for 'countries'
 *   },
 * });
 */
export function useAllIndustriesQuery(baseOptions: Apollo.QueryHookOptions<Types.AllIndustriesQuery, Types.AllIndustriesQueryVariables> & ({ variables: Types.AllIndustriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllIndustriesQuery, Types.AllIndustriesQueryVariables>(AllIndustriesDocument, options);
      }
export function useAllIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllIndustriesQuery, Types.AllIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllIndustriesQuery, Types.AllIndustriesQueryVariables>(AllIndustriesDocument, options);
        }
export function useAllIndustriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.AllIndustriesQuery, Types.AllIndustriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AllIndustriesQuery, Types.AllIndustriesQueryVariables>(AllIndustriesDocument, options);
        }
export type AllIndustriesQueryHookResult = ReturnType<typeof useAllIndustriesQuery>;
export type AllIndustriesLazyQueryHookResult = ReturnType<typeof useAllIndustriesLazyQuery>;
export type AllIndustriesSuspenseQueryHookResult = ReturnType<typeof useAllIndustriesSuspenseQuery>;
export type AllIndustriesQueryResult = Apollo.QueryResult<Types.AllIndustriesQuery, Types.AllIndustriesQueryVariables>;
export const IndustriesDocument = gql`
    query Industries($locationFilter: LocationFilterInput) {
  industries {
    edges {
      node {
        id
        ...Industry
        children(locationFilter: $locationFilter) {
          edges {
            node {
              id
              ...Industry
            }
          }
        }
      }
    }
  }
}
    ${IndustryFragmentDoc}`;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *      locationFilter: // value for 'locationFilter'
 *   },
 * });
 */
export function useIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.IndustriesQuery, Types.IndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IndustriesQuery, Types.IndustriesQueryVariables>(IndustriesDocument, options);
      }
export function useIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IndustriesQuery, Types.IndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IndustriesQuery, Types.IndustriesQueryVariables>(IndustriesDocument, options);
        }
export function useIndustriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.IndustriesQuery, Types.IndustriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IndustriesQuery, Types.IndustriesQueryVariables>(IndustriesDocument, options);
        }
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<typeof useIndustriesLazyQuery>;
export type IndustriesSuspenseQueryHookResult = ReturnType<typeof useIndustriesSuspenseQuery>;
export type IndustriesQueryResult = Apollo.QueryResult<Types.IndustriesQuery, Types.IndustriesQueryVariables>;
export const IndustryDocument = gql`
    query Industry($id: ID!) {
  industry(id: $id) {
    id
    ...Industry
  }
}
    ${IndustryFragmentDoc}`;

/**
 * __useIndustryQuery__
 *
 * To run a query within a React component, call `useIndustryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIndustryQuery(baseOptions: Apollo.QueryHookOptions<Types.IndustryQuery, Types.IndustryQueryVariables> & ({ variables: Types.IndustryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IndustryQuery, Types.IndustryQueryVariables>(IndustryDocument, options);
      }
export function useIndustryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IndustryQuery, Types.IndustryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IndustryQuery, Types.IndustryQueryVariables>(IndustryDocument, options);
        }
export function useIndustrySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.IndustryQuery, Types.IndustryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IndustryQuery, Types.IndustryQueryVariables>(IndustryDocument, options);
        }
export type IndustryQueryHookResult = ReturnType<typeof useIndustryQuery>;
export type IndustryLazyQueryHookResult = ReturnType<typeof useIndustryLazyQuery>;
export type IndustrySuspenseQueryHookResult = ReturnType<typeof useIndustrySuspenseQuery>;
export type IndustryQueryResult = Apollo.QueryResult<Types.IndustryQuery, Types.IndustryQueryVariables>;
export const LegalFormsDocument = gql`
    query LegalForms($countries: [ContentLanguage!]) {
  legalForms(countries: $countries) {
    edges {
      node {
        id
        ...LegalForm
      }
    }
  }
}
    ${LegalFormFragmentDoc}`;

/**
 * __useLegalFormsQuery__
 *
 * To run a query within a React component, call `useLegalFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalFormsQuery({
 *   variables: {
 *      countries: // value for 'countries'
 *   },
 * });
 */
export function useLegalFormsQuery(baseOptions?: Apollo.QueryHookOptions<Types.LegalFormsQuery, Types.LegalFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LegalFormsQuery, Types.LegalFormsQueryVariables>(LegalFormsDocument, options);
      }
export function useLegalFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LegalFormsQuery, Types.LegalFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LegalFormsQuery, Types.LegalFormsQueryVariables>(LegalFormsDocument, options);
        }
export function useLegalFormsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.LegalFormsQuery, Types.LegalFormsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LegalFormsQuery, Types.LegalFormsQueryVariables>(LegalFormsDocument, options);
        }
export type LegalFormsQueryHookResult = ReturnType<typeof useLegalFormsQuery>;
export type LegalFormsLazyQueryHookResult = ReturnType<typeof useLegalFormsLazyQuery>;
export type LegalFormsSuspenseQueryHookResult = ReturnType<typeof useLegalFormsSuspenseQuery>;
export type LegalFormsQueryResult = Apollo.QueryResult<Types.LegalFormsQuery, Types.LegalFormsQueryVariables>;
export const CountriesDocument = gql`
    query Countries {
  countries(includeCollections: true) {
    edges {
      node {
        ...CountryOrCollection
      }
    }
  }
}
    ${CountryOrCollectionFragmentDoc}`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesQuery, Types.CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesQuery, Types.CountriesQueryVariables>(CountriesDocument, options);
        }
export function useCountriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountriesQuery, Types.CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesSuspenseQueryHookResult = ReturnType<typeof useCountriesSuspenseQuery>;
export type CountriesQueryResult = Apollo.QueryResult<Types.CountriesQuery, Types.CountriesQueryVariables>;
export const DefaultCountriesDocument = gql`
    query DefaultCountries($filter: [ContentLanguage!]) {
  defaultCountries(filter: $filter) {
    edges {
      node {
        id
        ...DefaultLocation
      }
    }
  }
}
    ${DefaultLocationFragmentDoc}`;

/**
 * __useDefaultCountriesQuery__
 *
 * To run a query within a React component, call `useDefaultCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultCountriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDefaultCountriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.DefaultCountriesQuery, Types.DefaultCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DefaultCountriesQuery, Types.DefaultCountriesQueryVariables>(DefaultCountriesDocument, options);
      }
export function useDefaultCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DefaultCountriesQuery, Types.DefaultCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DefaultCountriesQuery, Types.DefaultCountriesQueryVariables>(DefaultCountriesDocument, options);
        }
export function useDefaultCountriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.DefaultCountriesQuery, Types.DefaultCountriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DefaultCountriesQuery, Types.DefaultCountriesQueryVariables>(DefaultCountriesDocument, options);
        }
export type DefaultCountriesQueryHookResult = ReturnType<typeof useDefaultCountriesQuery>;
export type DefaultCountriesLazyQueryHookResult = ReturnType<typeof useDefaultCountriesLazyQuery>;
export type DefaultCountriesSuspenseQueryHookResult = ReturnType<typeof useDefaultCountriesSuspenseQuery>;
export type DefaultCountriesQueryResult = Apollo.QueryResult<Types.DefaultCountriesQuery, Types.DefaultCountriesQueryVariables>;
export const LocationDocument = gql`
    query Location($id: ID!) {
  location(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationQuery(baseOptions: Apollo.QueryHookOptions<Types.LocationQuery, Types.LocationQueryVariables> & ({ variables: Types.LocationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LocationQuery, Types.LocationQueryVariables>(LocationDocument, options);
      }
export function useLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LocationQuery, Types.LocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LocationQuery, Types.LocationQueryVariables>(LocationDocument, options);
        }
export function useLocationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.LocationQuery, Types.LocationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LocationQuery, Types.LocationQueryVariables>(LocationDocument, options);
        }
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>;
export type LocationSuspenseQueryHookResult = ReturnType<typeof useLocationSuspenseQuery>;
export type LocationQueryResult = Apollo.QueryResult<Types.LocationQuery, Types.LocationQueryVariables>;
export const SearchLocationsDocument = gql`
    query SearchLocations($q: String!) {
  locationsV2(q: $q, kind: [MUNICIPALITY, COUNTY]) {
    edges {
      node {
        ...Location
      }
    }
  }
}
    ${LocationFragmentDoc}`;

/**
 * __useSearchLocationsQuery__
 *
 * To run a query within a React component, call `useSearchLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLocationsQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSearchLocationsQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchLocationsQuery, Types.SearchLocationsQueryVariables> & ({ variables: Types.SearchLocationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchLocationsQuery, Types.SearchLocationsQueryVariables>(SearchLocationsDocument, options);
      }
export function useSearchLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchLocationsQuery, Types.SearchLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchLocationsQuery, Types.SearchLocationsQueryVariables>(SearchLocationsDocument, options);
        }
export function useSearchLocationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.SearchLocationsQuery, Types.SearchLocationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchLocationsQuery, Types.SearchLocationsQueryVariables>(SearchLocationsDocument, options);
        }
export type SearchLocationsQueryHookResult = ReturnType<typeof useSearchLocationsQuery>;
export type SearchLocationsLazyQueryHookResult = ReturnType<typeof useSearchLocationsLazyQuery>;
export type SearchLocationsSuspenseQueryHookResult = ReturnType<typeof useSearchLocationsSuspenseQuery>;
export type SearchLocationsQueryResult = Apollo.QueryResult<Types.SearchLocationsQuery, Types.SearchLocationsQueryVariables>;
export const MarkAllNotificationsAsReadDocument = gql`
    mutation MarkAllNotificationsAsRead($size: Int, $offset: Int) {
  markAllNotificationsAsRead {
    notifications(size: $size, offset: $offset) {
      ...BaseNotificationConnection
    }
  }
}
    ${BaseNotificationConnectionFragmentDoc}`;
export type MarkAllNotificationsAsReadMutationFn = Apollo.MutationFunction<Types.MarkAllNotificationsAsReadMutation, Types.MarkAllNotificationsAsReadMutationVariables>;

/**
 * __useMarkAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsAsReadMutation, { data, loading, error }] = useMarkAllNotificationsAsReadMutation({
 *   variables: {
 *      size: // value for 'size'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useMarkAllNotificationsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkAllNotificationsAsReadMutation, Types.MarkAllNotificationsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkAllNotificationsAsReadMutation, Types.MarkAllNotificationsAsReadMutationVariables>(MarkAllNotificationsAsReadDocument, options);
      }
export type MarkAllNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationResult = Apollo.MutationResult<Types.MarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<Types.MarkAllNotificationsAsReadMutation, Types.MarkAllNotificationsAsReadMutationVariables>;
export const UpdateCompanyNotificationSettingsDocument = gql`
    mutation UpdateCompanyNotificationSettings($team: ID!, $entity: ID!, $status: NotificationSettingStatus!, $size: Int = 0, $offset: Int = 7) {
  updateEntityNotificationSettings(
    team: $team
    inputs: [{entity: $entity, status: $status}]
  ) {
    company(id: $entity) {
      id
      ...CompanyNotificationSettings
    }
    notifications(size: $size, offset: $offset) {
      ...NotificationConnection
    }
  }
}
    ${CompanyNotificationSettingsFragmentDoc}
${NotificationConnectionFragmentDoc}`;
export type UpdateCompanyNotificationSettingsMutationFn = Apollo.MutationFunction<Types.UpdateCompanyNotificationSettingsMutation, Types.UpdateCompanyNotificationSettingsMutationVariables>;

/**
 * __useUpdateCompanyNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyNotificationSettingsMutation, { data, loading, error }] = useUpdateCompanyNotificationSettingsMutation({
 *   variables: {
 *      team: // value for 'team'
 *      entity: // value for 'entity'
 *      status: // value for 'status'
 *      size: // value for 'size'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useUpdateCompanyNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyNotificationSettingsMutation, Types.UpdateCompanyNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyNotificationSettingsMutation, Types.UpdateCompanyNotificationSettingsMutationVariables>(UpdateCompanyNotificationSettingsDocument, options);
      }
export type UpdateCompanyNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateCompanyNotificationSettingsMutation>;
export type UpdateCompanyNotificationSettingsMutationResult = Apollo.MutationResult<Types.UpdateCompanyNotificationSettingsMutation>;
export type UpdateCompanyNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyNotificationSettingsMutation, Types.UpdateCompanyNotificationSettingsMutationVariables>;
export const UpdateNotificationDocument = gql`
    mutation UpdateNotification($notification: ID!, $status: NotificationStatus!) {
  updateNotifications(notifications: [$notification], status: $status) {
    notification(id: $notification) {
      id
      ...BaseNotification
    }
  }
}
    ${BaseNotificationFragmentDoc}`;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<Types.UpdateNotificationMutation, Types.UpdateNotificationMutationVariables>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateNotificationMutation, Types.UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateNotificationMutation, Types.UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<Types.UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateNotificationMutation, Types.UpdateNotificationMutationVariables>;
export const UpdateNotificationsDocument = gql`
    mutation UpdateNotifications($notifications: [ID!]!, $status: NotificationStatus!, $size: Int, $offset: Int) {
  updateNotifications(notifications: $notifications, status: $status) {
    notifications(size: $size, offset: $offset) {
      ...BaseNotificationConnection
    }
  }
}
    ${BaseNotificationConnectionFragmentDoc}`;
export type UpdateNotificationsMutationFn = Apollo.MutationFunction<Types.UpdateNotificationsMutation, Types.UpdateNotificationsMutationVariables>;

/**
 * __useUpdateNotificationsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationsMutation, { data, loading, error }] = useUpdateNotificationsMutation({
 *   variables: {
 *      notifications: // value for 'notifications'
 *      status: // value for 'status'
 *      size: // value for 'size'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useUpdateNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateNotificationsMutation, Types.UpdateNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateNotificationsMutation, Types.UpdateNotificationsMutationVariables>(UpdateNotificationsDocument, options);
      }
export type UpdateNotificationsMutationHookResult = ReturnType<typeof useUpdateNotificationsMutation>;
export type UpdateNotificationsMutationResult = Apollo.MutationResult<Types.UpdateNotificationsMutation>;
export type UpdateNotificationsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateNotificationsMutation, Types.UpdateNotificationsMutationVariables>;
export const CompanyNotificationSettingDocument = gql`
    query CompanyNotificationSetting($id: ID!, $team: ID!) {
  company(id: $id) {
    id
    notificationSetting(team: $team) {
      status
    }
  }
}
    `;

/**
 * __useCompanyNotificationSettingQuery__
 *
 * To run a query within a React component, call `useCompanyNotificationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyNotificationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyNotificationSettingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useCompanyNotificationSettingQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyNotificationSettingQuery, Types.CompanyNotificationSettingQueryVariables> & ({ variables: Types.CompanyNotificationSettingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyNotificationSettingQuery, Types.CompanyNotificationSettingQueryVariables>(CompanyNotificationSettingDocument, options);
      }
export function useCompanyNotificationSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyNotificationSettingQuery, Types.CompanyNotificationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyNotificationSettingQuery, Types.CompanyNotificationSettingQueryVariables>(CompanyNotificationSettingDocument, options);
        }
export function useCompanyNotificationSettingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CompanyNotificationSettingQuery, Types.CompanyNotificationSettingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyNotificationSettingQuery, Types.CompanyNotificationSettingQueryVariables>(CompanyNotificationSettingDocument, options);
        }
export type CompanyNotificationSettingQueryHookResult = ReturnType<typeof useCompanyNotificationSettingQuery>;
export type CompanyNotificationSettingLazyQueryHookResult = ReturnType<typeof useCompanyNotificationSettingLazyQuery>;
export type CompanyNotificationSettingSuspenseQueryHookResult = ReturnType<typeof useCompanyNotificationSettingSuspenseQuery>;
export type CompanyNotificationSettingQueryResult = Apollo.QueryResult<Types.CompanyNotificationSettingQuery, Types.CompanyNotificationSettingQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications($size: Int, $offset: Int) {
  notifications(size: $size, offset: $offset) {
    ...NotificationConnection
  }
}
    ${NotificationConnectionFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      size: // value for 'size'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.NotificationsQuery, Types.NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotificationsQuery, Types.NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotificationsQuery, Types.NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotificationsQuery, Types.NotificationsQueryVariables>(NotificationsDocument, options);
        }
export function useNotificationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.NotificationsQuery, Types.NotificationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.NotificationsQuery, Types.NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<Types.NotificationsQuery, Types.NotificationsQueryVariables>;
export const NotificationsUnreadCountDocument = gql`
    query NotificationsUnreadCount {
  notifications {
    unreadCount
  }
}
    `;

/**
 * __useNotificationsUnreadCountQuery__
 *
 * To run a query within a React component, call `useNotificationsUnreadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsUnreadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsUnreadCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsUnreadCountQuery(baseOptions?: Apollo.QueryHookOptions<Types.NotificationsUnreadCountQuery, Types.NotificationsUnreadCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotificationsUnreadCountQuery, Types.NotificationsUnreadCountQueryVariables>(NotificationsUnreadCountDocument, options);
      }
export function useNotificationsUnreadCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotificationsUnreadCountQuery, Types.NotificationsUnreadCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotificationsUnreadCountQuery, Types.NotificationsUnreadCountQueryVariables>(NotificationsUnreadCountDocument, options);
        }
export function useNotificationsUnreadCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.NotificationsUnreadCountQuery, Types.NotificationsUnreadCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.NotificationsUnreadCountQuery, Types.NotificationsUnreadCountQueryVariables>(NotificationsUnreadCountDocument, options);
        }
export type NotificationsUnreadCountQueryHookResult = ReturnType<typeof useNotificationsUnreadCountQuery>;
export type NotificationsUnreadCountLazyQueryHookResult = ReturnType<typeof useNotificationsUnreadCountLazyQuery>;
export type NotificationsUnreadCountSuspenseQueryHookResult = ReturnType<typeof useNotificationsUnreadCountSuspenseQuery>;
export type NotificationsUnreadCountQueryResult = Apollo.QueryResult<Types.NotificationsUnreadCountQuery, Types.NotificationsUnreadCountQueryVariables>;
export const GrowSuggestedCompaniesDocument = gql`
    query GrowSuggestedCompanies($first: Int!, $portfolio: ID!, $team: ID!, $settings: OpportunitySettingsInput!, $eventLanguages: [ContentLanguage!]) {
  growSuggestedCompanies: opportunityCompanies(
    first: $first
    portfolio: $portfolio
    team: $team
    settings: $settings
  ) {
    edges {
      node {
        id
        ...GrowSuggestedCompany
      }
    }
  }
}
    ${GrowSuggestedCompanyFragmentDoc}`;

/**
 * __useGrowSuggestedCompaniesQuery__
 *
 * To run a query within a React component, call `useGrowSuggestedCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrowSuggestedCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrowSuggestedCompaniesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      portfolio: // value for 'portfolio'
 *      team: // value for 'team'
 *      settings: // value for 'settings'
 *      eventLanguages: // value for 'eventLanguages'
 *   },
 * });
 */
export function useGrowSuggestedCompaniesQuery(baseOptions: Apollo.QueryHookOptions<Types.GrowSuggestedCompaniesQuery, Types.GrowSuggestedCompaniesQueryVariables> & ({ variables: Types.GrowSuggestedCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GrowSuggestedCompaniesQuery, Types.GrowSuggestedCompaniesQueryVariables>(GrowSuggestedCompaniesDocument, options);
      }
export function useGrowSuggestedCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GrowSuggestedCompaniesQuery, Types.GrowSuggestedCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GrowSuggestedCompaniesQuery, Types.GrowSuggestedCompaniesQueryVariables>(GrowSuggestedCompaniesDocument, options);
        }
export function useGrowSuggestedCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GrowSuggestedCompaniesQuery, Types.GrowSuggestedCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GrowSuggestedCompaniesQuery, Types.GrowSuggestedCompaniesQueryVariables>(GrowSuggestedCompaniesDocument, options);
        }
export type GrowSuggestedCompaniesQueryHookResult = ReturnType<typeof useGrowSuggestedCompaniesQuery>;
export type GrowSuggestedCompaniesLazyQueryHookResult = ReturnType<typeof useGrowSuggestedCompaniesLazyQuery>;
export type GrowSuggestedCompaniesSuspenseQueryHookResult = ReturnType<typeof useGrowSuggestedCompaniesSuspenseQuery>;
export type GrowSuggestedCompaniesQueryResult = Apollo.QueryResult<Types.GrowSuggestedCompaniesQuery, Types.GrowSuggestedCompaniesQueryVariables>;
export const SimilarCompaniesDocument = gql`
    query SimilarCompanies($company: ID!, $team: ID!, $portfolio: ID!, $size: Int = 6, $settings: OpportunitySettingsInput!, $eventLanguages: [ContentLanguage!]) {
  company(id: $company) {
    id
    similarCompanies(
      size: $size
      team: $team
      portfolio: $portfolio
      settings: $settings
    ) {
      edges {
        node {
          id
          ...GrowSuggestedCompany
        }
      }
    }
  }
}
    ${GrowSuggestedCompanyFragmentDoc}`;

/**
 * __useSimilarCompaniesQuery__
 *
 * To run a query within a React component, call `useSimilarCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarCompaniesQuery({
 *   variables: {
 *      company: // value for 'company'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      size: // value for 'size'
 *      settings: // value for 'settings'
 *      eventLanguages: // value for 'eventLanguages'
 *   },
 * });
 */
export function useSimilarCompaniesQuery(baseOptions: Apollo.QueryHookOptions<Types.SimilarCompaniesQuery, Types.SimilarCompaniesQueryVariables> & ({ variables: Types.SimilarCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SimilarCompaniesQuery, Types.SimilarCompaniesQueryVariables>(SimilarCompaniesDocument, options);
      }
export function useSimilarCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SimilarCompaniesQuery, Types.SimilarCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SimilarCompaniesQuery, Types.SimilarCompaniesQueryVariables>(SimilarCompaniesDocument, options);
        }
export function useSimilarCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.SimilarCompaniesQuery, Types.SimilarCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SimilarCompaniesQuery, Types.SimilarCompaniesQueryVariables>(SimilarCompaniesDocument, options);
        }
export type SimilarCompaniesQueryHookResult = ReturnType<typeof useSimilarCompaniesQuery>;
export type SimilarCompaniesLazyQueryHookResult = ReturnType<typeof useSimilarCompaniesLazyQuery>;
export type SimilarCompaniesSuspenseQueryHookResult = ReturnType<typeof useSimilarCompaniesSuspenseQuery>;
export type SimilarCompaniesQueryResult = Apollo.QueryResult<Types.SimilarCompaniesQuery, Types.SimilarCompaniesQueryVariables>;
export const MergeEntityDocument = gql`
    mutation MergeEntity($team: ID!, $portfolio: ID!, $entity: ID!, $input: UpdateEntityOwnerInput!, $ignoreCustomOwnerships: Boolean!, $withFlags: Boolean = false, $sidepanelEntity: ID!, $includePepV1: Boolean = false, $includePepV2: Boolean = false, $includeNetworkRoles: Boolean, $includeControlDetails: Boolean = false, $beneficialOwnerThreshold: Float!) {
  mergeEntity(team: $team, entity: $entity, input: $input) {
    entity(id: $sidepanelEntity) {
      ...Owners
      ... on Company {
        controlDetails(team: $team, beneficialOwnerThreshold: $beneficialOwnerThreshold) @include(if: $includeControlDetails) {
          ...ControlDetails
        }
        riskSummary(team: $team, includeNetworkRoles: $includeNetworkRoles) {
          ...RiskSummary
        }
        companyNetwork(team: $team, includeNetworkRoles: $includeNetworkRoles) {
          ...CompanyNetwork
        }
      }
    }
  }
}
    ${OwnersFragmentDoc}
${ControlDetailsFragmentDoc}
${RiskSummaryFragmentDoc}
${CompanyNetworkFragmentDoc}`;
export type MergeEntityMutationFn = Apollo.MutationFunction<Types.MergeEntityMutation, Types.MergeEntityMutationVariables>;

/**
 * __useMergeEntityMutation__
 *
 * To run a mutation, you first call `useMergeEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeEntityMutation, { data, loading, error }] = useMergeEntityMutation({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      entity: // value for 'entity'
 *      input: // value for 'input'
 *      ignoreCustomOwnerships: // value for 'ignoreCustomOwnerships'
 *      withFlags: // value for 'withFlags'
 *      sidepanelEntity: // value for 'sidepanelEntity'
 *      includePepV1: // value for 'includePepV1'
 *      includePepV2: // value for 'includePepV2'
 *      includeNetworkRoles: // value for 'includeNetworkRoles'
 *      includeControlDetails: // value for 'includeControlDetails'
 *      beneficialOwnerThreshold: // value for 'beneficialOwnerThreshold'
 *   },
 * });
 */
export function useMergeEntityMutation(baseOptions?: Apollo.MutationHookOptions<Types.MergeEntityMutation, Types.MergeEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MergeEntityMutation, Types.MergeEntityMutationVariables>(MergeEntityDocument, options);
      }
export type MergeEntityMutationHookResult = ReturnType<typeof useMergeEntityMutation>;
export type MergeEntityMutationResult = Apollo.MutationResult<Types.MergeEntityMutation>;
export type MergeEntityMutationOptions = Apollo.BaseMutationOptions<Types.MergeEntityMutation, Types.MergeEntityMutationVariables>;
export const ResetEntityOwnersDocument = gql`
    mutation ResetEntityOwners($team: ID!, $portfolio: ID!, $entity: ID!, $ignoreCustomOwnerships: Boolean, $withFlags: Boolean = true, $includePepV1: Boolean = false, $includePepV2: Boolean = false, $includeNetworkRoles: Boolean, $includeControlDetails: Boolean = false, $beneficialOwnerThreshold: Float!) {
  resetEntityOwners(team: $team, entity: $entity) {
    entity(id: $entity) {
      ...Owners
      ... on Company {
        controlDetails(team: $team, beneficialOwnerThreshold: $beneficialOwnerThreshold) @include(if: $includeControlDetails) {
          ...ControlDetails
        }
        riskSummary(team: $team, includeNetworkRoles: $includeNetworkRoles) {
          ...RiskSummary
        }
        companyNetwork(team: $team, includeNetworkRoles: $includeNetworkRoles) {
          ...CompanyNetwork
        }
      }
    }
  }
}
    ${OwnersFragmentDoc}
${ControlDetailsFragmentDoc}
${RiskSummaryFragmentDoc}
${CompanyNetworkFragmentDoc}`;
export type ResetEntityOwnersMutationFn = Apollo.MutationFunction<Types.ResetEntityOwnersMutation, Types.ResetEntityOwnersMutationVariables>;

/**
 * __useResetEntityOwnersMutation__
 *
 * To run a mutation, you first call `useResetEntityOwnersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetEntityOwnersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetEntityOwnersMutation, { data, loading, error }] = useResetEntityOwnersMutation({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      entity: // value for 'entity'
 *      ignoreCustomOwnerships: // value for 'ignoreCustomOwnerships'
 *      withFlags: // value for 'withFlags'
 *      includePepV1: // value for 'includePepV1'
 *      includePepV2: // value for 'includePepV2'
 *      includeNetworkRoles: // value for 'includeNetworkRoles'
 *      includeControlDetails: // value for 'includeControlDetails'
 *      beneficialOwnerThreshold: // value for 'beneficialOwnerThreshold'
 *   },
 * });
 */
export function useResetEntityOwnersMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetEntityOwnersMutation, Types.ResetEntityOwnersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetEntityOwnersMutation, Types.ResetEntityOwnersMutationVariables>(ResetEntityOwnersDocument, options);
      }
export type ResetEntityOwnersMutationHookResult = ReturnType<typeof useResetEntityOwnersMutation>;
export type ResetEntityOwnersMutationResult = Apollo.MutationResult<Types.ResetEntityOwnersMutation>;
export type ResetEntityOwnersMutationOptions = Apollo.BaseMutationOptions<Types.ResetEntityOwnersMutation, Types.ResetEntityOwnersMutationVariables>;
export const UndoMergeDocument = gql`
    mutation UndoMerge($team: ID!, $portfolio: ID!, $entity: ID!, $ignoreCustomOwnerships: Boolean!, $withFlags: Boolean = false, $sidepanelEntity: ID!, $includePepV1: Boolean = false, $includePepV2: Boolean = false, $includeNetworkRoles: Boolean, $includeControlDetails: Boolean = false, $beneficialOwnerThreshold: Float!) {
  undoMerge(team: $team, entity: $entity) {
    entity(id: $sidepanelEntity) {
      ...Owners
      ... on Company {
        controlDetails(team: $team, beneficialOwnerThreshold: $beneficialOwnerThreshold) @include(if: $includeControlDetails) {
          ...ControlDetails
        }
        riskSummary(team: $team, includeNetworkRoles: $includeNetworkRoles) {
          ...RiskSummary
        }
        companyNetwork(team: $team, includeNetworkRoles: $includeNetworkRoles) {
          ...CompanyNetwork
        }
      }
    }
  }
}
    ${OwnersFragmentDoc}
${ControlDetailsFragmentDoc}
${RiskSummaryFragmentDoc}
${CompanyNetworkFragmentDoc}`;
export type UndoMergeMutationFn = Apollo.MutationFunction<Types.UndoMergeMutation, Types.UndoMergeMutationVariables>;

/**
 * __useUndoMergeMutation__
 *
 * To run a mutation, you first call `useUndoMergeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoMergeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoMergeMutation, { data, loading, error }] = useUndoMergeMutation({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      entity: // value for 'entity'
 *      ignoreCustomOwnerships: // value for 'ignoreCustomOwnerships'
 *      withFlags: // value for 'withFlags'
 *      sidepanelEntity: // value for 'sidepanelEntity'
 *      includePepV1: // value for 'includePepV1'
 *      includePepV2: // value for 'includePepV2'
 *      includeNetworkRoles: // value for 'includeNetworkRoles'
 *      includeControlDetails: // value for 'includeControlDetails'
 *      beneficialOwnerThreshold: // value for 'beneficialOwnerThreshold'
 *   },
 * });
 */
export function useUndoMergeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UndoMergeMutation, Types.UndoMergeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UndoMergeMutation, Types.UndoMergeMutationVariables>(UndoMergeDocument, options);
      }
export type UndoMergeMutationHookResult = ReturnType<typeof useUndoMergeMutation>;
export type UndoMergeMutationResult = Apollo.MutationResult<Types.UndoMergeMutation>;
export type UndoMergeMutationOptions = Apollo.BaseMutationOptions<Types.UndoMergeMutation, Types.UndoMergeMutationVariables>;
export const UpdateEntityOwnerDocument = gql`
    mutation UpdateEntityOwner($team: ID!, $portfolio: ID!, $entity: ID!, $input: UpdateEntityOwnerInput!, $ignoreCustomOwnerships: Boolean!, $withFlags: Boolean = false, $sidepanelEntity: ID!, $includePepV1: Boolean = false, $includePepV2: Boolean = false, $includeNetworkRoles: Boolean, $includeControlDetails: Boolean = false, $beneficialOwnerThreshold: Float!) {
  updateEntityOwner(team: $team, entity: $entity, input: $input) {
    entity(id: $sidepanelEntity) {
      ...Owners
      ... on Company {
        controlDetails(team: $team, beneficialOwnerThreshold: $beneficialOwnerThreshold) @include(if: $includeControlDetails) {
          ...ControlDetails
        }
        riskSummary(team: $team, includeNetworkRoles: $includeNetworkRoles) {
          ...RiskSummary
        }
        companyNetwork(team: $team, includeNetworkRoles: $includeNetworkRoles) {
          ...CompanyNetwork
        }
      }
    }
  }
}
    ${OwnersFragmentDoc}
${ControlDetailsFragmentDoc}
${RiskSummaryFragmentDoc}
${CompanyNetworkFragmentDoc}`;
export type UpdateEntityOwnerMutationFn = Apollo.MutationFunction<Types.UpdateEntityOwnerMutation, Types.UpdateEntityOwnerMutationVariables>;

/**
 * __useUpdateEntityOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateEntityOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityOwnerMutation, { data, loading, error }] = useUpdateEntityOwnerMutation({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      entity: // value for 'entity'
 *      input: // value for 'input'
 *      ignoreCustomOwnerships: // value for 'ignoreCustomOwnerships'
 *      withFlags: // value for 'withFlags'
 *      sidepanelEntity: // value for 'sidepanelEntity'
 *      includePepV1: // value for 'includePepV1'
 *      includePepV2: // value for 'includePepV2'
 *      includeNetworkRoles: // value for 'includeNetworkRoles'
 *      includeControlDetails: // value for 'includeControlDetails'
 *      beneficialOwnerThreshold: // value for 'beneficialOwnerThreshold'
 *   },
 * });
 */
export function useUpdateEntityOwnerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEntityOwnerMutation, Types.UpdateEntityOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEntityOwnerMutation, Types.UpdateEntityOwnerMutationVariables>(UpdateEntityOwnerDocument, options);
      }
export type UpdateEntityOwnerMutationHookResult = ReturnType<typeof useUpdateEntityOwnerMutation>;
export type UpdateEntityOwnerMutationResult = Apollo.MutationResult<Types.UpdateEntityOwnerMutation>;
export type UpdateEntityOwnerMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEntityOwnerMutation, Types.UpdateEntityOwnerMutationVariables>;
export const CompanyOwnerChartDocument = gql`
    query CompanyOwnerChart($team: ID!, $portfolio: ID!, $withFlags: Boolean = true, $entity: ID!, $threshold: Float!, $ignoreCustomOwnerships: Boolean!) {
  entity(id: $entity) {
    id
    ... on Company {
      ownerChart(
        team: $team
        threshold: $threshold
        ignoreCustomOwnerships: $ignoreCustomOwnerships
      ) {
        ...OwnerChart
      }
      owners(
        team: $team
        threshold: $threshold
        ignoreCustomOwnerships: $ignoreCustomOwnerships
      ) {
        ...Table
      }
    }
    ... on GlobalCompany {
      ownerChart(
        team: $team
        threshold: $threshold
        ignoreCustomOwnerships: $ignoreCustomOwnerships
      ) {
        ...OwnerChart
      }
      owners(
        team: $team
        threshold: $threshold
        ignoreCustomOwnerships: $ignoreCustomOwnerships
      ) {
        ...Table
      }
    }
  }
}
    ${OwnerChartFragmentDoc}
${TableFragmentDoc}`;

/**
 * __useCompanyOwnerChartQuery__
 *
 * To run a query within a React component, call `useCompanyOwnerChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOwnerChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOwnerChartQuery({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      withFlags: // value for 'withFlags'
 *      entity: // value for 'entity'
 *      threshold: // value for 'threshold'
 *      ignoreCustomOwnerships: // value for 'ignoreCustomOwnerships'
 *   },
 * });
 */
export function useCompanyOwnerChartQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyOwnerChartQuery, Types.CompanyOwnerChartQueryVariables> & ({ variables: Types.CompanyOwnerChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOwnerChartQuery, Types.CompanyOwnerChartQueryVariables>(CompanyOwnerChartDocument, options);
      }
export function useCompanyOwnerChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOwnerChartQuery, Types.CompanyOwnerChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOwnerChartQuery, Types.CompanyOwnerChartQueryVariables>(CompanyOwnerChartDocument, options);
        }
export function useCompanyOwnerChartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CompanyOwnerChartQuery, Types.CompanyOwnerChartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyOwnerChartQuery, Types.CompanyOwnerChartQueryVariables>(CompanyOwnerChartDocument, options);
        }
export type CompanyOwnerChartQueryHookResult = ReturnType<typeof useCompanyOwnerChartQuery>;
export type CompanyOwnerChartLazyQueryHookResult = ReturnType<typeof useCompanyOwnerChartLazyQuery>;
export type CompanyOwnerChartSuspenseQueryHookResult = ReturnType<typeof useCompanyOwnerChartSuspenseQuery>;
export type CompanyOwnerChartQueryResult = Apollo.QueryResult<Types.CompanyOwnerChartQuery, Types.CompanyOwnerChartQueryVariables>;
export const EntityOwnershipsDocument = gql`
    query EntityOwnerships($id: ID!, $portfolio: ID!, $team: ID!) {
  entity(id: $id) {
    id
    name
    ... on Company {
      ownerships {
        ...ShareholderConnection
      }
    }
    ... on Person {
      ownerships {
        ...ShareholderConnection
      }
    }
    ... on GlobalCompany {
      ownerships {
        ...ShareholderConnection
      }
    }
    ... on GlobalPerson {
      ownerships {
        ...ShareholderConnection
      }
    }
  }
}
    ${ShareholderConnectionFragmentDoc}`;

/**
 * __useEntityOwnershipsQuery__
 *
 * To run a query within a React component, call `useEntityOwnershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityOwnershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityOwnershipsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      portfolio: // value for 'portfolio'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useEntityOwnershipsQuery(baseOptions: Apollo.QueryHookOptions<Types.EntityOwnershipsQuery, Types.EntityOwnershipsQueryVariables> & ({ variables: Types.EntityOwnershipsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EntityOwnershipsQuery, Types.EntityOwnershipsQueryVariables>(EntityOwnershipsDocument, options);
      }
export function useEntityOwnershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EntityOwnershipsQuery, Types.EntityOwnershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EntityOwnershipsQuery, Types.EntityOwnershipsQueryVariables>(EntityOwnershipsDocument, options);
        }
export function useEntityOwnershipsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.EntityOwnershipsQuery, Types.EntityOwnershipsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EntityOwnershipsQuery, Types.EntityOwnershipsQueryVariables>(EntityOwnershipsDocument, options);
        }
export type EntityOwnershipsQueryHookResult = ReturnType<typeof useEntityOwnershipsQuery>;
export type EntityOwnershipsLazyQueryHookResult = ReturnType<typeof useEntityOwnershipsLazyQuery>;
export type EntityOwnershipsSuspenseQueryHookResult = ReturnType<typeof useEntityOwnershipsSuspenseQuery>;
export type EntityOwnershipsQueryResult = Apollo.QueryResult<Types.EntityOwnershipsQuery, Types.EntityOwnershipsQueryVariables>;
export const CreateCustomPepDocument = gql`
    mutation CreateCustomPep($team: ID!, $entity: ID!, $input: CreateCustomPepInput!, $includePepV1: Boolean! = false, $includePepV2: Boolean! = true) {
  createCustomPep(team: $team, entity: $entity, input: $input) {
    person(id: $entity) {
      ...PersonPepInfo
    }
  }
}
    ${PersonPepInfoFragmentDoc}`;
export type CreateCustomPepMutationFn = Apollo.MutationFunction<Types.CreateCustomPepMutation, Types.CreateCustomPepMutationVariables>;

/**
 * __useCreateCustomPepMutation__
 *
 * To run a mutation, you first call `useCreateCustomPepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomPepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomPepMutation, { data, loading, error }] = useCreateCustomPepMutation({
 *   variables: {
 *      team: // value for 'team'
 *      entity: // value for 'entity'
 *      input: // value for 'input'
 *      includePepV1: // value for 'includePepV1'
 *      includePepV2: // value for 'includePepV2'
 *   },
 * });
 */
export function useCreateCustomPepMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCustomPepMutation, Types.CreateCustomPepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCustomPepMutation, Types.CreateCustomPepMutationVariables>(CreateCustomPepDocument, options);
      }
export type CreateCustomPepMutationHookResult = ReturnType<typeof useCreateCustomPepMutation>;
export type CreateCustomPepMutationResult = Apollo.MutationResult<Types.CreateCustomPepMutation>;
export type CreateCustomPepMutationOptions = Apollo.BaseMutationOptions<Types.CreateCustomPepMutation, Types.CreateCustomPepMutationVariables>;
export const CreateEntityDispositionsDocument = gql`
    mutation CreateEntityDispositions($team: ID!, $entity: ID!, $includePepV1: Boolean!, $includePepV2: Boolean!, $inputs: [CreateEntityDispositionInput!]!) {
  createEntityDispositions(team: $team, entity: $entity, inputs: $inputs) {
    person(id: $entity) {
      ...PersonPepInfo
    }
  }
}
    ${PersonPepInfoFragmentDoc}`;
export type CreateEntityDispositionsMutationFn = Apollo.MutationFunction<Types.CreateEntityDispositionsMutation, Types.CreateEntityDispositionsMutationVariables>;

/**
 * __useCreateEntityDispositionsMutation__
 *
 * To run a mutation, you first call `useCreateEntityDispositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityDispositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityDispositionsMutation, { data, loading, error }] = useCreateEntityDispositionsMutation({
 *   variables: {
 *      team: // value for 'team'
 *      entity: // value for 'entity'
 *      includePepV1: // value for 'includePepV1'
 *      includePepV2: // value for 'includePepV2'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateEntityDispositionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateEntityDispositionsMutation, Types.CreateEntityDispositionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateEntityDispositionsMutation, Types.CreateEntityDispositionsMutationVariables>(CreateEntityDispositionsDocument, options);
      }
export type CreateEntityDispositionsMutationHookResult = ReturnType<typeof useCreateEntityDispositionsMutation>;
export type CreateEntityDispositionsMutationResult = Apollo.MutationResult<Types.CreateEntityDispositionsMutation>;
export type CreateEntityDispositionsMutationOptions = Apollo.BaseMutationOptions<Types.CreateEntityDispositionsMutation, Types.CreateEntityDispositionsMutationVariables>;
export const DeleteEntityDispositionsDocument = gql`
    mutation DeleteEntityDispositions($team: ID!, $entity: ID!, $includePepV1: Boolean!, $includePepV2: Boolean!, $kind: EntityDispositionKind!) {
  deleteEntityDispositions(team: $team, entity: $entity, kind: $kind) {
    person(id: $entity) {
      ...PersonPepInfo
    }
  }
}
    ${PersonPepInfoFragmentDoc}`;
export type DeleteEntityDispositionsMutationFn = Apollo.MutationFunction<Types.DeleteEntityDispositionsMutation, Types.DeleteEntityDispositionsMutationVariables>;

/**
 * __useDeleteEntityDispositionsMutation__
 *
 * To run a mutation, you first call `useDeleteEntityDispositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntityDispositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEntityDispositionsMutation, { data, loading, error }] = useDeleteEntityDispositionsMutation({
 *   variables: {
 *      team: // value for 'team'
 *      entity: // value for 'entity'
 *      includePepV1: // value for 'includePepV1'
 *      includePepV2: // value for 'includePepV2'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useDeleteEntityDispositionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteEntityDispositionsMutation, Types.DeleteEntityDispositionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteEntityDispositionsMutation, Types.DeleteEntityDispositionsMutationVariables>(DeleteEntityDispositionsDocument, options);
      }
export type DeleteEntityDispositionsMutationHookResult = ReturnType<typeof useDeleteEntityDispositionsMutation>;
export type DeleteEntityDispositionsMutationResult = Apollo.MutationResult<Types.DeleteEntityDispositionsMutation>;
export type DeleteEntityDispositionsMutationOptions = Apollo.BaseMutationOptions<Types.DeleteEntityDispositionsMutation, Types.DeleteEntityDispositionsMutationVariables>;
export const PersonPepDispositionAuditTrailDocument = gql`
    query PersonPepDispositionAuditTrail($team: ID!, $entity: ID!) {
  person(id: $entity) {
    id
    pepDispositionAuditTrail(team: $team) {
      ...AuditTrailEntry
    }
  }
}
    ${AuditTrailEntryFragmentDoc}`;

/**
 * __usePersonPepDispositionAuditTrailQuery__
 *
 * To run a query within a React component, call `usePersonPepDispositionAuditTrailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonPepDispositionAuditTrailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonPepDispositionAuditTrailQuery({
 *   variables: {
 *      team: // value for 'team'
 *      entity: // value for 'entity'
 *   },
 * });
 */
export function usePersonPepDispositionAuditTrailQuery(baseOptions: Apollo.QueryHookOptions<Types.PersonPepDispositionAuditTrailQuery, Types.PersonPepDispositionAuditTrailQueryVariables> & ({ variables: Types.PersonPepDispositionAuditTrailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonPepDispositionAuditTrailQuery, Types.PersonPepDispositionAuditTrailQueryVariables>(PersonPepDispositionAuditTrailDocument, options);
      }
export function usePersonPepDispositionAuditTrailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonPepDispositionAuditTrailQuery, Types.PersonPepDispositionAuditTrailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonPepDispositionAuditTrailQuery, Types.PersonPepDispositionAuditTrailQueryVariables>(PersonPepDispositionAuditTrailDocument, options);
        }
export function usePersonPepDispositionAuditTrailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.PersonPepDispositionAuditTrailQuery, Types.PersonPepDispositionAuditTrailQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonPepDispositionAuditTrailQuery, Types.PersonPepDispositionAuditTrailQueryVariables>(PersonPepDispositionAuditTrailDocument, options);
        }
export type PersonPepDispositionAuditTrailQueryHookResult = ReturnType<typeof usePersonPepDispositionAuditTrailQuery>;
export type PersonPepDispositionAuditTrailLazyQueryHookResult = ReturnType<typeof usePersonPepDispositionAuditTrailLazyQuery>;
export type PersonPepDispositionAuditTrailSuspenseQueryHookResult = ReturnType<typeof usePersonPepDispositionAuditTrailSuspenseQuery>;
export type PersonPepDispositionAuditTrailQueryResult = Apollo.QueryResult<Types.PersonPepDispositionAuditTrailQuery, Types.PersonPepDispositionAuditTrailQueryVariables>;
export const PersonPepInfoDocument = gql`
    query PersonPepInfo($team: ID!, $entity: ID!, $includePepV1: Boolean!, $includePepV2: Boolean!) {
  person(id: $entity) {
    ...PersonPepInfo
  }
}
    ${PersonPepInfoFragmentDoc}`;

/**
 * __usePersonPepInfoQuery__
 *
 * To run a query within a React component, call `usePersonPepInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonPepInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonPepInfoQuery({
 *   variables: {
 *      team: // value for 'team'
 *      entity: // value for 'entity'
 *      includePepV1: // value for 'includePepV1'
 *      includePepV2: // value for 'includePepV2'
 *   },
 * });
 */
export function usePersonPepInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.PersonPepInfoQuery, Types.PersonPepInfoQueryVariables> & ({ variables: Types.PersonPepInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonPepInfoQuery, Types.PersonPepInfoQueryVariables>(PersonPepInfoDocument, options);
      }
export function usePersonPepInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonPepInfoQuery, Types.PersonPepInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonPepInfoQuery, Types.PersonPepInfoQueryVariables>(PersonPepInfoDocument, options);
        }
export function usePersonPepInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.PersonPepInfoQuery, Types.PersonPepInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonPepInfoQuery, Types.PersonPepInfoQueryVariables>(PersonPepInfoDocument, options);
        }
export type PersonPepInfoQueryHookResult = ReturnType<typeof usePersonPepInfoQuery>;
export type PersonPepInfoLazyQueryHookResult = ReturnType<typeof usePersonPepInfoLazyQuery>;
export type PersonPepInfoSuspenseQueryHookResult = ReturnType<typeof usePersonPepInfoSuspenseQuery>;
export type PersonPepInfoQueryResult = Apollo.QueryResult<Types.PersonPepInfoQuery, Types.PersonPepInfoQueryVariables>;
export const PrivatePersonPepInfoDocument = gql`
    query PrivatePersonPepInfo($team: ID!, $privatePerson: ID!) {
  privatePerson(id: $privatePerson) {
    ...PrivatePersonPepInfo
  }
}
    ${PrivatePersonPepInfoFragmentDoc}`;

/**
 * __usePrivatePersonPepInfoQuery__
 *
 * To run a query within a React component, call `usePrivatePersonPepInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivatePersonPepInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivatePersonPepInfoQuery({
 *   variables: {
 *      team: // value for 'team'
 *      privatePerson: // value for 'privatePerson'
 *   },
 * });
 */
export function usePrivatePersonPepInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.PrivatePersonPepInfoQuery, Types.PrivatePersonPepInfoQueryVariables> & ({ variables: Types.PrivatePersonPepInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrivatePersonPepInfoQuery, Types.PrivatePersonPepInfoQueryVariables>(PrivatePersonPepInfoDocument, options);
      }
export function usePrivatePersonPepInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrivatePersonPepInfoQuery, Types.PrivatePersonPepInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrivatePersonPepInfoQuery, Types.PrivatePersonPepInfoQueryVariables>(PrivatePersonPepInfoDocument, options);
        }
export function usePrivatePersonPepInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.PrivatePersonPepInfoQuery, Types.PrivatePersonPepInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PrivatePersonPepInfoQuery, Types.PrivatePersonPepInfoQueryVariables>(PrivatePersonPepInfoDocument, options);
        }
export type PrivatePersonPepInfoQueryHookResult = ReturnType<typeof usePrivatePersonPepInfoQuery>;
export type PrivatePersonPepInfoLazyQueryHookResult = ReturnType<typeof usePrivatePersonPepInfoLazyQuery>;
export type PrivatePersonPepInfoSuspenseQueryHookResult = ReturnType<typeof usePrivatePersonPepInfoSuspenseQuery>;
export type PrivatePersonPepInfoQueryResult = Apollo.QueryResult<Types.PrivatePersonPepInfoQuery, Types.PrivatePersonPepInfoQueryVariables>;
export const PortfolioCompaniesDocument = gql`
    query PortfolioCompanies($portfolio: ID!, $team: ID!, $settings: ListViewSettingsInput!, $page: ListViewPageInfoInput, $employees: Boolean!, $revenue: Boolean!, $hq: Boolean!, $statusModified: Boolean!, $tags: Boolean!, $assignees: Boolean!) {
  portfolioCompanies: listView(
    portfolio: $portfolio
    team: $team
    settings: $settings
    page: $page
  ) {
    totalCount
    edges {
      node {
        id
        ...PortfolioCompany
      }
    }
  }
}
    ${PortfolioCompanyFragmentDoc}`;

/**
 * __usePortfolioCompaniesQuery__
 *
 * To run a query within a React component, call `usePortfolioCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioCompaniesQuery({
 *   variables: {
 *      portfolio: // value for 'portfolio'
 *      team: // value for 'team'
 *      settings: // value for 'settings'
 *      page: // value for 'page'
 *      employees: // value for 'employees'
 *      revenue: // value for 'revenue'
 *      hq: // value for 'hq'
 *      statusModified: // value for 'statusModified'
 *      tags: // value for 'tags'
 *      assignees: // value for 'assignees'
 *   },
 * });
 */
export function usePortfolioCompaniesQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioCompaniesQuery, Types.PortfolioCompaniesQueryVariables> & ({ variables: Types.PortfolioCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioCompaniesQuery, Types.PortfolioCompaniesQueryVariables>(PortfolioCompaniesDocument, options);
      }
export function usePortfolioCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioCompaniesQuery, Types.PortfolioCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioCompaniesQuery, Types.PortfolioCompaniesQueryVariables>(PortfolioCompaniesDocument, options);
        }
export function usePortfolioCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.PortfolioCompaniesQuery, Types.PortfolioCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioCompaniesQuery, Types.PortfolioCompaniesQueryVariables>(PortfolioCompaniesDocument, options);
        }
export type PortfolioCompaniesQueryHookResult = ReturnType<typeof usePortfolioCompaniesQuery>;
export type PortfolioCompaniesLazyQueryHookResult = ReturnType<typeof usePortfolioCompaniesLazyQuery>;
export type PortfolioCompaniesSuspenseQueryHookResult = ReturnType<typeof usePortfolioCompaniesSuspenseQuery>;
export type PortfolioCompaniesQueryResult = Apollo.QueryResult<Types.PortfolioCompaniesQuery, Types.PortfolioCompaniesQueryVariables>;
export const PortfolioHealthDocument = gql`
    query PortfolioHealth($team: ID!, $duration: Duration!) {
  portfolioHealth(team: $team, duration: $duration) {
    ...ReviewMetrics
    ...RiskDistribution
    ...RiskFactors
  }
}
    ${ReviewMetricsFragmentDoc}
${RiskDistributionFragmentDoc}
${RiskFactorsFragmentDoc}`;

/**
 * __usePortfolioHealthQuery__
 *
 * To run a query within a React component, call `usePortfolioHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioHealthQuery({
 *   variables: {
 *      team: // value for 'team'
 *      duration: // value for 'duration'
 *   },
 * });
 */
export function usePortfolioHealthQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioHealthQuery, Types.PortfolioHealthQueryVariables> & ({ variables: Types.PortfolioHealthQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioHealthQuery, Types.PortfolioHealthQueryVariables>(PortfolioHealthDocument, options);
      }
export function usePortfolioHealthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioHealthQuery, Types.PortfolioHealthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioHealthQuery, Types.PortfolioHealthQueryVariables>(PortfolioHealthDocument, options);
        }
export function usePortfolioHealthSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.PortfolioHealthQuery, Types.PortfolioHealthQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioHealthQuery, Types.PortfolioHealthQueryVariables>(PortfolioHealthDocument, options);
        }
export type PortfolioHealthQueryHookResult = ReturnType<typeof usePortfolioHealthQuery>;
export type PortfolioHealthLazyQueryHookResult = ReturnType<typeof usePortfolioHealthLazyQuery>;
export type PortfolioHealthSuspenseQueryHookResult = ReturnType<typeof usePortfolioHealthSuspenseQuery>;
export type PortfolioHealthQueryResult = Apollo.QueryResult<Types.PortfolioHealthQuery, Types.PortfolioHealthQueryVariables>;
export const CreatePrivatePersonDocument = gql`
    mutation CreatePrivatePerson($team: ID!, $input: CreatePrivatePersonInput!) {
  createPrivatePerson(team: $team, input: $input) {
    ...PrivatePersonBase
  }
}
    ${PrivatePersonBaseFragmentDoc}`;
export type CreatePrivatePersonMutationFn = Apollo.MutationFunction<Types.CreatePrivatePersonMutation, Types.CreatePrivatePersonMutationVariables>;

/**
 * __useCreatePrivatePersonMutation__
 *
 * To run a mutation, you first call `useCreatePrivatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrivatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrivatePersonMutation, { data, loading, error }] = useCreatePrivatePersonMutation({
 *   variables: {
 *      team: // value for 'team'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrivatePersonMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePrivatePersonMutation, Types.CreatePrivatePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePrivatePersonMutation, Types.CreatePrivatePersonMutationVariables>(CreatePrivatePersonDocument, options);
      }
export type CreatePrivatePersonMutationHookResult = ReturnType<typeof useCreatePrivatePersonMutation>;
export type CreatePrivatePersonMutationResult = Apollo.MutationResult<Types.CreatePrivatePersonMutation>;
export type CreatePrivatePersonMutationOptions = Apollo.BaseMutationOptions<Types.CreatePrivatePersonMutation, Types.CreatePrivatePersonMutationVariables>;
export const DeletePrivatePersonDocument = gql`
    mutation DeletePrivatePerson($team: ID!, $id: ID!) {
  deletePrivatePerson(team: $team, id: $id) {
    __typename
  }
}
    `;
export type DeletePrivatePersonMutationFn = Apollo.MutationFunction<Types.DeletePrivatePersonMutation, Types.DeletePrivatePersonMutationVariables>;

/**
 * __useDeletePrivatePersonMutation__
 *
 * To run a mutation, you first call `useDeletePrivatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrivatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrivatePersonMutation, { data, loading, error }] = useDeletePrivatePersonMutation({
 *   variables: {
 *      team: // value for 'team'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePrivatePersonMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePrivatePersonMutation, Types.DeletePrivatePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePrivatePersonMutation, Types.DeletePrivatePersonMutationVariables>(DeletePrivatePersonDocument, options);
      }
export type DeletePrivatePersonMutationHookResult = ReturnType<typeof useDeletePrivatePersonMutation>;
export type DeletePrivatePersonMutationResult = Apollo.MutationResult<Types.DeletePrivatePersonMutation>;
export type DeletePrivatePersonMutationOptions = Apollo.BaseMutationOptions<Types.DeletePrivatePersonMutation, Types.DeletePrivatePersonMutationVariables>;
export const UpdatePrivatePersonDocument = gql`
    mutation UpdatePrivatePerson($team: ID!, $input: PrivatePersonUpdateInput!) {
  updatePrivatePerson(team: $team, input: $input) {
    ...PrivatePersonBase
  }
}
    ${PrivatePersonBaseFragmentDoc}`;
export type UpdatePrivatePersonMutationFn = Apollo.MutationFunction<Types.UpdatePrivatePersonMutation, Types.UpdatePrivatePersonMutationVariables>;

/**
 * __useUpdatePrivatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePrivatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivatePersonMutation, { data, loading, error }] = useUpdatePrivatePersonMutation({
 *   variables: {
 *      team: // value for 'team'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePrivatePersonMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePrivatePersonMutation, Types.UpdatePrivatePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePrivatePersonMutation, Types.UpdatePrivatePersonMutationVariables>(UpdatePrivatePersonDocument, options);
      }
export type UpdatePrivatePersonMutationHookResult = ReturnType<typeof useUpdatePrivatePersonMutation>;
export type UpdatePrivatePersonMutationResult = Apollo.MutationResult<Types.UpdatePrivatePersonMutation>;
export type UpdatePrivatePersonMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePrivatePersonMutation, Types.UpdatePrivatePersonMutationVariables>;
export const UpdatePrivatePersonReviewStatusDocument = gql`
    mutation UpdatePrivatePersonReviewStatus($team: ID!, $id: ID!, $status: PrivatePersonReviewStatusKind!) {
  updatePrivatePersonReviewStatus(team: $team, id: $id, status: $status) {
    privatePerson(id: $id) {
      ...ReviewPrivatePerson
    }
  }
}
    ${ReviewPrivatePersonFragmentDoc}`;
export type UpdatePrivatePersonReviewStatusMutationFn = Apollo.MutationFunction<Types.UpdatePrivatePersonReviewStatusMutation, Types.UpdatePrivatePersonReviewStatusMutationVariables>;

/**
 * __useUpdatePrivatePersonReviewStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePrivatePersonReviewStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivatePersonReviewStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivatePersonReviewStatusMutation, { data, loading, error }] = useUpdatePrivatePersonReviewStatusMutation({
 *   variables: {
 *      team: // value for 'team'
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePrivatePersonReviewStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePrivatePersonReviewStatusMutation, Types.UpdatePrivatePersonReviewStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePrivatePersonReviewStatusMutation, Types.UpdatePrivatePersonReviewStatusMutationVariables>(UpdatePrivatePersonReviewStatusDocument, options);
      }
export type UpdatePrivatePersonReviewStatusMutationHookResult = ReturnType<typeof useUpdatePrivatePersonReviewStatusMutation>;
export type UpdatePrivatePersonReviewStatusMutationResult = Apollo.MutationResult<Types.UpdatePrivatePersonReviewStatusMutation>;
export type UpdatePrivatePersonReviewStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePrivatePersonReviewStatusMutation, Types.UpdatePrivatePersonReviewStatusMutationVariables>;
export const PrivatePersonSearchDocument = gql`
    query PrivatePersonSearch($team: ID!, $searchInput: PrivatePersonSearchInput!, $pageInfo: PrivatePersonPageInfoInput) {
  privatePersonSearch(team: $team, searchInput: $searchInput, pageInfo: $pageInfo) {
    edges {
      node {
        ...PrivatePersonBase
      }
    }
  }
}
    ${PrivatePersonBaseFragmentDoc}`;

/**
 * __usePrivatePersonSearchQuery__
 *
 * To run a query within a React component, call `usePrivatePersonSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivatePersonSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivatePersonSearchQuery({
 *   variables: {
 *      team: // value for 'team'
 *      searchInput: // value for 'searchInput'
 *      pageInfo: // value for 'pageInfo'
 *   },
 * });
 */
export function usePrivatePersonSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.PrivatePersonSearchQuery, Types.PrivatePersonSearchQueryVariables> & ({ variables: Types.PrivatePersonSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrivatePersonSearchQuery, Types.PrivatePersonSearchQueryVariables>(PrivatePersonSearchDocument, options);
      }
export function usePrivatePersonSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrivatePersonSearchQuery, Types.PrivatePersonSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrivatePersonSearchQuery, Types.PrivatePersonSearchQueryVariables>(PrivatePersonSearchDocument, options);
        }
export function usePrivatePersonSearchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.PrivatePersonSearchQuery, Types.PrivatePersonSearchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PrivatePersonSearchQuery, Types.PrivatePersonSearchQueryVariables>(PrivatePersonSearchDocument, options);
        }
export type PrivatePersonSearchQueryHookResult = ReturnType<typeof usePrivatePersonSearchQuery>;
export type PrivatePersonSearchLazyQueryHookResult = ReturnType<typeof usePrivatePersonSearchLazyQuery>;
export type PrivatePersonSearchSuspenseQueryHookResult = ReturnType<typeof usePrivatePersonSearchSuspenseQuery>;
export type PrivatePersonSearchQueryResult = Apollo.QueryResult<Types.PrivatePersonSearchQuery, Types.PrivatePersonSearchQueryVariables>;
export const PrivatePersonsByReviewStatusDocument = gql`
    query PrivatePersonsByReviewStatus($team: ID!, $status: PrivatePersonReviewStatusKind!, $pageInfo: PrivatePersonPageInfoInput) {
  privatePersonsByReviewStatus(team: $team, status: $status, pageInfo: $pageInfo) {
    edges {
      node {
        ...ReviewPrivatePerson
      }
    }
  }
}
    ${ReviewPrivatePersonFragmentDoc}`;

/**
 * __usePrivatePersonsByReviewStatusQuery__
 *
 * To run a query within a React component, call `usePrivatePersonsByReviewStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivatePersonsByReviewStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivatePersonsByReviewStatusQuery({
 *   variables: {
 *      team: // value for 'team'
 *      status: // value for 'status'
 *      pageInfo: // value for 'pageInfo'
 *   },
 * });
 */
export function usePrivatePersonsByReviewStatusQuery(baseOptions: Apollo.QueryHookOptions<Types.PrivatePersonsByReviewStatusQuery, Types.PrivatePersonsByReviewStatusQueryVariables> & ({ variables: Types.PrivatePersonsByReviewStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrivatePersonsByReviewStatusQuery, Types.PrivatePersonsByReviewStatusQueryVariables>(PrivatePersonsByReviewStatusDocument, options);
      }
export function usePrivatePersonsByReviewStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrivatePersonsByReviewStatusQuery, Types.PrivatePersonsByReviewStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrivatePersonsByReviewStatusQuery, Types.PrivatePersonsByReviewStatusQueryVariables>(PrivatePersonsByReviewStatusDocument, options);
        }
export function usePrivatePersonsByReviewStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.PrivatePersonsByReviewStatusQuery, Types.PrivatePersonsByReviewStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PrivatePersonsByReviewStatusQuery, Types.PrivatePersonsByReviewStatusQueryVariables>(PrivatePersonsByReviewStatusDocument, options);
        }
export type PrivatePersonsByReviewStatusQueryHookResult = ReturnType<typeof usePrivatePersonsByReviewStatusQuery>;
export type PrivatePersonsByReviewStatusLazyQueryHookResult = ReturnType<typeof usePrivatePersonsByReviewStatusLazyQuery>;
export type PrivatePersonsByReviewStatusSuspenseQueryHookResult = ReturnType<typeof usePrivatePersonsByReviewStatusSuspenseQuery>;
export type PrivatePersonsByReviewStatusQueryResult = Apollo.QueryResult<Types.PrivatePersonsByReviewStatusQuery, Types.PrivatePersonsByReviewStatusQueryVariables>;
export const CompanyRemindersDocument = gql`
    query CompanyReminders($team: ID!, $id: ID!, $statusFilter: [ReminderStatus!]) {
  company(id: $id) {
    id
    ...CompanyReminders
  }
}
    ${CompanyRemindersFragmentDoc}`;

/**
 * __useCompanyRemindersQuery__
 *
 * To run a query within a React component, call `useCompanyRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRemindersQuery({
 *   variables: {
 *      team: // value for 'team'
 *      id: // value for 'id'
 *      statusFilter: // value for 'statusFilter'
 *   },
 * });
 */
export function useCompanyRemindersQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyRemindersQuery, Types.CompanyRemindersQueryVariables> & ({ variables: Types.CompanyRemindersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyRemindersQuery, Types.CompanyRemindersQueryVariables>(CompanyRemindersDocument, options);
      }
export function useCompanyRemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyRemindersQuery, Types.CompanyRemindersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyRemindersQuery, Types.CompanyRemindersQueryVariables>(CompanyRemindersDocument, options);
        }
export function useCompanyRemindersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CompanyRemindersQuery, Types.CompanyRemindersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyRemindersQuery, Types.CompanyRemindersQueryVariables>(CompanyRemindersDocument, options);
        }
export type CompanyRemindersQueryHookResult = ReturnType<typeof useCompanyRemindersQuery>;
export type CompanyRemindersLazyQueryHookResult = ReturnType<typeof useCompanyRemindersLazyQuery>;
export type CompanyRemindersSuspenseQueryHookResult = ReturnType<typeof useCompanyRemindersSuspenseQuery>;
export type CompanyRemindersQueryResult = Apollo.QueryResult<Types.CompanyRemindersQuery, Types.CompanyRemindersQueryVariables>;
export const CreatePrivatePersonReviewDocument = gql`
    mutation CreatePrivatePersonReview($privatePerson: ID!, $team: ID!, $input: PrivatePersonReviewInput!) {
  createPrivatePersonReview(
    team: $team
    privatePerson: $privatePerson
    input: $input
  ) {
    privatePerson(id: $privatePerson) {
      id
      reviews(team: $team) {
        ...PrivatePersonReview
      }
    }
  }
}
    ${PrivatePersonReviewFragmentDoc}`;
export type CreatePrivatePersonReviewMutationFn = Apollo.MutationFunction<Types.CreatePrivatePersonReviewMutation, Types.CreatePrivatePersonReviewMutationVariables>;

/**
 * __useCreatePrivatePersonReviewMutation__
 *
 * To run a mutation, you first call `useCreatePrivatePersonReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrivatePersonReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrivatePersonReviewMutation, { data, loading, error }] = useCreatePrivatePersonReviewMutation({
 *   variables: {
 *      privatePerson: // value for 'privatePerson'
 *      team: // value for 'team'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrivatePersonReviewMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePrivatePersonReviewMutation, Types.CreatePrivatePersonReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePrivatePersonReviewMutation, Types.CreatePrivatePersonReviewMutationVariables>(CreatePrivatePersonReviewDocument, options);
      }
export type CreatePrivatePersonReviewMutationHookResult = ReturnType<typeof useCreatePrivatePersonReviewMutation>;
export type CreatePrivatePersonReviewMutationResult = Apollo.MutationResult<Types.CreatePrivatePersonReviewMutation>;
export type CreatePrivatePersonReviewMutationOptions = Apollo.BaseMutationOptions<Types.CreatePrivatePersonReviewMutation, Types.CreatePrivatePersonReviewMutationVariables>;
export const CreateReviewDocument = gql`
    mutation CreateReview($entity: ID!, $team: ID!, $portfolio: ID!, $input: ReviewInputV2!) {
  createReviewV2(
    team: $team
    portfolio: $portfolio
    entity: $entity
    input: $input
  ) {
    company(id: $entity) {
      id
      ...BaseReviewCompany
    }
  }
}
    ${BaseReviewCompanyFragmentDoc}`;
export type CreateReviewMutationFn = Apollo.MutationFunction<Types.CreateReviewMutation, Types.CreateReviewMutationVariables>;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewMutation, { data, loading, error }] = useCreateReviewMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReviewMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateReviewMutation, Types.CreateReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateReviewMutation, Types.CreateReviewMutationVariables>(CreateReviewDocument, options);
      }
export type CreateReviewMutationHookResult = ReturnType<typeof useCreateReviewMutation>;
export type CreateReviewMutationResult = Apollo.MutationResult<Types.CreateReviewMutation>;
export type CreateReviewMutationOptions = Apollo.BaseMutationOptions<Types.CreateReviewMutation, Types.CreateReviewMutationVariables>;
export const CompanyReviewsDocument = gql`
    query CompanyReviews($team: ID!, $portfolio: ID!, $entity: ID!) {
  company(id: $entity) {
    ...BaseReviewCompany
  }
}
    ${BaseReviewCompanyFragmentDoc}`;

/**
 * __useCompanyReviewsQuery__
 *
 * To run a query within a React component, call `useCompanyReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyReviewsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      entity: // value for 'entity'
 *   },
 * });
 */
export function useCompanyReviewsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyReviewsQuery, Types.CompanyReviewsQueryVariables> & ({ variables: Types.CompanyReviewsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyReviewsQuery, Types.CompanyReviewsQueryVariables>(CompanyReviewsDocument, options);
      }
export function useCompanyReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyReviewsQuery, Types.CompanyReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyReviewsQuery, Types.CompanyReviewsQueryVariables>(CompanyReviewsDocument, options);
        }
export function useCompanyReviewsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CompanyReviewsQuery, Types.CompanyReviewsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyReviewsQuery, Types.CompanyReviewsQueryVariables>(CompanyReviewsDocument, options);
        }
export type CompanyReviewsQueryHookResult = ReturnType<typeof useCompanyReviewsQuery>;
export type CompanyReviewsLazyQueryHookResult = ReturnType<typeof useCompanyReviewsLazyQuery>;
export type CompanyReviewsSuspenseQueryHookResult = ReturnType<typeof useCompanyReviewsSuspenseQuery>;
export type CompanyReviewsQueryResult = Apollo.QueryResult<Types.CompanyReviewsQuery, Types.CompanyReviewsQueryVariables>;
export const PrivatePersonReviewFileDocument = gql`
    query PrivatePersonReviewFile($id: ID!, $team: ID!) {
  privatePersonReview(id: $id, team: $team) {
    id
    file
    fileName
  }
}
    `;

/**
 * __usePrivatePersonReviewFileQuery__
 *
 * To run a query within a React component, call `usePrivatePersonReviewFileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivatePersonReviewFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivatePersonReviewFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      team: // value for 'team'
 *   },
 * });
 */
export function usePrivatePersonReviewFileQuery(baseOptions: Apollo.QueryHookOptions<Types.PrivatePersonReviewFileQuery, Types.PrivatePersonReviewFileQueryVariables> & ({ variables: Types.PrivatePersonReviewFileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrivatePersonReviewFileQuery, Types.PrivatePersonReviewFileQueryVariables>(PrivatePersonReviewFileDocument, options);
      }
export function usePrivatePersonReviewFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrivatePersonReviewFileQuery, Types.PrivatePersonReviewFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrivatePersonReviewFileQuery, Types.PrivatePersonReviewFileQueryVariables>(PrivatePersonReviewFileDocument, options);
        }
export function usePrivatePersonReviewFileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.PrivatePersonReviewFileQuery, Types.PrivatePersonReviewFileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PrivatePersonReviewFileQuery, Types.PrivatePersonReviewFileQueryVariables>(PrivatePersonReviewFileDocument, options);
        }
export type PrivatePersonReviewFileQueryHookResult = ReturnType<typeof usePrivatePersonReviewFileQuery>;
export type PrivatePersonReviewFileLazyQueryHookResult = ReturnType<typeof usePrivatePersonReviewFileLazyQuery>;
export type PrivatePersonReviewFileSuspenseQueryHookResult = ReturnType<typeof usePrivatePersonReviewFileSuspenseQuery>;
export type PrivatePersonReviewFileQueryResult = Apollo.QueryResult<Types.PrivatePersonReviewFileQuery, Types.PrivatePersonReviewFileQueryVariables>;
export const PrivatePersonReviewFileNameDocument = gql`
    query PrivatePersonReviewFileName($id: ID!, $team: ID!) {
  privatePersonReview(id: $id, team: $team) {
    id
    fileName
  }
}
    `;

/**
 * __usePrivatePersonReviewFileNameQuery__
 *
 * To run a query within a React component, call `usePrivatePersonReviewFileNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivatePersonReviewFileNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivatePersonReviewFileNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *      team: // value for 'team'
 *   },
 * });
 */
export function usePrivatePersonReviewFileNameQuery(baseOptions: Apollo.QueryHookOptions<Types.PrivatePersonReviewFileNameQuery, Types.PrivatePersonReviewFileNameQueryVariables> & ({ variables: Types.PrivatePersonReviewFileNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrivatePersonReviewFileNameQuery, Types.PrivatePersonReviewFileNameQueryVariables>(PrivatePersonReviewFileNameDocument, options);
      }
export function usePrivatePersonReviewFileNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrivatePersonReviewFileNameQuery, Types.PrivatePersonReviewFileNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrivatePersonReviewFileNameQuery, Types.PrivatePersonReviewFileNameQueryVariables>(PrivatePersonReviewFileNameDocument, options);
        }
export function usePrivatePersonReviewFileNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.PrivatePersonReviewFileNameQuery, Types.PrivatePersonReviewFileNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PrivatePersonReviewFileNameQuery, Types.PrivatePersonReviewFileNameQueryVariables>(PrivatePersonReviewFileNameDocument, options);
        }
export type PrivatePersonReviewFileNameQueryHookResult = ReturnType<typeof usePrivatePersonReviewFileNameQuery>;
export type PrivatePersonReviewFileNameLazyQueryHookResult = ReturnType<typeof usePrivatePersonReviewFileNameLazyQuery>;
export type PrivatePersonReviewFileNameSuspenseQueryHookResult = ReturnType<typeof usePrivatePersonReviewFileNameSuspenseQuery>;
export type PrivatePersonReviewFileNameQueryResult = Apollo.QueryResult<Types.PrivatePersonReviewFileNameQuery, Types.PrivatePersonReviewFileNameQueryVariables>;
export const ReviewAiSummaryDocument = gql`
    query ReviewAiSummary($text: String!, $team: ID!, $summaryKind: SummarySelection) {
  reviewAiSummary(text: $text, team: $team, summaryKind: $summaryKind) {
    summary
  }
}
    `;

/**
 * __useReviewAiSummaryQuery__
 *
 * To run a query within a React component, call `useReviewAiSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewAiSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewAiSummaryQuery({
 *   variables: {
 *      text: // value for 'text'
 *      team: // value for 'team'
 *      summaryKind: // value for 'summaryKind'
 *   },
 * });
 */
export function useReviewAiSummaryQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewAiSummaryQuery, Types.ReviewAiSummaryQueryVariables> & ({ variables: Types.ReviewAiSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewAiSummaryQuery, Types.ReviewAiSummaryQueryVariables>(ReviewAiSummaryDocument, options);
      }
export function useReviewAiSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewAiSummaryQuery, Types.ReviewAiSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewAiSummaryQuery, Types.ReviewAiSummaryQueryVariables>(ReviewAiSummaryDocument, options);
        }
export function useReviewAiSummarySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ReviewAiSummaryQuery, Types.ReviewAiSummaryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewAiSummaryQuery, Types.ReviewAiSummaryQueryVariables>(ReviewAiSummaryDocument, options);
        }
export type ReviewAiSummaryQueryHookResult = ReturnType<typeof useReviewAiSummaryQuery>;
export type ReviewAiSummaryLazyQueryHookResult = ReturnType<typeof useReviewAiSummaryLazyQuery>;
export type ReviewAiSummarySuspenseQueryHookResult = ReturnType<typeof useReviewAiSummarySuspenseQuery>;
export type ReviewAiSummaryQueryResult = Apollo.QueryResult<Types.ReviewAiSummaryQuery, Types.ReviewAiSummaryQueryVariables>;
export const ReviewCompaniesDocument = gql`
    query ReviewCompanies($portfolio: ID!, $team: ID!, $filter: CompanyFilterInput!, $size: Int!, $offset: Int!) {
  team(id: $team) {
    id
    portfolio(id: $portfolio) {
      id
      name
      companies(filter: $filter, size: $size, offset: $offset) {
        edges {
          node {
            ...BaseReviewCompany
          }
        }
      }
    }
  }
}
    ${BaseReviewCompanyFragmentDoc}`;

/**
 * __useReviewCompaniesQuery__
 *
 * To run a query within a React component, call `useReviewCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewCompaniesQuery({
 *   variables: {
 *      portfolio: // value for 'portfolio'
 *      team: // value for 'team'
 *      filter: // value for 'filter'
 *      size: // value for 'size'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useReviewCompaniesQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewCompaniesQuery, Types.ReviewCompaniesQueryVariables> & ({ variables: Types.ReviewCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewCompaniesQuery, Types.ReviewCompaniesQueryVariables>(ReviewCompaniesDocument, options);
      }
export function useReviewCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewCompaniesQuery, Types.ReviewCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewCompaniesQuery, Types.ReviewCompaniesQueryVariables>(ReviewCompaniesDocument, options);
        }
export function useReviewCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ReviewCompaniesQuery, Types.ReviewCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewCompaniesQuery, Types.ReviewCompaniesQueryVariables>(ReviewCompaniesDocument, options);
        }
export type ReviewCompaniesQueryHookResult = ReturnType<typeof useReviewCompaniesQuery>;
export type ReviewCompaniesLazyQueryHookResult = ReturnType<typeof useReviewCompaniesLazyQuery>;
export type ReviewCompaniesSuspenseQueryHookResult = ReturnType<typeof useReviewCompaniesSuspenseQuery>;
export type ReviewCompaniesQueryResult = Apollo.QueryResult<Types.ReviewCompaniesQuery, Types.ReviewCompaniesQueryVariables>;
export const ReviewCompanyDataDocument = gql`
    query ReviewCompanyData($entity: ID!, $team: ID!, $includeSections: [ReviewSectionKind!]!, $excludeSections: [ReviewSectionKind!]!) {
  reviewCompanyData(
    id: $entity
    team: $team
    includeSections: $includeSections
    excludeSections: $excludeSections
  ) {
    ...ReviewCompanyData
  }
}
    ${ReviewCompanyDataFragmentDoc}`;

/**
 * __useReviewCompanyDataQuery__
 *
 * To run a query within a React component, call `useReviewCompanyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewCompanyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewCompanyDataQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *      team: // value for 'team'
 *      includeSections: // value for 'includeSections'
 *      excludeSections: // value for 'excludeSections'
 *   },
 * });
 */
export function useReviewCompanyDataQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewCompanyDataQuery, Types.ReviewCompanyDataQueryVariables> & ({ variables: Types.ReviewCompanyDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewCompanyDataQuery, Types.ReviewCompanyDataQueryVariables>(ReviewCompanyDataDocument, options);
      }
export function useReviewCompanyDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewCompanyDataQuery, Types.ReviewCompanyDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewCompanyDataQuery, Types.ReviewCompanyDataQueryVariables>(ReviewCompanyDataDocument, options);
        }
export function useReviewCompanyDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ReviewCompanyDataQuery, Types.ReviewCompanyDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewCompanyDataQuery, Types.ReviewCompanyDataQueryVariables>(ReviewCompanyDataDocument, options);
        }
export type ReviewCompanyDataQueryHookResult = ReturnType<typeof useReviewCompanyDataQuery>;
export type ReviewCompanyDataLazyQueryHookResult = ReturnType<typeof useReviewCompanyDataLazyQuery>;
export type ReviewCompanyDataSuspenseQueryHookResult = ReturnType<typeof useReviewCompanyDataSuspenseQuery>;
export type ReviewCompanyDataQueryResult = Apollo.QueryResult<Types.ReviewCompanyDataQuery, Types.ReviewCompanyDataQueryVariables>;
export const ReviewFileDocument = gql`
    query ReviewFile($id: ID!) {
  review(id: $id) {
    id
    file
    fileName
  }
}
    `;

/**
 * __useReviewFileQuery__
 *
 * To run a query within a React component, call `useReviewFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewFileQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewFileQuery, Types.ReviewFileQueryVariables> & ({ variables: Types.ReviewFileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewFileQuery, Types.ReviewFileQueryVariables>(ReviewFileDocument, options);
      }
export function useReviewFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewFileQuery, Types.ReviewFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewFileQuery, Types.ReviewFileQueryVariables>(ReviewFileDocument, options);
        }
export function useReviewFileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ReviewFileQuery, Types.ReviewFileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewFileQuery, Types.ReviewFileQueryVariables>(ReviewFileDocument, options);
        }
export type ReviewFileQueryHookResult = ReturnType<typeof useReviewFileQuery>;
export type ReviewFileLazyQueryHookResult = ReturnType<typeof useReviewFileLazyQuery>;
export type ReviewFileSuspenseQueryHookResult = ReturnType<typeof useReviewFileSuspenseQuery>;
export type ReviewFileQueryResult = Apollo.QueryResult<Types.ReviewFileQuery, Types.ReviewFileQueryVariables>;
export const ReviewFileNameDocument = gql`
    query ReviewFileName($id: ID!) {
  review(id: $id) {
    id
    fileName
  }
}
    `;

/**
 * __useReviewFileNameQuery__
 *
 * To run a query within a React component, call `useReviewFileNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewFileNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewFileNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewFileNameQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewFileNameQuery, Types.ReviewFileNameQueryVariables> & ({ variables: Types.ReviewFileNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewFileNameQuery, Types.ReviewFileNameQueryVariables>(ReviewFileNameDocument, options);
      }
export function useReviewFileNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewFileNameQuery, Types.ReviewFileNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewFileNameQuery, Types.ReviewFileNameQueryVariables>(ReviewFileNameDocument, options);
        }
export function useReviewFileNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ReviewFileNameQuery, Types.ReviewFileNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewFileNameQuery, Types.ReviewFileNameQueryVariables>(ReviewFileNameDocument, options);
        }
export type ReviewFileNameQueryHookResult = ReturnType<typeof useReviewFileNameQuery>;
export type ReviewFileNameLazyQueryHookResult = ReturnType<typeof useReviewFileNameLazyQuery>;
export type ReviewFileNameSuspenseQueryHookResult = ReturnType<typeof useReviewFileNameSuspenseQuery>;
export type ReviewFileNameQueryResult = Apollo.QueryResult<Types.ReviewFileNameQuery, Types.ReviewFileNameQueryVariables>;
export const ReviewPrivatePersonDataDocument = gql`
    query ReviewPrivatePersonData($team: ID!, $id: ID!) {
  reviewPrivatePersonData(team: $team, id: $id) {
    ...ReviewPrivatePersonData
  }
}
    ${ReviewPrivatePersonDataFragmentDoc}`;

/**
 * __useReviewPrivatePersonDataQuery__
 *
 * To run a query within a React component, call `useReviewPrivatePersonDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewPrivatePersonDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewPrivatePersonDataQuery({
 *   variables: {
 *      team: // value for 'team'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewPrivatePersonDataQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewPrivatePersonDataQuery, Types.ReviewPrivatePersonDataQueryVariables> & ({ variables: Types.ReviewPrivatePersonDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewPrivatePersonDataQuery, Types.ReviewPrivatePersonDataQueryVariables>(ReviewPrivatePersonDataDocument, options);
      }
export function useReviewPrivatePersonDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewPrivatePersonDataQuery, Types.ReviewPrivatePersonDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewPrivatePersonDataQuery, Types.ReviewPrivatePersonDataQueryVariables>(ReviewPrivatePersonDataDocument, options);
        }
export function useReviewPrivatePersonDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ReviewPrivatePersonDataQuery, Types.ReviewPrivatePersonDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewPrivatePersonDataQuery, Types.ReviewPrivatePersonDataQueryVariables>(ReviewPrivatePersonDataDocument, options);
        }
export type ReviewPrivatePersonDataQueryHookResult = ReturnType<typeof useReviewPrivatePersonDataQuery>;
export type ReviewPrivatePersonDataLazyQueryHookResult = ReturnType<typeof useReviewPrivatePersonDataLazyQuery>;
export type ReviewPrivatePersonDataSuspenseQueryHookResult = ReturnType<typeof useReviewPrivatePersonDataSuspenseQuery>;
export type ReviewPrivatePersonDataQueryResult = Apollo.QueryResult<Types.ReviewPrivatePersonDataQuery, Types.ReviewPrivatePersonDataQueryVariables>;
export const UpdateRiskLevelsDocument = gql`
    mutation UpdateRiskLevels($team: ID!, $riskLevels: [CustomRiskLevelInput!]!) {
  updateCustomRiskLevels(team: $team, riskLevels: $riskLevels)
}
    `;
export type UpdateRiskLevelsMutationFn = Apollo.MutationFunction<Types.UpdateRiskLevelsMutation, Types.UpdateRiskLevelsMutationVariables>;

/**
 * __useUpdateRiskLevelsMutation__
 *
 * To run a mutation, you first call `useUpdateRiskLevelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRiskLevelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRiskLevelsMutation, { data, loading, error }] = useUpdateRiskLevelsMutation({
 *   variables: {
 *      team: // value for 'team'
 *      riskLevels: // value for 'riskLevels'
 *   },
 * });
 */
export function useUpdateRiskLevelsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateRiskLevelsMutation, Types.UpdateRiskLevelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateRiskLevelsMutation, Types.UpdateRiskLevelsMutationVariables>(UpdateRiskLevelsDocument, options);
      }
export type UpdateRiskLevelsMutationHookResult = ReturnType<typeof useUpdateRiskLevelsMutation>;
export type UpdateRiskLevelsMutationResult = Apollo.MutationResult<Types.UpdateRiskLevelsMutation>;
export type UpdateRiskLevelsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateRiskLevelsMutation, Types.UpdateRiskLevelsMutationVariables>;
export const CalculateRiskLevelDocument = gql`
    query CalculateRiskLevel($team: ID!, $entity: ID!) {
  calculateRiskLevel(team: $team, entity: $entity) {
    ...RiskLevel
  }
}
    ${RiskLevelFragmentDoc}`;

/**
 * __useCalculateRiskLevelQuery__
 *
 * To run a query within a React component, call `useCalculateRiskLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateRiskLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateRiskLevelQuery({
 *   variables: {
 *      team: // value for 'team'
 *      entity: // value for 'entity'
 *   },
 * });
 */
export function useCalculateRiskLevelQuery(baseOptions: Apollo.QueryHookOptions<Types.CalculateRiskLevelQuery, Types.CalculateRiskLevelQueryVariables> & ({ variables: Types.CalculateRiskLevelQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CalculateRiskLevelQuery, Types.CalculateRiskLevelQueryVariables>(CalculateRiskLevelDocument, options);
      }
export function useCalculateRiskLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CalculateRiskLevelQuery, Types.CalculateRiskLevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CalculateRiskLevelQuery, Types.CalculateRiskLevelQueryVariables>(CalculateRiskLevelDocument, options);
        }
export function useCalculateRiskLevelSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CalculateRiskLevelQuery, Types.CalculateRiskLevelQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CalculateRiskLevelQuery, Types.CalculateRiskLevelQueryVariables>(CalculateRiskLevelDocument, options);
        }
export type CalculateRiskLevelQueryHookResult = ReturnType<typeof useCalculateRiskLevelQuery>;
export type CalculateRiskLevelLazyQueryHookResult = ReturnType<typeof useCalculateRiskLevelLazyQuery>;
export type CalculateRiskLevelSuspenseQueryHookResult = ReturnType<typeof useCalculateRiskLevelSuspenseQuery>;
export type CalculateRiskLevelQueryResult = Apollo.QueryResult<Types.CalculateRiskLevelQuery, Types.CalculateRiskLevelQueryVariables>;
export const RiskLevelsDocument = gql`
    query RiskLevels($team: ID!) {
  riskLevels(team: $team) {
    ...RiskLevel
  }
}
    ${RiskLevelFragmentDoc}`;

/**
 * __useRiskLevelsQuery__
 *
 * To run a query within a React component, call `useRiskLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiskLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskLevelsQuery({
 *   variables: {
 *      team: // value for 'team'
 *   },
 * });
 */
export function useRiskLevelsQuery(baseOptions: Apollo.QueryHookOptions<Types.RiskLevelsQuery, Types.RiskLevelsQueryVariables> & ({ variables: Types.RiskLevelsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RiskLevelsQuery, Types.RiskLevelsQueryVariables>(RiskLevelsDocument, options);
      }
export function useRiskLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RiskLevelsQuery, Types.RiskLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RiskLevelsQuery, Types.RiskLevelsQueryVariables>(RiskLevelsDocument, options);
        }
export function useRiskLevelsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.RiskLevelsQuery, Types.RiskLevelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RiskLevelsQuery, Types.RiskLevelsQueryVariables>(RiskLevelsDocument, options);
        }
export type RiskLevelsQueryHookResult = ReturnType<typeof useRiskLevelsQuery>;
export type RiskLevelsLazyQueryHookResult = ReturnType<typeof useRiskLevelsLazyQuery>;
export type RiskLevelsSuspenseQueryHookResult = ReturnType<typeof useRiskLevelsSuspenseQuery>;
export type RiskLevelsQueryResult = Apollo.QueryResult<Types.RiskLevelsQuery, Types.RiskLevelsQueryVariables>;
export const ResetEntityRolesDocument = gql`
    mutation ResetEntityRoles($team: ID!, $entity: ID!, $withFlags: Boolean = false, $ignoreCustomRoles: Boolean = false, $filterActive: Boolean) {
  resetEntityRoles(team: $team, entity: $entity) {
    entity(id: $entity) {
      id
      ...EntityBusinessRoleMeta
    }
  }
}
    ${EntityBusinessRoleMetaFragmentDoc}`;
export type ResetEntityRolesMutationFn = Apollo.MutationFunction<Types.ResetEntityRolesMutation, Types.ResetEntityRolesMutationVariables>;

/**
 * __useResetEntityRolesMutation__
 *
 * To run a mutation, you first call `useResetEntityRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetEntityRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetEntityRolesMutation, { data, loading, error }] = useResetEntityRolesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      entity: // value for 'entity'
 *      withFlags: // value for 'withFlags'
 *      ignoreCustomRoles: // value for 'ignoreCustomRoles'
 *      filterActive: // value for 'filterActive'
 *   },
 * });
 */
export function useResetEntityRolesMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetEntityRolesMutation, Types.ResetEntityRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetEntityRolesMutation, Types.ResetEntityRolesMutationVariables>(ResetEntityRolesDocument, options);
      }
export type ResetEntityRolesMutationHookResult = ReturnType<typeof useResetEntityRolesMutation>;
export type ResetEntityRolesMutationResult = Apollo.MutationResult<Types.ResetEntityRolesMutation>;
export type ResetEntityRolesMutationOptions = Apollo.BaseMutationOptions<Types.ResetEntityRolesMutation, Types.ResetEntityRolesMutationVariables>;
export const UpdateEntityRoleDocument = gql`
    mutation UpdateEntityRole($team: ID!, $entity: ID!, $input: UpdateEntityBusinessRoleInput!, $sidepanelEntity: ID!, $withFlags: Boolean = false, $ignoreCustomRoles: Boolean = false, $filterActive: Boolean, $includePepV1: Boolean = false, $includePepV2: Boolean = false, $includeNetworkRoles: Boolean) {
  updateEntityBusinessRole(team: $team, entity: $entity, input: $input) {
    entity(id: $sidepanelEntity) {
      id
      ...EntityBusinessRoleMeta
      ... on Company {
        riskSummary(team: $team, includeNetworkRoles: $includeNetworkRoles) {
          ...RiskSummary
        }
        companyNetwork(team: $team, includeNetworkRoles: $includeNetworkRoles) {
          ...CompanyNetwork
        }
      }
    }
  }
}
    ${EntityBusinessRoleMetaFragmentDoc}
${RiskSummaryFragmentDoc}
${CompanyNetworkFragmentDoc}`;
export type UpdateEntityRoleMutationFn = Apollo.MutationFunction<Types.UpdateEntityRoleMutation, Types.UpdateEntityRoleMutationVariables>;

/**
 * __useUpdateEntityRoleMutation__
 *
 * To run a mutation, you first call `useUpdateEntityRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityRoleMutation, { data, loading, error }] = useUpdateEntityRoleMutation({
 *   variables: {
 *      team: // value for 'team'
 *      entity: // value for 'entity'
 *      input: // value for 'input'
 *      sidepanelEntity: // value for 'sidepanelEntity'
 *      withFlags: // value for 'withFlags'
 *      ignoreCustomRoles: // value for 'ignoreCustomRoles'
 *      filterActive: // value for 'filterActive'
 *      includePepV1: // value for 'includePepV1'
 *      includePepV2: // value for 'includePepV2'
 *      includeNetworkRoles: // value for 'includeNetworkRoles'
 *   },
 * });
 */
export function useUpdateEntityRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEntityRoleMutation, Types.UpdateEntityRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEntityRoleMutation, Types.UpdateEntityRoleMutationVariables>(UpdateEntityRoleDocument, options);
      }
export type UpdateEntityRoleMutationHookResult = ReturnType<typeof useUpdateEntityRoleMutation>;
export type UpdateEntityRoleMutationResult = Apollo.MutationResult<Types.UpdateEntityRoleMutation>;
export type UpdateEntityRoleMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEntityRoleMutation, Types.UpdateEntityRoleMutationVariables>;
export const BusinessRolesDocument = gql`
    query BusinessRoles($entity: ID!, $team: ID!, $filterActive: Boolean, $ignoreCustomRoles: Boolean!, $withFlags: Boolean = false) {
  entity(id: $entity) {
    id
    ...EntityBusinessRoleMeta
  }
}
    ${EntityBusinessRoleMetaFragmentDoc}`;

/**
 * __useBusinessRolesQuery__
 *
 * To run a query within a React component, call `useBusinessRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessRolesQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *      team: // value for 'team'
 *      filterActive: // value for 'filterActive'
 *      ignoreCustomRoles: // value for 'ignoreCustomRoles'
 *      withFlags: // value for 'withFlags'
 *   },
 * });
 */
export function useBusinessRolesQuery(baseOptions: Apollo.QueryHookOptions<Types.BusinessRolesQuery, Types.BusinessRolesQueryVariables> & ({ variables: Types.BusinessRolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BusinessRolesQuery, Types.BusinessRolesQueryVariables>(BusinessRolesDocument, options);
      }
export function useBusinessRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BusinessRolesQuery, Types.BusinessRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BusinessRolesQuery, Types.BusinessRolesQueryVariables>(BusinessRolesDocument, options);
        }
export function useBusinessRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.BusinessRolesQuery, Types.BusinessRolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BusinessRolesQuery, Types.BusinessRolesQueryVariables>(BusinessRolesDocument, options);
        }
export type BusinessRolesQueryHookResult = ReturnType<typeof useBusinessRolesQuery>;
export type BusinessRolesLazyQueryHookResult = ReturnType<typeof useBusinessRolesLazyQuery>;
export type BusinessRolesSuspenseQueryHookResult = ReturnType<typeof useBusinessRolesSuspenseQuery>;
export type BusinessRolesQueryResult = Apollo.QueryResult<Types.BusinessRolesQuery, Types.BusinessRolesQueryVariables>;
export const RoleTitlesDocument = gql`
    query RoleTitles($country: ContentLanguage, $useGlobal: Boolean) {
  roleTitles(contentLanguage: $country, useGlobal: $useGlobal) {
    ...RoleTitle
  }
}
    ${RoleTitleFragmentDoc}`;

/**
 * __useRoleTitlesQuery__
 *
 * To run a query within a React component, call `useRoleTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleTitlesQuery({
 *   variables: {
 *      country: // value for 'country'
 *      useGlobal: // value for 'useGlobal'
 *   },
 * });
 */
export function useRoleTitlesQuery(baseOptions?: Apollo.QueryHookOptions<Types.RoleTitlesQuery, Types.RoleTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RoleTitlesQuery, Types.RoleTitlesQueryVariables>(RoleTitlesDocument, options);
      }
export function useRoleTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RoleTitlesQuery, Types.RoleTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RoleTitlesQuery, Types.RoleTitlesQueryVariables>(RoleTitlesDocument, options);
        }
export function useRoleTitlesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.RoleTitlesQuery, Types.RoleTitlesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RoleTitlesQuery, Types.RoleTitlesQueryVariables>(RoleTitlesDocument, options);
        }
export type RoleTitlesQueryHookResult = ReturnType<typeof useRoleTitlesQuery>;
export type RoleTitlesLazyQueryHookResult = ReturnType<typeof useRoleTitlesLazyQuery>;
export type RoleTitlesSuspenseQueryHookResult = ReturnType<typeof useRoleTitlesSuspenseQuery>;
export type RoleTitlesQueryResult = Apollo.QueryResult<Types.RoleTitlesQuery, Types.RoleTitlesQueryVariables>;
export const SidepanelEntityDocument = gql`
    query SidepanelEntity($entity: ID!, $team: ID!, $portfolio: ID!, $includeCreditReport: Boolean!, $includeRealEstates: Boolean!, $withFlags: Boolean!) {
  entity(id: $entity) {
    ...SidepanelEntity
  }
}
    ${SidepanelEntityFragmentDoc}`;

/**
 * __useSidepanelEntityQuery__
 *
 * To run a query within a React component, call `useSidepanelEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidepanelEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidepanelEntityQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      includeCreditReport: // value for 'includeCreditReport'
 *      includeRealEstates: // value for 'includeRealEstates'
 *      withFlags: // value for 'withFlags'
 *   },
 * });
 */
export function useSidepanelEntityQuery(baseOptions: Apollo.QueryHookOptions<Types.SidepanelEntityQuery, Types.SidepanelEntityQueryVariables> & ({ variables: Types.SidepanelEntityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SidepanelEntityQuery, Types.SidepanelEntityQueryVariables>(SidepanelEntityDocument, options);
      }
export function useSidepanelEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SidepanelEntityQuery, Types.SidepanelEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SidepanelEntityQuery, Types.SidepanelEntityQueryVariables>(SidepanelEntityDocument, options);
        }
export function useSidepanelEntitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.SidepanelEntityQuery, Types.SidepanelEntityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SidepanelEntityQuery, Types.SidepanelEntityQueryVariables>(SidepanelEntityDocument, options);
        }
export type SidepanelEntityQueryHookResult = ReturnType<typeof useSidepanelEntityQuery>;
export type SidepanelEntityLazyQueryHookResult = ReturnType<typeof useSidepanelEntityLazyQuery>;
export type SidepanelEntitySuspenseQueryHookResult = ReturnType<typeof useSidepanelEntitySuspenseQuery>;
export type SidepanelEntityQueryResult = Apollo.QueryResult<Types.SidepanelEntityQuery, Types.SidepanelEntityQueryVariables>;
export const SidepanelEntityKeyDetailsDocument = gql`
    query SidepanelEntityKeyDetails($entity: ID!, $shareholderLimit: Int) {
  entity(id: $entity) {
    id
    ... on Company {
      companyKeyDetails(shareholderLimit: $shareholderLimit) {
        address
        primaryIndustry
        shareholders {
          name
          share
        }
      }
    }
  }
}
    `;

/**
 * __useSidepanelEntityKeyDetailsQuery__
 *
 * To run a query within a React component, call `useSidepanelEntityKeyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidepanelEntityKeyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidepanelEntityKeyDetailsQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *      shareholderLimit: // value for 'shareholderLimit'
 *   },
 * });
 */
export function useSidepanelEntityKeyDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.SidepanelEntityKeyDetailsQuery, Types.SidepanelEntityKeyDetailsQueryVariables> & ({ variables: Types.SidepanelEntityKeyDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SidepanelEntityKeyDetailsQuery, Types.SidepanelEntityKeyDetailsQueryVariables>(SidepanelEntityKeyDetailsDocument, options);
      }
export function useSidepanelEntityKeyDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SidepanelEntityKeyDetailsQuery, Types.SidepanelEntityKeyDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SidepanelEntityKeyDetailsQuery, Types.SidepanelEntityKeyDetailsQueryVariables>(SidepanelEntityKeyDetailsDocument, options);
        }
export function useSidepanelEntityKeyDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.SidepanelEntityKeyDetailsQuery, Types.SidepanelEntityKeyDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SidepanelEntityKeyDetailsQuery, Types.SidepanelEntityKeyDetailsQueryVariables>(SidepanelEntityKeyDetailsDocument, options);
        }
export type SidepanelEntityKeyDetailsQueryHookResult = ReturnType<typeof useSidepanelEntityKeyDetailsQuery>;
export type SidepanelEntityKeyDetailsLazyQueryHookResult = ReturnType<typeof useSidepanelEntityKeyDetailsLazyQuery>;
export type SidepanelEntityKeyDetailsSuspenseQueryHookResult = ReturnType<typeof useSidepanelEntityKeyDetailsSuspenseQuery>;
export type SidepanelEntityKeyDetailsQueryResult = Apollo.QueryResult<Types.SidepanelEntityKeyDetailsQuery, Types.SidepanelEntityKeyDetailsQueryVariables>;
export const SidepanelNetworkRiskDocument = gql`
    query SidepanelNetworkRisk($entity: ID!, $team: ID!, $includeNetworkRoles: Boolean, $includePepV1: Boolean!, $includePepV2: Boolean!, $includeRiskSummary: Boolean!, $includeCompanyPeps: Boolean!) {
  company(id: $entity) {
    id
    riskSummary(team: $team, includeNetworkRoles: $includeNetworkRoles) @include(if: $includeRiskSummary) {
      ...RiskSummary
    }
    companyNetwork(team: $team, includeNetworkRoles: $includeNetworkRoles) @include(if: $includeCompanyPeps) {
      ...CompanyNetwork
    }
  }
}
    ${RiskSummaryFragmentDoc}
${CompanyNetworkFragmentDoc}`;

/**
 * __useSidepanelNetworkRiskQuery__
 *
 * To run a query within a React component, call `useSidepanelNetworkRiskQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidepanelNetworkRiskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidepanelNetworkRiskQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *      team: // value for 'team'
 *      includeNetworkRoles: // value for 'includeNetworkRoles'
 *      includePepV1: // value for 'includePepV1'
 *      includePepV2: // value for 'includePepV2'
 *      includeRiskSummary: // value for 'includeRiskSummary'
 *      includeCompanyPeps: // value for 'includeCompanyPeps'
 *   },
 * });
 */
export function useSidepanelNetworkRiskQuery(baseOptions: Apollo.QueryHookOptions<Types.SidepanelNetworkRiskQuery, Types.SidepanelNetworkRiskQueryVariables> & ({ variables: Types.SidepanelNetworkRiskQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SidepanelNetworkRiskQuery, Types.SidepanelNetworkRiskQueryVariables>(SidepanelNetworkRiskDocument, options);
      }
export function useSidepanelNetworkRiskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SidepanelNetworkRiskQuery, Types.SidepanelNetworkRiskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SidepanelNetworkRiskQuery, Types.SidepanelNetworkRiskQueryVariables>(SidepanelNetworkRiskDocument, options);
        }
export function useSidepanelNetworkRiskSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.SidepanelNetworkRiskQuery, Types.SidepanelNetworkRiskQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SidepanelNetworkRiskQuery, Types.SidepanelNetworkRiskQueryVariables>(SidepanelNetworkRiskDocument, options);
        }
export type SidepanelNetworkRiskQueryHookResult = ReturnType<typeof useSidepanelNetworkRiskQuery>;
export type SidepanelNetworkRiskLazyQueryHookResult = ReturnType<typeof useSidepanelNetworkRiskLazyQuery>;
export type SidepanelNetworkRiskSuspenseQueryHookResult = ReturnType<typeof useSidepanelNetworkRiskSuspenseQuery>;
export type SidepanelNetworkRiskQueryResult = Apollo.QueryResult<Types.SidepanelNetworkRiskQuery, Types.SidepanelNetworkRiskQueryVariables>;
export const AddTagsToCompaniesDocument = gql`
    mutation AddTagsToCompanies($team: ID!, $portfolio: ID!, $companies: [ID!]!, $tags: [ID!]!) {
  addStickersToCompanies(team: $team, companies: $companies, stickers: $tags) {
    team(id: $team) {
      id
      portfolio(id: $portfolio) {
        id
        companies(filter: {companies: $companies}) {
          edges {
            node {
              id
              ...CompanyTags
            }
          }
        }
      }
    }
  }
}
    ${CompanyTagsFragmentDoc}`;
export type AddTagsToCompaniesMutationFn = Apollo.MutationFunction<Types.AddTagsToCompaniesMutation, Types.AddTagsToCompaniesMutationVariables>;

/**
 * __useAddTagsToCompaniesMutation__
 *
 * To run a mutation, you first call `useAddTagsToCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagsToCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagsToCompaniesMutation, { data, loading, error }] = useAddTagsToCompaniesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      companies: // value for 'companies'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useAddTagsToCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddTagsToCompaniesMutation, Types.AddTagsToCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddTagsToCompaniesMutation, Types.AddTagsToCompaniesMutationVariables>(AddTagsToCompaniesDocument, options);
      }
export type AddTagsToCompaniesMutationHookResult = ReturnType<typeof useAddTagsToCompaniesMutation>;
export type AddTagsToCompaniesMutationResult = Apollo.MutationResult<Types.AddTagsToCompaniesMutation>;
export type AddTagsToCompaniesMutationOptions = Apollo.BaseMutationOptions<Types.AddTagsToCompaniesMutation, Types.AddTagsToCompaniesMutationVariables>;
export const CreateTagDocument = gql`
    mutation CreateTag($team: ID!, $name: String!) {
  createSticker(team: $team, name: $name) {
    id
    ...CompanyTag
  }
}
    ${CompanyTagFragmentDoc}`;
export type CreateTagMutationFn = Apollo.MutationFunction<Types.CreateTagMutation, Types.CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      team: // value for 'team'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTagMutation, Types.CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateTagMutation, Types.CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<Types.CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<Types.CreateTagMutation, Types.CreateTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($team: ID!, $tagId: ID!) {
  removeSticker(team: $team, stickers: [$tagId]) {
    team(id: $team) {
      id
      ...OrganizationTags
    }
  }
}
    ${OrganizationTagsFragmentDoc}`;
export type DeleteTagMutationFn = Apollo.MutationFunction<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      team: // value for 'team'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<Types.DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>;
export const RemoveTagsFromCompaniesDocument = gql`
    mutation RemoveTagsFromCompanies($team: ID!, $portfolio: ID!, $companies: [ID!]!, $tags: [ID!]!) {
  removeStickersFromCompanies(team: $team, companies: $companies, stickers: $tags) {
    team(id: $team) {
      id
      portfolio(id: $portfolio) {
        id
        companies(filter: {companies: $companies}) {
          edges {
            node {
              id
              ...CompanyTags
            }
          }
        }
      }
    }
  }
}
    ${CompanyTagsFragmentDoc}`;
export type RemoveTagsFromCompaniesMutationFn = Apollo.MutationFunction<Types.RemoveTagsFromCompaniesMutation, Types.RemoveTagsFromCompaniesMutationVariables>;

/**
 * __useRemoveTagsFromCompaniesMutation__
 *
 * To run a mutation, you first call `useRemoveTagsFromCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagsFromCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagsFromCompaniesMutation, { data, loading, error }] = useRemoveTagsFromCompaniesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      companies: // value for 'companies'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useRemoveTagsFromCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveTagsFromCompaniesMutation, Types.RemoveTagsFromCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveTagsFromCompaniesMutation, Types.RemoveTagsFromCompaniesMutationVariables>(RemoveTagsFromCompaniesDocument, options);
      }
export type RemoveTagsFromCompaniesMutationHookResult = ReturnType<typeof useRemoveTagsFromCompaniesMutation>;
export type RemoveTagsFromCompaniesMutationResult = Apollo.MutationResult<Types.RemoveTagsFromCompaniesMutation>;
export type RemoveTagsFromCompaniesMutationOptions = Apollo.BaseMutationOptions<Types.RemoveTagsFromCompaniesMutation, Types.RemoveTagsFromCompaniesMutationVariables>;
export const UpdateTagNameDocument = gql`
    mutation UpdateTagName($team: ID!, $tagId: ID!, $name: String!) {
  updateStickerName(team: $team, sticker: $tagId, name: $name) {
    team(id: $team) {
      id
      ...OrganizationTags
    }
  }
}
    ${OrganizationTagsFragmentDoc}`;
export type UpdateTagNameMutationFn = Apollo.MutationFunction<Types.UpdateTagNameMutation, Types.UpdateTagNameMutationVariables>;

/**
 * __useUpdateTagNameMutation__
 *
 * To run a mutation, you first call `useUpdateTagNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagNameMutation, { data, loading, error }] = useUpdateTagNameMutation({
 *   variables: {
 *      team: // value for 'team'
 *      tagId: // value for 'tagId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateTagNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTagNameMutation, Types.UpdateTagNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTagNameMutation, Types.UpdateTagNameMutationVariables>(UpdateTagNameDocument, options);
      }
export type UpdateTagNameMutationHookResult = ReturnType<typeof useUpdateTagNameMutation>;
export type UpdateTagNameMutationResult = Apollo.MutationResult<Types.UpdateTagNameMutation>;
export type UpdateTagNameMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTagNameMutation, Types.UpdateTagNameMutationVariables>;
export const TagsDocument = gql`
    query Tags($team: ID!) {
  team(id: $team) {
    id
    ...OrganizationTags
  }
}
    ${OrganizationTagsFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      team: // value for 'team'
 *   },
 * });
 */
export function useTagsQuery(baseOptions: Apollo.QueryHookOptions<Types.TagsQuery, Types.TagsQueryVariables> & ({ variables: Types.TagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TagsQuery, Types.TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TagsQuery, Types.TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TagsQuery, Types.TagsQueryVariables>(TagsDocument, options);
        }
export function useTagsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.TagsQuery, Types.TagsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TagsQuery, Types.TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsSuspenseQueryHookResult = ReturnType<typeof useTagsSuspenseQuery>;
export type TagsQueryResult = Apollo.QueryResult<Types.TagsQuery, Types.TagsQueryVariables>;
export const CreateTeamActivityV2Document = gql`
    mutation CreateTeamActivityV2($team: ID!, $companyId: ID!, $input: TeamActivityCreateInput!, $page: TeamActivityPageInfoInput!, $reminderStatus: ReminderStatus, $filter: TeamActivityFilterKind) {
  createTeamActivityV2(team: $team, company: $companyId, input: $input) {
    company(id: $companyId) {
      id
      ...CompanyTeamActivities
    }
  }
}
    ${CompanyTeamActivitiesFragmentDoc}`;
export type CreateTeamActivityV2MutationFn = Apollo.MutationFunction<Types.CreateTeamActivityV2Mutation, Types.CreateTeamActivityV2MutationVariables>;

/**
 * __useCreateTeamActivityV2Mutation__
 *
 * To run a mutation, you first call `useCreateTeamActivityV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamActivityV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamActivityV2Mutation, { data, loading, error }] = useCreateTeamActivityV2Mutation({
 *   variables: {
 *      team: // value for 'team'
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      reminderStatus: // value for 'reminderStatus'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCreateTeamActivityV2Mutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTeamActivityV2Mutation, Types.CreateTeamActivityV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateTeamActivityV2Mutation, Types.CreateTeamActivityV2MutationVariables>(CreateTeamActivityV2Document, options);
      }
export type CreateTeamActivityV2MutationHookResult = ReturnType<typeof useCreateTeamActivityV2Mutation>;
export type CreateTeamActivityV2MutationResult = Apollo.MutationResult<Types.CreateTeamActivityV2Mutation>;
export type CreateTeamActivityV2MutationOptions = Apollo.BaseMutationOptions<Types.CreateTeamActivityV2Mutation, Types.CreateTeamActivityV2MutationVariables>;
export const DeleteTeamActivityDocument = gql`
    mutation DeleteTeamActivity($team: ID!, $companyId: ID!, $teamActivity: ID!, $page: TeamActivityPageInfoInput!, $reminderStatus: ReminderStatus, $filter: TeamActivityFilterKind) {
  deleteTeamActivity(team: $team, teamActivity: $teamActivity) {
    company(id: $companyId) {
      id
      ...CompanyTeamActivities
    }
  }
}
    ${CompanyTeamActivitiesFragmentDoc}`;
export type DeleteTeamActivityMutationFn = Apollo.MutationFunction<Types.DeleteTeamActivityMutation, Types.DeleteTeamActivityMutationVariables>;

/**
 * __useDeleteTeamActivityMutation__
 *
 * To run a mutation, you first call `useDeleteTeamActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamActivityMutation, { data, loading, error }] = useDeleteTeamActivityMutation({
 *   variables: {
 *      team: // value for 'team'
 *      companyId: // value for 'companyId'
 *      teamActivity: // value for 'teamActivity'
 *      page: // value for 'page'
 *      reminderStatus: // value for 'reminderStatus'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeleteTeamActivityMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteTeamActivityMutation, Types.DeleteTeamActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteTeamActivityMutation, Types.DeleteTeamActivityMutationVariables>(DeleteTeamActivityDocument, options);
      }
export type DeleteTeamActivityMutationHookResult = ReturnType<typeof useDeleteTeamActivityMutation>;
export type DeleteTeamActivityMutationResult = Apollo.MutationResult<Types.DeleteTeamActivityMutation>;
export type DeleteTeamActivityMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTeamActivityMutation, Types.DeleteTeamActivityMutationVariables>;
export const UpdateTeamActivityV2Document = gql`
    mutation UpdateTeamActivityV2($teamActivity: ID!, $input: TeamActivityUpdateInput!) {
  updateTeamActivityV2(teamActivity: $teamActivity, input: $input) {
    teamActivity(id: $teamActivity) {
      id
      ...TeamActivity
    }
  }
}
    ${TeamActivityFragmentDoc}`;
export type UpdateTeamActivityV2MutationFn = Apollo.MutationFunction<Types.UpdateTeamActivityV2Mutation, Types.UpdateTeamActivityV2MutationVariables>;

/**
 * __useUpdateTeamActivityV2Mutation__
 *
 * To run a mutation, you first call `useUpdateTeamActivityV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamActivityV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamActivityV2Mutation, { data, loading, error }] = useUpdateTeamActivityV2Mutation({
 *   variables: {
 *      teamActivity: // value for 'teamActivity'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamActivityV2Mutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTeamActivityV2Mutation, Types.UpdateTeamActivityV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTeamActivityV2Mutation, Types.UpdateTeamActivityV2MutationVariables>(UpdateTeamActivityV2Document, options);
      }
export type UpdateTeamActivityV2MutationHookResult = ReturnType<typeof useUpdateTeamActivityV2Mutation>;
export type UpdateTeamActivityV2MutationResult = Apollo.MutationResult<Types.UpdateTeamActivityV2Mutation>;
export type UpdateTeamActivityV2MutationOptions = Apollo.BaseMutationOptions<Types.UpdateTeamActivityV2Mutation, Types.UpdateTeamActivityV2MutationVariables>;
export const ActivityStatsDocument = gql`
    query ActivityStats($team: ID!, $user: ID!) {
  activityStats(team: $team, user: $user) {
    userStats {
      search {
        ...ActivityStat
      }
      review {
        ...ActivityStat
      }
    }
    teamStats {
      ...ActivityStats
    }
    mostActiveUsers {
      id
      ...SimpleUser
    }
  }
}
    ${ActivityStatFragmentDoc}
${ActivityStatsFragmentDoc}
${SimpleUserFragmentDoc}`;

/**
 * __useActivityStatsQuery__
 *
 * To run a query within a React component, call `useActivityStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityStatsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useActivityStatsQuery(baseOptions: Apollo.QueryHookOptions<Types.ActivityStatsQuery, Types.ActivityStatsQueryVariables> & ({ variables: Types.ActivityStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActivityStatsQuery, Types.ActivityStatsQueryVariables>(ActivityStatsDocument, options);
      }
export function useActivityStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActivityStatsQuery, Types.ActivityStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActivityStatsQuery, Types.ActivityStatsQueryVariables>(ActivityStatsDocument, options);
        }
export function useActivityStatsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ActivityStatsQuery, Types.ActivityStatsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ActivityStatsQuery, Types.ActivityStatsQueryVariables>(ActivityStatsDocument, options);
        }
export type ActivityStatsQueryHookResult = ReturnType<typeof useActivityStatsQuery>;
export type ActivityStatsLazyQueryHookResult = ReturnType<typeof useActivityStatsLazyQuery>;
export type ActivityStatsSuspenseQueryHookResult = ReturnType<typeof useActivityStatsSuspenseQuery>;
export type ActivityStatsQueryResult = Apollo.QueryResult<Types.ActivityStatsQuery, Types.ActivityStatsQueryVariables>;
export const CompanyTeamActivitiesDocument = gql`
    query CompanyTeamActivities($team: ID!, $id: ID!, $page: TeamActivityPageInfoInput!, $reminderStatus: ReminderStatus, $filter: TeamActivityFilterKind) {
  company(id: $id) {
    id
    ...CompanyTeamActivities
  }
}
    ${CompanyTeamActivitiesFragmentDoc}`;

/**
 * __useCompanyTeamActivitiesQuery__
 *
 * To run a query within a React component, call `useCompanyTeamActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTeamActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTeamActivitiesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      reminderStatus: // value for 'reminderStatus'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyTeamActivitiesQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyTeamActivitiesQuery, Types.CompanyTeamActivitiesQueryVariables> & ({ variables: Types.CompanyTeamActivitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyTeamActivitiesQuery, Types.CompanyTeamActivitiesQueryVariables>(CompanyTeamActivitiesDocument, options);
      }
export function useCompanyTeamActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyTeamActivitiesQuery, Types.CompanyTeamActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyTeamActivitiesQuery, Types.CompanyTeamActivitiesQueryVariables>(CompanyTeamActivitiesDocument, options);
        }
export function useCompanyTeamActivitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CompanyTeamActivitiesQuery, Types.CompanyTeamActivitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyTeamActivitiesQuery, Types.CompanyTeamActivitiesQueryVariables>(CompanyTeamActivitiesDocument, options);
        }
export type CompanyTeamActivitiesQueryHookResult = ReturnType<typeof useCompanyTeamActivitiesQuery>;
export type CompanyTeamActivitiesLazyQueryHookResult = ReturnType<typeof useCompanyTeamActivitiesLazyQuery>;
export type CompanyTeamActivitiesSuspenseQueryHookResult = ReturnType<typeof useCompanyTeamActivitiesSuspenseQuery>;
export type CompanyTeamActivitiesQueryResult = Apollo.QueryResult<Types.CompanyTeamActivitiesQuery, Types.CompanyTeamActivitiesQueryVariables>;
export const TeamActivitiesDocument = gql`
    query TeamActivities($team: ID!, $user: ID!, $page: TeamActivityPageInfoInput!, $reminderStatus: ReminderStatus, $filter: TeamActivityFilterKind) {
  teamActivities(
    team: $team
    user: $user
    page: $page
    reminderStatus: $reminderStatus
    filter: $filter
  ) {
    edges {
      node {
        id
        ...TeamActivity
      }
    }
    totalCount
  }
}
    ${TeamActivityFragmentDoc}`;

/**
 * __useTeamActivitiesQuery__
 *
 * To run a query within a React component, call `useTeamActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamActivitiesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      user: // value for 'user'
 *      page: // value for 'page'
 *      reminderStatus: // value for 'reminderStatus'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamActivitiesQuery(baseOptions: Apollo.QueryHookOptions<Types.TeamActivitiesQuery, Types.TeamActivitiesQueryVariables> & ({ variables: Types.TeamActivitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeamActivitiesQuery, Types.TeamActivitiesQueryVariables>(TeamActivitiesDocument, options);
      }
export function useTeamActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamActivitiesQuery, Types.TeamActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeamActivitiesQuery, Types.TeamActivitiesQueryVariables>(TeamActivitiesDocument, options);
        }
export function useTeamActivitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.TeamActivitiesQuery, Types.TeamActivitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TeamActivitiesQuery, Types.TeamActivitiesQueryVariables>(TeamActivitiesDocument, options);
        }
export type TeamActivitiesQueryHookResult = ReturnType<typeof useTeamActivitiesQuery>;
export type TeamActivitiesLazyQueryHookResult = ReturnType<typeof useTeamActivitiesLazyQuery>;
export type TeamActivitiesSuspenseQueryHookResult = ReturnType<typeof useTeamActivitiesSuspenseQuery>;
export type TeamActivitiesQueryResult = Apollo.QueryResult<Types.TeamActivitiesQuery, Types.TeamActivitiesQueryVariables>;
export const ResetTeamReviewSettingsDocument = gql`
    mutation ResetTeamReviewSettings($team: ID!, $sections: [ReviewSectionKind!]!) {
  resetTeamReviewSettings(team: $team, sections: $sections) {
    team(id: $team) {
      id
      teamSettings {
        ...TeamReviewSettingsV2
      }
    }
  }
}
    ${TeamReviewSettingsV2FragmentDoc}`;
export type ResetTeamReviewSettingsMutationFn = Apollo.MutationFunction<Types.ResetTeamReviewSettingsMutation, Types.ResetTeamReviewSettingsMutationVariables>;

/**
 * __useResetTeamReviewSettingsMutation__
 *
 * To run a mutation, you first call `useResetTeamReviewSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTeamReviewSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTeamReviewSettingsMutation, { data, loading, error }] = useResetTeamReviewSettingsMutation({
 *   variables: {
 *      team: // value for 'team'
 *      sections: // value for 'sections'
 *   },
 * });
 */
export function useResetTeamReviewSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetTeamReviewSettingsMutation, Types.ResetTeamReviewSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetTeamReviewSettingsMutation, Types.ResetTeamReviewSettingsMutationVariables>(ResetTeamReviewSettingsDocument, options);
      }
export type ResetTeamReviewSettingsMutationHookResult = ReturnType<typeof useResetTeamReviewSettingsMutation>;
export type ResetTeamReviewSettingsMutationResult = Apollo.MutationResult<Types.ResetTeamReviewSettingsMutation>;
export type ResetTeamReviewSettingsMutationOptions = Apollo.BaseMutationOptions<Types.ResetTeamReviewSettingsMutation, Types.ResetTeamReviewSettingsMutationVariables>;
export const ReviewPdfSectionsDocument = gql`
    query ReviewPdfSections {
  reviewPdfSections {
    name
    kind
  }
}
    `;

/**
 * __useReviewPdfSectionsQuery__
 *
 * To run a query within a React component, call `useReviewPdfSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewPdfSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewPdfSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewPdfSectionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ReviewPdfSectionsQuery, Types.ReviewPdfSectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewPdfSectionsQuery, Types.ReviewPdfSectionsQueryVariables>(ReviewPdfSectionsDocument, options);
      }
export function useReviewPdfSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewPdfSectionsQuery, Types.ReviewPdfSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewPdfSectionsQuery, Types.ReviewPdfSectionsQueryVariables>(ReviewPdfSectionsDocument, options);
        }
export function useReviewPdfSectionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ReviewPdfSectionsQuery, Types.ReviewPdfSectionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewPdfSectionsQuery, Types.ReviewPdfSectionsQueryVariables>(ReviewPdfSectionsDocument, options);
        }
export type ReviewPdfSectionsQueryHookResult = ReturnType<typeof useReviewPdfSectionsQuery>;
export type ReviewPdfSectionsLazyQueryHookResult = ReturnType<typeof useReviewPdfSectionsLazyQuery>;
export type ReviewPdfSectionsSuspenseQueryHookResult = ReturnType<typeof useReviewPdfSectionsSuspenseQuery>;
export type ReviewPdfSectionsQueryResult = Apollo.QueryResult<Types.ReviewPdfSectionsQuery, Types.ReviewPdfSectionsQueryVariables>;
export const ReviewStatementInfoDocument = gql`
    query ReviewStatementInfo($team: ID!, $countries: [ContentLanguage!]!) {
  reviewStatementInfoV2(team: $team) {
    ...ReviewTriggerStatementInfo
  }
}
    ${ReviewTriggerStatementInfoFragmentDoc}`;

/**
 * __useReviewStatementInfoQuery__
 *
 * To run a query within a React component, call `useReviewStatementInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewStatementInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewStatementInfoQuery({
 *   variables: {
 *      team: // value for 'team'
 *      countries: // value for 'countries'
 *   },
 * });
 */
export function useReviewStatementInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewStatementInfoQuery, Types.ReviewStatementInfoQueryVariables> & ({ variables: Types.ReviewStatementInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewStatementInfoQuery, Types.ReviewStatementInfoQueryVariables>(ReviewStatementInfoDocument, options);
      }
export function useReviewStatementInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewStatementInfoQuery, Types.ReviewStatementInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewStatementInfoQuery, Types.ReviewStatementInfoQueryVariables>(ReviewStatementInfoDocument, options);
        }
export function useReviewStatementInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ReviewStatementInfoQuery, Types.ReviewStatementInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewStatementInfoQuery, Types.ReviewStatementInfoQueryVariables>(ReviewStatementInfoDocument, options);
        }
export type ReviewStatementInfoQueryHookResult = ReturnType<typeof useReviewStatementInfoQuery>;
export type ReviewStatementInfoLazyQueryHookResult = ReturnType<typeof useReviewStatementInfoLazyQuery>;
export type ReviewStatementInfoSuspenseQueryHookResult = ReturnType<typeof useReviewStatementInfoSuspenseQuery>;
export type ReviewStatementInfoQueryResult = Apollo.QueryResult<Types.ReviewStatementInfoQuery, Types.ReviewStatementInfoQueryVariables>;
export const TeamReviewSettingsDocument = gql`
    query TeamReviewSettings($team: ID!) {
  team(id: $team) {
    id
    teamSettings {
      ...TeamReviewSettingsV2
    }
  }
}
    ${TeamReviewSettingsV2FragmentDoc}`;

/**
 * __useTeamReviewSettingsQuery__
 *
 * To run a query within a React component, call `useTeamReviewSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamReviewSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamReviewSettingsQuery({
 *   variables: {
 *      team: // value for 'team'
 *   },
 * });
 */
export function useTeamReviewSettingsQuery(baseOptions: Apollo.QueryHookOptions<Types.TeamReviewSettingsQuery, Types.TeamReviewSettingsQueryVariables> & ({ variables: Types.TeamReviewSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeamReviewSettingsQuery, Types.TeamReviewSettingsQueryVariables>(TeamReviewSettingsDocument, options);
      }
export function useTeamReviewSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamReviewSettingsQuery, Types.TeamReviewSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeamReviewSettingsQuery, Types.TeamReviewSettingsQueryVariables>(TeamReviewSettingsDocument, options);
        }
export function useTeamReviewSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.TeamReviewSettingsQuery, Types.TeamReviewSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TeamReviewSettingsQuery, Types.TeamReviewSettingsQueryVariables>(TeamReviewSettingsDocument, options);
        }
export type TeamReviewSettingsQueryHookResult = ReturnType<typeof useTeamReviewSettingsQuery>;
export type TeamReviewSettingsLazyQueryHookResult = ReturnType<typeof useTeamReviewSettingsLazyQuery>;
export type TeamReviewSettingsSuspenseQueryHookResult = ReturnType<typeof useTeamReviewSettingsSuspenseQuery>;
export type TeamReviewSettingsQueryResult = Apollo.QueryResult<Types.TeamReviewSettingsQuery, Types.TeamReviewSettingsQueryVariables>;
export const TeamSettingsDocument = gql`
    query TeamSettings($team: ID!) {
  team(id: $team) {
    id
    ...TeamSettings
  }
  entityTypes(team: $team)
}
    ${TeamSettingsFragmentDoc}`;

/**
 * __useTeamSettingsQuery__
 *
 * To run a query within a React component, call `useTeamSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamSettingsQuery({
 *   variables: {
 *      team: // value for 'team'
 *   },
 * });
 */
export function useTeamSettingsQuery(baseOptions: Apollo.QueryHookOptions<Types.TeamSettingsQuery, Types.TeamSettingsQueryVariables> & ({ variables: Types.TeamSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeamSettingsQuery, Types.TeamSettingsQueryVariables>(TeamSettingsDocument, options);
      }
export function useTeamSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamSettingsQuery, Types.TeamSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeamSettingsQuery, Types.TeamSettingsQueryVariables>(TeamSettingsDocument, options);
        }
export function useTeamSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.TeamSettingsQuery, Types.TeamSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TeamSettingsQuery, Types.TeamSettingsQueryVariables>(TeamSettingsDocument, options);
        }
export type TeamSettingsQueryHookResult = ReturnType<typeof useTeamSettingsQuery>;
export type TeamSettingsLazyQueryHookResult = ReturnType<typeof useTeamSettingsLazyQuery>;
export type TeamSettingsSuspenseQueryHookResult = ReturnType<typeof useTeamSettingsSuspenseQuery>;
export type TeamSettingsQueryResult = Apollo.QueryResult<Types.TeamSettingsQuery, Types.TeamSettingsQueryVariables>;
export const TeamUsageReportCsvDocument = gql`
    query TeamUsageReportCsv($teamUsageReportCsvId: ID!, $from: ISODateTime!, $to: ISODateTime!) {
  teamUsageReportCsv(id: $teamUsageReportCsvId, from: $from, to: $to)
}
    `;

/**
 * __useTeamUsageReportCsvQuery__
 *
 * To run a query within a React component, call `useTeamUsageReportCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamUsageReportCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamUsageReportCsvQuery({
 *   variables: {
 *      teamUsageReportCsvId: // value for 'teamUsageReportCsvId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useTeamUsageReportCsvQuery(baseOptions: Apollo.QueryHookOptions<Types.TeamUsageReportCsvQuery, Types.TeamUsageReportCsvQueryVariables> & ({ variables: Types.TeamUsageReportCsvQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeamUsageReportCsvQuery, Types.TeamUsageReportCsvQueryVariables>(TeamUsageReportCsvDocument, options);
      }
export function useTeamUsageReportCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamUsageReportCsvQuery, Types.TeamUsageReportCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeamUsageReportCsvQuery, Types.TeamUsageReportCsvQueryVariables>(TeamUsageReportCsvDocument, options);
        }
export function useTeamUsageReportCsvSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.TeamUsageReportCsvQuery, Types.TeamUsageReportCsvQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TeamUsageReportCsvQuery, Types.TeamUsageReportCsvQueryVariables>(TeamUsageReportCsvDocument, options);
        }
export type TeamUsageReportCsvQueryHookResult = ReturnType<typeof useTeamUsageReportCsvQuery>;
export type TeamUsageReportCsvLazyQueryHookResult = ReturnType<typeof useTeamUsageReportCsvLazyQuery>;
export type TeamUsageReportCsvSuspenseQueryHookResult = ReturnType<typeof useTeamUsageReportCsvSuspenseQuery>;
export type TeamUsageReportCsvQueryResult = Apollo.QueryResult<Types.TeamUsageReportCsvQuery, Types.TeamUsageReportCsvQueryVariables>;
export const AddUsersToTeamDocument = gql`
    mutation AddUsersToTeam($team: ID!, $users: UsersCreationInputV2!) {
  addUsersToTeam: addUsersToTeamV2(team: $team, users: $users) {
    team(id: $team) {
      id
      name
      ...TeamSimpleUsers
    }
  }
}
    ${TeamSimpleUsersFragmentDoc}`;
export type AddUsersToTeamMutationFn = Apollo.MutationFunction<Types.AddUsersToTeamMutation, Types.AddUsersToTeamMutationVariables>;

/**
 * __useAddUsersToTeamMutation__
 *
 * To run a mutation, you first call `useAddUsersToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToTeamMutation, { data, loading, error }] = useAddUsersToTeamMutation({
 *   variables: {
 *      team: // value for 'team'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useAddUsersToTeamMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddUsersToTeamMutation, Types.AddUsersToTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddUsersToTeamMutation, Types.AddUsersToTeamMutationVariables>(AddUsersToTeamDocument, options);
      }
export type AddUsersToTeamMutationHookResult = ReturnType<typeof useAddUsersToTeamMutation>;
export type AddUsersToTeamMutationResult = Apollo.MutationResult<Types.AddUsersToTeamMutation>;
export type AddUsersToTeamMutationOptions = Apollo.BaseMutationOptions<Types.AddUsersToTeamMutation, Types.AddUsersToTeamMutationVariables>;
export const AssignUserRoleDocument = gql`
    mutation AssignUserRole($userId: ID!, $teamId: ID!, $roleId: ID!) {
  assignUserRole(user: $userId, team: $teamId, role: $roleId) {
    team(id: $teamId) {
      id
      ...TeamSimpleUsers
    }
  }
}
    ${TeamSimpleUsersFragmentDoc}`;
export type AssignUserRoleMutationFn = Apollo.MutationFunction<Types.AssignUserRoleMutation, Types.AssignUserRoleMutationVariables>;

/**
 * __useAssignUserRoleMutation__
 *
 * To run a mutation, you first call `useAssignUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserRoleMutation, { data, loading, error }] = useAssignUserRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useAssignUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.AssignUserRoleMutation, Types.AssignUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssignUserRoleMutation, Types.AssignUserRoleMutationVariables>(AssignUserRoleDocument, options);
      }
export type AssignUserRoleMutationHookResult = ReturnType<typeof useAssignUserRoleMutation>;
export type AssignUserRoleMutationResult = Apollo.MutationResult<Types.AssignUserRoleMutation>;
export type AssignUserRoleMutationOptions = Apollo.BaseMutationOptions<Types.AssignUserRoleMutation, Types.AssignUserRoleMutationVariables>;
export const RemoveUserFromTeamDocument = gql`
    mutation RemoveUserFromTeam($user: ID!, $team: ID!) {
  removeUserFromTeam(user: $user, team: $team) {
    team(id: $team) {
      id
      ...TeamSimpleUsers
    }
  }
}
    ${TeamSimpleUsersFragmentDoc}`;
export type RemoveUserFromTeamMutationFn = Apollo.MutationFunction<Types.RemoveUserFromTeamMutation, Types.RemoveUserFromTeamMutationVariables>;

/**
 * __useRemoveUserFromTeamMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromTeamMutation, { data, loading, error }] = useRemoveUserFromTeamMutation({
 *   variables: {
 *      user: // value for 'user'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useRemoveUserFromTeamMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveUserFromTeamMutation, Types.RemoveUserFromTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveUserFromTeamMutation, Types.RemoveUserFromTeamMutationVariables>(RemoveUserFromTeamDocument, options);
      }
export type RemoveUserFromTeamMutationHookResult = ReturnType<typeof useRemoveUserFromTeamMutation>;
export type RemoveUserFromTeamMutationResult = Apollo.MutationResult<Types.RemoveUserFromTeamMutation>;
export type RemoveUserFromTeamMutationOptions = Apollo.BaseMutationOptions<Types.RemoveUserFromTeamMutation, Types.RemoveUserFromTeamMutationVariables>;
export const RequestUsersToTeamDocument = gql`
    mutation RequestUsersToTeam($team: ID!, $emails: [String!]!) {
  requestUsersToTeam(team: $team, emails: $emails) {
    team(id: $team) {
      id
      name
      ...TeamSimpleUsers
    }
  }
}
    ${TeamSimpleUsersFragmentDoc}`;
export type RequestUsersToTeamMutationFn = Apollo.MutationFunction<Types.RequestUsersToTeamMutation, Types.RequestUsersToTeamMutationVariables>;

/**
 * __useRequestUsersToTeamMutation__
 *
 * To run a mutation, you first call `useRequestUsersToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUsersToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUsersToTeamMutation, { data, loading, error }] = useRequestUsersToTeamMutation({
 *   variables: {
 *      team: // value for 'team'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useRequestUsersToTeamMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestUsersToTeamMutation, Types.RequestUsersToTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestUsersToTeamMutation, Types.RequestUsersToTeamMutationVariables>(RequestUsersToTeamDocument, options);
      }
export type RequestUsersToTeamMutationHookResult = ReturnType<typeof useRequestUsersToTeamMutation>;
export type RequestUsersToTeamMutationResult = Apollo.MutationResult<Types.RequestUsersToTeamMutation>;
export type RequestUsersToTeamMutationOptions = Apollo.BaseMutationOptions<Types.RequestUsersToTeamMutation, Types.RequestUsersToTeamMutationVariables>;
export const TeamUsersDocument = gql`
    query TeamUsers($team: ID!, $q: String, $page: UserPageInfoInput) {
  team(id: $team) {
    id
    name
    users: simpleUsers(q: $q, page: $page) {
      edges {
        ...TeamSimpleUserConnection
      }
      totalCount
    }
  }
}
    ${TeamSimpleUserConnectionFragmentDoc}`;

/**
 * __useTeamUsersQuery__
 *
 * To run a query within a React component, call `useTeamUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamUsersQuery({
 *   variables: {
 *      team: // value for 'team'
 *      q: // value for 'q'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTeamUsersQuery(baseOptions: Apollo.QueryHookOptions<Types.TeamUsersQuery, Types.TeamUsersQueryVariables> & ({ variables: Types.TeamUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeamUsersQuery, Types.TeamUsersQueryVariables>(TeamUsersDocument, options);
      }
export function useTeamUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamUsersQuery, Types.TeamUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeamUsersQuery, Types.TeamUsersQueryVariables>(TeamUsersDocument, options);
        }
export function useTeamUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.TeamUsersQuery, Types.TeamUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TeamUsersQuery, Types.TeamUsersQueryVariables>(TeamUsersDocument, options);
        }
export type TeamUsersQueryHookResult = ReturnType<typeof useTeamUsersQuery>;
export type TeamUsersLazyQueryHookResult = ReturnType<typeof useTeamUsersLazyQuery>;
export type TeamUsersSuspenseQueryHookResult = ReturnType<typeof useTeamUsersSuspenseQuery>;
export type TeamUsersQueryResult = Apollo.QueryResult<Types.TeamUsersQuery, Types.TeamUsersQueryVariables>;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($team: ID!, $name: String!) {
  updateTeam(id: $team, name: $name) {
    team(id: $team) {
      id
      name
    }
  }
}
    `;
export type UpdateTeamMutationFn = Apollo.MutationFunction<Types.UpdateTeamMutation, Types.UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      team: // value for 'team'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTeamMutation, Types.UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTeamMutation, Types.UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<Types.UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTeamMutation, Types.UpdateTeamMutationVariables>;
export const TopicsDocument = gql`
    query Topics {
  topics(topLevel: true, first: 30) {
    edges {
      node {
        id
        ...Topic
      }
    }
  }
}
    ${TopicFragmentDoc}`;

/**
 * __useTopicsQuery__
 *
 * To run a query within a React component, call `useTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopicsQuery(baseOptions?: Apollo.QueryHookOptions<Types.TopicsQuery, Types.TopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TopicsQuery, Types.TopicsQueryVariables>(TopicsDocument, options);
      }
export function useTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TopicsQuery, Types.TopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TopicsQuery, Types.TopicsQueryVariables>(TopicsDocument, options);
        }
export function useTopicsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.TopicsQuery, Types.TopicsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TopicsQuery, Types.TopicsQueryVariables>(TopicsDocument, options);
        }
export type TopicsQueryHookResult = ReturnType<typeof useTopicsQuery>;
export type TopicsLazyQueryHookResult = ReturnType<typeof useTopicsLazyQuery>;
export type TopicsSuspenseQueryHookResult = ReturnType<typeof useTopicsSuspenseQuery>;
export type TopicsQueryResult = Apollo.QueryResult<Types.TopicsQuery, Types.TopicsQueryVariables>;
export const ResetUserSettingsDocument = gql`
    mutation ResetUserSettings($user: ID!, $events: Boolean!, $opportunity: Boolean!, $listView: Boolean!, $review: Boolean!, $application: Boolean!, $privacy: Boolean!, $team: ID!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  resetSettings(
    user: $user
    events: $events
    opportunity: $opportunity
    listView: $listView
    review: $review
    application: $application
    privacy: $privacy
  ) {
    currentUser {
      id
      ...Settings
    }
  }
}
    ${SettingsFragmentDoc}`;
export type ResetUserSettingsMutationFn = Apollo.MutationFunction<Types.ResetUserSettingsMutation, Types.ResetUserSettingsMutationVariables>;

/**
 * __useResetUserSettingsMutation__
 *
 * To run a mutation, you first call `useResetUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserSettingsMutation, { data, loading, error }] = useResetUserSettingsMutation({
 *   variables: {
 *      user: // value for 'user'
 *      events: // value for 'events'
 *      opportunity: // value for 'opportunity'
 *      listView: // value for 'listView'
 *      review: // value for 'review'
 *      application: // value for 'application'
 *      privacy: // value for 'privacy'
 *      team: // value for 'team'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useResetUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetUserSettingsMutation, Types.ResetUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetUserSettingsMutation, Types.ResetUserSettingsMutationVariables>(ResetUserSettingsDocument, options);
      }
export type ResetUserSettingsMutationHookResult = ReturnType<typeof useResetUserSettingsMutation>;
export type ResetUserSettingsMutationResult = Apollo.MutationResult<Types.ResetUserSettingsMutation>;
export type ResetUserSettingsMutationOptions = Apollo.BaseMutationOptions<Types.ResetUserSettingsMutation, Types.ResetUserSettingsMutationVariables>;
export const UpdateAllUserSettingsDocument = gql`
    mutation UpdateAllUserSettings($user: ID!, $team: ID!, $settings: UserSettingsInput!, $features: [FeatureInput!]!, $name: String!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  updateUserSettings(user: $user, input: $settings) {
    currentUser {
      id
      ...Settings
    }
  }
  updateUserFeatures: updateUserFeaturesV2(
    user: $user
    team: $team
    features: $features
  ) {
    user: currentUser {
      id
      ...Settings
    }
  }
  updateUser(user: $user, name: $name) {
    user: simpleUser(id: $user) {
      id
      ...UserInfo
    }
  }
}
    ${SettingsFragmentDoc}
${UserInfoFragmentDoc}`;
export type UpdateAllUserSettingsMutationFn = Apollo.MutationFunction<Types.UpdateAllUserSettingsMutation, Types.UpdateAllUserSettingsMutationVariables>;

/**
 * __useUpdateAllUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAllUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllUserSettingsMutation, { data, loading, error }] = useUpdateAllUserSettingsMutation({
 *   variables: {
 *      user: // value for 'user'
 *      team: // value for 'team'
 *      settings: // value for 'settings'
 *      features: // value for 'features'
 *      name: // value for 'name'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useUpdateAllUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAllUserSettingsMutation, Types.UpdateAllUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAllUserSettingsMutation, Types.UpdateAllUserSettingsMutationVariables>(UpdateAllUserSettingsDocument, options);
      }
export type UpdateAllUserSettingsMutationHookResult = ReturnType<typeof useUpdateAllUserSettingsMutation>;
export type UpdateAllUserSettingsMutationResult = Apollo.MutationResult<Types.UpdateAllUserSettingsMutation>;
export type UpdateAllUserSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAllUserSettingsMutation, Types.UpdateAllUserSettingsMutationVariables>;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($user: ID!, $team: ID!, $input: UserSettingsInput!) {
  updateUserSettings(user: $user, input: $input) {
    currentUser {
      id
      userSettings(team: $team) {
        ...UserSettings
      }
    }
  }
}
    ${UserSettingsFragmentDoc}`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<Types.UpdateUserSettingsMutation, Types.UpdateUserSettingsMutationVariables>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      user: // value for 'user'
 *      team: // value for 'team'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserSettingsMutation, Types.UpdateUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserSettingsMutation, Types.UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, options);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<Types.UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserSettingsMutation, Types.UpdateUserSettingsMutationVariables>;
export const CurrentUserSettingsDocument = gql`
    query CurrentUserSettings($team: ID!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  currentUser {
    id
    ...Settings
  }
}
    ${SettingsFragmentDoc}`;

/**
 * __useCurrentUserSettingsQuery__
 *
 * To run a query within a React component, call `useCurrentUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSettingsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useCurrentUserSettingsQuery(baseOptions: Apollo.QueryHookOptions<Types.CurrentUserSettingsQuery, Types.CurrentUserSettingsQueryVariables> & ({ variables: Types.CurrentUserSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentUserSettingsQuery, Types.CurrentUserSettingsQueryVariables>(CurrentUserSettingsDocument, options);
      }
export function useCurrentUserSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserSettingsQuery, Types.CurrentUserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentUserSettingsQuery, Types.CurrentUserSettingsQueryVariables>(CurrentUserSettingsDocument, options);
        }
export function useCurrentUserSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CurrentUserSettingsQuery, Types.CurrentUserSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CurrentUserSettingsQuery, Types.CurrentUserSettingsQueryVariables>(CurrentUserSettingsDocument, options);
        }
export type CurrentUserSettingsQueryHookResult = ReturnType<typeof useCurrentUserSettingsQuery>;
export type CurrentUserSettingsLazyQueryHookResult = ReturnType<typeof useCurrentUserSettingsLazyQuery>;
export type CurrentUserSettingsSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSettingsSuspenseQuery>;
export type CurrentUserSettingsQueryResult = Apollo.QueryResult<Types.CurrentUserSettingsQuery, Types.CurrentUserSettingsQueryVariables>;
export const SimpleUserDocument = gql`
    query SimpleUser($id: ID!) {
  user: simpleUser(id: $id) {
    id
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useSimpleUserQuery__
 *
 * To run a query within a React component, call `useSimpleUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSimpleUserQuery(baseOptions: Apollo.QueryHookOptions<Types.SimpleUserQuery, Types.SimpleUserQueryVariables> & ({ variables: Types.SimpleUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SimpleUserQuery, Types.SimpleUserQueryVariables>(SimpleUserDocument, options);
      }
export function useSimpleUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SimpleUserQuery, Types.SimpleUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SimpleUserQuery, Types.SimpleUserQueryVariables>(SimpleUserDocument, options);
        }
export function useSimpleUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.SimpleUserQuery, Types.SimpleUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SimpleUserQuery, Types.SimpleUserQueryVariables>(SimpleUserDocument, options);
        }
export type SimpleUserQueryHookResult = ReturnType<typeof useSimpleUserQuery>;
export type SimpleUserLazyQueryHookResult = ReturnType<typeof useSimpleUserLazyQuery>;
export type SimpleUserSuspenseQueryHookResult = ReturnType<typeof useSimpleUserSuspenseQuery>;
export type SimpleUserQueryResult = Apollo.QueryResult<Types.SimpleUserQuery, Types.SimpleUserQueryVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>;
export const EntityDocument = gql`
    query Entity($id: ID!) {
  entity(id: $id) {
    id
    ...BaseEntityLike
  }
}
    ${BaseEntityLikeFragmentDoc}`;

/**
 * __useEntityQuery__
 *
 * To run a query within a React component, call `useEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntityQuery(baseOptions: Apollo.QueryHookOptions<Types.EntityQuery, Types.EntityQueryVariables> & ({ variables: Types.EntityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EntityQuery, Types.EntityQueryVariables>(EntityDocument, options);
      }
export function useEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EntityQuery, Types.EntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EntityQuery, Types.EntityQueryVariables>(EntityDocument, options);
        }
export function useEntitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.EntityQuery, Types.EntityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EntityQuery, Types.EntityQueryVariables>(EntityDocument, options);
        }
export type EntityQueryHookResult = ReturnType<typeof useEntityQuery>;
export type EntityLazyQueryHookResult = ReturnType<typeof useEntityLazyQuery>;
export type EntitySuspenseQueryHookResult = ReturnType<typeof useEntitySuspenseQuery>;
export type EntityQueryResult = Apollo.QueryResult<Types.EntityQuery, Types.EntityQueryVariables>;
export const EntityAmsDocument = gql`
    query EntityAms($id: ID!, $teamId: ID!) {
  entity(id: $id) {
    ...Ams
  }
}
    ${AmsFragmentDoc}`;

/**
 * __useEntityAmsQuery__
 *
 * To run a query within a React component, call `useEntityAmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityAmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityAmsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useEntityAmsQuery(baseOptions: Apollo.QueryHookOptions<Types.EntityAmsQuery, Types.EntityAmsQueryVariables> & ({ variables: Types.EntityAmsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EntityAmsQuery, Types.EntityAmsQueryVariables>(EntityAmsDocument, options);
      }
export function useEntityAmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EntityAmsQuery, Types.EntityAmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EntityAmsQuery, Types.EntityAmsQueryVariables>(EntityAmsDocument, options);
        }
export function useEntityAmsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.EntityAmsQuery, Types.EntityAmsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EntityAmsQuery, Types.EntityAmsQueryVariables>(EntityAmsDocument, options);
        }
export type EntityAmsQueryHookResult = ReturnType<typeof useEntityAmsQuery>;
export type EntityAmsLazyQueryHookResult = ReturnType<typeof useEntityAmsLazyQuery>;
export type EntityAmsSuspenseQueryHookResult = ReturnType<typeof useEntityAmsSuspenseQuery>;
export type EntityAmsQueryResult = Apollo.QueryResult<Types.EntityAmsQuery, Types.EntityAmsQueryVariables>;
export const EntityFlagsDocument = gql`
    query EntityFlags($id: ID!) {
  entity(id: $id) {
    id
    name
    ...EntityFlags
  }
}
    ${EntityFlagsFragmentDoc}`;

/**
 * __useEntityFlagsQuery__
 *
 * To run a query within a React component, call `useEntityFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityFlagsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntityFlagsQuery(baseOptions: Apollo.QueryHookOptions<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables> & ({ variables: Types.EntityFlagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>(EntityFlagsDocument, options);
      }
export function useEntityFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>(EntityFlagsDocument, options);
        }
export function useEntityFlagsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>(EntityFlagsDocument, options);
        }
export type EntityFlagsQueryHookResult = ReturnType<typeof useEntityFlagsQuery>;
export type EntityFlagsLazyQueryHookResult = ReturnType<typeof useEntityFlagsLazyQuery>;
export type EntityFlagsSuspenseQueryHookResult = ReturnType<typeof useEntityFlagsSuspenseQuery>;
export type EntityFlagsQueryResult = Apollo.QueryResult<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>;
export const UpdateCurrentUserFeaturesDocument = gql`
    mutation UpdateCurrentUserFeatures($user: ID!, $team: ID!, $features: [FeatureInput!]!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  updateUserFeatures: updateUserFeaturesV2(
    user: $user
    team: $team
    features: $features
  ) {
    currentUser {
      id
      features: featuresV2(team: $team) {
        ...Features
      }
    }
  }
}
    ${FeaturesFragmentDoc}`;
export type UpdateCurrentUserFeaturesMutationFn = Apollo.MutationFunction<Types.UpdateCurrentUserFeaturesMutation, Types.UpdateCurrentUserFeaturesMutationVariables>;

/**
 * __useUpdateCurrentUserFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserFeaturesMutation, { data, loading, error }] = useUpdateCurrentUserFeaturesMutation({
 *   variables: {
 *      user: // value for 'user'
 *      team: // value for 'team'
 *      features: // value for 'features'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useUpdateCurrentUserFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCurrentUserFeaturesMutation, Types.UpdateCurrentUserFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCurrentUserFeaturesMutation, Types.UpdateCurrentUserFeaturesMutationVariables>(UpdateCurrentUserFeaturesDocument, options);
      }
export type UpdateCurrentUserFeaturesMutationHookResult = ReturnType<typeof useUpdateCurrentUserFeaturesMutation>;
export type UpdateCurrentUserFeaturesMutationResult = Apollo.MutationResult<Types.UpdateCurrentUserFeaturesMutation>;
export type UpdateCurrentUserFeaturesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCurrentUserFeaturesMutation, Types.UpdateCurrentUserFeaturesMutationVariables>;
export const UpdateTeamFeaturesDocument = gql`
    mutation UpdateTeamFeatures($team: ID!, $features: [FeatureInput!]!, $updateUserFeatures: Boolean = true, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  updateTeamFeatures: updateTeamFeaturesV2(
    team: $team
    features: $features
    updateUserFeatures: $updateUserFeatures
  ) {
    team(id: $team) {
      id
      features: featuresV2 {
        ...Features
      }
    }
  }
}
    ${FeaturesFragmentDoc}`;
export type UpdateTeamFeaturesMutationFn = Apollo.MutationFunction<Types.UpdateTeamFeaturesMutation, Types.UpdateTeamFeaturesMutationVariables>;

/**
 * __useUpdateTeamFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateTeamFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamFeaturesMutation, { data, loading, error }] = useUpdateTeamFeaturesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      features: // value for 'features'
 *      updateUserFeatures: // value for 'updateUserFeatures'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useUpdateTeamFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTeamFeaturesMutation, Types.UpdateTeamFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTeamFeaturesMutation, Types.UpdateTeamFeaturesMutationVariables>(UpdateTeamFeaturesDocument, options);
      }
export type UpdateTeamFeaturesMutationHookResult = ReturnType<typeof useUpdateTeamFeaturesMutation>;
export type UpdateTeamFeaturesMutationResult = Apollo.MutationResult<Types.UpdateTeamFeaturesMutation>;
export type UpdateTeamFeaturesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTeamFeaturesMutation, Types.UpdateTeamFeaturesMutationVariables>;
export const UpdateUserFeaturesDocument = gql`
    mutation UpdateUserFeatures($user: ID!, $team: ID!, $features: [FeatureInput!]!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  updateUserFeatures: updateUserFeaturesV2(
    user: $user
    team: $team
    features: $features
  ) {
    user(id: $user) {
      id
      features: featuresV2(team: $team) {
        ...Features
      }
    }
  }
}
    ${FeaturesFragmentDoc}`;
export type UpdateUserFeaturesMutationFn = Apollo.MutationFunction<Types.UpdateUserFeaturesMutation, Types.UpdateUserFeaturesMutationVariables>;

/**
 * __useUpdateUserFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateUserFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFeaturesMutation, { data, loading, error }] = useUpdateUserFeaturesMutation({
 *   variables: {
 *      user: // value for 'user'
 *      team: // value for 'team'
 *      features: // value for 'features'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useUpdateUserFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserFeaturesMutation, Types.UpdateUserFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserFeaturesMutation, Types.UpdateUserFeaturesMutationVariables>(UpdateUserFeaturesDocument, options);
      }
export type UpdateUserFeaturesMutationHookResult = ReturnType<typeof useUpdateUserFeaturesMutation>;
export type UpdateUserFeaturesMutationResult = Apollo.MutationResult<Types.UpdateUserFeaturesMutation>;
export type UpdateUserFeaturesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserFeaturesMutation, Types.UpdateUserFeaturesMutationVariables>;
export const CurrentUserFeaturesDocument = gql`
    query CurrentUserFeatures($team: ID!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  currentUser {
    id
    features: featuresV2(team: $team) {
      ...Features
    }
  }
}
    ${FeaturesFragmentDoc}`;

/**
 * __useCurrentUserFeaturesQuery__
 *
 * To run a query within a React component, call `useCurrentUserFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserFeaturesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useCurrentUserFeaturesQuery(baseOptions: Apollo.QueryHookOptions<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables> & ({ variables: Types.CurrentUserFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>(CurrentUserFeaturesDocument, options);
      }
export function useCurrentUserFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>(CurrentUserFeaturesDocument, options);
        }
export function useCurrentUserFeaturesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>(CurrentUserFeaturesDocument, options);
        }
export type CurrentUserFeaturesQueryHookResult = ReturnType<typeof useCurrentUserFeaturesQuery>;
export type CurrentUserFeaturesLazyQueryHookResult = ReturnType<typeof useCurrentUserFeaturesLazyQuery>;
export type CurrentUserFeaturesSuspenseQueryHookResult = ReturnType<typeof useCurrentUserFeaturesSuspenseQuery>;
export type CurrentUserFeaturesQueryResult = Apollo.QueryResult<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>;
export const DefaultFeaturesDocument = gql`
    query DefaultFeatures($includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  defaultFeatures(
    includeFeatureCategories: $includeFeatureCategories
    excludeFeatureCategories: $excludeFeatureCategories
  ) {
    ...Feature
  }
}
    ${FeatureFragmentDoc}`;

/**
 * __useDefaultFeaturesQuery__
 *
 * To run a query within a React component, call `useDefaultFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultFeaturesQuery({
 *   variables: {
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useDefaultFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>(DefaultFeaturesDocument, options);
      }
export function useDefaultFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>(DefaultFeaturesDocument, options);
        }
export function useDefaultFeaturesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>(DefaultFeaturesDocument, options);
        }
export type DefaultFeaturesQueryHookResult = ReturnType<typeof useDefaultFeaturesQuery>;
export type DefaultFeaturesLazyQueryHookResult = ReturnType<typeof useDefaultFeaturesLazyQuery>;
export type DefaultFeaturesSuspenseQueryHookResult = ReturnType<typeof useDefaultFeaturesSuspenseQuery>;
export type DefaultFeaturesQueryResult = Apollo.QueryResult<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>;
export const TeamFeaturesDocument = gql`
    query TeamFeatures($team: ID!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  team(id: $team) {
    id
    features: featuresV2 {
      ...Features
    }
  }
}
    ${FeaturesFragmentDoc}`;

/**
 * __useTeamFeaturesQuery__
 *
 * To run a query within a React component, call `useTeamFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFeaturesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useTeamFeaturesQuery(baseOptions: Apollo.QueryHookOptions<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables> & ({ variables: Types.TeamFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>(TeamFeaturesDocument, options);
      }
export function useTeamFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>(TeamFeaturesDocument, options);
        }
export function useTeamFeaturesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>(TeamFeaturesDocument, options);
        }
export type TeamFeaturesQueryHookResult = ReturnType<typeof useTeamFeaturesQuery>;
export type TeamFeaturesLazyQueryHookResult = ReturnType<typeof useTeamFeaturesLazyQuery>;
export type TeamFeaturesSuspenseQueryHookResult = ReturnType<typeof useTeamFeaturesSuspenseQuery>;
export type TeamFeaturesQueryResult = Apollo.QueryResult<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>;
export const UserFeaturesDocument = gql`
    query UserFeatures($team: ID!, $user: ID!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  user(id: $user) {
    id
    features: featuresV2(team: $team) {
      ...Features
    }
  }
}
    ${FeaturesFragmentDoc}`;

/**
 * __useUserFeaturesQuery__
 *
 * To run a query within a React component, call `useUserFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeaturesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      user: // value for 'user'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useUserFeaturesQuery(baseOptions: Apollo.QueryHookOptions<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables> & ({ variables: Types.UserFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>(UserFeaturesDocument, options);
      }
export function useUserFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>(UserFeaturesDocument, options);
        }
export function useUserFeaturesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>(UserFeaturesDocument, options);
        }
export type UserFeaturesQueryHookResult = ReturnType<typeof useUserFeaturesQuery>;
export type UserFeaturesLazyQueryHookResult = ReturnType<typeof useUserFeaturesLazyQuery>;
export type UserFeaturesSuspenseQueryHookResult = ReturnType<typeof useUserFeaturesSuspenseQuery>;
export type UserFeaturesQueryResult = Apollo.QueryResult<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>;
export const FinancialFieldsDocument = gql`
    query FinancialFields($countries: [ContentLanguage!]) {
  financialFields(countries: $countries) {
    id
    ...FinancialFieldInfo
    matchingInternationalFields {
      id
      ...FinancialFieldInfo
    }
  }
}
    ${FinancialFieldInfoFragmentDoc}`;

/**
 * __useFinancialFieldsQuery__
 *
 * To run a query within a React component, call `useFinancialFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialFieldsQuery({
 *   variables: {
 *      countries: // value for 'countries'
 *   },
 * });
 */
export function useFinancialFieldsQuery(baseOptions?: Apollo.QueryHookOptions<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>(FinancialFieldsDocument, options);
      }
export function useFinancialFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>(FinancialFieldsDocument, options);
        }
export function useFinancialFieldsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>(FinancialFieldsDocument, options);
        }
export type FinancialFieldsQueryHookResult = ReturnType<typeof useFinancialFieldsQuery>;
export type FinancialFieldsLazyQueryHookResult = ReturnType<typeof useFinancialFieldsLazyQuery>;
export type FinancialFieldsSuspenseQueryHookResult = ReturnType<typeof useFinancialFieldsSuspenseQuery>;
export type FinancialFieldsQueryResult = Apollo.QueryResult<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>;
export const DefaultFinancialFieldsDocument = gql`
    query DefaultFinancialFields($contentLanguage: ContentLanguage!) {
  defaultFinancialFieldsByContentLanguage(contentLanguage: $contentLanguage) {
    id
    ...FinancialFieldInfo
  }
}
    ${FinancialFieldInfoFragmentDoc}`;

/**
 * __useDefaultFinancialFieldsQuery__
 *
 * To run a query within a React component, call `useDefaultFinancialFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultFinancialFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultFinancialFieldsQuery({
 *   variables: {
 *      contentLanguage: // value for 'contentLanguage'
 *   },
 * });
 */
export function useDefaultFinancialFieldsQuery(baseOptions: Apollo.QueryHookOptions<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables> & ({ variables: Types.DefaultFinancialFieldsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>(DefaultFinancialFieldsDocument, options);
      }
export function useDefaultFinancialFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>(DefaultFinancialFieldsDocument, options);
        }
export function useDefaultFinancialFieldsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>(DefaultFinancialFieldsDocument, options);
        }
export type DefaultFinancialFieldsQueryHookResult = ReturnType<typeof useDefaultFinancialFieldsQuery>;
export type DefaultFinancialFieldsLazyQueryHookResult = ReturnType<typeof useDefaultFinancialFieldsLazyQuery>;
export type DefaultFinancialFieldsSuspenseQueryHookResult = ReturnType<typeof useDefaultFinancialFieldsSuspenseQuery>;
export type DefaultFinancialFieldsQueryResult = Apollo.QueryResult<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>;
export const LatestRateLimitDocument = gql`
    query LatestRateLimit($customer: ID!, $kind: RateLimitKind!) {
  latestRateLimit(customer: $customer, kind: $kind) {
    id
    ...BaseRateLimit
  }
}
    ${BaseRateLimitFragmentDoc}`;

/**
 * __useLatestRateLimitQuery__
 *
 * To run a query within a React component, call `useLatestRateLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestRateLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestRateLimitQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useLatestRateLimitQuery(baseOptions: Apollo.QueryHookOptions<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables> & ({ variables: Types.LatestRateLimitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>(LatestRateLimitDocument, options);
      }
export function useLatestRateLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>(LatestRateLimitDocument, options);
        }
export function useLatestRateLimitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>(LatestRateLimitDocument, options);
        }
export type LatestRateLimitQueryHookResult = ReturnType<typeof useLatestRateLimitQuery>;
export type LatestRateLimitLazyQueryHookResult = ReturnType<typeof useLatestRateLimitLazyQuery>;
export type LatestRateLimitSuspenseQueryHookResult = ReturnType<typeof useLatestRateLimitSuspenseQuery>;
export type LatestRateLimitQueryResult = Apollo.QueryResult<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($user: ID!) {
  resetPassword(user: $user) {
    id
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<Types.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;
export const RolesDocument = gql`
    query Roles {
  roles {
    id
    name
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<Types.RolesQuery, Types.RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RolesQuery, Types.RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RolesQuery, Types.RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RolesQuery, Types.RolesQueryVariables>(RolesDocument, options);
        }
export function useRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.RolesQuery, Types.RolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RolesQuery, Types.RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesSuspenseQueryHookResult = ReturnType<typeof useRolesSuspenseQuery>;
export type RolesQueryResult = Apollo.QueryResult<Types.RolesQuery, Types.RolesQueryVariables>;
export const EntitySanctionsDocument = gql`
    query EntitySanctions($id: ID!, $teamId: ID!) {
  entity(id: $id) {
    id
    name
    ...EntitySanctionInfo
  }
}
    ${EntitySanctionInfoFragmentDoc}`;

/**
 * __useEntitySanctionsQuery__
 *
 * To run a query within a React component, call `useEntitySanctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntitySanctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntitySanctionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useEntitySanctionsQuery(baseOptions: Apollo.QueryHookOptions<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables> & ({ variables: Types.EntitySanctionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>(EntitySanctionsDocument, options);
      }
export function useEntitySanctionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>(EntitySanctionsDocument, options);
        }
export function useEntitySanctionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>(EntitySanctionsDocument, options);
        }
export type EntitySanctionsQueryHookResult = ReturnType<typeof useEntitySanctionsQuery>;
export type EntitySanctionsLazyQueryHookResult = ReturnType<typeof useEntitySanctionsLazyQuery>;
export type EntitySanctionsSuspenseQueryHookResult = ReturnType<typeof useEntitySanctionsSuspenseQuery>;
export type EntitySanctionsQueryResult = Apollo.QueryResult<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>;
export const UpdateTeamSettingsDocument = gql`
    mutation UpdateTeamSettings($team: ID!, $input: TeamSettingsInput!) {
  updateTeamSettingsV2(team: $team, input: $input) {
    team(id: $team) {
      id
      ...TeamSettings
    }
  }
}
    ${TeamSettingsFragmentDoc}`;
export type UpdateTeamSettingsMutationFn = Apollo.MutationFunction<Types.UpdateTeamSettingsMutation, Types.UpdateTeamSettingsMutationVariables>;

/**
 * __useUpdateTeamSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateTeamSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamSettingsMutation, { data, loading, error }] = useUpdateTeamSettingsMutation({
 *   variables: {
 *      team: // value for 'team'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTeamSettingsMutation, Types.UpdateTeamSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTeamSettingsMutation, Types.UpdateTeamSettingsMutationVariables>(UpdateTeamSettingsDocument, options);
      }
export type UpdateTeamSettingsMutationHookResult = ReturnType<typeof useUpdateTeamSettingsMutation>;
export type UpdateTeamSettingsMutationResult = Apollo.MutationResult<Types.UpdateTeamSettingsMutation>;
export type UpdateTeamSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTeamSettingsMutation, Types.UpdateTeamSettingsMutationVariables>;
export const UpdateCompanyStatusesDocument = gql`
    mutation UpdateCompanyStatuses($portfolio: ID!, $team: ID!, $companies: [ID!]!, $status: CompanyStatus) {
  updateCompanyStatuses(
    portfolio: $portfolio
    team: $team
    companies: $companies
    status: $status
  ) {
    team(id: $team) {
      id
      portfolio(id: $portfolio) {
        id
        companies(filter: {companies: $companies}) {
          edges {
            node {
              id
              statusV2(team: $team, portfolio: $portfolio) {
                ...TeamCompanyStatus
              }
            }
          }
        }
      }
    }
  }
}
    ${TeamCompanyStatusFragmentDoc}`;
export type UpdateCompanyStatusesMutationFn = Apollo.MutationFunction<Types.UpdateCompanyStatusesMutation, Types.UpdateCompanyStatusesMutationVariables>;

/**
 * __useUpdateCompanyStatusesMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyStatusesMutation, { data, loading, error }] = useUpdateCompanyStatusesMutation({
 *   variables: {
 *      portfolio: // value for 'portfolio'
 *      team: // value for 'team'
 *      companies: // value for 'companies'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateCompanyStatusesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyStatusesMutation, Types.UpdateCompanyStatusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyStatusesMutation, Types.UpdateCompanyStatusesMutationVariables>(UpdateCompanyStatusesDocument, options);
      }
export type UpdateCompanyStatusesMutationHookResult = ReturnType<typeof useUpdateCompanyStatusesMutation>;
export type UpdateCompanyStatusesMutationResult = Apollo.MutationResult<Types.UpdateCompanyStatusesMutation>;
export type UpdateCompanyStatusesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyStatusesMutation, Types.UpdateCompanyStatusesMutationVariables>;
export const AddRowsToPortfolioDocument = gql`
    mutation AddRowsToPortfolio($rows: [PortfolioCompanyRowInput!]!, $team: ID!, $portfolio: ID!, $mergeStrategyKind: PortfolioMergeStrategyKind!) {
  addRowsToPortfolio(
    rows: $rows
    team: $team
    portfolio: $portfolio
    mergeStrategyKind: $mergeStrategyKind
  ) {
    strise
  }
}
    `;
export type AddRowsToPortfolioMutationFn = Apollo.MutationFunction<Types.AddRowsToPortfolioMutation, Types.AddRowsToPortfolioMutationVariables>;

/**
 * __useAddRowsToPortfolioMutation__
 *
 * To run a mutation, you first call `useAddRowsToPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRowsToPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRowsToPortfolioMutation, { data, loading, error }] = useAddRowsToPortfolioMutation({
 *   variables: {
 *      rows: // value for 'rows'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      mergeStrategyKind: // value for 'mergeStrategyKind'
 *   },
 * });
 */
export function useAddRowsToPortfolioMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddRowsToPortfolioMutation, Types.AddRowsToPortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddRowsToPortfolioMutation, Types.AddRowsToPortfolioMutationVariables>(AddRowsToPortfolioDocument, options);
      }
export type AddRowsToPortfolioMutationHookResult = ReturnType<typeof useAddRowsToPortfolioMutation>;
export type AddRowsToPortfolioMutationResult = Apollo.MutationResult<Types.AddRowsToPortfolioMutation>;
export type AddRowsToPortfolioMutationOptions = Apollo.BaseMutationOptions<Types.AddRowsToPortfolioMutation, Types.AddRowsToPortfolioMutationVariables>;
export const ValidatePortfolioCsvDocument = gql`
    query ValidatePortfolioCsv($csv: String!, $team: ID!, $portfolio: ID!) {
  validatePortfolioCsv(csv: $csv, team: $team) {
    rows {
      company {
        id
        ...CompanyMeta
      }
      assignees {
        id
        ...SimpleUser
      }
      tags {
        name
        existing {
          id
        }
      }
      status
      errors {
        message
        line
      }
    }
    errors {
      message
      line
    }
    lines
    duplicates
  }
}
    ${CompanyMetaFragmentDoc}
${SimpleUserFragmentDoc}`;

/**
 * __useValidatePortfolioCsvQuery__
 *
 * To run a query within a React component, call `useValidatePortfolioCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePortfolioCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePortfolioCsvQuery({
 *   variables: {
 *      csv: // value for 'csv'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *   },
 * });
 */
export function useValidatePortfolioCsvQuery(baseOptions: Apollo.QueryHookOptions<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables> & ({ variables: Types.ValidatePortfolioCsvQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>(ValidatePortfolioCsvDocument, options);
      }
export function useValidatePortfolioCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>(ValidatePortfolioCsvDocument, options);
        }
export function useValidatePortfolioCsvSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>(ValidatePortfolioCsvDocument, options);
        }
export type ValidatePortfolioCsvQueryHookResult = ReturnType<typeof useValidatePortfolioCsvQuery>;
export type ValidatePortfolioCsvLazyQueryHookResult = ReturnType<typeof useValidatePortfolioCsvLazyQuery>;
export type ValidatePortfolioCsvSuspenseQueryHookResult = ReturnType<typeof useValidatePortfolioCsvSuspenseQuery>;
export type ValidatePortfolioCsvQueryResult = Apollo.QueryResult<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>;