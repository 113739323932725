import { type AuthenticationError } from '@auth0/auth0-spa-js'
import { objectKeys } from '@strise/ts-utils'
import { ExternalLink, Typography } from '@strise/ui-components'
import type { ReactElement } from 'react'

export enum AuthError {
  Generic = 'generic',
  IssuedAt = 'issued_at',
  SessionExpired = 'session_expired',
  ApiAuthenticationFailed = 'api_authentication_failed',
  AuthenticationFailed = 'authentication_failed',
  MissingRole = 'missing_role',
  NoTeam = 'no_team',
  NoPortfolio = 'no_portfolio',
  NoUser = 'no_user'
}

const errorToCode = {
  'Issued At': AuthError.IssuedAt
}

export const auth0ErrorToInternalCode = (err: AuthenticationError): AuthError => {
  const errorDesc = err.error_description || ''
  const errorKey = objectKeys(errorToCode).find((error) => errorDesc.startsWith(error))

  if (!errorKey) return AuthError.Generic
  return errorToCode[errorKey]
}

export const codeToError: Partial<Record<AuthError, ReactElement>> = {
  [AuthError.Generic]: <Typography className='text-semantic-danger-main'>Login failed, please try again!</Typography>,
  [AuthError.IssuedAt]: (
    <Typography className='text-semantic-danger-main'>
      Something seems to be wrong with the local time on your computer. <br />
      Please adjust the clock on your computer and try logging in again. <br />
      See{' '}
      <ExternalLink href='https://support.microsoft.com/en-us/help/4026213/windows-how-to-set-your-time-and-time-zone'>
        here
      </ExternalLink>{' '}
      for how to adjust the time on Windows 10.
    </Typography>
  ),

  [AuthError.AuthenticationFailed]: (
    <div className='max-w-[480px]'>
      <Typography className='mb-2 text-semantic-danger-main' component='div'>
        Authentication Failed
      </Typography>
      <Typography className='mb-2'>This was unexpected, we are looking into why this happened.</Typography>
      <Typography>
        Meanwhile, if you could kindly try logging in again and let us know via{' '}
        <ExternalLink Icon={null} href='mailto:support@strise.ai'>
          support@strise.ai
        </ExternalLink>{' '}
        if the problem persists.
      </Typography>
    </div>
  ),

  [AuthError.SessionExpired]: <Typography>Session expired, please login to continue.</Typography>,
  [AuthError.NoTeam]: (
    <Typography>
      You're not registered in any team.
      <br />
      Please contact{' '}
      <ExternalLink Icon={null} href='mailto:support@strise.ai'>
        support
      </ExternalLink>
      .
    </Typography>
  ),

  [AuthError.NoPortfolio]: (
    <Typography>
      Your team does not have any portfolios.
      <br />
      Please contact{' '}
      <ExternalLink Icon={null} href='mailto:support@strise.ai'>
        support
      </ExternalLink>
      .
    </Typography>
  )
}
