import { type AnimatedIconProps, SvgMorphingWrapper } from '../SvgMorphingWrapper'
import { IconExpandAnimatedA } from './IconExpandAnimatedA'
import { IconExpandAnimatedB } from './IconExpandAnimatedB'
import { type ReactNode, forwardRef } from 'react'

export const IconExpandAnimated = forwardRef<SVGSVGElement, AnimatedIconProps>(
  (props, ref): ReactNode => (
    <SvgMorphingWrapper {...props} SvgComponentA={IconExpandAnimatedA} SvgComponentB={IconExpandAnimatedB} ref={ref} />
  )
)
IconExpandAnimated.displayName = 'IconExpandAnimated'
