import { type DivProps, useContext } from '@strise/react-utils'
import { cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { EntityKeyMetaItems } from '~/features/Ownerships/EntityKeyMetaItems'
import { type EntityLikeMetaFragment } from '~/graphqlTypes'
import { useIntersectionRef, useIsMobile } from '~/utils/hooks'

export const SidepanelEntityKeyMetaDetails = ({
  className,
  entity,
  ...props
}: {
  entity: EntityLikeMetaFragment
} & DivProps): ReactNode => {
  const { expanded, setShowHeaderEntity } = useContext(SidepanelContext)

  const intersectionRef = useIntersectionRef({ threshold: 1 }, (entries) => {
    entries.forEach((entry) => {
      setShowHeaderEntity(entry.intersectionRatio !== 1)
    })
  })

  const mobile = useIsMobile()

  return (
    <EntityKeyMetaItems
      className={cn(
        'grid',
        mobile ? 'grid-cols-1 items-start' : 'grid-cols-[repeat(auto-fill,_minmax(136px,_1fr))] items-center gap-1',
        className
      )}
      entity={entity}
      ref={intersectionRef}
      expanded={expanded}
      itemProps={{
        className: mobile ? 'mt-2' : undefined
      }}
      {...props}
    />
  )
}
