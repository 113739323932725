import { ReactRouterLink } from '@strise/app-shared'
import { type ButtonProps, IconButton, IconOverflowHorizontal, Tooltip, cn } from '@strise/ui-components'
import { Dropdown, type DropdownProps, MenuItem, type MenuItemProps } from '@strise/ui-components-legacy'
import type { ReactElement, ReactNode } from 'react'
import { forwardRef } from 'react'
import { MENU_Z_INDEX } from '~/utils/zIndexes'

interface ToggleButtonProps extends ButtonProps {
  dataTrack: string
  disabledText?: string
  icon?: ReactElement
}

const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  ({ className, dataTrack, disabled, disabledText, icon, ...props }, ref): ReactNode => {
    const buttonElement = (
      <IconButton
        className={className}
        ref={ref}
        variant='contained'
        disabled={disabled}
        data-track={dataTrack}
        {...props}
      >
        {icon ?? <IconOverflowHorizontal />}
      </IconButton>
    )

    if (disabledText && disabled) {
      return (
        <Tooltip content={disabledText}>
          <div>{buttonElement}</div>
        </Tooltip>
      )
    }

    return buttonElement
  }
)

export const DropdownMenu = ({
  buttonProps,
  className,
  dataTrack,
  disabledText,
  icon,
  menuItems,
  ...props
}: {
  buttonProps?: Omit<ButtonProps, 'children' | 'onClick'>
  dataTrack: string
  disabledText?: string
  icon?: ReactElement
  menuItems: Array<Omit<MenuItemProps & { hide?: boolean; to?: string }, 'children'>>
} & Omit<DropdownProps, 'children'>) => {
  return (
    <Dropdown
      className={className}
      menu
      ToggleComponent={<ToggleButton icon={icon} disabledText={disabledText} dataTrack={dataTrack} {...buttonProps} />}
      popperProps={{ zIndex: MENU_Z_INDEX, portal: true }}
      role='menu'
      {...props}
    >
      <div>
        {menuItems.map(({ hide, title, to, ...menuItemProps }, index) => {
          if (hide) return null

          const menuItem = (
            <MenuItem key={index} {...menuItemProps} className={cn('pr-4', menuItemProps.className)}>
              {title}
            </MenuItem>
          )

          if (to) {
            return (
              <ReactRouterLink key={index} className='no-underline' to={to}>
                {menuItem}
              </ReactRouterLink>
            )
          }

          return menuItem
        })}
      </div>
    </Dropdown>
  )
}
