import { Button, IconCrossSmall, cn } from '../..'
import type { DropResult as BaseDropResult } from '@hello-pangea/dnd'
import { Draggable, Droppable } from '@hello-pangea/dnd'
import { type ReactNode, forwardRef, useEffect, useState } from 'react'

export interface DraggableListProps<T extends { content: string; id: string }> {
  className?: string
  droppableId: string
  items: T[]
  renderEmptyList?: () => ReactNode
  renderItem: (item: T, index: number) => ReactNode
}

const DraggableList = forwardRef<HTMLDivElement, DraggableListProps<{ content: string; id: string }>>(
  ({ className, droppableId, items, renderEmptyList, renderItem }, _ref): JSX.Element => {
    const [renderedItems, setRenderedItems] = useState<ReactNode[]>([])

    useEffect(() => {
      const renderItems = async (): Promise<void> => {
        if (items.length > 0) {
          const rendered = await Promise.all(items.map(async (item, index) => await renderItem(item, index)))
          setRenderedItems(rendered)
        }
      }
      renderItems()
    }, [items, renderItem])

    return (
      <Droppable droppableId={droppableId} direction='vertical'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className={className}>
            {items.length > 0 ? renderedItems : renderEmptyList?.()}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }
)
DraggableList.displayName = 'DraggableList'

export interface DraggableItemProps {
  children?: ReactNode
  className?: string
  draggableId: string
  index: number
  isDragDisabled?: boolean
  onDelete?: (id: string) => void
}

const DraggableItem = forwardRef<HTMLDivElement, DraggableItemProps>(
  ({ children, className, draggableId, index, isDragDisabled, onDelete }, _ref): JSX.Element => {
    return (
      <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={cn('flex items-center justify-between', className, snapshot.isDragging && 'shadow-lg')}
          >
            <div className='pl-2 flex-1 truncate'>{children}</div>
            {onDelete && (
              <Button
                variant='ghost'
                palette='danger'
                size='sm'
                className='rounded-full p-0 m-1 aspect-square'
                onClick={() => onDelete(draggableId)}
              >
                <IconCrossSmall size='sm' />
              </Button>
            )}
          </div>
        )}
      </Draggable>
    )
  }
)

DraggableItem.displayName = 'DraggableItem'

export { DraggableList, DraggableItem }
export { DragDropContext } from '@hello-pangea/dnd'
export type { DraggableLocation, DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd'

export type DropResult = BaseDropResult
