import BaseTag from '~/features/Ownerships/ControlCard/BaseTag'
import { type BaseEntityLikeFragment } from '@strise/app-shared/src/graphqlTypes'
import { Button, IconChevronDownSmall, IconChevronRightSmall, Typography } from '@strise/ui-components'
import { type ReactNode, useState } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'

const FamilyMembersTag = ({
  label,
  relations,
  value
}: {
  label: string
  relations: {
    person: {
      __typename: 'Person'
    } & BaseEntityLikeFragment
    translatedRelationships: Array<string>
  }[]
  value: string
}): ReactNode => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <BaseTag
      label={label}
      value={value}
      button={
        <Button
          variant='ghost'
          palette='secondary'
          className='p-0 h-auto '
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(!isOpen)
          }}
          data-track='Family Members Tag / Toggle'
          startIcon={isOpen ? <IconChevronDownSmall /> : <IconChevronRightSmall />}
        />
      }
    >
      {isOpen && (
        <div className='flex flex-col bg-secondary-shade-5 p-2'>
          {relations.map((relation) => (
            <div key={relation.person.id} className='text-text-secondary'>
              {'↳ '}
              <EntityLink entity={relation.person} noFlags className='underline' />
              <Typography variant='aLabel'>{` (${relation.translatedRelationships.join(', ')})`}</Typography>
            </div>
          ))}
        </div>
      )}
    </BaseTag>
  )
}

export default FamilyMembersTag
