import { EntityDispositionKind, EntityDispositionStatusKind, ReviewSectionKind } from '@strise/types'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useDeleteEntityDispositionsMutation } from '~/graphqlOperations'
import type { PepInfoFragment, PersonBaseFragment } from '~/graphqlTypes'
import { refreshReviewSections } from '~/state'

export const systemSuggestedTruePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  !!pepInfo.matchInfoAnalysis?.suggestedTruePositive && !pepInfo.disposition

export const systemSuggestedFalsePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  !pepInfo.matchInfoAnalysis?.suggestedTruePositive && !pepInfo.disposition

export const userConfirmedFalsePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  pepInfo.disposition?.status === EntityDispositionStatusKind.ConfirmedFalse

export const userConfirmedTruePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  pepInfo.disposition?.status === EntityDispositionStatusKind.ConfirmedTrue

export const refreshPepAndSanctionsReviewCache = () => {
  const currentNonce = refreshReviewSections()[ReviewSectionKind.PepsAndSanctions] ?? 0
  refreshReviewSections({ [ReviewSectionKind.PepsAndSanctions]: currentNonce + 1 })
}

export const useDeletePepDispositions = ({
  person
}: {
  person: PersonBaseFragment
}): {
  deleteLoading: boolean
  handleDelete: () => Promise<void>
} => {
  const features = useCurrentUserFeatures()

  const [remove, { loading: deleteLoading }] = useDeleteEntityDispositionsMutation({
    onCompleted: refreshPepAndSanctionsReviewCache
  })

  const handleDelete = async (): Promise<void> => {
    await remove({
      variables: {
        entity: person.id,
        includePepV1: !!features.PEP_AND_SANCTIONS_V2 && !features.PEP_DISPOSITIONING,
        includePepV2: !!features.PEP_AND_SANCTIONS_V2 && !!features.PEP_DISPOSITIONING,
        kind: EntityDispositionKind.Pep
      }
    })
  }

  return { handleDelete, deleteLoading }
}
