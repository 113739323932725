import { Trans, t } from '@lingui/macro'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import { EntityDispositionStatusKind } from '@strise/types'
import { Button, IconPenBox, Typography, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { AssociatedCompanyLink } from '~/features/PepAndSanctions/AssociatedCompanyLink'
import { CreateCustomPepDialog } from '~/features/PepAndSanctions/CreateCustomPepDialog'
import { PepDispositionBadge } from '~/features/PepAndSanctions/PepDispositionBadge'
import { PepDispositionDialog } from '~/features/PepAndSanctions/PepDispositionDialog'
import { PepRelation } from '~/features/PepAndSanctions/PepRelation'
import { PepRole } from '~/features/PepAndSanctions/PepRole'
import { PepRolesAndRelations } from '~/features/PepAndSanctions/PepRolesAndRelations'
import {
  systemSuggestedFalsePepPredicate,
  userConfirmedFalsePepPredicate
} from '~/features/PepAndSanctions/pepDispositionUtils'
import {
  type CompanyNetworkEntityPersonFragment,
  type PepInfoFragment,
  type PersonBaseFragment,
  type PrivatePersonBaseFragment
} from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'
import { SidepanelTab } from '~/utils/urls'

const PepDispositionActions = ({
  pepInfosToDisposition,
  person,
  setIsDispositionDialogOpen
}: {
  pepInfosToDisposition: PepInfoFragment[]
  person: PersonBaseFragment | null
  setIsDispositionDialogOpen: SetStateFn<boolean>
}): ReactNode => {
  if (!person) return null

  if (!pepInfosToDisposition.length)
    return (
      <div className='flex gap-2'>
        <CreateCustomPepDialog person={person} />
      </div>
    )

  return (
    <Button
      variant='ghost'
      palette='primary'
      data-track='Open PEP disposition dialog'
      onClick={() => setIsDispositionDialogOpen(true)}
      size='sm'
      className='w-fit self-end'
      startIcon={<IconPenBox size='sm' className='mr-1' />}
    >
      <Trans>Disposition</Trans>
    </Button>
  )
}

const PepInfoContentOld = ({
  associatedCompanies,
  className,
  currentCompanyId,
  pepInfos,
  person,
  ...props
}: {
  associatedCompanies: CompanyNetworkEntityPersonFragment['associatedCompanies'] | null | undefined
  currentCompanyId: string | null | undefined
  pepInfos: PepInfoFragment[]
  person: PersonBaseFragment | PrivatePersonBaseFragment | null
} & DivProps): ReactNode => {
  // We return an empty pep if no results to "prove" that we've checked the person
  // Therefore, we need to filter it out here
  const filteredPepInfos = pepInfos.filter((info) => {
    return info.rca || info.pep
  })

  const pepInfosWithPep = filteredPepInfos.filter((info) => info.pep)
  const pepInfosWithRca = filteredPepInfos.filter((info) => info.rca)

  return (
    <div className={cn('w-full', className)} {...props} data-id={TestIDs.Pep.personContent}>
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          {person && <EntityLink entity={person} noFlags />}
          {!filteredPepInfos.length && (
            <Typography className='ml-1'>
              <Trans>is not a PEP</Trans>
            </Typography>
          )}
        </div>

        {associatedCompanies && currentCompanyId && (
          <div className='flex flex-col pb-1'>
            {associatedCompanies.map((c) => (
              <AssociatedCompanyLink key={c.id} currentCompanyId={currentCompanyId} associatedCompany={c} />
            ))}
          </div>
        )}
      </div>

      {!!filteredPepInfos.length && (
        <div className='flex flex-col gap-4'>
          {!!pepInfosWithPep.length && (
            <div>
              <div className='flex flex-col gap-2'>
                {pepInfosWithPep.map((pepInfo) => {
                  return (
                    <div key={pepInfo.id}>
                      {!pepInfo.roles.length && <Typography key={pepInfo.id}>{t`Unknown roles`}</Typography>}

                      {!!pepInfo.roles.length &&
                        pepInfo.roles.map((role, index) => <PepRole key={index} role={role} />)}
                    </div>
                  )
                })}
              </div>
            </div>
          )}

          {!!pepInfosWithRca.length && (
            <div>
              <div className='flex flex-col gap-4'>
                {pepInfosWithRca.map((pepInfo) => {
                  return (
                    <div key={pepInfo.id}>
                      {!pepInfo.relations.length && (
                        <Typography key={pepInfo.id}>{t`Unknown relationships`}</Typography>
                      )}

                      {pepInfo.relations.map((relation, index) => (
                        <PepRelation key={index} relation={relation} />
                      ))}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export const PepPersonContent = ({
  associatedCompanies,
  className,
  currentCompanyId,
  pepInfos,
  person,
  showSystemSuggestedFalse,
  ...props
}: {
  associatedCompanies: CompanyNetworkEntityPersonFragment['associatedCompanies'] | null | undefined
  currentCompanyId: string | null | undefined
  pepInfos: PepInfoFragment[]
  person: PersonBaseFragment | PrivatePersonBaseFragment | null
  showSystemSuggestedFalse: boolean
} & DivProps): ReactNode => {
  const [isDispositionDialogOpen, setIsDispositionDialogOpen] = useState(false)
  const features = useCurrentUserFeatures()

  // TODO: Support pep dispositioning for private persons
  if (!features.PEP_DISPOSITIONING || person?.__typename === 'PrivatePerson') {
    return (
      <PepInfoContentOld
        className={className}
        pepInfos={pepInfos}
        person={person}
        currentCompanyId={currentCompanyId}
        associatedCompanies={associatedCompanies}
        {...props}
      />
    )
  }

  // Filter and process PEP information
  const filteredPepInfos = pepInfos.filter((info) => info.pep || info.rca || info.customMeta)

  const customPepInfos = filteredPepInfos.filter((info) => info.customMeta)

  const pepInfosToDisposition = filteredPepInfos.filter((info) => !info.customMeta)

  const pepInfosToShow = filteredPepInfos.filter((info) => {
    if (!info.pep && !info.rca) return false
    if (info.disposition?.status === EntityDispositionStatusKind.ConfirmedTrue) return true
    if (info.disposition?.status === EntityDispositionStatusKind.ConfirmedFalse) return false
    if (showSystemSuggestedFalse) return true
    if (!info.matchInfoAnalysis?.suggestedTruePositive) return false

    return true
  })

  const firstSuggestedFalseInfo = pepInfosToDisposition.find(systemSuggestedFalsePepPredicate)
  const firstConfirmedFalseInfo = pepInfosToDisposition.find(userConfirmedFalsePepPredicate)

  const emptyPepInfo = customPepInfos[0] ?? firstSuggestedFalseInfo ?? firstConfirmedFalseInfo ?? null

  return (
    <>
      <div className={cn('w-full', className)} {...props} data-id={TestIDs.Pep.personContent}>
        <div className='flex justify-between items-start mb-4 flex-wrap'>
          <div className='flex items-center'>
            {person && (
              <EntityLink entity={person} noFlags className='self-end' sidepanelTab={SidepanelTab.PepsAndSanctions} />
            )}
            {!pepInfosToShow.length && (
              <div>
                <Typography className='ml-1'>
                  <Trans>is not a PEP</Trans>
                </Typography>
              </div>
            )}
          </div>
          <div className='flex flex-col space-y-2 ml-auto'>
            <PepDispositionActions
              pepInfosToDisposition={pepInfosToDisposition}
              person={person}
              setIsDispositionDialogOpen={setIsDispositionDialogOpen}
            />
            {associatedCompanies && currentCompanyId && (
              <div className='flex flex-col self-end max-w-48 break-all'>
                {associatedCompanies.map((c) => (
                  <AssociatedCompanyLink key={c.id} currentCompanyId={currentCompanyId} associatedCompany={c} />
                ))}
              </div>
            )}
          </div>
        </div>
        {!pepInfosToShow.length && <PepDispositionBadge pepInfo={emptyPepInfo} person={person} />}

        <PepRolesAndRelations pepInfosToShow={pepInfosToShow} person={person} />
      </div>

      {isDispositionDialogOpen && person && (
        <PepDispositionDialog setOpen={setIsDispositionDialogOpen} pepInfos={pepInfosToDisposition} person={person} />
      )}
    </>
  )
}
