import {
  Checkbox,
  Divider,
  IconButton,
  IconChevronLeftSuperSmall,
  IconChevronRightSuperSmall,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  type TableProps,
  TableRow,
  Typography
} from '../..'
import { cn } from '../../utils/className'
import { filterNullishValues } from '@strise/ts-utils'
import {
  type ColumnDef,
  type TableOptions,
  flexRender,
  getCoreRowModel,
  isFunction,
  useReactTable
} from '@tanstack/react-table'
import type { ReactNode } from 'react'
import { useMemo, useState } from 'react'

interface DataTablePaginationProps {
  /** Callback when page changes */
  onPageChange?: (pageIndex: number) => void
  /** Callback when page size changes */
  onPageSizeChange?: (pageSize: number) => void
  /** Total number of pages */
  pageCount?: number
  /** Current page (zero-based) */
  pageIndex: number
  /** Page size */
  pageSize: number
  /** Page size options */
  pageSizeOptions: number[]
  /** Text to display before the page size selector */
  rowsPerPageText: string
}

interface DataTableProps<TData> extends TableProps {
  /** ClassName for the body cell */
  bodyCellClassName?: string
  /** Define columns using createColumnHelper from @tanstack/react-table: https://tanstack.com/table/v8/docs/guide/column-defs  */
  // https://github.com/TanStack/table/issues/4382
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<ColumnDef<TData, any>>
  /** The data rows for your table */
  data: TData[]
  /** Text to display when the table has no data */
  emptyStateText?: string
  /** Enable column ordering */
  enableColumnOrdering?: boolean
  /** Enables pagination */
  enablePagination?: boolean
  /** Get className depending on the data in the row */
  getRowClassName?: (row: TData) => string
  /** ClassName for the header cell */
  headerCellClassName?: string
  /** ClassName for the header row */
  headerRowClassName?: string
  /** Loading state, if true, skeleton rows will be displayed */
  loading?: boolean
  /** Number of skeleton rows to display */
  loadingRows?: number
  /** Callback when page changes */
  onPageChange?: (pageIndex: number) => void
  /** Callback when page size changes */
  onPageSizeChange?: (pageSize: number) => void
  /** The tanstack table options */
  options?: Omit<TableOptions<TData>, 'data' | 'columns' | 'getCoreRowModel'>
  /** The orientation of the table */
  orientation?: 'horizontal' | 'vertical'
  /** Current page (zero-based) */
  pageIndex?: number
  /** Page size */
  pageSize?: number
  /** Page size options */
  pageSizeOptions?: number[]
  /** Text to display before the page size selector */
  rowsPerPageText?: string
  /** Total number of records */
  totalCount?: number
  /** The variant of the table */
  variant?: 'default' | 'backplate'
  /** ClassName for the table wrapper */
  wrapperClassName?: string
}

const addSelectionColumn = <TData,>({
  columns,
  options,
  variant
}: {
  columns: Array<ColumnDef<TData>>
  options?: Omit<TableOptions<TData>, 'data' | 'columns' | 'getCoreRowModel'>
  variant?: DataTableProps<TData>['variant']
}): Array<ColumnDef<TData>> => {
  if (options?.state?.rowSelection) {
    return [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            id='select-all'
            checked={table.getIsSomeRowsSelected() ? 'indeterminate' : table.getIsAllRowsSelected()}
            // Using onClick instead of onCheckedChange for compatibility with the handler
            onClick={table.getToggleAllRowsSelectedHandler()}
            palette={variant === 'backplate' ? 'tertiary' : 'secondary'}
          />
        ),
        cell: ({ row }): ReactNode => (
          <Checkbox
            id={options.getRowId ? options.getRowId(row.original, row.index, row.getParentRow()) : String(row.index)}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onCheckedChange={row.getToggleSelectedHandler()}
          />
        ),
        meta: { cellClassName: 'pl-2 pr-0', headerClassName: 'pl-2 pr-0' }
      },
      ...columns
    ]
  }

  return columns
}

const addSkeletonLoading = <TData,>(columns: Array<ColumnDef<TData>>): Array<ColumnDef<TData>> => {
  return columns.map((column) => ({
    ...column,
    cell: () => <Skeleton className='h-4 min-w-12' />
  }))
}

const extractColumns = <TData,>({
  columns,
  loading,
  options,
  variant
}: {
  columns: Array<ColumnDef<TData>>
  loading?: boolean
  options?: Omit<TableOptions<TData>, 'data' | 'columns' | 'getCoreRowModel'>
  variant?: DataTableProps<TData>['variant']
}): Array<ColumnDef<TData>> => {
  const columnsWithSelection = addSelectionColumn({ columns, options, variant })
  return loading ? addSkeletonLoading(columnsWithSelection) : columnsWithSelection
}

const defaultPageSizeOptions = [5, 10, 20, 30, 40, 50]

export const DataTable = <TData,>({
  bodyCellClassName,
  columns,
  data,
  emptyStateText,
  enableColumnOrdering = false,
  enablePagination = false,
  getRowClassName,
  headerCellClassName,
  headerRowClassName,
  loading,
  loadingRows = 5,
  onPageChange,
  onPageSizeChange,
  options,
  orientation = 'horizontal',
  pageIndex = 0,
  pageSize = 10,
  pageSizeOptions = defaultPageSizeOptions,
  rowsPerPageText = 'Rows per page:',
  totalCount,
  variant = 'default',
  wrapperClassName,

  ...props
}: DataTableProps<TData>): ReactNode => {
  const columnsWithCheckboxes = useMemo(
    () => extractColumns({ columns, options, variant, loading }),
    // Ignoring rule as we use JSON.stringify: https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(columns), JSON.stringify(options), variant, loading]
  )

  const [columnOrder, setColumnOrder] = useState<string[]>(() =>
    filterNullishValues(columnsWithCheckboxes.map((col) => col.id))
  )

  const table = useReactTable({
    // We don't care about the type here in the loading state
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    data: loading ? Array.from({ length: loadingRows }).map(() => ({}) as TData) : data,
    columns: columnsWithCheckboxes,
    getCoreRowModel: getCoreRowModel(),
    ...(enablePagination && {
      manualPagination: true,
      // Tanstack table expects -1 if the total number of pages is unknown
      pageCount: totalCount ? Math.ceil(totalCount / pageSize) : -1,
      state: {
        ...options?.state,
        columnOrder: enableColumnOrdering ? columnOrder : undefined
      },

      onPaginationChange: (updater): void => {
        if (isFunction(updater)) {
          const newState = updater({
            pageIndex,
            pageSize
          })

          if (newState.pageIndex !== pageIndex && onPageChange) {
            onPageChange(newState.pageIndex)
          }

          if (newState.pageSize !== pageSize && onPageSizeChange) {
            onPageSizeChange(newState.pageSize)
          }
        }
      }
    }),
    onColumnOrderChange: setColumnOrder,
    ...options
  })

  const renderVerticalTable = (): ReactNode => {
    if (data.length > 1) {
      throw new Error('Vertical orientation only supports one row of data')
    }
    return (
      <Table {...props}>
        <TableBody>
          {table
            .getRowModel()
            .rows[0]?.getVisibleCells()
            .map((cell) => {
              const header = table.getHeaderGroups()[0]?.headers.find((h) => h.id === cell.column.id)
              if (!header) {
                throw new Error('Header not found for cell')
              }
              return (
                <TableRow key={cell.id} className={getRowClassName?.(cell.row.original)}>
                  <TableHead className={cn('align-top', headerCellClassName)}>
                    {flexRender(cell.column.columnDef.header, header.getContext())}
                  </TableHead>
                  <TableCell className={cn(bodyCellClassName, cell.column.columnDef.meta?.cellClassName)}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    )
  }

  if (orientation === 'vertical') {
    return renderVerticalTable()
  }

  return (
    <div>
      <Table wrapperClassName={wrapperClassName} variant={variant} {...props}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className={headerRowClassName}>
              {headerGroup.headers.map((header) => (
                <TableHead
                  key={header.id}
                  className={cn(headerCellClassName, header.column.columnDef.meta?.headerClassName)}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.length || loading ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} className={getRowClassName?.(row.original)}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className={cn(bodyCellClassName, cell.column.columnDef.meta?.cellClassName)}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={table.getAllColumns().length} className='py-8 text-center hover:bg-background-paper'>
                {emptyStateText}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {enablePagination && (
        <DataTablePagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageCount={table.getPageCount()}
          pageSizeOptions={pageSizeOptions}
          rowsPerPageText={rowsPerPageText}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          currentPageCount={data.length}
        />
      )}
    </div>
  )
}

const DataTablePagination = ({
  currentPageCount,
  onPageChange,
  onPageSizeChange,
  pageCount,
  pageIndex,
  pageSize,
  pageSizeOptions,
  rowsPerPageText,
  ...props
}: DataTablePaginationProps & {
  currentPageCount: number
}): ReactNode => {
  const hasPreviousPage = pageIndex > 0
  const hasNextPage = currentPageCount >= pageSize

  return (
    <div className='flex items-center justify-end border-y border-gray-15 bg-gray-5 px-2 py-4' {...props}>
      <div className='flex items-center gap-2'>
        <Typography variant='body2' component='span'>
          {rowsPerPageText}
        </Typography>
        <Select
          variant='ghost'
          palette='tertiary'
          aria-label={rowsPerPageText}
          value={String(pageSize)}
          onValueChange={(value) => {
            onPageSizeChange?.(Number(value))
          }}
          options={pageSizeOptions.map((size) => ({
            value: String(size),
            children: String(size)
          }))}
        />
      </div>
      <Divider className='mx-2 h-10' orientation='vertical' />

      <div className='flex items-center gap-2'>
        {pageCount !== -1 && (
          <>
            <Typography variant='body2' component='span'>
              {pageIndex + 1} of {pageCount}
            </Typography>
            <Divider className='mx-2 h-10' orientation='vertical' />
          </>
        )}
        <IconButton
          variant='ghost'
          palette='tertiary'
          onClick={() => onPageChange?.(pageIndex - 1)}
          disabled={!hasPreviousPage}
          data-testid='pagination-prev-page'
          aria-label='Previous page'
        >
          <IconChevronLeftSuperSmall />
        </IconButton>
        <Divider className='mx-2 h-10' orientation='vertical' />
        <IconButton
          variant='ghost'
          palette='tertiary'
          onClick={() => onPageChange?.(pageIndex + 1)}
          disabled={!hasNextPage}
          data-testid='pagination-next-page'
          aria-label='Next page'
        >
          <IconChevronRightSuperSmall />
        </IconButton>
      </div>
    </div>
  )
}

export { type ColumnDef } from '@tanstack/react-table'
