import { type RiskColor } from '@strise/types'
import { IconDotSmall, type IconProps, cn } from '@strise/ui-components'
import type React from 'react'
import { riskColorClasses } from '~/features/Risk/risk'

export const RiskLevelDot = ({
  className,
  color,
  ...props
}: {
  color: RiskColor
} & IconProps &
  React.RefAttributes<SVGSVGElement>) => {
  return <IconDotSmall className={cn('shrink-0', riskColorClasses[color].colorClass, className)} {...props} />
}
