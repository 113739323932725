import { t } from '@lingui/macro'
import { Skeleton } from '@strise/ui-components'
import { type ReactNode, forwardRef } from 'react'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { Detail2 } from '~/components/Sidepanel/Detail2'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { PersonPepCard } from '~/features/PepAndSanctions/PersonPepCard'
import { SanctionCard } from '~/features/PepAndSanctions/SanctionCard'
import { useHelpCenterLink } from '~/features/Users/userHooks'
import {
  type PepInfoFragment,
  type SanctionInfoFragment,
  type SidepanelPersonFragment,
  type SidepanelPrivatePersonFragment
} from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'
import { SidepanelTab } from '~/utils/urls'

interface PepsAndSanctionsCardContentProps {
  pepInfos: PepInfoFragment[] | undefined
  pepLoading: boolean
  person: SidepanelPersonFragment | SidepanelPrivatePersonFragment
  sanctionInfo: SanctionInfoFragment | null
  sanctionsLoading: boolean
}

export const PepsAndSanctionsCardContent = forwardRef<HTMLDivElement, PepsAndSanctionsCardContentProps>(
  ({ pepInfos, pepLoading, person, sanctionInfo, sanctionsLoading }, ref): ReactNode => {
    const helpCenterLink = useHelpCenterLink()

    return (
      <SidepanelCard title={t`PEPs and Sanctions`} ref={ref} tab={SidepanelTab.PepsAndSanctions}>
        <div className='flex flex-col p-4'>
          <Detail2 title={t`Sanctions`} description={null} dataId={TestIDs.SidePanel.PepAndSanctions.sanctions}>
            {sanctionsLoading && <Skeleton className='h-[48px] w-[312px]' />}
            {!sanctionsLoading && sanctionInfo && (
              <SanctionCard
                entity={person}
                sanctions={sanctionInfo}
                associatedCompanies={null}
                currentCompanyId={null}
                noLink
              />
            )}

            <DataSources
              className='px-0'
              dataSources={person.dataSources.sanctions}
              hideLinks
              learnMoreLink={`${helpCenterLink}/articles/37050-how-strise-sanctions-work`}
            />
          </Detail2>

          <Detail2 title={t`PEP status`} description={null} dataId={TestIDs.SidePanel.PepAndSanctions.peps}>
            {pepLoading && <Skeleton className='h-[48px] w-[312px]' />}
            {!pepLoading && (
              <PersonPepCard pepInfos={pepInfos} person={person} associatedCompanies={null} currentCompanyId={null} />
            )}

            <DataSources
              className='px-0'
              dataSources={person.dataSources.peps}
              hideLinks
              learnMoreLink={`${helpCenterLink}/articles/20734-what-is-a-pep`}
            />
          </Detail2>
        </div>
      </SidepanelCard>
    )
  }
)
