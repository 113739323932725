import { LoadingView } from '@strise/app-shared'
import type { ReactNode } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { PreLoaderContext } from '~/contexts/PreLoaderContext/PreLoaderContext'

export const PreLoaderContextProvider = ({ children }: { children: ReactNode }): ReactNode => {
  const [loading, setLoading] = useState<boolean>(true)
  const [isHydrated, setIsHydrated] = useState<boolean>(false)

  // useEffect only runs on the client, so can be used to check if the client is hydrated
  useEffect(() => {
    setIsHydrated(true)
  }, [])

  const value = useMemo(
    () => ({
      loading,
      setLoading
    }),
    [loading]
  )

  if (!isHydrated) return null

  return (
    <PreLoaderContext.Provider value={value}>
      {loading && <LoadingView />}
      {children}
    </PreLoaderContext.Provider>
  )
}
