import { type EntityLikeMetaFragment } from '../../graphqlTypes'
import { extractEntityCountries } from '../../utils/entity'
import { NationFlag, type NationFlagBaseProps } from './NationFlag'
import type { ReactNode } from 'react'

export const EntityNationFlag = ({
  className,
  entity,
  ...props
}: { entity: EntityLikeMetaFragment } & NationFlagBaseProps): ReactNode => {
  const countries = extractEntityCountries(entity)

  return <NationFlag className={className} countryIsoCode={countries[0]?.isoAlpha2Code} {...props} />
}
