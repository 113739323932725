import { type RenderNodeContent, type Subsidiary } from './types'
import { Typography, cn } from '@strise/ui-components'
import { Handle, type Node, type NodeProps, Position } from '@xyflow/react'
import type { ReactNode } from 'react'

export type SubsidiaryNodeType<T extends Subsidiary<T>> = Node<
  T & {
    renderNodeContent?: RenderNodeContent<T>
  },
  'subsidiary'
>

type SubsidiaryNodeProps<T extends Subsidiary<T>> = NodeProps<SubsidiaryNodeType<T>>

const handleClassName = 'border !bg-white rounded-sm size-3 border-secondary-shade-40 !-left-[6px]'

export const SubsidiaryNode = <T extends Subsidiary<T>>({
  data,
  height = 0,
  width = 0
}: SubsidiaryNodeProps<T>): ReactNode => {
  const defaultNodeClassName = 'size-full border border-secondary-shade-20 bg-white cursor-grab active:cursor-grabbing'

  const defaultNodeContent = (
    <div className={defaultNodeClassName} style={{ width, height }}>
      <Typography variant='body3' className='flex flex-col gap-2 p-2'>
        <span>{data.name}</span>
        <span>{data.sharePercentage}%</span>
      </Typography>
    </div>
  )

  return (
    <>
      <Handle className={cn(handleClassName)} isConnectable={false} position={Position.Left} type='source' />
      <Handle className={cn(handleClassName)} isConnectable={false} position={Position.Left} type='target' />
      {data.numOwnerships > 0 && (
        <Handle
          isConnectable={false}
          className='!left-[42px] !size-0 !min-h-0 !min-w-0'
          position={Position.Bottom}
          type='source'
          id='subsidiaryParentHandle'
        />
      )}
      {data.renderNodeContent?.({ nodeId: data.id, className: defaultNodeClassName, width, height, data }) ??
        defaultNodeContent}
    </>
  )
}
