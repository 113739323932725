import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconBoard = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M21 3H3v18h18V3ZM1 1v22h22V1H1Z' clipRule='evenodd' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M8 11H6v1h2v-1ZM4 9v5h6V9H4ZM8 17H6v1h2v-1Zm-4-2v5h6v-5H4ZM18 11h-2v1h2v-1Zm-4-2v5h6V9h-6ZM18 17h-2v1h2v-1Zm-4-2v5h6v-5h-6ZM11 21V2h2v19h-2ZM20 8h-6V6h6v2ZM10 8H4V6h6v2Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconBoard.displayName = 'IconBoard'
