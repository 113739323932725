import { Skeleton } from '@strise/ui-components'
import type { ReactNode } from 'react'

export const ReviewCardSkeleton = (): ReactNode => (
  <div className='my-4 min-w-[700px]'>
    <div className='sticky z-[4] overflow-hidden'>
      <div className='flex items-center justify-between bg-background-paper px-4 py-2'>
        <div>
          <Skeleton className='mb-2 h-7 w-52' />
          <div className='flex items-center gap-2'>
            <Skeleton className='size-4' variant='circle' />
            <Skeleton className='h-3 w-20' />
            <Skeleton className='size-4' variant='circle' />
            <Skeleton className='h-3 w-20' />
            <Skeleton className='size-4' variant='circle' />
            <Skeleton className='h-3 w-20' />
          </div>
        </div>
        <div className='flex items-center gap-2'>
          <Skeleton className='h-5 w-16' variant='circle' />
          <Skeleton className='mx-2 size-6' variant='circle' />
          <Skeleton className='size-3.5' variant='circle' />
          <Skeleton className='h-5 w-24' />
        </div>
      </div>
    </div>
    <div className='border-t-2 border-solid border-tertiary-main bg-background-paper py-4'>
      <div className='flex h-[120px] flex-col justify-between px-4 pt-2'>
        <div className='flex justify-between px-5 legacy-sm:flex-col legacy-md:flex-row'>
          {Array.from({ length: 5 }).map((_, index) => (
            <div className='legacy-xs:my-2 legacy-md:my-0' key={index}>
              <Skeleton className='mb-2 h-5 w-16' />
              <Skeleton className='mb-2 h-5 w-24' />
            </div>
          ))}
        </div>
        <div className='flex items-center justify-end'>
          <Skeleton className='h-10 w-32' />
        </div>
      </div>
    </div>
    <div className='flex h-[41px] items-center justify-between rounded-b-[12px] border border-solid border-tertiary-shade-15 bg-tertiary-main'>
      <div className='flex h-full w-40 items-center pl-2'>
        <Skeleton className='mr-2 size-5' />
        <Skeleton className='h-3 w-32' />
      </div>
      <div className='flex h-full w-[218px] items-center border-l border-secondary-shade-15 px-8'>
        <Skeleton className='h-5' />
      </div>
    </div>
  </div>
)
