import { IconSuccessFill } from '@strise/ui-components'
import { IconDangerFillRound } from '@strise/ui-components'
import { Typography, cn } from '@strise/ui-components'

const CompareRow = ({
  comment,
  fieldName,
  isExactMatch
}: {
  comment?: string
  fieldName: string
  isExactMatch: boolean
}) => {
  return (
    <div
      className={cn('flex justify-between py-3 px-4 rounded-md', {
        'bg-semantic-danger-shade-5': !isExactMatch
      })}
    >
      <div className='flex gap-3 items-center'>
        {isExactMatch ? (
          <IconSuccessFill className='text-semantic-success-main' />
        ) : (
          <IconDangerFillRound className='text-semantic-danger-main' />
        )}
        <Typography variant='body1'>{fieldName}</Typography>
      </div>
      {comment && (
        <Typography
          variant='aLabelSmall'
          className={cn('self-center', {
            'text-semantic-danger-main': !isExactMatch,
            'text-semantic-success-main': isExactMatch
          })}
        >
          {comment}
        </Typography>
      )}
    </div>
  )
}

export default CompareRow
