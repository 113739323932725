import { filterNullishValues } from './array'
import { isArray, isNil, isObject, omit } from 'lodash-es'

export const isInObject = <Key extends PropertyKey>(
  object: Record<Key, unknown>,
  testKey: PropertyKey
): testKey is Key => {
  return testKey in object
}

export const objectKeys = <T extends object>(obj: T): Array<keyof T> => {
  return Object.keys(obj) as Array<keyof T>
}

export const objectEntries = <T extends object, K extends keyof T>(obj: T): Array<[keyof T, T[K]]> => {
  return Object.entries(obj) as Array<[keyof T, T[K]]>
}

export const deepMergeObjects = <T extends object>(...objects: Array<T | null | undefined>): T => {
  return filterNullishValues(objects).reduce<T>((objAcc, obj) => {
    return objectKeys(obj).reduce<T>((keyAcc, key) => {
      const accValue = objAcc[key]
      const objValue = obj[key]

      if (isNil(objValue)) return keyAcc

      if (isObject(accValue) && isObject(objValue) && !isArray(accValue) && !isArray(objValue)) {
        // Recursively merge nested objects, return a new object
        return {
          ...keyAcc,
          [key]: deepMergeObjects(accValue, objValue)
        }
      }

      // Assign the value from obj, ensuring a new object is returned
      return {
        ...keyAcc,
        [key]: objValue
      }
    }, objAcc) // Start with the current accumulator as the base
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  }, {} as T)
}

export const reduceFlatten = <O extends object>(acc: O, cur: O): O => ({
  ...acc,
  ...cur
})

type OmitDeep<T, Key extends string> =
  T extends Array<infer U>
    ? Array<OmitDeep<U, Key>>
    : T extends object
      ? { [K in Exclude<keyof T, Key>]: OmitDeep<T[K], Key> }
      : T

export const omitDeepTypename = <T>(obj: T): OmitDeep<T, '__typename'> => omitDeep(obj, '__typename')
export const omitDeep = <T, Key extends string>(obj: T, propertyToOmit: Key): OmitDeep<T, Key> => {
  if (Array.isArray(obj)) {
    return obj.map((e) => omitDeep(e, propertyToOmit) as OmitDeep<T, Key>) as OmitDeep<T, Key>
  }

  if (isObject(obj)) {
    const omitted = omit(obj, propertyToOmit)
    return Object.fromEntries(
      Object.entries(omitted).map(([key, value]) => [key, omitDeep(value, propertyToOmit)])
    ) as OmitDeep<T, Key>
  }

  return obj as OmitDeep<T, Key>
}
