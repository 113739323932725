import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconAdverseFlag = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <circle cx={12} cy={12} r={10} fill='#fff' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M3 1a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3Zm18 11a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-10 6.008V10h2v8.008h-2ZM11 9V7h2v2h-2Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconAdverseFlag.displayName = 'IconAdverseFlag'
