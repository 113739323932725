import { createContext, useContext } from 'react'

interface DarkModeContextProps {
  state: boolean
}

export const DarkModeContext = createContext<DarkModeContextProps>({ state: false })

export const useDarkMode = (): { darkMode: boolean } => {
  const { state } = useContext(DarkModeContext)
  return { darkMode: state }
}
