import CURRENT_USER from '../../../../../../../libs/app-shared/graphql/currentUser.graphql'
import { type MutationUpdaterFn } from '@apollo/client/index.js'
import { type CurrentUserQuery, type UpdateAllUserSettingsMutation } from '~/graphqlTypes'

export interface UserInfoState {
  name: string
}

export const handleUpdateUserCache: (
  userId: string | null | undefined
) => MutationUpdaterFn<UpdateAllUserSettingsMutation> =
  (userId) =>
  (cache, { data }) => {
    if (!userId) return

    const updatedUser = data?.updateUser.user

    if (updatedUser?.id !== userId) return

    const existingData: CurrentUserQuery | null = cache.readQuery({
      query: CURRENT_USER
    })

    if (!existingData?.currentUser) return

    cache.writeQuery({
      query: CURRENT_USER,
      data: {
        ...existingData,
        currentUser: {
          ...existingData.currentUser,
          name: updatedUser.name || existingData.currentUser.name
        }
      }
    })
  }
