import { Trans } from '@lingui/macro'
import { PrivatePersonReviewStatusKind } from '@strise/types'
import { IconReview, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useRef } from 'react'
import { useResizeObserver } from 'usehooks-ts'
import { useTeam } from '~/contexts/TeamContext/TeamContext'
import { PrivatePersonReviewCard } from '~/features/Review/PrivatePersonReviewCard'
import { ReviewCardSkeleton } from '~/features/Review/ReviewCardSkeleton'
import { ReviewPrivatePersonHeader } from '~/features/Review/ReviewPrivatePersonHeader'
import { usePrivatePersonsByReviewStatusQuery, useTeamReviewSettingsQuery } from '~/graphqlOperations'
import { TestIDs } from '~/utils/testIDs'

export const ReviewPrivatePersonsContent = (): ReactNode => {
  const { id: teamId } = useTeam()

  // TODO: Infinite scroll
  const { data, loading: privatePersonSearchLoading } = usePrivatePersonsByReviewStatusQuery({
    variables: {
      status: PrivatePersonReviewStatusKind.InReview,
      pageInfo: {
        limit: 10,
        offset: 0
      }
    },
    fetchPolicy: 'network-only'
  })
  const privatePersons = data?.privatePersonsByReviewStatus.edges.map((edge) => edge.node)

  const { data: teamSettingsData, loading: teamSettingsLoading } = useTeamReviewSettingsQuery({ variables: {} })
  const teamReviewSettings = teamSettingsData?.team.teamSettings.reviewSettingsV2

  const loading = privatePersonSearchLoading || teamSettingsLoading

  const filterRef = useRef<HTMLDivElement>(null)
  const { height: filterHeight } = useResizeObserver({ ref: filterRef })

  return (
    <div>
      <ReviewPrivatePersonHeader ref={filterRef} />
      <div className='mx-auto max-w-[960px] pb-[30vh] pt-4 flex flex-col gap-4'>
        {loading && !privatePersons && (
          <div>
            <ReviewCardSkeleton />
            <ReviewCardSkeleton />
            <ReviewCardSkeleton />
          </div>
        )}
        {!loading && !privatePersons?.length && (
          <div
            className='mt-16 flex flex-col items-center justify-center text-center'
            data-id={TestIDs.Review.emptyState}
          >
            <IconReview className='mb-4 size-[84px] text-secondary-shade-30' />
            <Typography className='mb-4' variant='h2'>
              <Trans>No private persons to show</Trans>
            </Typography>
          </div>
        )}
        {teamReviewSettings &&
          privatePersons?.map((privatePerson) => {
            return (
              <PrivatePersonReviewCard
                key={privatePerson.id}
                filterHeight={filterHeight}
                privatePerson={privatePerson}
                teamId={teamId}
                teamReviewSettings={teamReviewSettings}
              />
            )
          })}
      </div>
    </div>
  )
}
