import { type MessageDescriptor, i18n } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { IconHelp, Tooltip } from '@strise/ui-components'
import { Table, TableCell, TableRow } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { type CreditReportFragment } from '~/graphqlTypes'

const CreditScoreOverviewRow = ({
  className,
  count,
  sum,
  title,
  tooltip,
  ...props
}: {
  count: string | undefined
  sum: string | undefined
  title: MessageDescriptor
  tooltip?: ReactNode
} & Omit<DivProps, 'title'>): ReactNode => {
  return (
    <TableRow className={className} {...props}>
      <TableCell size='large' color='text.secondary' pl={0}>
        <span className='mr-2'>{i18n._(title)}</span>
        {tooltip && (
          <Tooltip content={tooltip}>
            <IconHelp />
          </Tooltip>
        )}
      </TableCell>
      <TableCell size='large' color='text.secondary' textAlign='right' numeric>
        {count ?? '-'}
      </TableCell>
      <TableCell size='large' textAlign='right' width={200} pr={0} numeric>
        {sum ?? '-'}
      </TableCell>
    </TableRow>
  )
}

export const SidepanelCreditReportOverview = ({
  creditReport
}: {
  creditReport: CreditReportFragment | null
}): ReactNode => {
  return (
    <div>
      <Table>
        <tbody>
          <CreditScoreOverviewRow
            title={defineMessage({ message: 'Remarks' })}
            count={creditReport?.unsettledRemarksCount}
            sum={creditReport?.unsettledRemarksSum}
            tooltip={t`These remarks can be both settled and unsettled`}
          />

          <CreditScoreOverviewRow
            title={defineMessage({ message: 'Partially settled remarks' })}
            count={creditReport?.partiallySettledRemarksCount}
            sum={creditReport?.partiallySettledRemarksSum}
          />

          <CreditScoreOverviewRow
            title={defineMessage({ message: 'Voluntary collateral' })}
            count={creditReport?.collateralCount}
            sum={creditReport?.collateralSum}
          />
          {creditReport?.totalDebtBalance && (
            <CreditScoreOverviewRow
              className='border-b-0'
              title={defineMessage({ message: 'Total debt balance' })}
              sum={creditReport.totalDebtBalance}
              count=''
            />
          )}
        </tbody>
      </Table>
    </div>
  )
}
