import { type OwnerNodeType } from './OwnerNode'
import { type RootNodeType } from './RootNode'
import { NODE_HEIGHT, NODE_SEPARATION, NODE_WIDTH, RANK_SEPARATION, ROOT_NODE_HEIGHT } from './sizes'
import { type OwnershipChart, type OwnershipChartEdge, type RenderNodeContent, type RootEntity } from './types'
import Dagre from '@dagrejs/dagre'
import { type SetStateFn } from '@strise/react-utils'
import { type Edge, Position, type SetCenterOptions } from '@xyflow/react'
import { orderBy } from 'lodash-es'
import { useEffect, useState } from 'react'

const createDagreInstance = (): Dagre.graphlib.Graph => {
  const dagreGraph = new Dagre.graphlib.Graph()
  dagreGraph.setGraph({ nodesep: NODE_SEPARATION, ranksep: RANK_SEPARATION })
  dagreGraph.setDefaultEdgeLabel(() => ({}))
  return dagreGraph
}

export const useOwnershipGraph = <T extends OwnershipChart>(
  chart: T,
  rootEntity: RootEntity,
  renderNodeContent?: RenderNodeContent<T['nodes'][number]>,
  renderRootNodeContent?: RenderNodeContent<RootEntity>,
  focusNodeId?: string
): {
  ownerEdges: Edge[]
  ownerNodes: Array<OwnerNodeType<T['nodes'][number]> | RootNodeType>
  rootNode: RootNodeType
  setHoveredNodeId: SetStateFn<string | null>
} => {
  // Store in state to get a new instance for each chart
  const [dagreGraph] = useState(createDagreInstance)
  const [hoveredNodeId, setHoveredNodeId] = useState<string | null>(null)
  const [pinnedNodeId, setPinnedNodeId] = useState<string | null>(null)

  const edgeIds = new Set(chart.edges.flatMap((edge) => [edge.parent, edge.child]))

  const highlightPathStartingNode = pinnedNodeId ?? hoveredNodeId

  const highlightedPath = highlightPathStartingNode
    ? findPathToRoot(highlightPathStartingNode, rootEntity.id, chart.edges)
    : []

  const handlePinNode = (nodeId: string): void => {
    if (pinnedNodeId === nodeId) {
      setPinnedNodeId(null)
    } else {
      setPinnedNodeId(nodeId)
    }
  }

  useEffect(() => {
    if (focusNodeId) {
      handlePinNode(focusNodeId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusNodeId])

  const filteredNodes = chart.nodes.filter((node) => {
    // If the root entity owns itself, it will be part of the chart, but we don't want to render it
    // We instead render it explicitly as the root node
    const isRootNode = node.id === rootEntity.id
    if (isRootNode) {
      return false
    }
    // Filter out any nodes that are not part of the edges
    return edgeIds.has(node.id)
  })

  filteredNodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: NODE_WIDTH, height: NODE_HEIGHT })
  })

  dagreGraph.setNode(rootEntity.id, { height: ROOT_NODE_HEIGHT })

  // Filter out self-ownership edges where the parent is not the root entity
  const filteredEdges = chart.edges.filter((edge) => !(edge.parent === edge.child && edge.parent !== rootEntity.id))

  // Sort edges by percentage,
  const sortedEdges = orderBy(filteredEdges, (edge) => Number.parseFloat(edge.share.replace('%', '')), 'desc')

  sortedEdges.forEach((edge) => {
    dagreGraph.setEdge(edge.parent, edge.child)
  })

  Dagre.layout(dagreGraph)

  const ownerNodes: Array<OwnerNodeType<T['nodes'][number]>> = filteredNodes.map((node) => {
    const { height, width, x, y } = dagreGraph.node(node.id)
    return {
      id: node.id,
      data: {
        ...node,
        renderNodeContent,
        handlePinNode,
        setHoveredNodeId,
        isHovered: node.id === hoveredNodeId,
        pinnedNodeId
      },
      position: { x, y },
      width,
      height,
      // Pre calculate the handle positions to enable rendering edges in SSR
      handles: [
        {
          type: 'source',
          position: Position.Bottom,
          x: width / 2,
          y: 0 + height
        },
        {
          type: 'target',
          position: Position.Top,
          x: width / 2,
          y: 0
        }
      ],
      type: 'owner',
      connectable: false,
      draggable: false
    }
  })

  const rootDagreNode = dagreGraph.node(rootEntity.id)
  const rootNode: RootNodeType = {
    id: rootEntity.id,
    data: { ...rootEntity, renderNodeContent: renderRootNodeContent },
    position: { x: rootDagreNode.x, y: rootDagreNode.y },
    height: rootDagreNode.height,
    // So we can still draw a node in the minimap, even though the width will not match exactly
    measured: {
      width: NODE_WIDTH
    },
    initialWidth: NODE_WIDTH * 2,
    type: 'root',
    // Pre calculate the handle positions to enable rendering edges in SSR
    handles: [
      {
        type: 'target',
        position: Position.Top,
        x: NODE_WIDTH,
        y: 0
      },
      {
        type: 'source',
        position: Position.Bottom,
        x: NODE_WIDTH / 2,
        y: rootDagreNode.height
      }
    ],
    connectable: false,
    draggable: false
  }

  const nodes = [...ownerNodes, rootNode]

  // Map edges to React Flow edges
  const edges: Edge[] = sortedEdges.map((edge) => {
    const isSelfOwnership = edge.parent === edge.child
    const marked = highlightedPath.includes(edge)
    return {
      id: `${edge.parent}-${edge.child}`,
      source: edge.parent,
      sourceHandle: isSelfOwnership ? 'selfOwnership' : undefined,
      target: edge.child,
      label: edge.share,
      selectable: false,
      animated: false,
      type: 'ownership',
      data: { marked }
    }
  })

  return { ownerNodes: nodes, rootNode, ownerEdges: edges, setHoveredNodeId }
}

export const findPathToRoot = (
  nodeId: string,
  rootId: string,
  allEdges: OwnershipChartEdge[],
  maxDepth: number = 100
): OwnershipChartEdge[] => {
  const traverseDown = (
    currentNodeId: string,
    depth: number = 0,
    visited: Set<string> = new Set()
  ): OwnershipChartEdge[] => {
    if (visited.has(currentNodeId) || depth > maxDepth) {
      return [] // Cycle detected or max depth reached, return empty path
    }

    const newVisited = new Set(visited).add(currentNodeId)
    const childEdges = allEdges.filter((edge) => edge.parent === currentNodeId)

    return childEdges.flatMap((childEdge) => {
      if (childEdge.child === rootId) {
        return [childEdge]
      }
      const subPath = traverseDown(childEdge.child, depth + 1, newVisited)
      return subPath.length > 0 ? [childEdge, ...subPath] : []
    })
  }

  return traverseDown(nodeId)
}

export const centerOnRootNode = (
  rootNode: RootNodeType,
  setCenter: (x: number, y: number, options: SetCenterOptions) => Promise<boolean>,
  animate = true
): void => {
  setCenter(rootNode.position.x + NODE_WIDTH, rootNode.position.y - NODE_HEIGHT, {
    zoom: 0.8,
    duration: animate ? 500 : undefined
  })
}
