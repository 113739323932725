import { NationFlags } from '@strise/app-shared'
import { useContext } from '@strise/react-utils'
import { ellipsis } from '@strise/ts-utils'
import { Typography, cn } from '@strise/ui-components'
import { type ReactNode } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import ConnectOwnerLink from '~/features/Ownerships/ConnectOwnerLink'
import DefaultTag from '~/features/Ownerships/ControlCard/DefaultTag'
import FamilyMembersTag from '~/features/Ownerships/ControlCard/FamilyMembersTag'
import OwnerControlAdditionalInformation from '~/features/Ownerships/ControlCard/OwnerControlAdditionalInformation'
import OwnershipLevel from '~/features/Ownerships/ControlCard/OwnershipLevel'
import { type CompanyControlEntityFragment } from '~/graphqlTypes'
import { CustomMeta } from '~/utils/apiTable/CustomMeta'
import { useIsMergeableEntity } from '~/utils/entity'

const maxNameLength = 30

const OwnerControlDetails = ({
  isBeneficialOwner,
  owner
}: {
  isBeneficialOwner: boolean
  owner: CompanyControlEntityFragment
}): ReactNode => {
  const { above25 } = useContext(SidepanelContext)

  const isMergeable =
    useIsMergeableEntity(owner.entity) && owner.ownershipMax && owner.ownershipMax > (above25 ? 25 : 5)
  const isSlim = owner.entity.__typename === 'Company' && owner.entity.isSlim

  const trimmedName = owner.entity.name ? ellipsis(owner.entity.name, maxNameLength) : null

  return (
    <div className={cn('flex flex-col gap-1 p-3 rounded-lg', isMergeable && isBeneficialOwner && 'bg-yellow-5')}>
      <div className='flex justify-between'>
        <div className='flex items-center justify-start gap-1 relative'>
          <EntityLink
            entity={owner.entity}
            noLink={isSlim}
            iconWrapperProps={{ size: 'sm' }}
            withIcon={isSlim}
            className='relative'
            maxLength={maxNameLength}
          >
            {isSlim && owner.entity.__typename === 'Company' && (
              <NationFlags
                className='absolute size-3.5 -bottom-0 -left-4 bg-background-paper rounded'
                countryIsoCodes={[owner.entity.primaryCountry?.kind ?? '']}
              />
            )}
            {trimmedName}
          </EntityLink>
          <OwnershipLevel
            level={owner.additionalInformation.find((info) => info.__typename === 'ControlPath')?.path.length ?? 0}
            variant='contained'
          />
        </div>
        {isMergeable && <ConnectOwnerLink entity={owner.entity} className='self-start' />}
        {owner.customMeta && (
          <div>
            <CustomMeta customMeta={owner.customMeta} />
          </div>
        )}
      </div>
      {owner.reasons.length > 0 && <Typography>{owner.reasons.join(' & ')}</Typography>}
      <div className='flex gap-2 flex-wrap'>
        {owner.keyTags
          .filter((tag) => tag.__typename === 'DefaultTag')
          .map((tag) => (
            <DefaultTag key={tag.label} label={tag.label} value={tag.value} />
          ))}
        {owner.keyTags
          .filter((tag) => tag.__typename === 'FamilyMembersTag')
          .map((tag) => (
            <FamilyMembersTag key={tag.label} label={tag.label} value={tag.value} relations={tag.relations} />
          ))}
      </div>

      <OwnerControlAdditionalInformation owner={owner} additionalInformation={owner.additionalInformation} />
    </div>
  )
}

export default OwnerControlDetails
