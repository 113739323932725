import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconBirthdayCake = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M1 10h22v13H1V10Zm2 2v9h18v-9H3Z' clipRule='evenodd' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M7 11.5V6h2v5.5H7ZM11 11.5V6h2v5.5h-2ZM15 11.5V6h2v5.5h-2ZM3.509 16H2v-2h2.5l.3.4c.493.657 1.228 1.6 2.7 1.6.482 0 .799-.094 1.037-.21.252-.124.464-.295.736-.537l.107-.097C9.907 14.682 10.664 14 12 14c1.353 0 2.336.69 3.026 1.191l.157.115c.294.215.52.38.75.508.25.14.423.186.567.186.803 0 1.213-.191 1.525-.425.178-.134.35-.3.556-.523.056-.059.116-.125.181-.197.16-.173.343-.374.53-.562l.294-.293H21.5v2h-1.073l-.151.165-.232.252c-.23.246-.497.517-.819.758-.688.516-1.528.825-2.725.825-.606 0-1.121-.204-1.542-.44a9.663 9.663 0 0 1-.973-.652l-.136-.1C13.164 16.31 12.647 16 12 16c-.558 0-.793.209-1.358.712l-.04.035c-.29.258-.673.587-1.186.839C8.89 17.844 8.268 18 7.5 18c-2.118 0-3.35-1.21-3.991-2Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        d='M16 4a1 1 0 0 0 1-1l-1-2-1 2a1 1 0 0 0 1 1ZM12 4a1 1 0 0 0 1-1l-1-2-1 2a1 1 0 0 0 1 1ZM8 4a1 1 0 0 0 1-1L8 1 7 3a1 1 0 0 0 1 1Z'
      />
    </svg>
  )
)
IconBirthdayCake.displayName = 'IconBirthdayCake'
