import { cn } from '../../utils/className'
import { Typography } from '../Typography'
import { tagVariants } from '../variants/tagVariants'
import { type DivPropsWithChildren } from '@strise/react-utils'
import type { VariantProps } from 'class-variance-authority'
import { type ReactNode, forwardRef } from 'react'

export interface TagProps extends DivPropsWithChildren, VariantProps<typeof tagVariants> {}

export const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ children, className, palette, ...props }, ref): ReactNode => {
    return (
      <div className={cn(tagVariants({ palette }), className)} ref={ref} {...props}>
        <Typography variant='aLabel'>{children}</Typography>
      </div>
    )
  }
)

Tag.displayName = 'Tag'
