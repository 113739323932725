import { cn } from '@strise/ui-components'
import { BaseEdge, type Edge, EdgeLabelRenderer, type EdgeProps, Position, getBezierPath } from '@xyflow/react'
import type { ReactNode } from 'react'

export type OwnershipEdgeType = Edge<{ marked: boolean }>

type OwnershipEdgeProps = EdgeProps<OwnershipEdgeType>

export const OwnershipEdge = ({
  data,
  id,
  label,
  sourceHandleId,
  sourceX,
  sourceY,
  targetX,
  targetY
}: OwnershipEdgeProps): ReactNode => {
  const isSelfOwnershipEdge = sourceHandleId === 'selfOwnership'

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition: isSelfOwnershipEdge ? Position.Left : undefined,
    curvature: isSelfOwnershipEdge ? 0.5 : undefined
  })

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        className={cn(data?.marked && !isSelfOwnershipEdge && '!stroke-blue-50 !stroke-[2]')}
      />
      <EdgeLabelRenderer>
        <span
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`
          }}
          className='absolute bg-white p-0.5 text-[10px] leading-none text-secondary-shade-70'
        >
          {label}
        </span>
      </EdgeLabelRenderer>
    </>
  )
}
