import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { type TabRefs } from '~/components/Sidepanel/SidepanelTabs/sidepanelTabsUtils'
import { EventsCard } from '~/features/Events/SidepanelEventsCard'
import { SidepanelOwnershipsCard } from '~/features/Ownerships/SidepanelOwnershipsCard'
import { SidepanelPersonPepsAndSanctionsCard } from '~/features/PepAndSanctions/SidepanelPersonPepsAndSanctionsCard'
import { SidepanelRolesCard } from '~/features/Roles/SidepanelRolesCard'
import { PersonDetailsCard } from '~/features/SidepanelPersonDetailsCard'
import { SidepanelPersonHeaderCard } from '~/features/SidepanelPersonHeaderCard'
import { SidepanelSimilarEntities } from '~/features/SimilarEntities/SidepanelSimilarEntities'
import { type SidepanelPersonFragment } from '~/graphqlTypes'
import { hasEntityAccess, isGraniteEntity } from '~/utils/entity'
import { SidepanelTab } from '~/utils/urls'

export const SidepanelPersonLayout = ({ person, tabRefs }: { person: SidepanelPersonFragment; tabRefs: TabRefs }) => {
  const [showOriginal, setShowOriginal] = useState(false)

  return (
    <>
      <Helmet>
        <title>{person.name}</title>
      </Helmet>
      <SidepanelPersonHeaderCard ref={tabRefs[SidepanelTab.Person]} person={person} />
      {hasEntityAccess(person) && (
        <>
          {!person.isGlobalPerson && !isGraniteEntity(person) && <SidepanelSimilarEntities />}
          <PersonDetailsCard person={person} />
          <SidepanelPersonPepsAndSanctionsCard person={person} ref={tabRefs[SidepanelTab.PepsAndSanctions]} />
          <SidepanelRolesCard entity={person} ref={tabRefs[SidepanelTab.Roles]} sidepanelTab={SidepanelTab.Roles} />
          <SidepanelOwnershipsCard
            ref={tabRefs[SidepanelTab.Ownership]}
            sidepanelTab={SidepanelTab.Ownership}
            entity={person}
            showOriginal={showOriginal}
            setShowOriginal={setShowOriginal}
          />
          {!person.isGlobalPerson && <EventsCard ref={tabRefs[SidepanelTab.Events]} />}
        </>
      )}
    </>
  )
}
