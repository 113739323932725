import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconListSearch = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M4 3h19V2a1 1 0 0 0-1-1H4v2ZM4 7h19V5H4v2ZM10 11H4V9h6v2ZM4 15h4v-2H4v2ZM8 19H4v-2h4v2ZM4 23h6v-2H4v2ZM3 15H1v-2h2v2ZM1 19h2v-2H1v2ZM3 23H2a1 1 0 0 1-1-1v-1h2v2ZM1 11h2V9H1v2ZM3 7H1V5h2v2ZM1 3h2V1H1v2Z'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M21.299 19.884a6.5 6.5 0 1 0-1.57 1.258l2.064 2.065 1.414-1.414-1.908-1.909ZM16.5 20a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconListSearch.displayName = 'IconListSearch'
