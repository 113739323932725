import { IconCross, Typography, cn } from '../..'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { type ComponentPropsWithoutRef, type HTMLAttributes, type ReactNode, forwardRef } from 'react'

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

export type DialogProps = ComponentPropsWithoutRef<typeof Dialog>

export interface DialogOverlayProps extends ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> {
  className?: string
}

export interface DialogContentProps extends ComponentPropsWithoutRef<typeof DialogPrimitive.Content> {
  className?: string
}

export interface DialogHeaderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

export interface DialogFooterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

export interface DialogTitleProps extends ComponentPropsWithoutRef<typeof DialogPrimitive.Title> {
  className?: string
}

export interface DialogDescriptionProps extends ComponentPropsWithoutRef<typeof DialogPrimitive.Description> {
  className?: string
}

const DialogOverlay = forwardRef<React.ElementRef<typeof DialogPrimitive.Overlay>, DialogOverlayProps>(
  ({ className, ...props }, ref): ReactNode => (
    <DialogPrimitive.Overlay
      ref={ref}
      className={cn(
        'fixed inset-0 z-50 bg-gray-50/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
        className
      )}
      {...props}
    />
  )
)
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const DialogContent = forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, DialogContentProps>(
  ({ children, className, ...props }, ref): ReactNode => (
    <DialogPortal>
      <DialogOverlay />
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'fixed left-1/2 top-1/2 z-50 grid size-max min-w-[50vw] max-w-[90vw] max-h-[90vh] -translate-x-1/2 -translate-y-1/2 gap-4 bg-background-paper p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-sm',
          className
        )}
        // To prevent closing sidepanel etc. when escape key is pressed in dialog
        onEscapeKeyDown={(event) => event.stopPropagation()}
        {...props}
      >
        {children}
        <DialogPrimitive.Close className='absolute p-2 right-4 top-4 transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none text-gray-60'>
          <IconCross className='size-5' />
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    </DialogPortal>
  )
)
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({ className, ...props }: DialogHeaderProps): JSX.Element => (
  <div className={cn('flex flex-col space-y-2 text-center sm:text-left', className)} {...props} />
)
DialogHeader.displayName = 'DialogHeader'

const DialogFooter = ({ className, ...props }: DialogFooterProps): JSX.Element => (
  <div className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} {...props} />
)
DialogFooter.displayName = 'DialogFooter'

const DialogTitle = forwardRef<React.ElementRef<typeof DialogPrimitive.Title>, DialogTitleProps>(
  ({ children, className, ...props }, ref) => (
    <DialogPrimitive.Title ref={ref} className='text-left'>
      <Typography variant='h4' component='span' className={className} {...props}>
        {children}
      </Typography>
    </DialogPrimitive.Title>
  )
)
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = forwardRef<React.ElementRef<typeof DialogPrimitive.Description>, DialogDescriptionProps>(
  ({ className, ...props }, ref): ReactNode => (
    <DialogPrimitive.Description ref={ref} className={cn('text-sm text-left', className)} {...props} />
  )
)
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription
}
