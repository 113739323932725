import { cn } from '../../utils/className'
import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { type ExcludedStyleProps } from '@strise/react-utils'
import { type ComponentPropsWithoutRef, type ReactNode, forwardRef } from 'react'

export type DividerProps = Omit<ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>, ExcludedStyleProps>

export const Divider = forwardRef<React.ElementRef<typeof SeparatorPrimitive.Root>, DividerProps>(
  ({ className, decorative = true, orientation = 'horizontal', ...props }, ref): ReactNode => (
    <SeparatorPrimitive.Root
      ref={ref}
      className={cn('border-none bg-divider', orientation === 'horizontal' ? 'h-px w-auto' : 'h-auto w-px', className)}
      decorative={decorative}
      orientation={orientation}
      {...props}
    />
  )
)
Divider.displayName = 'Divider'
