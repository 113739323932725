import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { type EntityLinkProps } from '~/components/EntityLink/entityLinkUtils'
import { useEntityQuery } from '~/graphqlOperations'

export const EntityIdLink = forwardRef<HTMLDivElement, EntityLinkProps & { id: string }>(
  ({ id, ...props }: EntityLinkProps & { id: string }, ref): ReactNode => {
    const { data } = useEntityQuery({
      variables: { id }
    })
    const entity = data?.entity
    if (!entity) return null
    return <EntityLink ref={ref} entity={entity} {...props} />
  }
)
