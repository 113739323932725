import { type DivProps } from '@strise/react-utils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import { EntityKeyMetaItem } from '~/components/EntityMeta/EntityKeyMetaItem'
import { hasKeyMetaExtra, hasKeyMetaV2 } from '~/components/EntityMeta/entityMetaUtils'
import { type BaseEntityLikeFragment } from '~/graphqlTypes'

interface EntityKeyMetaItemsProps extends DivProps {
  entity: BaseEntityLikeFragment
  expanded: boolean
  itemProps?: DivProps
  size?: 'sm' | 'md'
}

export const EntityKeyMetaItems = forwardRef<HTMLDivElement, EntityKeyMetaItemsProps>(
  ({ className, entity, expanded, itemProps, size = 'md', ...props }: EntityKeyMetaItemsProps, ref): ReactNode => {
    const defaultItems = hasKeyMetaV2(entity) ? entity.keyMetaV2 : []
    const items = hasKeyMetaExtra(entity) ? entity.keyMetaExtra : defaultItems

    return (
      <div className={className} ref={ref} {...props}>
        {items.map((item, index) => (
          <EntityKeyMetaItem
            item={item}
            key={index}
            expanded={expanded}
            dataTrack={`${entity.__typename} / ${index} copied`}
            size={size}
            {...itemProps}
          />
        ))}
      </div>
    )
  }
)
