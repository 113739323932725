import { Trans } from '@lingui/macro'
import { setChildState, useContext } from '@strise/react-utils'
import { type ComboboxItem, IconSearch, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { AssigneeSearchSelect } from '~/features/Assignee/SelectCompanyAssignee'
import { UserChip } from '~/features/Assignee/UserChip'
import { type SimpleUserFragment } from '~/graphqlTypes'

export const GrowAssigneesFilter = (): ReactNode => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const saveAssigneeSettings = setChildState(saveSettings, 'grow.assignees')
  const assignees = settings.grow.assignees

  const handleChange = (values: Array<ComboboxItem<SimpleUserFragment>>): void => {
    saveAssigneeSettings(values.map((value) => value.value))
  }

  const handleRemove = (assignee: SimpleUserFragment): void => {
    saveAssigneeSettings(assignees.filter((a) => a.id !== assignee.id))
  }

  const assigneeEdges = assignees.map((assignee) => ({
    node: assignee,
    __typename: 'CompanyUserConnectionEdge'
  }))

  return (
    <div className='grid gap-2'>
      <Typography variant='aLabelSmallBold'>
        <Trans>Assignees</Trans>
      </Typography>
      <div className='flex flex-wrap space-x-2'>
        {assignees.map((assignee) => (
          <UserChip className='w-fit px-2' user={assignee} key={assignee.id} onDelete={() => handleRemove(assignee)} />
        ))}
      </div>
      <AssigneeSearchSelect
        variant='outlined'
        assignees={assigneeEdges}
        onChange={handleChange}
        hideSelectedValues
        startIcon={<IconSearch size='md' className='mr-2' />}
        data-track='Grow Filter / Assignees Changed'
      >
        <Trans>Search assignees</Trans>
      </AssigneeSearchSelect>
    </div>
  )
}
