import { type SetStateFn } from '@strise/react-utils'
import type { ReactNode, RefObject } from 'react'
import { useMemo, useState } from 'react'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { UPPER_THRESHOLD as DEFAULT_BENEFICIAL_OWNER_THRESHOLD } from '~/features/Ownerships/ControlCard/BeneficialOwnerThresholdToggle'

export const SidepanelContextProvider = ({
  children,
  expanded,
  id,
  scrollEl,
  setExpanded,
  setId,
  sidepanelOwnerNodeId
}: {
  children: ReactNode
  expanded: boolean
  id: string
  scrollEl: RefObject<HTMLElement | null>
  setExpanded: SetStateFn<boolean | null>
  setId: SetStateFn<string | null>
  sidepanelOwnerNodeId?: string | null
}): ReactNode => {
  const [showHeaderEntity, setShowHeaderEntity] = useState(false)
  const [showNetworkRisk, setShowNetworkRisk] = useState(false)

  const [chartFocusNodeId, setChartFocusNodeId] = useState<string | null>(sidepanelOwnerNodeId ?? null)
  const [above25, setAbove25] = useState(false)

  const [beneficialOwnerThreshold, setBeneficialOwnerThreshold] = useState(DEFAULT_BENEFICIAL_OWNER_THRESHOLD)

  const sidepanelContextValue = useMemo(
    () => ({
      id,
      setId,
      expanded,
      setExpanded,
      scrollEl,
      showHeaderEntity,
      setShowHeaderEntity,
      chartFocusNodeId,
      setChartFocusNodeId,
      showNetworkRisk,
      setShowNetworkRisk,
      above25,
      setAbove25,
      beneficialOwnerThreshold,
      setBeneficialOwnerThreshold
    }),
    [
      id,
      setId,
      expanded,
      setExpanded,
      showHeaderEntity,
      chartFocusNodeId,
      setChartFocusNodeId,
      showNetworkRisk,
      setShowNetworkRisk,
      above25,
      setAbove25,
      beneficialOwnerThreshold,
      setBeneficialOwnerThreshold
    ]
  )

  return <SidepanelContext.Provider value={sidepanelContextValue}>{children}</SidepanelContext.Provider>
}
