import { type SetStateFn, createContext } from '@strise/react-utils'
import type React from 'react'

interface SidepanelContextProps {
  above25: boolean
  beneficialOwnerThreshold: number
  chartFocusNodeId: string | null
  expanded: boolean
  id: string
  scrollEl: React.RefObject<HTMLElement | null>
  setAbove25: SetStateFn<boolean>
  setBeneficialOwnerThreshold: SetStateFn<number>
  setChartFocusNodeId: SetStateFn<string | null>
  setExpanded: SetStateFn<boolean | null>
  setId: SetStateFn<string | null>
  setShowHeaderEntity: SetStateFn<boolean>
  setShowNetworkRisk: SetStateFn<boolean>
  showHeaderEntity: boolean
  showNetworkRisk: boolean
}

export const SidepanelContext = createContext<SidepanelContextProps>()
