import { t } from '@lingui/macro'
import { EntityNationFlag } from '@strise/app-shared'
import { objectKeys } from '@strise/ts-utils'
import { Checkbox, Chip, cn } from '@strise/ui-components'
import { TableCell, type TableCellProps, TableRow } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { memo } from 'react'
import { NewChip } from '~/components/NewChip'
import { companyInPortfolio } from '~/features/CompanyStatus/companyStatusUtils'
import {
  type PortfolioEnabledColumns,
  type PortfolioField,
  type PortfolioTableColumn
} from '~/features/Portfolio/utils/portfolioTableColumns'
import { renderTableRow } from '~/features/Portfolio/utils/portfolioTableRows'
import { type PortfolioCompaniesQuery, type PortfolioCompanyFragment } from '~/graphqlTypes'
import { newCompaniesState } from '~/state'
import { TestIDs } from '~/utils/testIDs'

const cellProps: Partial<Record<PortfolioField, TableCellProps>> = {
  tags: {
    p: 0
  },
  assignees: {
    p: 0
  },
  status: {
    p: 0
  }
}

interface CompaniesTableRowProps {
  columns: PortfolioTableColumn[]
  company: PortfolioCompanyFragment
  enabledColumns: PortfolioEnabledColumns
  hasSelected: boolean
  index: number
  isSelected: boolean
  newCompanies: string[]
  onSelect: () => void
}

const companiesTableRowIsEqual = (
  {
    company: prevCompany,
    enabledColumns: prevEnabledColumns,
    hasSelected: prevHasSelected,
    isSelected: prevIsSelected
  }: CompaniesTableRowProps,
  {
    company: nextCompany,
    enabledColumns: nextEnabledColumns,
    hasSelected: nextHasSelected,
    isSelected: nextIsSelected
  }: CompaniesTableRowProps
): boolean =>
  prevIsSelected === nextIsSelected &&
  JSON.stringify(prevCompany) === JSON.stringify(nextCompany) &&
  prevHasSelected === nextHasSelected &&
  JSON.stringify(prevEnabledColumns) === JSON.stringify(nextEnabledColumns)

const CompaniesTableRow = memo(
  ({
    columns,
    company,
    enabledColumns,
    hasSelected,
    index,
    isSelected,
    newCompanies,
    onSelect
  }: CompaniesTableRowProps): ReactNode => {
    const showCheckbox = isSelected || hasSelected
    const render = renderTableRow(company, enabledColumns, index)
    const removedCompany = !companyInPortfolio(company.statusV2.status)
    const newCompany = !removedCompany && newCompanies.includes(company.id)

    return (
      <TableRow
        className={`group/portfolio-row h-full ${
          // !Important to override TableRow bgcolor
          newCompany
            ? '!bg-accent-green-shade-5 hover:!bg-accent-green-shade-10'
            : '!bg-transparent hover:!bg-secondary-shade-5'
        }`}
      >
        <TableCell height={52} pr={0}>
          <div>
            {!showCheckbox && (
              <EntityNationFlag
                entity={company}
                className='mt-[6px] size-[22px] group-hover/portfolio-row:hidden'
                data-id={TestIDs.Portfolio.flag(index)}
              />
            )}
            <Checkbox
              className={`${showCheckbox ? 'block' : 'hidden'} group-hover/portfolio-row:block`}
              id={company.id}
              onCheckedChange={onSelect}
              checked={isSelected}
              data-track='Portfolio / Select company'
              data-id={TestIDs.Portfolio.checkbox(index)}
            />
          </div>
        </TableCell>

        {columns.map(({ field }, i) => {
          return (
            <TableCell key={i} position='relative' {...cellProps[field]}>
              {field === 'name' && (
                <div className={cn('flex min-w-0 items-center', { 'text-text-secondary': removedCompany })}>
                  {newCompany && <NewChip className='-ml-6 mr-2 inline-block' />}
                  {removedCompany && (
                    <Chip
                      className='-ml-6 mr-2 inline-block'
                      variant='contained'
                      palette='tertiary'
                      label={t`Removed`}
                      data-id={TestIDs.Portfolio.removedCompanyChip}
                    />
                  )}

                  {render[field]?.renderValue}
                </div>
              )}

              {field !== 'name' && render[field]?.renderValue}
            </TableCell>
          )
        })}
      </TableRow>
    )
  },
  companiesTableRowIsEqual
)

export const PortfolioTableBody = ({
  columns,
  companies,
  enabledColumns,
  onSelect,
  selected
}: {
  columns: PortfolioTableColumn[]
  companies: PortfolioCompaniesQuery['portfolioCompanies']['edges']
  enabledColumns: PortfolioEnabledColumns
  onSelect: (id: string) => () => void
  selected: Record<string, boolean>
}) => {
  const newCompanies = newCompaniesState()

  return (
    <tbody className='bg-white' id='portfolio-view-table-body'>
      {companies.map(({ node: company }, index) => {
        const isSelected = selected[company.id] ?? false
        const hasSelected = objectKeys(selected).length > 0

        return (
          <CompaniesTableRow
            key={`${company.id}-${index}`}
            enabledColumns={enabledColumns}
            columns={columns}
            company={company}
            newCompanies={newCompanies}
            index={index}
            isSelected={isSelected}
            hasSelected={hasSelected}
            onSelect={onSelect(company.id)}
          />
        )
      })}
    </tbody>
  )
}
