import { t } from '@lingui/macro'
import { Button } from '@strise/ui-components'
import { IconChevronRightSmall } from '@strise/ui-components/src/icons/general/IconChevronRightSmall'
import { IconChevronUpSmall } from '@strise/ui-components/src/icons/general/IconChevronUpSmall'
import { type ReactNode } from 'react'

const ExpandButton = ({
  dataTrack,
  isExpanded,
  setIsExpanded
}: {
  dataTrack: string
  isExpanded: boolean
  setIsExpanded: (expanded: boolean) => void
}): ReactNode => {
  return (
    <Button
      variant='ghost'
      palette='secondary'
      className='p-0 h-auto underline hover:bg-transparent focus:bg-transparent active:bg-transparent'
      onClick={() => setIsExpanded(!isExpanded)}
      data-track={dataTrack}
      startIcon={isExpanded ? <IconChevronUpSmall /> : <IconChevronRightSmall />}
    >
      {isExpanded ? t`View less` : t`View more`}
    </Button>
  )
}

export default ExpandButton
