import { Trans, t } from '@lingui/macro'
import { Empty, formatDate } from '@strise/app-shared'
import { Typography } from '@strise/ui-components'
import { Table, TableCell, TableRow } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { type CreditReportFragment, type PaymentRemarkFragment } from '~/graphqlTypes'

const CollateralRow = ({ collateral }: { collateral: PaymentRemarkFragment }) => {
  return (
    <TableRow style={{ verticalAlign: 'top' }}>
      <TableCell size='large' pl={0}>
        <Typography variant='aLabel'>
          <div className='text-text-secondary'>{collateral.kind}</div>
          {collateral.creditor ? (
            <EntityLink entity={collateral.creditor} className='font-regular' />
          ) : (
            <div>{collateral.creditorName || '-'}</div>
          )}
        </Typography>
      </TableCell>
      <TableCell size='large' textAlign='right' numeric>
        {collateral.amount}
      </TableCell>
      <TableCell size='large' pr={0} color='text.secondary' textAlign='right'>
        {formatDate(collateral.date, { relative: false })}
      </TableCell>
    </TableRow>
  )
}

export const SidepanelCollateral = ({ creditReport }: { creditReport?: CreditReportFragment | null }): ReactNode => {
  if (!creditReport) return null

  const collaterals = creditReport.collateral

  if (!collaterals.length) {
    return (
      <Empty title={t`No collateral found`}>
        <Trans>We couldn't find any collateral on this company</Trans>
      </Empty>
    )
  }

  return (
    <Table>
      <tbody>
        {collaterals.map((collateral, index) => {
          return <CollateralRow key={index} collateral={collateral} />
        })}
      </tbody>
    </Table>
  )
}
