import { t } from '@lingui/macro'
import { useContext } from '@strise/react-utils'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, cn } from '@strise/ui-components'
import { type ComponentProps, type ReactNode, type Ref, useState } from 'react'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import ExpandButton from '~/features/Ownerships/ControlCard/ExpandButton'
import OwnerControlDetails from '~/features/Ownerships/ControlCard/OwnerControlDetails'
import { useCompanyControlDetailsQuery } from '~/graphqlOperations'
import { type SidepanelCompanyFragment, type SidepanelGlobalCompanyFragment } from '~/graphqlTypes'
import { type SidepanelTab } from '~/utils/urls'

const StyledTableHead = ({ children, ...props }: ComponentProps<typeof TableHead>): ReactNode => (
  <TableHead className='text-text-secondary flex flex-col' {...props}>
    {children}
  </TableHead>
)

const StyledTableCell = ({ children, ...props }: ComponentProps<typeof TableCell>): ReactNode => (
  <TableCell className='w-2/3' {...props}>
    {children}
  </TableCell>
)

const StyledTableRow = ({ children, ...props }: ComponentProps<typeof TableRow>): ReactNode => (
  <TableRow className='hover:bg-transparent' {...props}>
    {children}
  </TableRow>
)

const TableSection = ({ children }: { children: ReactNode }): ReactNode => (
  <div className='flex flex-col justify-center gap-2'>{children}</div>
)

const TableSectionRow = ({ children, isLast }: { children: ReactNode; isLast?: boolean }): ReactNode => (
  <div className={cn('pb-2 border-gray-15', { 'border-b': !isLast })}>{children}</div>
)

const SidepanelCompanyOwnershipAndControlCard = ({
  company,
  ref,
  sidepanelTab,
  ...props
}: {
  company: SidepanelCompanyFragment | SidepanelGlobalCompanyFragment
  ref?: Ref<HTMLDivElement>
  sidepanelTab?: SidepanelTab
}): ReactNode => {
  const { beneficialOwnerThreshold } = useContext(SidepanelContext)

  const { data, loading: controlDetailsLoading } = useCompanyControlDetailsQuery({
    variables: {
      company: company.id,
      beneficialOwnerThreshold: beneficialOwnerThreshold
    }
  })

  const [ownersExpanded, setOwnersExpanded] = useState(false)

  const controlDetails = data?.company.controlDetails
  const beneficialOwners = controlDetails?.beneficialOwners.entities || []
  const boTotalOwnership = controlDetails?.beneficialOwners.totalOwnership
  const owners = controlDetails?.owners.entities || []
  const ownersTotalCount = controlDetails?.owners.totalCount
  const ownersTotalOwnership = controlDetails?.owners.totalOwnership
  const shareClasses = controlDetails?.shareClasses.shareClasses || []
  const dataSource = data?.company.dataSources.shareholders

  const beneficialOwnersSection = (): ReactNode => {
    if (beneficialOwners.length === 0) {
      return <Typography className='text-text-secondary'>{t`No registered beneficial owners`}</Typography>
    }

    return (
      <TableSection>
        {beneficialOwners.map((owner, index) => (
          <TableSectionRow key={index} isLast={index === beneficialOwners.length - 1}>
            <OwnerControlDetails owner={owner} isBeneficialOwner />
          </TableSectionRow>
        ))}
      </TableSection>
    )
  }

  const ownersSection = (): ReactNode => {
    if (owners.length === 0) {
      return <Typography className='text-text-secondary'>{t`No registered owners`}</Typography>
    }

    return (
      <TableSection>
        <div>
          <Typography>
            {ownersTotalCount} {t`small share holders`}
          </Typography>
          <ExpandButton
            isExpanded={ownersExpanded}
            setIsExpanded={setOwnersExpanded}
            dataTrack='Small Shareholders / View More'
          />
        </div>
        {ownersExpanded && (
          <TableSection>
            {owners.map((owner, index) => (
              <TableSectionRow key={index} isLast={index === owners.length - 1}>
                <OwnerControlDetails owner={owner} isBeneficialOwner={false} />
              </TableSectionRow>
            ))}
          </TableSection>
        )}
      </TableSection>
    )
  }

  const shareClassesSection = (): ReactNode => {
    return (
      <TableSection>
        {shareClasses.map((shareClass, index) => (
          <TableSectionRow key={index} isLast={index === shareClasses.length - 1}>
            <Typography>
              {shareClass.shareClass}: {shareClass.share}
            </Typography>
            {shareClass.votingRights !== null && (
              <Typography>{shareClass.votingRights ? t`Voting rights` : t`No voting rights`}</Typography>
            )}
          </TableSectionRow>
        ))}
      </TableSection>
    )
  }

  return (
    <SidepanelCard
      title={t`Ownership & Control`}
      loading={controlDetailsLoading}
      ref={ref}
      tab={sidepanelTab}
      {...props}
    >
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableHead>
              <Typography>{t`Beneficial owners`}</Typography>
              {boTotalOwnership && (
                <Typography className='text-sm'>
                  {boTotalOwnership} {t`ownership`}
                </Typography>
              )}
            </StyledTableHead>
            <StyledTableCell>{beneficialOwnersSection()}</StyledTableCell>
          </StyledTableRow>

          {(owners.length > 0 || beneficialOwners.length === 0) && (
            <StyledTableRow>
              <StyledTableHead>
                <Typography>{t`Owners`}</Typography>
                {ownersTotalOwnership && (
                  <Typography className='text-sm'>
                    {ownersTotalOwnership} {t`ownership`}
                  </Typography>
                )}
              </StyledTableHead>
              <StyledTableCell>{ownersSection()}</StyledTableCell>
            </StyledTableRow>
          )}

          <StyledTableRow>
            <StyledTableHead>
              <Typography>{t`Share classes`}</Typography>
            </StyledTableHead>
            <StyledTableCell>{shareClassesSection()}</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
      {dataSource && <DataSources dataSources={dataSource} />}
    </SidepanelCard>
  )
}

export default SidepanelCompanyOwnershipAndControlCard
