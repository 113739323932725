import { t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { ReminderStatus } from '@strise/types'
import { IconGarbage, IconPenBox, type IconPropsWithRef } from '@strise/ui-components'
import { MenuItem, OverflowMenu } from '@strise/ui-components-legacy'
import { type TeamActivityFormData } from '~/features/TeamActivity/teamActivityUtils'
import { type TeamActivityFragment } from '~/graphqlTypes'

export const TeamActivityMenu = ({
  iconProps,
  setDeleteDialogOpen,
  setFormData,
  teamActivity
}: {
  iconProps?: IconPropsWithRef
  setDeleteDialogOpen: SetStateFn<false | string>
  setFormData: SetStateFn<TeamActivityFormData>
  teamActivity: TeamActivityFragment
}) => {
  const handleEditClick = (): void => {
    setFormData({
      id: teamActivity.id,
      kind: teamActivity.kind,
      timestamp: teamActivity.timestamp,
      user: teamActivity.createdBy,
      note: teamActivity.note || '',
      assignee: teamActivity.assignee ?? teamActivity.createdBy ?? null,
      reminderStatus: teamActivity.reminder?.status ?? ReminderStatus.Inactive,
      company: null
    })
  }

  const handleDeleteClick = (): void => setDeleteDialogOpen(teamActivity.id)

  const dropDownItems = [
    {
      title: t`Edit`,
      onClick: handleEditClick,
      startIcon: <IconPenBox />
    },
    {
      title: t`Delete`,
      onClick: handleDeleteClick,
      startIcon: <IconGarbage />
    }
  ]

  return (
    <OverflowMenu
      buttonProps={{
        'data-track': 'Team activities / Open dropdown options',
        palette: 'tertiary',
        iconProps
      }}
    >
      {dropDownItems.map(({ title, ...props }, index) => {
        return (
          <MenuItem key={index} {...props}>
            {title}
          </MenuItem>
        )
      })}
    </OverflowMenu>
  )
}
