import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { objectKeys } from '@strise/ts-utils'
import { Select } from '@strise/ui-components-legacy'
import {
  type PortfolioEnabledColumns,
  portfolioColumnTitles,
  portfolioColumns
} from '~/features/Portfolio/utils/portfolioTableColumns'

export const PortfolioColumnSelector = ({
  enabledColumns,
  setEnabledColumns
}: {
  enabledColumns: PortfolioEnabledColumns
  setEnabledColumns: SetStateFn<PortfolioEnabledColumns>
}) => {
  const options = portfolioColumns
    .filter(({ removable }) => removable)
    .map(({ field }) => ({
      text: i18n._(portfolioColumnTitles[field]),
      value: field
    }))

  const value = objectKeys(enabledColumns)

  const saveHandler = (selectValue: string[]): void => {
    setEnabledColumns(() => {
      return Object.fromEntries(
        portfolioColumns.map((column) => {
          return [column.field, selectValue.includes(column.field)]
        })
      ) as unknown as PortfolioEnabledColumns
    })
  }

  return (
    <Select<string>
      multiple
      variant='ghost'
      palette='tertiary'
      initValue={value}
      options={options}
      onSave={saveHandler}
      buttonText={t`Edit columns`}
      saveButtonText={t`Apply`}
      buttonProps={{
        className: 'h-full pl-4',
        endIcon: null
      }}
      data-track='Portfolio / Columns / Changed'
    />
  )
}
