import { Trans, t } from '@lingui/macro'
import { getBrowserGlobals } from '@strise/react-utils'
import { CompanyStatus } from '@strise/types'
import { Button } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DataSourceTooltip } from '~/components/DataSourceTooltip'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { SidepanelCopyEntityLink } from '~/components/Sidepanel/SidepanelCopyEntityLink'
import {
  SidepanelHasAccessAlert,
  SidepanelInactiveAlert,
  SidepanelSanctionsAlert
} from '~/components/Sidepanel/SidepanelEntityAlerts'
import { SidepanelEntityKeyMetaDetails } from '~/components/Sidepanel/SidepanelEntityKeyMetaDetails'
import { SidepanelNotificationSettings } from '~/components/Sidepanel/SidepanelNotificationSettings'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useSelectCompaniesStatus } from '~/features/CompanyStatus/useSelectCompaniesStatus'
import { ConflictAlert } from '~/features/Conflicts/ConflictAlert'
import { ConflictAlertType } from '~/features/Conflicts/ConflictAlertType'
import { SidepanelCreateReviewPdf } from '~/features/Review/SidepanelCreateReviewPdf'
import { SidepanelGlobalEntityBanner } from '~/features/SidepanelGlobalEntityBanner'
import { type SidepanelCompanyFragment } from '~/graphqlTypes'
import { hasEntityAccess } from '~/utils/entity'
import { TestIDs } from '~/utils/testIDs'
import { ContentViews, SidepanelTab } from '~/utils/urls'

interface MetaCardContentProps {
  company: SidepanelCompanyFragment
}

export const HeaderCardContent = ({ company }: MetaCardContentProps): ReactNode => {
  const location = useLocation()
  const navigate = useNavigate()
  const { loading: statusLoading, updateCompanyStatus } = useSelectCompaniesStatus([company])

  const features = useCurrentUserFeatures()

  const isoCountryCode = company.primaryCountry?.isoAlpha2Code.toLowerCase() ?? ''
  const companyOrgNum = company.organizationNumberV2?.value ?? ''

  const entityLink = `${getBrowserGlobals()?.window.location.origin || ''}/company/${company.isGlobalCompany ? company.id : `${isoCountryCode}/${companyOrgNum}`}`

  const handleAddToReview = async (): Promise<void> => {
    await updateCompanyStatus(CompanyStatus.InQualification, company.statusV2?.status ?? null, true)
    navigate(`/${ContentViews.Review}${location.search}`)
  }

  return (
    <>
      <SidepanelHasAccessAlert entity={company} />

      <SidepanelInactiveAlert entity={company} />

      {company.isGlobalCompany && <SidepanelGlobalEntityBanner v2={company.isGlobalCompany} />}

      <div className='flex flex-col gap-2 p-4'>
        <div className='flex flex-wrap justify-between gap-2'>
          <EntityLink
            variant='h3'
            entity={company}
            iconWrapperProps={{
              className: 'p-2 pl-0 mr-1'
            }}
            wrapperProps={{ className: 'max-w-[350px] text-wrap' }}
            withIcon
            noTooltip
            noLink
            sanctionsLink
          />

          {hasEntityAccess(company) && (
            <div className='shrink-0'>
              <div className='flex flex-wrap items-center gap-2'>
                <DataSourceTooltip content={t`Copy company link to clipboard`}>
                  <div>
                    <SidepanelCopyEntityLink entityLink={entityLink} />
                  </div>
                </DataSourceTooltip>
                <SidepanelNotificationSettings notificationEntity={company} />
                {features.REVIEW_PDF && !company.isGlobalCompany && (
                  <DataSourceTooltip content={t`Download PDF report`}>
                    <div>
                      <SidepanelCreateReviewPdf company={company} />
                    </div>
                  </DataSourceTooltip>
                )}

                {features.REVIEW && (
                  <Button
                    variant='contained'
                    palette='primary'
                    className='px-4'
                    onClick={handleAddToReview}
                    loading={statusLoading}
                    data-track='Company / Add to Review'
                    data-id={TestIDs.SidePanel.Review.Add}
                  >
                    <Trans>Add to Review</Trans>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
        <ConflictAlert type={ConflictAlertType.SIDEPANEL} entity={company} className='-mx-4' />
        <div>
          <SidepanelEntityKeyMetaDetails entity={company} />
          {!features.SIDEPANEL_COMPANY_PEPS && <SidepanelSanctionsAlert className='mt-2' entityId={company.id} />}
        </div>
      </div>
    </>
  )
}

interface MetaCardProps {
  company: SidepanelCompanyFragment
}

export const SidepanelCompanyHeaderCard = forwardRef<HTMLDivElement, MetaCardProps>(
  ({ company, ...props }, ref): ReactNode => {
    return (
      <SidepanelCard ref={ref} tab={SidepanelTab.Company}>
        <HeaderCardContent company={company} {...props} />
      </SidepanelCard>
    )
  }
)
