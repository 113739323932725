import { useReactiveVar } from '@apollo/client/index.js'
import { useUserId } from '@strise/app-shared'
import { useContext } from '@strise/react-utils'
import { filterNullishValues } from '@strise/ts-utils'
import {
  ContentLanguage,
  FinancialFieldKind,
  OpportunityFinancialHighlightCount,
  type UserSettingsInput
} from '@strise/types'
import { type ComboboxItem } from '@strise/ui-components'
import { isNil } from 'lodash-es'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { MAX_HIGHLIGHTED_FINANCIALS } from '~/constants'
import {
  CurrentUserSettingsContext,
  useCurrentUserEnabledContentLanguages
} from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type FinancialHighlightedOptions, type FinancialId } from '~/features/Financials/financialTypes'
import { useDefaultFinancialFieldsQuery, useUpdateUserSettingsMutation } from '~/graphqlOperations'
import { type FinancialFieldInfoFragment } from '~/graphqlTypes'
import { financialFieldsMap } from '~/state'

export const getFinancialFieldKind = (id: FinancialId, kind: FinancialFieldKind): boolean => {
  if (!financialFieldsMap()[id]) return false

  return financialFieldsMap()[id]?.kind === kind
}

export const isPercentageField = (id: FinancialId): boolean => getFinancialFieldKind(id, FinancialFieldKind.Percentage)
export const isDayField = (id: FinancialId): boolean => getFinancialFieldKind(id, FinancialFieldKind.Day)

// Use 'no-NO' locale to force spaces as delimiter
const number = new Intl.NumberFormat('no-NO', {
  maximumFractionDigits: 0
})

const decimal = new Intl.NumberFormat('no-NO', {
  style: 'decimal',
  maximumFractionDigits: 1
})

const percent = new Intl.NumberFormat('no-NO', {
  style: 'percent',
  maximumFractionDigits: 1
})

export const toReadableFigure = (
  value: number | null | undefined,
  fieldInfo: FinancialFieldInfoFragment | null,
  withDecimals?: boolean
): string => {
  if (isNil(value)) return '-'

  if (fieldInfo?.kind === FinancialFieldKind.Percentage) return percent.format(value / 100)

  if (withDecimals) return decimal.format(value)

  return number.format(value)
}

const PRIORITIZED_LANGUAGES = [
  ContentLanguage.Norwegian,
  ContentLanguage.Swedish,
  ContentLanguage.Danish,
  ContentLanguage.Finnish,
  ContentLanguage.English
]

export const useDefaultHighlightedFinancials = (): FinancialId[] => {
  const enabledContentLanguages = useCurrentUserEnabledContentLanguages()
  const filteredLanguages = PRIORITIZED_LANGUAGES.find((language) => enabledContentLanguages.includes(language))

  const { data } = useDefaultFinancialFieldsQuery({
    variables: { contentLanguage: filteredLanguages ?? ContentLanguage.Norwegian }
  })

  return data?.defaultFinancialFieldsByContentLanguage.map((field) => field.id) ?? []
}

export const financialHighlightCountToNumber = (
  count: OpportunityFinancialHighlightCount | null | undefined
): 3 | 6 => {
  switch (count) {
    case OpportunityFinancialHighlightCount.Three: {
      return 3
    }
    case OpportunityFinancialHighlightCount.Six: {
      return 6
    }
    default: {
      return 3
    }
  }
}

export const useHighlighted = (
  savedHighlightedIds: FinancialId[],
  maxHighlighted = MAX_HIGHLIGHTED_FINANCIALS
): FinancialHighlightedOptions => {
  const defaultHighlightedFinancials = useDefaultHighlightedFinancials()
  const financialsFieldMapState = useReactiveVar(financialFieldsMap)

  const highlighted: FinancialHighlightedOptions = useMemo(
    () =>
      [...savedHighlightedIds, ...defaultHighlightedFinancials].slice(0, maxHighlighted).map((id) => ({
        id,
        label: financialsFieldMapState[id]?.name ?? ''
      })),
    // `defaultHighlightedFinancials` can be undefined, so we need to include it in the deps - if not we can end up with an empty array
    [defaultHighlightedFinancials, savedHighlightedIds, maxHighlighted]
  )

  return highlighted
}

export const useHighlightedFinancials = (
  settingsKey: 'grow' | 'sidepanel',
  onCompleted?: () => void
): [FinancialHighlightedOptions, (value: FinancialId[]) => void, boolean] => {
  const currentUserId = useUserId()
  const [update, { loading }] = useUpdateUserSettingsMutation({ onCompleted })
  const { settings } = useContext(CurrentUserSettingsContext)

  const isOpportunities = settingsKey === 'grow'

  const savedHighlighted = settings[settingsKey].financialFieldsHighlighted
  const highlighted = useHighlighted(
    savedHighlighted,
    isOpportunities
      ? financialHighlightCountToNumber(settings.grow.financialHighlightCount)
      : MAX_HIGHLIGHTED_FINANCIALS
  )

  const saveHighlighted = async (value: FinancialId[]): Promise<void> => {
    const key = isOpportunities ? 'opportunityFinancialHighlightsSelector' : 'sidePanelFinancialHighlightsSelector'

    const input: UserSettingsInput = {
      application: {
        [key]: value
      }
    }

    await update({
      variables: {
        user: currentUserId,
        input
      }
    })
  }

  return [highlighted, saveHighlighted, loading]
}

export const useFinancialFieldSelectOptions = (
  financialsFieldMapState: (FinancialFieldInfoFragment & {
    matchingInternationalFields: FinancialFieldInfoFragment[]
  })[],
  children?: (fieldName: string, countryIsoCodes: string[], selected?: boolean) => ReactNode,
  selectedFinancialIds?: string[]
): Array<ComboboxItem<FinancialId>> => {
  return useMemo(() => {
    const alreadyListedIds: string[] = []

    const options = Object.values(financialsFieldMapState).map((financialField) => {
      if (alreadyListedIds.includes(financialField.id)) return null

      // eslint-disable-next-line functional/immutable-data
      financialField.matchingInternationalFields.forEach((f) => alreadyListedIds.push(f.id))

      const fieldCountry = financialField.countryV2
      const matchingFieldCountries = financialField.matchingInternationalFields.map((f) => f.countryV2)

      const countries = filterNullishValues([fieldCountry, ...matchingFieldCountries].map((country) => country?.kind))

      const selected = selectedFinancialIds?.includes(financialField.id)

      return {
        id: financialField.id,
        value: financialField.id,
        label: financialField.name,
        renderNode: children?.(financialField.name, countries, selected)
      }
    })
    return filterNullishValues(options)
  }, [JSON.stringify(financialsFieldMapState)])
}
