import { Trans, t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  LoaderRound
} from '@strise/ui-components'
import { type ReactNode, useState } from 'react'
import { useCreateCustomPepMutation } from '~/graphqlOperations'
import { type BaseEntityLikeFragment, type PepInfoFragment } from '~/graphqlTypes'

export const DeleteCustomPepDialog = ({
  children,
  pepInfo,
  person
}: {
  children: ReactNode
  pepInfo: PepInfoFragment
  person: BaseEntityLikeFragment
}): ReactNode => {
  const [isOpen, setIsOpen] = useState(false)

  const handleCompleted = () => {
    toast.success(t`PEP deleted`)
    setIsOpen(false)
  }

  const [create, { loading }] = useCreateCustomPepMutation({ onCompleted: handleCompleted })

  const handleConfirm = () => {
    create({
      variables: {
        entity: person.id,
        input: {
          name: pepInfo.name,
          isPep: !!pepInfo.pep,
          isRca: !!pepInfo.rca,
          comment: pepInfo.disposition?.comment,
          archived: true
        }
      }
    })
  }

  return (
    <AlertDialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <AlertDialogTrigger>{children}</AlertDialogTrigger>
      <AlertDialogContent className='overflow-visible min-w-[600px]'>
        <AlertDialogHeader>
          <AlertDialogTitle>
            <Trans>Delete custom PEP</Trans>
          </AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogFooter>
          {!loading && (
            <AlertDialogCancel onClick={() => setIsOpen(false)}>
              <Trans>Cancel</Trans>
            </AlertDialogCancel>
          )}
          <AlertDialogAction onClick={handleConfirm} disabled={loading} className='min-w-[100px]'>
            {loading ? <LoaderRound size='sm' /> : <Trans>Confirm</Trans>}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
