import { Trans, t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import { type SetStateFn, setChildState } from '@strise/react-utils'
import { objectEntries } from '@strise/ts-utils'
import { AuditRisk, type ReviewAmsEventFeedbackInput, ReviewStatus } from '@strise/types'
import { Alert, Button, Divider, Typography } from '@strise/ui-components'
import { type ReactNode, type RefObject, useEffect, useState } from 'react'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { RowValueSkeleton } from '~/features/Review/ReviewCompanyCardLoader'
import { ReviewRow } from '~/features/Review/ReviewRow'
import { ReviewSelectAllCheckboxSection } from '~/features/Review/ReviewSelectAllCheckboxSection'
import { extractReviewSections } from '~/features/Review/reviewPrivatePersonCardUtils'
import { ReviewCardContentView, type ReviewState } from '~/features/Review/reviewUtils'
import { useCreatePrivatePersonReviewMutation, useReviewPrivatePersonDataQuery } from '~/graphqlOperations'
import { type TeamReviewSettingsV2Fragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

interface PrivatePersonReviewCardContentProps {
  cardAnchorRef: RefObject<HTMLDivElement>
  privatePersonId: string
  resetReviewState: () => void
  reviewState: ReviewState
  setCardView: SetStateFn<ReviewCardContentView>
  setReviewState: SetStateFn<ReviewState>
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
}
export const PrivatePersonReviewCardContent = ({
  cardAnchorRef,
  privatePersonId,
  resetReviewState,
  reviewState,
  setCardView,
  setReviewState,
  teamReviewSettings
}: PrivatePersonReviewCardContentProps): ReactNode => {
  const features = useCurrentUserFeatures()
  const { data, error, loading } = useReviewPrivatePersonDataQuery({
    variables: {
      id: privatePersonId
    }
  })

  const handleCompleted = (): void => {
    toast.success(t`Review created`)
    resetReviewState()
    setCardView(ReviewCardContentView.AFTER_REVIEW)
    // Since the opened card is long, we scroll to top of card after collapsing the card
    // to avoid the user ending up in the middle of the page
    cardAnchorRef.current?.scrollIntoView(true)
  }

  const [createReview, { loading: createReviewLoading }] = useCreatePrivatePersonReviewMutation({
    onCompleted: handleCompleted
  })

  const [hasSubmitErrors, setHasSubmitErrors] = useState(false)

  const checkedMap = reviewState.checkedMap
  const inlineCommentsMap = reviewState.inlineCommentsMap
  const setCheckedMap = setChildState(setReviewState, 'checkedMap')
  const setInlineCommentsMap = setChildState(setReviewState, 'inlineCommentsMap')

  const reviewPrivatePersonData = data?.reviewPrivatePersonData

  // TODO: Separate sections as ams takes a long time to load
  const reviewSections = extractReviewSections({
    reviewPrivatePersonData: reviewPrivatePersonData,
    reviewState,
    setReviewState
  })

  const rowKeys = reviewSections.flatMap((section) => {
    return section.rows.filter((row) => !!row.children).map((row) => row.key)
  })

  const checkedCount = rowKeys.filter((key) => checkedMap[key]).length

  const validateHasErrors = (): boolean => {
    const rowsCount = rowKeys.length
    const hasErrors = checkedCount !== rowsCount
    setHasSubmitErrors(hasErrors)
    return hasErrors
  }

  useEffect(() => {
    if (hasSubmitErrors) validateHasErrors()
  }, [checkedMap])

  const handleSubmit = async (): Promise<void> => {
    const hasErrors = validateHasErrors()
    if (hasErrors) {
      return
    }

    const inlineCommentsInput = objectEntries(inlineCommentsMap).flatMap(([key, inlineComments]) => {
      if (!inlineComments) return []

      const matchingRow = reviewSections.flatMap((section) => section.rows).find((row) => row.key === key)

      if (!matchingRow) return []

      return inlineComments.map((inlineComment) => {
        return {
          message: inlineComment.message,
          fieldKindV2: matchingRow.kind,
          createdBy: inlineComment.createdBy.id,
          createdAt: inlineComment.createdAt
        }
      })
    })

    const reviewAmsFeedback: ReviewAmsEventFeedbackInput = {
      feedback: reviewState.amsEventFeedback,
      hits: [
        {
          entity: privatePersonId,
          hits: reviewPrivatePersonData?.ams?.length ?? 0
        }
      ]
    }

    await createReview({
      variables: {
        privatePerson: privatePersonId,
        input: {
          reviewStatus: ReviewStatus.Approved,
          comment: reviewState.comment,
          // TODO: Add risk level state
          auditRisk: AuditRisk.Low,
          inlineComments: inlineCommentsInput,
          amsEventFeedback: features.AMS_EVENT_FEEDBACK ? reviewAmsFeedback : undefined
        }
      }
    })
  }

  return (
    <div>
      <ReviewSelectAllCheckboxSection
        className='pb-0 pl-4'
        idSuffix='top'
        checkedCount={checkedCount}
        setCheckedMap={setCheckedMap}
        rowKeys={rowKeys}
        id={privatePersonId}
        errors={hasSubmitErrors}
        disabled={createReviewLoading}
        loading={loading}
      />
      {reviewSections.map((section) => {
        return (
          <div key={section.title}>
            <Typography className='mb-2 mt-4 px-4' variant='subtitle1' data-id={`Review / ${section.title}`}>
              {section.title}
            </Typography>
            <Divider />
            {error && (
              <div className='p-4'>
                <Alert variant='danger'>{t`Something went wrong. Please try again`}</Alert>
              </div>
            )}
            {loading && (
              <div className='flex flex-col gap-4 p-4'>
                {Array.from({ length: 2 }).map((_, rowIndex) => (
                  <RowValueSkeleton key={rowIndex} />
                ))}
              </div>
            )}
            {section.rows.map((row) => (
              <ReviewRow
                key={row.key}
                checkedMap={checkedMap}
                setCheckedMap={setCheckedMap}
                inlineCommentsMap={inlineCommentsMap}
                setInlineCommentsMap={setInlineCommentsMap}
                row={row}
                entityId={privatePersonId}
                errors={hasSubmitErrors}
                loading={loading}
                teamReviewSettings={teamReviewSettings}
                alerts={[]}
              />
            ))}
          </div>
        )
      })}
      <ReviewSelectAllCheckboxSection
        className='pb-0 pl-4'
        idSuffix='bottom'
        checkedCount={checkedCount}
        setCheckedMap={setCheckedMap}
        rowKeys={rowKeys}
        id={privatePersonId}
        errors={hasSubmitErrors}
        disabled={createReviewLoading}
        loading={loading}
      />
      <div className='flex justify-end px-4 items-center mt-4'>
        {hasSubmitErrors && (
          <Typography data-id={TestIDs.Review.Card.errorMessage} className='text-semantic-danger-main'>
            <Trans>Please correct the required inputs above</Trans>
          </Typography>
        )}
        <Button variant='ghost' palette='secondary' onClick={resetReviewState} data-track='false' className='mr-1'>
          {t`Reset`}
        </Button>
        <Button
          className='px-10'
          variant='contained'
          palette='primary'
          data-track='false'
          data-id={TestIDs.Review.Card.submitReviewButton}
          onClick={handleSubmit}
          loading={createReviewLoading}
          disabled={hasSubmitErrors}
        >
          {t`Save Review`}
        </Button>
      </div>
    </div>
  )
}
