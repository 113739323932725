import { Duration, type PortfolioHealthReviewMetric } from '@strise/types'
import {
  Card,
  CardContent,
  CardFooter,
  IconArrowDownSmall,
  IconArrowUpSmall,
  Select,
  Tooltip,
  Typography,
  cn
} from '@strise/ui-components'
import type { ReactNode } from 'react'
import { getDurationLabel, sortDurations } from '~/features/PortfolioHealth/utils/durationUtils'

interface PortfolioHealthReviewMetricCardProps {
  duration?: Duration
  metric: PortfolioHealthReviewMetric
  onDurationChange?: (duration: Duration) => void
  subtitle?: string
  teamText?: string
  title: string
  tooltipText: string
  tooltipTextFooter?: string
}

export const PortfolioHealthReviewMetricCard = ({
  duration,
  metric,
  onDurationChange,
  subtitle,
  teamText,
  title,
  tooltipText,
  tooltipTextFooter
}: PortfolioHealthReviewMetricCardProps): ReactNode => {
  const { teamCount, userCount, userPercentageChange } = metric

  const durationOptions = Object.values(Duration)
    .sort(sortDurations)
    .map((value) => ({
      value,
      children: getDurationLabel(value)
    }))

  const userPercentageChangeAttributes = {
    color: userPercentageChange && userPercentageChange < 0 ? 'text-semantic-danger-shade-50' : 'text-blue-60',
    icon:
      userPercentageChange && userPercentageChange < 0 ? (
        <IconArrowDownSmall size='md' />
      ) : (
        <IconArrowUpSmall size='md' />
      )
  }

  return (
    <Card
      className='size-full border-gray-10 bg-gradient-to-b from-white from-50% to-blue-5 to-50% hover:border-gray-10 active:border-gray-10 p-0'
      variant='contained'
      highlight='bottom'
    >
      <Tooltip content={tooltipText} side='top'>
        <CardContent className='flex flex-col justify-start items-baseline rounded-b-md bg-white w-full py-8'>
          <div className='flex items-center gap-1'>
            <Typography variant='subtitle2'>{title}</Typography>
            {duration && onDurationChange && (
              <Select
                value={duration}
                onValueChange={(value) => onDurationChange(value as Duration)}
                options={durationOptions}
                variant='ghost'
                className='w-full text-blue-40 focus-within:bg-transparent hover:bg-transparent active:bg-transparent p-0 h-4'
                itemProps={{
                  className:
                    'bg-background-paper hover:bg-gray-10 data-[highlighted]:bg-gray-10 data-[state=selected]:bg-gray-10 text-text-primary'
                }}
              />
            )}
          </div>
          <div className='flex flex-row justify-start items-baseline gap-4 pt-2.5'>
            <Typography variant='h1'>{userCount}</Typography>
            {userPercentageChange && (
              <Typography
                variant='aLabelBold'
                component='span'
                className={cn(userPercentageChangeAttributes.color, 'flex items-center')}
              >
                {userPercentageChange}%{userPercentageChangeAttributes.icon}
              </Typography>
            )}
          </div>
          <Typography variant='body1' className='text-gray-60 min-h-6 flex items-baseline'>
            {subtitle}
          </Typography>
        </CardContent>
      </Tooltip>
      <Tooltip content={tooltipTextFooter}>
        <CardFooter className='gap-x-2'>
          <Typography variant='aLabelBold'>{teamCount}</Typography>
          <Typography variant='body1'>{teamText}</Typography>
        </CardFooter>
      </Tooltip>
    </Card>
  )
}
