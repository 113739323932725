import { Trans, t } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { ExternalLink, IconInfo, IconSanction, Typography, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { JsonRawDataTooltip } from '~/components/JsonRawDataTooltip'
import { AssociatedCompanyLink } from '~/features/PepAndSanctions/AssociatedCompanyLink'
import { extractSanctionInfoTitle } from '~/features/PepAndSanctions/pepAndSanctionsUtils'
import {
  type BaseEntityLikeFragment,
  type CompanyNetworkEntityPersonFragment,
  type SanctionInfoFragment
} from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

const extractDomain = (href: string): string => {
  try {
    const url = new URL(href)
    return url.hostname.replace(/^www\./, '')
  } catch {
    return href
  }
}

export const SanctionsEntityContent = ({
  associatedCompanies,
  className,
  currentCompanyId,
  entity,
  entityName,
  noLink,
  sanctionInfo,
  ...props
}: {
  associatedCompanies: CompanyNetworkEntityPersonFragment['associatedCompanies'] | null
  currentCompanyId: string | null
  entity: BaseEntityLikeFragment | null
  entityName: string | null | undefined
  noLink?: boolean
  sanctionInfo: SanctionInfoFragment
} & DivProps): ReactNode => {
  return (
    <div className={cn('flex flex-col gap-2', className)} {...props}>
      <div className='flex items-center gap-1'>
        {entity && !noLink ? (
          <EntityLink entity={entity} noFlags />
        ) : (
          <Typography>{entityName ?? t`Unknown`}</Typography>
        )}
        <Typography>{extractSanctionInfoTitle(sanctionInfo)}</Typography>
        {sanctionInfo.isSanctioned && <IconSanction className='text-semantic-danger-main' />}
      </div>
      {associatedCompanies && currentCompanyId && (
        <div className='flex flex-col'>
          {associatedCompanies.map((c) => (
            <AssociatedCompanyLink key={c.id} currentCompanyId={currentCompanyId} associatedCompany={c} />
          ))}
        </div>
      )}
      {!!sanctionInfo.sanctionedBy.length && (
        <div className='flex items-center gap-1' data-id={TestIDs.Common.Sanctions.sources}>
          <Typography className='w-fit' variant='aLabel'>
            {sanctionInfo.sanctionedBy.join(', ')}
          </Typography>
          <JsonRawDataTooltip json={sanctionInfo.sourceJson}>
            <IconInfo className='text-text-secondary' size='md' />
          </JsonRawDataTooltip>
        </div>
      )}
      {!!sanctionInfo.sourceLinks.length && (
        <div className='flex flex-col' data-id={TestIDs.Common.Sanctions.references}>
          <Typography className='mb-2 text-text-secondary' variant='aLabelSmall'>
            <Trans>References</Trans>
          </Typography>
          {sanctionInfo.sourceLinks.map((reference, index) => (
            <ExternalLink
              key={index}
              href={reference}
              className='text-sm leading-4 text-text-link'
              iconProps={{ size: 'xs' }}
            >
              {extractDomain(reference)}
            </ExternalLink>
          ))}
        </div>
      )}
    </div>
  )
}
