import { Trans, t } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { Typography, type TypographyProps, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { openChat } from '~/utils/intercom'

interface OpenChatLinkProps extends TypographyProps {
  msg?: string
}

export const OpenChatLink = ({ className, msg, ...props }: OpenChatLinkProps): ReactNode => (
  <Typography
    className={cn('cursor-pointer text-text-link underline', className)}
    onClick={() => openChat(msg)}
    {...props}
  />
)

export interface ContactStriseProps extends Omit<DivProps, 'variant'> {
  additionalText?: string
  intention?: string
  lowercase?: boolean
}

export const ContactStrise = ({
  additionalText,
  className,
  intention,
  lowercase,
  ...props
}: ContactStriseProps): ReactNode => {
  const msg = intention ? t`I want to ${intention}.` : undefined
  return (
    <OpenChatLink className={cn('cursor-pointer', className)} msg={msg} variant='body1' {...props}>
      {lowercase ? <Trans>contact Strise</Trans> : <Trans>Contact Strise</Trans>}
      {additionalText ? ` ${additionalText}` : null}
    </OpenChatLink>
  )
}

export const ContactStriseToEnable = ({
  className,
  intention,
  ...props
}: Omit<ContactStriseProps, 'additionalText' | 'palette'> & { intention: string }): ReactNode => {
  return <ContactStrise className={className} intention={intention} additionalText={t`to enable`} {...props} />
}
