import {
  type BlockType,
  type Format,
  isBlockActive,
  isMarkActive,
  toggleBlock,
  toggleMark
} from './richTextControlUtils'
import { type DivProps } from '@strise/react-utils'
import { objectKeys } from '@strise/ts-utils'
import {
  type ButtonProps,
  IconBold,
  IconBulletList,
  IconButton,
  IconItalic,
  IconUnderline,
  cn
} from '@strise/ui-components'
import type { ReactElement, ReactNode } from 'react'
import { useSlate } from 'slate-react'

const formatIconMap: Record<Format, ReactElement> = {
  bold: <IconBold />,
  italic: <IconItalic />,
  underline: <IconUnderline />
}

const blockIconMap: Record<BlockType, ReactElement> = {
  'bulleted-list': <IconBulletList />
}

const ControlButton = ({ active, className, onClick, ...props }: ButtonProps & { active: boolean }): ReactNode => {
  return (
    <IconButton
      className={cn('mr-2 size-6 rounded', active ? 'bg-secondary-shade-20' : 'bg-secondary-shade-5', className)}
      type='button'
      variant='contained'
      palette='tertiary'
      onMouseDown={(e) => {
        e.preventDefault()
        onClick?.(e)
      }}
      {...props}
    />
  )
}

const MarkButton = ({ className, format, ...props }: { format: Format } & ButtonProps): ReactNode => {
  const editor = useSlate()

  const isActive = isMarkActive(editor, format)

  const icon = formatIconMap[format]

  return (
    <ControlButton className={className} onClick={() => toggleMark(editor, format)} active={isActive} {...props}>
      {icon}
    </ControlButton>
  )
}

const BlockButton = ({ className, format, ...props }: { format: BlockType } & ButtonProps): ReactNode => {
  const editor = useSlate()

  const isActive = isBlockActive(editor, format)

  const icon = blockIconMap[format]

  return (
    <ControlButton className={className} onClick={() => toggleBlock(editor, format)} active={isActive} {...props}>
      {icon}
    </ControlButton>
  )
}

export const RichTextControls = ({
  buttonProps,
  className,
  ...props
}: { buttonProps?: ButtonProps } & DivProps): ReactNode => {
  return (
    <div className={className} {...props}>
      {objectKeys(formatIconMap).map((format) => (
        <MarkButton key={format} format={format} {...buttonProps} />
      ))}
      {objectKeys(blockIconMap).map((blockType) => (
        <BlockButton key={blockType} format={blockType} {...buttonProps} />
      ))}
    </div>
  )
}
