import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconBusinessPerson = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M18 7a5.99 5.99 0 0 1-2.357 4.768A9.002 9.002 0 0 1 21 20v3h-2v-3a7 7 0 1 0-14 0v3H3v-3a9.002 9.002 0 0 1 5.357-8.232A5.99 5.99 0 0 1 6 7H3V5h3V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v3h3v2h-3ZM8 7a4 4 0 1 0 8 0H8Zm8.006-2V3.001H7.994V5h8.012Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconBusinessPerson.displayName = 'IconBusinessPerson'
