import { ReactRouterLink } from '@strise/app-shared'
import { toTitleCase } from '@strise/ts-utils'
import { ExternalLink, Typography, type LinkProps as UILinkProps, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { type EntityLinkProps } from '~/components/EntityLink/entityLinkUtils'
import { FavIcon } from '~/components/FavIcon'
import { ContentViews, eventPath } from '~/utils/urls'
import { useActiveContentView } from '~/utils/viewsHooks'

export const EventLink = ({
  className,
  entityId,
  eventId,
  mentions,
  ...props
}: {
  entityId: string
  eventId: string
  mentions?: string[]
} & EntityLinkProps): ReactNode => {
  const activeContentView = useActiveContentView()
  const location = useLocation()
  const homeView = ContentViews.Home

  return (
    <ReactRouterLink
      className={className}
      to={eventPath(activeContentView ?? homeView, entityId, eventId, location.search, mentions)}
      {...props}
    />
  )
}

export const PublisherLink = ({ children, className, href, variant, ...props }: UILinkProps): ReactNode => {
  if (!href) return null
  if (href.includes('paper')) {
    const publisher = href.split('.')[1]
    return (
      <Typography className={className} {...props}>
        {publisher && toTitleCase(publisher)}, print
      </Typography>
    )
  }
  return (
    <ExternalLink className={cn('flex items-center gap-1', className)} variant={variant} href={href} {...props}>
      <FavIcon publisher={href} className='w-4 shrink-0' />
      <span className='block'>{children}</span>
    </ExternalLink>
  )
}
