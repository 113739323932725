import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconCalculator = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 32 32'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M7 3h18v26H7V3zm2 2v22h14V5H9zm2 11h2v2h-2v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2zm-10 4h2v2h-2v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2zm-10 4h2v2h-2v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z'
        clipRule='evenodd'
      />
      <path fill='currentColor' fillRule='evenodd' d='M11 7h9.9v6.1L11 13.1V7zm2 2v2.1h5.9V9H13z' clipRule='evenodd' />
    </svg>
  )
)
IconCalculator.displayName = 'IconCalculator'
