import { Box, type BoxProps } from './Box'
import { ButtonDropdown, type ButtonDropdownProps } from './ButtonDropdown'
import { Popper, type PopperProps } from './Popper'
import { type DivProps, getBrowserGlobals } from '@strise/react-utils'
import { cn } from '@strise/ui-components'
import { isFunction } from 'lodash-es'
import type { MouseEvent, MutableRefObject, ReactElement, ReactNode, TouchEvent } from 'react'
import { cloneElement, isValidElement, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useOnClickOutside } from 'usehooks-ts'

export type DropDownToggleEvent = MouseEvent<Document | HTMLElement> | TouchEvent<Document | HTMLElement>
export type DropdownToggleFn = (event?: DropDownToggleEvent, preventDefault?: boolean) => void
export type DropdownChildren = ReactNode | (({ toggle }: { toggle: DropdownToggleFn }) => ReactNode)
export type DropdownTrigger = 'click' | 'hover'

export interface DropdownProps extends Omit<BoxProps, 'children' | 'onToggle'> {
  ToggleComponent?: ReactElement<{
    open: boolean
    ref: MutableRefObject<object | null>
  }>
  backdrop?: boolean
  buttonProps?: ButtonDropdownProps
  buttonText?: ReactNode
  children: DropdownChildren
  hoverDelay?: number
  menu?: boolean
  onOpen?: () => void
  onOutsideClick?: () => void
  onToggle?: (open: boolean, event?: DropDownToggleEvent) => void
  paperProps?: DivProps
  popperProps?: PopperProps
  trigger?: DropdownTrigger
}

export const Dropdown = ({
  ToggleComponent,
  backdrop = false,
  buttonProps,
  buttonText,
  children,
  hoverDelay = 0,
  menu = false,
  onOpen,
  onOutsideClick,
  onToggle,
  paperProps,
  popperProps,
  trigger = 'click',
  ...props
}: DropdownProps): ReactNode => {
  const [open, setOpen] = useState(false)
  const [hoverOpen, setHoverOpen] = useState(false)
  const hoverTimeout = useRef<number | null>(null)
  const toggleRef = useRef(null)

  useEffect(() => {
    if (open && onOpen) onOpen()
    // onOpen might not be memoized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (hoverTimeout.current) {
      getBrowserGlobals()?.window.clearTimeout(hoverTimeout.current)
    }

    if (hoverOpen) {
      hoverTimeout.current =
        getBrowserGlobals()?.window.setTimeout(() => {
          setOpen(true)
        }, hoverDelay) || null
    } else {
      setOpen(false)
    }

    return () => {
      if (hoverTimeout.current) {
        getBrowserGlobals()?.window.clearTimeout(hoverTimeout.current)
      }
    }
  }, [hoverOpen, hoverDelay])

  const hover = trigger === 'hover'

  const toggle: DropdownToggleFn = (event, preventDefault: boolean = true) => {
    if (event) {
      // We can disable preventDefault to make urls work
      if (preventDefault) event.preventDefault()
      event.stopPropagation()
    }
    if (!children) return
    if (onToggle) onToggle(!open, event)
    setOpen((current) => !current)
  }

  const handleClickOutside = (): void => {
    // Avoid closing when clicking on a modal
    const modalOpen = browserGlobals?.document.querySelector('body [role="dialog"]')
    // Avoid closing parent dropdown when clicking on child dropdown items
    const openDropdownChildren = containerRef.current?.querySelector('[data-dropdown-open="true"]')
    if (!modalOpen && !openDropdownChildren) {
      setOpen(false)
      if (onOutsideClick) onOutsideClick()
    }
  }

  const Toggle =
    ToggleComponent && isValidElement(ToggleComponent)
      ? cloneElement(ToggleComponent, {
          ...(hover ? undefined : { onClick: toggle }),
          open,
          ref: toggleRef
        })
      : ToggleComponent

  const containerRef = useRef<HTMLDivElement>(null)
  const clickOutsideRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(clickOutsideRef, handleClickOutside, 'mousedown', { capture: true })
  const browserGlobals = getBrowserGlobals()

  return (
    <Box
      position='relative'
      onMouseEnter={
        children && hover
          ? () => {
              setHoverOpen(true)
            }
          : undefined
      }
      onMouseLeave={
        children && hover
          ? () => {
              setHoverOpen(false)
            }
          : undefined
      }
      onTouchEnd={children && hover ? toggle : undefined}
      ref={containerRef}
      data-dropdown-open={open}
      {...props}
    >
      {Boolean(Toggle) && Toggle}
      {!Toggle && (
        <ButtonDropdown ref={toggleRef} onClick={toggle} indicator={open ? 'open' : true} {...buttonProps}>
          {buttonText}
        </ButtonDropdown>
      )}

      {browserGlobals &&
        backdrop &&
        open &&
        createPortal(
          <div
            onClick={(event) => event.preventDefault()}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: (popperProps?.zIndex ?? 10) - 1
            }}
          />,

          browserGlobals.document.body
        )}

      <Popper anchorEl={toggleRef.current} open={open} {...popperProps}>
        <Box ref={clickOutsideRef}>
          <div
            {...paperProps}
            className={cn('min-w-[200px] bg-secondary-shade-90 text-white shadow-md', paperProps?.className)}
            onClick={menu ? toggle : undefined}
          >
            {isFunction(children) ? children({ toggle }) : children}
          </div>
        </Box>
      </Popper>
    </Box>
  )
}
