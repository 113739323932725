'use client'

import { cn } from '../../utils/className'
import { composeRefs } from '@strise/react-utils'
import { type ReactNode, type TextareaHTMLAttributes, forwardRef, useCallback, useEffect, useRef } from 'react'

export interface TextAreaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onInput'> {
  autoResize?: boolean
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ autoResize, className, value, ...props }, ref): ReactNode => {
    const resizeRef = useRef<HTMLTextAreaElement | null>(null)

    const handleResize = useCallback(() => {
      if (!autoResize) return
      if (!resizeRef.current) return

      // Temporarily shrink to get the scroll height
      resizeRef.current.style.height = '0px'
      const scrollHeight = resizeRef.current.scrollHeight
      resizeRef.current.style.height = `${scrollHeight}px`
    }, [autoResize])

    // Trigger handleResize on all controlled value changes, so it does not only trigger on user input (ex. AI generated text)
    useEffect(() => {
      handleResize()
    }, [value, handleResize])

    return (
      <textarea
        ref={composeRefs(ref, resizeRef)}
        className={cn(
          'min-h-[100px] w-full border-2 border-secondary-shade-10 p-2',
          { 'resize-none overflow-hidden': autoResize },
          className
        )}
        value={value}
        {...props}
      />
    )
  }
)

TextArea.displayName = 'TextArea'
