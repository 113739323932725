import { colors } from '@strise/ui-components-legacy'
import { creditScoreColorIntervals, finnishCreditScoreColorIntervals } from '~/features/CreditReport/creditScore'

export const scoreToColor = (score: number | null | undefined, depth: 50 | 100 | 10, isFinnish: boolean): string => {
  if (!score) return colors.gray[10]

  const colorIntervals = isFinnish ? finnishCreditScoreColorIntervals : creditScoreColorIntervals

  const matchingInterval = colorIntervals.find((interval) => score >= interval.from && score <= interval.to)

  if (!matchingInterval) return colors.gray[10]

  return matchingInterval.color[depth]
}
