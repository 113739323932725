import { EntityFlag as AppSharedEntityFlag, type FlagEntity } from '@strise/app-shared'
import { type DivProps } from '@strise/react-utils'
import { type ChipProps } from '@strise/ui-components'
import type { ReactNode } from 'react'

interface EntityFlagProps {
  chipProps?: ChipProps
  entityOrId: FlagEntity | string
}

export const EntityFlag = ({ chipProps, className, entityOrId, ...props }: EntityFlagProps & DivProps): ReactNode => {
  return <AppSharedEntityFlag className={className} entityOrId={entityOrId} chipProps={chipProps} {...props} />
}
