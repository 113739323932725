import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconImport = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M3 21v-9H1v11h22V12h-2v9H3Z' clipRule='evenodd' />
      <path
        fill='currentColor'
        d='m5.293 9.293 1.414-1.414L11 12.172V.586h2v11.586l4.293-4.293 1.414 1.414L12 16 5.293 9.293Z'
      />
    </svg>
  )
)
IconImport.displayName = 'IconImport'
