import { t } from '@lingui/macro'
import { ellipsis, toTitleCase } from '@strise/ts-utils'
import { Chip, type ChipProps } from '@strise/ui-components'
import type { ReactNode } from 'react'

export const IdNameChip = ({
  className,
  maxLength = 30,
  titleCase,
  value,
  ...props
}: {
  maxLength?: number
  titleCase?: boolean
  value: {
    id: string
    name?: string | null
  }
} & Omit<ChipProps, 'value'>): ReactNode => {
  const entityName = value.name ?? t`Unknown`
  const transformedEntityName = titleCase ? toTitleCase(entityName) : entityName
  const label = ellipsis(transformedEntityName, maxLength)
  return <Chip className={className} label={label} {...props} />
}
