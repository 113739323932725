import { Trans, t } from '@lingui/macro'
import { extractEntityContentLanguage, extractIsCompany, extractIsGlobalV2 } from '@strise/app-shared'
import { type FeaturesMap } from '@strise/app-shared/src/i18n'
import { type SetStateFn, setChildState, useContext } from '@strise/react-utils'
import { ApplicationSearchReturnType, type TimespanInput } from '@strise/types'
import { Button, IconCross, TextArea, Typography } from '@strise/ui-components'
import { ConfirmDialog, DatePicker } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { EntitySearchInput } from '~/components/Entity/EntitySearchInput'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { type SupportedSidepanelEntityFragment } from '~/components/Sidepanel/utils/sidepanelUtils'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useTeamSettings } from '~/contexts/TeamSettingsContext/TeamSettingsContext'
import { StepContent } from '~/features/Ownerships/edit-owner/StepInfo'
import { RoleTitlesCombobox } from '~/features/Roles/RoleTitlesCombobox'
import { type RoleFormData } from '~/features/Roles/SidepanelRolesCard'
import { SearchEntityKindFilter } from '~/features/Search/SearchEntityKindFilter'
import { EntityLocationFilterKind } from '~/features/Search/searchUtils'
import { useRoleTitlesQuery, useUpdateEntityRoleMutation } from '~/graphqlOperations'
import { type BaseEntityLikeFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

export interface EditRoleDialogProps {
  entity: SupportedSidepanelEntityFragment
  formData: RoleFormData
  handleUpdate: () => void
  isExistingRole: boolean
  isOpen: boolean
  setFormData: SetStateFn<RoleFormData>
  setIsOpen: SetStateFn<boolean>
  showHistoric: boolean
}

enum AddRoleStep {
  SEARCH_ENTITY = 'SEARCH_ENTITY',
  SET_ROLES = 'SET_ROLES'
}

const extractEntityLocationFilter = (features: FeaturesMap): EntityLocationFilterKind[] => {
  // eslint-disable-next-line functional/no-let
  let locationFilters = [EntityLocationFilterKind.NORDICS]

  if (features.CONTENT_UNITED_KINGDOM) {
    locationFilters = [...locationFilters, EntityLocationFilterKind.UK]
  }

  if (features.CONTENT_GERMAN) {
    locationFilters = [...locationFilters, EntityLocationFilterKind.GERMANY]
  }

  if (features.GLOBAL_ENTITIES_V2) {
    locationFilters = [...locationFilters, EntityLocationFilterKind.GLOBAL]
  }

  return locationFilters
}

const SearchEntityStep = ({
  selectedEntity,
  setSelectedEntity
}: {
  selectedEntity: BaseEntityLikeFragment | null
  setSelectedEntity: SetStateFn<BaseEntityLikeFragment | null>
}): ReactNode => {
  const features = useCurrentUserFeatures()
  const { data: { entityTypes = [ApplicationSearchReturnType.All] } = {} } = useTeamSettings()
  const [entityKindFilter, setEntityKindFilter] = useState<ApplicationSearchReturnType>(ApplicationSearchReturnType.All)

  return (
    <div className='flex items-center'>
      <SearchEntityKindFilter entityTypes={entityTypes} state={entityKindFilter} setState={setEntityKindFilter} />
      <EntitySearchInput
        variant='outlined'
        entityKindFilter={entityKindFilter}
        entityLocationFilters={extractEntityLocationFilter(features)}
        selectedEntity={selectedEntity}
        setSelectedEntity={setSelectedEntity}
        dataTrack='Add Roles / Search Entity'
        data-id={TestIDs.SidePanel.Roles.searchEntityTrigger}
        itemsWrapperProps={{ 'data-id': TestIDs.SidePanel.Roles.searchEntityResults }}
        inputProps={{ 'data-id': TestIDs.SidePanel.Roles.searchEntityInput }}
      />
    </div>
  )
}

const DeleteRoleDialog = ({
  entity,
  handleCancel,
  handleDeletion,
  role,
  roleEntity
}: {
  entity: BaseEntityLikeFragment
  handleCancel: () => void
  handleDeletion: () => void
  role: string
  roleEntity: BaseEntityLikeFragment
}): ReactNode => (
  <ConfirmDialog
    isOpen
    onConfirm={handleDeletion}
    danger
    onClose={handleCancel}
    onCancel={handleCancel}
    cancelButtonProps={{
      'data-track': 'Edit Roles / Delete role / Cancel'
    }}
    confirmButtonProps={{
      'data-track': 'Edit Roles / Delete role / Confirm',
      'data-id': TestIDs.SidePanel.Roles.removeRoleConfirmButton
    }}
    contentMaxWidth={600}
    title={t`Are you sure you want to remove ${roleEntity.name} as ${role} of ${entity.name}?`}
    confirmText={t`Delete`}
    cancelText={t`Cancel`}
  >
    <Typography>
      <Trans>The original version of the role table is always available.</Trans>
    </Typography>
  </ConfirmDialog>
)

const extractTitle = (entity: SupportedSidepanelEntityFragment): string => {
  if (extractIsCompany(entity)) {
    return t`Edit role in ${entity.name}`
  }
  return t`Edit role for ${entity.name}`
}

export const EditRoleDialog = ({
  entity,
  formData,
  handleUpdate,
  isExistingRole,
  isOpen,
  setFormData,
  setIsOpen,
  showHistoric
}: EditRoleDialogProps): ReactNode => {
  const isCompany = extractIsCompany(entity)
  const country = extractEntityContentLanguage(entity)
  const useGlobal = extractIsGlobalV2(entity)
  const { showNetworkRisk } = useContext(SidepanelContext)

  const { data, loading: loadingRoleTitles } = useRoleTitlesQuery({
    variables: {
      country,
      useGlobal
    }
  })

  const selectedRoleTitle = data?.roleTitles.find((role) => role.id === formData.roleTitleId)

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState<AddRoleStep | null>(AddRoleStep.SEARCH_ENTITY)

  const handleChange =
    (key: keyof TimespanInput) =>
    (date: Date | null): void => {
      setFormData((prev) => {
        if (!date) {
          return {
            ...prev,
            period: {
              ...prev.period,
              [key]: null
            }
          }
        }

        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1, 12))
        return {
          ...prev,
          period: {
            ...prev.period,
            [key]: utcDate.toISOString()
          }
        }
      })
    }

  const [update, { loading: loadingUpdate }] = useUpdateEntityRoleMutation({})

  const onUpdate = async (shouldDelete: boolean): Promise<void> => {
    if (!formData.entity || !formData.roleTitleId) return
    // if we are in person sidepanel, we need to swap the entity and assigned entity
    const roleEntity = isCompany ? formData.entity.id : entity.id
    const assignedEntity = isCompany ? entity.id : formData.entity.id
    await update({
      variables: {
        input: {
          entity: roleEntity,
          role: {
            roleTitleId: formData.roleTitleId,
            period: {
              from: formData.period?.from,
              to: formData.period?.to
            }
          },
          hidden: shouldDelete,
          comment: formData.comment
        },
        entity: assignedEntity,
        sidepanelEntity: assignedEntity,
        filterActive: showHistoric ? undefined : true,
        includeNetworkRoles: showNetworkRisk
      }
    })
    setIsOpen(false)
    setDeleteDialogOpen(false)
    handleUpdate()
  }
  const onClose = (): void => {
    setFormData({
      entity: null,
      period: null,
      roleTitleId: null,
      comment: null
    })
    setIsOpen(false)
  }

  const steps = [
    {
      step: AddRoleStep.SEARCH_ENTITY,
      title: (
        <SearchEntityStep
          setSelectedEntity={setChildState(setFormData, 'entity')}
          selectedEntity={formData.entity ?? null}
        />
      ),
      isCompleted: !!formData.entity
    },
    {
      step: AddRoleStep.SET_ROLES,
      title: (
        <div>
          <Typography variant='subtitle1'>
            <Trans>Set roles</Trans>
          </Typography>
          <Typography variant='body2'>
            {isCompany ? (
              <Trans>Role options are determined by the company's country.</Trans>
            ) : (
              <Trans>Role options are determined by the person's nationality.</Trans>
            )}
          </Typography>
        </div>
      ),
      content: (
        <div className='grid gap-4 rounded border border-gray-15 p-4'>
          <div className='grid grid-cols-[200px_1fr] items-center'>
            <Typography variant='aLabel' className='text-gray-40'>
              <Trans>Role</Trans>
              <span className='text-semantic-danger-main'>*</span>
            </Typography>
            <RoleTitlesCombobox
              isCompany={isCompany}
              isLoading={loadingRoleTitles}
              onAdd={(role) => {
                setFormData((prev) => ({
                  ...prev,
                  roleTitleId: role.id
                }))
              }}
              roleTitles={data?.roleTitles ?? []}
              selectedRoleTitle={selectedRoleTitle}
            />
          </div>
          <div className='grid h-[40px] grid-cols-[200px_1fr] items-center'>
            <Typography variant='aLabel' className='text-gray-40'>
              <Trans>Date</Trans>
            </Typography>
            <div className='grid grid-cols-2 gap-4'>
              <div className='flex gap-2'>
                <DatePicker
                  onChange={handleChange('from')}
                  selected={formData.period?.from ? new Date(formData.period.from) : null}
                  showMonthYearPicker
                  dateFormat='MMMM yyyy'
                  className='w-full'
                  placeholderText={t`From`}
                  data-id={TestIDs.SidePanel.Roles.fromDateInput}
                />
                <Button
                  data-track='Edit Roles / Reset From Period'
                  variant='ghost'
                  className='h-fit p-2'
                  onClick={() => handleChange('from')(null)}
                >
                  <IconCross size='xs' />
                </Button>
              </div>
              <div className='flex gap-2'>
                <DatePicker
                  onChange={handleChange('to')}
                  selected={formData.period?.to ? new Date(formData.period.to) : null}
                  showMonthYearPicker
                  dateFormat='MMMM yyyy'
                  minDate={formData.period?.from ? new Date(formData.period.from) : null}
                  maxDate={new Date()}
                  className='w-full min-w-[100px]'
                  placeholderText={t`To`}
                  customInput={
                    formData.period?.from && !formData.period.to ? (
                      <div className='w-full'>
                        <Trans>Present</Trans>
                      </div>
                    ) : null
                  }
                />
                <Button
                  data-track='Edit Roles / Reset To Period'
                  variant='ghost'
                  className='h-fit p-2'
                  onClick={() => handleChange('to')(null)}
                >
                  <IconCross size='xs' />
                </Button>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-[200px_1fr] items-center'>
            <Typography variant='aLabel' className='self-start text-gray-40'>
              <Trans>Comment</Trans>
            </Typography>
            <div>
              <TextArea
                value={formData.comment ?? ''}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    comment: e.target.value
                  }))
                }}
              />
            </div>
            {isExistingRole && (
              <div>
                <Button
                  data-track='Edit Role / Remove Role'
                  onClick={() => {
                    setDeleteDialogOpen(true)
                  }}
                  variant='ghost'
                  palette='danger'
                  data-id={TestIDs.SidePanel.Roles.removeRoleButton}
                >
                  <Trans>Remove role</Trans>
                </Button>
                {deleteDialogOpen && selectedRoleTitle?.id && formData.entity && (
                  <DeleteRoleDialog
                    handleCancel={() => setDeleteDialogOpen(false)}
                    handleDeletion={async () => await onUpdate(true)}
                    role={selectedRoleTitle.name}
                    roleEntity={isCompany ? formData.entity : entity}
                    entity={isCompany ? entity : formData.entity}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ),
      isCompleted: !!formData.roleTitleId && !!formData.entity
    }
  ]

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title={extractTitle(entity)}
      onConfirm={async () => await onUpdate(false)}
      onCancel={onClose}
      confirmText={t`Save and close`}
      loading={loadingUpdate}
      contentMaxWidth={800}
      containerProps={{ className: 'bg-gray-5' }}
      cancelText={t`Cancel`}
      confirmButtonProps={{
        palette: 'primary',
        'data-track': `Edit Roles Dialog / ${isCompany ? 'Company' : 'Person'} / Confirm`,
        'data-id': TestIDs.SidePanel.Roles.editRoleConfirmButton,
        disabled: steps.some((step) => !step.isCompleted)
      }}
      cancelButtonProps={{ 'data-track': `Edit Roles Dialog / ${isCompany ? 'Company' : 'Person'} / Cancel` }}
    >
      {steps.map((stepInfo, index) => {
        return (
          <StepContent
            key={index}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepInfo={stepInfo}
            previousStepInfo={steps[index - 1]}
            index={index}
          />
        )
      })}
    </ConfirmDialog>
  )
}
