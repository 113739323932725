import { type TypePolicies } from '@apollo/client'
import {
  type CompanyStickerConnectionEdge,
  type NotificationConnection,
  type PortfolioCompaniesArgs,
  type PortfolioCompanyConnection,
  type QueryListViewArgs,
  type QueryNotificationsArgs,
  type QueryTeamActivitiesArgs,
  type SimpleCompanyConnection,
  type StickerConnectionEdge,
  type TeamActivityConnection
} from '@strise/types'
import { eventsCache } from '~/features/Events/eventsCache'
import { type ConflictsQuery } from '~/graphqlTypes'
import { newCompaniesState } from '~/state'

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      listView: {
        keyArgs: false,
        // @ts-expect-error
        merge(
          existing: SimpleCompanyConnection | undefined,
          incoming: SimpleCompanyConnection | undefined,
          { args }: { args: QueryListViewArgs | null }
        ): SimpleCompanyConnection | undefined {
          if (!existing) return incoming
          if (!incoming) return existing

          const offset: number | null = args?.page?.offset ?? null
          if (offset === null) return existing

          const newEdges = incoming.edges

          if (offset === 0) {
            newCompaniesState([])
            return { ...incoming, edges: newEdges, __typename: 'SimpleCompanyConnection' }
          }

          const newCompanies = newCompaniesState()
          const edges = existing.edges

          const updatedEdges = [
            ...edges.slice(0, offset + newCompanies.length),
            ...newEdges,
            ...edges.slice(offset + newCompanies.length + newEdges.length)
          ]

          return { ...incoming, edges: updatedEdges }
        }
      },
      notifications: {
        keyArgs: false,
        merge(
          existing: NotificationConnection | undefined,
          incoming: NotificationConnection | undefined,
          { args }: { args: QueryNotificationsArgs | null }
        ): NotificationConnection | undefined {
          if (!existing) return incoming
          if (!incoming) return existing

          const offset = args?.offset ?? 0

          if (offset === 0) {
            return { ...incoming, edges: incoming.edges }
          }

          return {
            ...existing,
            edges: [...existing.edges, ...incoming.edges]
          }
        }
      },
      teamActivities: {
        keyArgs: ['reminderStatus', 'filter', 'team', 'user'],
        // @ts-expect-error
        merge(
          existing: TeamActivityConnection | undefined,
          incoming: TeamActivityConnection | undefined,
          { args }: { args: QueryTeamActivitiesArgs | null }
        ): TeamActivityConnection | undefined {
          if (!existing) return incoming
          if (!incoming) return existing

          const offset = args?.page.offset ?? 0

          if (offset === 0) {
            return { ...incoming, edges: incoming.edges }
          }

          return {
            ...existing,
            edges: [...existing.edges, ...incoming.edges]
          }
        }
      }
    }
  },
  Company: {
    fields: {
      teamActivities: {
        keyArgs: ['reminderStatus', 'filter', 'team', 'id'],
        // @ts-expect-error
        merge(
          existing: TeamActivityConnection | undefined,
          incoming: TeamActivityConnection | undefined,
          { args }: { args: QueryTeamActivitiesArgs | null }
        ): TeamActivityConnection | undefined {
          if (!existing) return incoming
          if (!incoming) return existing

          const offset = args?.page.offset ?? 0

          if (offset === 0) {
            return { ...incoming, edges: incoming.edges }
          }

          return {
            ...existing,
            edges: [...existing.edges, ...incoming.edges]
          }
        }
      },
      events: eventsCache,
      conflicts: {
        keyArgs: ['team', 'resolved'],
        merge(_, incoming: ConflictsQuery): ConflictsQuery {
          // Always return the incoming conflicts list
          return incoming
        }
      }
    }
  },
  CounterParty: {
    keyFields: ['id', 'direction']
  },
  OwnershipChartNode: {
    // isLeaf can change between custom and official ownership charts. It's used to determine if a node can be connected to other nodes.
    keyFields: ['id', 'indirectShareValue', 'isLeaf']
  },
  Person: {
    fields: {
      events: eventsCache
    }
  },
  Location: {
    fields: {
      events: eventsCache
    }
  },
  Topic: {
    fields: {
      events: eventsCache
    }
  },
  Industry: {
    fields: {
      events: eventsCache
    }
  },
  Portfolio: {
    fields: {
      companies: {
        keyArgs: ['filter'],
        merge(
          existing: PortfolioCompanyConnection | undefined,
          incoming: PortfolioCompanyConnection | undefined,
          { args }: { args: PortfolioCompaniesArgs | null }
        ): PortfolioCompanyConnection | undefined {
          if (!existing) return incoming
          if (!incoming) return existing

          const offset = args?.offset ?? 0

          if (offset === 0) {
            return { ...incoming, edges: incoming.edges }
          }

          return {
            ...existing,
            edges: [...existing.edges, ...incoming.edges]
          }
        }
      }
    }
  },
  CompanyStickerConnection: {
    keyFields: ['key'],
    fields: {
      edges(existing: CompanyStickerConnectionEdge[] | undefined, { canRead }): CompanyStickerConnectionEdge[] {
        return (existing || []).filter(({ node }) => canRead(node))
      }
    }
  },
  StickerConnection: {
    fields: {
      edges(existing: StickerConnectionEdge[] | undefined, { canRead }): StickerConnectionEdge[] {
        return (existing || []).filter(({ node }) => canRead(node))
      }
    }
  },
  Address: {
    merge: true
  },
  ReviewCompanyData: {
    // We need to do this as we fetch multiple queries in parallel and they end up overwriting eachother
    keyFields: ['id', 'includeSections', 'excludeSections']
  },
  // Custom key fields as multiple persons can be listed with the same pep id, but with different match info analysis
  // A longer term solution is to create an edge between the pep id and the person and have the match info analysis and disposition there
  PepInfo: {
    keyFields: (pepInfo) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions,@typescript-eslint/no-base-to-string
      return `${pepInfo.id}-${JSON.stringify(pepInfo.matchInfoAnalysis ?? {})}`
    }
  },
  // Custom key fields as multiple persons can be listed with the same pep id, but with different match info analysis
  // A longer term solution is to create an edge between the pep id and the person and have the match info analysis and disposition there
  ReviewPepInfo: {
    keyFields: (pepInfo) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions,@typescript-eslint/no-base-to-string
      return `${pepInfo.id}-${JSON.stringify(pepInfo.matchInfoAnalysis ?? {})}`
    }
  }
}
