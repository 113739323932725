/**
 * PieChartDonut Component
 *
 * A reusable donut chart component built on top of Recharts that provides:
 * - Consistent styling and theming
 * - Configurable center label and total
 * - Customizable tooltips and legends
 * - Responsive layout
 * - Accessibility support
 *
 * @example
 * ```tsx
 * <PieChartDonut
 *   data={data}
 *   config={config}
 *   dataKey="visitors"
 *   nameKey="browser"
 *   showTotal
 * />
 * ```
 */
import { cn } from '../../utils/className'
import {
  type ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent
} from './Chart'
import { isNumber } from 'lodash-es'
import type { ComponentProps, ReactNode } from 'react'
import { useMemo } from 'react'
import type { PieProps } from 'recharts'
import { Cell, Label, Pie, PieChart as RechartsPieChart } from 'recharts'
import type { PolarViewBox } from 'recharts/types/util/types'

// Types for center label configuration
interface CenterLabelProps extends Omit<ComponentProps<'text'>, 'viewBox'> {
  config: ChartConfig
  dataKey: string
  total: number | null
  viewBox: PolarViewBox
}

// Types for tooltip configuration
interface TooltipConfig {
  hideIndicator?: boolean
  hideLabel?: boolean
  indicator?: 'line' | 'dot' | 'dashed'
}

export interface PieChartDonutProps extends ComponentProps<'div'> {
  /** Optional className for container styling */
  className?: string
  /** Configuration object for styling and labeling */
  config: ChartConfig
  /** Array of data objects to be displayed in the chart */
  data: Array<Record<string, string | number>>
  /** Key in data object that contains the values */
  dataKey: string
  /** Inner radius of the donut (default: 60) */
  innerRadius?: number
  /** Key in data object that contains the names */
  nameKey: string
  /** Outer radius of the donut (default: 80) */
  outerRadius?: number
  /** Spacing angle between segments (default: 0) */
  paddingAngle?: number
  /** Whether to show the center label (default: true) */
  showLabel?: boolean
  /** Whether to show the legend (default: false) */
  showLegend?: boolean
  /** Whether to show total in center (default: true) */
  showTotal?: boolean
  /** Custom tooltip configuration */
  tooltipProps?: TooltipConfig
}

const CenterLabel = ({ config, dataKey, total, viewBox, ...props }: CenterLabelProps): ReactNode => {
  if (!viewBox.cx || !viewBox.cy) return null

  return (
    <text x={viewBox.cx} y={viewBox.cy - 5} textAnchor='middle' dominantBaseline='middle' {...props}>
      <tspan className='fill-gray-50 text-xs'>{config[dataKey]?.label || 'Total'}</tspan>
      <tspan
        x={viewBox.cx}
        y={viewBox.cy + 5}
        textAnchor='middle'
        dominantBaseline='middle'
        className='text-sm font-bold'
      >
        {total?.toLocaleString()}
      </tspan>
    </text>
  )
}

export function PieChartDonut({
  className,
  config,
  data,
  dataKey,
  innerRadius = 60,
  nameKey,
  outerRadius = 80,
  paddingAngle = 0,
  showLabel = true,
  showLegend = false,
  showTotal = true,
  tooltipProps,
  ...props
}: PieChartDonutProps): JSX.Element {
  // Calculate total if needed
  const total = useMemo(() => {
    if (!showTotal) return null
    return data.reduce((acc, curr) => {
      const value = curr[dataKey]
      return acc + (isNumber(value) ? value : 0)
    }, 0)
  }, [data, dataKey, showTotal])

  // Prepare pie chart props
  const pieProps = {
    data,
    dataKey,
    nameKey,
    innerRadius,
    outerRadius,
    strokeWidth: 0,
    strokeLinejoin: 'round' as const,
    paddingAngle
  } satisfies Required<
    Pick<
      PieProps,
      'data' | 'dataKey' | 'nameKey' | 'innerRadius' | 'outerRadius' | 'strokeWidth' | 'strokeLinejoin' | 'paddingAngle'
    >
  >

  return (
    <ChartContainer config={config} className={cn(className)} {...props}>
      <RechartsPieChart>
        <ChartTooltip
          content={
            <ChartTooltipContent
              hideLabel={tooltipProps?.hideLabel}
              hideIndicator={tooltipProps?.hideIndicator}
              indicator={tooltipProps?.indicator}
            />
          }
        />

        <Pie {...pieProps}>
          {data.map((entry, index) => {
            const key = entry[nameKey] as string
            const color = config[key]?.color || `var(--chart-${index + 1})`
            return <Cell key={`cell-${key}`} fill={color} />
          })}

          {showLabel && showTotal && (
            <Label
              /* eslint-disable-next-line react/no-unstable-nested-components */
              content={(labelProps): ReactNode => (
                <CenterLabel
                  viewBox={labelProps.viewBox as PolarViewBox}
                  total={total}
                  dataKey={dataKey}
                  config={config}
                />
              )}
            />
          )}
        </Pie>

        {showLegend && <ChartLegend content={<ChartLegendContent nameKey={nameKey} />} />}
      </RechartsPieChart>
    </ChartContainer>
  )
}
