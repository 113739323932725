import { NetworkStatus, useApolloClient } from '@apollo/client/index.js'
import { t } from '@lingui/macro'
import { Empty } from '@strise/app-shared'
import { type DivProps } from '@strise/react-utils'
import { IconButton, IconRefresh, Tooltip, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { IDVCheckItem } from '~/features/IDVCheck/IDVCheckItem'
import { updateReviewIdvRolesCache } from '~/features/IDVCheck/idvCacheUtils'
import { useIdvRolesQuery } from '~/graphqlOperations'
import { type CompanyBaseFragment } from '~/graphqlTypes'

export const SidepanelIDVCard = ({ className, company }: { company: CompanyBaseFragment } & DivProps): ReactNode => {
  const apolloClient = useApolloClient()

  const {
    data: idvData,
    loading,
    networkStatus,
    refetch
  } = useIdvRolesQuery({
    variables: {
      entity: company.id
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      updateReviewIdvRolesCache({
        cache: apolloClient.cache,
        companyId: company.id,
        idvRoles: data.company.idvRoles
      })
    }
  })

  const isRefetching = networkStatus === NetworkStatus.refetch

  const initialLoading = loading && networkStatus === NetworkStatus.loading

  const idvRoles = idvData?.company.idvRoles ?? []

  const title = (
    <div className='flex justify-between items-center pb-2 w-full'>
      <Typography variant='subtitle1'>{t`IDV`}</Typography>
      <Tooltip content={t`Refresh`}>
        <div>
          <IconButton
            data-track='IDV Check / Refresh'
            variant='ghost'
            onClick={async () => await refetch()}
            loading={isRefetching}
            loaderProps={{ size: 'sm' }}
          >
            <IconRefresh size='sm' />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  )

  return (
    <SidepanelCard className={className} title={title} loading={initialLoading}>
      <div className='flex grow flex-col px-4 pb-4 space-y-2'>
        {!idvRoles.length && <Empty title={t`No IDV roles found`} />}

        {idvRoles.map((role) => (
          <IDVCheckItem key={role.person.id} role={role} companyId={company.id} refetchIdvRoles={refetch} />
        ))}
      </div>
    </SidepanelCard>
  )
}
