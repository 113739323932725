import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconHome = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 1 1 11v10a2 2 0 0 0 2 2h8v-5a1 1 0 1 1 2 0v5h8a2 2 0 0 0 2-2V11l-2-1.818V4a2 2 0 0 0-2-2h-2a2.002 2.002 0 0 0-1.983 1.742L12 1Zm0 2.703-9 8.182V21h6v-3a3 3 0 1 1 6 0v3h6v-9.115l-2-1.818V4h-2l-.49 3.803-4.51-4.1ZM12 10a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3 1a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconHome.displayName = 'IconHome'
