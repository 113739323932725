import { t } from '@lingui/macro'
import { type Duration, type PortfolioHealthReviewMetrics } from '@strise/types'
import type { ReactNode } from 'react'
import { PortfolioHealthReviewMetricCard } from '~/features/PortfolioHealth/PortfolioHealthReviewMetricCard'

interface PortfolioHealthReviewMetricsSectionProps {
  duration: Duration
  onDurationChange: (duration: Duration) => void
  reviewMetrics: PortfolioHealthReviewMetrics
}

export const PortfolioHealthReviewMetricsSection = ({
  duration,
  onDurationChange,
  reviewMetrics
}: PortfolioHealthReviewMetricsSectionProps): ReactNode => {
  return (
    <div className='py-6'>
      <div className='grid grid-cols-1 lg:grid-cols-4 gap-6'>
        <PortfolioHealthReviewMetricCard
          duration={duration}
          onDurationChange={onDurationChange}
          metric={reviewMetrics.completedMetric}
          title={t`Reviews`}
          teamText={t`in entire team`}
          subtitle={t`Done by you`}
          tooltipText={t`The number of reviews that you have completed in the selected time period`}
          tooltipTextFooter={t`The number of reviews that your team has completed in the selected time period`}
        />
        <PortfolioHealthReviewMetricCard
          metric={reviewMetrics.pendingMetric}
          title={t`Pending reviews`}
          teamText={t`pending in entire team`}
          tooltipText={t`The number of reviews that are pending`}
          tooltipTextFooter={t`The number of reviews that your team has pending`}
        />
        <PortfolioHealthReviewMetricCard
          metric={reviewMetrics.highPriorityMetric}
          title={t`High priority reviews`}
          teamText={t`high priority in entire team`}
          tooltipText={t`The number of reviews that are marked as high priority`}
          tooltipTextFooter={t`The number of reviews that your team has marked as high priority`}
        />
        <PortfolioHealthReviewMetricCard
          metric={reviewMetrics.upcomingMetric}
          title={t`Upcoming reviews`}
          teamText={t`upcoming in entire team`}
          subtitle={t`Next month`}
          tooltipText={t`The number of reviews that are upcoming in the selected time period`}
          tooltipTextFooter={t`The number of reviews that your team has upcoming in the selected time period`}
        />
      </div>
    </div>
  )
}
