import { t } from '@lingui/macro'
import { Trans } from '@lingui/macro'
import { UserAvatar, formatDate } from '@strise/app-shared'
import { useContext } from '@strise/react-utils'
import { Typography, cn } from '@strise/ui-components'
import { type ReactNode } from 'react'
import { ContentViewContext } from '~/components/Layout/ContentViewContext'
import { DownloadPrivatePersonReviewPdf } from '~/features/Review/DownloadReviewPdf'
import { AuditRiskChip } from '~/features/Risk/AuditRiskChip'
import { CustomRiskLevelChip } from '~/features/Risk/CustomRiskLevelChip'
import { type PrivatePersonReviewFragment } from '~/graphqlTypes'

export const ReviewPrivatePersonCardSummaryContent = ({
  review
}: {
  review: PrivatePersonReviewFragment | null
}): ReactNode => {
  const { isSmallScreen } = useContext(ContentViewContext)
  if (!review) return null

  const rows = [
    {
      title: t`Date`,
      content: <Typography>{formatDate(review.created, { relative: false })}</Typography>
    },
    {
      title: t`Risk`,
      content: review.customRiskLevel ? (
        <CustomRiskLevelChip customRiskLevel={review.customRiskLevel} />
      ) : (
        <AuditRiskChip className='m-0' risk={review.risk} />
      )
    },
    {
      title: t`Reviewer`,
      content: review.user ? (
        <div className='flex'>
          <UserAvatar user={review.user} className='mr-1 size-6' />
          <Typography>{review.user.name}</Typography>
        </div>
      ) : (
        <Typography>
          <Trans>Unknown</Trans>
        </Typography>
      )
    },
    {
      title: t`Report`,
      content: <DownloadPrivatePersonReviewPdf review={review} className='h-auto p-0' />
    }
  ]

  return (
    <div className='px-4 pt-2'>
      <div className={cn('flex justify-between px-5', isSmallScreen ? 'flex-col' : 'flex-row')}>
        {rows.map((row, index) => {
          return (
            <div className={isSmallScreen ? 'my-2' : undefined} key={index}>
              <Typography className='block' variant='aLabelBold'>
                {row.title}
              </Typography>
              <div className='mt-[6px]'>{row.content}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
