import { t } from '@lingui/macro'
import { Skeleton, Typography } from '@strise/ui-components'
import { type ReactNode } from 'react'
import CompareRow from '~/components/RegistryCheck/CompareRow'
import { type RegistryCheckMetaQuery } from '~/graphqlTypes'

const CompareSection = ({
  loading,
  registryCheck
}: {
  loading: boolean
  registryCheck: RegistryCheckMetaQuery['company']['registryCheck']
}): ReactNode => {
  const loadingSkeleton = (
    <div className='flex flex-col gap-2'>
      <Skeleton className='h-8 w-full' />
      <Skeleton className='h-8 w-full' />
      <Skeleton className='h-8 w-full' />
      <Skeleton className='h-8 w-full' />
    </div>
  )

  return (
    <div className='bg-white rounded-sm p-4'>
      <Typography variant='subtitle2' className='pb-4'>{t`Verifying information against local registry`}</Typography>
      {loading ? (
        loadingSkeleton
      ) : (
        <div className='flex flex-col gap-2'>
          {registryCheck.map((row) => (
            <CompareRow
              key={row.fieldName}
              fieldName={row.fieldName}
              isExactMatch={row.isExactMatch}
              comment={row.comment ?? undefined}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default CompareSection
