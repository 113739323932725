import { t } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { IdentityVerificationStatus } from '@strise/types'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  IconNotificationAll
} from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { IdvActions } from '~/features/IDVCheck/IdvActions'
import { IdvCheckDocuments } from '~/features/IDVCheck/IdvCheckDocuments'
import { type IdvRoleFragment } from '~/graphqlTypes'

interface IDVCheckModalDCollectedProps {
  children: ReactNode
  loadingStatus: IdentityVerificationStatus | null
  onUpdateStatus: (status: IdentityVerificationStatus) => Promise<void>
  role: IdvRoleFragment
}

export const IDVCheckModalPendingReview = ({
  children,
  loadingStatus,
  onUpdateStatus,
  role
}: IDVCheckModalDCollectedProps): ReactNode => {
  const [open, setOpen] = useState(false)

  const collectedDate = role.idvRequest?.documentCollectedAt
    ? formatDate(role.idvRequest.documentCollectedAt, { relative: false, time: true })
    : t`Unknown`

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className='flex items-center gap-2'>
            <IconNotificationAll className='text-gray-70' /> {t`ID collected`}
          </DialogTitle>
          <DialogDescription>
            {t`ID proof is collected ${collectedDate} and ready for inspection.`}
            <IdvCheckDocuments role={role} />
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <IdvActions
            status={IdentityVerificationStatus.PendingDocument}
            onUpdateStatus={onUpdateStatus}
            setOpen={setOpen}
            loadingStatus={loadingStatus}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
