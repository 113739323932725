import type { ReactNode } from 'react'
import { useMemo, useState } from 'react'
import { SidepanelNavHistoryContext } from '~/components/Sidepanel/SidepanelNavHistoryContext'

export const SidepanelNavHistoryContextProvider = ({ children }: { children: ReactNode }): ReactNode => {
  const [sidepanelHistory, setSidepanelHistory] = useState<string[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const sidepanelNavHistoryContextValue = useMemo(
    () => ({
      sidepanelHistory,
      setSidepanelHistory,
      currentIndex,
      setCurrentIndex
    }),
    [sidepanelHistory, currentIndex]
  )

  return (
    <SidepanelNavHistoryContext.Provider value={sidepanelNavHistoryContextValue}>
      {children}
    </SidepanelNavHistoryContext.Provider>
  )
}
