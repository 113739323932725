import { Button, type ButtonProps, IconCheckSmall, cn } from '@strise/ui-components'
import { type ReactNode } from 'react'

export const TeamActivityCompleteButton = ({
  children,
  className,
  trackId,
  ...props
}: {
  trackId: string
} & ButtonProps): ReactNode => (
  <Button
    variant='contained'
    palette='tertiary'
    size='sm'
    className={cn('rounded-sm border pl-0 pr-2 text-sm', className)}
    type='button'
    data-track={trackId}
    startIcon={<IconCheckSmall />}
    {...props}
  >
    {children}
  </Button>
)
