import { t } from '@lingui/macro'
import { type PortfolioHealthRiskFactors } from '@strise/types'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  type CardProps,
  IconBusinessPerson,
  IconFamily,
  IconFlag,
  IconGlobe,
  IconSanction,
  IconWarning,
  IconWarningExclamation,
  Tooltip,
  Typography
} from '@strise/ui-components'
import type { ReactNode } from 'react'

export type RiskFactorKeys = Exclude<keyof PortfolioHealthRiskFactors, '__typename'>

const colorCardConfig = {
  warning: {
    className: 'border-none bg-yellow-5'
  },
  danger: {
    className: 'border-none bg-semantic-danger-shade-5'
  },
  neutral: {
    className: 'border-none bg-gray-5'
  }
} as const

const RISK_FACTOR_CONFIG: Record<RiskFactorKeys, { cardProps: Partial<CardProps>; icon: ReactNode; title: string }> = {
  pepCount: { title: 'PEPs', cardProps: colorCardConfig.warning, icon: <IconBusinessPerson size='md' /> },
  sanctionCount: { title: 'Sanctions', cardProps: colorCardConfig.danger, icon: <IconFlag size='md' /> },
  rcaCount: { title: 'RCAs', cardProps: colorCardConfig.neutral, icon: <IconFamily size='md' /> },
  pepInHighRiskCountryCount: {
    title: 'PEPs in high risk countries',
    cardProps: colorCardConfig.warning,
    icon: <IconGlobe size='md' />
  },
  highRiskCountryCount: { title: 'Jurisdiction', cardProps: colorCardConfig.warning, icon: <IconWarning size='md' /> },
  highRiskIndustryCount: {
    title: 'High risk industries',
    cardProps: colorCardConfig.warning,
    icon: <IconWarningExclamation size='md' />
  }
}

interface RiskFactorsCardProps {
  riskFactor: {
    found: string
    kind: RiskFactorKeys
  }
}

export const PortfolioHealthRiskFactorsCard = ({ riskFactor }: RiskFactorsCardProps): ReactNode => {
  const config = RISK_FACTOR_CONFIG[riskFactor.kind]
  const tooltipText = t`The number of companies in your portfolio that have ${config.title}`
  return (
    <Card variant='contained' size='lg' rounded='sm' {...config.cardProps}>
      <Tooltip content={tooltipText}>
        <CardContent className='flex-col justify-between space-y-3 p-0'>
          <CardHeader className='flex flex-col items-start p-0'>
            <Typography variant='aLabel'>{config.title}</Typography>
          </CardHeader>
          <CardDescription className='text-gray-100 flex flex-row items-center gap-2'>
            {config.icon}{' '}
            <Typography variant='h3' component='span'>
              {riskFactor.found}
            </Typography>
            {riskFactor.kind === 'sanctionCount' && (
              <div className='flex items-center justify-end w-full'>
                <IconSanction className='text-semantic-danger-main' size='xl' />
              </div>
            )}
          </CardDescription>
        </CardContent>
      </Tooltip>
    </Card>
  )
}
