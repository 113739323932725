import { useReactiveVar } from '@apollo/client/index.js'
import { extractIsCompany } from '@strise/app-shared'
import { filterNullishValues } from '@strise/ts-utils'
import { uniqBy } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { useCompanyOwnerChartQuery } from '~/graphqlOperations'
import {
  type CustomMetaFragment,
  type EntityLikeMetaFragment,
  type OwnerChartEdgeFragment,
  type OwnerChartFragment,
  type OwnerChartNodeEntityFragment,
  type OwnerChartNodeFragment,
  type TableFragment
} from '~/graphqlTypes'
import { refreshOwnershipIdState } from '~/state'

export interface TransformedOwnerChartNode extends Omit<OwnerChartNodeFragment, '__typename'> {
  __typename: OwnerChartNodeEntityFragment['__typename'] | 'UnknownShareholder'
  isUncertain: boolean
}

export interface TransformedOwner extends TransformedOwnerChartNode {
  customMeta?: CustomMetaFragment | null
  owners: TransformedOwner[]
  share: string
  subsidiaries: TransformedOwnerChartNode[]
}

// This function finds the owner of a certain entity in the chart
export const findOwnerRecursive = (
  owners: TransformedOwner[],
  targetId: TransformedOwner['id'],
  subsidiaryId?: string
): TransformedOwner | undefined => {
  // Use reduce to sequentially check each owner
  return owners.reduce((found: TransformedOwner | undefined, owner: TransformedOwner) => {
    // If found is already defined, return it
    if (found) return found

    // If current owner's id matches target or if it is a subsidiary of the target, return it
    if (owner.id === targetId && (!subsidiaryId || owner.owners.some((o) => o.id === subsidiaryId))) {
      return owner
    }

    // Else, recursively search in owner's ownees
    return findOwnerRecursive(owner.owners, targetId, subsidiaryId)
  }, undefined)
}

// This function finds all companies owned by a certain entity in the chart
export const findOwnedCompanies = (
  owners: TransformedOwner[],
  ownerId: TransformedOwner['id'],
  rootEntityId: string
): TransformedOwnerChartNode[] => {
  // Set to keep track of owners and avoid duplicates
  const ownees = owners.flatMap((owner) => {
    // If the current owner is the owner we are looking for, return all its ownees
    if (owner.id === ownerId) {
      // filter out the root entity in case it owns itself
      return owner.subsidiaries.filter((ownee) => ownee.id !== rootEntityId)
    }

    // Otherwise, continue the search recursively in the current owner's ownees
    return findOwnedCompanies(owner.owners, ownerId, rootEntityId)
  })

  const uniqueOwnedCompanies: TransformedOwnerChartNode[] = uniqBy(ownees, (ownee) => ownee.id)

  return uniqueOwnedCompanies
}

const extractOwnees = (
  entityId: string,
  ownerEdges: OwnerChartEdgeFragment[],
  transformedNodes: TransformedOwnerChartNode[]
): TransformedOwnerChartNode[] => {
  // Find edges which have entityId as parent.
  const ids = new Set(ownerEdges.filter((edge) => edge.parent === entityId).map((edge) => edge.child))
  // Map child id of each relevant edge to its corresponding node in transformedNodes.
  const ownees = filterNullishValues(transformedNodes.filter((node) => ids.has(node.id)))

  return ownees
}

const recursiveExtractOwners = (
  entityId: string,
  ownerEdges: OwnerChartEdgeFragment[],
  transformedNodes: TransformedOwnerChartNode[],
  alreadyVisitedEntityIds: string[] = []
): TransformedOwner[] => {
  const owners = transformedNodes
    // Filter parents of entityId. Exclude already visited entity ids to avoid infinite recursion in circular ownerships
    .filter((node) =>
      ownerEdges.some(
        (edge) => edge.child === entityId && edge.parent === node.id && !alreadyVisitedEntityIds.includes(edge.child)
      )
    )
    .map((node) => {
      // Find related edge
      const matchingEdge = ownerEdges.find((edge) => edge.child === entityId && edge.parent === node.id)

      if (!matchingEdge) {
        return {
          ...node,
          share: '?',
          owners: [],
          subsidiaries: []
        }
      }
      const ownees = extractOwnees(node.id, ownerEdges, transformedNodes)
      const parentWithOwners = recursiveExtractOwners(matchingEdge.parent, ownerEdges, transformedNodes, [
        ...alreadyVisitedEntityIds,
        entityId
      ])
      return {
        ...node,
        share: matchingEdge.share,
        modifiedAt: matchingEdge.customMeta?.lastModifiedAt,
        owners: parentWithOwners,
        subsidiaries: ownees,
        customMeta: matchingEdge.customMeta
      }
    })

  return owners
}

export const flattenOwnershipNode = (node: OwnerChartNodeFragment): TransformedOwnerChartNode => {
  return {
    ...node,
    ...node.entity,
    id: node.entity?.id ?? node.id,
    name: node.entity?.name ?? node.name,
    isUncertain: false,
    __typename: node.entity?.__typename ?? 'UnknownShareholder'
  }
}

export interface OwnersState {
  hasCustomOwners: boolean
  hasData: boolean
  lastModifiedAt: string | null | undefined
  loading: boolean
  ownerChart: OwnerChartFragment | null
  recursiveOwners: TransformedOwner[]
  table: TableFragment | null
  transformedNodes: TransformedOwnerChartNode[]
}

export const defaultOwnersState = {
  recursiveOwners: [],
  transformedNodes: [],
  loading: false,
  hasData: false,
  hasCustomOwners: false,
  lastModifiedAt: null,
  table: null,
  ownerChart: null
}

export const useOwners = (
  entity: EntityLikeMetaFragment,
  indirectOwnersPercentage: number,
  showOriginal: boolean
): OwnersState => {
  const refreshOwnershipId = useReactiveVar(refreshOwnershipIdState)

  const { data, loading, refetch } = useCompanyOwnerChartQuery({
    variables: {
      entity: entity.id,
      threshold: indirectOwnersPercentage,
      withFlags: true,
      ignoreCustomOwnerships: showOriginal
    },
    skip: !extractIsCompany(entity) || (entity.__typename === 'Company' && !entity.showOwnerChart),
    fetchPolicy: 'cache-and-network'
  })
  const [state, setState] = useState<OwnersState>(defaultOwnersState)

  useEffect(() => {
    const company = data?.entity

    // ownerChart can still be null even if 'ownerChart' in company is true
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!company || !('ownerChart' in company) || !('owners' in company) || !company.ownerChart) return

    const { ownerChart } = company

    const transformedNodes = ownerChart.nodes.map((node) => flattenOwnershipNode(node))

    const recursiveOwners = recursiveExtractOwners(company.id, ownerChart.edges, transformedNodes)

    setState({
      recursiveOwners,
      transformedNodes,
      loading: false,
      hasData: true,
      hasCustomOwners: ownerChart.hasCustomOwners,
      lastModifiedAt: ownerChart.lastModifiedAt,
      table: company.owners,
      ownerChart
    })
  }, [data, loading])

  useEffect(() => {
    if (!data) return
    if (refreshOwnershipId !== entity.id) return
    refetch()
  }, [refreshOwnershipId])

  const values = useMemo(() => {
    return { ...state, loading }
  }, [JSON.stringify(state), loading])

  return values
}
