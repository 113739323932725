import { type ForwardedRef, forwardRef } from 'react'
import { BEHIND_Z_INDEX } from '~/utils/zIndexes'

export const ReviewCardAnchor = forwardRef(({ ...props }, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div
      className='absolute top-[-theme(header.height)]'
      ref={ref}
      style={{
        zIndex: BEHIND_Z_INDEX
      }}
      {...props}
    />
  )
})
