import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconCompare = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M15.707 3.293 23.414 11H1V9h17.586l-4.293-4.293 1.414-1.414ZM8.293 20.707.586 13H23v2H5.414l4.293 4.293-1.414 1.414Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconCompare.displayName = 'IconCompare'
