import { Pagination } from '../Pagination'
import { SortableTableHeadCell } from './SortableTableHeadCell'
import {
  type SortField,
  type SortableTableColumn,
  type SortableTableCommonProps,
  type SortableTableRow,
  enrichColumnsWithCheckbox,
  enrichRowsWithCheckbox,
  filterColumns,
  useSelectTableRowsWithState
} from './sortableTableUtils'
import { IconSearch, Input, LoaderRound, Skeleton, Typography } from '@strise/ui-components'
import { Table, TableCell, TableRow } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { Fragment } from 'react'

const SortableTableLoadingBody = <K extends string>({
  columns,
  pageSize
}: {
  columns: Array<SortableTableColumn<K>>
  pageSize: number
}): ReactNode => {
  return (
    <>
      {Array.from({ length: pageSize }, (_, rowIndex) => (
        <TableRow key={rowIndex}>
          {columns.map((__, columnIndex) => (
            <TableCell key={columnIndex}>
              <Skeleton className='h-[21px] w-[30%]' />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

export interface SortableTableBaseProps<K extends string> extends SortableTableCommonProps<K> {
  pageIndex: number
  pageSize: number
  setPageIndex: (pn: number) => void
  sortFields: Array<SortField<string>>
  totalRows: number
  updateSortField: (field: K) => () => void
}

export const SortableTableBase = <K extends string>({
  // Data
  checkActive,
  className,
  columns,
  extraRows,

  // State
  filter,
  filterString,
  getRowId,
  handleFilterStringChange,
  headerPalette,
  headerProps,

  // Initial state
  inputPlaceholder,
  loading,

  // Functions
  mutationLoading,
  pageIndex,
  pageSize,
  rows,
  selected,
  setPageIndex,

  // UI
  setPageSize,
  setSelected,
  size,
  sortFields,

  // Props
  striped,
  tableHeadProps,
  totalRows,
  updateSortField,
  wrapperProps,
  ...props
}: SortableTableBaseProps<K>): ReactNode => {
  const filteredColumns = filterColumns(columns)

  const { handleSelect, selectAll } = useSelectTableRowsWithState(rows, selected, setSelected)
  const rowsWithCheckbox: Array<SortableTableRow<K>> = enrichRowsWithCheckbox(rows, handleSelect, selected)
  const columnsWithCheckbox: Array<SortableTableColumn<K>> = enrichColumnsWithCheckbox(
    filteredColumns,
    selectAll,
    totalRows || 0,
    selected
  )

  const sortString = sortFields.map((sortField) => `${sortField.field}-${String(sortField.direction)}`).join('-')

  return (
    <>
      {filter && handleFilterStringChange && (
        <div {...headerProps}>
          <Input
            startIcon={<IconSearch className='mx-2' />}
            className='h-full grow border-b'
            palette='tertiary'
            variant={null}
            placeholder={inputPlaceholder ?? 'Search...'}
            value={filterString ?? ''}
            onChange={handleFilterStringChange}
          />
        </div>
      )}
      <div className='overflow-auto' {...wrapperProps}>
        <Table className={className} {...props}>
          <thead>
            <TableRow>
              {columnsWithCheckbox.map((column, idx) => {
                const sort =
                  sortFields[sortFields.length - 1]?.field === column.field
                    ? sortFields[sortFields.length - 1]
                    : undefined
                return (
                  <SortableTableHeadCell
                    key={idx}
                    column={column}
                    sort={sort}
                    handleSort={updateSortField}
                    size={size}
                    palette={headerPalette}
                    {...tableHeadProps}
                  />
                )
              })}
            </TableRow>
          </thead>
          <tbody>
            {loading && <SortableTableLoadingBody columns={columnsWithCheckbox} pageSize={pageSize} />}
            {!loading &&
              rowsWithCheckbox.map((row, rowIdx) => {
                const rowId = getRowId(rowIdx)

                if (!rowId) return null

                const isActive = checkActive ? checkActive(row) : false
                return (
                  <Fragment key={`${pageSize}-${pageIndex}-${rowId}-${filterString ?? ''}-${sortString}`}>
                    <TableRow striped={striped} index={rowIdx} isActive={isActive}>
                      {columnsWithCheckbox.map(({ cellProps, field }, colIdx) => (
                        <TableCell key={`${rowIdx}-${colIdx}`} size={size} {...cellProps}>
                          {row[field].renderValue}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.key && extraRows?.[row.key] && (
                      <TableRow striped={striped} index={rowIdx}>
                        <TableCell colSpan={columnsWithCheckbox.length} size={size}>
                          {extraRows[row.key]}
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                )
              })}
          </tbody>
        </Table>
        {!loading && !rowsWithCheckbox.length && (
          <Typography className='p-10 text-center text-text-secondary'>No results</Typography>
        )}

        <div className='sticky inset-x-0'>
          <Pagination
            nextLoading={loading}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalResults={totalRows}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </div>
      </div>
      {mutationLoading && <LoaderRound className='absolute bottom-5 right-5' />}
    </>
  )
}
