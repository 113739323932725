import { zodResolver } from '@hookform/resolvers/zod'
import { Trans, t } from '@lingui/macro'
import {
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Label,
  TextArea
} from '@strise/ui-components'
import { type ReactNode } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { type BaseEntityLikeFragment } from '~/graphqlTypes'

const getCreatePepInputSchema = (isPepOrRca: boolean) =>
  z
    .object({
      name: z.string().min(1, t`Name is required`),
      isPep: z.boolean().default(false),
      isRca: z.boolean().default(false),
      comment: z.string().optional()
    })
    .refine((data) => !isPepOrRca || data.isPep || data.isRca, {
      message: t`At least one of PEP or RCA must be checked`,
      path: ['isPep']
    })

export type CreatePepInput = z.infer<ReturnType<typeof getCreatePepInputSchema>>

interface CreatePepFormProps {
  isPepOrRca: boolean
  onSubmit: SubmitHandler<CreatePepInput>
  person: BaseEntityLikeFragment
}

export const CreatePepForm = ({ isPepOrRca, onSubmit, person }: CreatePepFormProps): ReactNode => {
  const CreatePepInputSchema = getCreatePepInputSchema(isPepOrRca)

  const form = useForm<CreatePepInput>({
    resolver: zodResolver(CreatePepInputSchema),
    defaultValues: {
      name: person.name ?? '',
      isPep: false,
      isRca: false,
      comment: ''
    }
  })

  return (
    <Form {...form}>
      <form id='pep-form' onSubmit={form.handleSubmit(onSubmit)} className='bg-background-paper space-y-4'>
        {isPepOrRca && (
          <>
            {/* Name */}
            <FormField
              control={form.control}
              name='name'
              render={({ field }) => (
                <FormItem required>
                  <FormLabel>
                    <Trans>Name</Trans>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Checkboxes for PEP & RCA */}
            <div className='flex flex-row items-center space-x-6'>
              <FormField
                control={form.control}
                name='isPep'
                render={({ field }) => (
                  <FormItem>
                    <div className='flex items-center space-x-2'>
                      <Checkbox
                        id='is-pep'
                        checked={field.value}
                        onCheckedChange={(checked) => field.onChange(Boolean(checked))}
                        data-track=''
                      />
                      <Label>
                        <Trans>PEP</Trans>
                      </Label>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='isRca'
                render={({ field }) => (
                  <FormItem>
                    <div className='flex items-center space-x-2'>
                      <Checkbox
                        id='is-rca'
                        checked={field.value}
                        onCheckedChange={(checked) => field.onChange(Boolean(checked))}
                        data-track=''
                      />
                      <Label>
                        <Trans>RCA</Trans>
                      </Label>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </>
        )}

        {/* Comment */}
        <FormField
          control={form.control}
          name='comment'
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Trans>Comment</Trans>
              </FormLabel>
              <FormControl>
                <TextArea {...field} placeholder={t`Optional comment`} autoResize />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  )
}
