import * as Sentry from '@sentry/react'
import { Authorize, Toaster, UserRoleEnum } from '@strise/app-shared'
import '@strise/app-shared/src/react-flow.css'
import { UiComponentsProvider } from '@strise/ui-components'
import { UiComponentsLegacyProvider } from '@strise/ui-components-legacy'
import '@strise/ui-components-legacy/src/index.css'
import '@strise/ui-components/src/tailwind.css'
import { type ReactNode } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Routes } from '~/Routes'
import { ApolloClientContextProvider } from '~/apolloClient/ApolloClientContextProvider'
import { AuthInit } from '~/components/Auth'
import { IdentifyErrorService } from '~/components/IdentifyErrorService'
import { IdentifyServices } from '~/components/IdentifyServices'
import { InitAppSharedContext } from '~/contexts/AppSharedContext/InitAppSharedContext'
import { CurrentUserSettingsContextProvider } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContextProvider'
import { DisplayLanguageContextProvider } from '~/contexts/DisplayLanguageContext/DisplayLanguageContextProvider'
import { PreLoaderContextProvider } from '~/contexts/PreLoaderContext/PreLoaderContextProvider'
import { PreLoaderContextStop } from '~/contexts/PreLoaderContext/PreLoaderContextStop'
import { RecentlyVisitedEntitiesContextProvider } from '~/contexts/RecentlyVisitedEntitiesContext/RecentlyVisitedEntitiesContextProvider'
import { TeamContextProvider } from '~/contexts/TeamContext/TeamContextProvider'
import { TeamSettingsContextProvider } from '~/contexts/TeamSettingsContext/TeamSettingsContextProvider'
import { SettingsModalContextProvider } from '~/features/Settings/SettingsModalContextProvider'
import { useNavigateToSetup } from '~/features/Setup/setupUtils'
import { spoof } from '~/features/Spoof/spoof'
import { useFetchUserFinancialFields, useSaveUserTimeZone } from '~/features/Users/userHooks'
import '~/renderer/PageLayout.css'
import { themeBreakpoints } from '~/theme'
import { fixGoogleTranslateBug } from '~/utils/googleTranslate'
import { useLocalStorageVersion } from '~/utils/hooks'
import { initTracking } from '~/utils/tracking'
import { ErrorView } from '~/views/ErrorView'

const InitUser = ({ children }: { children: ReactNode }): ReactNode => {
  useSaveUserTimeZone()
  useNavigateToSetup()
  const loadingFinFields = useFetchUserFinancialFields()

  if (loadingFinFields) return null

  return <>{children}</>
}

const Init = ({ children }: { children: ReactNode }): ReactNode => (
  <PreLoaderContextProvider>
    <AuthInit>
      <IdentifyErrorService>
        <ApolloClientContextProvider>
          <Authorize minRequiredRole={UserRoleEnum.TeamMember}>
            <UiComponentsProvider>
              <TeamContextProvider>
                <TeamSettingsContextProvider>
                  <RecentlyVisitedEntitiesContextProvider>
                    <CurrentUserSettingsContextProvider>
                      <SettingsModalContextProvider>
                        <InitUser>
                          <DisplayLanguageContextProvider>
                            <PreLoaderContextStop>{children}</PreLoaderContextStop>
                          </DisplayLanguageContextProvider>
                        </InitUser>
                      </SettingsModalContextProvider>
                    </CurrentUserSettingsContextProvider>
                  </RecentlyVisitedEntitiesContextProvider>
                </TeamSettingsContextProvider>
              </TeamContextProvider>
            </UiComponentsProvider>
          </Authorize>
        </ApolloClientContextProvider>
      </IdentifyErrorService>
    </AuthInit>
  </PreLoaderContextProvider>
)

export const Page = (): ReactNode => {
  useLocalStorageVersion('0.5')
  fixGoogleTranslateBug()
  initTracking()
  spoof.init()

  return (
    <UiComponentsLegacyProvider themeOverrides={themeBreakpoints}>
      <Sentry.ErrorBoundary fallback={<ErrorView />}>
        <Init>
          <InitAppSharedContext>
            <Toaster />
            <IdentifyServices>
              <Routes />
            </IdentifyServices>
          </InitAppSharedContext>
        </Init>
      </Sentry.ErrorBoundary>
    </UiComponentsLegacyProvider>
  )
}
