import { t } from '@lingui/macro'
import { EntityDispositionStatusKind } from '@strise/types'
import { Tooltip } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { Dot } from '~/components/Dot'
import { PepDispositionMeta } from '~/features/PepAndSanctions/PepDispositionMeta'
import type { PepInfoFragment } from '~/graphqlTypes'

const extractClassName = ({
  pepInfo
}: {
  pepInfo: PepInfoFragment
}):
  | 'bg-semantic-success-main'
  | 'bg-semantic-danger-main'
  | 'border border-semantic-success-main'
  | 'border border-semantic-danger-main' => {
  if (pepInfo.disposition?.status === EntityDispositionStatusKind.ConfirmedTrue) {
    return 'bg-semantic-success-main'
  }

  if (pepInfo.disposition?.status === EntityDispositionStatusKind.ConfirmedFalse) {
    return 'bg-semantic-danger-main'
  }

  if (pepInfo.matchInfoAnalysis?.suggestedTruePositive) {
    return 'border border-semantic-success-main'
  }

  return 'border border-semantic-danger-main'
}

export const PepInfoStatusIcon = ({ pepInfo }: { pepInfo: PepInfoFragment }): ReactNode => {
  const className = extractClassName({ pepInfo })

  return (
    <Tooltip
      arrow
      content={
        pepInfo.disposition ? <PepDispositionMeta disposition={pepInfo.disposition} /> : t`Not user confirmed yet`
      }
    >
      <span>
        <Dot className={className} />
      </span>
    </Tooltip>
  )
}
