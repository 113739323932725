import { type SxProps } from '../styleFunctions/StyleFunction'
import { Box, type BoxProps } from './Box'
import { type Placement, type PositioningStrategy } from '@popperjs/core'
import { type Options } from '@popperjs/core/lib/modifiers/offset'
import { type PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow'
import { getBrowserGlobals } from '@strise/react-utils'
import { type ReactNode, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'

/** @deprecated use `Popover` from `@strise/ui-components` */
export interface PopperProps extends BoxProps {
  arrow?: boolean
  arrowProps?: SxProps
  offset?: Options['offset']
  overflow?: PreventOverflowModifier['options']
  placement?: Placement
  portal?: boolean
  strategy?: PositioningStrategy
  zIndex?: number
}

/** @deprecated use `Popover` from `@strise/ui-components` */
export const Popper = ({
  anchorEl,
  arrow = false,
  arrowProps,
  children,
  offset,
  open,
  overflow = {},
  placement = 'bottom-end',
  portal = false,
  strategy = 'absolute',
  zIndex = 9999,
  ...props
}: {
  anchorEl: HTMLElement | null
  open: boolean
} & PopperProps): ReactNode => {
  const [popperElement, setPopperElement] = useState()
  const [arrowElement, setArrowElement] = useState()
  const { attributes, styles, update } = usePopper(anchorEl, popperElement, {
    placement,
    strategy,
    modifiers: [
      {
        name: 'preventOverflow',
        options: overflow
      },
      {
        name: 'offset',
        options: { offset: arrow ? [0, 20] : offset }
      },
      {
        name: 'arrow',
        enabled: arrow,
        options: {
          element: arrowElement
        }
      }
    ]
  })

  const observer = useRef<ResizeObserver | null>(null)
  useEffect(() => {
    if (anchorEl && update) {
      observer.current = new ResizeObserver(() => {
        update()
      })
      observer.current.observe(anchorEl)
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
    }
  }, [anchorEl, update])

  if (!open) return null

  const browserGlobals = getBrowserGlobals()

  const popperBox = (
    <Box role='tooltip' ref={setPopperElement} style={{ zIndex, ...styles.popper }} {...attributes.popper} {...props}>
      {arrow && (
        <Box
          className='system-popper-arrow'
          ref={setArrowElement}
          width={1}
          px={4}
          sx={{
            '&:before': {
              content: '""',
              display: 'block',
              width: 20,
              height: 20,
              transform: 'rotate(-45deg)',
              bgcolor: 'secondary.90'
            },
            ...arrowProps
          }}
          style={styles.arrow}
        />
      )}

      {children}
    </Box>
  )

  return (
    <>
      {browserGlobals && portal && ReactDOM.createPortal(popperBox, browserGlobals.document.body)}
      {!portal && popperBox}
    </>
  )
}
