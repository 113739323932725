import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconEye = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M18.872 10.778a11 11 0 0 0-13.744 0L3.601 12l1.527 1.222a11 11 0 0 0 13.744 0L20.399 12l-1.527-1.222ZM3.879 9.216a13 13 0 0 1 16.242 0L23.601 12l-3.48 2.784a13 13 0 0 1-16.242 0L.399 12l3.48-2.784Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconEye.displayName = 'IconEye'
