import { type DivPropsWithChildren } from '@strise/react-utils'
import { cn } from '@strise/ui-components'
import { REVIEW_CHECKBOX_COLUMN_WIDTH_CLASSES } from '~/features/Review/reviewUtils'

export const ReviewCheckboxColumn = ({ children, className, ...props }: DivPropsWithChildren) => {
  return (
    <div
      className={cn('shrink-0 break-words pl-4 py-4 pr-1', REVIEW_CHECKBOX_COLUMN_WIDTH_CLASSES, className)}
      {...props}
    >
      <div className={cn('flex w-full items-center')}>{children}</div>
    </div>
  )
}
