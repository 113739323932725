import { Tooltip, Typography, type TypographyProps } from '@strise/ui-components'
import type { ReactNode } from 'react'

export const Timespan = ({
  className,
  duration,
  timespan,
  ...props
}: {
  duration: string | null
  timespan: string | null
} & TypographyProps): ReactNode => {
  if (!timespan && !duration) return null

  return (
    <Tooltip content={duration && <span>{duration}</span>}>
      <Typography className={className} {...props}>
        {timespan}
      </Typography>
    </Tooltip>
  )
}
