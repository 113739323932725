import { t } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { Tooltip, type TooltipProps, Typography, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'

interface TooltipContentProps extends Pick<TooltipProps, 'content'>, Omit<DivProps, 'content'> {
  dataSource?: string
}

const TooltipContent = ({ className, content, dataSource, ...props }: TooltipContentProps): ReactNode => {
  return (
    <div className={cn('relative p-1 text-center', className)} {...props}>
      {content && (
        <Typography variant='body2' className={cn({ 'mb-2': dataSource })}>
          {content}
        </Typography>
      )}

      {dataSource && (
        <Typography className='text-secondary-shade-20' variant='body3'>
          {t`Source`}: {dataSource}
        </Typography>
      )}
    </div>
  )
}

export const DataSourceTooltip = ({
  children,
  content,
  dataSource,
  open,
  side,
  ...props
}: {
  dataSource?: string
} & TooltipProps): ReactNode => {
  const showArrow = side !== 'left'

  if (!content && !dataSource) return children

  return (
    <Tooltip
      content={<TooltipContent content={content} dataSource={dataSource} />}
      arrow={showArrow}
      open={open}
      {...props}
    >
      {children}
    </Tooltip>
  )
}
