import { t } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { ContentType, triggerDownload } from '@strise/react-utils'
import { stringToSlug } from '@strise/ts-utils'
import { Button, DataTable, IconDownload, Tooltip, createColumnHelper } from '@strise/ui-components'
import Papa from 'papaparse'
import { type ReactNode, useMemo } from 'react'
import { type AuditTrailEntryFragment, type BaseEntityLikeFragment } from '~/graphqlTypes'

const columnHelper = createColumnHelper<AuditTrailEntryFragment>()

const extractColumns = (targetName: string) => {
  return [
    columnHelper.accessor((info) => info.date, {
      header: t`Date`,
      cell: (info) => formatDate(info.getValue(), { relative: false })
    }),
    columnHelper.accessor((info) => info.target, {
      header: targetName,
      cell: (info) => {
        const metadata = info.row.original.metadata
        const tooltipContent = metadata.map((m) => `${m.name}: ${m.value}`).join('\n')
        return (
          <Tooltip content={tooltipContent} arrow>
            <span>{info.getValue()}</span>
          </Tooltip>
        )
      }
    }),
    columnHelper.accessor((info) => info.action, {
      header: t`Action`,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor((info) => info.comment, {
      header: t`Comment`,
      cell: (info) => info.getValue() || '-'
    }),
    columnHelper.accessor((info) => info.user, {
      header: t`User`,
      cell: (info) => info.getValue()?.name ?? t`Deleted user`
    })
  ]
}

interface AuditTrailTableProps {
  entity: BaseEntityLikeFragment
  entries: AuditTrailEntryFragment[]
  loading: boolean
  targetName: string
}

export const AuditTrailTable = ({ entity, entries, loading, targetName }: AuditTrailTableProps): ReactNode => {
  const columns = useMemo(() => extractColumns(targetName), [])

  const handleDownload = () => {
    const csvData = entries.map((entry) => ({
      [t`Date`]: formatDate(entry.date, { relative: false }),
      [targetName]: entry.target,
      [t`Action`]: entry.action,
      [t`Comment`]: entry.comment,
      [t`User`]: entry.user?.email || t`Deleted user`,
      [t`Metadata`]: entry.metadata.map((m) => `${m.name}: ${m.value}`).join('\n')
    }))

    const csvContent = Papa.unparse(csvData)

    const fileName = `audit-trail_${stringToSlug(entity.name ?? entity.id)}_${Date.now()}`

    triggerDownload(csvContent, fileName, ContentType.CSV)
  }

  return (
    <div className='flex flex-col'>
      <Button
        variant='outlined'
        onClick={handleDownload}
        className='ml-auto'
        startIcon={<IconDownload size='sm' className='mr-1' />}
        data-track='Audit trail table / Download CSV'
      >
        {t`Download as CSV`}
      </Button>
      <DataTable columns={columns} data={entries} loading={loading} emptyStateText={t`No audit trail entries`} />
    </div>
  )
}
