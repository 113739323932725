import { cn } from '../../utils/className'
import type { IconProps } from '../../utils/iconUtils'
import type { ReactNode } from 'react'

export const LogoIcon = ({ className, ...props }: IconProps): ReactNode => (
  <svg
    className={cn('h-[18px] w-[25px]', className)}
    viewBox='0 0 25 18'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M22.5646 2.1855C22.925 2.1855 23.2773 2.29239 23.5769 2.49265C23.8765 2.6929 24.11 2.97752 24.2478 3.31051C24.3857 3.64349 24.4216 4.00987 24.3512 4.3633C24.2808 4.71674 24.1071 5.04134 23.8521 5.29604C23.5972 5.55075 23.2724 5.72412 22.9189 5.79421C22.5654 5.86431 22.1991 5.82798 21.8662 5.68983C21.5334 5.55168 21.249 5.31791 21.049 5.01809C20.849 4.71827 20.7425 4.36589 20.7428 4.0055C20.7433 3.52265 20.9354 3.05973 21.277 2.71846C21.6186 2.3772 22.0817 2.1855 22.5646 2.1855ZM22.5646 1.979C22.1638 1.979 21.772 2.09786 21.4387 2.32053C21.1055 2.54321 20.8457 2.8597 20.6923 3.23C20.539 3.60029 20.4988 4.00775 20.577 4.40085C20.6552 4.79396 20.8482 5.15504 21.1316 5.43846C21.415 5.72187 21.7761 5.91487 22.1692 5.99306C22.5623 6.07126 22.9698 6.03113 23.3401 5.87775C23.7104 5.72436 24.0269 5.46462 24.2496 5.13137C24.4722 4.79811 24.5911 4.40631 24.5911 4.0055C24.5911 3.73938 24.5387 3.47586 24.4368 3.23C24.335 2.98413 24.1857 2.76073 23.9975 2.57255C23.8094 2.38437 23.586 2.2351 23.3401 2.13326C23.0942 2.03142 22.8307 1.979 22.5646 1.979Z' />
    <path d='M21.8154 2.95728H22.6729C23.0404 2.95728 23.3134 3.19352 23.3134 3.51727C23.3179 3.62347 23.2899 3.72851 23.2334 3.81851C23.1768 3.9085 23.0943 3.97921 22.9967 4.02127C23.2067 4.02127 23.2837 4.09828 23.2837 4.30653V5.05552H23.0737V4.30653C23.0766 4.28808 23.0752 4.26921 23.0695 4.25143C23.0638 4.23365 23.054 4.21746 23.0409 4.20417C23.0277 4.19088 23.0117 4.18086 22.994 4.17493C22.9763 4.169 22.9574 4.16732 22.9389 4.17003H22.0254V5.05552H21.8154V2.95728ZM22.6414 3.97578C22.6999 3.9786 22.7583 3.96965 22.8133 3.94948C22.8682 3.92931 22.9186 3.89832 22.9613 3.85834C23.0041 3.81836 23.0384 3.7702 23.0622 3.71673C23.086 3.66326 23.0988 3.60555 23.0999 3.54703C23.0999 3.29853 22.9249 3.15153 22.6379 3.15153H22.0219V3.97578H22.6414Z' />
    <path d='M4.95775 7.86774H0V10.3545H4.95775V7.86774Z' />
    <path d='M13.6065 0.362C9.69527 0.362 7.43777 2.854 7.48152 5.42125C7.52527 7.9885 8.97602 9.5915 11.8565 10.1357L14.7213 10.6607C16.3348 10.9652 16.9473 11.6267 16.9683 12.7607C16.991 13.9857 15.9568 15.0357 13.9653 15.0725H13.7255C11.5013 15.0725 10.0785 13.8807 9.93152 11.989H7.06152C7.52002 15.5625 9.53427 17.6432 13.6205 17.6432C17.5773 17.6432 19.9205 15.4365 19.8575 12.535C19.8068 9.79975 18.0515 8.321 15.1623 7.81L12.5618 7.355C11.118 7.09425 10.3865 6.48 10.3638 5.28475C10.3393 4.023 11.5503 2.9695 13.463 2.93275H13.7028C15.2988 2.903 16.305 3.74125 16.7408 5.03275H19.6878C19.3623 2.3955 17.2693 0.3655 13.6065 0.36725' />
  </svg>
)
