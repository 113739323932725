'use client'

import { IconCheckMedium, IconMinus } from '../../icons/general'
import { cn } from '../../utils/className'
import { Tooltip } from '../Tooltip'
import { Typography, type TypographyProps } from '../Typography'
import { checkboxVariants } from '../variants/checkboxVariants'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { type DataProps, type DivProps, type ExcludedStyleProps } from '@strise/react-utils'
import { type VariantProps } from 'class-variance-authority'
import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import { forwardRef, useEffect, useRef, useState } from 'react'

export interface CheckboxProps
  extends Omit<ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>, ExcludedStyleProps | 'onChange' | 'title'>,
    DataProps,
    VariantProps<typeof checkboxVariants> {
  /** Optional props for the container */
  containerProps?: DivProps
  /** Element below label */
  description?: string | null
  /** Optional props for the description */
  descriptionProps?: TypographyProps
  /** Used for connecting label to checkbox */
  id: string
  /** Element to the right of checkbox */
  label?: ReactNode
  /** Optional props for the label container */
  labelContainerProps?: DivProps
  /** Optional props for the label */
  labelProps?: TypographyProps
  /** Optional function to trigger when the checked value changes */
  onCheckedChange?: (checked: boolean) => void
  /** Whether to truncate the label when it exceeds the container width */
  truncateLabel?: boolean
}

/**
 * Wraps the label in a tooltip if the label is truncated
 * @param children - The children to wrap
 * @param label - The label to check for truncation
 * @param labelRef - The ref of the label
 * @param truncateLabel - Whether to truncate the label
 * @param props - The props to pass to the tooltip
 * @returns The children wrapped in a tooltip if the label is truncated
 */
const LabelWrapper = ({
  children,
  label,
  labelRef,
  truncateLabel = false,
  ...props
}: {
  children: ReactNode
  label: ReactNode
  labelRef: React.RefObject<HTMLLabelElement>
  truncateLabel?: boolean
}): ReactNode => {
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    const labelElement = labelRef.current
    if (!labelElement) return

    // if the scrollWidth is greater than the clientWidth, then the label is truncated
    const truncated = truncateLabel && labelElement.scrollWidth > labelElement.clientWidth
    setIsTruncated(truncated)
  }, [labelRef, truncateLabel])

  // only show tooltip if the label is truncated
  if (isTruncated) {
    return (
      <Tooltip content={label} {...props}>
        {children}
      </Tooltip>
    )
  }
  return children
}

const Checkbox = forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  (
    {
      checked,
      className,
      containerProps,
      description,
      descriptionProps,
      id,
      label,
      labelContainerProps,
      labelProps,
      palette = 'secondary',
      truncateLabel = false,
      ...props
    },
    ref
  ) => {
    const CheckedIcon = checked === 'indeterminate' ? IconMinus : IconCheckMedium

    const { className: containerClassName, ...containerRest } = containerProps || {}
    const { className: labelContainerClassName, ...labelContainerRest } = labelContainerProps || {}
    const { className: labelClassName, ...labelRest } = labelProps || {}
    const { className: descriptionClassName, ...descriptionRest } = descriptionProps || {}

    const labelRef = useRef<HTMLLabelElement>(null)
    const labelId = `${id}-label`

    return (
      <div className={cn('flex items-center space-x-2', containerClassName)} {...containerRest}>
        <CheckboxPrimitive.Root
          ref={ref}
          id={id}
          checked={checked}
          className={cn(checkboxVariants({ palette }), className)}
          aria-labelledby={labelId}
          {...props}
        >
          <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
            <CheckedIcon className={`size-[18px] ${palette === 'tertiary' ? 'text-text-primary' : 'text-white'}`} />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {(label || description) && (
          <div
            className={cn(
              { 'text-white': palette === 'tertiary' },
              { 'overflow-hidden': truncateLabel },
              'cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:text-text-secondary',
              labelContainerClassName
            )}
            {...labelContainerRest}
          >
            {label && (
              <LabelWrapper label={label} labelRef={labelRef} truncateLabel={truncateLabel}>
                <Typography
                  asChild
                  variant='body1'
                  className={cn(
                    'block cursor-[inherit]',
                    {
                      'overflow-hidden text-ellipsis whitespace-nowrap': truncateLabel
                    },
                    labelClassName
                  )}
                  {...labelRest}
                >
                  <label htmlFor={id} id={labelId} ref={labelRef}>
                    {label}
                  </label>
                </Typography>
              </LabelWrapper>
            )}
            {description && (
              <Typography
                asChild
                variant='body2'
                className={cn('block cursor-[inherit]', descriptionClassName)}
                {...descriptionRest}
              >
                <label htmlFor={id}>{description}</label>
              </Typography>
            )}
          </div>
        )}
      </div>
    )
  }
)

Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
