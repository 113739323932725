import { t } from '@lingui/macro'
import { type PortfolioHealthRiskFactors } from '@strise/types'
import { Card, CardContent, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import {
  PortfolioHealthRiskFactorsCard,
  type RiskFactorKeys
} from '~/features/PortfolioHealth/PortfolioHealthRiskFactorsCard'

interface PortfolioHealthRiskFactorsSectionProps {
  riskFactors: PortfolioHealthRiskFactors
}

export const PortfolioHealthRiskFactorsSection = ({
  riskFactors
}: PortfolioHealthRiskFactorsSectionProps): ReactNode => {
  const filteredEntries = Object.entries(riskFactors).filter(([key]) => key !== '__typename')

  return (
    <Card size='sm' className='p-0 hover:border-gray-10 active:border-gray-10' palette='white'>
      <CardContent className='px-4 py-6'>
        <Typography variant='subtitle2' className='pb-4'>{t`Risk factors in portfolio`}</Typography>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
          {(filteredEntries as [RiskFactorKeys, string][]).map(([kind, found]) => (
            <PortfolioHealthRiskFactorsCard key={kind} riskFactor={{ kind, found }} />
          ))}
        </div>
      </CardContent>
    </Card>
  )
}
