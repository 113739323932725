import { Trans } from '@lingui/macro'
import { useContext } from '@strise/react-utils'
import { Button, IconReview, Typography } from '@strise/ui-components'
import type { ReactNode, Ref } from 'react'
import { ContentViewContext } from '~/components/Layout/ContentViewContext'
import { ReviewCompanyCard } from '~/features/Review/ReviewCompanyCard'
import { type BaseReviewCompanyFragment, type TeamReviewSettingsV2Fragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

export const ReviewCompanyList = ({
  companies,
  filterHeight,
  infiniteScrollItemRef,
  loading,
  teamId,
  teamReviewSettings,
  toggleFilterOpen
}: {
  companies: BaseReviewCompanyFragment[]
  filterHeight: number | undefined
  infiniteScrollItemRef: Ref<HTMLDivElement>
  loading: boolean
  teamId: string
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
  toggleFilterOpen: () => void
}): ReactNode => {
  const { isMediumScreen } = useContext(ContentViewContext)

  if (!companies.length && !loading) {
    return (
      <div className='mt-16 flex flex-col items-center justify-center text-center' data-id={TestIDs.Review.emptyState}>
        <IconReview className='mb-4 size-[84px] text-secondary-shade-30' />
        <Typography className='mb-4' variant='h2'>
          <Trans>No companies to show</Trans>
        </Typography>
        <Typography className='mb-4 w-3/4' variant='body1'>
          <Trans>Explore more companies by adjusting one of the many filters</Trans>
        </Typography>
        <Button
          variant='contained'
          palette='secondary'
          onClick={toggleFilterOpen}
          data-track='Review / Empty / Open filters'
        >
          <Trans>Open filters</Trans>
        </Button>
      </div>
    )
  }

  return (
    <div className={isMediumScreen ? 'pr-10' : undefined}>
      {companies.map((company) => (
        <ReviewCompanyCard
          ref={infiniteScrollItemRef}
          filterHeight={filterHeight}
          key={company.id}
          baseCompany={company}
          teamId={teamId}
          teamReviewSettings={teamReviewSettings}
        />
      ))}
    </div>
  )
}
