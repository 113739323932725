import { IconRadio, IconRadioSelected } from '../../icons/general'
import { cn } from '../../utils/className'
import { Typography } from '../Typography'
import type { ChangeEvent, HTMLAttributes, Ref } from 'react'
import { forwardRef } from 'react'

export interface RadioProps extends Omit<HTMLAttributes<HTMLLabelElement>, 'onChange'> {
  checked?: boolean
  disabled?: boolean
  id?: string
  label?: string
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  value?: string
}

// TODO - create palette variants with cva and support uncontrolled checked state
export const Radio = forwardRef(
  (
    { checked, className, disabled, id, label, name, onChange, value, ...props }: RadioProps,
    ref: Ref<HTMLInputElement>
  ) => (
    <label htmlFor={id} className={cn('flex gap-2 cursor-pointer', className)} {...props}>
      <input
        className='absolute -z-10 m-0 size-px overflow-hidden opacity-0'
        ref={ref}
        type='radio'
        id={id}
        name={name}
        value={value}
        checked={Boolean(checked)}
        aria-checked={Boolean(checked)}
        onChange={onChange}
        disabled={disabled}
      />
      {checked ? <IconRadioSelected /> : <IconRadio />}
      {label && (
        <Typography variant='body1' className={cn('block cursor-[inherit]')}>
          {label}
        </Typography>
      )}
    </label>
  )
)

Radio.displayName = 'Radio'
