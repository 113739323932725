import { NetworkStatus } from '@apollo/client/index.js'
import { Trans, t } from '@lingui/macro'
import { ReactRouterLink, toast } from '@strise/app-shared'
import { type SetStateFn } from '@strise/react-utils'
import { NotificationStatus } from '@strise/types'
import { Button, IconBell, IconButton, IconCheck, Skeleton, Typography, cn } from '@strise/ui-components'
import { type DropDownToggleEvent, Dropdown } from '@strise/ui-components-legacy'
import type { ForwardedRef, ReactNode } from 'react'
import { forwardRef, useEffect, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { DataSourceTooltip } from '~/components/DataSourceTooltip'
import { PaginationFooter } from '~/components/PaginationFooter'
import { HEADER_HEIGHT } from '~/constants'
import { Notification } from '~/features/Notifications/Notification'
import {
  useMarkAllNotificationsAsReadMutation,
  useNotificationsQuery,
  useNotificationsUnreadCountQuery,
  useUpdateNotificationsMutation
} from '~/graphqlOperations'
import {
  type MarkAllNotificationsAsReadMutation,
  type NotificationsQuery,
  type NotificationsUnreadCountQuery,
  type UpdateNotificationsMutation
} from '~/graphqlTypes'

const NOTIFICATIONS_POLLING_MS = 60_000

const ToggleButton = forwardRef(
  (
    {
      onClick,
      open,
      setDropdownOpen,
      unreadCount
    }: {
      onClick?: () => void
      open?: boolean
      setDropdownOpen: SetStateFn<boolean>
      unreadCount: number
    },
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    useEffect(() => {
      setDropdownOpen(open || false)
    }, [open])

    const unreadDotSize = 8

    const content = (
      <IconButton
        className={cn(
          'relative h-[calc(theme(height.header)-1px)] w-[theme(height.header)]',
          open ? 'bg-secondary-shade-90' : 'bg-tertiary-shade-5 hover:bg-tertiary-main'
        )}
        ref={ref}
        onClick={onClick}
        variant='contained'
        palette={open ? 'secondary' : 'tertiary'}
        data-track='Notifications / Toggle'
        data-id='Notifications / Bell'
        aria-label={t`Notifications`}
      >
        {!!unreadCount && (
          <span
            className='absolute bottom-[8px] rounded-full bg-semantic-danger-main'
            style={{
              width: unreadDotSize,
              height: unreadDotSize,
              left: HEADER_HEIGHT / 2 - unreadDotSize / 2
            }}
          />
        )}

        <IconBell />
      </IconButton>
    )

    if (open) return content

    return (
      <DataSourceTooltip key='notifications-tooltip' content={t`Notifications`} aria-label={t`Notifications`}>
        {content}
      </DataSourceTooltip>
    )
  }
)

const NotificationsActions = ({
  hasUnread,
  offset,
  size,
  updateUnreadCount
}: {
  hasUnread: boolean
  offset: number
  size: number
  updateUnreadCount: (newUnreadCount?: number | null) => void
}) => {
  const handleCompleted = (data: MarkAllNotificationsAsReadMutation): void => {
    updateUnreadCount(data.markAllNotificationsAsRead.notifications.unreadCount)
    toast.success(t`All notifications marked as read`)
  }
  const [markAllAsRead, { loading }] = useMarkAllNotificationsAsReadMutation({
    variables: { size, offset },
    onCompleted: handleCompleted
  })

  const handleMarkAllAsRead = async (): Promise<void> => {
    await markAllAsRead()
  }

  return (
    <Button
      size='sm'
      variant='ghost'
      palette='tertiary'
      loading={loading}
      className='text-white hover:text-text-primary'
      startIcon={<IconCheck size='sm' className='mr-1' />}
      onClick={handleMarkAllAsRead}
      data-track='Notifications / Mark all as read'
      disabled={!hasUnread}
    >
      <Trans>Mark all as read</Trans>
    </Button>
  )
}

const size = 20

export const Notifications = (): ReactNode => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [offset, setOffset] = useState(0)
  const [unreadCount, setUnreadCount] = useState(0)

  const updateUnreadCount = (newUnreadCount?: number | null): void =>
    setUnreadCount((prevUnreadCount) => newUnreadCount ?? prevUnreadCount)

  const handleUnreadQueryCompleted = (queryData: NotificationsUnreadCountQuery): void => {
    updateUnreadCount(queryData.notifications.unreadCount)
  }

  const handleQueryCompleted = (queryData: NotificationsQuery): void => {
    updateUnreadCount(queryData.notifications.unreadCount)
  }

  const {
    networkStatus: unreadNetworkStatus,
    startPolling: unreadStartPolling,
    stopPolling: unreadStopPolling
  } = useNotificationsUnreadCountQuery({
    pollInterval: NOTIFICATIONS_POLLING_MS,
    notifyOnNetworkStatusChange: true,
    onCompleted: handleUnreadQueryCompleted
  })

  const { data, fetchMore, loading, networkStatus, refetch } = useNotificationsQuery({
    variables: { size, offset: 0 },
    skip: !dropdownOpen,
    notifyOnNetworkStatusChange: true,
    onCompleted: handleQueryCompleted,
    fetchPolicy: 'network-only'
  })

  const hasMoreNotifications = data?.notifications.edges.length === size + offset

  useEffect(() => {
    if (dropdownOpen) {
      unreadStopPolling()
    } else {
      unreadStartPolling(NOTIFICATIONS_POLLING_MS)
    }
  }, [dropdownOpen])

  const pollLoading = loading && unreadNetworkStatus === NetworkStatus.poll

  const refetchLoading = loading && networkStatus === NetworkStatus.refetch
  const fetchMoreLoading = loading && networkStatus === NetworkStatus.fetchMore
  const showToggleButtonLoader = loading && !refetchLoading && !pollLoading && !fetchMoreLoading
  const showFooterLoader = loading && (showToggleButtonLoader || fetchMoreLoading)

  const notifications = data?.notifications.edges ?? []

  const handleMutationCompleted = (mutationData: UpdateNotificationsMutation): void =>
    updateUnreadCount(mutationData.updateNotifications.notifications.unreadCount)
  const [update] = useUpdateNotificationsMutation({ onCompleted: handleMutationCompleted })

  useEffect(() => {
    if (offset === 0) return

    fetchMore({ variables: { offset, size } })
  }, [offset])

  // Making sure we reset 'offset' state when closing dropdown, we're doing this to prevent pagination issues when reopening
  useEffect(() => {
    if (dropdownOpen) return
    setOffset(0)
  }, [dropdownOpen])

  const handleNextPage = (): void => {
    setOffset((prevOffset) => prevOffset + size)
  }

  const handleToggle = async (open: boolean, event?: DropDownToggleEvent): Promise<void> => {
    if (open) return

    // Should only mark the clicked event as unread if closing by clicking a notification
    const notificationId = event && 'dataset' in event.currentTarget ? event.currentTarget.dataset.notificationId : null

    const unreadNotifications = notifications.filter(
      ({ node: notification }) =>
        notification.status === NotificationStatus.Unread && (!notificationId || notificationId === notification.id)
    )
    const unreadIds = unreadNotifications.map(({ node: notification }) => notification.id)

    if (!unreadIds.length) return

    await update({
      variables: {
        notifications: unreadIds,
        size,
        offset,
        status: NotificationStatus.Read
      }
    })
  }

  const [infiniteScrollItemRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMoreNotifications,
    onLoadMore: handleNextPage,
    disabled: loading,
    rootMargin: '0px 0px 30px 0px'
  })

  return (
    <Dropdown
      ToggleComponent={<ToggleButton unreadCount={unreadCount} setDropdownOpen={setDropdownOpen} />}
      paperProps={{ className: 'min-w-[560px]' }}
      onToggle={handleToggle}
    >
      {({ toggle }) => (
        <>
          <div className='flex w-full items-center justify-between pb-2 pl-4 pr-2 pt-5'>
            <Typography variant='aLabelBold' component='div'>
              <Trans>Notifications</Trans>
            </Typography>
            <NotificationsActions
              size={size}
              offset={offset}
              updateUnreadCount={updateUnreadCount}
              hasUnread={unreadCount > 0}
            />
          </div>
          <div className='flex max-h-[70vh] flex-col gap-2 overflow-auto'>
            {notifications.map(({ node: notification }) => (
              <Notification
                ref={infiniteScrollItemRef}
                key={notification.id}
                notification={notification}
                toggle={toggle}
                size={size}
                offset={offset}
                refetchNotifications={refetch}
                theme='dark'
              />
            ))}
            {!notifications.length && !showFooterLoader && (
              <Typography className='w-full pb-4 text-center'>
                <Trans>No notifications</Trans>
              </Typography>
            )}

            {showFooterLoader && (
              <div className='m-4 grid gap-4'>
                {Array.from({ length: 2 }).map((_, index) => {
                  return (
                    <Skeleton
                      className='h-12 w-full animate-pulse bg-tertiary-shade-50'
                      animation='pulse'
                      key={index}
                    />
                  )
                })}
              </div>
            )}
            {!!notifications.length && !showFooterLoader && (
              <PaginationFooter
                nextPage={handleNextPage}
                hasMore={hasMoreNotifications}
                hideNoMore
                buttonProps={{
                  className: 'w-full bg-secondary-90 text-primary-40 h-12',
                  variant: 'ghost'
                }}
              />
            )}
          </div>
          <ReactRouterLink
            to='/notifications'
            className='flex items-center justify-center py-4 text-semanticBlue-50'
            // Close dropdown when navigating to notifications page
            onClick={() => toggle()}
          >
            <Trans>See all notifications</Trans>
          </ReactRouterLink>
        </>
      )}
    </Dropdown>
  )
}
