import { Trans } from '@lingui/macro'
import { Typography, cn } from '@strise/ui-components'
import type { ForwardedRef, ReactNode } from 'react'
import { forwardRef } from 'react'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { GrowAccountantFilter } from '~/features/Grow/GrowAccountantFilter'
import { GrowAssigneesFilter } from '~/features/Grow/GrowAssigneesFilter'
import { GrowCollateralCreditorFilter } from '~/features/Grow/GrowCollateralCreditorFilter'
import { GrowEmployeeFilter } from '~/features/Grow/GrowEmployeeFilter'
import { GrowFlagFilter } from '~/features/Grow/GrowFlagFilter'
import { GrowInceptionFilter } from '~/features/Grow/GrowInceptionFilter'
import { GrowLegalFormFilter } from '~/features/Grow/GrowLegalFormFilter'
import { GrowRealEstateCollateralCreditorFilter } from '~/features/Grow/GrowRealEstateCollateralCreditorFilter'
import { GrowSettingsFinancials } from '~/features/Grow/GrowSettingsFinancials'
import { GrowTagsFilter } from '~/features/Grow/GrowTagsFilter'
import { GrowIndustryFilter } from '~/features/Grow/Industry/GrowIndustryFilter'
import { GrowLocationRestriction } from '~/features/Grow/Location/GrowLocationRestriction'

export const GrowSettingsPanel = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const features = useCurrentUserFeatures()

  return (
    <div className='flex flex-col gap-4' ref={ref}>
      <div className='flex flex-col gap-4 rounded bg-background-paper p-8'>
        <div className='flex flex-col gap-2'>
          <Typography variant='subtitle1'>
            <Trans>Portfolio</Trans>
          </Typography>
          <Typography variant='body2'>
            <Trans>
              Results are based on Team Portfolio. Use assignees and/or tags to filter the portfolio and narrow the
              results.
            </Trans>
          </Typography>
        </div>
        <GrowAssigneesFilter />
        <GrowTagsFilter />
      </div>

      <div className='grid gap-8 rounded bg-background-paper p-8'>
        <div className='flex flex-col gap-2'>
          <Typography variant='subtitle1'>
            <Trans>Filters</Trans>
          </Typography>
          <Typography variant='body2'>
            <Trans>
              Apply filters to tweak the results for Grow opportunities. Adjustments are also reflected in the side
              panel.
            </Trans>
          </Typography>
        </div>
        <GrowLocationRestriction />
        <GrowSettingsFinancials />
        <GrowIndustryFilter />
        <GrowEmployeeFilter />
        <GrowInceptionFilter />
        <GrowAccountantFilter />
        {features.CREDIT_DATA && <GrowCollateralCreditorFilter />}
        {features.REAL_ESTATE_DATA_KARTVERKET && <GrowRealEstateCollateralCreditorFilter />}
        <GrowLegalFormFilter />
        <GrowFlagFilter />
      </div>
    </div>
  )
})

interface SettingProps {
  children: ReactNode
  className?: string
  description?: string
  title: string
}

export const GrowSettingsSections = ({
  children,
  className,
  description,
  title,
  ...props
}: SettingProps): ReactNode => {
  return (
    <div className={cn('flex flex-col gap-2', className)} {...props}>
      <div>
        <Typography variant='subtitle2'>{title}</Typography>
        {description && (
          <Typography className='mb-4 block text-text-secondary' variant='aLabelSmall'>
            {description}
          </Typography>
        )}
      </div>
      {children}
    </div>
  )
}
