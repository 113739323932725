import { IconOpenInTab } from '../../icons/general'
import { cn } from '../../utils/className'
import { type IconComponent, type IconPropsWithRef } from '../../utils/iconUtils'
import { Button, type ButtonProps, IconButton } from '../Button'
import { Typography, type TypographyProps } from '../Typography'
import { type DataProps } from '@strise/react-utils'
import type { AnchorHTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'

export type LinkProps = TypographyProps & AnchorHTMLAttributes<HTMLAnchorElement> & DataProps

export const Link = forwardRef<HTMLElement, LinkProps>(
  ({ className, ...props }, ref): ReactNode => (
    <Typography className={cn('text-inherit hover:underline', className)} ref={ref} component='a' {...props} />
  )
)

Link.displayName = 'Link'

export type LinkButtonProps = ButtonProps & Omit<LinkProps, 'variant'>

export const LinkButton = forwardRef<HTMLElement, LinkButtonProps>(
  ({ children, className, ...props }, ref): ReactNode => (
    <Button className={className} asChild variant='ghost' palette='secondary' {...props}>
      <Link ref={ref} className='no-underline'>
        {children}
      </Link>
    </Button>
  )
)

LinkButton.displayName = 'LinkButton'

export type ExternalLinkProps = {
  Icon?: IconComponent | null
  href: string
  iconProps?: IconPropsWithRef
} & LinkProps

export const ExternalLink = ({
  Icon = IconOpenInTab,
  children,
  className,
  href,
  iconProps,
  ...props
}: ExternalLinkProps): ReactNode => {
  const transformedHref = href.startsWith('http') ? href : `https://${href}`
  return (
    <Link
      className={cn('underline', className)}
      target='_blank'
      rel='noopener noreferrer'
      href={transformedHref}
      {...props}
    >
      {children}
      {Icon && <Icon size='sm' {...iconProps} className={cn('ml-1', iconProps?.className)} />}
    </Link>
  )
}

type ExternalLinkIconButtonProps = Pick<ExternalLinkProps, 'href'> & ButtonProps

export const ExternalLinkIconButton = ({
  children,
  className,
  href,
  ...props
}: ExternalLinkIconButtonProps): ReactNode => (
  <IconButton asChild className={cn('no-underline', className)} {...props}>
    <ExternalLink Icon={null} href={href}>
      {children}
    </ExternalLink>
  </IconButton>
)
