import { t } from '@lingui/macro'
import { languageToLocale, toast } from '@strise/app-shared'
import { ContentType, triggerDownload } from '@strise/react-utils'
import {
  Button,
  Checkbox,
  DatePickerRange,
  type DateRange,
  IconDownload,
  IconExport,
  LoaderRound,
  Modal,
  Tooltip,
  Typography
} from '@strise/ui-components'
import '@strise/ui-components/src/react-day-picker.css'
import { endOfDay, startOfDay } from 'date-fns'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { useDisplayLanguage } from '~/contexts/DisplayLanguageContext/displayLanguageContextUtils'
import { useTeamUsageReportCsvLazyQuery } from '~/graphqlOperations'
import { type TeamUsageReportCsvQuery } from '~/graphqlTypes'

interface GenerateTeamUsageReportProps {
  teamId: string
  teamName: string
}

interface ReportOption {
  id: string
  key: string
  label: string
}

interface ReportSection {
  options: ReportOption[]
  title: string
}

export const GenerateTeamUsageReport = ({ teamId, teamName }: GenerateTeamUsageReportProps): ReactNode => {
  const [dateRange, setDateRange] = useState<DateRange | undefined>()
  const [open, setOpen] = useState(false)
  const displayLanguage = useDisplayLanguage()
  const locale = languageToLocale[displayLanguage]

  const REPORT_SECTIONS: ReportSection[] = [
    {
      title: t`General information`,
      options: [
        { id: 'company-id', label: t`Company ID`, key: 'companyId' },
        { id: 'roles-individuals', label: t`Roles and individuals`, key: 'rolesAndIndividuals' },
        { id: 'country', label: t`Country`, key: 'country' },
        { id: 'industry-type', label: t`Industry type`, key: 'industryType' },
        { id: 'legal-form', label: t`Legal form`, key: 'legalForm' },
        { id: 'auditor', label: t`Auditor`, key: 'auditor' }
      ]
    },
    {
      title: t`Risk factors`,
      options: [
        { id: 'fcrr', label: t`FCRR`, key: 'fcrr' },
        { id: 'sanctions', label: t`Sanctions`, key: 'sanctions' },
        { id: 'status', label: t`Status`, key: 'status' },
        { id: 'status-changes', label: t`Status changes`, key: 'statusChanges' },
        { id: 'peps', label: t`PEPs`, key: 'peps' },
        { id: 'ams', label: t`AMS`, key: 'ams' },
        { id: 'flags', label: t`Flags`, key: 'flags' }
      ]
    },
    {
      title: t`Review details`,
      options: [
        { id: 'assignees', label: t`Assignees`, key: 'assignees' },
        { id: 'review-added-completed', label: t`Review added/completed`, key: 'reviewAddedCompleted' },
        { id: 'tags', label: t`Tags`, key: 'tags' },
        { id: 'review-risk-level', label: t`Review risk level`, key: 'reviewRiskLevel' },
        { id: 'next-upcoming-review', label: t`Next upcoming review`, key: 'nextUpcomingReview' },
        { id: 'comments', label: t`Comments`, key: 'comments' }
      ]
    }
  ]

  const [selectAll, setSelectAll] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(() =>
    Object.fromEntries(REPORT_SECTIONS.flatMap((section) => section.options.map((option) => [option.key, false])))
  )

  const handleSelectAll = (checked: boolean) => {
    setSelectAll(checked)
    setSelectedOptions(Object.fromEntries(Object.keys(selectedOptions).map((key) => [key, checked])))
  }

  const handleOptionChange = (key: string, checked: boolean) => {
    const newOptions = { ...selectedOptions, [key]: checked }
    setSelectedOptions(newOptions)
    setSelectAll(Object.values(newOptions).every(Boolean))
  }

  const handleDownload = (data: TeamUsageReportCsvQuery) => {
    try {
      if (!data.teamUsageReportCsv) {
        toast.error('No data to download')
        return
      }
      if (!dateRange?.from || !dateRange.to) {
        toast.error('Please select a date range')
        return
      }
      const teamUsageReportCsvTitle = `${teamName}_from:${dateRange.from.toISOString()}_to:${dateRange.to.toISOString()}`
      triggerDownload(data.teamUsageReportCsv, teamUsageReportCsvTitle, ContentType.CSV)
      setDateRange(undefined)
      toast.success(t`Report downloaded successfully`)
      setOpen(false)
    } catch (error) {
      console.error(error)
      toast.error(t`Error downloading report`)
    }
  }

  const [fetchCsv, { loading: csvLoading }] = useTeamUsageReportCsvLazyQuery({
    onCompleted: handleDownload,
    fetchPolicy: 'no-cache'
  })

  const handleConfirm = async (): Promise<void> => {
    if (!dateRange?.from || !dateRange.to) {
      toast.error(t`Please select a date range`)
      return
    }
    // Use start of day to include the full day
    const from = startOfDay(dateRange.from).toISOString()
    // Use end of day to include the full day
    const to = endOfDay(dateRange.to).toISOString()

    await fetchCsv({
      variables: {
        teamUsageReportCsvId: teamId,
        from,
        to
      }
    })
  }

  return (
    <>
      <Tooltip content={t`Export the team usage report`}>
        <Button
          variant='outlined'
          palette='tertiary'
          className='rounded-sm'
          data-track='Home / Export'
          onClick={() => setOpen(true)}
        >
          {t`Export`} <IconExport className='size-4 ml-2' />
        </Button>
      </Tooltip>
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        title={t`Export team usage report`}
        actions={
          <>
            <Button
              data-track='Export Report / Cancel'
              variant='outlined'
              palette='tertiary'
              onClick={() => setOpen(false)}
            >
              {t`Cancel`}
            </Button>
            <Button
              variant='contained'
              palette='primary'
              disabled={!dateRange || csvLoading}
              onClick={handleConfirm}
              data-track='Portfolio Health / Generate team usage report'
            >
              {csvLoading && <LoaderRound palette='primary' size='sm' className='absolute left-1/2' />}
              {t`Download report`} <IconDownload className='size-4 ml-2' />
            </Button>
          </>
        }
      >
        <div className='max-w-sm'>
          <DatePickerRange
            placeholder={t`Select the date range for the report`}
            value={dateRange}
            onChange={setDateRange}
            locale={locale}
            disabled={csvLoading}
          />
          {dateRange && (
            <Typography variant='aLabelSmall'>
              {t`Confirm the date range is correct before generating the report`}
            </Typography>
          )}
        </div>

        <div>
          <div className='pb-2 pt-4'>
            <Typography variant='aLabelBold'>{t`Select report options`}</Typography>
          </div>
          <Checkbox
            id='select-all'
            label={t`All`}
            checked={selectAll}
            onCheckedChange={handleSelectAll}
            data-track='Export Report / Select All'
          />
          <div className='grid grid-cols-3 gap-x-8 py-2'>
            {REPORT_SECTIONS.map((section) => (
              <div key={section.title} className='space-y-2'>
                <Typography variant='aLabelBold' className='text-text-secondary'>
                  {section.title}
                </Typography>

                {section.options.map((option) => (
                  <Checkbox
                    key={option.id}
                    id={option.id}
                    label={option.label}
                    checked={selectedOptions[option.key]}
                    onCheckedChange={(checked) => handleOptionChange(option.key, checked)}
                    data-track={`Export Report / ${section.title} / ${option.label}`}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  )
}
