import { NationFlag } from '@strise/app-shared'
import { Tooltip } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { Fragment } from 'react'
import { type CountryFragment } from '~/graphqlTypes'

export const Countries = ({ countries }: { countries: CountryFragment[] }): ReactNode[] => {
  return countries.map((country, index) => {
    const isLast = index === countries.length - 1

    const content = (
      <div className='flex gap-1'>
        <NationFlag countryIsoCode={country.isoAlpha2Code} className='size-4' />
        <span>{country.englishName}</span>
      </div>
    )

    return (
      <Fragment key={country.isoAlpha2Code}>
        <Tooltip content={content}>
          <span>{country.isoAlpha2Code}</span>
        </Tooltip>
        {!isLast && <span>, </span>}
      </Fragment>
    )
  })
}
