import { AuthContext, type AuthUser } from './AuthContext'
import { AuthError, auth0ErrorToInternalCode } from './authError'
import { logoutWithError } from './authUtils'
import {
  type Auth0Client,
  type Auth0ClientOptions,
  type User as Auth0User,
  AuthenticationError,
  createAuth0Client
} from '@auth0/auth0-spa-js'
import { getBrowserGlobals } from '@strise/react-utils'
import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const TOKEN_CACHE_LOCATION =
  getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.NODE_ENV === 'development' || process.env.NODE_ENV === 'development'
    ? 'localstorage'
    : 'memory'

interface AuthProviderBaseProps {
  children: ReactNode
  onLogout?: (errorCode?: AuthError, errorContext?: string) => PromiseLike<boolean> | undefined
  options: Auth0ClientOptions
}

interface AuthProviderProps extends AuthProviderBaseProps {
  isNextJs?: boolean
}

interface AuthProviderContentProps extends AuthProviderBaseProps {
  redirectFunction: (redirectUrl: string) => void
}

export const AuthProvider = ({ children, isNextJs = false, onLogout, options }: AuthProviderProps): ReactNode => {
  return isNextJs ? (
    <NextJsRouterAuthProvider options={options} onLogout={onLogout}>
      {children}
    </NextJsRouterAuthProvider>
  ) : (
    <ReactRouterAuthProvider options={options} onLogout={onLogout}>
      {children}
    </ReactRouterAuthProvider>
  )
}

const NextJsRouterAuthProvider = ({ children, onLogout, options }: AuthProviderBaseProps): ReactNode => {
  const router = useRouter()

  return (
    <AuthProviderContent
      options={options}
      redirectFunction={async (redirectUrl) => await router.push(redirectUrl)}
      onLogout={onLogout}
    >
      {children}
    </AuthProviderContent>
  )
}
const ReactRouterAuthProvider = ({ children, onLogout, options }: AuthProviderBaseProps): ReactNode => {
  const navigate = useNavigate()

  return (
    <AuthProviderContent
      options={options}
      redirectFunction={(redirectUrl) => navigate(redirectUrl, { replace: true })}
      onLogout={onLogout}
    >
      {children}
    </AuthProviderContent>
  )
}

export const AuthProviderContent = ({
  children,
  onLogout,
  options,
  redirectFunction
}: AuthProviderContentProps): ReactNode => {
  const [auth0, setAuth0] = useState<Auth0Client | null>(null)
  const [authenticated, setAuthenticated] = useState(false)
  const [user, setUser] = useState<AuthUser | null>(null)
  const [tokenMeta, setTokenMeta] = useState<Auth0User | null>(null)
  const [loading, setLoading] = useState(true)

  const handleRedirect = useCallback(
    (redirectUrl: string = '/') => {
      redirectFunction(redirectUrl)
    },
    [redirectFunction]
  )

  useEffect(() => {
    const init = async (): Promise<void> => {
      const auth0Client = await createAuth0Client({
        cacheLocation: TOKEN_CACHE_LOCATION,
        ...options
      })
      setAuth0(auth0Client)

      try {
        const queryParams = new URLSearchParams(getBrowserGlobals()?.window.location.search)
        const code = queryParams.get('code')
        const state = queryParams.get('state')
        const connection = queryParams.get('connection')

        if (code && state) {
          const { appState } = await auth0Client.handleRedirectCallback<{ redirectUrl: string | undefined }>()
          handleRedirect(appState?.redirectUrl)
        } else if (connection) {
          // If there is no state param, this is an IdP initiated log in.  Redirect
          // to Auth0 to get the token.
          await auth0Client.loginWithRedirect({
            authorizationParams: {
              connection,
              redirect_uri: getBrowserGlobals()?.window.location.origin
            }
          })
        }
      } catch (err) {
        console.error(err)
        handleRedirect(
          `/?error=${err instanceof AuthenticationError ? auth0ErrorToInternalCode(err) : AuthError.Generic}`
        )
      }

      const isAuthenticated = await auth0Client.isAuthenticated()

      if (isAuthenticated) {
        const decodedToken = await auth0Client.getUser()

        if (!decodedToken) {
          await logoutWithError({
            auth0: auth0Client,
            onLogout,
            errorCode: AuthError.AuthenticationFailed,
            errorContext: 'Failed to get user from auth0 "auth0Client.getUser()"'
          })
          return
        }

        setTokenMeta(decodedToken)
        setAuthenticated(true)
      }

      setLoading(false)
    }

    init()
    // Disabling as we don't want dependencies here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextValue = useMemo(
    () => ({
      auth0,
      authenticated,
      user,
      setUser,
      tokenMeta,
      onLogout
    }),
    // Ignoring rule as we use JSON.stringify: https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth0, authenticated, JSON.stringify(user), JSON.stringify(tokenMeta)]
  )

  if (loading) return null

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
