import { SlideToast } from './SlideToast'
import { toast } from './toast'
import type { ReactNode } from 'react'
import { Toaster as ToasterComponent, resolveValue } from 'react-hot-toast'

const typeToVariant = (type: string): 'success' | 'error' => {
  if (type === 'success' || type === 'error') return type
  return 'success'
}

export const Toaster = (): ReactNode => (
  <ToasterComponent
    position='bottom-right'
    toastOptions={{
      duration: 7000
    }}
  >
    {(item) => (
      <SlideToast
        variant={typeToVariant(item.type)}
        label={resolveValue(item.message, item)}
        onClose={() => toast.dismiss(item.id)}
        visible={item.visible}
        data-id='app-toast'
      />
    )}
  </ToasterComponent>
)
