import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconBell = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M11 3.082V1h2v2.082a6.124 6.124 0 0 1 4.94 4.556L21.28 21H13v2h-2v-2H2.718l3.34-13.362A6.124 6.124 0 0 1 11 3.082ZM12 5a4.123 4.123 0 0 0-4 3.123L5.28 19H18.72l-2.72-10.877A4.123 4.123 0 0 0 12 5Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconBell.displayName = 'IconBell'
