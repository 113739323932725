import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { TeamSettingsContext } from '~/contexts/TeamSettingsContext/TeamSettingsContext'
import { useTeamSettingsQuery } from '~/graphqlOperations'

export const TeamSettingsContextProvider = ({ children }: { children: ReactNode }) => {
  const { data, loading, refetch } = useTeamSettingsQuery({
    variables: {},
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  const value = useMemo(() => ({ data, loading, refetch }), [data, loading, refetch])

  return <TeamSettingsContext.Provider value={value}>{children}</TeamSettingsContext.Provider>
}
