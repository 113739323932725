import { type SetStateFn } from '@strise/react-utils'
import { Dialog, DialogContent, cn } from '@strise/ui-components'
import { type DialogProps } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { PepDispositionAuditTrail } from '~/features/PepAndSanctions/PepDispositionAuditTrail'
import { PepDispositionComparison } from '~/features/PepAndSanctions/PepDispositionComparison'
import { PepDispositionList } from '~/features/PepAndSanctions/PepDispositionList'
import { dialogBgColor } from '~/features/PepAndSanctions/dispositionDialogUtils'
import { type PepInfoFragment, type PersonBaseFragment } from '~/graphqlTypes'

interface PepDispositionDialogProps extends Omit<DialogProps, 'isOpen'> {
  pepInfos: PepInfoFragment[]
  person: PersonBaseFragment
  setOpen: SetStateFn<boolean>
}

export const PepDispositionDialog = ({ pepInfos, person, setOpen, ...props }: PepDispositionDialogProps): ReactNode => {
  const defaultValue = pepInfos.length === 1 && pepInfos[0] ? pepInfos[0] : null
  const [isAuditTrailOpen, setIsAuditTrailOpen] = useState(false)
  const [comparePepInfo, setComparePepInfo] = useState<PepInfoFragment | null>(defaultValue)

  // Render both dialogs to keep the state when switching views
  return (
    <Dialog onOpenChange={setOpen} open>
      <DialogContent
        className={cn(dialogBgColor, 'min-w-[640px] max-w-[856px] min-h-[480px] overflow-auto')}
        // To prevent triggering of tooltips and popovers inside the dialog on open
        onOpenAutoFocus={(event) => event.preventDefault()}
      >
        <PepDispositionComparison
          person={person}
          comparePepInfo={comparePepInfo}
          pepInfos={pepInfos}
          setOpen={setOpen}
          isAuditTrailOpen={isAuditTrailOpen}
          setIsAuditTrailOpen={setIsAuditTrailOpen}
          setComparePepInfo={defaultValue ? null : setComparePepInfo}
          {...props}
        />
        <PepDispositionList
          person={person}
          pepInfos={pepInfos}
          isAuditTrailOpen={isAuditTrailOpen}
          setIsAuditTrailOpen={setIsAuditTrailOpen}
          comparePepInfo={comparePepInfo}
          setComparePepInfo={setComparePepInfo}
          {...props}
        />
        <PepDispositionAuditTrail
          person={person}
          isAuditTrailOpen={isAuditTrailOpen}
          setIsAuditTrailOpen={setIsAuditTrailOpen}
          {...props}
        />
      </DialogContent>
    </Dialog>
  )
}
