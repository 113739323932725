import { type StepperStep } from './Stepper'
import { type DivProps } from '@strise/react-utils'
import { Typography, cn } from '@strise/ui-components'
import { isNil } from 'lodash-es'

interface StepHeaderProps extends DivProps {
  currentStepIndex: number | null
  onChange?: (stepIndex: number) => void
  step: StepperStep
  stepIndex: number
}

export const StepHeader = ({ currentStepIndex, onChange, step, stepIndex, ...props }: StepHeaderProps) => {
  const handleStepClick = (): void => {
    if (step.clickEnabled && onChange) {
      onChange(stepIndex)
    }
  }

  return (
    <div
      className={`flex items-center py-1 ${
        step.clickEnabled ? 'pointer-events-auto cursor-pointer' : 'pointer-events-none'
      }`}
      onClick={() => handleStepClick()}
      {...props}
    >
      <div
        className={cn(
          `mr-2 flex size-[30px] items-center justify-center rounded-[20px] p-2`,
          { 'bg-background-paper border-2 border-divider': isNil(currentStepIndex) },
          { 'bg-primary-main': !isNil(currentStepIndex) && stepIndex <= currentStepIndex },
          { 'bg-secondary-shade-30': !isNil(currentStepIndex) && stepIndex > currentStepIndex }
        )}
      >
        <Typography
          className={
            !isNil(currentStepIndex) && stepIndex <= currentStepIndex
              ? 'text-text-primaryContrast'
              : 'text-text-primary'
          }
        >
          {stepIndex + 1}
        </Typography>
      </div>
      <div>
        <Typography>{step.title}</Typography>
        {step.label && (
          <Typography variant='aLabelSmallBold' className='absolute w-2/3 truncate'>
            {step.label}
          </Typography>
        )}
      </div>
    </div>
  )
}
