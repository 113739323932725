import { Trans } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { forwardRef, useEffect, useState } from 'react'
import { Ownerships } from '~/features/Ownerships/Ownerships'
import { useOwners } from '~/features/Ownerships/ownershipChartUtils'
import { type EntityLikeMetaFragment } from '~/graphqlTypes'

interface ExportOwnershipsProps extends DivProps {
  entity: EntityLikeMetaFragment
  includeFlags?: boolean
  includeStatus?: boolean
  includeSubsidiaryCount?: boolean
  onLoaded: (hasCustomOwners: boolean) => void
}

export const ExportOwnerships = forwardRef<HTMLDivElement, ExportOwnershipsProps>(
  ({ entity, includeFlags, includeStatus, includeSubsidiaryCount, onLoaded }, ref): ReactNode => {
    const { hasCustomOwners } = useOwners(entity, 5, false)
    const [hasLoadedOriginal, setHasLoadedOriginal] = useState(false)
    const [hasLoadedCustom, setHasLoadedCustom] = useState(false)

    useEffect(() => {
      if (hasLoadedOriginal && (hasLoadedCustom || !hasCustomOwners)) {
        onLoaded(hasCustomOwners)
      }
    }, [hasLoadedOriginal, hasLoadedCustom])

    const baseOwnershipsProps = {
      entity,
      above25: false,
      editMode: false,
      noFlags: !includeFlags,
      noStatus: !includeStatus,
      noLink: true,
      noSubsidiaryCount: !includeSubsidiaryCount,
      isExport: true,
      disableMinimap: true,
      disableControls: true,
      autoSize: true
    }

    return (
      <div className='absolute' style={{ left: -10_000 }}>
        <div className='grid place-items-center gap-4' ref={ref}>
          {hasCustomOwners && (
            <Typography variant='h3'>
              <Trans>Original</Trans>
            </Typography>
          )}

          <Ownerships {...baseOwnershipsProps} showOriginal onChartLoaded={() => setHasLoadedOriginal(true)} />
          {hasCustomOwners && (
            <>
              <Typography variant='h3'>
                <Trans>Edited</Trans>
              </Typography>
              <Ownerships
                {...baseOwnershipsProps}
                showOriginal={false}
                onChartLoaded={() => {
                  setHasLoadedCustom(true)
                }}
              />
            </>
          )}
        </div>
      </div>
    )
  }
)
