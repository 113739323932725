import { ApolloClientProvider as AppSharedClientProvider } from '@strise/app-shared'
import { useContext } from '@strise/react-utils'
import type { ReactNode } from 'react'
import { ApolloClientContext } from '~/apolloClient/ApolloClientContext'
import { ApolloClientProvider } from '~/apolloClient/ApolloClientProvider'

const ApolloClientWrapper = ({ children }: { children: ReactNode }) => {
  const { client, link } = useContext(ApolloClientContext)

  if (!client) return null

  return (
    <AppSharedClientProvider client={client} link={link}>
      {children}
    </AppSharedClientProvider>
  )
}

export const ApolloClientContextProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ApolloClientProvider>
      <ApolloClientWrapper>{children}</ApolloClientWrapper>
    </ApolloClientProvider>
  )
}
