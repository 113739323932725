import { Trans } from '@lingui/macro'
import { NationFlag } from '@strise/app-shared'
import { Combobox, type ComboboxItem, Typography } from '@strise/ui-components'
import { type ReactNode } from 'react'
import { type RoleTitleFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

interface Props {
  isCompany: boolean
  isLoading: boolean
  onAdd: (role: ComboboxItem<RoleTitleFragment>) => void
  roleTitles: RoleTitleFragment[]
  selectedRoleTitle?: RoleTitleFragment
}

export const RoleTitlesCombobox = ({
  isCompany,
  isLoading,
  onAdd,
  roleTitles,
  selectedRoleTitle
}: Props): ReactNode => {
  return (
    <Combobox
      data-track={`Add Role / Search Roles / ${isCompany ? 'Company' : 'Person'}`}
      singleSelect
      closeOnSelect
      variant='ghost'
      loading={isLoading}
      data-id={TestIDs.SidePanel.Roles.searchRoleTitleTrigger}
      popoverProps={{ 'data-id': TestIDs.SidePanel.Roles.searchRoleTitleResults }}
      value={
        selectedRoleTitle
          ? [
              {
                id: selectedRoleTitle.id,
                label: selectedRoleTitle.name,
                value: selectedRoleTitle,
                renderNode: (
                  <div className='flex items-center gap-2'>
                    <NationFlag countryIsoCode={selectedRoleTitle.country?.isoAlpha2Code} /> {selectedRoleTitle.name}
                  </div>
                )
              }
            ]
          : []
      }
      onAdd={onAdd}
      items={roleTitles.map((role) => ({
        id: role.id,
        label: role.name,
        value: role,
        renderNode: (
          <div className='flex items-center gap-2'>
            <NationFlag countryIsoCode={role.country?.isoAlpha2Code} /> {role.name}
          </div>
        )
      }))}
    >
      <Typography variant='aLabel' className='ml-[-8px] text-gray-40'>
        <Trans>Select role</Trans>
      </Typography>
    </Combobox>
  )
}
