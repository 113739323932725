import { cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { Timespan } from '~/components/Timespan'
import { useIsReview } from '~/contexts/RenderContext/RenderContext'
import { PersonReviewInfo } from '~/features/Review/PersonReviewInfo'
import { type BaseEntityLikeFragment, type RoleMetaFragment } from '~/graphqlTypes'
import { CustomMeta } from '~/utils/apiTable/CustomMeta'
import type { handleEditRowFn } from '~/utils/apiTable/apiTableUtils'

export const RoleWithEntity = ({
  editMode,
  entity,
  handleEdit,
  roleMetas
}: {
  editMode?: boolean
  entity: BaseEntityLikeFragment
  handleEdit?: handleEditRowFn
  roleMetas: Array<RoleMetaFragment>
}): ReactNode => {
  const isReview = useIsReview()
  const doesEntityHaveActiveRole = roleMetas.some((roleMeta) => roleMeta.isActive)
  // If detailedRoles is false, all the info we need will be in the first roleMeta
  const firstMeta = roleMetas.at(0)

  const hasRoleDescription = roleMetas.some((r) => !!r.roleTitleDescription)

  return (
    <div className='flex w-full'>
      {hasRoleDescription && (
        <div className='min-h-flags-chip w-full'>
          <EntityLink entity={entity} transparent={!doesEntityHaveActiveRole} />
          {isReview && (entity.__typename === 'Person' || entity.__typename === 'GlobalPerson') && (
            <PersonReviewInfo person={entity} />
          )}
          <div className={cn('flex flex-col border-l border-divider px-2 py-0.5')}>
            {roleMetas.map((roleMeta, index) => (
              <div key={index} className='grid grid-cols-3 justify-between'>
                <span>{roleMeta.roleTitleDescription ?? roleMeta.roleTitle}</span>
                <Timespan
                  timespan={roleMeta.prettyTimespan}
                  duration={roleMeta.prettyDuration}
                  className='whitespace-nowrap'
                />
                <CustomMeta
                  editMode={editMode}
                  customMeta={roleMeta.customMeta}
                  handleEdit={() => {
                    handleEdit?.(entity, {
                      existingRole: true,
                      type: 'EditRole',
                      roleTitleId: roleMeta.roleTitleId,
                      periodFrom: roleMeta.period.from,
                      periodTo: roleMeta.period.to,
                      comment: roleMeta.customMeta?.comment
                    })
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {!hasRoleDescription && firstMeta && (
        <div className='grid w-full grid-cols-[3fr_auto_auto] items-center justify-center gap-2'>
          <div className='min-h-flags-chip'>
            <EntityLink entity={entity} transparent={!doesEntityHaveActiveRole} />
            {isReview && (entity.__typename === 'Person' || entity.__typename === 'GlobalPerson') && (
              <PersonReviewInfo person={entity} />
            )}
          </div>
          <div className='flex items-center'>
            <Timespan
              timespan={firstMeta.prettyTimespan}
              duration={firstMeta.prettyDuration}
              className='whitespace-nowrap'
            />
          </div>
          <CustomMeta
            className='mx-0'
            editMode={editMode}
            handleEdit={() => {
              handleEdit?.(entity, {
                existingRole: true,
                type: 'EditRole',
                roleTitleId: firstMeta.roleTitleId,
                periodFrom: firstMeta.period.from,
                periodTo: firstMeta.period.to,
                comment: firstMeta.customMeta?.comment
              })
            }}
            customMeta={firstMeta.customMeta}
          />
        </div>
      )}
    </div>
  )
}
