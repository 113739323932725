import { Trans } from '@lingui/macro'
import { Button, Divider, IconFilter, cn } from '@strise/ui-components'
import { type ReactNode, forwardRef } from 'react'

export const ReviewPrivatePersonHeader = forwardRef<HTMLDivElement>((_, ref): ReactNode => {
  return (
    <div
      ref={ref}
      className={cn(
        'sticky top-0 z-[11] flex min-h-[theme(height.sub-header)] items-stretch border-x-0 border-b border-solid border-divider bg-background-default'
      )}
    >
      <Button
        variant='ghost'
        palette='tertiary'
        className='h-auto px-3'
        startIcon={<IconFilter className='mr-2' />}
        data-track='Private Person Review / Filter / Open'
      >
        <Trans>Filter</Trans>
      </Button>
      <Divider orientation='vertical' />
      <Button
        variant='contained'
        palette='secondary'
        className='h-auto ml-auto w-48'
        data-track='Private Person Review / Add to Review / Open dialog'
      >
        <Trans>Add to Review</Trans>
      </Button>
    </div>
  )
})
