import { useReactiveVar } from '@apollo/client/index.js'
import { t } from '@lingui/macro'
import { extractEntityContentLanguage, extractIsPerson } from '@strise/app-shared'
import { useContext } from '@strise/react-utils'
import { ApplicationSearchReturnType } from '@strise/types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ScrollArea,
  Skeleton,
  Typography
} from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { EntitySearchInput } from '~/components/Entity/EntitySearchInput'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { TruncatedList } from '~/components/TruncatedList/TruncatedList'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { EntityKeyMetaItems } from '~/features/Ownerships/EntityKeyMetaItems'
import { OwnershipsContext } from '~/features/Ownerships/OwnershipsContext/OwnershipsContext'
import MergeEntityDetails from '~/features/Ownerships/edit-owner/MergeEntityDetails'
import { EntityLocationFilterKind } from '~/features/Search/searchUtils'
import { useMergeEntityMutation, useSuggestedMergeEntitiesQuery } from '~/graphqlOperations'
import { type BaseEntityLikeFragment, type EntityLikeMetaFragment } from '~/graphqlTypes'
import { refreshReviewCompanyMap } from '~/state'
import { TestIDs } from '~/utils/testIDs'

export const MergeEntityDialog = ({
  entity,
  handleClose
}: {
  entity: EntityLikeMetaFragment
  handleClose: () => void
}): ReactNode => {
  const [entityList, setEntityList] = useState<BaseEntityLikeFragment[]>([])
  const [selectedEntity, setSelectedEntity] = useState<BaseEntityLikeFragment | null>(null)

  const { rootEntity, showOriginal } = useContext(OwnershipsContext)
  const { beneficialOwnerThreshold, showNetworkRisk } = useContext(SidepanelContext)
  const features = useCurrentUserFeatures()

  const refreshPrepareReview = useReactiveVar(refreshReviewCompanyMap)

  const { owners } = useContext(OwnershipsContext)

  const node = owners.ownerChart?.nodes.find((n) => n.id === entity.id)
  const ownershipValue = node?.indirectShareValue

  const [update, { loading }] = useMergeEntityMutation({
    onCompleted: () => {
      // prepare review again to update ownerships, peps, sanctions, etc
      refreshReviewCompanyMap({
        ...refreshPrepareReview,
        [rootEntity.id]: (refreshPrepareReview[rootEntity.id] ?? 0) + 1,
        [entity.id]: (refreshPrepareReview[entity.id] ?? 0) + 1
      })
      handleClose()
    }
  })

  const handleMerge = async (mergeEntityId: string): Promise<void> => {
    await update({
      variables: {
        entity: entity.id,
        sidepanelEntity: rootEntity.id,
        input: {
          entity: mergeEntityId,
          ownership: ownershipValue
        },
        ignoreCustomOwnerships: showOriginal,
        includeNetworkRoles: showNetworkRisk,
        includeControlDetails: features.OWNERSHIP_AND_CONTROL,
        beneficialOwnerThreshold: beneficialOwnerThreshold
      }
    })
  }

  const isPerson = extractIsPerson(entity)
  const contentLanguage = extractEntityContentLanguage(entity)

  const { loading: suggestedMergeEntitiesLoading } = useSuggestedMergeEntitiesQuery({
    variables: {
      q: entity.name ?? '',
      input: {
        returnType: isPerson ? ApplicationSearchReturnType.Person : ApplicationSearchReturnType.Company,
        countries: contentLanguage ? [contentLanguage] : undefined,
        withGlobal: true,
        first: 6,
        exclude: [entity.id]
      },
      showExtraMeta: true
    },
    skip: !entity.name,
    onCompleted(data) {
      setEntityList(data.suggestedMergeEntities.edges.map((e) => e.node))
    }
  })

  const onSearchSelect = (searchEntity: BaseEntityLikeFragment | null) => {
    setSelectedEntity(searchEntity)
    if (!searchEntity) return

    const filteredList = entityList.filter((e) => e.id !== searchEntity.id)
    setEntityList([searchEntity, ...filteredList])
  }

  return (
    <Dialog open={true} onOpenChange={handleClose}>
      <DialogContent className='max-w-3xl max-h-[98vh] pb-0 px-4 bg-secondary-shade-5 overflow-visible'>
        <DialogHeader className='flex flex-col'>
          <DialogTitle>{t`Connect ownership of:`}</DialogTitle>
          <div className='flex flex-col gap-2 border border-gray-10 p-3 bg-white rounded-sm'>
            <div className='flex justify-between gap-2'>
              <EntityLink entity={entity} withIcon noTooltip noLink />
            </div>
            <EntityKeyMetaItems className='flex gap-2' entity={entity} expanded size='sm' />
          </div>

          <Typography className='pt-3'>{t`Connect with:`}</Typography>

          <div className='flex flex-col gap-2 border border-gray-15 p-2 bg-white rounded-sm'>
            <EntitySearchInput
              initialInput={entity.name ?? ''}
              variant='contained'
              dataTrack='Merge Entity / Search Entity'
              entityKindFilter={isPerson ? ApplicationSearchReturnType.Person : ApplicationSearchReturnType.Company}
              entityLocationFilters={[EntityLocationFilterKind.ALL]}
              selectedEntity={null}
              setSelectedEntity={onSearchSelect}
              singleSelect={true}
              data-id={TestIDs.SidePanel.Ownerships.searchEntityTrigger}
              itemsWrapperProps={{ 'data-id': TestIDs.SidePanel.Ownerships.searchEntityResults }}
              inputProps={{ 'data-id': TestIDs.SidePanel.Ownerships.searchEntityInput }}
              showExtraMeta={true}
              withDataSources={true}
              clearSearchOnSelect={false}
              exclude={[entity.id]}
            />
            <Typography variant='aLabelSmall'>{t`Possible matches:`}</Typography>
            {suggestedMergeEntitiesLoading ? (
              <ScrollArea className='max-h-[60vh]'>
                <div className='flex flex-col gap-2'>
                  <Skeleton className='h-10 w-full' />
                  <Skeleton className='h-10 w-full' />
                  <Skeleton className='h-10 w-full' />
                </div>
              </ScrollArea>
            ) : (
              <ScrollArea className='max-h-[40vh]'>
                <TruncatedList
                  items={entityList}
                  truncateAfter={3}
                  className='p-0'
                  context='Suggested Merge Entities'
                  paginationProps={{ className: 'pl-1' }}
                >
                  {(suggestedEntity, i, isLast) => {
                    return (
                      <MergeEntityDetails
                        key={`${suggestedEntity.id} ${i}`}
                        entity={suggestedEntity}
                        isLast={isLast}
                        isSelected={selectedEntity?.id === suggestedEntity.id}
                        onClick={() => setSelectedEntity(suggestedEntity)}
                        dataTrackId={i.toString()}
                      />
                    )
                  }}
                </TruncatedList>
              </ScrollArea>
            )}
          </div>
        </DialogHeader>

        <DialogFooter className='sticky bg-secondary-shade-5 border-t border-gray-15 h-12'>
          <Button
            palette='secondary'
            onClick={handleClose}
            data-track='Edit ownership / Merge Entity / Cancel'
            data-id={TestIDs.SidePanel.Ownerships.mergeEntityCancelButton}
            className='h-full'
          >{t`Cancel`}</Button>
          <Button
            palette='primary'
            variant='contained'
            onClick={async () => await handleMerge(selectedEntity?.id ?? '')}
            disabled={!selectedEntity}
            loading={loading}
            data-track='Edit ownership / Merge Entity / Confirm'
            data-id={TestIDs.SidePanel.Ownerships.mergeEntityConfirmButton}
            className='h-full'
          >{t`Connect ownership`}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
