import { Trans, t } from '@lingui/macro'
import { Empty } from '@strise/app-shared'
import { type DivProps } from '@strise/react-utils'
import { Divider } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { Fragment } from 'react'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { Register } from '~/features/Registers/Register'
import { type SidepanelCompanyFragment } from '~/graphqlTypes'

export const SidepanelCompanyRegistersCard = ({
  className,
  company,
  ...props
}: { company: SidepanelCompanyFragment } & DivProps): ReactNode => {
  const registers = company.registers.edges

  return (
    <SidepanelCard className={className} title={t`Registers`} {...props}>
      <div className='flex grow flex-col px-4 pb-4'>
        {!registers.length && (
          <Empty title={t`No registers found`}>
            <Trans>We couldn't find any registers for this company</Trans>
          </Empty>
        )}

        {registers.map(({ node: register }, index) => {
          const isLast = index === registers.length - 1

          return (
            <Fragment key={index}>
              <Register className='mb-2 py-2' entityId={company.id} register={register} />
              {!isLast && <Divider />}
            </Fragment>
          )
        })}
      </div>
    </SidepanelCard>
  )
}
