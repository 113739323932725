import { t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Typography
} from '@strise/ui-components'
import type { ReactNode } from 'react'
import { resetPrivatePersonSearchCache } from '~/features/PrivatePersons/privatePerson'
import { useDeletePrivatePersonMutation } from '~/graphqlOperations'
import { type PrivatePersonBaseFragment } from '~/graphqlTypes'

interface DeletePrivatePersonDialogProps {
  onOpenChange: (open: boolean) => void
  open: boolean
  privatePerson: PrivatePersonBaseFragment
}

export const DeletePrivatePersonDialog = ({
  onOpenChange,
  open,
  privatePerson
}: DeletePrivatePersonDialogProps): ReactNode => {
  const [deletePrivatePerson, { loading }] = useDeletePrivatePersonMutation({
    onCompleted: () => {
      toast.success(t`${privatePerson.name} has been deleted`)
      onOpenChange(false)
    },
    update: resetPrivatePersonSearchCache
  })

  const handleDelete = async () => {
    await deletePrivatePerson({ variables: { id: privatePerson.id } })
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t`Are you sure you want to delete ${privatePerson.name}?`}</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          <Typography variant='body1'>
            {t`This action cannot be undone and will permanently delete the private person.`}
          </Typography>
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel>{t`Cancel`}</AlertDialogCancel>
          <Button
            disabled={loading}
            onClick={handleDelete}
            variant='contained'
            palette='danger'
            loading={loading}
            data-track='Private person portfolio / Delete private person / Confirm'
          >
            {t`Delete`}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
