// https://vike.dev/onRenderClient
import { getBrowserGlobals } from '@strise/react-utils'
import { hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// @ts-expect-error onRenderClient is not typed
function onRenderClient(pageContext): void {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { Page } = pageContext
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = getBrowserGlobals()!.document.getElementById('root')!

  const page = (
    <BrowserRouter>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      <Page {...pageContext.pageProps} />
    </BrowserRouter>
  )
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (pageContext.isHydration) {
    hydrateRoot(root, page)
  }
}

export { onRenderClient }
