import { type SetStateFn } from '@strise/react-utils'
import { IconCheckSmall, cn } from '@strise/ui-components'
import { Accordion } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { useEffect } from 'react'

interface StepInfo<T> {
  content?: ReactNode
  isCompleted: boolean
  keepOpenAfterCompletion?: boolean
  step: T
  title: ReactNode
}

export const StepContent = <T,>({
  currentStep,
  index,
  previousStepInfo,
  setCurrentStep,
  stepInfo
}: {
  currentStep: T | null
  index: number
  previousStepInfo: StepInfo<T> | undefined
  setCurrentStep: SetStateFn<T | null>
  stepInfo: StepInfo<T>
}): ReactNode => {
  // Set to current step when previous step is completed
  useEffect(() => {
    if (!previousStepInfo?.isCompleted) return

    setCurrentStep(stepInfo.step)
  }, [previousStepInfo?.isCompleted])

  const isOpen = (stepInfo.keepOpenAfterCompletion && stepInfo.isCompleted) || currentStep === stepInfo.step

  const numberContainerClasses =
    stepInfo.isCompleted || index === 0 || previousStepInfo?.isCompleted
      ? 'bg-primary-main text-background-paper'
      : 'border-[1px] border-divider'

  const label = (
    <div className='flex w-full items-center justify-items-center'>
      <div
        className={cn(
          'mr-4 flex size-[32px] shrink-0 items-center justify-center rounded-full',
          numberContainerClasses
        )}
      >
        {stepInfo.isCompleted ? <IconCheckSmall /> : index + 1}
      </div>
      <div className='grow'>{stepInfo.title}</div>
    </div>
  )

  return (
    <Accordion
      open={isOpen}
      py={2}
      px={4}
      label={label}
      toggle={stepInfo.content ? () => setCurrentStep(isOpen ? null : stepInfo.step) : undefined}
      mb={4}
      border='none'
      bgcolor='background.paper'
      minHeight={64}
    >
      {stepInfo.content}
    </Accordion>
  )
}
