import { useContext } from '@strise/react-utils'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { PreLoaderContext } from '~/contexts/PreLoaderContext/PreLoaderContext'

export const PreLoaderContextStop = ({ children }: { children: ReactNode }): ReactNode => {
  const { loading, setLoading } = useContext(PreLoaderContext)
  useEffect(() => {
    setLoading(false)
  }, [])

  if (loading) return null

  return <>{children}</>
}
