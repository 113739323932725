import { type MessageDescriptor, i18n } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { useContext } from '@strise/react-utils'
import { objectKeys } from '@strise/ts-utils'
import { Button } from '@strise/ui-components'
import type { MouseEvent } from 'react'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { type TabRefs, useSidepanelTabs } from '~/components/Sidepanel/SidepanelTabs/sidepanelTabsUtils'
import { SidepanelTabsLoader } from '~/components/Sidepanel/SidepanelTabsLoader'
import { type SupportedSidepanelEntityFragment } from '~/components/Sidepanel/utils/sidepanelUtils'
import { hasEntityAccess } from '~/utils/entity'
import { SidepanelTab } from '~/utils/urls'

const tabLabels: Partial<Record<SidepanelTab, MessageDescriptor>> = {
  [SidepanelTab.Company]: defineMessage({ message: 'Company' }),
  [SidepanelTab.People]: defineMessage({ message: 'People' }),
  [SidepanelTab.Financials]: defineMessage({ message: 'Financials' }),
  [SidepanelTab.Events]: defineMessage({ message: 'Events' }),
  [SidepanelTab.Grow]: defineMessage({ message: 'Grow' }),
  [SidepanelTab.Person]: defineMessage({ message: 'Person' }),
  [SidepanelTab.Roles]: defineMessage({ message: 'Roles' }),
  [SidepanelTab.Ownership]: defineMessage({ message: 'Ownership' }),
  [SidepanelTab.PepsAndSanctions]: defineMessage({ message: 'PEPs and Sanctions' })
}

export const SidepanelTabs = ({
  entity,
  loading,
  sidepanelTab,
  tabRefs
}: {
  entity: SupportedSidepanelEntityFragment | null | undefined
  loading: boolean
  sidepanelTab: SidepanelTab | null
  tabRefs: TabRefs
}) => {
  const { scrollEl: scrollRef } = useContext(SidepanelContext)
  const sidepanelTabs = useSidepanelTabs(entity)
  const [activeTab, setActiveTab] = useState(sidepanelTabs[0])
  const observer = useRef<IntersectionObserver | null>(null)
  const scrollTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const scrollDoneFn = useRef<(() => void) | null>(null)

  useEffect(() => {
    if (!hasEntityAccess(entity)) return

    setActiveTab(sidepanelTabs[0])

    // useEffect is browser only
    // eslint-disable-next-line no-restricted-globals
    observer.current = new window.IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { rootBounds } = entry
          const tab = (entry.target as HTMLElement).dataset.tab as SidepanelTab | undefined

          if (!tab || !rootBounds) return

          const isAbove = entry.boundingClientRect.y < rootBounds.y

          if (!entry.isIntersecting && isAbove) {
            setActiveTab(tab)
          } else if (entry.isIntersecting && !isAbove) {
            const prevTab = sidepanelTabs[sidepanelTabs.indexOf(tab) - 1]
            if (prevTab && tabLabels[prevTab]) {
              setActiveTab(prevTab)
            }
          }
        })
      },
      { root: scrollRef.current, rootMargin: '-1px 0px 0px 0px', threshold: 0 }
    )

    objectKeys(tabRefs).forEach((tab) => {
      const el = tabRefs[tab]?.current
      if (observer.current && el) {
        observer.current.observe(el)
      }
    })

    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }

      if (scrollDoneFn.current && scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', scrollDoneFn.current)
      }
    }
  }, [hasEntityAccess(entity)])

  const scrollToTab = (event: MouseEvent<HTMLButtonElement> | null, tab: SidepanelTab): void => {
    const el = tabRefs[tab]?.current
    const scrollEl = scrollRef.current

    if (!el || !scrollEl) return

    if (scrollDoneFn.current) {
      scrollEl.removeEventListener('scroll', scrollDoneFn.current)
    }

    scrollDoneFn.current = () => {
      if (scrollTimeout.current) clearTimeout(scrollTimeout.current)

      scrollTimeout.current = setTimeout(() => {
        setActiveTab(tab)

        if (event) {
          const clickedTab = event.target as HTMLButtonElement
          clickedTab.blur()
        }

        if (scrollDoneFn.current) {
          scrollEl.removeEventListener('scroll', scrollDoneFn.current)
        }

        const successfullScroll = el.getBoundingClientRect().top - scrollEl.getBoundingClientRect().top === 0
        if (!successfullScroll) {
          el.scrollIntoView()
        }
      }, 50)
    }

    scrollEl.addEventListener('scroll', scrollDoneFn.current)
    el.scrollIntoView()
  }

  useLayoutEffect(() => {
    if (!hasEntityAccess(entity) || !sidepanelTab) return

    // Wait for the sidepanel to load and add a small timeout
    // before scrolling to allow refs to connect
    // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
    setTimeout(() => {
      scrollToTab(null, sidepanelTab)
    }, 700)
  }, [entity?.id, sidepanelTab])

  if (loading) {
    return <SidepanelTabsLoader />
  }

  if (!sidepanelTabs.length) return null

  return (
    <div id='sidepanel-tabs' data-id='Sidepanel / Tabs' className='flex h-sub-header bg-tertiary-main pt-1'>
      {sidepanelTabs.map((tab, index) => {
        const tabLabel = tabLabels[tab]

        if (!tabLabel) return null

        return (
          <Button
            key={index}
            variant='contained'
            className={`h-full border-primary-main px-8 ${
              activeTab === tab ? 'bg-white hover:bg-white' : 'bg-tertiary-main hover:bg-transparent'
            }`}
            palette='tertiary'
            onClick={(event: MouseEvent<HTMLButtonElement>) => scrollToTab(event, tab)}
            data-track={`Sidepanel / Tab / ${tab}`}
          >
            {i18n._(tabLabel)}
          </Button>
        )
      })}
    </div>
  )
}
