import { type MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { AuditRisk, RiskColor } from '@strise/types'

interface RiskColorClasses {
  bgColorClass: string
  colorClass: string
}

export const auditToRiskColor: Record<AuditRisk, RiskColor> = {
  [AuditRisk.None]: RiskColor.Black,
  [AuditRisk.Low]: RiskColor.Green,
  [AuditRisk.Medium]: RiskColor.Yellow,
  [AuditRisk.High]: RiskColor.Red
}

export const riskColorClasses: Record<RiskColor, RiskColorClasses> = {
  [RiskColor.Red]: {
    colorClass: 'text-semantic-danger-main',
    bgColorClass: 'bg-semantic-danger-shade-10'
  },
  [RiskColor.Orange]: {
    colorClass: 'text-semantic-warning-main',
    bgColorClass: 'bg-semantic-warning-shade-10'
  },
  [RiskColor.Yellow]: {
    colorClass: 'text-semantic-notice-main',
    bgColorClass: 'bg-semantic-notice-shade-10'
  },
  [RiskColor.Green]: {
    colorClass: 'text-semantic-success-main',
    bgColorClass: 'bg-semantic-success-shade-10'
  },
  [RiskColor.Blue]: {
    colorClass: 'text-semantic-info-main',
    bgColorClass: 'bg-semantic-info-shade-10'
  },
  [RiskColor.Pink]: {
    colorClass: 'text-accent-pink-main',
    bgColorClass: 'bg-accent-pink-shade-10'
  },
  [RiskColor.Black]: {
    colorClass: 'text-secondary-main',
    bgColorClass: 'bg-secondary-shade-10'
  }
}

export const auditRiskTitles: Partial<Record<AuditRisk, MessageDescriptor>> = {
  [AuditRisk.High]: defineMessage({ message: 'High' }),
  [AuditRisk.Medium]: defineMessage({ message: 'Medium' }),
  [AuditRisk.Low]: defineMessage({ message: 'Low' }),
  [AuditRisk.None]: defineMessage({ message: 'None' })
}

export const riskChipClasses: Record<RiskColor, string> = {
  [RiskColor.Red]: 'bg-semantic-danger-shade-10 hover:bg-semantic-danger-shade-20 active:bg-semantic-danger-shade-30',
  [RiskColor.Orange]:
    'bg-semantic-warning-shade-10 hover:bg-semantic-warning-shade-20 active:bg-semantic-warning-shade-30',
  [RiskColor.Yellow]:
    'bg-semantic-notice-shade-10 hover:bg-semantic-notice-shade-20 active:bg-semantic-notice-shade-30',
  [RiskColor.Green]:
    'bg-semantic-success-shade-10 hover:bg-semantic-success-shade-20 active:bg-semantic-success-shade-30',
  [RiskColor.Blue]: 'bg-semantic-info-shade-10 hover:bg-semantic-info-shade-20 active:bg-semantic-info-shade-30',
  [RiskColor.Pink]: 'bg-accent-pink-shade-10 hover:bg-accent-pink-shade-20 active:bg-accent-pink-shade-30',
  [RiskColor.Black]: 'bg-secondary-shade-30 hover:bg-secondary-shade-40 active:bg-secondary-shade-50'
}
