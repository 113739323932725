import { t } from '@lingui/macro'
import { Button, Dialog, DialogContent, DialogFooter, DialogTitle, Typography } from '@strise/ui-components'
import { type ReactNode, useState } from 'react'
import CompareSection from '~/components/RegistryCheck/CompareSection'
import DocumentDownloadSection from '~/components/RegistryCheck/DocumentDownloadSection'
import { useRegistryCheckMetaQuery } from '~/graphqlOperations'
import { type BaseReviewCompanyFragment } from '~/graphqlTypes'

const RegistryCheckDialog = ({
  entity,
  setOpen
}: {
  entity: BaseReviewCompanyFragment
  setOpen: (open: boolean) => void
}): ReactNode => {
  const { data, loading } = useRegistryCheckMetaQuery({
    variables: {
      entity: entity.id
    }
  })

  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([])

  return (
    <Dialog open={true} onOpenChange={setOpen}>
      <DialogContent className='w-[48rem] bg-secondary-shade-5 flex flex-col gap-3 pb-0'>
        <DialogTitle>
          <Typography variant='h4' className='pb-2'>{t`Verify Information`}</Typography>
        </DialogTitle>

        <div className='flex flex-col gap-3 overflow-y-auto px-4'>
          <CompareSection loading={loading} registryCheck={data?.company.registryCheck ?? []} />
          <DocumentDownloadSection
            registryDocuments={data?.company.registryDocuments ?? []}
            registryDocumentsLoading={loading}
            selectedDocumentIds={selectedDocumentIds}
            setSelectedDocumentIds={setSelectedDocumentIds}
          />
        </div>

        <DialogFooter className='bg-secondary-shade-5 p-4'>
          <Button
            variant='ghost'
            palette='secondary'
            onClick={() => setOpen(false)}
            data-track='Registry Check / Cancel'
          >
            {t`Cancel`}
          </Button>
          <Button
            variant='contained'
            palette='primary'
            disabled={selectedDocumentIds.length === 0}
            data-track='Registry Check / Request Document'
          >
            {t`Request Document`}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default RegistryCheckDialog
