import { useIsSupervisor } from '@strise/app-shared'
import { Button } from '@strise/ui-components'

interface PepDispositionResetProps {
  deleteLoading: boolean
  disabled: boolean
  onDelete: () => Promise<void>
}

export const PepDispositionReset = ({ deleteLoading, disabled, onDelete }: PepDispositionResetProps) => {
  const isSupervisor = useIsSupervisor()

  if (!isSupervisor) return null

  return (
    <Button
      variant='ghost'
      palette='danger'
      className='shrink-0'
      onClick={onDelete}
      loading={deleteLoading}
      disabled={disabled}
      data-track='Pep disposition dialog / Reset'
    >
      Reset all (admin)
    </Button>
  )
}
