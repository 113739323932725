import { type ApolloClient, useApolloClient, useReactiveVar } from '@apollo/client/index.js'
import type { SetStateFn } from '@strise/react-utils'
import { deepMergeObjects, filterNullishValues, objectEntries } from '@strise/ts-utils'
import { ReviewRowKind, ReviewSectionKind } from '@strise/types'
import { uniqBy } from 'lodash-es'
import { useEffect } from 'react'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { ReviewCardContentView, type ReviewState, defaultOpenedReviewState } from '~/features/Review/reviewUtils'
import { CreditReportCompanyDocument, useReviewCompanyDataLazyQuery } from '~/graphqlOperations'
import {
  type BaseReviewCompanyFragment,
  type CreditReportCompanyQuery,
  type CreditReportCompanyQueryVariables,
  type ReviewAlertFragment,
  type ReviewCompanyDataFragment,
  type ReviewCompanyDataQuery
} from '~/graphqlTypes'
import { portfolio, refreshReviewCompanyMap, refreshReviewSections, team } from '~/state'

const updateCompanyCreditScoreCache = (apolloClient: ApolloClient<object>, data: ReviewCompanyDataFragment) => {
  const reviewCompanyCreditScore = data.creditScore?.value

  // No credit score, so we don't need to refetch
  if (!reviewCompanyCreditScore) return

  const variables: CreditReportCompanyQueryVariables = { entity: data.id, team: team(), portfolio: portfolio() }

  const existingCompanyFragment = apolloClient.cache.readQuery<CreditReportCompanyQuery>({
    query: CreditReportCompanyDocument,
    variables: variables
  })

  // Not in cache, so we don't need to refetch
  if (!existingCompanyFragment) return

  apolloClient.query({
    query: CreditReportCompanyDocument,
    variables: variables,
    fetchPolicy: 'network-only'
  })
}

const updateCache = (apolloClient: ApolloClient<object>, data: ReviewCompanyDataFragment) => {
  updateCompanyCreditScoreCache(apolloClient, data)
}

/**
 * Refreshes the review sections that might've been updated
 */
const useRefreshReviewSections = (
  cardView: ReviewCardContentView,
  reviewState: ReviewState,
  fetchReviewCompany: (sectionsToFetch?: ReviewSectionKind[]) => void
): void => {
  const refreshedSections = useReactiveVar(refreshReviewSections)

  useEffect(() => {
    if (cardView !== ReviewCardContentView.IN_REVIEW) return
    if (!reviewState.opened) return

    const sectionsToRefresh = objectEntries(refreshedSections)
      .filter(([_, nonce]) => !!nonce)
      .map(([section]) => section)

    if (!sectionsToRefresh.length) return

    fetchReviewCompany(sectionsToRefresh)
  }, [refreshedSections])
}

const useRefreshReviewCompanyMap = (
  baseCompany: BaseReviewCompanyFragment,
  cardView: ReviewCardContentView,
  reviewState: ReviewState,
  setReviewState: SetStateFn<ReviewState>,
  fetchReviewCompany: () => void
): void => {
  const refreshReview = useReactiveVar(refreshReviewCompanyMap)

  useEffect(() => {
    if (!refreshReview[baseCompany.id]) return
    if (cardView !== ReviewCardContentView.IN_REVIEW) return

    fetchReviewCompany()

    if (!reviewState.opened) return
    // Reset rows that can be modified when the review is refreshed
    setReviewState(() => {
      return {
        ...reviewState,
        checkedMap: {
          ...reviewState.checkedMap,
          [ReviewRowKind.Peps]: false,
          [ReviewRowKind.Sanctions]: false,
          [ReviewRowKind.BeneficialOwners]: false,
          [ReviewRowKind.AlternativeBeneficialOwners]: false,
          [ReviewRowKind.OtherOwners]: false,
          [ReviewRowKind.OtherRoles]: false,
          [ReviewRowKind.Chairpersons]: false,
          [ReviewRowKind.Ceos]: false,
          [ReviewRowKind.Innehavers]: false,
          [ReviewRowKind.OtherBoardMembers]: false,
          [ReviewRowKind.Partners]: false,
          [ReviewRowKind.RolesAdverseMediaScreening]: false
        }
      }
    })
  }, [refreshReview[baseCompany.id]])
}

export const useReviewCompany = (
  baseCompany: BaseReviewCompanyFragment,
  cardView: ReviewCardContentView,
  setCardView: SetStateFn<ReviewCardContentView>,
  reviewState: ReviewState,
  setReviewState: SetStateFn<ReviewState>
): {
  alerts: ReviewAlertFragment[]
  errorSections: Set<ReviewSectionKind>
  fetchReviewCompany: () => void
  loadingSections: Set<ReviewSectionKind>
  reviewCompany: ReviewCompanyDataFragment | null
} => {
  const apolloClient = useApolloClient()
  const features = useCurrentUserFeatures()

  const handleCompleted = (data: ReviewCompanyDataQuery) => {
    updateCache(apolloClient, data.reviewCompanyData)
  }

  const [
    fetchCompanyInformation,
    { data: companyInformationData, error: companyInformationError, loading: companyInformationLoading }
  ] = useReviewCompanyDataLazyQuery({
    variables: {
      entity: baseCompany.id,
      includeSections: [ReviewSectionKind.CompanyInformation],
      excludeSections: []
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleted
  })

  const [
    fetchPepsAndSanctions,
    { data: pepsAndSanctionsData, error: pepsAndSanctionsError, loading: pepsAndSanctionsLoading }
  ] = useReviewCompanyDataLazyQuery({
    variables: {
      entity: baseCompany.id,
      includeSections: [ReviewSectionKind.PepsAndSanctions],
      excludeSections: []
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleted
  })

  const [fetchManagement, { data: managementData, error: managementError, loading: managementLoading }] =
    useReviewCompanyDataLazyQuery({
      variables: {
        entity: baseCompany.id,
        includeSections: [ReviewSectionKind.ManagementAndRightsHolders],
        excludeSections: []
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: handleCompleted
    })

  const [fetchAms, { data: amsData, error: amsError, loading: amsLoading }] = useReviewCompanyDataLazyQuery({
    variables: { entity: baseCompany.id, includeSections: [ReviewSectionKind.Ams], excludeSections: [] },
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleted
  })

  const [fetchEsg, { data: esgData, error: esgError, loading: esgLoading }] = useReviewCompanyDataLazyQuery({
    variables: { entity: baseCompany.id, includeSections: [ReviewSectionKind.Esg], excludeSections: [] },
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleted
  })

  const [
    fetchCustomCheckboxes,
    { data: customCheckboxesData, error: customCheckboxesError, loading: customCheckboxesLoading }
  ] = useReviewCompanyDataLazyQuery({
    variables: {
      entity: baseCompany.id,
      includeSections: [ReviewSectionKind.CustomCheckboxes],
      excludeSections: []
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleted
  })

  const fetchReviewCompany = (sectionsToFetch?: ReviewSectionKind[]): void => {
    const shouldFetchAll = !sectionsToFetch || sectionsToFetch.length === 0

    if (shouldFetchAll || sectionsToFetch.includes(ReviewSectionKind.CompanyInformation)) {
      fetchCompanyInformation()
    }

    if (shouldFetchAll || sectionsToFetch.includes(ReviewSectionKind.ManagementAndRightsHolders)) {
      fetchManagement()
    }

    if (
      features.PEP_AND_SANCTIONS_V2 &&
      (shouldFetchAll || sectionsToFetch.includes(ReviewSectionKind.PepsAndSanctions))
    ) {
      fetchPepsAndSanctions()
    }

    if (features.ADVERSE_MEDIA_SCREENING && (shouldFetchAll || sectionsToFetch.includes(ReviewSectionKind.Ams))) {
      fetchAms()
    }

    if (features.ESG && (shouldFetchAll || sectionsToFetch.includes(ReviewSectionKind.Esg))) {
      fetchEsg()
    }

    if (
      features.REVIEW_CUSTOM_CHECKBOXES &&
      (shouldFetchAll || sectionsToFetch.includes(ReviewSectionKind.CustomCheckboxes))
    ) {
      fetchCustomCheckboxes()
    }

    if (!reviewState.opened) {
      setReviewState((prevState) => {
        return {
          ...prevState,
          ...defaultOpenedReviewState
        }
      })
    }
    setCardView(ReviewCardContentView.IN_REVIEW)
  }

  useRefreshReviewSections(cardView, reviewState, fetchReviewCompany)
  useRefreshReviewCompanyMap(baseCompany, cardView, reviewState, setReviewState, fetchReviewCompany)

  useEffect(() => {
    if (!reviewState.opened) return
    if (cardView !== ReviewCardContentView.LOADING_REVIEW) return

    fetchReviewCompany()
  }, [reviewState.opened])

  const mergedResponses = deepMergeObjects(
    companyInformationData,
    pepsAndSanctionsData,
    managementData,
    amsData,
    esgData,
    customCheckboxesData
  )
  const reviewCompany = mergedResponses.reviewCompanyData

  const loadingSectionsArray = filterNullishValues([
    companyInformationLoading ? ReviewSectionKind.CompanyInformation : null,
    pepsAndSanctionsLoading ? ReviewSectionKind.PepsAndSanctions : null,
    managementLoading ? ReviewSectionKind.ManagementAndRightsHolders : null,
    amsLoading ? ReviewSectionKind.Ams : null,
    esgLoading ? ReviewSectionKind.Esg : null,
    customCheckboxesLoading ? ReviewSectionKind.CustomCheckboxes : null
  ])

  const errorSectionsArray = filterNullishValues([
    companyInformationError ? ReviewSectionKind.CompanyInformation : null,
    pepsAndSanctionsError ? ReviewSectionKind.PepsAndSanctions : null,
    managementError ? ReviewSectionKind.ManagementAndRightsHolders : null,
    amsError ? ReviewSectionKind.Ams : null,
    esgError ? ReviewSectionKind.Esg : null,
    customCheckboxesError ? ReviewSectionKind.CustomCheckboxes : null
  ])

  const loadingSections = new Set(loadingSectionsArray)
  const errorSections = new Set(errorSectionsArray)

  const alerts = uniqBy(
    filterNullishValues([
      companyInformationData?.reviewCompanyData.alerts,
      pepsAndSanctionsData?.reviewCompanyData.alerts,
      managementData?.reviewCompanyData.alerts,
      amsData?.reviewCompanyData.alerts,
      esgData?.reviewCompanyData.alerts,
      customCheckboxesData?.reviewCompanyData.alerts
    ]).flat(),
    (alert) => alert.kind
  )

  return { reviewCompany, alerts, fetchReviewCompany, loadingSections, errorSections }
}
