import { useCurrentUser } from '@strise/app-shared'
import { Duration } from '@strise/types'
import { Skeleton } from '@strise/ui-components'
import { useState } from 'react'
import { useTeam } from '~/contexts/TeamContext/TeamContext'
import { PortfolioHealthHeaderCard } from '~/features/PortfolioHealth/PortfolioHealthHeaderCard'
import { PortfolioHealthReviewMetricsSection } from '~/features/PortfolioHealth/PortfolioHealthReviewMetricsSection'
import { PortfolioHealthRiskAssessmentSection } from '~/features/PortfolioHealth/PortfolioHealthRiskAssessmentSection'
import { PortfolioHealthRiskFactorsSection } from '~/features/PortfolioHealth/PortfolioHealthRiskFactorsSection'
import { usePortfolioHealthQuery } from '~/graphqlOperations'

export const PortfolioHealthView = () => {
  const user = useCurrentUser()
  const team = useTeam()
  const [duration, setDuration] = useState<Duration>(Duration.Day)

  const { data, loading } = usePortfolioHealthQuery({
    variables: {
      team: team.id,
      duration
    },
    fetchPolicy: 'cache-and-network'
  })

  return (
    <div className='relative legacy-xs:min-w-0 legacy-md:min-w-0 legacy-lg:min-w-[1000px]'>
      {loading && (
        <div className='mx-auto max-w-7xl py-8 space-y-4'>
          <Skeleton className='h-32' />

          <div className='grid grid-cols-1 lg:grid-cols-4 gap-6'>
            {Array.from({ length: 4 }).map((_, i) => (
              <Skeleton key={`metric-${i}`} className='h-64' />
            ))}
          </div>

          <div className='grid grid-cols-4 gap-6'>
            <Skeleton className='col-span-4 lg:col-span-3 h-64' />
            <Skeleton className='col-span-4 lg:col-span-1 h-64' />
          </div>
        </div>
      )}
      {!loading && data?.portfolioHealth && (
        <div className='max-w-7xl mx-auto'>
          <PortfolioHealthHeaderCard teamName={team.name} teamId={team.id} userName={user.name} />
          <div className='px-4'>
            <PortfolioHealthReviewMetricsSection
              duration={duration}
              onDurationChange={setDuration}
              reviewMetrics={data.portfolioHealth.reviewMetrics}
            />
            <div className='grid grid-cols-4 gap-6'>
              <div className='col-span-4 lg:col-span-3'>
                <PortfolioHealthRiskFactorsSection riskFactors={data.portfolioHealth.riskFactors} />
              </div>
              <div className='col-span-4 lg:col-span-1 '>
                <PortfolioHealthRiskAssessmentSection riskDistribution={data.portfolioHealth.riskDistribution} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
