import { type DivProps, useContext } from '@strise/react-utils'
import { type ReactNode } from 'react'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { SidepanelSimilarEntitiesCard } from '~/features/SimilarEntities/SidepanelSimilarEntitiesCard'
import { useGlobalEntitySimilarEntitiesQuery } from '~/graphqlOperations'

const SIMILAR_ENTITIES_LIMIT = 20

export const SidepanelSimilarEntities = ({ className, ...props }: DivProps): ReactNode => {
  const features = useCurrentUserFeatures()
  const { id } = useContext(SidepanelContext)
  const { data, loading } = useGlobalEntitySimilarEntitiesQuery({
    variables: { id, limit: SIMILAR_ENTITIES_LIMIT },
    skip: !features.GLOBAL_ENTITIES
  })
  const similarEntities = data?.entity && 'similarEntities' in data.entity ? data.entity.similarEntities.edges : []

  const filteredEntities = similarEntities.filter((e) => e.node.id !== id)

  if (!features.GLOBAL_ENTITIES) return null

  return (
    <SidepanelSimilarEntitiesCard
      entity={data?.entity}
      className={className}
      entityEdges={filteredEntities}
      loading={loading}
      {...props}
    />
  )
}
