import { type MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { CompanyStatus } from '@strise/types'

export const companyStatusTitles: Partial<Record<CompanyStatus, MessageDescriptor>> = {
  [CompanyStatus.Account]: defineMessage({ message: 'Account' }),
  [CompanyStatus.Prospect]: defineMessage({ message: 'Prospect' }),
  [CompanyStatus.Following]: defineMessage({ message: 'Following' }),
  [CompanyStatus.InQualification]: defineMessage({
    message: 'In Review'
  }),
  [CompanyStatus.Qualified]: defineMessage({ message: 'Qualified' }),
  [CompanyStatus.Disqualified]: defineMessage({ message: 'Disqualified' })
}
