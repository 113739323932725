import { type ReactiveVar } from '@apollo/client/index.js'
import { ReactRouterLink } from '@strise/app-shared'
import { Logo, LogoIcon, LogoStripe, VisuallyHidden, cn } from '@strise/ui-components'
import { type ReactNode, useEffect, useState } from 'react'
import { MenuNav } from '~/components/Menu/MenuNav'
import { MenuState } from '~/components/Menu/menuUtils'
import { menuState } from '~/state'

// Workaround from https://github.com/apollographql/apollo-client/issues/10065
// When importing useReactiveVar from @apollo/client, the menu could get stucked in an open state
function useReactiveVar<T>(rv: ReactiveVar<T>): T {
  const value = rv()
  const [, setValue] = useState<T>(value)

  useEffect(() => {
    // We need a stable handle to function passed into rv.onNextChange.
    // Se that we can clean it up properly.
    // See ReactiveVar implementation for reasoning
    // @apollo/client/cache/inmemory/reactiveVars.js
    const onNextChange = (v: T): void => {
      setValue(v)

      listenForUpdate()
    }

    const listenForUpdate = (): (() => void) => rv.onNextChange(onNextChange)

    return listenForUpdate()
  }, [rv])

  return value
}

export const MenuContent = (): ReactNode => {
  const state = useReactiveVar(menuState)
  const [navOpen, setNavOpen] = useState(false)

  const width = {
    expanded: {
      md: 'legacy-md:w-[240px]',
      lg: 'legacy-lg:w-[240px]'
    },
    collapsed: {
      lg: 'legacy-lg:w-[64px]'
    }
  }

  const expanded = state === MenuState.DESKTOP_EXPANDED || navOpen || state === MenuState.MOBILE_OPEN

  return (
    <>
      <div className={cn('shrink-0 legacy-xs:hidden legacy-xs:w-0 legacy-lg:block', width.collapsed.lg)} />
      <aside
        className={cn(
          'fixed left-0 top-0 h-full legacy-xs:w-[90%] legacy-lg:translate-x-0',
          width.expanded.md,
          expanded ? width.expanded.lg : width.collapsed.lg,
          state === MenuState.MOBILE_OPEN ? 'legacy-xs:translate-x-0' : 'legacy-xs:-translate-x-full'
        )}
        id='app-menu'
        onMouseEnter={state === MenuState.MOBILE_OPEN ? undefined : () => menuState(MenuState.DESKTOP_EXPANDED)}
        onMouseLeave={state === MenuState.MOBILE_OPEN ? undefined : () => menuState(MenuState.DEFAULT)}
        style={{
          transition: `all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86)`
        }}
      >
        <div className='relative inset-y-0 flex h-full flex-col bg-secondary-shade-10'>
          <MenuNav expanded={expanded} navOpen={navOpen} setNavOpen={(open) => setNavOpen(open)} />

          <ReactRouterLink to='/' className='relative mt-auto flex h-breadcrumb items-center pr-4'>
            <VisuallyHidden>Strise</VisuallyHidden>
            {expanded && <LogoStripe className='mr-2 grow-0 basis-[35%]' />}
            <div>
              {expanded && <Logo />}
              {!expanded && <LogoIcon className='ml-5' />}
            </div>
          </ReactRouterLink>
        </div>
      </aside>
    </>
  )
}
