import { NationFlags } from '@strise/app-shared'
import { IconCheckSmall } from '@strise/ui-components'
import type { ReactNode } from 'react'

export const FinancialFieldSelectRenderText = (
  fieldName: string,
  countryIsoCodes: string[],
  selected?: boolean
): ReactNode => {
  return (
    <div className='flex items-center'>
      {selected && <IconCheckSmall className='mr-4' />}
      <NationFlags className='mr-2' countryIsoCodes={countryIsoCodes} />
      {fieldName}
    </div>
  )
}
