import { IconSpark, IconWarningFill } from '../../icons/general'
import { cn } from '../../utils/className'
import { LoaderRound } from '../LoaderRound'
import { Typography } from '../Typography'
import { progressVariants } from '../variants/progressVariants'
import { type VariantProps } from 'class-variance-authority'
import type { ReactNode } from 'react'

const variantToIcon = {
  success: <IconSpark className='mr-4 text-accent-green-shade-50' />,
  error: <IconWarningFill className='mr-4 text-semantic-danger-shade-50' />,
  loading: <LoaderRound palette='primary' size='sm' className='mr-4' />
}

type ProgressVariant = 'loading' | 'error' | 'success'

export interface ProgressProps extends VariantProps<typeof progressVariants> {
  className?: string
  progressPercentage: number
  resetButton?: ReactNode
  title: string
  variant: ProgressVariant
}

export const Progress = ({
  className,
  progressPercentage,
  resetButton,
  title,
  variant,
  ...props
}: ProgressProps): ReactNode => {
  const icon = variantToIcon[variant]

  return (
    <div className={cn('h-16 w-full overflow-hidden rounded-xl bg-secondary-shade-5', className)} {...props}>
      <div className={progressVariants({ variant })} style={{ width: `${progressPercentage}%` }}>
        <div className='flex w-full items-center justify-between p-4'>
          <div className='flex items-center'>
            {icon}
            <Typography className='m-0 font-regular' variant='subtitle1'>
              {title}
            </Typography>
          </div>
          {progressPercentage === 100 && resetButton}
        </div>
      </div>
    </div>
  )
}
