import { type AnimatedIconProps, SvgMorphingWrapper } from '../SvgMorphingWrapper'
import { IconCollapseAnimatedA } from './IconCollapseAnimatedA'
import { IconCollapseAnimatedB } from './IconCollapseAnimatedB'
import { type ReactNode, forwardRef } from 'react'

export const IconCollapseAnimated = forwardRef<SVGSVGElement, AnimatedIconProps>(
  (props, ref): ReactNode => (
    <SvgMorphingWrapper
      {...props}
      SvgComponentA={IconCollapseAnimatedA}
      SvgComponentB={IconCollapseAnimatedB}
      ref={ref}
    />
  )
)
IconCollapseAnimated.displayName = 'IconCollapseAnimated'
