import { type DivPropsWithChildren } from '@strise/react-utils'
import { cn } from '@strise/ui-components'

interface StepCardProps extends DivPropsWithChildren {
  className?: string
}

export const StepCard = ({ children, className, ...props }: StepCardProps) => {
  return (
    <div className={cn('py-2 px-4 bg-background-paper', className)} {...props}>
      {children}
    </div>
  )
}

export const StepCardContent = ({ children, className }: DivPropsWithChildren) => {
  return <div className={cn('py-2 px-4', className)}>{children}</div>
}
