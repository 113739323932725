import * as React from 'react'

export const createContext = <T extends object>(): React.Context<T | undefined> => {
  const context = React.createContext<T | undefined>(undefined)
  return context
}

export const useContext = <T extends object>(context: React.Context<T | undefined>): T => {
  const ctx = React.useContext(context)
  if (!ctx) {
    const displayName = context.displayName ?? ''
    throw new Error(`useContext(${displayName}) must be used within a ${displayName}Provider`)
  }
  return ctx
}
