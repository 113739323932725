import { Trans, t } from '@lingui/macro'
import { CompanyStatusDot, UserAvatar, formatDate } from '@strise/app-shared'
import { companyStatusTitles } from '@strise/app-shared/src/i18n'
import { type SetStateFn, useContext } from '@strise/react-utils'
import { IconClock, Typography, cn } from '@strise/ui-components'
import { addYears, differenceInDays } from 'date-fns'
import type { ReactNode } from 'react'
import { ContentViewContext } from '~/components/Layout/ContentViewContext'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { DownloadReviewPdf } from '~/features/Review/DownloadReviewPdf'
import { ReviewCompanyStartButton } from '~/features/Review/ReviewCompanyStartButton'
import { type ReviewCardContentView } from '~/features/Review/reviewUtils'
import { AuditRiskChip } from '~/features/Risk/AuditRiskChip'
import { CustomRiskLevelChip } from '~/features/Risk/CustomRiskLevelChip'
import { type ReviewFragment } from '~/graphqlTypes'
import { getTitle } from '~/utils/enum'
import { TestIDs } from '~/utils/testIDs'

export const ReviewCompanyCardSummaryContent = ({
  fetchReviewCompany,
  review,
  setCardView
}: {
  fetchReviewCompany: () => void
  review: ReviewFragment | null
  setCardView: SetStateFn<ReviewCardContentView>
}): ReactNode => {
  const { isSmallScreen } = useContext(ContentViewContext)
  const features = useCurrentUserFeatures()

  if (!review) return null

  const rows = [
    {
      title: t`Date`,
      content: <Typography>{formatDate(review.created, { relative: false })}</Typography>
    },
    {
      title: t`Status`,
      content: review.companyStatus ? (
        <div className='flex'>
          <CompanyStatusDot status={review.companyStatus} />
          <Typography>{getTitle(companyStatusTitles[review.companyStatus])}</Typography>
        </div>
      ) : (
        <Typography>
          <Trans>None</Trans>
        </Typography>
      )
    },
    {
      title: t`Risk`,
      content: review.customRiskLevel ? (
        <CustomRiskLevelChip customRiskLevel={review.customRiskLevel} />
      ) : (
        <AuditRiskChip className='m-0' risk={review.risk} />
      )
    },
    {
      title: t`Reviewer`,
      content: review.user ? (
        <div className='flex'>
          <UserAvatar user={review.user} className='mr-1 size-6' />
          <Typography>{review.user.name}</Typography>
        </div>
      ) : (
        <Typography>
          <Trans>Unknown</Trans>
        </Typography>
      )
    },
    {
      title: t`Report`,
      content: <DownloadReviewPdf review={review} className='h-auto p-0' />
    }
  ]

  return (
    <div className='px-4 pt-2'>
      <div className={cn('flex justify-between px-5', isSmallScreen ? 'flex-col' : 'flex-row')}>
        {rows.map((row, index) => {
          return (
            <div className={isSmallScreen ? 'my-2' : undefined} key={index}>
              <Typography className='block' variant='aLabelBold'>
                {row.title}
              </Typography>
              <div className='mt-[6px]'>{row.content}</div>
            </div>
          )
        })}
      </div>
      {(features.REVIEW_VALIDITY || review.comment) && (
        // TODO: Implement better styling logic
        <div className='mt-4'>
          {features.REVIEW_VALIDITY && (
            <div
              className={cn(
                'bg-semantic-notice-shade-10 px-4 py-2 flex items-center',
                review.comment
                  ? 'rounded-[0_0_0_0]' // Sharp borders when both present
                  : 'rounded-[0_12px_12px_12px]' // Rounded bottom when alone
              )}
            >
              <IconClock size='lg' className='mr-2' />
              <Typography className='whitespace-pre-line'>
                {formatDate(addYears(new Date(review.created), 1), { relative: false })}: Review validity expires in{' '}
                {Math.max(
                  0,
                  differenceInDays(
                    // Using hardcoded 1 year for the demo
                    addYears(new Date(review.created), 1),
                    new Date()
                  )
                )}{' '}
                days
              </Typography>
            </div>
          )}
          {review.comment && (
            <div className='bg-secondary-shade-5 px-4 py-2 rounded-[0_12px_12px_12px]'>
              <Typography className='whitespace-pre-line'>{review.comment}</Typography>
            </div>
          )}
        </div>
      )}
      <div className='text-right'>
        <ReviewCompanyStartButton
          setCardView={setCardView}
          data-track='Review / Start new review'
          fetchReviewCompany={fetchReviewCompany}
          variant='outlined'
          palette='tertiary'
          data-id={TestIDs.Review.Card.startReviewButton}
        >
          <Trans>New Review</Trans>
        </ReviewCompanyStartButton>
      </div>
    </div>
  )
}
