import { type ReactNode } from 'react'
import { useEffect } from 'react'
import { BaseRiskSelect } from '~/features/Risk/BaseRiskSelect'
import { CustomRiskLevelAlert } from '~/features/Risk/CustomRiskLevelAlert'
import { riskColorClasses } from '~/features/Risk/risk'
import { type RiskLevelsQuery } from '~/graphqlTypes'

export const CustomRiskLevelSelect = ({
  disabled,
  onChange,
  riskLevels,
  selectedRisk
}: {
  disabled?: boolean
  onChange: (label: string) => void
  riskLevels: RiskLevelsQuery['riskLevels']
  selectedRisk: string | null
}): ReactNode => {
  useEffect(() => {
    const riskId = riskLevels[0]?.id
    if (!riskId) return
    onChange(riskId)
  }, [riskLevels])

  if (riskLevels.length === 0) {
    return <CustomRiskLevelAlert />
  }

  const sortedRiskLevels = [...riskLevels].sort((a, b) => a.sortingIndex - b.sortingIndex)

  const options = sortedRiskLevels.map((risk) => ({
    value: risk.id,
    label: risk.label,
    colorClass: riskColorClasses[risk.color].colorClass,
    bgColorClass: riskColorClasses[risk.color].bgColorClass
  }))

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value)
  }

  return (
    <BaseRiskSelect
      disabled={disabled}
      selectedValue={selectedRisk ?? ''}
      options={options}
      name='custom-risk'
      onChange={handleChange}
    />
  )
}
