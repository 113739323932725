import { type SetStateFn, createContext } from '@strise/react-utils'

interface SidepanelNavHistoryContextProps {
  currentIndex: number
  setCurrentIndex: SetStateFn<number>
  setSidepanelHistory: SetStateFn<string[]>
  sidepanelHistory: string[]
}

export const SidepanelNavHistoryContext = createContext<SidepanelNavHistoryContextProps>()
