import { t } from '@lingui/macro'
import { Tooltip, cn } from '@strise/ui-components'
import { usePalette } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { forwardRef, useRef } from 'react'
import { useResizeObserver } from 'usehooks-ts'
import { GoogleMap } from '~/components/GoogleMap'
import { Detail, type DetailProps } from '~/components/Sidepanel/Detail'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { SelectCompanyAssignee } from '~/features/Assignee/SelectCompanyAssignee'
import { type AssigneeEdge } from '~/features/Assignee/assigneeUtils'
import { SelectCompaniesStatus } from '~/features/CompanyStatus/SelectCompaniesStatus'
import { companyInPortfolio } from '~/features/CompanyStatus/companyStatusUtils'
import { InputCompanyTags } from '~/features/Tags/InputCompanyTags'
import { TeamActivities } from '~/features/TeamActivity/TeamActivities'
import { type CompanyMetaFragment, type SidepanelCompanyFragment } from '~/graphqlTypes'
import { hasEntityAccess } from '~/utils/entity'
import { useIsMobile } from '~/utils/hooks'
import { TestIDs } from '~/utils/testIDs'
import { SidepanelTab } from '~/utils/urls'

const Map = ({ company, siblingHeight }: { company: SidepanelCompanyFragment; siblingHeight: number | undefined }) => {
  const coordinates =
    company.coordinates?.split(',').map((coordinateString: string) => Number.parseFloat(coordinateString)) ?? null
  const location = coordinates || company.headquartersLocation?.name

  const paperBgColor = usePalette('background.paper')
  const paperBgColor0 = usePalette('background.paper:0')

  if (!location) return null

  return (
    <div className='absolute bottom-[16px] left-[60%] right-0 top-0 z-[1]'>
      <div
        className='absolute inset-y-0 left-0 z-[2] w-[150px]'
        style={{
          background: `linear-gradient(90deg, ${paperBgColor}, ${paperBgColor0})`
        }}
      />

      <GoogleMap
        location={location}
        marker
        mapOptions={{
          zoomControl: false,
          zoom: 3.5,
          draggable: false,
          draggableCursor: 'default'
        }}
        width='100%'
        height={siblingHeight || 300}
      />

      <div
        className='absolute bottom-0 z-[3] h-[50px] w-full'
        style={{
          background: `linear-gradient(to bottom, ${paperBgColor0} 0%, ${paperBgColor} 50%)`
        }}
      />
    </div>
  )
}

const MetaDetail = ({
  children,
  className,
  contentContainerProps,
  labelContainerProps,
  ...props
}: DetailProps): ReactNode => {
  return (
    <Detail
      className={className}
      labelContainerProps={{
        ...labelContainerProps,
        className: cn(
          'mt-1 legacy-xs:mb-0 legacy-xs:min-h-0 legacy-md:mb-1 legacy-md:min-h-[48px]',
          labelContainerProps?.className
        )
      }}
      contentContainerProps={{
        ...contentContainerProps,
        className: cn('my-1 legacy-xs:w-full legacy-md:w-1/2', contentContainerProps?.className)
      }}
      hideDivider
      {...props}
    >
      {children}
    </Detail>
  )
}

const TeamActivityDetail = ({ assignees, company }: { assignees: AssigneeEdge[]; company: CompanyMetaFragment }) => {
  const features = useCurrentUserFeatures()

  if (!features.TEAM_ACTIVITIES_AND_REMINDERS) return null

  return (
    <MetaDetail label={t`Team activity`} contentContainerProps={{ className: 'flex-grow' }}>
      <TeamActivities company={company} assignees={assignees} />
    </MetaDetail>
  )
}

interface MetaCardContentProps {
  company: SidepanelCompanyFragment
}

export const MetaCardContent = ({ company }: MetaCardContentProps): ReactNode => {
  const metaRef = useRef<HTMLDivElement>(null)
  const { height: metaHeight } = useResizeObserver({ ref: metaRef })

  const mobile = useIsMobile()

  const inPortfolio = companyInPortfolio(company.statusV2?.status)

  const disabledExplanation = inPortfolio ? '' : t`You must add the company to your portfolio before you can edit this.`

  return (
    <>
      <div className='relative z-[4] px-4 py-2'>
        <div
          className={cn('flex flex-col', { 'pointer-events-none opacity-30': !hasEntityAccess(company) })}
          ref={metaRef}
        >
          <MetaDetail label={t`Status`} data-id={TestIDs.SidePanel.CompanyStatus.root}>
            <SelectCompaniesStatus
              data-id={TestIDs.SidePanel.CompanyStatus.trigger}
              companies={[company]}
              triggerIconVisibility='hover'
              variant='ghost'
              className='h-12 w-full'
              data-track-context='sidepanel'
              contentProps={{ 'data-id': TestIDs.SidePanel.CompanyStatus.select }}
            />
          </MetaDetail>
          <MetaDetail label={t`Assignee`} data-id={TestIDs.SidePanel.Assignee.root}>
            <Tooltip content={disabledExplanation}>
              <SelectCompanyAssignee
                data-id={TestIDs.SidePanel.Assignee.trigger}
                className='h-12 w-full'
                disabled={hasEntityAccess(company) && !inPortfolio}
                assignees={inPortfolio ? company.assignee.edges : []}
                companyIds={[company.id]}
                data-track-context='sidepanel'
              />
            </Tooltip>
          </MetaDetail>
          <MetaDetail label={t`Tags`}>
            <Tooltip content={disabledExplanation}>
              <InputCompanyTags
                className='h-auto min-h-[48px]'
                disabled={hasEntityAccess(company) && !inPortfolio}
                tags={inPortfolio ? company.tags.edges : []}
                companyIds={[company.id]}
                cacheKeys={{ [company.id]: company.tags.key }}
                data-track-context='sidepanel'
                maxLabelLengthProps={{
                  maxLabelLength: 20,
                  maxTotalLabelLength: 36
                }}
                data-id={TestIDs.SidePanel.Tags.trigger}
                itemsWrapperProps={{ 'data-id': TestIDs.SidePanel.Tags.options }}
              />
            </Tooltip>
          </MetaDetail>
        </div>
        {hasEntityAccess(company) && <TeamActivityDetail company={company} assignees={company.assignee.edges} />}
      </div>
      {!mobile && <Map siblingHeight={metaHeight} company={company} />}
    </>
  )
}

interface MetaCardProps {
  company: SidepanelCompanyFragment
}

export const SidepanelCompanyMetaCard = forwardRef<HTMLDivElement, MetaCardProps>(
  ({ company, ...props }, ref): ReactNode => {
    return (
      <SidepanelCard ref={ref} tab={SidepanelTab.Company}>
        <MetaCardContent company={company} {...props} />
      </SidepanelCard>
    )
  }
)
