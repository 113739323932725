import { Trans, t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { Label, Switch, Typography } from '@strise/ui-components'
import { type ReactNode, forwardRef } from 'react'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { RiskSummaryCard } from '~/features/RiskSummary/RiskSummaryCard'
import { type RiskSummaryFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'
import { SidepanelTab } from '~/utils/urls'

interface SidepanelRiskSummaryCardProps {
  changeNetworkState: SetStateFn<boolean>
  loading: boolean
  riskSummary: RiskSummaryFragment | undefined | null
  showNetwork: boolean
}

export const SidepanelRiskSummaryCard = forwardRef<HTMLDivElement, SidepanelRiskSummaryCardProps>(
  ({ changeNetworkState, loading, riskSummary, showNetwork }, ref): ReactNode => {
    const risks = riskSummary?.risks ?? []

    return (
      <SidepanelCard
        ref={ref}
        tab={SidepanelTab.RiskSummary}
        title={t`Risk Summary`}
        description={t`Quick overview of key risk factors. Click the cards for detailed information.`}
        id='risk-summary'
        data-id={TestIDs.SidePanel.RiskSummary.root}
        loading={loading}
      >
        <div className='mr-8 flex justify-end gap-2 pb-2'>
          <Label variant='aLabelSmall' htmlFor='show-network-switch'>
            <Trans>Show network risk</Trans>
          </Label>
          <Switch
            id='show-network-switch'
            data-id={TestIDs.SidePanel.RiskSummary.showNetworkSwitch}
            checked={showNetwork}
            onCheckedChange={changeNetworkState}
          />
          <Typography variant='aLabelSmall'>
            {showNetwork && <Trans>On</Trans>}
            {!showNetwork && <Trans>Off</Trans>}
          </Typography>
        </div>
        <div className='grid auto-rows-fr grid-cols-3 gap-2 px-4 pb-4'>
          {risks.map((risk) => (
            <RiskSummaryCard key={`${risk.reason}${risk.label}${risk.level}${risk.kind}`} risk={risk} />
          ))}
        </div>
      </SidepanelCard>
    )
  }
)
