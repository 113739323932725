import { IconChevronLeft, IconChevronRight } from '../..'
import { cn } from '../../utils/className'
import { buttonVariants } from '../variants/buttonVariants'
import type { ComponentProps } from 'react'
import { DayPicker, type DayPickerProps } from 'react-day-picker'

export type CalendarProps = ComponentProps<typeof DayPicker> & {
  className?: string
  classNames?: Partial<DayPickerProps['classNames']>
  showWeekNumber?: DayPickerProps['showWeekNumber']
}

/** For the Calendar to work, it needs some base styles from @react-day-picker
 *  Include the styles like this: import '@strise/ui-components/src/react-day-picker.css'
 */
const Calendar = ({ className, classNames, showWeekNumber, ...props }: CalendarProps): JSX.Element => {
  return (
    <DayPicker
      showOutsideDays
      className={cn('p-3', className)}
      classNames={{
        dropdown_root:
          '[&>span>svg]:mx-1 [&>span>svg]:w-[1.2rem] [&>span>svg]:pl-1 [&>span>svg]:rotate-90 [&>span>svg]:mb-2',
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-y-0 justify-center',
        month: 'flex flex-col items-center space-y-4',
        month_caption: 'flex justify-center pt-1 relative items-center text-text-primary',
        caption_label: 'font-medium text-text-primary',
        nav: 'space-x-1 flex items-center ',
        button_previous: cn(
          buttonVariants({ variant: 'outlined' }),
          'size-7 bg-transparent p-0 opacity-50 hover:bg-gray-5 absolute left-5 top-3 shadow-sm shadow-gray-50 rounded-md border-none'
        ),
        button_next: cn(
          buttonVariants({ variant: 'outlined' }),
          'size-7 bg-transparent p-0 opacity-50 hover:bg-gray-5 absolute right-5 top-3 shadow-sm shadow-gray-50 rounded-md border-none'
        ),
        month_grid: 'w-full border-collapse space-y-1',
        weekdays: cn('flex', showWeekNumber && 'justify-end'),
        weekday: 'rounded-md w-9 text-[0.8rem] text-text-primary',
        week: 'flex w-full mt-2',
        day: 'h-9 w-9 text-center text-sm p-0 relative',
        day_button: cn(
          buttonVariants({ variant: 'ghost' }),
          'size-9 p-0 rounded-md bg-transparent hover:bg-gray-40 hover:text-white text-text-primary focus-within:bg-gray-100 focus-within:text-white'
        ),
        range_start: 'bg-gray-100 [&>button]:text-white rounded-md',
        range_end: 'bg-gray-100 [&>button]:text-white rounded-md',
        range_middle: '[&>button]:bg-gray-50 rounded-md [&>button]:text-white',
        selected: 'bg-gray-100 rounded-md [&>button]:text-white',
        today: 'font-bold',
        disabled: 'text-gray-60 opacity-50',
        hidden: 'invisible',
        ...classNames
      }}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        Chevron: ({ ...item }) =>
          item.orientation === 'left' ? <IconChevronLeft className='size-3' /> : <IconChevronRight className='size-3' />
      }}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
