import { t } from '@lingui/macro'
import { ReactRouterLink } from '@strise/app-shared'
import {
  Card,
  CardContent,
  CardDescription,
  CardHighlight,
  CardTitle,
  IconArrowRight,
  Typography
} from '@strise/ui-components'
import type { ReactNode } from 'react'

interface HomeCardProps {
  description: string
  icon: ReactNode
  title: string
  url: string
}

export const HomeCard = ({ description, icon, title, url }: HomeCardProps): ReactNode => {
  return (
    <ReactRouterLink to={url} className='no-underline hover:no-underline'>
      <Card
        variant='contained'
        highlightPalette='primary'
        highlight='bottom'
        palette='primary'
        className='size-full border-gray-10 bg-gradient-to-b from-white from-50% to-blue-50 to-50% hover:to-blue-80 active:to-blue-90'
      >
        <CardContent className='h-full rounded-b-md bg-white'>
          <CardTitle className='py-0'>{icon}</CardTitle>
          <Typography variant='h2' className='py-4'>
            {title}
          </Typography>
          <CardDescription>
            <Typography variant='body1' className='text-gray-60'>
              {description}
            </Typography>
          </CardDescription>
        </CardContent>
        <CardHighlight>
          <Typography variant='body1'>
            {t`Go to`} {title}
          </Typography>
          <IconArrowRight />
        </CardHighlight>
      </Card>
    </ReactRouterLink>
  )
}
