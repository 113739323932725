import { makeVar } from '@apollo/client/index.js'
import { type ApiEnvState, initApiEnv } from '@strise/app-shared'
import { type ReviewSectionKind } from '@strise/types'
import { MenuState } from '~/components/Menu/menuUtils'
import { type FinancialFieldsMap } from '~/features/Financials/financialTypes'

// Team / portfolio
export const team = makeVar<string | null>(null)
export const portfolio = makeVar<string | null>(null)

// Financials
export const financialFieldsMap = makeVar<FinancialFieldsMap>({})

// API
export const apiEnvState = makeVar<ApiEnvState>(initApiEnv())

// Company
export const lastAddedCompanyState = makeVar<string | null>(null)
export const newCompaniesState = makeVar<string[]>([])

// Review

// Refresh review companies companies
export const refreshReviewCompanies = makeVar<number>(0)

// Refresh the review for a specific company if open
export const refreshReviewCompanyMap = makeVar<Record<string, number>>({})

// Refresh these sections for all open reviews
export const refreshReviewSections = makeVar<Partial<Record<ReviewSectionKind, number>>>({})

// Spoof
export const spoofUser = makeVar<string | null>(null)

// Team activities
export const refreshActivityViewState = makeVar<number>(0)
export const refreshCompanyRemindersState = makeVar<number>(0)

// Ownership
export const refreshOwnershipIdState = makeVar<string | null>(null)

// Menu
export const menuState = makeVar<MenuState>(MenuState.DEFAULT)
