import { i18n } from '@lingui/core'
import { type Duration } from '@strise/types'
import { Select, type SelectProps } from '@strise/ui-components'
import { capitalize } from 'lodash-es'
import { useMemo } from 'react'
import { timeOptions } from '~/components/Filter/FilterTime'
import { getTimeFilterText } from '~/features/Events/eventUtils'

export const EventsTimespan = ({
  duration,
  setDuration,
  ...props
}: {
  duration: Duration
  setDuration: (newDuration: Duration) => void
} & Omit<SelectProps<Duration>, 'options'>) => {
  const options = useMemo(
    () =>
      timeOptions.map((option) => ({
        value: option,
        children: capitalize(i18n._(getTimeFilterText(option)))
      })),
    []
  )

  return (
    <Select
      options={options}
      value={duration}
      placeholder='Time span'
      onValueChange={setDuration}
      variant='ghost'
      data-track='Sidepanel / Events / Time span select'
      {...props}
    />
  )
}
