import { Trans, t } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { Button, Chip, Divider, IconButton, IconHelp, Tooltip, Typography, cn } from '@strise/ui-components'
import {
  Modal,
  ModalContent,
  ModalHeader,
  Table,
  TableCell,
  TableHeadCell,
  TableRow,
  type TableSize
} from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { useToggle } from 'usehooks-ts'
import { useTruncatedList } from '~/components/TruncatedList/useTruncatedList'
import { type CountyCourtJudgementsReportFragment } from '~/graphqlTypes'

interface CCJTableProps {
  judgements: CountyCourtJudgementsReportFragment['judgements']
  size?: TableSize
  truncateAfter?: number
}

export const CCJTable = ({ judgements, size = 'large', truncateAfter = 10 }: CCJTableProps): ReactNode => {
  const [helpModalOpen, toggleHelpModalOpen] = useToggle(false)
  const { buttonText, hasReachedTreshold, itemsLeftText, showAll, toggleShowAll, truncatedItems } = useTruncatedList(
    judgements,
    truncateAfter
  )

  if (!judgements.length) return null

  const getStatusCellContent = (status?: string | null, paidDate?: string | null): ReactNode => {
    if (status === 'Satisfied') {
      return (
        <Tooltip
          arrow
          className='flex flex-col'
          content={
            <>
              <Trans>Settled date: </Trans>
              <span>{paidDate ? formatDate(paidDate) : t`Unknown`}</span>
            </>
          }
        >
          <Trans>Settled</Trans>
        </Tooltip>
      )
    }
    return status === 'Judgment' ? t`Pay due` : status
  }

  const headerProps = {
    palette: 'tertiary',
    textProps: {
      className: 'font-medium whitespace-nowrap',
      variant: null
    },
    className: 'px-2',
    size
  }
  const cellProps = {
    className: 'font-regular px-2',
    size
  }

  return (
    <div className='flex flex-col'>
      <Table>
        <thead>
          <TableRow>
            <TableHeadCell {...headerProps}>
              <Trans>Match</Trans>
              <IconButton
                className='rounded-full text-text-secondary'
                onClick={toggleHelpModalOpen}
                data-track="CCJ's / Help modal / Open"
              >
                <IconHelp size='lg' />
              </IconButton>
            </TableHeadCell>
            <TableHeadCell {...headerProps}>
              <Trans>Court</Trans>
            </TableHeadCell>
            <TableHeadCell {...headerProps}>
              <Trans>Case update</Trans>
            </TableHeadCell>
            <TableHeadCell {...headerProps}>
              <Trans>Amount</Trans>
            </TableHeadCell>
            <TableHeadCell {...headerProps}>
              <Trans>Date</Trans>
            </TableHeadCell>
          </TableRow>
        </thead>
        <tbody>
          {truncatedItems.map((judgement) => (
            <TableRow key={judgement.caseNumber}>
              <TableCell {...cellProps}>
                <Tooltip content={judgement.incomingRecordDetails}>
                  <span>
                    <Chip
                      className={cn(judgement.isExactMatch && 'bg-semantic-success-shade-10')}
                      label={judgement.isExactMatch ? t`Exact` : t`Likely`}
                    />
                  </span>
                </Tooltip>
              </TableCell>
              <TableCell {...cellProps}>{judgement.court}</TableCell>
              <TableCell {...cellProps}>{getStatusCellContent(judgement.status, judgement.paidDate)}</TableCell>

              <TableCell {...cellProps} className={cn(cellProps.className, 'whitespace-nowrap')}>
                {judgement.amount}
              </TableCell>
              <TableCell {...cellProps} className={cn(cellProps.className, 'whitespace-nowrap text-text-secondary')}>
                {judgement.date ? formatDate(judgement.date) : t`Unknown`}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {hasReachedTreshold && (
        <div className='flex items-center gap-1'>
          {itemsLeftText}
          <Button
            className='px-2'
            variant='ghost'
            palette='primary'
            data-track={`Button / Sidepanel / CCJ's / ${showAll ? 'Show less' : 'Show all'}`}
            onClick={toggleShowAll}
          >
            {buttonText}
          </Button>
        </div>
      )}
      <MatchHelpModal isOpen={helpModalOpen} handleClose={toggleHelpModalOpen} />
    </div>
  )
}
const gridClassName = 'grid grid-cols-[0.5fr_0.5fr_1fr] py-6'

const MatchHelpModal = ({ handleClose, isOpen }: { handleClose: () => void; isOpen: boolean }): ReactNode => {
  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} contentMaxWidth={1000}>
      <ModalHeader onClose={handleClose}>
        <Typography component='h1' variant='subtitle1'>
          <Trans>Match</Trans>
        </Typography>
      </ModalHeader>
      <ModalContent>
        <div className='flex flex-col p-10'>
          <Divider className='mt-10' />
          <div className={gridClassName}>
            <span>
              <Trans>Match</Trans>
            </span>
            <span>
              <Chip className='bg-semantic-success-shade-10' label={t`Exact`} />
            </span>
            <Typography>
              <Trans>
                This is a perfect match. The company name in our records exactly matches the one in CCJ records.
              </Trans>
            </Typography>
          </div>
          <Divider />
          <div className={gridClassName}>
            <span />
            <span>
              <Chip label={t`Likely`} />
            </span>
            <Typography>
              <Trans>
                Almost there! This means the company name is very close, but not an exact match. For example, if our
                record shows 'Shady Shark' but the actual name is 'Shady Shark Limited', our data provider tag it as
                'Likely'.
              </Trans>
            </Typography>
          </div>
          <Divider />
          <div className={gridClassName}>
            <span />
            <span>
              <Tooltip arrow content='Giffgaff, Hertz House, Vine Street, Uxbridge, ub8 1qe, ub8 1qe' open>
                <Chip label={t`Likely`} />
              </Tooltip>
            </span>
            <Typography>
              <Trans>Hover the 'likely' tag to reveal data match</Trans>
            </Typography>
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}
