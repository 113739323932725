import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconChevronRightSmall = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M10.707 7.293 15.414 12l-4.707 4.707-1.414-1.414L12.586 12 9.293 8.707l1.414-1.414Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconChevronRightSmall.displayName = 'IconChevronRightSmall'
