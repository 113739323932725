import { type ApolloClient, type ApolloLink, ApolloProvider, type NormalizedCacheObject } from '@apollo/client/index.js'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'

export const ApolloClientProvider = ({
  children,
  client,
  link
}: {
  children: ReactNode
  client: ApolloClient<NormalizedCacheObject>
  link: ApolloLink
}) => {
  const [hasLink, setHasLink] = useState(false)

  useEffect(() => {
    client.setLink(link)
    setHasLink(true)
  }, [link, client])

  if (!hasLink) return null

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
