import { t } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { Divider, IconSend, Typography } from '@strise/ui-components'
import { type IdvRoleFragment } from '~/graphqlTypes'

export const IDVCheckTooltipPending = ({ role }: { role: IdvRoleFragment }) => {
  return (
    <div className='max-w-60 p-1'>
      <div className='flex flex-col gap-y-1'>
        <Typography variant='subtitle2' className='flex flex-row items-center justify-start gap-x-2'>
          <IconSend />
          {t`Waiting for documents`}
        </Typography>
        <Typography variant='body2'>
          {t`ID is requested and`} <Typography variant='aLabelSmallBold'>{role.person.name ?? t`Unknown`}</Typography>{' '}
          {t`has been notified by email`}.
        </Typography>
      </div>
      <Divider className='my-1' />
      <div className='flex flex-col items-start justify-start gap-x-1'>
        <Typography variant='body2'>
          {t`Data notified`}:{' '}
          <Typography variant='aLabelSmallBold'>
            {role.idvRequest?.documentNotifiedAt
              ? formatDate(role.idvRequest.documentNotifiedAt, { relative: false, time: true })
              : t`Now`}
          </Typography>
        </Typography>
        <Typography variant='body2'>
          {t`Link expires`}:{' '}
          <Typography variant='aLabelSmallBold'>
            {role.idvRequest?.linkExpiresAt
              ? formatDate(role.idvRequest.linkExpiresAt, { relative: false, time: true })
              : t`N/A`}
          </Typography>
        </Typography>
      </div>
    </div>
  )
}
