import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconAmLcheckNotChecked = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M2 4a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v7h-2V4a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h5.579v2H5a3 3 0 0 1-3-3V4Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M18 7H6V5h12v2ZM18 11H6V9h12v2ZM11 15H6v-2h5v2ZM11 19H6v-2h5v2Z'
        clipRule='evenodd'
      />
      <path
        fill='#000'
        fillRule='evenodd'
        d='M18 23h-2v-2h2v2ZM13 15a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-1v1h-2v-1a2 2 0 0 1 2-2h1v-2h-4v1.48h-2V15Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconAmLcheckNotChecked.displayName = 'IconAmLcheckNotChecked'
