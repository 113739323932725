import { type MutationUpdaterFn } from '@apollo/client/index.js'
import { Trans, t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import { type SetStateFn, setChildState } from '@strise/react-utils'
import { objectEntries } from '@strise/ts-utils'
import {
  AuditRisk,
  CompanyStatus,
  type ReviewAmsEventFeedbackInput,
  type ReviewSectionKind,
  ReviewSettingKind,
  ReviewStatus,
  TrackedActivityKind
} from '@strise/types'
import { Alert, Button, Divider, Typography, cn } from '@strise/ui-components'
import { ConfirmDialog } from '@strise/ui-components-legacy'
import type { ReactNode, RefObject } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useToggle } from 'usehooks-ts'
import RegistryCheckButton from '~/components/RegistryCheck/RegistryCheckButton'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { updateCompanyStatusCache } from '~/features/CompanyStatus/companyStatusUtils'
import { ConflictAlert } from '~/features/Conflicts/ConflictAlert'
import { ConflictAlertType } from '~/features/Conflicts/ConflictAlertType'
import { useUnresolvedConflicts } from '~/features/Conflicts/useHasUnresolvedConflicts'
import { ReviewAutomatedRiskRatingSection } from '~/features/Review/ReviewAutomatedRiskRatingSection'
import { RowValueSkeleton } from '~/features/Review/ReviewCompanyCardLoader'
import { ReviewCompanyStatusSection } from '~/features/Review/ReviewCompanyStatusSection'
import { ReviewRiskAssessmentSection } from '~/features/Review/ReviewRiskAssessmentSection'
import { ReviewRow } from '~/features/Review/ReviewRow'
import { ReviewSelectAllCheckboxSection } from '~/features/Review/ReviewSelectAllCheckboxSection'
import { ReviewSummarySection } from '~/features/Review/ReviewSummarySection'
import { extractReviewSections } from '~/features/Review/reviewCompanyCardUtils'
import { ReviewCardContentView, type ReviewState, extractEntityAmsHits } from '~/features/Review/reviewUtils'
import { useCreateReviewMutation } from '~/graphqlOperations'
import {
  type BaseReviewCompanyFragment,
  type CreateReviewMutation,
  type ReviewAlertFragment,
  type ReviewCompanyDataFragment,
  type TeamReviewSettingsV2Fragment
} from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'
import { track } from '~/utils/tracking'

interface ReviewCompanyCardContentProps {
  alerts: ReviewAlertFragment[]
  baseCompany: BaseReviewCompanyFragment
  cardAnchorRef: RefObject<HTMLDivElement>
  errorSections: Set<ReviewSectionKind>
  loadingSections: Set<ReviewSectionKind>
  resetReviewState: (companyId: string) => void
  reviewCompanyData: ReviewCompanyDataFragment | null
  reviewState: ReviewState
  setCardView: SetStateFn<ReviewCardContentView>
  setIsReviewFinished: SetStateFn<boolean>
  setReviewState: SetStateFn<ReviewState>
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
}

export const ReviewCompanyCardContent = ({
  alerts,
  baseCompany,
  cardAnchorRef,
  errorSections,
  loadingSections,
  resetReviewState,
  reviewCompanyData,
  reviewState,
  setCardView,
  setIsReviewFinished,
  setReviewState,
  teamReviewSettings
}: ReviewCompanyCardContentProps): ReactNode => {
  const features = useCurrentUserFeatures()

  const checkedMap = reviewState.checkedMap
  const comment = reviewState.comment
  const inlineCommentsMap = reviewState.inlineCommentsMap
  const setCheckedMap = setChildState(setReviewState, 'checkedMap')
  const setComment = setChildState(setReviewState, 'comment')
  const setInlineCommentsMap = setChildState(setReviewState, 'inlineCommentsMap')

  const existingReviews = baseCompany.reviews.edges
  const existingReview = existingReviews.length ? existingReviews[0]?.node : null

  const [companyStatus, setCompanyStatus] = useState<CompanyStatus | null>(baseCompany.statusV2?.status || null)
  const [hasSubmitErrors, setHasSubmitHasSubmitErrors] = useState(false)
  const [submittedWithErrors, setSubmittedWithErrors] = useState(false)

  const [auditRisk, setAuditRisk] = useState(existingReview?.risk || AuditRisk.None)
  const [customRiskLevel, setCustomRiskLevel] = useState<string | null>(null)

  const reviewCardRef = useRef<HTMLDivElement | null>(null)

  const [resetDialogOpen, toggleResetDialogOpen, setResetDialogOpen] = useToggle(false)

  const hasLoadingSections = loadingSections.size !== 0

  const { conflictsLoading, hasConflicts } = useUnresolvedConflicts(baseCompany)

  const handleCompleted = (): void => {
    toast.success(features.EMAIL_REVIEW ? t`Review created. The report is sent to your email.` : t`Review created`)
    resetReviewState(baseCompany.id)
    setCardView(ReviewCardContentView.AFTER_REVIEW)
    // Since the opened card is long, we scroll to top of card after collapsing the card
    // to avoid the user ending up in the middle of the page
    cardAnchorRef.current?.scrollIntoView(true)
  }

  const handleReset = (): void => {
    setCheckedMap({})
    setComment('')
    setResetDialogOpen(false)
    setCardView(ReviewCardContentView.BEFORE_REVIEW)
    resetReviewState(baseCompany.id)
  }

  const handleClose = (): void => {
    setResetDialogOpen(false)
  }

  const updateCache: MutationUpdaterFn<CreateReviewMutation> = (cache, { data: mutationData }) => {
    const { id, statusV2 } = mutationData?.createReviewV2.company ?? {}
    if (!id || statusV2?.status === undefined) return
    cache.modify(
      updateCompanyStatusCache({
        __typename: 'SimpleCompany',
        id,
        statusV2: { status: statusV2.status }
      })
    )
  }

  const [create, { loading: createReviewLoading }] = useCreateReviewMutation({
    onCompleted: handleCompleted,
    update: updateCache
  })

  useEffect(() => {
    if (submittedWithErrors) validateHasErrors()
  }, [checkedMap, companyStatus])

  const sections = extractReviewSections({
    companyId: baseCompany.id,
    baseCompany,
    reviewCompanyData,
    reviewState,
    setReviewState
  })

  const rowKeys = sections.flatMap((section) => {
    return section.rows.filter((row) => !!row.children).map((row) => row.key)
  })

  const checkedCount = rowKeys.filter((key) => checkedMap[key]).length

  const validateHasErrors = (): boolean => {
    if (companyStatus === CompanyStatus.InQualification) {
      setHasSubmitHasSubmitErrors(true)
      return true
    }

    const rowsCount = rowKeys.length
    if (checkedCount !== rowsCount) {
      setHasSubmitHasSubmitErrors(true)
      return true
    }

    setHasSubmitHasSubmitErrors(false)
    return false
  }

  const handleSubmit = async (): Promise<void> => {
    const hasErrors = validateHasErrors()
    if (hasErrors) {
      setSubmittedWithErrors(true)
      return
    }

    try {
      setIsReviewFinished(true)
      const reviewStatus =
        companyStatus === CompanyStatus.Disqualified ? ReviewStatus.Disapproved : ReviewStatus.Approved

      const inlineCommentsInput = objectEntries(inlineCommentsMap).flatMap(([key, inlineComments]) => {
        if (!inlineComments) return []

        const matchingRow = sections.flatMap((section) => section.rows).find((row) => row.key === key)

        if (!matchingRow) return []

        return inlineComments.map((inlineComment) => {
          return {
            message: inlineComment.message,
            fieldKindV2: matchingRow.kind,
            createdBy: inlineComment.createdBy.id,
            createdAt: inlineComment.createdAt,
            customCheckboxName: 'customCheckboxName' in matchingRow ? matchingRow.customCheckboxName : undefined
          }
        })
      })

      const entityAmsHits = reviewCompanyData ? extractEntityAmsHits(reviewCompanyData) : []

      const reviewAmsFeedback: ReviewAmsEventFeedbackInput = {
        feedback: reviewState.amsEventFeedback,
        hits: entityAmsHits
      }

      await create({
        variables: {
          entity: baseCompany.id,
          input: {
            companyStatus,
            reviewStatus,
            comment,
            auditRisk,
            customRiskLevel,
            inlineCommentsV2: inlineCommentsInput,
            amsEventFeedback: features.AMS_EVENT_FEEDBACK ? reviewAmsFeedback : undefined
          }
        }
      })

      track(TrackedActivityKind.StriseReviewCreated, {
        companyId: baseCompany.id,
        legalForm: reviewCompanyData?.legalForm,
        auditRisk,
        customRiskLevel,
        reviewStatus,
        companyStatus,
        comment,
        origin: 'REVIEW'
      })

      resetReviewState(baseCompany.id)
    } catch (error) {
      console.error('Error submitting review:', error)
      toast.error(t`An error occurred while submitting the Review`)
    }
  }

  const checkAllIsEnabled = teamReviewSettings.settings.find(
    (setting) => setting.kind === ReviewSettingKind.EnableCheckAll
  )?.enabled

  return (
    <div ref={reviewCardRef} className={checkAllIsEnabled ? undefined : '-mt-4'}>
      {features.REGISTRY_CHECK && <RegistryCheckButton entity={baseCompany} />}
      <ConflictAlert type={ConflictAlertType.REVIEW} entity={baseCompany} className='-mt-4 mb-8' />
      <div className={cn(hasConflicts && 'pointer-events-none select-none opacity-40')}>
        {checkAllIsEnabled && (
          <ReviewSelectAllCheckboxSection
            className='pb-0 pl-4'
            idSuffix='top'
            checkedCount={checkedCount}
            setCheckedMap={setCheckedMap}
            rowKeys={rowKeys}
            id={baseCompany.id}
            errors={false}
            disabled={createReviewLoading || hasConflicts}
            loading={hasLoadingSections || conflictsLoading}
          />
        )}

        {sections.map((section) => {
          const hasSectionError = errorSections.has(section.sectionKind)
          const hideSection = !hasSectionError && section.rows.every((row) => !row.children)
          const isSectionLoading = loadingSections.has(section.sectionKind)

          if (!isSectionLoading && hideSection) return null

          return (
            <div key={section.title}>
              <Typography className='mb-2 mt-4 px-4' variant='subtitle1' data-id={`Review / ${section.title}`}>
                {section.title}
              </Typography>

              <Divider />

              {hasSectionError && (
                <div className='p-4'>
                  <Alert variant='danger'>{t`Something went wrong. Please try again`}</Alert>
                </div>
              )}

              {isSectionLoading && (
                <div className='flex flex-col gap-4 p-4'>
                  {Array.from({ length: 3 }).map((_, rowIndex) => (
                    <RowValueSkeleton key={rowIndex} />
                  ))}
                </div>
              )}

              {!isSectionLoading &&
                section.rows.map((row) => {
                  return (
                    <ReviewRow
                      key={row.key}
                      checkedMap={checkedMap}
                      setCheckedMap={setCheckedMap}
                      entityId={baseCompany.id}
                      loading={createReviewLoading || conflictsLoading}
                      errors={hasSubmitErrors}
                      teamReviewSettings={teamReviewSettings}
                      inlineCommentsMap={inlineCommentsMap}
                      setInlineCommentsMap={setInlineCommentsMap}
                      disabled={hasConflicts}
                      row={row}
                      alerts={alerts}
                    />
                  )
                })}
            </div>
          )
        })}

        <div data-id='Review / Comment and Done'>
          <div className='px-4'>
            {checkAllIsEnabled && (
              <ReviewSelectAllCheckboxSection
                idSuffix='bottom'
                className='mt-2'
                checkedCount={checkedCount}
                setCheckedMap={setCheckedMap}
                rowKeys={rowKeys}
                id={baseCompany.id}
                disabled={createReviewLoading || hasConflicts}
                loading={hasLoadingSections || conflictsLoading}
                errors={hasSubmitErrors}
              />
            )}

            <ReviewAutomatedRiskRatingSection teamReviewSettings={teamReviewSettings} entityId={baseCompany.id} />

            <ReviewRiskAssessmentSection
              auditRisk={auditRisk}
              setAuditRisk={setAuditRisk}
              customRiskLevel={customRiskLevel}
              setCustomRiskLevel={setCustomRiskLevel}
              disabled={createReviewLoading}
              teamReviewSettings={teamReviewSettings}
            />

            <ReviewCompanyStatusSection
              companyId={baseCompany.id}
              companyStatus={companyStatus}
              noDivider
              setCompanyStatus={setCompanyStatus}
              errors={hasSubmitErrors}
              disabled={createReviewLoading}
            />
            {teamReviewSettings.settings.find((setting) => setting.kind === ReviewSettingKind.EnableComment)
              ?.enabled && (
              <ReviewSummarySection
                containerRef={reviewCardRef}
                loading={hasLoadingSections}
                onChange={setComment}
                value={comment}
              />
            )}
          </div>
        </div>
      </div>

      <div className='mt-4 flex items-center px-4'>
        <div className='flex-1'>
          {createReviewLoading && (
            <Typography className='mr-4 flex-1'>
              {features.EMAIL_REVIEW ? (
                <Trans>
                  This may take some time. You can safely continue using the app, and you will receive an email when the
                  report is finished.
                </Trans>
              ) : (
                <Trans>This may take some time. You can safely continue using the app.</Trans>
              )}
            </Typography>
          )}
          {hasSubmitErrors && (
            <Typography data-id={TestIDs.Review.Card.errorMessage} className='text-semantic-danger-main'>
              <Trans>Please correct the required inputs above</Trans>
            </Typography>
          )}
        </div>

        <Button
          variant='ghost'
          palette='secondary'
          disabled={createReviewLoading}
          onClick={toggleResetDialogOpen}
          data-track='false'
          className='mr-1'
          data-id={TestIDs.Review.Card.resetReviewButton}
        >
          {t`Reset`}
        </Button>
        <Button
          className='px-10'
          variant='contained'
          palette='primary'
          loading={createReviewLoading}
          onClick={handleSubmit}
          data-track='false'
          disabled={hasSubmitErrors || hasConflicts || conflictsLoading}
          data-id={TestIDs.Review.Card.submitReviewButton}
        >
          {t`Save Review`}
        </Button>
        {resetDialogOpen && (
          <ConfirmDialog
            isOpen
            confirmText={t`Confirm`}
            cancelText={t`Cancel`}
            onConfirm={handleReset}
            onCancel={handleClose}
            confirmButtonProps={{ palette: 'danger', 'data-id': TestIDs.Review.Card.resetReviewConfirmButton }}
            title={t`Reset`}
            contentMaxWidth={888}
          >
            <Typography>
              <Trans>You are about to reset the comment, inline comments and checkboxes</Trans>
            </Typography>
          </ConfirmDialog>
        )}
      </div>
    </div>
  )
}
