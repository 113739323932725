import { type ImgHTMLAttributes, type ReactNode, forwardRef, useEffect, useState } from 'react'

const defaultFallbackSrc =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAIAAAAiOjnJAAAABGdBTUEAALGPC/xhBQAABDBJREFUeF7t1bERACEMxEDov2j/04MVSRRwgWbH3Jk5vQqsF/hh9SqwXuCsLzZYgfcNVqECRIFgEVXb7GJlgCnQxWK66leDpSfABAgW01W/Giw9ASZAsJiu+tVg6QkwAYLFdNWvBktPgAkQLKarfjVYegJMgGAxXfWrwdITYAIEi+mqXw2WngATIFhMV/1qsPQEmADBYrrqV4OlJ8AECBbTVb8aLD0BJkCwmK761WDpCTABgsV01a8GS0+ACRAspqt+NVh6AkyAYDFd9avB0hNgAgSL6apfDZaeABMgWExX/Wqw9ASYAMFiuupXg6UnwAQIFtNVvxosPQEmQLCYrvrVYOkJMAGCxXTVrwZLT4AJECymq341WHoCTIBgMV31q8HSE2ACBIvpql8Nlp4AEyBYTFf9arD0BJgAwWK66leDpSfABAgW01W/Giw9ASZAsJiu+tVg6QkwAYLFdNWvBktPgAkQLKarfjVYegJMgGAxXfWrwdITYAIEi+mqXw2WngATIFhMV/1qsPQEmADBYrrqV4OlJ8AECBbTVb8aLD0BJkCwmK761WDpCTABgsV01a8GS0+ACRAspqt+NVh6AkyAYDFd9avB0hNgAgSL6apfDZaeABMgWExX/Wqw9ASYAMFiuupXg6UnwAQIFtNVvxosPQEmQLCYrvrVYOkJMAGCxXTVrwZLT4AJECymq341WHoCTIBgMV31q8HSE2ACBIvpql8Nlp4AEyBYTFf9arD0BJgAwWK66leDpSfABAgW01W/Giw9ASZAsJiu+tVg6QkwAYLFdNWvBktPgAkQLKarfjVYegJMgGAxXfWrwdITYAIEi+mqXw2WngATIFhMV/1qsPQEmADBYrrqV4OlJ8AECBbTVb8aLD0BJkCwmK761WDpCTABgsV01a8GS0+ACRAspqt+NVh6AkyAYDFd9avB0hNgAgSL6apfDZaeABMgWExX/Wqw9ASYAMFiuupXg6UnwAQIFtNVvxosPQEmQLCYrvrVYOkJMAGCxXTVrwZLT4AJECymq341WHoCTIBgMV31q8HSE2ACBIvpql8Nlp4AEyBYTFf9arD0BJgAwWK66leDpSfABAgW01W/Giw9ASZAsJiu+tVg6QkwAYLFdNWvBktPgAkQLKarfjVYegJMgGAxXfWrwdITYAIEi+mqXw2WngATIFhMV/1qsPQEmADBYrrqV4OlJ8AECBbTVb8aLD0BJkCwmK761WDpCTABgsV01a8GS0+ACRAspqt+NVh6AkyAYDFd9avB0hNgAgSL6apfDZaeABMgWExX/Wqw9ASYAMFiuupXg6UnwAQIFtNVvxosPQEmQLCYrvrVYOkJMAGCxXTVrwZLT4AJECymq341WHoCTIBgMV31q8HSE2ACBIvpql8Nlp4AEyBYTFf9arD0BJgAwWK66leDpSfABPgAKXOzx1khoKwAAAAASUVORK5CYII='

const fetchIsValidImage = async (url: string | undefined): Promise<unknown> => {
  if (!url) return false

  const mutableImage = new Image()
  mutableImage.src = url
  return await new Promise((resolve) => {
    mutableImage.onload = () => resolve(true)
    mutableImage.onerror = () => resolve(false)
  })
}

export type ImgProps = {
  fallbackSrc?: string
  noFallback?: boolean
  src: string | null | undefined
} & Omit<ImgHTMLAttributes<HTMLElement>, 'src'>

export const Img = forwardRef<HTMLImageElement, ImgProps>(
  ({ alt, className, fallbackSrc, noFallback, src, ...props }, ref): ReactNode => {
    const [srcState, setSrcState] = useState<string | null>(null)

    useEffect(() => {
      if (!src) return
      void (async () => {
        const isValid = await fetchIsValidImage(src)

        setSrcState(() => {
          if (!isValid && noFallback) return null
          if (!isValid && !noFallback) return fallbackSrc ?? defaultFallbackSrc

          return src
        })
      })()
    }, [src, fallbackSrc, noFallback])

    if (!srcState) return <div className={className} ref={ref} {...props} />

    return <img className={className} ref={ref} src={srcState} {...props} alt={alt} />
  }
)
