import { type MessageDescriptor, i18n } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { Chip, cn } from '@strise/ui-components'
import { type ReactNode } from 'react'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { EntityLocationFilterKind } from '~/features/Search/searchUtils'
import { getTitle } from '~/utils/enum'

const entityLocationFilterToTitle: Record<EntityLocationFilterKind, MessageDescriptor> = {
  [EntityLocationFilterKind.ALL]: defineMessage({ message: 'All' }),
  [EntityLocationFilterKind.NORDICS]: defineMessage({ message: 'Nordics' }),
  [EntityLocationFilterKind.UK]: defineMessage({ message: 'UK' }),
  [EntityLocationFilterKind.GERMANY]: defineMessage({ message: 'Germany' }),
  [EntityLocationFilterKind.BENELUX]: defineMessage({ message: 'Benelux' }),
  [EntityLocationFilterKind.FRANCE]: defineMessage({ message: 'France' }),
  [EntityLocationFilterKind.GLOBAL]: defineMessage({ message: 'Global' })
}

export const SearchEntityLocationFilter = ({
  setState,
  state
}: {
  setState: SetStateFn<EntityLocationFilterKind>
  state: EntityLocationFilterKind
}): ReactNode => {
  const features = useCurrentUserFeatures()

  const filteredLocations = Object.values(EntityLocationFilterKind).filter((value) => {
    if (value === EntityLocationFilterKind.GLOBAL) return features.GLOBAL_ENTITIES || features.GLOBAL_ENTITIES_V2
    if (value === EntityLocationFilterKind.UK) return features.CONTENT_UNITED_KINGDOM
    if (value === EntityLocationFilterKind.GERMANY) return features.CONTENT_GERMAN
    if (value === EntityLocationFilterKind.NORDICS)
      return (
        features.CONTENT_DANISH || features.CONTENT_NORWEGIAN || features.CONTENT_SWEDISH || features.CONTENT_FINNISH
      )
    if (value === EntityLocationFilterKind.BENELUX) {
      return features.CONTENT_LUXEMBOURGISH || features.CONTENT_DUTCH || features.CONTENT_BELGIAN
    }
    if (value === EntityLocationFilterKind.FRANCE) return features.CONTENT_FRENCH
    return true
  })

  // We only want to show location filter if we have all + at least two others
  if (filteredLocations.length < 3) return null

  return (
    <div className='flex p-2 pt-4'>
      {filteredLocations.map((value) => {
        const title = getTitle(entityLocationFilterToTitle[value])
        const isActive = state === value
        return (
          <Chip
            className={cn('mx-2 rounded-[4px]', isActive ? '' : 'bg-secondary-shade-5')}
            key={value}
            variant='contained'
            palette={isActive ? 'primary' : 'tertiary'}
            label={i18n._(title)}
            onClick={() => setState(value)}
          />
        )
      })}
    </div>
  )
}
