import { cn } from '../../utils/className'
import { Label, type LabelProps } from '../Label'
import { Typography, type TypographyProps } from '../Typography'
import { useFormField } from './useFormField'
import { Slot } from '@radix-ui/react-slot'
import { createContext } from '@strise/react-utils'
import type { ReactNode } from 'react'
import { forwardRef, useId, useMemo } from 'react'
import { Controller, type ControllerProps, type FieldPath, type FieldValues, FormProvider } from 'react-hook-form'

const Form = FormProvider

interface FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName
}

const FormFieldContext = createContext<FormFieldContextValue>()

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  ...props
}: ControllerProps<TFieldValues, TName>): ReactNode => {
  const value = useMemo(() => ({ name: props.name }), [props.name])
  return (
    <FormFieldContext.Provider value={value}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  )
}

interface FormItemContextValue {
  id: string
  required?: boolean
}

const FormItemContext = createContext<FormItemContextValue>()

const FormItem = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & { required?: boolean }>(
  ({ className, required, ...props }, ref): ReactNode => {
    const id = useId()

    const value = useMemo(() => ({ id, required }), [id, required])

    return (
      <FormItemContext.Provider value={value}>
        <div ref={ref} className={cn('flex flex-col space-y-1', className)} {...props} />
      </FormItemContext.Provider>
    )
  }
)
FormItem.displayName = 'FormItem'

const FormLabel = forwardRef<HTMLLabelElement, LabelProps>(({ children, className, ...props }, ref): ReactNode => {
  const { error, formItemId, required } = useFormField()

  return (
    <Label
      ref={ref}
      variant='aLabelSmallBold'
      className={cn(error && 'text-semantic-danger-main', className)}
      htmlFor={formItemId}
      {...props}
    >
      {children}
      {required && <span className='text-semantic-danger-main'>*</span>}
    </Label>
  )
})
FormLabel.displayName = 'FormLabel'

const FormControl = forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(
  ({ ...props }, ref): ReactNode => {
    const { error, formDescriptionId, formItemId, formMessageId } = useFormField()

    return (
      <Slot
        ref={ref}
        id={formItemId}
        aria-describedby={error ? `${formDescriptionId} ${formMessageId}` : formDescriptionId}
        aria-invalid={!!error}
        {...props}
      />
    )
  }
)
FormControl.displayName = 'FormControl'

const FormDescription = forwardRef<HTMLParagraphElement, TypographyProps>(
  ({ children, className, ...props }, ref): ReactNode => {
    const { formDescriptionId } = useFormField()

    return (
      <Typography
        ref={ref}
        id={formDescriptionId}
        className={cn('text-sm text-text-secondary', className)}
        variant='body2'
        {...props}
      >
        {children}
      </Typography>
    )
  }
)
FormDescription.displayName = 'FormDescription'

const FormMessage = forwardRef<HTMLParagraphElement, TypographyProps>(
  ({ children, className, ...props }, ref): ReactNode => {
    const { error, formMessageId } = useFormField()
    const body = error ? String(error.message) : children

    if (!body) {
      return null
    }

    return (
      <Typography
        ref={ref}
        id={formMessageId}
        className={cn('text-semantic-danger-main', className)}
        variant='aLabelSmallBold'
        {...props}
      >
        {body}
      </Typography>
    )
  }
)
FormMessage.displayName = 'FormMessage'

export {
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
  FormFieldContext,
  FormItemContext
}
