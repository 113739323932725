import { t } from '@lingui/macro'
import { Duration } from '@strise/types'

export const getDurationTitles = () => ({
  [Duration.Day]: t`Review last 24 hours`,
  [Duration.Week]: t`Review last 7 days`,
  [Duration.Month]: t`Review last 30 days`,
  [Duration.HalfYear]: t`Review last 6 months`,
  [Duration.Year]: t`Review last year`,
  [Duration.TwoYear]: t`Review last 2 years`,
  [Duration.ThreeYear]: t`Review last 3 years`,
  [Duration.FourYear]: t`Review last 4 years`,
  [Duration.FiveYear]: t`Review last 5 years`
})

export const getDurationTitle = (duration: Duration): string => {
  return getDurationTitles()[duration] || t`Review`
}

export const getDurationLabel = (duration: Duration): string =>
  ({
    [Duration.Day]: t`last 24 hours`,
    [Duration.Week]: t`last 7 days`,
    [Duration.Month]: t`last 30 days`,
    [Duration.HalfYear]: t`last 6 months`,
    [Duration.Year]: t`last year`,
    [Duration.TwoYear]: t`last 2 years`,
    [Duration.ThreeYear]: t`last 3 years`,
    [Duration.FourYear]: t`last 4 years`,
    [Duration.FiveYear]: t`last 5 years`
  })[duration] || t`Unknown duration`

const durationOrder = {
  [Duration.Day]: 1,
  [Duration.Week]: 2,
  [Duration.Month]: 3,
  [Duration.HalfYear]: 4,
  [Duration.Year]: 5,
  [Duration.TwoYear]: 6,
  [Duration.ThreeYear]: 7,
  [Duration.FourYear]: 8,
  [Duration.FiveYear]: 9
} as const

export const sortDurations = (a: Duration, b: Duration): number => {
  return durationOrder[a] - durationOrder[b]
}
