import { FlagSeverity } from '@strise/types'
import {
  IconInfoBlue,
  IconPositiveGreen,
  type IconProps,
  type IconPropsWithRef,
  IconWarningRed,
  IconWarningYellow,
  cn
} from '@strise/ui-components'
import type { ForwardRefExoticComponent, ReactNode, RefAttributes } from 'react'

const severityToComponent: Partial<
  Record<FlagSeverity, ForwardRefExoticComponent<Omit<IconProps, 'ref'> & RefAttributes<SVGSVGElement>>>
> = {
  [FlagSeverity.Positive]: IconPositiveGreen,
  [FlagSeverity.Low]: IconInfoBlue,
  [FlagSeverity.Medium]: IconWarningYellow,
  [FlagSeverity.High]: IconWarningRed
}

export const AdverseFlag = ({
  className,
  severity,
  ...props
}: { severity: FlagSeverity } & IconPropsWithRef): ReactNode => {
  const VariantComponent = severityToComponent[severity]

  if (!VariantComponent) return null

  // Using `text-text-primary` here since all our icons with primary text color is overridden to use `currentColor`,
  // whilst the ones with white text color has kept the hardcoded white color.
  return <VariantComponent className={cn('text-text-primary', className)} size='md' {...props} />
}
