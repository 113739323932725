import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconChevronLeft = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M6.586 12 17.293 1.293l1.414 1.414L9.414 12l9.293 9.293-1.414 1.414L6.586 12Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconChevronLeft.displayName = 'IconChevronLeft'
