import { Trans } from '@lingui/macro'
import { PrivatePersonReviewStatusKind } from '@strise/types'
import { Button, IconInfo, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useUpdatePrivatePersonReviewStatus } from '~/features/PrivatePersons/privatePerson'
import { type ReviewPrivatePersonFragment } from '~/graphqlTypes'

interface PrivatePersonReviewCardFooterProps {
  privatePerson: ReviewPrivatePersonFragment
}

export const PrivatePersonReviewCardFooter = ({ privatePerson }: PrivatePersonReviewCardFooterProps): ReactNode => {
  const { updateReviewStatus, updateReviewStatusLoading } = useUpdatePrivatePersonReviewStatus()

  return (
    <div className='flex items-center justify-between rounded-b-[12px] border border-solid border-tertiary-shade-15 bg-tertiary-main'>
      <div className='flex items-center p-2'>
        <IconInfo />
        <Typography className='ml-2 flex items-center' variant='aLabelSmall'>
          <Trans>Added by</Trans> {privatePerson.reviewStatus?.createdBy?.name}
        </Typography>
      </div>
      <Button
        loading={updateReviewStatusLoading}
        variant='contained'
        onClick={async () => await updateReviewStatus(privatePerson.id, PrivatePersonReviewStatusKind.NotInReview)}
        data-track='Review / Remove from Review'
        className='rounded-br-[12px] *:rounded-br-[12px]'
      >
        <Trans>Remove from Review</Trans>
      </Button>
    </div>
  )
}
