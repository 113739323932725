import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconRefresh = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M20.242 12a8.636 8.636 0 0 1-8.637 8.636c-4.77 0-8.73-3.866-8.73-8.636a8.636 8.636 0 0 1 15.822-4.794l-4.781-1.115-.49 2.102 8.165 1.905 1.245-8.741-2.138-.305-.622 4.374a10.777 10.777 0 0 0-8.564-4.222C5.692 1.204.947 5.811.725 11.577v.824c.208 5.78 5.05 10.395 10.88 10.395 5.83 0 10.581-4.622 10.789-10.401L20.242 12Z'
      />
    </svg>
  )
)
IconRefresh.displayName = 'IconRefresh'
