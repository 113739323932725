import { ConflictType } from '~/features/Conflicts/ConflictType'
import { useConflictsQuery } from '~/graphqlOperations'
import { type ConflictsQuery } from '~/graphqlTypes'
import { type EntityIdentity } from '~/utils/entity'

interface UseUnresolvedConflicts {
  conflicts: ConflictsQuery['company']['conflicts']
  conflictsLoading: boolean
  hasConflicts: boolean
  hasOwnershipConflicts: boolean
  hasRoleConflicts: boolean
  isCompany: boolean
  loading: boolean
}

export const useUnresolvedConflicts = (entity: EntityIdentity): UseUnresolvedConflicts => {
  const isCompany = entity.__typename === 'Company'

  const { data, loading } = useConflictsQuery({
    nextFetchPolicy: 'cache-and-network',
    variables: {
      entity: entity.id,
      resolved: false
    },
    skip: !isCompany
  })

  const conflicts = data?.company.conflicts ?? []
  const hasConflicts = conflicts.length > 0
  const hasRoleConflicts = conflicts.some((conflict) => conflict.__typename === ConflictType.ROLES)
  const hasOwnershipConflicts = conflicts.some((conflict) => conflict.__typename === ConflictType.OWNERSHIPS)

  return {
    conflicts,
    conflictsLoading: loading,
    hasConflicts,
    hasRoleConflicts,
    hasOwnershipConflicts,
    isCompany,
    loading
  }
}
