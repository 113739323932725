import { cn } from '../../utils/className'
import { type DivProps } from '@strise/react-utils'
import { type ReactNode, forwardRef } from 'react'

export interface ScrollbarProps extends DivProps {
  thumbProps?: DivProps
}

// TODO - consider migrating to Radix UI ScrollArea: https://www.radix-ui.com/primitives/docs/components/scroll-area
export const Scrollbar = forwardRef<HTMLDivElement, ScrollbarProps>(
  ({ className, thumbProps, ...props }, ref): ReactNode => {
    const { className: thumbClassName, ...restThumbProps } = thumbProps || {}
    return (
      <div className={cn('p-1', className)} {...props}>
        <div
          className={cn('h-[8px] rounded bg-secondary-shade-40 opacity-80 hover:opacity-100', thumbClassName)}
          ref={ref}
          {...restThumbProps}
        />
      </div>
    )
  }
)

Scrollbar.displayName = 'Scrollbar'
