import { t } from '@lingui/macro'
import { Dialog } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { type SupportedSidepanelEntityFragment } from '~/components/Sidepanel/utils/sidepanelUtils'
import { Ownerships } from '~/features/Ownerships/Ownerships'

interface OwnershipsDialogProps {
  above25: boolean
  entity: SupportedSidepanelEntityFragment
  showOriginal: boolean
  toggleFullScreen: () => void
}

export const OwnershipsModal = ({
  above25,
  entity,
  showOriginal,
  toggleFullScreen
}: OwnershipsDialogProps): ReactNode => {
  const content = (
    <div className='flex justify-center p-4'>
      <Ownerships
        className='w-[90vw]'
        key={`percentage-${String(above25)}-full-screen`}
        showOriginal={showOriginal}
        editMode={false}
        fullScreenMode={true}
        above25={above25}
        entity={entity}
        noFlags={false}
        noSubsidiaryCount={false}
        noStatus={false}
        isExport={false}
        noLink
      />
    </div>
  )

  return (
    <Dialog
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={true}
      contentMaxWidth='calc(100vw - 128px)'
      title={t`Ownership (preview only)`}
      isOpen
      onRequestClose={toggleFullScreen}
      onClose={toggleFullScreen}
      body={content}
      headerProps={{ closeButtonProps: { className: 'text-text-primary' } }}
      contentProps={{ width: 'auto', minWidth: 800 }}
      modalContentProps={{ width: 'auto', className: 'overflow-auto' }}
    />
  )
}
