import { ReviewValueStatusKind } from '@strise/types'
import { Alert, type AlertVariant } from '@strise/ui-components'
import type { ReviewAlertFragment } from '~/graphqlTypes'

const reviewMetaStatusToAlertVariant: Record<ReviewValueStatusKind, AlertVariant> = {
  [ReviewValueStatusKind.Danger]: 'danger',
  [ReviewValueStatusKind.Warning]: 'warning',
  [ReviewValueStatusKind.Info]: 'info',
  [ReviewValueStatusKind.Ok]: 'neutral'
}

export const ReviewAlert = ({ alert }: { alert: ReviewAlertFragment }) => {
  return (
    <Alert variant={reviewMetaStatusToAlertVariant[alert.meta.status]} className='mt-2'>
      {alert.meta.reason}
    </Alert>
  )
}
