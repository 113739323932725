import type { ReactNode } from 'react'
import { RenderContext, type RenderContextType } from '~/contexts/RenderContext/RenderContext'

export const RenderContextProvider = ({
  children,
  type
}: {
  children: ReactNode
  type: RenderContextType
}): ReactNode => <RenderContext.Provider value={type}>{children}</RenderContext.Provider>
