import { t } from '@lingui/macro'
import { IconConnect, cn } from '@strise/ui-components'
import { Button, Tooltip } from '@strise/ui-components'
import { useScrollToEntityOwnershipChartNode } from '~/components/Sidepanel/SidepanelContext/useScrollToOwnershipChartNode'
import { type BaseEntityLikeFragment } from '~/graphqlTypes'

const ConnectOwnerLink = ({
  className,
  entity,
  rootEntityId = ''
}: {
  className?: string
  entity: BaseEntityLikeFragment
  rootEntityId?: string
}) => {
  const scrollToOwnershipChartNode = useScrollToEntityOwnershipChartNode(rootEntityId)

  return (
    <Tooltip content={t`This ownership has unresolved missing entity connections.`}>
      <Button
        endIcon={<IconConnect className='ml-1' size='sm' />}
        onClick={(event) => {
          scrollToOwnershipChartNode(entity.id, event.ctrlKey || event.metaKey)
        }}
        palette='primary'
        variant='ghost'
        className={cn(
          'hover:bg-transparent active:bg-transparent focus:bg-transparent text-sm whitespace-nowrap hover:underline',
          className
        )}
        data-track='Review / Owner Detail / Mergeable Entity / Open'
      >
        {t`Connect owner`}
      </Button>
    </Tooltip>
  )
}

export default ConnectOwnerLink
