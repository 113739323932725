import { useReactiveVar } from '@apollo/client/index.js'
import { Trans, t } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { filterNullishValues, replaceItemFromArray } from '@strise/ts-utils'
import { Button, type ComboboxItem, type ComboboxProps, cn } from '@strise/ui-components'
import { ConfirmDialog, type ConfirmDialogProps } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { FinancialSelect } from '~/features/Financials/FinancialSelect'
import { type FinancialId, type FinancialRowsMap } from '~/features/Financials/financialTypes'
import { useDefaultHighlightedFinancials } from '~/features/Financials/financials'
import { type CountryFragment } from '~/graphqlTypes'
import { financialFieldsMap } from '~/state'

interface SparkLineHighlightsEditProps extends Omit<DivProps, 'children'> {
  children?: (figure: FinancialId) => ReactNode
  country?: CountryFragment | null
  financialsRowsMap?: FinancialRowsMap
  ids: FinancialId[]
  onChange: (index: number, newValue: FinancialId) => void
  selectProps?: Omit<ComboboxProps<FinancialId>, 'options' | 'value'>
  selectWrapperProps?: DivProps
}

export const SparkLineHighlightsEdit = ({
  children,
  className,
  country,
  ids,
  onChange,
  selectProps,
  selectWrapperProps,
  ...props
}: SparkLineHighlightsEditProps): ReactNode => {
  const financialsFieldMapState = useReactiveVar(financialFieldsMap)
  const defaultHighlightedFinancials = useDefaultHighlightedFinancials()

  return (
    <div className={cn('grid gap-4', className)} {...props}>
      {ids.map((id, index) => {
        const financialId = defaultHighlightedFinancials[index]

        if (!financialId) return null

        const selectedFieldInfo = financialsFieldMapState[id] ?? financialsFieldMapState[financialId]

        if (!selectedFieldInfo) return null

        const matchingInternationalFieldInfo = selectedFieldInfo.matchingInternationalFields.find(
          (f) => f.countryV2?.kind === country?.kind
        )
        const fieldInfo = matchingInternationalFieldInfo ?? selectedFieldInfo

        const countries = filterNullishValues([
          fieldInfo.countryV2?.kind,
          ...selectedFieldInfo.matchingInternationalFields.map((f) => f.countryV2?.kind)
        ])

        const handleChange = (newFigure: Array<ComboboxItem<FinancialId>>): void => {
          if (!newFigure[0]) return
          if (newFigure.length !== 1) return
          onChange(index, newFigure[0].value)
        }

        return (
          <div
            key={`${id}-${index}`}
            {...selectWrapperProps}
            className={cn('flex flex-col gap-4', selectWrapperProps?.className)}
          >
            <FinancialSelect
              fieldInfo={fieldInfo}
              countries={countries}
              onChange={handleChange}
              data-id='financial-sparkline-select'
              {...selectProps}
            />

            {children?.(fieldInfo.id)}
          </div>
        )
      })}
    </div>
  )
}

export const SparkLineHighlightsEditModal = ({
  country,
  financialsRowsMap,
  highlightedFields,
  onConfirm,
  ...props
}: {
  country?: CountryFragment | null
  financialsRowsMap: FinancialRowsMap
  highlightedFields: FinancialId[]
  onConfirm: (value: FinancialId[]) => void
} & Pick<ConfirmDialogProps, 'onCancel'>) => {
  const defaultHighlightedFinancials = useDefaultHighlightedFinancials()

  const [selected, setSelected] = useState(highlightedFields)

  const handleChange = (index: number, financialId: FinancialId): void => {
    setSelected((currValue) => {
      return replaceItemFromArray(currValue, index, financialId)
    })
  }

  const handleConfirm = (): void => {
    onConfirm(selected)
  }

  const handleReset = (): void => {
    setSelected(defaultHighlightedFinancials)
  }

  return (
    <ConfirmDialog
      isOpen
      title={t`Customize favourites`}
      confirmText={t`Save`}
      cancelText={t`Cancel`}
      contentMaxWidth={800}
      onConfirm={handleConfirm}
      {...props}
    >
      <Trans>Use the menus beneath to set your favorite financial figures.</Trans>
      <SparkLineHighlightsEdit
        className='mt-4'
        ids={selected}
        country={country}
        onChange={handleChange}
        financialsRowsMap={financialsRowsMap}
      />

      <Button
        className='mt-4'
        variant='outlined'
        palette='danger'
        onClick={handleReset}
        data-track='Sidepanel / Financials / Reset to default'
      >
        <Trans>Reset to default</Trans>
      </Button>
    </ConfirmDialog>
  )
}
