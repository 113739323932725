import { Card, CardContent, CardHighlight, IconArrowDiagonalUp, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'

interface HomeCardActionsProps {
  icon: ReactNode
  onClick?: () => void
  title: string
  url?: string
}

export const HomeCardActions = ({ icon, onClick, title, url }: HomeCardActionsProps): ReactNode => {
  const action = url ? 'externalLink' : 'onClick'

  return (
    <Card
      action={action}
      variant='contained'
      highlight='right'
      highlightPalette='secondary'
      className='h-[3.4rem] w-full border-0 hover:border'
      onClick={onClick}
      to={url}
    >
      <CardContent className='size-full flex-row items-center justify-start gap-x-4 rounded-xl bg-white py-0'>
        {icon}
        <Typography variant='subtitle2'>{title}</Typography>
      </CardContent>

      <CardHighlight>
        <IconArrowDiagonalUp className='text-blue-50' />
      </CardHighlight>
    </Card>
  )
}
