import { cn } from '../..'
import { buttonVariants } from '../variants/buttonVariants'
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import { type ComponentPropsWithoutRef, type HTMLAttributes, type ReactNode, forwardRef } from 'react'

const AlertDialog = AlertDialogPrimitive.Root

const AlertDialogTrigger = AlertDialogPrimitive.Trigger

const AlertDialogPortal = AlertDialogPrimitive.Portal

export interface AlertDialogOverlayProps extends ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay> {
  className?: string
}

const AlertDialogOverlay = forwardRef<React.ElementRef<typeof AlertDialogPrimitive.Overlay>, AlertDialogOverlayProps>(
  ({ className, ...props }, ref): ReactNode => (
    <AlertDialogPrimitive.Overlay
      className={cn(
        'fixed inset-0 z-50 bg-gray-50/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
        className
      )}
      {...props}
      ref={ref}
    />
  )
)
AlertDialogOverlay.displayName = 'AlertDialogOverlay'

export interface AlertDialogContentProps extends ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content> {
  className?: string
}

const AlertDialogContent = forwardRef<React.ElementRef<typeof AlertDialogPrimitive.Content>, AlertDialogContentProps>(
  ({ className, ...props }, ref): ReactNode => (
    <AlertDialogPortal>
      <AlertDialogOverlay />
      <AlertDialogPrimitive.Content
        ref={ref}
        className={cn(
          'fixed left-1/2 top-1/2 z-50 grid max-h-[80vh] max-w-lg overflow-auto -translate-x-1/2 -translate-y-1/2 gap-4 border bg-background-paper p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
          className
        )}
        // To prevent closing sidepanel etc. when escape key is pressed in dialog
        onEscapeKeyDown={(event) => event.stopPropagation()}
        {...props}
      />
    </AlertDialogPortal>
  )
)
AlertDialogContent.displayName = 'AlertDialogContent'

export interface AlertDialogHeaderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

const AlertDialogHeader = ({ className, ...props }: AlertDialogHeaderProps): JSX.Element => (
  <div className={cn('flex flex-col space-y-2 text-center sm:text-left', className)} {...props} />
)
AlertDialogHeader.displayName = 'AlertDialogHeader'

export interface AlertDialogFooterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

const AlertDialogFooter = ({ className, ...props }: AlertDialogFooterProps): JSX.Element => (
  <div className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} {...props} />
)
AlertDialogFooter.displayName = 'AlertDialogFooter'

export interface AlertDialogTitleProps extends ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Title> {
  className?: string
}

const AlertDialogTitle = forwardRef<React.ElementRef<typeof AlertDialogPrimitive.Title>, AlertDialogTitleProps>(
  ({ className, ...props }, ref): ReactNode => (
    <AlertDialogPrimitive.Title ref={ref} className={cn('text-lg', className)} {...props} />
  )
)
AlertDialogTitle.displayName = 'AlertDialogTitle'

export interface AlertDialogDescriptionProps extends ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Description> {
  className?: string
}

const AlertDialogDescription = forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Description>,
  AlertDialogDescriptionProps
>(
  ({ className, ...props }, ref): ReactNode => (
    <AlertDialogPrimitive.Description ref={ref} className={cn('text-sm', className)} {...props} />
  )
)
AlertDialogDescription.displayName = 'AlertDialogDescription'

export interface AlertDialogActionProps extends ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Action> {
  className?: string
}

const AlertDialogAction = forwardRef<React.ElementRef<typeof AlertDialogPrimitive.Action>, AlertDialogActionProps>(
  ({ className, ...props }, ref): ReactNode => (
    <AlertDialogPrimitive.Action
      ref={ref}
      className={cn(buttonVariants({ variant: 'contained', palette: 'secondary' }), className)}
      {...props}
    />
  )
)
AlertDialogAction.displayName = 'AlertDialogAction'

export interface AlertDialogCancelProps extends ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel> {
  className?: string
}

const AlertDialogCancel = forwardRef<React.ElementRef<typeof AlertDialogPrimitive.Cancel>, AlertDialogCancelProps>(
  ({ className, ...props }, ref): ReactNode => (
    <AlertDialogPrimitive.Cancel
      ref={ref}
      className={cn(buttonVariants({ variant: 'outlined' }), 'mt-2 sm:mt-0', className)}
      {...props}
    />
  )
)
AlertDialogCancel.displayName = 'AlertDialogCancel'

export {
  AlertDialog,
  AlertDialogPortal,
  AlertDialogOverlay,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCancel
}
