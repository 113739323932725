import { i18n } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { useContext } from '@strise/react-utils'
import { Duration } from '@strise/types'
import { Divider } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { forwardRef, memo, useState } from 'react'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { EventsFeed } from '~/features/Events/EventsFeed'
import { EventsTimespan } from '~/features/Events/EventsTimespan'
import { EventsFilter } from '~/features/Events/SidepanelEventsCardFilter'
import { useIsMobile } from '~/utils/hooks'
import { SidepanelTab } from '~/utils/urls'

const EventsCardContent = memo(({ id }: { id: string }) => {
  const isSmallScreen = useIsMobile()

  const [showAllState, setShowAllState] = useState(false)
  const [durationState, setDurationState] = useState(Duration.Year)

  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)

  return (
    <>
      <EventsFilter settingsState={settings} saveSettings={saveSettings} />
      <div className='bg-background-default px-4 pb-4'>
        <EventsFeed
          className='max-w-[780px]'
          id={id}
          settings={settings}
          duration={durationState}
          showAll={showAllState}
          setShowAll={setShowAllState}
          cacheKey='sidepanel-events'
          headerProps={{
            className: '-mx-4'
          }}
          headerChildren={
            <EventsTimespan duration={durationState} setDuration={setDurationState} className='ml-auto' />
          }
          isSmallScreen={isSmallScreen}
        />
      </div>
      <Divider />
    </>
  )
})

export const EventsCard = forwardRef<HTMLDivElement>((_props, ref): ReactNode => {
  const { id } = useContext(SidepanelContext)
  const title = defineMessage({ message: 'Events' })

  return (
    <SidepanelCard title={i18n._(title)} ref={ref} tab={SidepanelTab.Events}>
      <EventsCardContent id={id} />
    </SidepanelCard>
  )
})
