import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconBrokenHeart = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M11.046 2.6c.384.284.72.567.989.81.268-.243.602-.525.985-.808C14.01 1.868 15.492 1 17.085 1c3.108 0 5.946 2.583 5.946 6.378 0 3.472-1.552 5.52-3.112 7.58v.002l-.222.292c-.79 1.068-2.553 2.952-4.067 4.522a217.913 217.913 0 0 1-2.652 2.7l-.174.174-.062.061-.709.704-.706-.707.709-.706-.71.706-.06-.061-.174-.174a226.858 226.858 0 0 1-2.637-2.7c-1.514-1.573-3.275-3.456-4.08-4.517l-.004-.004c-.1-.135-.202-.269-.304-.403-1.52-2.008-3.066-4.049-3.066-7.47C1.001 3.574 3.858 1 6.962 1c1.601 0 3.089.866 4.084 1.6ZM3.004 7.379C3.004 4.648 4.993 3 6.962 3c.936 0 1.985.539 2.893 1.209.285.21.54.421.753.61l-1.683 1.73 4.7 5.063-3.61 4.715 2.025 4.253-.002.001-.146-.148c-.522-.53-1.234-1.257-1.993-2.046-1.535-1.597-3.205-3.389-3.928-4.34l-.253-.337c-1.547-2.05-2.714-3.597-2.714-6.332ZM13.5 19.095c.222-.228.453-.465.688-.709 1.542-1.599 3.206-3.385 3.9-4.325l.007-.008.16-.213c1.591-2.106 2.773-3.67 2.773-6.462C21.028 4.648 19.05 3 17.085 3c-.925 0-1.967.537-2.872 1.208a11.357 11.357 0 0 0-1.415 1.25l-.016.018-.002.002-.743.825-.004-.003-.335.372 4.51 4.846-3.933 5.14 1.225 2.437Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconBrokenHeart.displayName = 'IconBrokenHeart'
