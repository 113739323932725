import { Trans } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { Divider, Typography, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { TransformedTableItem } from '~/components/TransformedTable/TransformedTableItem'
import { TruncatedList } from '~/components/TruncatedList/TruncatedList'
import { type TransformedApiTableSection } from '~/utils/apiTable/apiTableUtils'

export const TransformedTableSection = ({
  className,
  hideDivider,
  itemProps,
  paginationProps,
  rowKey,
  section,
  ...props
}: {
  hideDivider: boolean
  itemProps?: DivProps
  paginationProps?: DivProps
  rowKey: string
  section: TransformedApiTableSection
} & DivProps): ReactNode => {
  const filteredItems = section.items.filter((item) => !!item.content)

  if (!filteredItems.length) {
    return (
      <Typography className='px-1 text-text-secondary'>
        <Trans>No data</Trans>
      </Typography>
    )
  }

  return (
    <>
      <div className={cn(section.inlineLabel ? 'flex' : undefined, className)} {...props}>
        {section.label && <div className={section.inlineLabel ? 'w-1/2 shrink-0 py-2' : 'pt-2'}>{section.label}</div>}
        <TruncatedList
          className={className}
          items={filteredItems}
          truncateAfter={section.paginationThreshold}
          context={rowKey}
          paginationProps={{ ...paginationProps, className: 'pl-2' }}
          {...props}
        >
          {(item, index, isLast) => {
            return <TransformedTableItem key={index} item={item} isLast={isLast} {...itemProps} />
          }}
        </TruncatedList>
      </div>
      {!hideDivider && <Divider />}
    </>
  )
}
