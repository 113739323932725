import { useCallback } from 'react'
import * as XLSX from 'xlsx'

const MAX_FILE_LENGTH = 24
export const useDownloadXlsx = (): ((data: object[], fileNamePrefix?: string) => void) => {
  return useCallback((data: object[], fileNamePrefix?: string) => {
    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(data)
    const formattedFileNamePrefix = fileNamePrefix?.replace(/\s/g, '-').substring(0, MAX_FILE_LENGTH)
    const [firstKey] = Object.keys(data)
    const firstKeyString = firstKey?.substring(0, MAX_FILE_LENGTH)
    const filename = `${formattedFileNamePrefix ?? firstKeyString ?? 'strise'}.xlsx`

    XLSX.utils.book_append_sheet(workBook, workSheet, filename)
    XLSX.writeFile(workBook, filename)
  }, [])
}
