import { Trans } from '@lingui/macro'
import { Select, SelectItem, type SelectProps } from '@strise/ui-components'
import type { ReactNode } from 'react'

const generateYearOptions = (): number[] => {
  const currentYear = new Date().getFullYear()
  return Array.from({ length: currentYear - 1900 + 1 }, (_, index) => currentYear - index)
}

export interface YearSelectProps extends Omit<SelectProps, 'options'> {
  includeEmpty?: boolean
}

export const YearSelect = ({ includeEmpty = true, ...props }: YearSelectProps): ReactNode => {
  const years = generateYearOptions()

  return (
    <Select {...props} placeholder={<Trans>Select year</Trans>}>
      {includeEmpty && (
        <SelectItem value='none'>
          <Trans>Select year</Trans>
        </SelectItem>
      )}
      {years.map((year) => (
        <SelectItem key={year} value={year.toString()}>
          {year}
        </SelectItem>
      ))}
    </Select>
  )
}
