import { type ReactNode } from 'react'
import { ReviewItem } from '~/features/Review/ReviewItem'
import { reviewEntityToEntity, reviewRoleMetaToRoleMeta } from '~/features/Review/reviewValueTransformers'
import { type ReviewDetailedRoleValueFragment } from '~/graphqlTypes'
import { RoleWithDetailedEntity } from '~/utils/apiTable/RoleWithDetailedEntity'

export const ReviewDetailedRole = ({ item }: { item: ReviewDetailedRoleValueFragment }): ReactNode => {
  if (!item.value) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const entity = reviewEntityToEntity(item.value.entity)

  const otherRoles = item.value.otherRoles.map((otherRole) => ({
    company: reviewEntityToEntity(otherRole.company),
    roles: otherRole.roles.map((role) => reviewRoleMetaToRoleMeta(role))
  }))

  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <RoleWithDetailedEntity entity={entity} otherRoles={otherRoles} otherIndustries={item.value.otherIndustries} />
    </ReviewItem>
  )
}
