import { AppSharedContext, type AppSharedContextProps } from './AppSharedContext'
import { displayLanguage } from './state'
import type { ReactNode } from 'react'
import { useEffect } from 'react'

export const AppSharedContextProvider = ({
  children,
  value
}: {
  children: ReactNode
  value: AppSharedContextProps
}): ReactNode => {
  useEffect(() => {
    displayLanguage(value.language)
  }, [value.language])

  return <AppSharedContext.Provider value={value}>{children}</AppSharedContext.Provider>
}
