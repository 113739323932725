import { t } from '@lingui/macro'
import { IconButton, IconLink } from '@strise/ui-components'
import { copyString } from '~/utils/hooks'

export const SidepanelCopyEntityLink = ({ entityLink }: { entityLink: string }) => {
  return (
    <IconButton
      className='p-2'
      variant='ghost'
      onClick={copyString(entityLink)}
      disabled={!entityLink}
      palette='tertiary'
      aria-label={t`Copy company link to clipboard`}
      data-track='Sidepanel / Copy company link to clipboard'
    >
      <IconLink />
    </IconButton>
  )
}
