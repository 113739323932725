import { t } from '@lingui/macro'
import { type PortfolioHealthRiskDistribution } from '@strise/types'
import { Card, CardContent, PieChartDonut, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'

interface PortfolioHealthRiskAssessmentSectionProps {
  riskDistribution: PortfolioHealthRiskDistribution
}

const DEFAULT_COLORS = {
  BLACK: 'var(--chart-black)',
  BLUE: 'var(--chart-blue)',
  GREEN: 'var(--chart-green)',
  RED: 'var(--chart-red)',
  YELLOW: 'var(--chart-yellow)',
  PINK: 'var(--chart-pink)',
  ORANGE: 'var(--chart-orange)',
  GRAY: 'var(--chart-gray)'
} as const

/**
 * Portfolio Health Risk Assessment Section Component
 *
 * Displays a donut chart showing the distribution of risk levels in the portfolio
 * with their respective percentages and counts.
 */
export const PortfolioHealthRiskAssessmentSection = ({
  riskDistribution
}: PortfolioHealthRiskAssessmentSectionProps): ReactNode => {
  // Transform risk distribution data for chart consumption
  const riskDistributionData = riskDistribution.segments.map((segment) => ({
    risk: segment.name.toLowerCase(),
    total: segment.count
  }))

  // Chart configuration with risk levels and their visual representation
  const riskConfig = {
    total: {
      label: t`Total`
    },
    ...Object.fromEntries(
      riskDistribution.segments.map((segment) => [
        segment.name.toLowerCase(),
        {
          label: `${segment.name} (${segment.percentage}%)`,
          color: segment.name === 'Unknown' ? DEFAULT_COLORS.GRAY : DEFAULT_COLORS[segment.color]
        }
      ])
    )
  }

  return (
    <Card size='sm' className='p-0 hover:border-gray-10 active:border-gray-10' palette='white'>
      <CardContent className='px-4 py-6'>
        <Typography variant='subtitle2' className='pb-4'>{t`Portfolio risk assessment`}</Typography>
        <PieChartDonut
          config={riskConfig}
          data={riskDistributionData}
          dataKey='total'
          nameKey='risk'
          className='h-52 xl:h-[12.5rem]'
          innerRadius={30}
          outerRadius={55}
          showLegend
        />
      </CardContent>
    </Card>
  )
}
