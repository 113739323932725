import { t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { PrivatePersonReviewRowKind } from '@strise/types'
import { type ReactNode } from 'react'
import { ReviewEvents } from '~/features/Review/items/ReviewEvents'
import { ReviewPepsWithDisposition } from '~/features/Review/items/ReviewPepsWithDisposition'
import { ReviewSanctions } from '~/features/Review/items/ReviewSanctions'
import { type ReviewState } from '~/features/Review/reviewUtils'
import { type ReviewPrivatePersonDataFragment } from '~/graphqlTypes'

export interface PrivatePersonReviewRowType {
  children: ReactNode
  description?: string | null
  key: string
  kind: PrivatePersonReviewRowKind
  label: string
}

interface PrivatePersonReviewSection {
  rows: PrivatePersonReviewRowType[]
  title: string
}

export const extractReviewSections = ({
  reviewPrivatePersonData,
  reviewState,
  setReviewState
}: {
  reviewPrivatePersonData: ReviewPrivatePersonDataFragment | null | undefined
  reviewState: ReviewState
  setReviewState: SetStateFn<ReviewState>
}): PrivatePersonReviewSection[] => {
  return [
    {
      title: t`PEPs and Sanctions`,
      rows: [
        {
          key: PrivatePersonReviewRowKind.Peps,
          kind: PrivatePersonReviewRowKind.Peps,
          label: t`PEPs`,
          children: reviewPrivatePersonData?.peps && <ReviewPepsWithDisposition items={reviewPrivatePersonData.peps} />
        },
        {
          key: PrivatePersonReviewRowKind.Sanctions,
          kind: PrivatePersonReviewRowKind.Sanctions,
          label: t`Sanctions`,
          children: reviewPrivatePersonData?.sanctions && (
            <ReviewSanctions items={[reviewPrivatePersonData.sanctions]} />
          )
        }
      ]
    },
    {
      title: t`Adverse Media Screening`,
      rows: [
        {
          key: PrivatePersonReviewRowKind.AdverseMediaScreening,
          kind: PrivatePersonReviewRowKind.AdverseMediaScreening,
          label: t`AMS`,
          children: reviewPrivatePersonData?.ams && (
            <ReviewEvents
              entityId={reviewPrivatePersonData.id}
              items={reviewPrivatePersonData.ams}
              reviewState={reviewState}
              setReviewState={setReviewState}
            />
          )
        }
      ]
    }
  ]
}
