import * as Sentry from '@sentry/react'
import { useTokenMeta } from '@strise/app-shared'
import type { ReactNode } from 'react'

export const IdentifyErrorService = ({ children }: { children: ReactNode }): ReactNode => {
  const token = useTokenMeta()
  const userId = token?.sub
  if (userId) {
    Sentry.setUser({ id: userId })
  }

  return <>{children}</>
}
