import { SUBSIDIARY_HANDLE_OFFSET } from './sizes'
import { BaseEdge, type Edge, EdgeLabelRenderer, type EdgeProps, getStraightPath } from '@xyflow/react'
import type { ReactNode } from 'react'

export type SubsidiaryEdgeType = Edge

type SubsidiaryEdgeProps = EdgeProps<SubsidiaryEdgeType>

export const SubsidiaryEdge = ({
  id,
  label,
  sourceHandleId,
  sourceX,
  sourceY,
  targetX,
  targetY
}: SubsidiaryEdgeProps): ReactNode => {
  const isSourceRoot = sourceHandleId === 'rootNodeBottomHandle'
  // Edge from the subsidiary node to the expand node
  const isSourceSubsidiaryParent = sourceHandleId === 'subsidiaryParentHandle'
  const isSourceExpand = sourceHandleId === 'expandNodeBottomHandle'

  const [edgePath, labelX] = getStraightPath({
    // For subsidiary edges, position the edge in the middle of the handle
    // If the source is either a root node, a subsidiary parent or an expand node the edge should be centered in the middle of the handle (default)
    sourceX: isSourceRoot || isSourceSubsidiaryParent || isSourceExpand ? sourceX : sourceX + SUBSIDIARY_HANDLE_OFFSET,
    // If the source is a subsidiary parent, this means the target will be an expand node and the edge should be centered in the middle of the handle
    targetX: isSourceSubsidiaryParent ? targetX : targetX + SUBSIDIARY_HANDLE_OFFSET,
    sourceY,
    targetY
  })

  // Offset for the height of the label
  const labelVerticalOffset = targetY - 5
  // Label container width + 8px padding
  const labelHorizontalOffset = 30 + 8

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <span
          style={{
            transform: `translate(${labelX - labelHorizontalOffset}px, ${labelVerticalOffset}px)`
          }}
          className='absolute z-max w-[30px] bg-white text-right text-[10px] leading-none text-secondary-shade-70'
        >
          {label}
        </span>
      </EdgeLabelRenderer>
    </>
  )
}
