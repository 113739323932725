import { Typography, cn } from '@strise/ui-components'
import { type ReactNode } from 'react'

const OwnershipLevel = ({
  level,
  variant = 'outlined'
}: {
  level: number
  variant?: 'contained' | 'outlined'
}): ReactNode => {
  if (level === 0) {
    return null
  }

  return (
    <Typography
      variant='aLabel'
      className={cn(
        'text-sm rounded-sm px-1 ml-1 h-4 leading-4',
        variant === 'contained' && 'bg-secondary-shade-10',
        variant === 'outlined' && 'border border-blue-10 text-text-secondary'
      )}
    >
      {level}
    </Typography>
  )
}

export default OwnershipLevel
