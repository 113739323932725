import { AdverseFlag } from '../AdverseFlag'
import { flagSeverityToChipTitle, flagSeverityToClasses } from './flags'
import { i18n } from '@lingui/core'
import { type FlagSeverity } from '@strise/types'
import { cn } from '@strise/ui-components'
import type { ReactNode } from 'react'

export const flagChipProps = (severity: FlagSeverity): { className: string; label: string; startIcon: ReactNode } => ({
  className: cn(flagSeverityToClasses[severity], 'mr-2'),
  label: i18n._(flagSeverityToChipTitle[severity]),
  startIcon: <AdverseFlag severity={severity} />
})
