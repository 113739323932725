import { CompanyStatus } from '@strise/types'
import { IconDotSmall, type IconProps, cn } from '@strise/ui-components'
import type { RefAttributes } from 'react'

const companyStatusColors: Record<CompanyStatus, string | null> = {
  [CompanyStatus.Ignored]: null,
  [CompanyStatus.Account]: 'text-semantic-success-main',
  [CompanyStatus.Prospect]: 'text-accent-pink-main',
  [CompanyStatus.Following]: 'text-semantic-info-main',
  [CompanyStatus.InQualification]: 'text-semantic-warning-main',
  [CompanyStatus.Qualified]: 'text-semantic-notice-main',
  [CompanyStatus.Disqualified]: 'text-semantic-danger-main'
}

export const CompanyStatusDot = ({
  className,
  status,
  ...props
}: {
  status: CompanyStatus
} & IconProps &
  RefAttributes<SVGSVGElement>) => {
  const color = companyStatusColors[status]

  if (!color) return null

  return <IconDotSmall className={cn('shrink-0', color, className)} {...props} />
}
