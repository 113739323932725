import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconToday = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M2.434 6.567 6.599 2.4 5.185.987 1.019 5.153l1.415 1.414ZM18.835 1 23 5.166 21.586 6.58l-4.165-4.166L18.835 1Z'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 23c5.523 0 10-4.477 10-10S17.523 3 12 3 2 7.477 2 13s4.477 10 10 10Zm-1-10V7h2v5.585l3.244 3.243-1.415 1.415-3.535-3.536A.998.998 0 0 1 11 13Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconToday.displayName = 'IconToday'
