import { cn } from '../../../utils/className'
import { type IconProps, iconVariants } from '../../../utils/iconUtils'
import { type ReactNode, forwardRef } from 'react'

export const IconExpandAnimatedA = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <rect x='3' y='2' width='18' height='20' stroke='currentColor' strokeWidth='2' />
      <path d='M10 16L6 12L10 8' stroke='currentColor' strokeWidth='2' />
      <path d='M14 8L18 12L14 16' stroke='currentColor' strokeWidth='2' />
      <path d='M18 12L6 12' stroke='currentColor' strokeWidth='2' />
    </svg>
  )
)
IconExpandAnimatedA.displayName = 'IconExpandAnimatedA'
