import { Trans } from '@lingui/macro'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import { Checkbox, Divider, Skeleton, Typography, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { type ReviewCheckedMap, extractTextColorClass } from '~/features/Review/reviewUtils'

export const ReviewSelectAllCheckboxSection = ({
  checkedCount,
  className,
  disabled,
  errors,
  id,
  idSuffix,
  loading,
  rowKeys,
  setCheckedMap,
  ...props
}: {
  checkedCount: number
  disabled: boolean
  errors: boolean
  id: string
  idSuffix: string
  loading: boolean
  rowKeys: string[]
  setCheckedMap: SetStateFn<ReviewCheckedMap>
} & DivProps): ReactNode => {
  const totalRowKeys = rowKeys.length

  const isChecked = !!checkedCount
  const allKey = `all-${id}-${idSuffix}`

  const handleSelectAll = (): void => {
    setCheckedMap(Object.fromEntries(rowKeys.map((rowKey) => [rowKey, true])))
  }

  const handleCheckboxChange = (): void => {
    setCheckedMap(() => {
      if (isChecked) {
        return {}
      }

      return Object.fromEntries(rowKeys.map((rowKey) => [rowKey, true]))
    })
  }

  const hasError = Boolean(errors && totalRowKeys !== checkedCount)
  const colorClass = extractTextColorClass(hasError, !!checkedCount)

  return (
    <>
      <div className={cn('shrink-0 py-2 pr-1', { 'min-h-[64px]': errors }, className)} {...props}>
        <div className='flex items-center'>
          <Checkbox
            id={allKey}
            labelProps={{ className: colorClass }}
            name={allKey}
            checked={isChecked && checkedCount < totalRowKeys ? 'indeterminate' : isChecked}
            label={loading ? <Skeleton className='h-[15px] w-[21px]' /> : `${checkedCount}/${totalRowKeys}`}
            onCheckedChange={handleCheckboxChange}
            disabled={disabled || loading}
            data-track='Filter / Review / All'
          />
          {hasError && (
            <Typography className='cursor-pointer' onClick={handleSelectAll}>
              <Typography className='ml-1' component='span'>
                -{' '}
              </Typography>{' '}
              <Typography className='text-text-link' component='span'>
                <Trans>Check all</Trans>
              </Typography>
            </Typography>
          )}
        </div>
        {hasError && (
          <Typography className={cn('mt-1', colorClass)}>
            <Trans>Check all boxes before proceeding</Trans>
          </Typography>
        )}
      </div>
      {errors && <Divider />}
    </>
  )
}
