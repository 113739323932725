import { getBrowserGlobals } from '@strise/react-utils'
import { useContext } from 'react'
import { useNavigateEntity } from '~/components/EntityLink/entityLinkUtils'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { TestIDs } from '~/utils/testIDs'
import { SidepanelTab } from '~/utils/urls'

/**
 * Scrolls to the ownership chart and sets the focus node to the given owner node. Note - this will only work within the sidepanel context!
 * @see useScrollToEntityOwnershipChartNode - For usage outside sidepanel context
 *
 * @param ownerNodeId - The ID of the owner node to scroll to.
 */
export const useTryScrollToOwnershipChartNode = (): ((ownerNodeId: string | null) => void) | undefined => {
  const sidepanelContext = useContext(SidepanelContext)
  const setChartFocusNodeId = sidepanelContext?.setChartFocusNodeId

  if (!setChartFocusNodeId) {
    return
  }

  return (ownerNodeId: string | null) => {
    const ownershipChart = getBrowserGlobals()?.document.querySelector(
      `[data-id="${TestIDs.SidePanel.Ownerships.root}"]`
    )
    if (ownershipChart) {
      ownershipChart.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => {
        setChartFocusNodeId(ownerNodeId)
      }, 500)
    }
  }
}

/**
 * Scrolls to the ownership chart and sets the focus node to the given owner node. Uses the Sidepanel context if
 * available, otherwise navigates to the entity first.
 *
 * @param entityId - The ID of the entity to scroll to.
 */
export const useScrollToEntityOwnershipChartNode = (
  entityId: string
): ((ownerNodeId: string | null, openInNewTab?: boolean) => void) => {
  const scrollFn = useTryScrollToOwnershipChartNode()
  const navigateEntity = useNavigateEntity(SidepanelTab.Ownership)

  return (ownerNodeId: string | null, openInNewTab?: boolean) =>
    scrollFn ? scrollFn(ownerNodeId) : navigateEntity(entityId, openInNewTab, ownerNodeId ?? undefined)
}
