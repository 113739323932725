import { t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import { type SetStateFn, copyTextToClipboard, useDependencyState } from '@strise/react-utils'
import { IdentityVerificationStatus } from '@strise/types'
import { Button, Tooltip, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { IDVCheckModalDecided } from '~/features/IDVCheck/IDVCheckModalDecided'
import { IDVCheckModalPendingReview } from '~/features/IDVCheck/IDVCheckModalPendingReview'
import { IDVCheckModalSendRequest } from '~/features/IDVCheck/IDVCheckModalSendRequest'
import { IDVCheckTooltipPending } from '~/features/IDVCheck/IDVCheckTooltipPending'
import { IdvCheckModalPendingDocument } from '~/features/IDVCheck/IdvCheckModalPendingDocument'
import { updateIdvRolesCache, updateReviewIdvRolesCache } from '~/features/IDVCheck/idvCacheUtils'
import { useRequestIdvVerificationMutation, useUpdateIdvStatusMutation } from '~/graphqlOperations'
import { type IdvRoleFragment, type RequestIdvVerificationMutation, type UpdateIdvStatusMutation } from '~/graphqlTypes'

interface IDVCheckItemProps {
  companyId: string
  refetchIdvRoles: () => void
  role: IdvRoleFragment
}

interface UseRequestIdvVerification {
  handleRequest: () => Promise<void>
  requestLoading: boolean
}

const useRequestIdvVerification = ({
  companyId,
  onRequestSuccess,
  role,
  setStatusState
}: {
  companyId: string
  onRequestSuccess: () => void
  role: IdvRoleFragment
  setStatusState: SetStateFn<IdentityVerificationStatus>
}): UseRequestIdvVerification => {
  const handleRequestCompleted = (data: RequestIdvVerificationMutation) => {
    if (!data.requestIdentityVerification) {
      toast.error(t`Failed to request ID verification`)
      setStatusState(IdentityVerificationStatus.Failed)
      return
    }
    onRequestSuccess()
  }

  const [request, { loading: requestLoading }] = useRequestIdvVerificationMutation({
    onCompleted: handleRequestCompleted,
    update: (cache, { data }) => {
      if (!data?.requestIdentityVerification) return
      updateIdvRolesCache({ cache, person: role.person, companyId, idvRequest: data.requestIdentityVerification })
      updateReviewIdvRolesCache({
        cache,
        companyId,
        idvRoles: [{ ...role, idvRequest: data.requestIdentityVerification }]
      })
    }
  })

  const handleRequest = async (): Promise<void> => {
    await request({
      variables: { person: role.person.id, company: companyId }
    })
  }

  return { handleRequest, requestLoading }
}

interface UseUpdateIdvStatus {
  handleUpdateStatus: (status: IdentityVerificationStatus) => Promise<void>
  loadingStatus: IdentityVerificationStatus | null
}

const useUpdateIdvStatus = ({
  companyId,
  role,
  setStatusState
}: {
  companyId: string
  role: IdvRoleFragment
  setStatusState: SetStateFn<IdentityVerificationStatus>
}): UseUpdateIdvStatus => {
  const [loadingStatus, setLoadingStatus] = useState<IdentityVerificationStatus | null>(null)

  const handleUpdateCompleted = (data: UpdateIdvStatusMutation) => {
    setLoadingStatus(null)

    if (!data.updateIdentityVerificationStatus) {
      toast.error(t`Failed to update ID verification status`)
      setStatusState(IdentityVerificationStatus.Failed)
      return
    }
  }

  const handleError = () => {
    setLoadingStatus(null)
  }

  const [update] = useUpdateIdvStatusMutation({
    onCompleted: handleUpdateCompleted,
    onError: handleError,
    update: (cache, { data }) => {
      if (!data?.updateIdentityVerificationStatus) return
      updateIdvRolesCache({
        cache,
        person: role.person,
        companyId,
        idvRequest: data.updateIdentityVerificationStatus
      })
      updateReviewIdvRolesCache({
        cache,
        companyId,
        idvRoles: [{ ...role, idvRequest: data.updateIdentityVerificationStatus }]
      })
    }
  })

  const handleUpdateStatus = async (status: IdentityVerificationStatus): Promise<void> => {
    setLoadingStatus(status)

    await update({
      variables: { person: role.person.id, company: companyId, status },
      optimisticResponse: role.idvRequest
        ? {
            __typename: 'Mutation',
            updateIdentityVerificationStatus: {
              ...role.idvRequest,
              status
            }
          }
        : undefined
    })
  }

  return { handleUpdateStatus, loadingStatus }
}

export const IDVCheckItem = ({ companyId, refetchIdvRoles, role }: IDVCheckItemProps): ReactNode => {
  const [statusState, setStatusState] = useDependencyState(
    role.idvRequest?.status ?? IdentityVerificationStatus.NoStatus,
    [role.idvRequest?.status]
  )
  const [showSendRequestModal, setShowSendRequestModal] = useState(false)

  const { handleRequest, requestLoading } = useRequestIdvVerification({
    companyId,
    role,
    setStatusState,
    onRequestSuccess: () => {
      // Automatically open the modal when the request is successful
      // We need to handle this as a state outside of the modal to avoid rendering 2 modals if sidepanel and review are open
      setShowSendRequestModal(true)
    }
  })
  const { handleUpdateStatus, loadingStatus } = useUpdateIdvStatus({ companyId, role, setStatusState })

  const url = role.idvRequest?.url ?? null

  const onUrlCopy = () => {
    if (!url) {
      toast.error(t`No URL to copy`)
      setStatusState(IdentityVerificationStatus.Failed)
      return
    }

    copyTextToClipboard(url)
    toast.success(t`Copied to clipboard`)
  }

  const renderButtons = () => {
    const buttonMap = {
      [IdentityVerificationStatus.NoStatus]: (
        <div className='flex items-center justify-end gap-x-2'>
          <Button
            onClick={async () => await handleUpdateStatus(IdentityVerificationStatus.NotRequired)}
            data-track='IDV Check / Not needed'
            variant='outlined'
            loading={loadingStatus === IdentityVerificationStatus.NotRequired}
            size='xs'
            className={cn('border-[#403dff] text-[#403dff] hover:bg-[#403dff69]')}
          >
            {t`Not needed`}
          </Button>
          <Button
            onClick={handleRequest}
            loading={requestLoading}
            data-track='IDV Check / Request ID'
            variant='contained'
            palette='primary'
            size='xs'
          >
            {t`Request ID`}
          </Button>
        </div>
      ),
      [IdentityVerificationStatus.IdvRequestCreated]: (
        <IDVCheckModalSendRequest
          name={role.person.name ?? t`Unknown`}
          onUpdateStatus={handleUpdateStatus}
          statusLoading={loadingStatus}
          url={url}
          onUrlCopy={onUrlCopy}
          open={showSendRequestModal}
          setOpen={setShowSendRequestModal}
        >
          <Button
            loading={requestLoading}
            data-track='IDV Check / Confirm'
            variant='contained'
            palette='primary'
            size='xs'
          >
            {t`Confirm`}
          </Button>
        </IDVCheckModalSendRequest>
      ),

      [IdentityVerificationStatus.PendingDocument]: (
        <IdvCheckModalPendingDocument
          role={role}
          loadingStatus={loadingStatus}
          onUpdateStatus={handleUpdateStatus}
          url={url}
          onUrlCopy={onUrlCopy}
        >
          <Tooltip content={<IDVCheckTooltipPending role={role} />}>
            <div>
              <Button
                data-track='IDV Check / Pending...'
                variant='contained'
                palette='tertiary'
                size='xs'
              >{t`Pending...`}</Button>
            </div>
          </Tooltip>
        </IdvCheckModalPendingDocument>
      ),
      [IdentityVerificationStatus.PendingReview]: (
        <IDVCheckModalPendingReview role={role} loadingStatus={loadingStatus} onUpdateStatus={handleUpdateStatus}>
          <Button data-track='IDV Check / Verify' variant='contained' palette='primary' size='xs'>
            {t`Verify`}
          </Button>
        </IDVCheckModalPendingReview>
      ),
      [IdentityVerificationStatus.Approved]: (
        <IDVCheckModalDecided
          role={role}
          status={IdentityVerificationStatus.Approved}
          onUpdateStatus={handleUpdateStatus}
          loadingStatus={loadingStatus}
        >
          <Button
            data-track='IDV Check / Approved'
            variant='contained'
            size='xs'
            className={cn(
              'bg-semantic-success-shade-10 text-semantic-success-shade-100 hover:bg-semantic-success-shade-20'
            )}
          >
            {t`Approved`}
          </Button>
        </IDVCheckModalDecided>
      ),
      [IdentityVerificationStatus.Declined]: (
        <IDVCheckModalDecided
          role={role}
          status={IdentityVerificationStatus.Declined}
          loadingStatus={loadingStatus}
          onUpdateStatus={handleUpdateStatus}
        >
          <Button
            data-track='IDV Check / Declined'
            variant='contained'
            size='xs'
            className={cn('bg-semantic-danger-shade-5 text-semantic-danger-shade-50 hover:bg-semantic-danger-shade-10')}
          >
            {t`Declined`}
          </Button>
        </IDVCheckModalDecided>
      ),
      [IdentityVerificationStatus.NotRequired]: (
        <Button
          data-track='IDV Check / Not needed / Cancel'
          onClick={async () => await handleUpdateStatus(IdentityVerificationStatus.NoStatus)}
          variant='contained'
          loading={loadingStatus === IdentityVerificationStatus.NoStatus}
          size='xs'
        >
          {t`Not needed`}
        </Button>
      ),
      [IdentityVerificationStatus.Failed]: (
        <div className='flex gap-x-2'>
          <Button
            onClick={() => {
              setStatusState(role.idvRequest?.status ?? IdentityVerificationStatus.NoStatus)
              refetchIdvRoles()
            }}
            data-track='IDV Check / Retry'
            variant='contained'
            palette='secondary'
            size='xs'
          >
            {t`Retry`}
          </Button>

          <Button
            onClick={async () => await handleUpdateStatus(IdentityVerificationStatus.NoStatus)}
            data-track='IDV Check / Reset'
            variant='outlined'
            palette='danger'
            size='xs'
          >
            {t`Reset`}
          </Button>
        </div>
      )
    }

    return buttonMap[statusState]
  }

  return (
    <div className='grid grid-cols-3 items-center'>
      <div className='col-span-1'>
        <EntityLink entity={role.person} />
      </div>
      <div className='col-span-2 flex justify-end'>{renderButtons()}</div>
    </div>
  )
}
