import { Label, Radio, cn } from '@strise/ui-components'
import { type ReactNode } from 'react'

interface RiskOption {
  bgColorClass: string
  colorClass: string
  label: string
  value: string
}

export const BaseRiskSelect = ({
  disabled,
  name,
  onChange,
  options,
  selectedValue
}: {
  disabled?: boolean
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  options: RiskOption[]
  selectedValue: string
}): ReactNode => {
  return (
    <>
      {options.map((option) => (
        <Label
          key={option.value}
          className={cn(
            'inline-flex cursor-pointer items-center p-2',
            selectedValue === option.value && `rounded font-medium ${option.bgColorClass}`
          )}
          variant='aLabel'
        >
          <Radio
            className={cn('mr-1', option.colorClass)}
            value={option.value}
            checked={selectedValue === option.value}
            name={name}
            data-id={`${name}-${option.value}`}
            onChange={onChange}
            disabled={disabled}
          />
          {option.label}
        </Label>
      ))}
    </>
  )
}
