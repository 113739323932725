import { Trans } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { type DivProps } from '@strise/react-utils'
import { IconCertified, IconNotCertified, Typography, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { EventLink } from '~/components/EventLink'
import { type RegisterFragment } from '~/graphqlTypes'
import { useIsMobile } from '~/utils/hooks'

export const Register = ({
  className,
  entityId,
  register,
  ...props
}: { entityId: string; register: RegisterFragment } & DivProps): ReactNode => {
  const isMobile = useIsMobile()
  const iconClassName = `mr-2 shrink-0 ${
    register.isRegistered ? 'text-semantic-success-shade-50' : 'text-secondary-shade-50'
  }`
  return (
    <div className={cn('flex w-full items-center py-1', className)} {...props}>
      {register.isRegistered ? (
        <IconCertified className={iconClassName} />
      ) : (
        <IconNotCertified className={iconClassName} />
      )}
      <div>
        <div className='flex items-center'>
          <Typography variant='aLabel'>{register.name}</Typography>
          {register.registerId && (
            <Typography className='ml-1' variant='body2'>
              · {register.registerId}
            </Typography>
          )}
        </div>
        <div className={cn('flex', isMobile ? 'flex-col items-start' : 'flex-row items-center')}>
          <Typography
            className={cn(
              'mr-1 rounded px-1',
              register.isRegistered
                ? 'bg-semantic-success-shade-10 text-semantic-success-shade-100'
                : 'bg-secondary-shade-10 text-gray-100'
            )}
            variant='body2'
          >
            {register.statusDescription}
          </Typography>
          {register.date && (
            <Typography variant='body2'>
              {!isMobile && '·'} <Trans>Last Changed</Trans>: {formatDate(register.date, { relative: false })}
            </Typography>
          )}

          {register.event && (
            <div className='flex items-center'>
              {!isMobile && (
                <Typography className='mx-1' variant='body2'>
                  ·{' '}
                </Typography>
              )}

              <EventLink
                entityId={entityId}
                eventId={register.event.id}
                variant='body2'
                className='block text-primary-main underline'
                data-track='Sidepanel / Register / Event link'
              >
                <Trans>See details</Trans>
              </EventLink>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
