import { t } from '@lingui/macro'
import { Divider, Tabs, TabsContent, TabsList, TabsTrigger } from '@strise/ui-components'
import { memo } from 'react'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { PortfolioCompanyView } from '~/features/Portfolio/components/PortfolioCompany/PortfolioCompanyView'
import { PortfolioPrivatePersonView } from '~/features/Portfolio/components/PortfolioPrivatePerson/PortfolioPrivatePersonView'

export const PortfolioView = memo(() => {
  const features = useCurrentUserFeatures()
  const isPrivatePersonEnabled = features.EXPERIMENTAL_PRIVATE_PERSON_FEATURES

  return (
    <Tabs defaultValue='company'>
      <TabsList className={isPrivatePersonEnabled ? 'flex justify-center py-2' : 'hidden'}>
        <TabsTrigger value='company'>{t`Businesses entities`}</TabsTrigger>
        {isPrivatePersonEnabled && <TabsTrigger value='person'>{t`Private persons`}</TabsTrigger>}
      </TabsList>
      <TabsContent value='company'>
        <PortfolioCompanyView />
      </TabsContent>
      {isPrivatePersonEnabled && (
        <TabsContent value='person'>
          <Divider />
          <PortfolioPrivatePersonView />
        </TabsContent>
      )}
    </Tabs>
  )
})
