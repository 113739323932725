import { type TableRowKind } from '@strise/types'
import { type ExternalLinkProps, type IconComponent } from '@strise/ui-components'
import { type ReactNode } from 'react'
import {
  type BaseEntityLikeFragment,
  type CustomMetaFragment,
  type DataSourceFragment,
  type TableLabelFragment,
  type TableRowValueFragment
} from '~/graphqlTypes'

export interface TransformedApiTableItem {
  Icon?: IconComponent | null
  containerClassName?: string
  content?: ReactNode
  customMeta?: CustomMetaFragment | null
  dataTrack?: string | null
  handleCopy?: () => void
  hideBorder?: boolean
  href?: string | null
  label?: ReactNode
  linkProps?: Omit<ExternalLinkProps, 'href'>
  tooltipContent?: ReactNode
}

export interface TransformedApiTableRow {
  disabled?: boolean
  hide?: boolean
  key: string
  kind: TableRowKind
  label: ReactNode
  paginationThreshold: number | null | undefined
  sections: TransformedApiTableSection[]
  showEmpty?: boolean
}

export interface TransformedApiTableSection {
  inlineLabel?: boolean
  items: TransformedApiTableItem[]
  label: ReactNode
  paginationThreshold: number | null | undefined
}

export interface TransformedApiTable {
  dataSources: DataSourceFragment[]
  description: ReactNode
  lastModifiedAt?: Date | null
  rows: TransformedApiTableRow[]
  title: ReactNode
}

interface EditRoleContext {
  comment?: string | null
  existingRole?: boolean
  periodFrom?: string | null
  periodTo?: string | null
  roleTitleId?: string
  type: 'EditRole'
}

export type EditContext = EditRoleContext

export type handleEditRowFn = (entity: BaseEntityLikeFragment | null, context?: EditContext) => void

export interface TableValueContentProps {
  editMode?: boolean
  entity: BaseEntityLikeFragment
  handleEdit?: handleEditRowFn
  value: TableRowValueFragment
}

export interface TableLabelContentProps {
  editMode?: boolean
  handleEdit?: handleEditRowFn
  label: TableLabelFragment
}

export const mkTableValueLabel = (value: TableRowValueFragment): string | null => {
  switch (value.__typename) {
    case 'TableRowValueLabeledString': {
      return value.label
    }
  }
  return null
}
