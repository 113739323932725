import { useApolloClient } from '@apollo/client/index.js'
import { t } from '@lingui/macro'
import { toast, useUserId } from '@strise/app-shared'
import { useContext } from '@strise/react-utils'
import { DisplayLanguage } from '@strise/types'
import { useCallback } from 'react'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useUpdateUserSettingsMutation } from '~/graphqlOperations'

const DEFAULT_LANGUAGE = DisplayLanguage.English

export const languageToISO: Partial<Record<DisplayLanguage, string>> = {
  [DisplayLanguage.Norwegian]: 'no',
  [DisplayLanguage.English]: 'en',
  [DisplayLanguage.Swedish]: 'sv',
  [DisplayLanguage.Danish]: 'da'
}
export const languageToLocaleStr: Partial<Record<DisplayLanguage, string>> = {
  [DisplayLanguage.Norwegian]: 'nb',
  [DisplayLanguage.English]: 'en',
  [DisplayLanguage.Swedish]: 'sv',
  [DisplayLanguage.Danish]: 'da'
}

export const useDisplayLanguage = (): DisplayLanguage => {
  const userSettings = useContext(CurrentUserSettingsContext)
  const displayLanguage = userSettings.settings.displayLanguage || DEFAULT_LANGUAGE

  return displayLanguage
}

export const useUpdateDisplayLanguage = (): [
  DisplayLanguage,
  (lang: DisplayLanguage, onCompleted?: () => void) => void,
  boolean
] => {
  const apolloClient = useApolloClient()
  const currentUserId = useUserId()
  const displayLanguage = useDisplayLanguage()

  const handleCompleted = async (): Promise<void> => {
    await apolloClient.resetStore()
    toast.success(t`Language updated`)
  }
  const [update, { loading }] = useUpdateUserSettingsMutation({
    onCompleted: handleCompleted
  })

  const handleUpdate = useCallback((newLang: DisplayLanguage, onCompleted?: () => void) => {
    update({
      variables: {
        user: currentUserId,
        input: { application: { displayLanguage: newLang } }
      }
    }).then(() => {
      if (onCompleted) onCompleted()
    })
  }, [])

  return [displayLanguage, handleUpdate, loading]
}
