import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ScrollArea,
  cn
} from '../../'
import type { ComponentPropsWithoutRef } from 'react'
import type * as React from 'react'

export interface ModalProps {
  actions?: React.ReactNode
  children?: React.ReactNode
  className?: string
  contentClassName?: string
  description?: string
  dialogProps?: ComponentPropsWithoutRef<typeof Dialog>
  isOpen: boolean
  onClose: () => void
  title?: React.ReactNode
  trigger?: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({
  actions,
  children,
  contentClassName,
  description,
  dialogProps,
  isOpen,
  onClose,
  title,
  trigger,
  ...props
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose} {...dialogProps} {...props}>
      {trigger && <DialogTrigger>{trigger}</DialogTrigger>}
      <DialogContent
        className={cn('flex flex-col py-0 gap-0 bg-gray-5', contentClassName)}
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <div className='shrink-0'>
          <DialogHeader className='py-6 flex flex-row justify-between'>
            {title && <DialogTitle>{title}</DialogTitle>}
            {description && <DialogDescription>{description}</DialogDescription>}
          </DialogHeader>
        </div>

        <div className='flex-1 rounded-sm bg-background-paper'>
          <ScrollArea className='overflow-auto'>
            <div className='px-6 py-4 max-h-[calc(85vh-12rem)]'>{children}</div>
          </ScrollArea>
        </div>

        <div className='shrink-0 pt-4 pb-6 flex justify-end gap-2 bg-gray-5'>{actions && actions}</div>
      </DialogContent>
    </Dialog>
  )
}

export default Modal
