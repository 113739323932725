import { t } from '@lingui/macro'
import { Button, Typography } from '@strise/ui-components'
import { type ReactNode, useState } from 'react'
import RegistryCheckDialog from '~/components/RegistryCheck/RegistryCheckDialog'
import { type BaseReviewCompanyFragment } from '~/graphqlTypes'

const RegistryCheckButton = ({ entity }: { entity: BaseReviewCompanyFragment }): ReactNode => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className='flex justify-between w-full p-6 border-b border-divider -mt-4'>
        <div className='flex flex-col items-start'>
          <Typography variant='subtitle1'>{t`Verify Information`}</Typography>
          <Typography variant='body1'>{t`Check information against local registry data`}</Typography>
        </div>
        <Button
          className='self-center'
          variant='contained'
          palette='primary'
          onClick={() => setOpen(true)}
          data-track='Registry Check / Verify information'
        >
          {t`Verify information`}
        </Button>
      </div>

      {open && <RegistryCheckDialog entity={entity} setOpen={setOpen} />}
    </>
  )
}

export default RegistryCheckButton
