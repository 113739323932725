import { getBrowserGlobals } from './browserGlobals'

export enum ContentType {
  PNG = 'png',
  JPG = 'jpg',
  PDF = 'pdf',
  CSV = 'csv',
  JSON = 'json',
  TEXT = 'text',
  HTML = 'html',
  XML = 'xml',
  ZIP = 'zip',
  OCTET_STREAM = 'octet-stream'
}

const contentTypeToMimeType: Record<ContentType, string> = {
  [ContentType.PNG]: 'image/png',
  [ContentType.JPG]: 'image/jpeg',
  [ContentType.PDF]: 'application/pdf',
  [ContentType.CSV]: 'text/csv',
  [ContentType.JSON]: 'application/json',
  [ContentType.TEXT]: 'text/plain',
  [ContentType.HTML]: 'text/html',
  [ContentType.XML]: 'application/xml',
  [ContentType.ZIP]: 'application/zip',
  [ContentType.OCTET_STREAM]: 'application/octet-stream'
}

const resolveMimeType = (type: ContentType | string): string => {
  if (Object.values(ContentType).includes(type as ContentType)) {
    return contentTypeToMimeType[type as ContentType]
  }
  return type
}

export const createBlob = (content: Array<string | Uint8Array>, contentType: ContentType | string): Blob | null => {
  const browserGlobals = getBrowserGlobals()
  if (!browserGlobals) {
    return null
  }
  const mimeType = resolveMimeType(contentType)
  return new browserGlobals.window.Blob(content, { type: `${mimeType};charset=utf-8;` })
}

export const createFile = (blob: Blob | null, name: string, contentType: ContentType | string): File | null => {
  if (!blob) {
    return null
  }

  const browserGlobals = getBrowserGlobals()
  if (!browserGlobals) return null

  const mimeType = resolveMimeType(contentType)
  const fileExtension = Object.entries(contentTypeToMimeType)
    .find(([, type]) => type === mimeType)?.[0]
    ?.toLowerCase()

  const finalName = fileExtension && !name.endsWith(`.${fileExtension}`) ? `${name}.${fileExtension}` : name

  return new browserGlobals.window.File([blob], finalName, { type: mimeType })
}

export const base64ToBlob = (base64: string, contentType: ContentType | string): Blob | null => {
  const browserGlobals = getBrowserGlobals()
  if (!browserGlobals) {
    return null
  }
  const sliceSize = 512
  const byteCharacters = browserGlobals.window.atob(base64)
  const chunkCount = Math.ceil(byteCharacters.length / sliceSize)

  const byteArrays = Array.from({ length: chunkCount }, (_, i) => {
    const offset = i * sliceSize
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = Array.from(slice, (str) => str.charCodeAt(0))
    return new Uint8Array(byteNumbers)
  })

  return createBlob(byteArrays, contentType)
}

// TODO: fix eslint errors if viable
const saveAs = (file: File): void => {
  const browserGlobals = getBrowserGlobals()
  if (browserGlobals) {
    const url = browserGlobals.window.URL.createObjectURL(file)
    const aElement = browserGlobals.document.createElement('a')
    aElement.href = url
    aElement.download = file.name
    aElement.style.display = 'none'
    // eslint-disable-next-line unicorn/prefer-dom-node-append
    browserGlobals.document.body.appendChild(aElement)
    aElement.click()
    browserGlobals.window.URL.revokeObjectURL(url)
    browserGlobals.document.body.removeChild(aElement)
  }
}

export const triggerBase64Download = (base64: string, name: string, contentType: ContentType | string): void => {
  const blob = base64ToBlob(base64, contentType)
  const file = createFile(blob, name, contentType)
  if (!file) return

  saveAs(file)
}

export const triggerDownload = (content: string, name: string, contentType: ContentType | string): void => {
  const blob = createBlob([content], contentType)
  const file = createFile(blob, name, contentType)
  if (!file) return

  saveAs(file)
}
