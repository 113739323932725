import { i18n } from '@lingui/core'
import { Trans, defineMessage } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { Typography, cn } from '@strise/ui-components'
import { Modal, ModalContent, ModalHeader, colors } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { scoreToColor } from '~/components/Score/scoreUtils'
import { CreditScoreIndicator } from '~/features/CreditReport/CreditScoreIndicator'

const generalRatings = [
  {
    min: 0,
    max: 0,
    title: defineMessage({ message: 'Not rated' })
  },
  {
    min: 1,
    max: 20,
    title: defineMessage({ message: 'Very high risk' })
  },
  {
    min: 21,
    max: 29,
    title: defineMessage({ message: 'High risk' })
  },
  {
    min: 30,
    max: 50,
    title: defineMessage({ message: 'Moderate risk' })
  },
  {
    min: 51,
    max: 70,
    title: defineMessage({ message: 'Low risk' })
  },
  {
    min: 71,
    max: 100,
    title: defineMessage({ message: 'Very low risk' })
  }
]

// https://strise.slack.com/files/U04TELLKTJT/F088HQCU1CN/finland_score_enhancement.pdf
const finnishRatings = [
  {
    min: 0,
    max: 0,
    title: defineMessage({ message: 'Not rated' })
  },
  {
    min: 1,
    max: 648,
    title: defineMessage({ message: 'Very high risk' })
  },
  {
    min: 649,
    max: 898,
    title: defineMessage({ message: 'High risk' })
  },
  {
    min: 899,
    max: 972,
    title: defineMessage({ message: 'Moderate risk' })
  },
  {
    min: 973,
    max: 992,
    title: defineMessage({ message: 'Low risk' })
  },
  {
    min: 993,
    max: 1000,
    title: defineMessage({ message: 'Very low risk' })
  }
]

const internationalRatings = [
  {
    character: 'E',
    color: colors.gray[5],
    title: defineMessage({ message: 'Not rated' })
  },
  {
    character: 'D',
    color: colors.semanticRed[50],
    title: defineMessage({ message: 'High risk' })
  },
  {
    character: 'C',
    color: colors.semanticOrange[50],
    title: defineMessage({ message: 'Moderate risk' })
  },
  {
    character: 'B',
    color: colors.semanticYellow[50],
    title: defineMessage({ message: 'Low risk' })
  },
  {
    character: 'A',
    color: colors.semanticGreen[50],
    title: defineMessage({ message: 'Very low risk' })
  }
]

const RatingBox = ({ className, ...props }: DivProps): ReactNode => {
  return <div className={cn('mb-2 h-6 w-full border-x border-background-paper', className)} {...props} />
}

export const SidepanelCreditScoreHelpModal = ({
  handleClose,
  isFinnish
}: {
  handleClose: () => void
  isFinnish: boolean
}): ReactNode => {
  const ratings = isFinnish ? finnishRatings : generalRatings
  return (
    <Modal onRequestClose={handleClose} isOpen contentMaxWidth={942}>
      <ModalHeader onClose={handleClose}>
        <Trans>Rating</Trans>
      </ModalHeader>
      <ModalContent>
        <CreditScoreIndicator
          className='mb-[3.75rem]'
          size='medium'
          score={isFinnish ? 1000 : 100}
          scoreCharacter='A'
          mixedColors
          isFetched
          isFinnish={isFinnish}
        />
        <div className='mb-8'>
          <Typography variant='subtitle1'>
            {isFinnish ? <Trans>0-1000 Rating</Trans> : <Trans>1-100 Rating</Trans>}
          </Typography>
          <div className='flex'>
            {ratings.map((rating, index) => {
              return (
                <div className='w-1/6' key={index}>
                  <RatingBox style={{ backgroundColor: scoreToColor(rating.min, 50, isFinnish) }} />
                  <Typography className='block' variant='aLabelSmall'>
                    {rating.min ? `${rating.min} - ${rating.max}` : '-'}
                  </Typography>
                  <Typography className='block' variant='aLabelSmall'>
                    {i18n._(rating.title)}
                  </Typography>
                </div>
              )
            })}
          </div>
        </div>
        <div className='mb-8'>
          <Typography variant='subtitle1'>
            <Trans>A-E - International rating</Trans>
          </Typography>
          <div className='mb-8 flex'>
            {internationalRatings.map((rating, index) => {
              return (
                <div className='w-1/5' key={index}>
                  <RatingBox style={{ backgroundColor: rating.color }} />
                  <Typography className='block' variant='aLabelSmall'>
                    {rating.character}
                  </Typography>
                  <Typography className='block' variant='aLabelSmall'>
                    {i18n._(rating.title)}
                  </Typography>
                </div>
              )
            })}
          </div>
        </div>
        <div className='mb-8'>
          <Typography variant='subtitle1'>
            <Trans>Why some companies don't get a score</Trans>
          </Typography>
          <Typography className='mb-4 font-regular' variant='subtitle1'>
            <Trans>
              The most frequent reason is unusual financials, where we can’t build the same score model as for other
              more conventional companies that supply goods or services. The second most frequent reason is when there
              aren't enough comparable data to make a score.
            </Trans>
          </Typography>
          <Typography className='font-regular' variant='subtitle1'>
            <Trans>
              We've also excluded certain forms of organizations that don't have to report their financials, or for
              other reasons deviate from regular organizations. This applies to, among other things, governmental
              institutions, voluntary organizations, and other organizations with special interests.
            </Trans>
          </Typography>
        </div>
      </ModalContent>
    </Modal>
  )
}
