import { type MessageDescriptor, i18n } from '@lingui/core'
import { type SetStateFn } from '@strise/react-utils'
import { type Primitive } from '@strise/types'
import { isObject } from 'lodash-es'
import type { ComponentType } from 'react'
import { useState } from 'react'
import { useDebounceValue } from 'usehooks-ts'
import { EditFilterArray } from '~/components/Filter/EditFilterArray'
import { type ChipComponentType } from '~/components/Filter/FilterChips'
import { FilterFromToActive } from '~/components/Filter/FilterFromToActive'
import { FilterFromToEdit } from '~/components/Filter/FilterFromToEdit'
import { type FromToLike } from '~/components/Filter/filterFromToUtils'
import { type FilterOptionsHook } from '~/components/Filter/filterHooks'
import { inputToNumberOrNull } from '~/components/Filter/filterUtils'

export interface FilterSpec<T> {
  Component: ComponentType<{ setValue: SetStateFn<T>; value: T }>
  EditComponent: ComponentType<{ setValue: SetStateFn<T>; value: T }>
  totalSelected: (value: T) => number
}

const itemToId = (item: Primitive | object): string => {
  if (!isObject(item)) return String(item)
  if ('id' in item) return String(item.id)
  return JSON.stringify(item)
}

// TODO - this composition is not very intuitive, should consider refactoring
export const filterArray = <I extends Primitive | object, T extends object>({
  ChipComponent,
  dataTrack,
  editLabel,
  enableSearch,
  extractSetSubValue,
  extractSubValue,
  titleMap,
  useOptions,
  ...props
}: {
  ChipComponent: ChipComponentType<I>
  dataTrack: string
  editLabel: MessageDescriptor
  enableSearch?: boolean
  extractSetSubValue: (fn: SetStateFn<T>) => SetStateFn<I[]>
  extractSubValue: (v: T) => I[]
  titleMap?: Record<string, MessageDescriptor>
  useOptions: FilterOptionsHook<I>
}): FilterSpec<T> => {
  return {
    Component: ({ setValue, value, ...componentProps }) => {
      const subValue = extractSubValue(value)
      const setSubValue = extractSetSubValue(setValue)

      const handleDelete = (toDelete: I): void => {
        const idToDelete = itemToId(toDelete)

        const newValue = subValue.filter((item) => {
          const itemId = itemToId(item)
          return itemId !== idToDelete
        })

        setSubValue(newValue)
      }

      if (!subValue.length) return null

      return (
        <>
          {subValue.map((v) => (
            <ChipComponent
              key={itemToId(v)}
              value={v}
              onDelete={() => handleDelete(v)}
              titleMap={titleMap}
              {...componentProps}
            />
          ))}
        </>
      )
    },
    EditComponent: ({ setValue, value }) => {
      const [inputValue, setInputValue] = useState('')
      const [debouncedInputValue] = useDebounceValue(inputValue, 500)

      const subValue = extractSubValue(value)
      const setSubValue = extractSetSubValue(setValue)

      const {
        disableBackendSearch,
        items,
        loading,
        paginationProps,
        value: filterValue
      } = useOptions({ searchValue: debouncedInputValue, currentValues: subValue })

      return (
        <EditFilterArray
          value={filterValue}
          setValue={setSubValue}
          inputValue={inputValue}
          setInputValue={setInputValue}
          options={items}
          loading={loading}
          dataTrack={dataTrack}
          editLabel={i18n._(editLabel)}
          search={enableSearch}
          paginationProps={paginationProps}
          disableBackendSearch={disableBackendSearch}
          {...props}
        />
      )
    },
    totalSelected: (value: T) => {
      const subValue = extractSubValue(value)
      return subValue.length
    }
  }
}

// TODO - this composition is not very intuitive, should consider refactoring
export const filterFromTo = <Typename extends string, T extends object>({
  dataTrack,
  editLabel,
  extractSetSubValue,
  extractSubValue,
  header,
  suffix
}: {
  dataTrack: string
  editLabel: MessageDescriptor
  extractSetSubValue: (fn: SetStateFn<T>) => SetStateFn<FromToLike<Typename>>
  extractSubValue: (v: T) => FromToLike<Typename>
  header?: MessageDescriptor
  suffix: MessageDescriptor
}): FilterSpec<T> => {
  return {
    Component: ({ setValue, value, ...props }) => {
      const subValue = extractSubValue(value)
      const setSubValue = extractSetSubValue(setValue)

      const handleDelete = (): void => {
        const newValue = { from: null, to: null, __typename: subValue.__typename }
        setSubValue(newValue)
      }
      return <FilterFromToActive filter={subValue} onDelete={handleDelete} suffix={suffix} {...props} />
    },
    EditComponent: ({ setValue, value }) => {
      const subValue = extractSubValue(value)
      const setSubValue = extractSetSubValue(setValue)

      const saveHandler = (from: string, to: string): void => {
        const newValue = {
          from: inputToNumberOrNull(from),
          to: inputToNumberOrNull(to),
          __typename: subValue.__typename
        }

        setSubValue(newValue)
      }

      const resetHandler = (): void => {
        const newValue = { from: null, to: null, __typename: subValue.__typename }
        setSubValue(newValue)
      }
      return (
        <FilterFromToEdit
          filter={subValue}
          onReset={resetHandler}
          onSave={saveHandler}
          dataTrack={dataTrack}
          title={i18n._(editLabel)}
          header={header ? i18n._(header) : undefined}
        />
      )
    },
    totalSelected: (value: T) => {
      const subValue = extractSubValue(value)

      return subValue.to || subValue.from ? 1 : 0
    }
  }
}
