import { type OwnershipChartNode, type RenderNodeContent } from './types'
import { IconButton, IconPin, Typography, cn } from '@strise/ui-components'
import { Handle, type Node, type NodeProps, Position, useEdges } from '@xyflow/react'
import type { ReactNode } from 'react'

export type OwnerNodeType<T extends OwnershipChartNode> = Node<
  T & {
    handlePinNode: (nodeId: string) => void
    isHovered: boolean
    pinnedNodeId: string | null
    renderNodeContent?: RenderNodeContent<T>
    setHoveredNodeId: (nodeId: string | null) => void
  },
  'owner'
>

type OwnerNodeProps<T extends OwnershipChartNode> = NodeProps<OwnerNodeType<T>>

const handleClassName = 'border !bg-transparent rounded-full size-[5px] border-secondary-shade-40 -z-10'

export const OwnerNode = <T extends OwnershipChartNode>({
  data,
  height = 0,
  width = 0
}: OwnerNodeProps<T>): ReactNode => {
  const isPinned = data.id === data.pinnedNodeId
  const isOtherNodePinned = data.pinnedNodeId && data.id !== data.pinnedNodeId
  const edges = useEdges()
  const ownershipEdge = edges.find((edge) => edge.source === data.id)

  const defaultNodeClassName = cn(
    'relative size-full cursor-grab border border-secondary-shade-20 bg-white active:cursor-grabbing',
    (isPinned || (data.isHovered && !isOtherNodePinned)) &&
      'border-transparent outline outline-2 outline-primary-shade-50'
  )

  const defaultNodeContent = (
    <div className={defaultNodeClassName} style={{ width, height }}>
      <Typography variant='body3' className='flex flex-col gap-2 p-2'>
        <span>{data.name}</span>
        <span>{data.indirectShare}</span>
      </Typography>
    </div>
  )

  const handleMouseEnter = (): void => {
    data.setHoveredNodeId(data.id)
  }

  const handleMouseLeave = (): void => {
    data.setHoveredNodeId(null)
  }

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Handle className={cn(handleClassName)} position={Position.Bottom} type='source' />
      <IconButton
        className={cn(
          'absolute right-[-12px] top-[-12px] z-10 hidden rounded-full border border-solid border-secondary-shade-10 bg-white',
          (data.isHovered || isPinned) && 'flex',
          isPinned && 'outline outline-2 outline-primary-shade-50'
        )}
        variant='outlined'
        palette='tertiary'
        onClick={() => data.handlePinNode(data.id)}
        data-track='Ownerships / Owner / Pin'
      >
        <IconPin size='md' />
      </IconButton>
      {data.renderNodeContent?.({
        nodeId: data.id,
        className: defaultNodeClassName,
        ownershipEdge,
        width,
        height,
        data
      }) ?? defaultNodeContent}
      {!data.isLeaf && <Handle className={cn(handleClassName)} position={Position.Top} type='target' />}
    </div>
  )
}
