import { FullPageLoader } from '../FullPageLoader'
import { useLogout } from './authUtils'
import type { ForwardedRef } from 'react'
import { forwardRef, useEffect } from 'react'

export const LogOutView = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const logout = useLogout()
  useEffect(() => {
    logout()
  }, [logout])
  return (
    <div ref={ref}>
      <FullPageLoader />
    </div>
  )
})
