import type { ReactNode } from 'react'
import { type SelectCompaniesStatusProps, type SelectCompany } from '~/features/CompanyStatus/companyStatusUtils'
import { useSelectCompaniesStatus } from '~/features/CompanyStatus/useSelectCompaniesStatus'

export const SelectCompaniesStatus = ({
  companies,
  portfolioId,
  teamId,
  ...props
}: { companies: SelectCompany[]; portfolioId?: string; teamId?: string } & Omit<
  SelectCompaniesStatusProps,
  'companyIds' | 'updateCompanyStatus' | 'loading'
>): ReactNode => {
  const { SelectCompaniesStatus: SelectStatuses } = useSelectCompaniesStatus(companies, portfolioId, teamId)
  return <SelectStatuses {...props} />
}
