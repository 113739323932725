import { Chip, cn } from '@strise/ui-components'
import { type ReactNode } from 'react'

interface RiskChipProps {
  className?: string
  colorClass: string
  label: string
  onClick?: () => void
}

export const BaseRiskChip = ({ className, colorClass, label, onClick }: RiskChipProps): ReactNode => {
  return <Chip className={cn(colorClass, className)} onClick={onClick} label={label} />
}
