import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconDownload = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m17.157 6.774-.167-1.45a1.5 1.5 0 0 0-2.191-1.15l-1.193.633-1.033-.87a4.003 4.003 0 0 0-6.414 1.941l-.28.96-.936.353A3.002 3.002 0 0 0 6 13h3v2H6a5 5 0 0 1-1.761-9.68 6.003 6.003 0 0 1 9.622-2.913 3.5 3.5 0 0 1 5.116 2.689A5.002 5.002 0 0 1 18 15h-3v-2h3a3 3 0 0 0 .588-5.943l-1.431-.283Zm-1.864 10.519L13 19.586V7h-2v12.586l-2.293-2.293-1.414 1.414L12 23.414l4.707-4.707-1.414-1.414Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconDownload.displayName = 'IconDownload'
