import { useReactiveVar } from '@apollo/client/index.js'
import { NationFlags } from '@strise/app-shared'
import { filterNullishValues } from '@strise/ts-utils'
import { type CountryKind } from '@strise/types'
import { Combobox, type ComboboxProps, Typography, cn } from '@strise/ui-components'
import { FinancialFieldSelectRenderText } from '~/features/Financials/FinancialFieldSelectRenderText'
import { type FinancialId } from '~/features/Financials/financialTypes'
import { useFinancialFieldSelectOptions } from '~/features/Financials/financials'
import { type FinancialFieldInfoFragment } from '~/graphqlTypes'
import { financialFieldsMap } from '~/state'

export const FinancialSelect = ({
  className,
  countries,
  fieldInfo,
  ...props
}: {
  countries: CountryKind[]
  fieldInfo: FinancialFieldInfoFragment
} & Omit<ComboboxProps<FinancialId>, 'items' | 'value'>) => {
  const financialsFieldMapState = useReactiveVar(financialFieldsMap)

  const financialFieldMapValues = filterNullishValues(Object.values(financialsFieldMapState))

  const options = useFinancialFieldSelectOptions(financialFieldMapValues, FinancialFieldSelectRenderText)

  return (
    <Combobox
      className={cn('h-14 w-full pr-4', className)}
      variant='contained'
      palette='tertiary'
      inlineSearch
      disableSelectedOnTop
      singleSelect
      items={options}
      value={[{ id: fieldInfo.id, label: fieldInfo.name, value: fieldInfo.id }]}
      customSelectedItemsRenderer={
        <div className='flex gap-2'>
          <NationFlags countryIsoCodes={countries} />
          <Typography>{fieldInfo.name}</Typography>
        </div>
      }
      data-track='false'
      {...props}
    />
  )
}
