import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export const IconFlag = forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.666504 13.6666H1.99984V9.99991L2.61883 9.53567C3.52511 8.85596 4.72825 8.71986 5.76346 9.17996C6.50057 9.50756 7.30923 9.6412 8.11255 9.56817L11.6996 9.24208L9.73836 5.31957L11.8005 0.782796L8.13896 1.44854C7.44517 1.57468 6.72966 1.49614 6.07976 1.22249C4.75198 0.663425 3.24814 0.74965 1.99984 1.44016V0.333252H0.666504V13.6666ZM1.99984 3.02337V8.34014C3.26911 7.48326 4.89606 7.33535 6.30498 7.96154C6.83429 8.19679 7.41498 8.29276 7.99184 8.24031L9.6334 8.09108L8.26132 5.34692L9.53248 2.55036L8.37748 2.76036C7.429 2.93281 6.45083 2.82544 5.56235 2.45134C4.52734 2.01554 3.34272 2.12811 2.40832 2.75105L1.99984 3.02337Z'
        fill='currentColor'
      />
    </svg>
  )
)
IconFlag.displayName = 'IconFlag'
