import { type ReactNode } from 'react'
import { ReviewItem } from '~/features/Review/ReviewItem'
import { reviewEntityToEntity, reviewRoleMetaToRoleMeta } from '~/features/Review/reviewValueTransformers'
import { type ReviewRoleValueFragment, type RoleMetaFragment } from '~/graphqlTypes'
import { RoleWithEntity } from '~/utils/apiTable/RoleWithEntity'

export const ReviewRole = ({ item }: { item: ReviewRoleValueFragment }): ReactNode => {
  if (!item.value) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const entity = reviewEntityToEntity(item.value.entity)

  const roleMetas = item.value.roleMetas.map((roleMeta): RoleMetaFragment => reviewRoleMetaToRoleMeta(roleMeta))

  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <RoleWithEntity entity={entity} roleMetas={roleMetas} />
    </ReviewItem>
  )
}
