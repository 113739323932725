import { t } from '@lingui/macro'
import { Tooltip, type TooltipProps } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { Fragment } from 'react'

interface JsonRawDataTooltipProps extends TooltipProps {
  json: string[]
}

export const JsonRawDataTooltip = ({ children, json, ...props }: JsonRawDataTooltipProps): ReactNode => {
  const jsonWithContent = json.filter((sourceJson) => sourceJson.length > 2)
  if (jsonWithContent.length === 0) return children

  return (
    <Tooltip
      className='max-h-96 overflow-auto'
      content={
        <pre>
          {t`Raw data:`}
          {jsonWithContent.map((sourceJson, index) => {
            return (
              <Fragment key={index}>
                <br />
                {sourceJson}
                <br />
                {sourceJson}
              </Fragment>
            )
          })}
        </pre>
      }
      {...props}
    >
      {children}
    </Tooltip>
  )
}
