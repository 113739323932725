import { t } from '@lingui/macro'
import { IdentityVerificationStatus } from '@strise/types'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  IconCheck,
  IconDanger
} from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { IdvActions } from '~/features/IDVCheck/IdvActions'
import { IdvCheckDocuments } from '~/features/IDVCheck/IdvCheckDocuments'
import { type IdvRoleFragment } from '~/graphqlTypes'

interface IDVCheckModalDecided {
  children: ReactNode
  loadingStatus: IdentityVerificationStatus | null
  onUpdateStatus: (status: IdentityVerificationStatus) => Promise<void>
  role: IdvRoleFragment
  status: IdentityVerificationStatus.Approved | IdentityVerificationStatus.Declined
}

export const IDVCheckModalDecided = ({
  children,
  loadingStatus,
  onUpdateStatus,
  role,
  status
}: IDVCheckModalDecided): ReactNode => {
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className='flex items-center gap-2'>
            {status === IdentityVerificationStatus.Approved && (
              <>
                <IconCheck className='text-semantic-success-main' /> {t`ID Verification Approved`}
              </>
            )}
            {status === IdentityVerificationStatus.Declined && (
              <>
                <IconDanger className='text-semantic-danger-main' /> {t`ID Verification Declined`}
              </>
            )}
          </DialogTitle>
          <DialogDescription>
            <IdvCheckDocuments role={role} />
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <IdvActions onUpdateStatus={onUpdateStatus} status={status} setOpen={setOpen} loadingStatus={loadingStatus} />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
