import { Trans, t } from '@lingui/macro'
import { Button, IconPlusSmall, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { OwnerDetail } from '~/features/Ownerships/OwnerDetail'
import { Role } from '~/utils/apiTable/Role'
import { RoleWithEntity } from '~/utils/apiTable/RoleWithEntity'
import { RoleWithEntityForPerson } from '~/utils/apiTable/RoleWithEntityForPerson'
import { type TableLabelContentProps, type TableValueContentProps } from '~/utils/apiTable/apiTableUtils'

export const TableLabelContent = ({ editMode, handleEdit, label }: TableLabelContentProps): ReactNode => {
  if (label.__typename === 'TableLabelEntity') {
    return (
      <div className='flex items-center'>
        <EntityLink entity={label.entity} transparent={!label.isActive} />
        {label.description && <Typography className='ml-1'>({label.description})</Typography>}
      </div>
    )
  }

  if (label.__typename === 'TableLabelEntityRole') {
    return (
      <div className='grid items-center'>
        <EntityLink entity={label.entity} transparent={!label.isActive} />
        {editMode && handleEdit && (
          <Button
            data-track='Sidepanel / Roles / Open Add Role'
            palette='primary'
            className='w-fit pl-0'
            startIcon={<IconPlusSmall />}
            onClick={() => {
              handleEdit(label.entity, {
                existingRole: true,
                type: 'EditRole'
              })
            }}
          >
            <Typography>
              <Trans>Add</Trans>
            </Typography>
          </Button>
        )}
      </div>
    )
  }

  if (label.__typename === 'TableLabelRole') {
    return (
      <div className='flex items-center gap-2'>
        <Typography className='mr-1'>{label.name}</Typography>
        {editMode && handleEdit && (
          <Button
            data-track='Sidepanel / Roles / Open Add Role'
            palette='primary'
            className='h-[24px] w-fit p-0'
            onClick={() => {
              handleEdit(null, {
                existingRole: false,
                type: 'EditRole',
                roleTitleId: label.roleTitleId
              })
            }}
          >
            <IconPlusSmall />
          </Button>
        )}
      </div>
    )
  }

  // Keeping this in case we introduce more types l8r
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (label.__typename === 'TableLabelString') {
    return <span className={label.isActive ? 'text-inherit' : 'text-text-secondary'}>{label.content}</span>
  }

  return null
}

export const TableValueContent = ({ editMode, entity, handleEdit, value }: TableValueContentProps): ReactNode => {
  switch (value.__typename) {
    case 'TableRowValueString': {
      return value.content
    }
    case 'TableRowValueLabeledString': {
      return value.content
    }
    case 'TableRowValueRelatedEntity': {
      return <EntityLink entity={value.entity} />
    }
    case 'TableRowValueOwnership': {
      return (
        <OwnerDetail
          rootEntityId={entity.id}
          entity={value.maybeEntity}
          entityName={value.entityName}
          ownership={value.ownership}
          uboMeta={value.uboMeta}
          customMeta={value.customMeta}
          shareClasses={value.shareClasses.map(
            (shareClass) => `${shareClass.shareClass ?? t`Unknown`}: ${shareClass.share}`
          )}
        />
      )
    }
    case 'TableRowValueRoleWithEntity': {
      return (
        <RoleWithEntity entity={value.entity} roleMetas={value.roleMetas} handleEdit={handleEdit} editMode={editMode} />
      )
    }
    case 'TableRowValueRole': {
      return <Role roleMeta={value.roleMeta} handleEdit={handleEdit} editMode={editMode} />
    }
    case 'TableRowValueRoleForPerson': {
      return <RoleWithEntityForPerson value={value} handleEdit={handleEdit} editMode={editMode} />
    }
    default: {
      return null
    }
  }
}
