import { Button, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { spoof } from '~/features/Spoof/spoof'
import { MENU_Z_INDEX } from '~/utils/zIndexes'

export const SpoofWarning = (): ReactNode => {
  const handleClick = (): void => spoof.stop()

  return (
    <Typography
      className='fixed bottom-0 w-screen bg-semantic-danger-main p-1 text-center text-white'
      style={{ zIndex: MENU_Z_INDEX }}
      component='div'
      variant='body2'
    >
      YOU ARE SPOOFING{' '}
      <Button
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          background: 'none',
          border: 'none',
          color: 'inherit',
          padding: 0
        }}
        onClick={handleClick}
        data-track='false'
      >
        STOP?
      </Button>
    </Typography>
  )
}
