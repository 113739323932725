import { type ReactNode } from 'react'
import { BaseRiskChip } from '~/features/Risk/BaseRiskChip'
import { riskChipClasses } from '~/features/Risk/risk'
import { type ReviewFragment } from '~/graphqlTypes'

interface CustomRiskLevelChipProps {
  className?: string
  customRiskLevel: NonNullable<ReviewFragment['customRiskLevel']>
  onClick?: () => void
}

export const CustomRiskLevelChip = ({ className, customRiskLevel, onClick }: CustomRiskLevelChipProps): ReactNode => {
  return (
    <BaseRiskChip
      className={className}
      colorClass={riskChipClasses[customRiskLevel.color]}
      label={customRiskLevel.label}
      onClick={onClick}
    />
  )
}
