import { Trans, t } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { formatNumber } from '@strise/ts-utils'
import {
  Divider,
  IconButton,
  IconChevronLeftSuperSmall,
  IconChevronRightSuperSmall,
  LoaderRound,
  Select,
  Skeleton,
  Typography
} from '@strise/ui-components'
import { forwardRef } from 'react'

export interface PaginationProps extends DivProps {
  nextFn?: () => void
  nextLoading?: boolean
  pageIndex: number
  pageSize: number
  pageSizeOptions?: number[]
  prevFn?: () => void
  setPageIndex?: (index: number) => void
  setPageSize?: (size: number) => void
  totalApprox?: boolean
  totalResults: number
  totalSeparator?: string
  totalSuffix?: string
}

const defaultPageSizeOptions = [10, 25, 50, 100, 250]

export const Pagination = forwardRef<HTMLDivElement, PaginationProps>(
  (
    {
      nextFn,
      nextLoading,
      pageIndex,
      pageSize,
      pageSizeOptions = defaultPageSizeOptions,
      prevFn,
      setPageIndex,
      setPageSize,
      totalApprox,
      totalResults,
      totalSeparator = '/',
      totalSuffix
    },
    ref
  ) => {
    const from = (pageIndex + 1) * pageSize - pageSize + 1
    const toTemp = (pageIndex + 1) * pageSize
    const to = toTemp > totalResults ? totalResults : toTemp

    const handleNextClick =
      nextFn ||
      (setPageIndex && to < totalResults
        ? () => {
            setPageIndex(pageIndex + 1)
          }
        : undefined)

    const handlePrevClick =
      prevFn ||
      (setPageIndex && from > 1
        ? () => {
            setPageIndex(pageIndex - 1)
          }
        : undefined)

    return (
      <div className='flex h-14 items-center justify-end' ref={ref}>
        {setPageSize && (
          <div className='flex h-full items-center'>
            <Typography className='mr-2' variant='body2' component='span'>
              <Trans>Rows per page</Trans>:
            </Typography>
            <Select
              variant='ghost'
              palette='tertiary'
              aria-label={t`Select number of rows per page`}
              value={String(pageSize)}
              options={pageSizeOptions.map((size) => ({
                value: String(size),
                children: String(size)
              }))}
              onValueChange={(size: string) => {
                if (size) {
                  setPageSize(Number.parseInt(size, 10))
                }
              }}
            />

            <Divider className='h-full' orientation='vertical' />
          </div>
        )}

        <div className='px-4'>
          {nextLoading ? (
            <Skeleton className='h-2 w-20' />
          ) : (
            <>
              <Typography variant='body2' component='span'>
                {from} - {to}{' '}
              </Typography>
              {totalResults && (
                <>
                  <Typography className='text-text-secondary' variant='body2' component='span'>
                    {totalSeparator}
                  </Typography>
                  <Typography variant='body2' component='span' data-testid='pagination-total-count'>
                    {' '}
                    {totalApprox && '~'}
                    {formatNumber(totalResults)}{' '}
                  </Typography>
                  {totalSuffix && (
                    <Typography className='text-text-secondary' variant='body2' component='span'>
                      {totalSuffix}
                    </Typography>
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className='flex h-full'>
          <Divider orientation='vertical' />
          <IconButton
            className='p-4'
            variant='ghost'
            palette='tertiary'
            onClick={handlePrevClick}
            disabled={!handlePrevClick}
            data-testid='pagination-prev-page'
            aria-label={t`Previous page`}
          >
            <IconChevronLeftSuperSmall />
          </IconButton>
          <Divider orientation='vertical' />
          {nextLoading && (
            <div className='p-4'>
              <LoaderRound size='md' />
            </div>
          )}

          {!nextLoading && (
            <IconButton
              className='p-4'
              variant='ghost'
              palette='tertiary'
              onClick={handleNextClick}
              disabled={!handleNextClick}
              data-testid='pagination-next-page'
              aria-label={t`Next page`}
            >
              <IconChevronRightSuperSmall />
            </IconButton>
          )}
        </div>
      </div>
    )
  }
)
