import { useReactiveVar } from '@apollo/client/index.js'
import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useOnClickOutside } from 'usehooks-ts'
import { Dim } from '~/components/Dim'
import { MenuContent } from '~/components/Menu/MenuContent'
import { MenuState } from '~/components/Menu/menuUtils'
import { menuState } from '~/state'

export const Menu = (): ReactNode => {
  const state = useReactiveVar(menuState)

  const { pathname } = useLocation()
  useEffect(() => {
    if (state !== MenuState.MOBILE_OPEN) return
    menuState(MenuState.DEFAULT)
  }, [pathname])

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => menuState(MenuState.DEFAULT))

  return (
    <>
      <div className='relative z-30' ref={ref}>
        <MenuContent />
      </div>
      {state === MenuState.MOBILE_OPEN && <Dim />}
    </>
  )
}
