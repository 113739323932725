import { Trans, t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import { type CreatePrivatePersonInput, type PrivatePersonUpdateInput } from '@strise/types'
import { Button, IconAvatarAdd, Modal } from '@strise/ui-components'
import { useRef, useState } from 'react'
import type { ReactNode } from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { PrivatePersonForm } from '~/features/PrivatePersons/PrivatePersonForm/PrivatePersonForm'
import type { CreatePrivatePersonFormRef } from '~/features/PrivatePersons/PrivatePersonForm/PrivatePersonForm'
import { useCreatePrivatePersonMutation } from '~/graphqlOperations'

const isCreateInput = (data: CreatePrivatePersonInput | PrivatePersonUpdateInput): data is CreatePrivatePersonInput => {
  return !!data.address && !!data.name && !!data.nin
}

export const CreatePrivatePersonDialog = (): ReactNode => {
  const [isOpen, setIsOpen] = useState(false)
  const formRef = useRef<CreatePrivatePersonFormRef>(null)

  const [createPrivatePerson, { loading }] = useCreatePrivatePersonMutation({
    onCompleted: () => {
      toast.success(t`Private person created`)
      setIsOpen(false)
    }
  })

  // Trigger the form submission
  const handleSubmit = () => formRef.current?.submit()

  // Called on submit if the form is valid
  const onSubmit: SubmitHandler<CreatePrivatePersonInput | PrivatePersonUpdateInput> = async (data) => {
    if (isCreateInput(data)) {
      await createPrivatePerson({ variables: { input: data } })
      setIsOpen(false)
    }
  }

  return (
    <>
      <Button
        variant='contained'
        palette='secondary'
        className='h-auto'
        data-track='Portfolio / Create new individual'
        onClick={() => setIsOpen(true)}
      >
        <IconAvatarAdd className='size-4 mr-2' />
        {t`Create new individual`}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={<Trans>Create private person</Trans>}
        actions={
          <>
            <Button
              className='rounded-sm'
              variant='contained'
              data-track='Create Private Person / Cancel'
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              className='rounded-sm'
              variant='contained'
              palette='primary'
              data-track='Create Private Person / Create'
              onClick={handleSubmit}
              loading={loading}
            >
              <Trans>Create new individual</Trans>
            </Button>
          </>
        }
      >
        <PrivatePersonForm ref={formRef} handleClose={() => setIsOpen(false)} onSubmit={onSubmit} />
      </Modal>
    </>
  )
}
