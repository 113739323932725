import { type DivProps, copyTextToClipboard } from '@strise/react-utils'
import { ellipsis } from '@strise/ts-utils'
import { type KeyEntityMeta } from '@strise/types'
import { IconButton, IconCopy, Tooltip, Typography, cn } from '@strise/ui-components'
import { type ReactNode, useCallback, useMemo } from 'react'
import { extractIcon } from '~/utils/apiTable/apiTableValueBaseUtils'

interface EntityKeyMetaItemProps extends DivProps {
  dataTrack?: string
  expanded?: boolean
  item: KeyEntityMeta
  size?: 'sm' | 'md'
}

export const EntityKeyMetaItem = ({
  className,
  dataTrack,
  expanded = false,
  item,
  size = 'md',
  ...props
}: EntityKeyMetaItemProps): ReactNode => {
  const { content, copyValue, iconId, label, tooltip } = item

  const Icon = useMemo(() => (iconId ? extractIcon(iconId) : undefined), [iconId])

  const displayContent = expanded ? content : ellipsis(content, 20)

  const hasCopyAndTrackValue = Boolean(copyValue) && Boolean(dataTrack)

  const handleCopy = useCallback(() => {
    if (copyValue) {
      copyTextToClipboard(copyValue)
    }
  }, [copyValue])

  return (
    <div
      className={cn(
        'group/meta-item z-[1] rounded-sm border border-secondary-shade-15',
        size === 'sm' ? 'px-2 py-1' : 'px-4 py-3',
        className
      )}
      {...props}
    >
      <Tooltip content={tooltip ?? content}>
        <div className='relative flex items-center gap-2 overflow-hidden'>
          {hasCopyAndTrackValue && (
            <div className='invisible absolute size-full bg-gradient-to-l from-white from-20% to-transparent to-50% group-hover/meta-item:visible' />
          )}
          {Icon && <Icon className='shrink-0' size={size} />}
          <div className='flex min-w-0 flex-col flex-nowrap justify-start'>
            <Typography
              className='overflow-hidden truncate'
              variant={size === 'sm' ? 'aLabelExtraSmallBold' : 'aLabelSmallBold'}
              component='p'
            >
              {displayContent}
            </Typography>
            <Typography
              className='overflow-hidden truncate text-secondary-shade-40'
              variant={size === 'sm' ? 'aLabelExtraSmall' : 'aLabelSmall'}
            >
              {label}
            </Typography>
          </div>
          {hasCopyAndTrackValue && (
            <IconButton
              className={cn(
                'invisible absolute right-0 z-[2] ml-auto justify-end bg-secondary-shade-30 group-hover/meta-item:visible',
                size === 'sm' ? 'p-1.5' : 'p-2'
              )}
              variant='contained'
              palette='tertiary'
              onClick={handleCopy}
              data-track={dataTrack}
            >
              <IconCopy className='text-white' size={size} />
            </IconButton>
          )}
        </div>
      </Tooltip>
    </div>
  )
}
