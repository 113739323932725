import { type SetStateFn, setChildState } from '@strise/react-utils'
import { ellipsis } from '@strise/ts-utils'
import { AmsEventFeedbackKind } from '@strise/types'
import { Typography } from '@strise/ui-components'
import { type ReactNode } from 'react'
import { EventLink } from '~/components/EventLink'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { AmsEventFeedbackToggle } from '~/features/Events/AmsEventFeedbackToggle'
import { prefixPaywallTitle } from '~/features/Events/eventUtils'
import { ReviewItem } from '~/features/Review/ReviewItem'
import { type ReviewState } from '~/features/Review/reviewUtils'
import { reviewEventToEvent } from '~/features/Review/reviewValueTransformers'
import { type ReviewEventValueFragment } from '~/graphqlTypes'

export const ReviewEvent = ({
  entityId,
  item,
  reviewState,
  setReviewState
}: {
  entityId: string
  item: ReviewEventValueFragment
  reviewState: ReviewState
  setReviewState: SetStateFn<ReviewState>
}): ReactNode => {
  const features = useCurrentUserFeatures()

  if (!item.value) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const event = reviewEventToEvent(item.value)

  const amsEventFeedback = reviewState.amsEventFeedback
  const setAmsEventFeedback = setChildState(setReviewState, 'amsEventFeedback')

  const isAmsRelevant = reviewState.amsEventFeedback.some(
    (feedback) => feedback.event === event.id && feedback.entity === entityId
  )

  const handleClickAmsEventFeedback = (): void => {
    setAmsEventFeedback(() => {
      if (isAmsRelevant) {
        return amsEventFeedback.filter((feedback) => !(feedback.event === event.id && feedback.entity === entityId))
      }

      return [...amsEventFeedback, { entity: entityId, event: event.id, kind: AmsEventFeedbackKind.IsAms }]
    })
  }

  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <EventLink className='flex hover:no-underline' entityId={entityId} eventId={event.id} mentions={[entityId]}>
        <div className='group/event-container relative flex w-full items-center justify-between'>
          <div className='flex-1'>
            <Typography variant='aLabelBold' className='block group-hover/event-container:underline'>
              {ellipsis(prefixPaywallTitle(event), 200)}
            </Typography>
            {!!item.value.amsTopics.length && (
              <Typography variant='body2' className='mt-2 text-text-secondary'>
                {item.value.amsTopics.join(', ')}
              </Typography>
            )}
          </div>
        </div>
      </EventLink>
      {features.AMS_EVENT_FEEDBACK && (
        <AmsEventFeedbackToggle
          isAmsRelevant={!!isAmsRelevant}
          handleClickAmsEventFeedback={handleClickAmsEventFeedback}
        />
      )}
    </ReviewItem>
  )
}
