import { isObject } from 'lodash-es'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getByPath = (obj: object, path: ReadonlyArray<string | number>): any => {
  if (!path.length) return obj

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!!path.length && (obj === null || !isObject(obj))) return undefined
  const [head] = path
  const childValue = obj[head as keyof object] as unknown as object
  return getByPath(childValue, path.slice(1))
}
