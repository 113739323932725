import { Trans } from '@lingui/macro'
import { useSizeLimitedPersistentState } from '@strise/react-utils'
import { Typography } from '@strise/ui-components'
import { type ReactNode, forwardRef, useRef, useState } from 'react'
import { STORAGE_KEYS } from '~/constants'
import { FlyIcon } from '~/features/Review/FlyIcon'
import { ReviewCardAnchor } from '~/features/Review/ReviewCardAnchor'
import { ReviewCardFooter } from '~/features/Review/ReviewCardFooter'
import { ReviewCardHeader } from '~/features/Review/ReviewCardHeader'
import { ReviewCompanyCardContent } from '~/features/Review/ReviewCompanyCardContent'
import { ReviewCompanyCardSummaryContent } from '~/features/Review/ReviewCompanyCardSummaryContent'
import { ReviewCompanyStartButton } from '~/features/Review/ReviewCompanyStartButton'
import { ReviewCardContentView, type ReviewState, defaultReviewState } from '~/features/Review/reviewUtils'
import { useReviewCompany } from '~/features/Review/useReviewCompany'
import { type BaseReviewCompanyFragment, type TeamReviewSettingsV2Fragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

const getInitialCardView = (existingReview: boolean, reviewStateActive: boolean): ReviewCardContentView => {
  if (reviewStateActive) {
    return ReviewCardContentView.LOADING_REVIEW
  }
  if (existingReview) {
    return ReviewCardContentView.AFTER_REVIEW
  }
  return ReviewCardContentView.BEFORE_REVIEW
}

interface ReviewCompanyCardProps {
  baseCompany: BaseReviewCompanyFragment
  filterHeight: number | undefined
  teamId: string
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
}

export const ReviewCompanyCard = forwardRef<HTMLDivElement, ReviewCompanyCardProps>(
  ({ baseCompany, filterHeight, teamId, teamReviewSettings }, ref): ReactNode => {
    const stateKey = `${STORAGE_KEYS.reviewState2}-${baseCompany.id}-${teamId}`
    const [reviewState, setReviewState, resetReviewState] = useSizeLimitedPersistentState<ReviewState>(
      stateKey,
      defaultReviewState,
      {
        maxSize: 5,
        daysToLive: 7
      }
    )

    const existingReviews = baseCompany.reviews.edges
    const existingReview = existingReviews.length ? existingReviews[0]?.node : null

    const [cardView, setCardView] = useState<ReviewCardContentView>(() =>
      getInitialCardView(!!existingReview, !!reviewState.opened)
    )

    const [isReviewFinished, setIsReviewFinished] = useState<boolean>(false)

    const cardAnchorRef = useRef<HTMLDivElement>(null)

    const { alerts, errorSections, fetchReviewCompany, loadingSections, reviewCompany } = useReviewCompany(
      baseCompany,
      cardView,
      setCardView,
      reviewState,
      setReviewState
    )

    return (
      <div className='my-4 min-w-[700px]' ref={ref} data-id={TestIDs.Review.Card.root(baseCompany.name)}>
        <ReviewCardAnchor ref={cardAnchorRef} />
        <ReviewCardHeader company={baseCompany} filterHeight={filterHeight} />
        <div className='border-t-2 border-solid border-tertiary-main bg-background-paper py-4  min-h-[150px]'>
          {cardView === ReviewCardContentView.BEFORE_REVIEW && (
            <div className='grid grid-cols-[1fr_max-content] grid-rows-[1fr_min-content] items-center gap-4 px-4 pt-4'>
              <div className='col-[1/3] mb-1 self-center'>
                <FlyIcon />
              </div>
              <Typography className='text-text-secondary'>
                <Trans>This entity has not been reviewed yet.</Trans>
              </Typography>
              <ReviewCompanyStartButton
                setCardView={setCardView}
                fetchReviewCompany={fetchReviewCompany}
                data-track='Review / Start Review'
                data-id={TestIDs.Review.Card.startReviewButton}
                className='mt-0'
              >
                <Trans>Start Review</Trans>
              </ReviewCompanyStartButton>
            </div>
          )}

          {(cardView === ReviewCardContentView.IN_REVIEW || cardView === ReviewCardContentView.LOADING_REVIEW) && (
            <ReviewCompanyCardContent
              baseCompany={baseCompany}
              cardAnchorRef={cardAnchorRef}
              reviewCompanyData={reviewCompany}
              loadingSections={loadingSections}
              errorSections={errorSections}
              teamReviewSettings={teamReviewSettings}
              reviewState={reviewState}
              setReviewState={setReviewState}
              resetReviewState={resetReviewState}
              setIsReviewFinished={setIsReviewFinished}
              setCardView={setCardView}
              alerts={alerts}
            />
          )}

          {cardView === ReviewCardContentView.AFTER_REVIEW && (
            <ReviewCompanyCardSummaryContent
              setCardView={setCardView}
              review={existingReview ?? null}
              fetchReviewCompany={fetchReviewCompany}
            />
          )}
        </div>
        <ReviewCardFooter company={baseCompany} isReviewFinished={isReviewFinished} />
      </div>
    )
  }
)
