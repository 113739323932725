import { Trans, t } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { Button, type ButtonProps, Chip, LoaderRound } from '@strise/ui-components'
import type { FC, MouseEvent, ReactNode } from 'react'
import { ContactStriseToEnable } from '~/components/ContactStrise'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import {
  useDownloadPrivatePersonReview,
  useDownloadReview,
  useHasPrivatePersonReviewFile,
  useHasReviewFile
} from '~/features/Review/reviewUtils'
import { type PrivatePersonReviewFragment, type ReviewFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

const DefaultDownloadButton = (props: ButtonProps): ReactNode => (
  <Button
    variant='ghost'
    palette='primary'
    data-track='Download Risk assessment report'
    data-id={TestIDs.Review.Card.downloadPdfButton}
    {...props}
  >
    <Trans>View report</Trans>
  </Button>
)

const renderDownloadState = (
  hasFile: boolean,
  isOver1HourOld: boolean,
  loading: boolean,
  DownloadButton: FC<ButtonProps>,
  handleClick: (event: MouseEvent) => Promise<void>,
  props: ButtonProps & DivProps
) => {
  if (!hasFile && isOver1HourOld) {
    return <Trans>No report</Trans>
  }

  if (!hasFile) {
    return (
      <Chip
        label={
          <div className='flex items-center gap-x-2'>
            <Trans>Loading</Trans>
            <LoaderRound size='xs' />
          </div>
        }
      />
    )
  }

  return <DownloadButton onClick={handleClick} loading={loading} {...props} />
}

export const DownloadReviewPdf = ({
  DownloadButton = DefaultDownloadButton,
  hideContactSales,
  review,
  ...props
}: {
  DownloadButton?: FC<ButtonProps>
  hideContactSales?: boolean
  review: ReviewFragment
} & ButtonProps &
  DivProps): ReactNode => {
  const features = useCurrentUserFeatures()
  const { hasFile, isOver1HourOld } = useHasReviewFile(review)
  const { downloadReview, loading } = useDownloadReview()

  const handleClick = async (event: MouseEvent): Promise<void> => {
    if (!hasFile) return
    event.preventDefault()
    await downloadReview(review.id)
  }

  if (!features.REVIEW_PDF && !hideContactSales) {
    return <ContactStriseToEnable intention={t`enable the Review PDF feature`} {...props} />
  }

  if (!features.REVIEW_PDF) {
    return null
  }

  return renderDownloadState(hasFile, isOver1HourOld, loading, DownloadButton, handleClick, props)
}

export const DownloadPrivatePersonReviewPdf = ({
  DownloadButton = DefaultDownloadButton,
  hideContactSales,
  review,
  ...props
}: {
  DownloadButton?: FC<ButtonProps>
  hideContactSales?: boolean
  review: PrivatePersonReviewFragment
} & ButtonProps &
  DivProps): ReactNode => {
  const features = useCurrentUserFeatures()
  const { hasFile, isOver1HourOld } = useHasPrivatePersonReviewFile(review)
  const { downloadReview, loading } = useDownloadPrivatePersonReview()

  const handleClick = async (event: MouseEvent): Promise<void> => {
    if (!hasFile) return
    event.preventDefault()
    await downloadReview(review.id)
  }

  if (!features.REVIEW_PDF && !hideContactSales) {
    return <ContactStriseToEnable intention={t`enable the Review PDF feature`} {...props} />
  }

  if (!features.REVIEW_PDF) {
    return null
  }

  return renderDownloadState(hasFile, isOver1HourOld, loading, DownloadButton, handleClick, props)
}
