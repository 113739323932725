import { ContentLanguage, DisplayLanguage } from '@strise/types'

export const displayLanguageToTitle: Partial<Record<DisplayLanguage, string>> = {
  [DisplayLanguage.English]: 'English',
  [DisplayLanguage.Norwegian]: 'Norsk',
  [DisplayLanguage.Swedish]: 'Svenska',
  [DisplayLanguage.Danish]: 'Dansk'
}

export const languageToCountryCode: Partial<Record<ContentLanguage, string>> = {
  [ContentLanguage.Danish]: 'dk',
  [ContentLanguage.English]: 'gb',
  [ContentLanguage.Swedish]: 'se',
  [ContentLanguage.Norwegian]: 'no',
  [ContentLanguage.German]: 'de',
  [ContentLanguage.Spanish]: 'es',
  [ContentLanguage.French]: 'fr',
  [ContentLanguage.Finnish]: 'fi'
}
