import { Trans, t } from '@lingui/macro'
import { extractAddress, hasAddressValues } from '@strise/app-shared'
import { IconInfo, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { PepRole } from '~/features/PepAndSanctions/PepRole'
import type { AddressFragment, PepInfoFragment } from '~/graphqlTypes'

export const PepPersonAddressInfo = ({ address }: { address: AddressFragment }): ReactNode => {
  if (!hasAddressValues(address)) return null

  return (
    <div className='flex items-center'>
      <IconInfo className='mr-1 text-text-secondary' size='md' />
      <Typography className='text-text-secondary' variant='body2'>
        {extractAddress(address)}
      </Typography>
    </div>
  )
}

export const PepRelation = ({ relation }: { relation: PepInfoFragment['relations'][number] }): ReactNode => {
  return (
    <div>
      <div className='flex w-full items-center justify-between'>
        <Typography variant='body1'>
          <Trans>
            RCA due to {relation.relationDescription} {relation.relatedPep.name}
          </Trans>
        </Typography>
      </div>
      <PepPersonAddressInfo address={relation.relatedPep.address} />
      {!relation.relatedPep.roles.length && <Typography>{t`Unknown roles`}</Typography>}

      {relation.relatedPep.roles.map((role, index) => (
        <div key={index} className='flex gap-2 pl-1'>
          <Typography variant='body2' className='mt-1'>
            &#8627;
          </Typography>
          <PepRole role={role} />
        </div>
      ))}
    </div>
  )
}
