import { type SetStateFn, createContext } from '@strise/react-utils'
import { type SettingsTab } from '~/features/Settings/UserSettings/components/SettingsTab'
import type { TeamSettingsState, UserSettingsState } from '~/features/Settings/types'

interface SettingsContextWrapperProps {
  activeTab: SettingsTab | null
  handleSaveSettings: () => void
  hasUnsavedTeamChanges: boolean
  hasUnsavedUserChanges: boolean
  setActiveTab: (settingsTab: SettingsTab) => void
  setIsSettingsOpen: (isOpen: boolean) => void
  setTabValidationErrors: SetStateFn<Partial<Record<SettingsTab, boolean>>>
  setTeamSettingsState: SetStateFn<TeamSettingsState>
  setUserSettingsState: SetStateFn<UserSettingsState>
  settingsMutationLoading: boolean
  tabValidationErrors: Partial<Record<SettingsTab, boolean>>
  teamSettingsState: TeamSettingsState
  unsavedTeamChangesMap: Record<SettingsTab, boolean>
  unsavedUserChangesMap: Record<SettingsTab, boolean>
  userSettingsState: UserSettingsState
}

export const SettingsModalContext = createContext<SettingsContextWrapperProps>()
