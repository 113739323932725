import { cn } from '../../utils/className'
import { type DivProps } from '@strise/react-utils'
import { type VariantProps, cva } from 'class-variance-authority'
import { type ReactNode, forwardRef } from 'react'

const loaderRoundVariants = cva(
  'block shrink-0 animate-spin rounded-full border-4 align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]',
  {
    variants: {
      size: {
        xs: 'size-3 border-[3px]',
        sm: 'size-4 border-[3px]',
        md: 'size-6',
        lg: 'size-10'
      },
      palette: {
        primary: 'border-primary-shade-5 border-r-primary-main',
        secondary: 'border-secondary-shade-30 border-r-secondary-main',
        tertiary: 'border-tertiary-main border-r-tertiary-shade-30',
        white: 'border-secondary-shade-60 border-r-white'
      }
    },
    defaultVariants: {
      size: 'lg',
      palette: 'secondary'
    }
  }
)

export interface LoaderRoundProps extends DivProps, VariantProps<typeof loaderRoundVariants> {}

export const LoaderRound = forwardRef<HTMLDivElement, LoaderRoundProps>(
  ({ className, palette, size, ...props }, ref): ReactNode => {
    return (
      <div ref={ref} className={cn(loaderRoundVariants({ palette, size }), className)} role='status' {...props}>
        <span className='sr-only'>Loading...</span>
      </div>
    )
  }
)
LoaderRound.displayName = 'LoaderRound'
