import { type SetStateFn } from '@strise/react-utils'
import { Divider, IconButton, IconCross, IconFilter, Typography, cn } from '@strise/ui-components'
import { sum } from 'lodash-es'
import { useToggle } from 'usehooks-ts'
import { ActiveFilters } from '~/components/Filter/ActiveFilters'
import { sidepanelEventsFilters } from '~/components/Sidepanel/sidepanelEventsFilters'
import { type UserSettingsFragment } from '~/graphqlTypes'
import { useIsMobile } from '~/utils/hooks'

export const EventsFilter = ({
  saveSettings,
  settingsState
}: {
  saveSettings: SetStateFn<UserSettingsFragment>
  settingsState: UserSettingsFragment
}) => {
  const [edit, toggleEdit] = useToggle(false)

  const filteredFilters = Object.values(sidepanelEventsFilters).filter((value) => !!value.totalSelected(settingsState))
  const isMobile = useIsMobile()
  const totalActiveFiltersCount = sum(filteredFilters.map((filter) => filter.totalSelected(settingsState)))

  return (
    <div
      className={cn(
        'flex min-h-[theme(height.sub-header)] items-center border-y border-solid border-divider',
        edit ? 'bg-secondary-shade-90 text-secondary-contrastText' : 'bg-none text-text-primary'
      )}
      data-id='Sidepanel / Event Filter'
    >
      <div className='flex'>
        <IconButton
          className={cn(
            'h-sub-header w-[theme(height.sub-header)] shrink-0 self-start',
            edit ? 'bg-secondary-shade-70' : ''
          )}
          variant={edit ? 'contained' : 'ghost'}
          palette={edit ? 'secondary' : 'tertiary'}
          onClick={toggleEdit}
          data-track={edit ? 'Sidepanel Events / Filter / Close' : 'Sidepanel Events / Filter / Open'}
        >
          {edit ? <IconCross className='text-secondary-shade-90' /> : <IconFilter />}
        </IconButton>
        {isMobile && !edit && !!totalActiveFiltersCount && (
          <div className='absolute m-1 size-5 rounded-full bg-primary-main text-center text-background-paper'>
            <Typography variant='body2' className='leading-5'>
              {totalActiveFiltersCount}
            </Typography>
          </div>
        )}
      </div>
      {!edit && <Divider className='mr-4 self-stretch' orientation='vertical' />}

      {!edit && !isMobile && (
        <div className='flex min-h-[theme(height.sub-header)] flex-wrap items-center'>
          <ActiveFilters
            toggleEdit={toggleEdit}
            filters={filteredFilters}
            setValue={saveSettings}
            value={settingsState}
          />
        </div>
      )}

      {edit && (
        <div className='flex min-h-[theme(height.sub-header)] grow flex-wrap items-center'>
          {Object.values(sidepanelEventsFilters).map(({ EditComponent }, index) => (
            <div className='max-w-[250px] grow' key={index}>
              <EditComponent value={settingsState} setValue={saveSettings} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
