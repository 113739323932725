import { t } from '@lingui/macro'
import { type FlagEntity, ReactRouterLink, extractIsActive } from '@strise/app-shared'
import { useContext } from '@strise/react-utils'
import { IconLock, IconOpenInTab, Tooltip, Typography, cn } from '@strise/ui-components'
import type { MouseEvent } from 'react'
import { forwardRef } from 'react'
import { EntityFlag } from '~/components/EntityFlag'
import { EntityIcon } from '~/components/EntityIcon'
import { type EntityLinkProps, useEntityUrl, useNavigateEntity } from '~/components/EntityLink/entityLinkUtils'
import { EntityMetaItems } from '~/components/EntityMeta/EntityMetaItems'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { RecentlyVisitedEntitiesContext } from '~/contexts/RecentlyVisitedEntitiesContext/RecentlyVisitedEntitiesContext'
import { SanctionIcon } from '~/features/PepAndSanctions/SanctionIcon'
import { hasEntityAccess } from '~/utils/entity'
import { useAltClickToOmni } from '~/utils/hooks'

export const EntityLink = forwardRef<HTMLDivElement, EntityLinkProps & { entity: FlagEntity }>(
  (
    {
      children,
      className,
      disableOnNoAccess,
      entity,
      iconWrapperProps,
      maxLength = Number.POSITIVE_INFINITY,
      noFlags,
      noLifeStatus,
      noLink,
      noTooltip,
      onClick,
      openInNewTab,
      sanctionsLink,
      sidepanelTab,
      transparent,
      withIcon,
      wrapperProps,
      ...props
    }: EntityLinkProps & { entity: FlagEntity },
    ref
  ) => {
    const features = useCurrentUserFeatures()

    const entityUrl = useEntityUrl(entity)
    const navigateEntity = useNavigateEntity(sidepanelTab)
    const handleOmniClick = useAltClickToOmni(entity.id)
    const { extractIsEntityRecentlyVisited } = useContext(RecentlyVisitedEntitiesContext)

    if (!entity.id) return null

    const isRecentlyVisited = extractIsEntityRecentlyVisited(entity.id)

    const handleClick = (event: MouseEvent<HTMLElement>): void => {
      event.preventDefault()
      event.stopPropagation()

      if (event.altKey) {
        handleOmniClick(event)
        return
      }

      if (onClick) onClick(event)
      const newTab = openInNewTab || event.ctrlKey || event.metaKey
      navigateEntity(entity.id, newTab)
    }

    const isActive = noLifeStatus || extractIsActive(entity)

    const showTooltip = !noTooltip

    const baseProps = {
      ref,
      className: cn('flex min-h-flags-chip items-center', !isActive && 'text-text-secondary line-through')
    }

    const tooltipContent = features.GLOBAL_ENTITIES
      ? t`You need to fetch global owners to gain access to this entity`
      : t`Contact Strise to upgrade your plan to gain access to this entity`

    const link =
      !hasEntityAccess(entity) && disableOnNoAccess ? (
        <div {...baseProps}>
          <Typography className={cn('text-text-primary', className)} {...props} onClick={handleOmniClick}>
            {children ?? entity.name}
          </Typography>
          <Tooltip content={tooltipContent}>
            <div>
              <IconLock size='md' className='ml-1' />
            </div>
          </Tooltip>
        </div>
      ) : (
        <Tooltip
          content={
            showTooltip ? (
              <div className='flex flex-col gap-2 p-1'>
                {(entity.name?.length ?? 0) > maxLength && <Typography>{entity.name}</Typography>}
                <EntityMetaItems entity={entity} />
              </div>
            ) : null
          }
        >
          {noLink ? (
            <Typography
              {...baseProps}
              className={cn('text-text-primary', baseProps.className, className)}
              onClick={handleOmniClick}
              component='div'
              {...props}
            >
              {children ?? entity.name}
            </Typography>
          ) : (
            <ReactRouterLink
              // This to prop is only for accessibility. The real navigation is done in the onClick handler
              to={entityUrl}
              onClick={handleClick}
              {...baseProps}
              className={cn(
                { 'text-text-link': !isRecentlyVisited && !transparent },
                { 'text-accent-blue-shade-10': !isRecentlyVisited && transparent },
                { 'text-text-visitedLink': isRecentlyVisited && !transparent },
                { 'text-accent-purple-shade-20': isRecentlyVisited && transparent },
                baseProps.className,
                className
              )}
              {...props}
            >
              {children ?? entity.name}
              {openInNewTab && <IconOpenInTab size='sm' className='ml-2' />}
            </ReactRouterLink>
          )}
        </Tooltip>
      )

    const linkWithMeta = (
      <span {...wrapperProps} className={cn('inline-flex items-center', wrapperProps?.className)}>
        {withIcon && (
          <EntityIcon entity={entity} {...iconWrapperProps} className={cn('mr-2 block', iconWrapperProps?.className)} />
        )}

        {link}
        {!noFlags && (
          <>
            <EntityFlag className='ml-1' entityOrId={entity} />
            <SanctionIcon className='ml-1' entityId={entity.id} sanctionsLink={sanctionsLink} />
          </>
        )}
      </span>
    )

    return linkWithMeta
  }
)
