import { Trans } from '@lingui/macro'
import { UserAvatar, useCurrentUser } from '@strise/app-shared'
import { Button, IconPlus } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { useTeam } from '~/contexts/TeamContext/TeamContext'
import { InviteUsers } from '~/features/InviteUsers/InviteUsers'

const InviteNewUsersButtonStartIcon = (): ReactNode => {
  const currentUser = useCurrentUser()
  return (
    <div className='relative flex items-center'>
      <IconPlus className='absolute left-[12px] mr-3' size='md' />
      <UserAvatar className='mr-3 size-10 rounded-full opacity-10' user={currentUser} />
    </div>
  )
}

export const ActivityInviteNewUsers = (): ReactNode => {
  const team = useTeam()

  const [isInviteUserOpen, setIsInviteUserOpen] = useState<boolean>(false)

  const openInviteUserModal = (): void => setIsInviteUserOpen(true)

  return (
    <>
      <Button
        startIcon={<InviteNewUsersButtonStartIcon />}
        className='pl-0 hover:bg-transparent active:bg-transparent'
        palette='primary'
        variant='ghost'
        data-track='Activity View / Most Active Members / Invite your colleagues'
        onClick={openInviteUserModal}
        data-id='Activity View / Invite your colleagues button'
      >
        <Trans>Invite your colleagues</Trans>
      </Button>

      {isInviteUserOpen && <InviteUsers team={team} setOpen={setIsInviteUserOpen} />}
    </>
  )
}
