import { t } from '@lingui/macro'
import { Button, DataTable, Skeleton, Typography, cn, createColumnHelper } from '@strise/ui-components'
import { useMemo, useState } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { EntityMetaItems } from '~/components/EntityMeta/EntityMetaItems'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { useSidepanelEntityKeyDetailsQuery } from '~/graphqlOperations'
import { type BaseEntityLikeFragment, type SidepanelEntityKeyDetailsQuery } from '~/graphqlTypes'
import { hasDataSources } from '~/utils/entity'

const LoadingSkeleton = () => (
  <div className='flex flex-col gap-2'>
    <Skeleton className='h-5 w-96' />
    <Skeleton className='h-5 w-96' />
    <Skeleton className='h-5 w-96' />
  </div>
)

const CellWithFallback = ({ value }: { value: string | null }) => (
  <span className={cn({ 'text-text-secondary': !value })}>{value || 'N/A'}</span>
)

const columnHelper = createColumnHelper<{
  address: string | null
  primaryIndustry: string | null
  shareholders: string | null
}>()

const extractColumns = () => {
  return [
    columnHelper.accessor((info) => info.address, {
      header: t`Address`,
      cell: ({ getValue }) => <CellWithFallback value={getValue()} />
    }),
    columnHelper.accessor((info) => info.primaryIndustry, {
      header: t`Industries`,
      cell: ({ getValue }) => <CellWithFallback value={getValue()} />
    }),
    columnHelper.accessor((info) => info.shareholders, {
      header: t`Shareholders`,
      cell: ({ getValue }) => <CellWithFallback value={getValue()} />
    })
  ]
}

const CompanyDetails = ({ entity }: { entity: SidepanelEntityKeyDetailsQuery['entity'] | undefined }) => {
  const columns = useMemo(() => extractColumns(), [entity])

  if (!entity || entity.__typename !== 'Company') return null

  const data = [
    {
      address: entity.companyKeyDetails.address,
      primaryIndustry: entity.companyKeyDetails.primaryIndustry,
      shareholders:
        entity.companyKeyDetails.shareholders.reduce((acc, curr, index, array) => {
          const item = `${curr.name} (${curr.share}%)`
          const separator = index < array.length - 1 ? ', ' : ''
          return acc + item + separator
        }, '') || null
    }
  ]

  return (
    <DataTable
      columns={columns}
      data={data}
      orientation='vertical'
      bodyCellClassName='p-0 text-left'
      headerCellClassName='p-0 w-1/4'
      variant='backplate'
      className='w-full'
      getRowClassName={() => 'border-none p-0 pointer-events-none'}
    />
  )
}

const MergeEntityDetails = ({
  dataTrackId,
  entity,
  isLast,
  isSelected,
  onClick
}: {
  dataTrackId: string
  entity: BaseEntityLikeFragment
  isLast: boolean
  isSelected: boolean
  onClick: () => void
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { data, loading } = useSidepanelEntityKeyDetailsQuery({
    variables: {
      entity: entity.id,
      shareholderLimit: 3
    }
  })

  return (
    <div className={cn(!isLast && 'border-b border-b-gray-15')}>
      <button
        type='button'
        className={cn(
          'pt-2 border-2 border-transparent rounded-md w-full group relative hover:border-blue-5',
          isSelected && 'border-2 border-blue-40 bg-blue-5 hover:border-blue-40'
        )}
        onClick={onClick}
        data-track={`Merge Entity / Select / ${dataTrackId}`}
      >
        <div className='flex flex-col gap-2 px-3 pb-2'>
          <Typography className='absolute top-2 right-3 opacity-0 group-hover:opacity-100 text-sm text-text-link'>
            {t`Click to select`}
          </Typography>

          <EntityLink entity={entity} withIcon noTooltip openInNewTab />
          <EntityMetaItems entity={entity} />
          {isOpen && (loading ? <LoadingSkeleton /> : <CompanyDetails entity={data?.entity} />)}
        </div>
        <div className='flex justify-between px-3 gap-2 w-full group-hover:bg-blue-5/50'>
          <Button
            variant='ghost'
            palette='primary'
            className='text-sm p-0 h-auto '
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(!isOpen)
            }}
            data-track='Merge Entity / Details Toggle'
          >
            {isOpen ? t`Hide details` : t`View more details`}
          </Button>
          {hasDataSources(entity) && <DataSources dataSources={entity.dataSources.baseInfo} className='p-0' />}
        </div>
      </button>
    </div>
  )
}

export default MergeEntityDetails
