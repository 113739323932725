import { Trans } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { Button, type ButtonProps, Typography, cn } from '@strise/ui-components'
import type { ChangeEvent, DragEvent, ReactElement, ReactNode } from 'react'
import { useRef, useState } from 'react'

export interface DragAndDropFileProps extends ButtonProps {
  setFile: SetStateFn<File | null>
  supportedFilesText?: string | ReactElement
  uploadIcon?: ReactElement
}

export const DragAndDropFile = ({
  className,
  setFile,
  supportedFilesText,
  uploadIcon,
  ...props
}: DragAndDropFileProps): ReactNode => {
  const [isDraggedOver, setIsDraggedOver] = useState(false)
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  const handleClick = (): void => hiddenFileInput.current?.click()

  const handleDrop = (event: DragEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    const file = event.dataTransfer.files[0] ?? null
    setFile(file)
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault()
    const file = (event.target.files ?? [])[0] ?? null
    setFile(file)
  }

  const handleDragOver = (event: DragEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    setIsDraggedOver(true)
  }

  const handleDragExit = (event: DragEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    setIsDraggedOver(false)
  }

  return (
    <>
      <Button
        variant='outlined'
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragExit={handleDragExit}
        className={cn(
          className,
          `h-52 w-full items-center justify-center gap-4 border-dashed border-primary-main font-medium ${
            isDraggedOver ? 'bg-blue-5' : ''
          }`
        )}
        data-track='Drag and drop file'
        {...props}
      >
        {uploadIcon && <div>{uploadIcon}</div>}
        {isDraggedOver ? (
          <Typography>
            <Trans>Drop the file</Trans>
          </Typography>
        ) : (
          <Typography>
            <span className='text-text-link'>
              <Trans>Browse files</Trans>
            </span>{' '}
            <Trans>or drag and drop</Trans>
          </Typography>
        )}
      </Button>
      <input type='file' ref={hiddenFileInput} onChange={handleFileChange} style={{ display: 'none' }} />
      {supportedFilesText && <Typography className='mt-2 text-text-secondary'>{supportedFilesText}</Typography>}
    </>
  )
}
