import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'
import { scoreToColor } from '~/components/Score/scoreUtils'
import { creditScoreColorIntervals } from '~/features/CreditReport/creditScore'

const scoreStep = 1

const useSmoothedScore = (value: number | null | undefined, maxValue: number, disableAnimation?: boolean): number => {
  const [currentValue, setCurrentValue] = useState(0)
  const ascending = useRef(true)
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (!value) return

    ascending.current = currentValue <= value
    const change = (): void => {
      setCurrentValue((prevScore) => {
        if (disableAnimation) return value

        if (ascending.current) {
          if (prevScore < value && prevScore < maxValue) {
            timeoutRef.current = setTimeout(change, 1)
            return prevScore + scoreStep
          }
          return prevScore
        }
        if (prevScore > value && prevScore > 0) {
          timeoutRef.current = setTimeout(change, 1)
          return prevScore - scoreStep
        }
        return prevScore
      })
    }
    timeoutRef.current = setTimeout(change, 1)
    return () => {
      if (timeoutRef.current !== null) clearTimeout(timeoutRef.current)
    }
  }, [value])

  return currentValue
}

const Arc = ({
  color,
  from,
  max,
  radius,
  to,
  width
}: {
  color: string
  from: number
  max: number
  radius: number
  to: number
  width: number
}): ReactNode => {
  const angleRange = 2 * Math.PI
  // start from 12 o clock
  const angleOffset = 1.5 * Math.PI

  const startAngle = angleOffset + (angleRange * from) / max
  // Subtract a small number to avoid overlapping arcs when to === max
  const endAngle = angleOffset + (angleRange * to) / max - 0.0001

  const circleCenter = radius + width / 2

  const arcXStart = circleCenter + Math.cos(startAngle) * radius
  const arcYStart = circleCenter + Math.sin(startAngle) * radius
  const arcXEnd = circleCenter + Math.cos(endAngle) * radius
  const arcYEnd = circleCenter + Math.sin(endAngle) * radius

  const largeArcFlag = endAngle - startAngle >= Math.PI ? 1 : 0

  const moveTo = `M${arcXStart},${arcYStart}`
  const arcTo = `A${radius},${radius} 0 ${largeArcFlag} 1 ${arcXEnd},${arcYEnd}`

  const d = `${moveTo} ${arcTo}`

  return <path fill='none' stroke={color} strokeWidth={width} d={d} />
}

export const Score = ({
  disableAnimation,
  isFinnish,
  maxScore = 100,
  mixedColors,
  radius,
  score,
  width
}: {
  disableAnimation?: boolean
  isFinnish: boolean
  maxScore?: number
  mixedColors?: boolean
  radius: number
  score?: number | null
  width: number
}): ReactNode => {
  const currentScore = useSmoothedScore(score, maxScore, disableAnimation)

  const svgWidth = 2 * (radius + width / 2)

  return (
    <svg width={svgWidth} height={svgWidth}>
      <circle
        cx={radius + width / 2}
        cy={radius + width / 2}
        r={radius}
        fill='none'
        stroke={scoreToColor(score, 10, isFinnish)}
        strokeWidth={width}
      />
      {mixedColors ? (
        <>
          {creditScoreColorIntervals.map((interval, index) => {
            if (currentScore < interval.from) return null

            return (
              <Arc
                key={index}
                from={interval.from}
                to={Math.min(currentScore, interval.to + 1)}
                max={maxScore}
                width={width}
                radius={radius}
                color={interval.color[50]}
              />
            )
          })}
        </>
      ) : (
        <Arc
          from={0}
          to={Math.min(currentScore, 100)}
          max={maxScore}
          width={width}
          radius={radius}
          color={scoreToColor(currentScore, 50, isFinnish)}
        />
      )}
    </svg>
  )
}
