import { createContext, useContext } from 'react'

export enum RenderContextType {
  Review = 'review',
  Sidepanel = 'sidepanel'
}

export const RenderContext = createContext<RenderContextType | null>(null)

export const useIsReview = (): boolean => {
  return useContext(RenderContext) === RenderContextType.Review
}
