import { useReactiveVar } from '@apollo/client/index.js'
import { Trans, t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import { type DivProps } from '@strise/react-utils'
import { reduceFlatten } from '@strise/ts-utils'
import { Alert, IconButton, IconPenBox, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { useToggle } from 'usehooks-ts'
import { DataSourceTooltip } from '~/components/DataSourceTooltip'
import { SparkLineFigure } from '~/components/SparkLine/SparkLineFigure'
import { SparkLineHighlightsEditModal } from '~/components/SparkLine/SparkLineHighlightsEdit'
import { type FinancialId } from '~/features/Financials/financialTypes'
import { useDefaultHighlightedFinancials, useHighlightedFinancials } from '~/features/Financials/financials'
import { languageToCurrencyMap } from '~/features/Settings/TeamFeatures/utils/finacialFieldsUtils'
import { type CountryFragment, type FinancialsFragment } from '~/graphqlTypes'
import { financialFieldsMap } from '~/state'
import { TestIDs } from '~/utils/testIDs'

interface SparkLineHighlightsProps extends DivProps {
  country?: CountryFragment | null
  disableEdit?: boolean
  financials: FinancialsFragment
  settingsKey: 'grow' | 'sidepanel'
}

export const SparkLineHighlights = ({
  className,
  country,
  disableEdit = false,
  financials,
  settingsKey,
  ...props
}: SparkLineHighlightsProps): ReactNode => {
  const [edit, toggleEdit] = useToggle(false)
  const [highlighted, saveHighlighted] = useHighlightedFinancials(settingsKey)
  const highlightedFields = highlighted.map(({ id }) => id)

  const save = (value: FinancialId[]): void => {
    saveHighlighted(value)
    toggleEdit()
    toast.success(t`Financial favourites updated`)
  }

  const financialsRowsMap = useMemo(() => {
    const mergedFinancials = [
      ...financials.sections.flatMap((sections) => sections.rows),
      ...financials.compare.flatMap((sections) => sections.rows)
    ]

    return mergedFinancials
      .map((row) => {
        return { [row.fieldInfo.id]: row }
      })
      .reduce(reduceFlatten, {})
  }, [financials])

  const financialsFieldMapState = useReactiveVar(financialFieldsMap)
  const defaultHighlightedFinancials = useDefaultHighlightedFinancials()

  const lastMeta = financials.meta[0]

  if (!lastMeta) return null

  const lastPeriodTitle = lastMeta.periodTitle
  const lastCurrency = lastMeta.currency

  const expectedCurrency = country ? languageToCurrencyMap[country.kind] : null
  const isUnusualCurrency = !!expectedCurrency && expectedCurrency !== lastMeta.currency

  return (
    <div className={className} {...props}>
      {!disableEdit && edit && (
        <SparkLineHighlightsEditModal
          financialsRowsMap={financialsRowsMap}
          highlightedFields={highlightedFields}
          country={country}
          onConfirm={save}
          onCancel={toggleEdit}
        />
      )}

      <div className='mb-2 flex items-end justify-between text-text-secondary'>
        <div className='flex items-center'>
          <Typography variant='aLabelSmall' className='tabular-nums'>
            <Trans>In {lastCurrency} ‘000</Trans> • {lastPeriodTitle}
          </Typography>
          {isUnusualCurrency && (
            <Alert className='ml-2' variant='info' size='sm'>
              <Trans>Currency differs from registered country currency</Trans>
            </Alert>
          )}
        </div>
        {!disableEdit && (
          <DataSourceTooltip content={t`Edit favorite financials`} side='left'>
            <IconButton
              onClick={toggleEdit}
              data-track='Sidepanel / Financials / Edit sparklines'
              data-id={TestIDs.SidePanel.Financials.editButton}
            >
              <IconPenBox />
            </IconButton>
          </DataSourceTooltip>
        )}
      </div>
      <div className='-mx-2 -mb-2 flex flex-wrap'>
        {highlighted.map((field, index) => {
          const defaultId = defaultHighlightedFinancials[index]
          const selectedFieldInfo =
            financialsFieldMapState[field.id] ?? (defaultId && financialsFieldMapState[defaultId])

          if (!selectedFieldInfo) return null

          const matchingInternationalFieldInfo = selectedFieldInfo.matchingInternationalFields.find(
            (f) => f.countryV2?.kind === country?.kind
          )
          const fieldInfo = matchingInternationalFieldInfo ?? selectedFieldInfo

          const fieldInfoValues = (financialsRowsMap[fieldInfo.id]?.values ?? []) as number[]
          return (
            <div className='legacy-xs:w-1/2 legacy-lg:w-1/3' key={index}>
              <div className='mx-2 mb-2'>
                <SparkLineFigure figures={fieldInfoValues} fieldInfo={fieldInfo} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
