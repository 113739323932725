import { useApolloClient } from '@apollo/client/index.js'
import { t } from '@lingui/macro'
import { ReviewValueStatusKind } from '@strise/types'
import { IconButton, IconRefresh, Tooltip } from '@strise/ui-components'
import { updateReviewIdvRolesCache } from '~/features/IDVCheck/idvCacheUtils'
import { ReviewItem } from '~/features/Review/ReviewItem'
import { ReviewIdvRole } from '~/features/Review/items/ReviewIdvRole'
import { useIdvRolesLazyQuery } from '~/graphqlOperations'
import { type ReviewIdvRoleValueFragment } from '~/graphqlTypes'

export const ReviewIdvRoles = ({ companyId, items }: { companyId: string; items: ReviewIdvRoleValueFragment[] }) => {
  const apolloClient = useApolloClient()

  const [fetch, { loading }] = useIdvRolesLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      updateReviewIdvRolesCache({
        cache: apolloClient.cache,
        companyId,
        idvRoles: data.company.idvRoles
      })
    }
  })

  const handleRefetch = (): void => {
    fetch({
      variables: {
        entity: companyId
      }
    })
  }

  if (!items.length) {
    return (
      <ReviewItem
        reviewMeta={{
          status: ReviewValueStatusKind.Ok,
          reason: t`No IDV Roles`,
          __typename: 'ReviewValueMeta' as const
        }}
      />
    )
  }

  return (
    <>
      <div className='flex justify-end pr-4 pt-2'>
        <Tooltip content={t`Refresh`}>
          <div>
            <IconButton
              data-track='IDV Check / Refresh'
              variant='ghost'
              onClick={handleRefetch}
              loading={loading}
              loaderProps={{ size: 'sm' }}
            >
              <IconRefresh size='sm' />
            </IconButton>
          </div>
        </Tooltip>
      </div>
      {items.map((item) => {
        return (
          <ReviewIdvRole
            key={item.value?.person.id}
            companyId={companyId}
            item={item}
            refetchIdvRoles={handleRefetch}
          />
        )
      })}
    </>
  )
}
