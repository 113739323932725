import type { ReactNode } from 'react'
import { ChatFallback } from '~/components/Chat'
import { useLoadChat } from '~/utils/intercom'
import { useTrackUser } from '~/utils/tracking'

export const IdentifyServices = ({ children }: { children: ReactNode }): ReactNode => {
  useTrackUser()
  useLoadChat()

  return (
    <>
      {children}
      <ChatFallback />
    </>
  )
}
