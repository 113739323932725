import { Trans, t } from '@lingui/macro'
import { SortableTable, toast, useCurrentUser, useIsSupervisor } from '@strise/app-shared'
import { FeatureCategories } from '@strise/app-shared/src/components/Features/FeatureCategories'
import { type FeaturesMap, useUpdateCurrentUserFeatures } from '@strise/app-shared/src/i18n'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import { compareEmailDomains, removeItemFromArray, replaceItemFromArray, validateEmail } from '@strise/ts-utils'
import { FeatureCategoryKind, type Team } from '@strise/types'
import {
  Button,
  IconButton,
  IconGarbage,
  IconSend,
  Input,
  type InputProps,
  Link,
  Typography
} from '@strise/ui-components'
import { Accordion, ConfirmDialog } from '@strise/ui-components-legacy'
import { uniq } from 'lodash-es'
import type { ChangeEvent, FormEvent, ReactNode } from 'react'
import { useState } from 'react'
import { useIsTeamManager } from '~/contexts/TeamContext/TeamContext'
import { DisabledFeatureMessage } from '~/features/Features/DisabledFeatureMessage'
import { useSetTeamUsers } from '~/features/Users/teamUsers'
import { useAddUsersToTeamMutation, useRequestUsersToTeamMutation } from '~/graphqlOperations'
import {
  type AddUsersToTeamMutation,
  type FeatureFragment,
  type RequestUsersToTeamMutation,
  type TeamSimpleUsersFragment
} from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

const sanitizeEmail = (email: string): string => email.trim().toLowerCase()

interface InvitationUser {
  email: string
  name: string
}

const EditName = ({
  name,
  setName,
  ...props
}: { name: string; setName: (n: string) => void } & InputProps): ReactNode => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => setName(event.target.value)

  return <Input variant='outlined' value={name} onChange={handleChange} {...props} />
}

interface InviteUserFeaturesProps {
  currentFeatures: FeatureFragment[]
  defaultFeaturesState: FeaturesMap
  featuresState: FeaturesMap
  isUpdated: boolean
  setFeaturesState: SetStateFn<FeaturesMap>
}

const InviteUserFeatures = ({
  className,
  currentFeatures,
  defaultFeaturesState,
  featuresState,
  isUpdated,
  setFeaturesState,
  ...props
}: InviteUserFeaturesProps & DivProps): ReactNode => {
  const [openSection, setOpenSection] = useState<boolean>(false)

  const handleReset = (): void => setFeaturesState(defaultFeaturesState)

  const handleToggleAccordion = (): void => {
    setOpenSection((prevOpenSection) => !prevOpenSection)
  }

  return (
    <div className={className} {...props}>
      <Accordion
        p={2}
        my={4}
        pb={0}
        open={openSection}
        label={
          <Typography className='pl-2'>
            <Trans>Customize which features new members can access</Trans>
          </Typography>
        }
        toggle={handleToggleAccordion}
      >
        <FeatureCategories
          currentFeatures={currentFeatures}
          featuresState={featuresState}
          setFeaturesState={setFeaturesState}
          DisabledFeatureComponent={DisabledFeatureMessage}
          isLoading={false}
        />
      </Accordion>
      <div className='mt-3 h-[23px]'>
        {isUpdated && (
          <Link variant='body1' className='cursor-pointer text-text-link' color='text.link' onClick={handleReset}>
            <Trans>Reset to team features</Trans>
          </Link>
        )}
      </div>
    </div>
  )
}

const InviteUsersTable = ({
  addedUsers,
  className,
  setAddedUsers,
  ...props
}: {
  addedUsers: InvitationUser[]
  setAddedUsers: SetStateFn<InvitationUser[]>
} & DivProps): ReactNode => {
  const isTeamManager = useIsTeamManager()
  if (!addedUsers.length) return null

  const columns = [
    {
      title: t`Email`,
      field: 'email',
      sortable: true
    },
    {
      title: t`Name`,
      field: 'name',
      sortable: false,
      hide: !isTeamManager
    },
    {
      title: '',
      field: 'actions',
      sortable: false
    }
  ]

  const rows = addedUsers.map((user, index) => {
    const setUserName = (value: string): void => {
      setAddedUsers((prevAddedUsers) => {
        const prevItem = prevAddedUsers[index]
        if (!prevItem) return prevAddedUsers

        const newItem = { ...prevItem, name: value }
        return replaceItemFromArray(prevAddedUsers, index, newItem)
      })
    }

    const handleRemoveAddedUser = (): void => {
      setAddedUsers((prevAddedUsers) => removeItemFromArray(prevAddedUsers, index))
    }

    return {
      email: {
        renderValue: user.email,
        sortValue: user.email
      },
      name: {
        renderValue: <EditName name={user.name} setName={setUserName} data-id={TestIDs.Settings.Members.nameInput} />,
        sortValue: user.name
      },
      actions: {
        renderValue: (
          <IconButton
            tabIndex={-1}
            onClick={handleRemoveAddedUser}
            data-track='Settings / Team / Invite user / Remove user'
          >
            <IconGarbage />
          </IconButton>
        )
      }
    }
  })

  const getRowId = (index: number): string | undefined => addedUsers[index]?.email

  return (
    <div className={className} data-id='invite-users-sortable-table' {...props}>
      <SortableTable
        columns={columns}
        rows={rows}
        initialSortField='email'
        headerPalette='background.paper'
        tableHeadProps={{
          className: 'border-bottom border-color-secondary-100'
        }}
        getRowId={getRowId}
      />
    </div>
  )
}

const EmailForm = ({
  addedUsers,
  setAddedUsers
}: {
  addedUsers: InvitationUser[]
  setAddedUsers: SetStateFn<InvitationUser[]>
}): ReactNode => {
  const user = useCurrentUser()
  const isSupervisor = useIsSupervisor()
  const [emailsInput, setEmailsInput] = useState('')

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => setEmailsInput(event.target.value)

  const handleEmailSubmit = (event: FormEvent): void => {
    event.preventDefault()
    if (!emailsInput) return

    const emails = emailsInput.split(',')
    const sanitizedEmails = emails.map((email) => sanitizeEmail(email))
    const duplicateFiltered = uniq(sanitizedEmails)

    const filteredEmails = duplicateFiltered.filter((email) => {
      const isValid = validateEmail(email)
      const alreadyExists = addedUsers.find((u) => u.email === email)
      const isValidDomain = compareEmailDomains(user.email, email) || isSupervisor

      if (!isValid) toast.error(`${email} ${t`is not a valid email`}`)
      if (alreadyExists) toast.error(`${email} ${t`already exists`}`)

      if (!isValidDomain) {
        const emailSplit = user.email.split('@')
        const validDomain = emailSplit.length === 2 && emailSplit[1]
        toast.error(`${email} ${t`doesn't end with ${validDomain}. Please contact Strise to add this user.`}`)
      }

      return isValid && !alreadyExists && isValidDomain
    })

    const users = filteredEmails.map((email) => ({
      email: sanitizeEmail(email),
      name: ''
    }))

    if (!users.length) return

    setAddedUsers((prevAddedUsers) => [...prevAddedUsers, ...users])
    setEmailsInput('')
  }

  return (
    <form onSubmit={handleEmailSubmit}>
      <div className='flex'>
        <Input
          variant='outlined'
          palette='secondary'
          className='h-12 grow'
          value={emailsInput}
          onChange={handleEmailChange}
          placeholder={t`Email(s), comma separated`}
          data-id='invite-users-email-input-field'
        />

        <Button
          variant='contained'
          palette='tertiary'
          className='ml-2 h-12'
          type='submit'
          disabled={!emailsInput}
          data-track='Settings / Team / Invite user / Add email'
          data-id='invite-users-add-to-list-button'
        >
          <Trans>Add to list</Trans>
        </Button>
      </div>
    </form>
  )
}

export const InviteUsers = ({
  emails,
  onCompleted,
  setOpen,
  team
}: {
  emails?: string[]
  onCompleted?: (userIds: string[]) => void
  setOpen: SetStateFn<boolean>
  team: Pick<Team, 'id' | 'name'>
}) => {
  const setTeamUsers = useSetTeamUsers()
  const defaultAddedUsers = emails?.map((email) => ({ name: '', email })) ?? []
  const [addedUsers, setAddedUsers] = useState<InvitationUser[]>(defaultAddedUsers)

  // We don't actually update current user features, but use it as a default value when inviting users
  const {
    currentFeatures,
    defaultFeaturesState,
    featuresLoading,
    featuresState,
    featuresStateInput,
    isFeaturesUpdated,
    setFeaturesState
  } = useUpdateCurrentUserFeatures(team.id, [], [FeatureCategoryKind.Alerts, FeatureCategoryKind.Content])

  const handleCompleted = (userEdges: TeamSimpleUsersFragment['users']['edges']): void => {
    // Update context with new user
    setTeamUsers(userEdges)

    if (onCompleted) {
      const filteredUserIds = userEdges
        .filter(({ node: user }) => user.email && emails?.includes(user.email))
        .map(({ node: user }) => user.id)
      onCompleted(filteredUserIds)
    }

    setOpen(false)
  }

  const handleInviteUsersCompleted = (data: AddUsersToTeamMutation): void => {
    toast.success(t`Users invited`)

    const userEdges = data.addUsersToTeam.team.users.edges

    handleCompleted(userEdges)
  }

  const handleRequestUsersCompleted = (data: RequestUsersToTeamMutation): void => {
    toast.success(t`Users requested`)

    const userEdges = data.requestUsersToTeam.team.users.edges

    handleCompleted(userEdges)
  }

  const isTeamManager = useIsTeamManager()
  const [inviteUsers, { loading: inviteUsersLoading }] = useAddUsersToTeamMutation({
    onCompleted: handleInviteUsersCompleted
  })
  const [requestUsers, { loading: requestUsersLoading }] = useRequestUsersToTeamMutation({
    onCompleted: handleRequestUsersCompleted
  })
  const loading = inviteUsersLoading || requestUsersLoading || featuresLoading

  const handleDialogClose = (): void => {
    if (loading) return

    setOpen(false)
  }

  const handleInviteUsers = async (): Promise<void> => {
    if (addedUsers.some((addedUser) => !addedUser.name.length)) {
      toast.error(t`Missing name`)
      return
    }

    const userCreationInputResource = addedUsers.map((addedUser) => ({
      ...addedUser,
      sendInviteEmail: true
    }))

    await inviteUsers({
      variables: {
        team: team.id,
        users: {
          users: userCreationInputResource,
          features: featuresStateInput
        }
      }
    })
  }

  const handleRequestUsers = async (): Promise<void> => {
    const addedEmails = addedUsers.map((addedUser) => addedUser.email)

    await requestUsers({
      variables: {
        team: team.id,
        emails: addedEmails
      }
    })
  }
  const handleSave = (): void => {
    if (addedUsers.some((addedUser) => !addedUser.email.length)) {
      toast.error(t`Missing email`)
      return
    }

    if (isTeamManager) {
      handleInviteUsers()
    } else {
      handleRequestUsers()
    }
  }

  return (
    <ConfirmDialog
      isOpen
      onRequestClose={handleDialogClose}
      cancelText={t`Close`}
      confirmText={isTeamManager ? t`Send invite` : t`Send request`}
      cancelButtonProps={{ disabled: loading, 'data-id': 'invite-users-confirm-dialog-cancel-button' }}
      confirmButtonProps={{
        loading,
        disabled: !addedUsers.length,
        endIcon: <IconSend className='ml-auto' />,
        'data-id': 'invite-users-confirm-dialog-accept-button'
      }}
      onConfirm={handleSave}
      onCancel={handleDialogClose}
      title={isTeamManager ? t`Add new members` : t`Request new members`}
      testId='invite-users-confirm-dialog'
      contentMaxWidth={740}
    >
      <Typography className='mb-1'>
        {isTeamManager ? (
          <Trans>Invite new members to your team</Trans>
        ) : (
          <Trans>Request new members to your team</Trans>
        )}
      </Typography>
      <EmailForm addedUsers={addedUsers} setAddedUsers={setAddedUsers} />
      <InviteUsersTable className='mt-7' addedUsers={addedUsers} setAddedUsers={setAddedUsers} />
      {isTeamManager && (
        <InviteUserFeatures
          className='mt-7'
          currentFeatures={currentFeatures}
          featuresState={featuresState}
          setFeaturesState={setFeaturesState}
          isUpdated={isFeaturesUpdated}
          defaultFeaturesState={defaultFeaturesState}
        />
      )}
    </ConfirmDialog>
  )
}
