import { type DeleteModifier } from '@apollo/client/cache/core/types/common'
import { type ApolloCache } from '@apollo/client/index.js'
import { t } from '@lingui/macro'
import { formatDate, toast } from '@strise/app-shared'
import { type DivProps } from '@strise/react-utils'
import { CounterPartyDirection } from '@strise/types'
import { Button, IconButton, IconPenBox, IconPlus, Tag, Typography, cn } from '@strise/ui-components'
import { Table, TableCell, TableRow } from '@strise/ui-components-legacy'
import { noop } from 'lodash-es'
import * as React from 'react'
import { type ReactNode, useState } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import {
  AddEditEntityCounterpartyDialog,
  DeleteCounterpartyDialog,
  type EntityCounterpartyFormData
} from '~/features/Counterparty/AddEditEntityCounterpartyDialog'
import { SidepanelEditableCard } from '~/features/SidepanelEditableCard'
import {
  useCounterpartiesQuery,
  useCreateCounterPartyMutation,
  useDeleteCounterPartyMutation,
  useUpdateCounterPartyMutation
} from '~/graphqlOperations'
import { type BaseEntityLikeFragment, type CounterPartyFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'
import { type SidepanelTab } from '~/utils/urls'

interface SidepanelCounterpartiesCardProps extends DivProps {
  entity: BaseEntityLikeFragment
  sidepanelTab: SidepanelTab
}

const updateCache = (cache: ApolloCache<object>, counterpartyEntity: string | null): void => {
  if (!counterpartyEntity) return

  cache.modify({
    id: cache.identify({ __typename: 'Company', id: counterpartyEntity }),
    fields: {
      counterparties(_, { DELETE }): DeleteModifier {
        return DELETE
      }
    }
  })
}

const CounterpartiesRow = ({
  direction,
  editMode,
  node,
  onEditClick,
  striped
}: {
  direction: CounterPartyDirection
  editMode: boolean
  node: CounterPartyFragment | null
  onEditClick: () => void
  striped: boolean
}) => {
  return (
    <TableRow className='flex justify-between align-top' striped={striped}>
      <TableCell size='medium'>
        {node ? (
          <div>
            <EntityLink entity={node.counterPartyEntity} />
            {!!node.comment && (
              <Typography variant='body1' className='pb-2 text-text-secondary'>
                {node.comment}
              </Typography>
            )}
            <div className='flex text-left'>
              <Tag palette='blue'>
                <Typography className='text-sm'>Edited</Typography>
              </Tag>
              <Typography variant='aLabelSmall' className='content-center pl-2 text-secondary-shade-50'>
                by {node.createdBy.name} · {formatDate(node.lastModified)}
              </Typography>
            </div>
          </div>
        ) : (
          <div>
            <Typography variant='body1' className='py-1 text-text-secondary'>
              {t`No ${direction.toLowerCase()} counterparties added`}
            </Typography>
          </div>
        )}
      </TableCell>
      {editMode && node && (
        <IconButton
          className={cn('size-10 text-gray-60')}
          variant='ghost'
          onClick={onEditClick}
          data-track='Counterparties / Edit counterparty'
        >
          <IconPenBox size='md' />
        </IconButton>
      )}
    </TableRow>
  )
}

export const SidepanelCounterpartiesCard = React.forwardRef<HTMLDivElement, SidepanelCounterpartiesCardProps>(
  ({ className, entity, sidepanelTab, ...props }, ref): ReactNode => {
    const [editMode, setEditMode] = useState(false)
    const [showOriginal, setShowOriginal] = useState(false)
    const [counterpartyDialogOpen, setCounterpartyDialogOpen] = useState(false)
    const [counterpartyDeleteDialogOpen, setCounterpartyDeleteDialogOpen] = useState(false)
    const [counterpartyFormData, setCounterpartyFormData] = useState<EntityCounterpartyFormData>({
      id: '',
      comment: '',
      counterpartyEntity: null,
      direction: null,
      entity: entity.id
    })

    const getMutationOptions = (
      successMessage: string
    ): { onCompleted: () => void; update: (cache: ApolloCache<object>) => void } => ({
      onCompleted: () => {
        toast.success(successMessage)
        handleCounterpartyDialogClose()
      },
      update: (cache: ApolloCache<object>) => updateCache(cache, counterpartyFormData.counterpartyEntity)
    })

    const [addCounterparty, { loading: addLoading }] = useCreateCounterPartyMutation(
      getMutationOptions(t`Counterparty added`)
    )

    const [updateCounterparty, { loading: updateLoading }] = useUpdateCounterPartyMutation(
      getMutationOptions(t`Counterparty updated`)
    )

    const [deleteCounterparty, { loading: deleteLoading }] = useDeleteCounterPartyMutation(
      getMutationOptions(t`Counterparty deleted`)
    )

    const { data, loading } = useCounterpartiesQuery({
      variables: { company: entity.id }
    })

    const counterparties = data?.company.counterParties

    const handleCounterpartyDialogClose = (): void => {
      setCounterpartyDialogOpen(false)
      setCounterpartyDeleteDialogOpen(false)
      setCounterpartyFormData({
        id: '',
        comment: '',
        direction: null,
        counterpartyEntity: null,
        entity: entity.id
      })
    }

    const handleDeleteCounterparty = async (): Promise<void> => {
      await deleteCounterparty({
        variables: {
          counterPartyId: counterpartyFormData.id,
          company: entity.id
        }
      })
    }

    const handleUpdateCounterparty = async (): Promise<void> => {
      await updateCounterparty({
        variables: {
          counterPartyId: counterpartyFormData.id,
          comment: counterpartyFormData.comment,
          company: counterpartyFormData.entity || ''
        }
      })
    }

    const handleAddCounterparty = async (): Promise<void> => {
      if (!counterpartyFormData.counterpartyEntity) return
      if (!counterpartyFormData.direction) return

      await addCounterparty({
        variables: {
          input: {
            comment: counterpartyFormData.comment,
            entity: entity.id,
            counterPartyEntity: counterpartyFormData.counterpartyEntity,
            direction: counterpartyFormData.direction
          },
          entity: entity.id
        }
      })
    }

    const counterpartiesContent = (): ReactNode => {
      return (
        <div>
          {Object.values(CounterPartyDirection).map((direction, dirind) => {
            const hasRows = !!data?.company.counterParties.filter((cp) => cp.direction === direction).length
            return (
              <div key={dirind} className={cn('flex w-full shrink-0 border-b border-b-gray-10 px-2')}>
                <Typography className='mr-3 flex w-1/3 shrink-0 flex-col justify-center whitespace-normal capitalize text-text-secondary'>
                  {direction.toLowerCase()}
                </Typography>
                <Table>
                  <tbody>
                    {!hasRows && !loading ? (
                      <CounterpartiesRow
                        node={null}
                        editMode={editMode}
                        onEditClick={noop}
                        striped={true}
                        direction={direction}
                      />
                    ) : (
                      counterparties?.map((counterparty, index) => {
                        if (counterparty.direction !== direction) return null

                        return (
                          <CounterpartiesRow
                            node={counterparty}
                            key={index}
                            striped={hasRows ? index === counterparties.length - 1 : false}
                            editMode={editMode}
                            direction={direction}
                            onEditClick={() => {
                              setCounterpartyDialogOpen(true)
                              setCounterpartyFormData({
                                id: counterparty.id,
                                comment: counterparty.comment || '',
                                counterpartyEntity: counterparty.counterPartyEntity.id,
                                direction: counterparty.direction,
                                entity: entity.id
                              })
                            }}
                          />
                        )
                      })
                    )}
                  </tbody>
                </Table>
              </div>
            )
          })}
        </div>
      )
    }

    return (
      <div className={className} {...props} data-id={TestIDs.SidePanel.Counterparty.root}>
        <SidepanelEditableCard
          ref={ref}
          tab={sidepanelTab}
          title='Counterparties'
          loading={loading}
          setEditMode={setEditMode}
          context='Counterparties'
          editMode={editMode}
          editTitle='Edit counterparties'
          hasCustomData={false}
          hasEditAccess={true}
          lastEditedAt={null}
          setShowOriginal={setShowOriginal}
          showOriginal={showOriginal}
          toggleEditButtonDataId={TestIDs.SidePanel.Counterparty.toggleEditButton}
        >
          <div className='w-full px-2'>{counterpartiesContent()}</div>
          <Button
            className={editMode ? 'w-full py-2' : 'hidden'}
            variant='ghost'
            data-track={`Counterparties / ${entity.name} / Add counterparty`}
            data-id={TestIDs.SidePanel.Counterparty.addCounterpartyCardButton}
            onClick={() => setCounterpartyDialogOpen(true)}
          >
            <IconPlus className='mr-2 rounded-sm bg-gray-5 p-1 text-blue-50' />
            <Typography className='text-blue-50'>{t`Add counterparty`}</Typography>
          </Button>
        </SidepanelEditableCard>
        {counterpartyDialogOpen && (
          <AddEditEntityCounterpartyDialog
            formData={counterpartyFormData}
            entity={entity}
            onClose={handleCounterpartyDialogClose}
            handleConfirm={counterpartyFormData.id ? handleUpdateCounterparty : handleAddCounterparty}
            loading={updateLoading || addLoading}
            setFormData={setCounterpartyFormData}
            onCounterpartyDelete={() => setCounterpartyDeleteDialogOpen(true)}
          />
        )}
        {counterpartyDeleteDialogOpen && (
          <DeleteCounterpartyDialog
            counterpartyEntityId={counterpartyFormData.counterpartyEntity || ''}
            handleCancel={() => setCounterpartyDeleteDialogOpen(false)}
            handleDeletion={handleDeleteCounterparty}
            loading={deleteLoading}
          />
        )}
      </div>
    )
  }
)
