import { IconChevronDown, cn } from '../..'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { type HTMLAttributes, type ReactNode, forwardRef } from 'react'

export interface AccordionBaseProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

export type AccordionProps =
  | (Omit<AccordionPrimitive.AccordionSingleProps, 'type'> & { type: 'single' })
  | (Omit<AccordionPrimitive.AccordionMultipleProps, 'type'> & { type: 'multiple' })

export interface AccordionItemProps extends AccordionBaseProps {
  disabled?: boolean
  value: string
}

export interface AccordionTriggerProps extends HTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  disabled?: boolean
}

export interface AccordionContentProps extends AccordionBaseProps {
  children: ReactNode
}

const Accordion = forwardRef<HTMLDivElement, AccordionProps>(({ ...props }, ref) => (
  <AccordionPrimitive.Root ref={ref} {...props} />
))

Accordion.displayName = 'Accordion'

const AccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn('border-b border-gray-20', className)} {...props} />
))

AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, className, ...props }, ref) => (
    <AccordionPrimitive.Header className='flex'>
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          'flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all text-left [&[data-state=open]>svg]:rotate-180',
          className
        )}
        {...props}
      >
        {children}
        <IconChevronDown className='size-4 shrink-0 transition-transform duration-200' />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
)

AccordionTrigger.displayName = 'AccordionTrigger'

const AccordionContent = forwardRef<HTMLDivElement, AccordionContentProps>(({ children, className, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className='overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down'
    {...props}
  >
    <div className={cn('pb-4 pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
))

AccordionContent.displayName = 'AccordionContent'

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }
