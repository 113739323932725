import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { useDependencyState } from '@strise/react-utils'
import { Button, Input, type InputProps, Label, Typography, cn } from '@strise/ui-components'
import { Dropdown } from '@strise/ui-components-legacy'
import { isNil } from 'lodash-es'
import type { ReactNode } from 'react'
import { FilterButtonText } from '~/components/Filter/Filter'
import { type FromToLike, activeText, valueToString } from '~/components/Filter/filterFromToUtils'

const NumberInput = ({ className, ...props }: InputProps): ReactNode => {
  return (
    <Input
      className={cn('rounded-full bg-secondary-shade-70 text-sm', className)}
      variant='contained'
      palette='secondary'
      type='number'
      {...props}
    />
  )
}

export const FilterFromToEdit = <T extends string>({
  dataTrack,
  filter,
  header,
  onReset,
  onSave,
  title
}: {
  dataTrack: string
  filter: FromToLike<T> | null | undefined
  header?: string
  onReset: () => void
  onSave: (from: string, to: string) => void
  title: string
}): ReactNode => {
  const [from, setFrom] = useDependencyState(valueToString(filter?.from), [filter?.from])
  const [to, setTo] = useDependencyState(valueToString(filter?.to), [filter?.to])

  const chipLabel =
    !isNil(filter?.from) || !isNil(filter?.to)
      ? activeText(filter.from ?? null, filter.to ?? null)
          .map((text) => i18n._(text))
          .join(' ')
      : undefined

  return (
    <Dropdown
      width={1}
      buttonText={<FilterButtonText onReset={onReset} chipLabel={chipLabel} label={title} />}
      buttonProps={{
        className: 'bg-secondary-shade-90 w-full h-sub-header',
        variant: 'contained',
        palette: 'secondary'
      }}
      paperProps={{
        className: 'z-[4]'
      }}
    >
      <div className='space-y-2 p-2'>
        {header && <Typography variant='body2'>{header}</Typography>}
        <div>
          <Label className='mb-2'>
            <Trans>From</Trans>
          </Label>
          <NumberInput value={from} onChange={(e) => setFrom(e.target.value)} />
        </div>

        <div>
          <Label className='mb-2'>
            <Trans>To</Trans>
          </Label>
          <NumberInput value={to} onChange={(e) => setTo(e.target.value)} />
        </div>
      </div>

      <Button
        className='w-full'
        variant='contained'
        palette='primary'
        onClick={() => onSave(from, to)}
        data-track={dataTrack}
      >
        <Trans>Apply</Trans>
      </Button>
    </Dropdown>
  )
}
